import classNames from "classnames";

import React from "react";
import ButtonContainer from "../../app/components/containers/ButtonContainer";
import { FlexJustifyContent } from "../../app/components/utils/constants";
import Button, { ButtonTypes } from "../../app/components/buttons/Button";

interface DialogBoxProps {
  headerText?: string;
  closeClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  dangerBtnText?: string;
  safeBtnText?: string;
  safeId?: string;
  cancelBtnText?: string;
  cancelId?: string;
  confirmText?: string;
  dangerClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onSafeClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
  loadingText?: string;
  columnClasses?: string;
  safeBtnClasses?: string;
  serverErrors?: string;
}

class DialogBox extends React.Component<DialogBoxProps, any> {
  public static defaultProps = {
    cancelBtnText: "Cancel",
    columnClasses: "small-centered small-12 medium-7 large-5 columns",
  };

  render() {
    const modalClasses = classNames({
      in: true,
      modal: true,
      active: true,
      "dialog-box": true,
    });

    const stacked = this.props.dangerBtnText && this.props.safeBtnText && this.props.cancelBtnText;
    const actionBtnClasses = classNames({
      stacked: !!stacked,
      row: true,
      last: true,
      "action-btns": true,
    });

    return (
      <div className="modal-open">
        <div className={modalClasses} role="dialog" aria-labelledby="" aria-hidden="false" style={{ display: "block" }}>
          <div className={`modal-container ${this.props.columnClasses}`}>
            <div className="modal-dialog">
              <div className="modal-content">
                {this.renderHeader()}
                <div className="modal-body">
                  <div className="row">
                    <div className="columns small-centered">{this.renderTextOrComponent()}</div>
                  </div>
                  {this.renderErrors()}
                  <div className={actionBtnClasses}>
                    <ButtonContainer justify={FlexJustifyContent.Center}>
                      {this.renderCloseBtn()}
                      {this.renderDangerBtn()}
                      {this.renderSafeBtn()}
                    </ButtonContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCloseBtn() {
    return (
      <Button
        data-test="dialog_box.button.action.cancel"
        buttonType={ButtonTypes.Cancel}
        className="dialog-cancel-btn"
        disabled={this.props.isLoading}
        onClick={this.props.closeClick}
      >
        {this.props.cancelBtnText || "Cancel"}
      </Button>
    );
  }

  renderDangerBtn() {
    if (this.props.dangerBtnText && this.props.dangerClick) {
      return (
        <Button
          data-test="dialog_box.button.action.danger"
          buttonType={ButtonTypes.Danger}
          className="dialog-danger-btn"
          disabled={this.props.isLoading}
          onClick={this.props.dangerClick}
        >
          {this.props.dangerBtnText}
        </Button>
      );
    }
  }

  renderSafeBtn() {
    if (this.props.safeBtnText && this.props.onSafeClick) {
      return (
        <Button
          data-test="dialog_box.button.action.primary"
          buttonType={ButtonTypes.Primary}
          className={`${this.props.safeBtnClasses || ""} dialog-safe-btn`}
          disabled={this.props.isLoading}
          onClick={this.props.onSafeClick}
        >
          {this.props.isLoading ? this.props.loadingText || "Loading..." : this.props.safeBtnText}
        </Button>
      );
    }
  }

  renderTextOrComponent() {
    if (!this.props.confirmText) {
      return this.props.children;
    } else {
      return <p className="heading">{this.props.confirmText}</p>;
    }
  }

  renderErrors() {
    if (this.props.serverErrors) {
      return (
        <div className="row">
          <div className="columns small-centered">
            <div className="error">{this.props.serverErrors}</div>
          </div>
        </div>
      );
    }
  }

  renderHeader() {
    if (this.props.headerText) {
      return (
        <div className="modal-header">
          <div className="modal-header-content">
            <h2 className="modal-title">{this.props.headerText}</h2>
          </div>
        </div>
      );
    }
  }
}

export default DialogBox;
