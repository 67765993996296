import alt from "../../alt";
import CommentApi from "../apis/comment-api";
import messageActions from "../../common/actions/message-actions";

class CommentActions {
  constructor() {
    this.generateActions(
      "fetchComments",
      "fetchCommentsDone",
      "fetchCommentsFail",
      "fetchComment",
      "fetchCommentDone",
      "fetchCommentFail",
      "createCommentDone",
      "createCommentFail",
      "updateCommentDone",
      "updateCommentFail",
      "deleteCommentDone",
      "deleteCommentFail"
    );
  }

  createComment(comment, cid) {
    CommentApi.createComment(comment)
      .then(result => {
        this.createCommentDone({ data: result.data, cid });
      })
      .catch(result => {
        messageActions.storeError({ body: result.messages[0] });
        this.createCommentFail({ data: result, cid });
      });
    return { cid };
  }

  updateComment(comment, cid) {
    CommentApi.updateComment(comment)
      .then(result => {
        this.updateCommentDone({ data: result.data, cid });
      })
      .catch(result => {
        messageActions.storeError({ body: result.messages[0] });
        this.updateCommentFail({ data: result, cid });
      });
    return { cid };
  }

  deleteComment(id, cid) {
    CommentApi.deleteComment(id)
      .then(result => {
        this.deleteCommentDone({ data: result.data, id, cid });
      })
      .catch(result => {
        this.deleteCommentFail({ data: result, cid });
      });
    return { cid };
  }
}

export default alt.createActions(CommentActions);
