import moment from "moment";
import React from "react";

export default class TimeSlotHeader extends React.Component<{
  mom: moment.Moment;
}> {
  render() {
    return (
      <div className="time-slot-header">
        <div>{this.props.mom.format("dddd")}</div>
        <div>{this.props.mom.format("MMM Do")}</div>
      </div>
    );
  }
}
