import React from "react";
import I from "immutable";
import _ from "lodash";

import OwnerStore from "./stores/owner-store";
import ErrorRenderingUtils from "../utils/error-rendering-utils";
import { StatusStates } from "../stores/base-store";
import ModelUpdateUtils from "../utils/model-update-utils";
import { HubAccessDropdown } from "../../../web/apps/property-management-hub/src/components/owners/hub-access-dropdown";
import { Box } from "@chakra-ui/react";
import { OwnerHubAccessLevelEnum } from "@pm-shared/owner";

let BaseOwnerForm = {
  mixins: [ErrorRenderingUtils, ModelUpdateUtils],

  getInitialState() {
    let data =
      this.props.data ||
      I.fromJS({
        first_name: "",
        last_name: "",
        email: "",
        hub_access_level: OwnerHubAccessLevelEnum.DIRECT_ONLY,
        contact: {
          business_phone: "",
          cell_phone: "",
          home_phone: "",
        },
      });
    let cid = _.uniqueId("newOwnerForm_");

    return {
      data: data,
      status: OwnerStore.getStatus(cid),
      cid: cid,
    };
  },

  getDefaultProps() {
    return {
      constraints: {
        first_name: {
          presence: {
            message: "First name is required",
            allowEmpty: false,
          },
        },
        last_name: {
          presence: {
            message: "Last name is required",
            allowEmpty: false,
          },
        },
        email: {
          email: {
            message: "Email address is not valid",
          },
          presence: {
            message: "Email is required",
            allowEmpty: false,
          },
        },
      },
    };
  },

  render() {
    let selectedExisting = !!this.state.data.get("id") && !!this.props.propertyId;
    return (
      <form onSubmit={this.onSubmit} className="vendor-invite-modal" name="owner-form">
        {this.renderErrors()}

        <div className="row">
          {this.renderName()}
          <div className="columns medium-4">
            <div className="label-container">
              <label>Email</label>
            </div>
            <input
              type="text"
              placeholder="Email"
              name="email"
              data-test="email"
              autoComplete="email"
              value={this.state.data.get("email")}
              onChange={(e) => {
                this.setVal("email", e.target.value.trim());
              }}
              disabled={selectedExisting}
            />
          </div>
        </div>

        <div className="row">
          <div className="columns medium-4">
            <div className="label-container">
              <label>Cell Phone</label>
            </div>
            <input
              type="tel"
              placeholder="Cell phone"
              name="mobile"
              data-test="mobile"
              value={this.state.data.getIn(["contact", "cell_phone"])}
              onChange={this.onChangeSetIn(["contact", "cell_phone"])}
              disabled={selectedExisting}
            />
          </div>
          <div className="columns medium-4">
            <div className="label-container">
              <label>Home Phone</label>
            </div>
            <input
              type="tel"
              placeholder="Home phone"
              name="phone"
              data-test="phone"
              value={this.state.data.getIn(["contact", "home_phone"])}
              onChange={this.onChangeSetIn(["contact", "home_phone"])}
              disabled={selectedExisting}
            />
          </div>
          <div className="columns medium-4">
            <div className="label-container">
              <label>Business Phone</label>
            </div>
            <input
              type="tel"
              placeholder="Business phone"
              name="business-phone"
              data-test="business-phone"
              value={this.state.data.getIn(["contact", "business_phone"])}
              onChange={this.onChangeSetIn(["contact", "business_phone"])}
              disabled={selectedExisting}
            />
          </div>
        </div>
        <div className="row">
          <div className="columns large-12">
            <label className="horizontal-input" htmlFor="hub-access-level">
              <Box mt={2}>
                <HubAccessDropdown
                  disabled={selectedExisting}
                  accessLevel={this.state.data.get("hub_access_level")}
                  onChange={(hub_access_level) =>
                    this.setState({
                      data: this.state.data.set("hub_access_level", hub_access_level),
                    })
                  }
                />
              </Box>
            </label>
          </div>
        </div>

        {this.renderButtonControls()}
      </form>
    );
  },

  toggleNotifications(e) {
    let currentVal = this.state.data.get("hub_access_level");
    this.setState({
      data: this.state.data.set(
        "hub_access_level",
        ![OwnerHubAccessLevelEnum.DIRECT_ONLY, OwnerHubAccessLevelEnum.NONE].includes(currentVal)
          ? OwnerHubAccessLevelEnum.ALL_NOTIFICATIONS
          : OwnerHubAccessLevelEnum.DIRECT_ONLY
      ),
    });
  },

  getConstraints() {
    return this.props.constraints;
  },

  isPendingSave() {
    return OwnerStore.getStatus(this.state.cid).get("statusState") === StatusStates.PENDING;
  },

  componentDidMount() {
    OwnerStore.listen(this.ownersChanged);
  },

  componentWillUnmount() {
    OwnerStore.unlisten(this.ownersChanged);
  },

  ownersChanged() {
    let status = OwnerStore.getStatus(this.state.cid);
    this.setState({ status });

    if (status.get("statusState") === StatusStates.DONE) {
      this.props.close();
    }
  },
};

export default BaseOwnerForm;
