import alt from "../../alt";
import MeldAPI from "../apis/meld-api";
import messageActions from "../../common/actions/message-actions";
import HistoryUtils from "../../common/utils/history-utils";

class MeldActions {
  constructor() {
    this.generateActions(
      "cancelDone",
      "cancelFail",
      "fetchMelds",
      "fetchMeldsDone",
      "fetchMeld",
      "fetchMeldDone",
      "fetchMeldsByUnit",
      "fetchMeldsByUnitDone",
      "fetchMeldsByUnitFail",
      "markCompleteDone",
      "markCompleteFail",
      "reviewDone",
      "reviewFail",
      "updateDone",
      "updateFail",
      "acceptDone",
      "acceptFail",
      "assignMaintenanceDone",
      "assignMaintenanceFail",
      "updateSegmentsDone",
      "updateSegmentsFail",
      "addNewVendorAppointmentDone",
      "addNewVendorAppointmentFail",
      "rescheduleVendorAppointmentDone",
      "rescheduleVendorAppointmentFail"
    );
  }

  cancel(notes, id, { cid } = {}) {
    MeldAPI.cancel(notes, id, cid)
      .then((result) => {
        this.cancelDone({ data: result.data, cid });
        return result;
      })
      .catch((result) => {
        this.cancelFail({ cid });
      });

    return { cid };
  }

  markComplete(id, data, cid) {
    MeldAPI.markComplete(id, data)
      .then((result) => {
        this.markCompleteDone({ data: result.data, cid });
        return result;
      })
      .catch((result) => {
        this.markCompleteFail({ cid });
      });

    return { cid };
  }

  review(id, data, cid) {
    MeldAPI.review(id, data)
      .then((result) => {
        this.reviewDone({ data: result.data, cid });
        messageActions.storeSuccess({ body: "Review submitted!" });
        return result;
      })
      .catch((result) => {
        messageActions.storeError({ body: result.messages[0] });
        this.reviewFail({ cid });
      });

    return { cid };
  }

  fetchMeldsFail(data) {
    messageActions.storeError({ body: "Failed to get your melds." });
    return { data };
  }

  fetchMeldFail(data) {
    messageActions.storeError({ body: "Failed to get your meld." });
    return { data };
  }

  update(ticket, cid) {
    MeldAPI.update(ticket, cid)
      .then((res) => {
        this.updateDone({ data: res.data, cid });
        return res;
      })
      .catch((res) => {
        this.updateFail({ data: res, cid });
      });

    return { cid };
  }

  accept(meldId, data, { cid, options = {} } = {}) {
    MeldAPI.accept(meldId, data, options)
      .then((res) => {
        this.acceptDone({ data: res, cid });
      })
      .catch((res) => {
        this.acceptFail({ data: res, cid });
      });

    return { id: meldId, cid };
  }

  assignMaintenance(maintenance, id, { cid } = {}) {
    MeldAPI.assignMaintenance(id, maintenance)
      .then((res) => {
        this.assignMaintenanceDone({ data: res.data, cid });
      })
      .catch((res) => {
        this.assignMaintenanceFail({ data: res, cid });
      });
    return { data: maintenance, id, cid };
  }

  updateSegments(id, data, { cid, options = {} } = {}) {
    MeldAPI.updateSegments(id, data, options)
      .then((res) => {
        this.updateSegmentsDone({ data: res, cid });
      })
      .catch((res) => {
        this.updateSegmentsFail({ data: res, cid });
      });

    return { id, cid };
  }

  addNewVendorAppointment(id, data, { cid, options = {} } = {}) {
    return new Promise((resolve, reject) => {
      MeldAPI.addNewVendorAppointment(id, data, options)
        .then((res) => {
          this.addNewVendorAppointmentDone({ data: res, cid });
          resolve(res);
        })
        .catch((res) => {
          this.addNewVendorAppointmentFail({ data: res, cid });
          reject(res);
        });
    });
  }

  rescheduleVendorAppointment(id, data, { cid, options = {} } = {}) {
    return new Promise((resolve, reject) => {
      MeldAPI.rescheduleVendorAppointment(id, data, options)
        .then((res) => {
          this.rescheduleVendorAppointmentDone({ data: res, cid });
          resolve(res);
        })
        .catch((res) => {
          this.rescheduleVendorAppointmentFail({ data: res, cid });
          reject(res);
        });
    });
  }
}

export default alt.createActions(MeldActions);
