var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const ContextMenu = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement("path", { d: "M37 40H3a3 3 0 01-3-3V3a3 3 0 013-3h34a3 3 0 013 3v34a3 3 0 01-3 3zM3 2a1 1 0 00-1 1v34a1 1 0 001 1h34a1 1 0 001-1V3a1 1 0 00-1-1z" }), /* @__PURE__ */ React.createElement("path", { d: "M20 24a4 4 0 114-4 4 4 0 01-4 4zm0-6a2 2 0 102 2 2 2 0 00-2-2zM20 12.88a4 4 0 114-4 4 4 0 01-4 4zm0-6a2 2 0 102 2 2 2 0 00-2-2zM20 35.12a4 4 0 114-4 4 4 0 01-4 4zm0-6a2 2 0 102 2 2 2 0 00-2-2z" }));
