import alt from "../../alt";

import BaseStore from "../../stores/base-store";
import CommentSource from "../sources/comment-source.js";
import CommentActions from "../actions/comment-actions";

class CommentStore extends BaseStore {
  constructor(options = {}) {
    options.defaultComparator = (comment1, comment2) => comment1.get("created").localeCompare(comment2.get("created"));
    options.idField = "composite_id";
    super(options);

    this.registerAsync(CommentSource);

    this.exportPublicMethods({
      getComments: this.getAll.bind(this)
    });

    this.bindListeners({
      fetchingAll: CommentActions.fetchComments,
      fetchAllDone: CommentActions.fetchCommentsDone,
      fetchAllFail: CommentActions.fetchCommentsFail,
      fetching: CommentActions.fetchComment,
      fetchDone: CommentActions.fetchCommentDone,
      fetchFail: CommentActions.fetchCommentFail,
      saving: CommentActions.createComment,
      saveDone: CommentActions.createCommentDone,
      saveFail: CommentActions.createCommentFail,
      updating: CommentActions.updateComment,
      updateDone: CommentActions.updateCommentDone,
      updateFail: CommentActions.updateCommentFail,
      deleting: CommentActions.deleteComment,
      deleteDone: CommentActions.deleteCommentDone,
      deleteFail: CommentActions.deleteCommentFail
    });
  }
}

export default alt.createStore(CommentStore, "CommentStore");
