import React, { useState } from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiTextColor,
  EuiContextMenuPanel,
  EuiContextMenuItem,
  EuiPopover,
  EuiBadge,
  EuiHorizontalRule,
  EuiImage,
  EuiTitle,
  EuiIcon,
  EuiLink,
} from "@elastic/eui";

import URL from "@pm-shared/utils/url";
import { getIdFromText } from "@pm-frontend/shared/utils/idFromText";
import { PmBadge } from "@pm-frontend/shared/components/PmBadge";
import { BorderColor, colors } from "@pm-frontend/styles";
import { Link } from "react-router-dom";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { SecondaryAction, SecondaryActionIconsSrc } from "@pm-frontend/shared/components/PageHeader/PageHeader";
import { DetailViewsPageHeaderProps } from "./DetailViewsPageHeader";
import { PmFilledButton, PmFilledButtonProps } from "../Buttons/PmFilledButton";
import { PmText } from "../Text/PmText";

const isEnabled = (obj: { enabled?: boolean }) => !obj.hasOwnProperty("enabled") || obj.enabled;

const getMobilePrimaryActionProps = (
  primaryAction: DetailViewsPageHeaderProps["primaryAction"],
  secondaryActions: DetailViewsPageHeaderProps["secondaryActions"],
  linkActions: DetailViewsPageHeaderProps["linkActions"]
): PmFilledButtonProps | undefined => {
  if (primaryAction?.mobilePrimary && isEnabled(primaryAction)) {
    return { ...primaryAction, isLoading: false };
  }
  if (secondaryActions) {
    for (let secondaryAction of secondaryActions) {
      if (secondaryAction.mobilePrimary && isEnabled(secondaryAction)) {
        if (typeof secondaryAction.href === "string") {
          return { text: secondaryAction.text, href: secondaryAction.href, isLoading: false };
        } else if (secondaryAction.onClick) {
          return { text: secondaryAction.text, onClick: secondaryAction.onClick, isLoading: false };
        }
      }
    }
  }
  if (linkActions) {
    for (let linkAction of linkActions) {
      if (linkAction.mobilePrimary && isEnabled(linkAction)) {
        return { ...linkAction, isLoading: false };
      }
    }
  }
};

const MobileDetailViewsPageHeader = ({
  subtitle,
  title,
  icon,
  primaryAction,
  secondaryActions,
  prependBadgeRow,
  badges,
  backButtonUrl,
  backButtonOnClick,
  backButtonAriaLabel,
  backButtonText,
  linkActions,
  isInactive = false,
  inactiveAction,
  extraContentRow,
  "data-testid": dataTestId,
  backButtonLinkProps,
}: DetailViewsPageHeaderProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const closeMenu = () => setIsMenuOpen(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // the first enabled action with `mobilePrimary` set to true is used
  let mobilePrimaryActionProps = getMobilePrimaryActionProps(primaryAction, secondaryActions, linkActions);

  const convertedPrimaryAction: SecondaryAction | undefined =
    primaryAction && !primaryAction.mobilePrimary
      ? {
          ...primaryAction,
          ...(primaryAction.dataTestId ? { dataTestId: primaryAction.dataTestId } : {}),
        }
      : undefined;

  const convertedLinkActions: SecondaryAction[] = linkActions
    ? linkActions.map((linkAction) => {
        return {
          ...linkAction,
          ...(linkAction.dataTestId ? { dataTestId: linkAction.dataTestId } : {}),
        };
      })
    : [];

  let mobileSecondaryActions: SecondaryAction[] = [];

  if (isInactive && inactiveAction) {
    mobileSecondaryActions = [
      {
        text: inactiveAction.text,
        onClick: inactiveAction.onClick,
      },
    ];
  } else {
    mobileSecondaryActions = secondaryActions
      ? ([convertedPrimaryAction, ...convertedLinkActions, ...secondaryActions].filter(Boolean) as SecondaryAction[])
      : ([convertedPrimaryAction, ...convertedLinkActions].filter(Boolean) as SecondaryAction[]);
  }

  // if the 'enabled' prop is not set the item should be enabled
  const filteredSecondaryActions = mobileSecondaryActions?.filter(
    (action) => !action.hasOwnProperty("enabled") || action.enabled
  );

  const secondaryActionsTitle = (
    <EuiText color={colors.neutrals.gray800} style={{ fontWeight: 700 }}>
      Actions
    </EuiText>
  );

  const renderMenuItems = (items: Array<SecondaryAction | undefined> = []) =>
    items.filter(Boolean).map((item: SecondaryAction) => {
      const textOfItem = (
        <EuiTextColor
          color={colors.neutrals.gray800}
          style={{ maxWidth: "fit-content" }}
          data-testid={item && item.dataTestId ? item.dataTestId : undefined}
        >
          {item.text}
        </EuiTextColor>
      );
      let wrappedItem = textOfItem;
      if (item.href) {
        wrappedItem = (
          <Link to={item.href} target={item.internalLink ? undefined : "_blank"} onClick={closeMenu}>
            {textOfItem}
          </Link>
        );
      } else if (item.linkToProps) {
        wrappedItem = (
          <Link to={item.linkToProps} target={item.internalLink ? undefined : "_blank"} onClick={closeMenu}>
            {textOfItem}
          </Link>
        );
      }

      return (
        <EuiContextMenuItem
          key={item.text}
          style={{
            padding: "0px",
            background: colors.neutrals.gray50,
            textDecoration: "none",
          }}
        >
          <EuiFlexGroup
            direction="row"
            alignItems="flexStart"
            gutterSize="s"
            style={{ padding: "10px 12px" }}
            wrap={false}
            onClick={
              item.onClick
                ? () => {
                    if (item.onClick && typeof item.onClick === "function") {
                      item.onClick();
                    }
                    closeMenu();
                  }
                : undefined
            }
          >
            <EuiFlexItem grow={false} style={{ padding: "2px", maxWidth: "fit-content" }}>
              <EuiImage
                src={item.icon ? SecondaryActionIconsSrc[item.icon] : SecondaryActionIconsSrc.grayExclamation}
                alt=""
                size="original"
                wrapperProps={{ style: { maxWidth: "fit-content" } }}
              />
            </EuiFlexItem>
            <EuiFlexItem
              grow={false}
              style={{
                fontFamily: "Open Sans",
                fontSize: "16px",
                lineHeight: "16px",
                maxWidth: "fit-content",
              }}
            >
              {wrappedItem}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiContextMenuItem>
      );
    });

  const isSubtitleSimilar = (titleText: string, subtitleText?: string) => {
    // return boolean if title contains subtitle string
    return subtitleText && titleText?.toLowerCase().includes(subtitleText?.toLowerCase());
  };

  let formattedSubtitle: React.ReactNode;
  if (subtitle && !isSubtitleSimilar(title, subtitle.text)) {
    if (subtitle.href) {
      formattedSubtitle = (
        <span>
          <Link to={subtitle.href}>
            <EuiLink>{subtitle.text}</EuiLink>
          </Link>
        </span>
      );
    } else {
      formattedSubtitle = (
        <EuiText size="s">
          <EuiTextColor color={colors.neutrals.gray700}>{subtitle.text}</EuiTextColor>
        </EuiText>
      );
    }
  }

  let backButtonLinkElement: React.ReactNode;
  if (backButtonUrl) {
    backButtonLinkElement = (
      <Link
        to={LinkHelper.normalize(backButtonUrl)}
        style={{ maxWidth: "fit-content" }}
        {...(backButtonOnClick ? { onClick: backButtonOnClick } : {})}
      >
        <EuiFlexGroup direction="row" responsive={false} gutterSize="s">
          <EuiFlexItem grow={false}>
            <EuiIcon type={URL.getStatic("icons/arrow_back.svg")} aria-label={backButtonAriaLabel} size="original" />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{backButtonText}</EuiFlexItem>
        </EuiFlexGroup>
      </Link>
    );
  } else if (backButtonLinkProps) {
    backButtonLinkElement = (
      <Link
        {...backButtonLinkProps}
        style={{ maxWidth: "fit-content" }}
        {...(backButtonOnClick ? { onClick: backButtonOnClick } : {})}
      >
        <EuiFlexGroup direction="row" responsive={false} gutterSize="s">
          <EuiFlexItem grow={false}>
            <EuiIcon type={URL.getStatic("icons/arrow_back.svg")} aria-label={backButtonAriaLabel} size="original" />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{backButtonText}</EuiFlexItem>
        </EuiFlexGroup>
      </Link>
    );
  } else if (backButtonOnClick) {
    backButtonLinkElement = (
      <EuiLink style={{ maxWidth: "fit-content" }} onClick={backButtonOnClick}>
        <EuiFlexGroup direction="row" responsive={false} gutterSize="s">
          <EuiFlexItem grow={false}>
            <EuiIcon type={URL.getStatic("icons/arrow_back.svg")} aria-label={backButtonAriaLabel} size="original" />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{backButtonText}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiLink>
    );
  }

  const actionsMenuButton = (
    <EuiFlexGroup
      direction="row"
      gutterSize="s"
      responsive={false}
      onClick={toggleMenu}
      alignItems="center"
      style={{ cursor: "pointer" }}
    >
      <EuiFlexItem grow={false}>
        <EuiIcon
          size="original"
          type={URL.getStatic("icons/horizontal-dots-blue.svg")}
          aria-label="Open secondary action menu"
          color={colors.brand.meldBlue}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <PmText color={colors.brand.meldBlue} textAlign="center" fontSize="p2">
          More actions
        </PmText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
  return (
    <EuiFlexGroup direction="column" style={{ gap: "12px" }}>
      <EuiFlexGroup alignItems="stretch" direction="column" style={{ gap: "24px" }} wrap={false}>
        <EuiFlexItem grow={false} style={{ maxWidth: "fit-content" }}>
          {backButtonLinkElement}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" responsive={false} gutterSize="m" alignItems="center">
            {mobilePrimaryActionProps && <PmFilledButton {...mobilePrimaryActionProps} padding="5.5px 12px" />}
            <EuiFlexItem grow={false} style={{ maxWidth: "fit-content" }}>
              {filteredSecondaryActions && filteredSecondaryActions.length > 0 && (
                <EuiPopover
                  data-testid="page-header-secondary-actions"
                  id={getIdFromText(title)}
                  button={actionsMenuButton}
                  isOpen={isMenuOpen}
                  closePopover={closeMenu}
                  panelPaddingSize="s"
                  anchorPosition="downRight"
                  style={{ maxWidth: "fit-content" }}
                >
                  <EuiContextMenuPanel
                    title={secondaryActionsTitle}
                    items={renderMenuItems(filteredSecondaryActions)}
                  />
                </EuiPopover>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="column" style={{ gap: "12px" }}>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup direction="column" style={{ gap: "12px" }}>
                {icon && (
                  <EuiFlexItem grow={false}>
                    <EuiIcon type={icon} />
                  </EuiFlexItem>
                )}
                <EuiFlexItem grow={false}>
                  {formattedSubtitle}
                  <EuiText>
                    <EuiTextColor color={colors.neutrals.gray900}>
                      <EuiTitle>
                        <h2 style={{ fontSize: "24px" }} {...(dataTestId ? { "data-testid": dataTestId } : {})}>
                          {title}
                        </h2>
                      </EuiTitle>
                    </EuiTextColor>
                  </EuiText>
                </EuiFlexItem>
                {isInactive && (
                  <EuiFlexItem grow={false}>
                    <EuiFlexGroup gutterSize="s">
                      <EuiBadge color={colors.neutrals.gray700}>
                        <EuiTextColor color={colors.brand.white}>Inactive</EuiTextColor>
                      </EuiBadge>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                )}
                {(prependBadgeRow || badges || extraContentRow) && (
                  <EuiFlexGroup gutterSize="s" direction="column">
                    {(prependBadgeRow || badges) && (
                      <EuiFlexGroup gutterSize="s">
                        {prependBadgeRow && (
                          <EuiFlexItem grow={false}>
                            <EuiText
                              color={colors.neutrals.gray800}
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                marginRight: "8px",
                              }}
                              grow={false}
                            >
                              {prependBadgeRow}
                            </EuiText>
                          </EuiFlexItem>
                        )}
                        {badges && (
                          <EuiFlexItem grow={false}>
                            <EuiFlexGroup gutterSize="s" alignItems="center" responsive={false}>
                              {badges.map((badge) => (
                                <EuiFlexItem key={badge.text} grow={false}>
                                  <PmBadge {...badge} />
                                </EuiFlexItem>
                              ))}
                            </EuiFlexGroup>
                          </EuiFlexItem>
                        )}
                      </EuiFlexGroup>
                    )}
                  </EuiFlexGroup>
                )}
                {extraContentRow && (
                  <EuiFlexGroup gutterSize="s" alignItems="center">
                    {extraContentRow}
                  </EuiFlexGroup>
                )}
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule margin="none" size="full" style={{ marginBottom: "0px", backgroundColor: BorderColor }} />
    </EuiFlexGroup>
  );
};

export { MobileDetailViewsPageHeader };
