import Check from "../svg/check-svg";
import classNames from "classnames";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import I from "immutable";
import meldAPI from "./apis/meld-api";
import Modal from "../modal";
import ModalHeader from "../common/components/modal-header";
import ModelUpdateUtils from "../utils/model-update-utils";
import React from "react";
import Warning from "../svg/warning-svg";
import WarningPanel from "../common/components/warning-panel";
import { ErrorList } from "../common/components/errors";
import { Response } from "../utils/base-api";
import MeldUtils from "./utils/meld-utils";
import ButtonContainer from "../../../app/components/containers/ButtonContainer";
import Button, { ButtonTypes } from "../../../app/components/buttons/Button";
import { FinishMeldOpened, track } from "@pm-app/utils/analytics";
import Features from "@pm-assets/js/common/feature-flags";
import VendorMeldUtils from "../vendor_app/meld/utils/vendor-meld-utils";
import Link from "../../../web/shared/utils/link";

interface MarkTicketCompleteModalProps {
  bypass?: boolean;
  close: () => void;
  onSaved: () => void;
  meld: I.Map<string, any>;
}

interface MarkTicketCompleteModalState {
  cancel: boolean;
  data: I.Map<string, any>;
  loading: boolean;
  serverErrors?: string[];
}

class MarkTicketCompleteModal extends React.Component<MarkTicketCompleteModalProps, MarkTicketCompleteModalState> {
  constructor(props: MarkTicketCompleteModalProps) {
    super(props);

    const dateString = MeldUtils.getApptEventStart(this.props.meld);

    this.state = {
      data: I.Map({
        is_complete: true,
        date: dateString ? new Date(dateString) : new Date(),
      }),
      loading: false,
      cancel: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  render() {
    let isComplete = this.state.data.get("is_complete");

    let yesSelectClasses = classNames({
      selection: true,
      checked: isComplete && !this.state.cancel,
    });

    let noSelectClasses = classNames({
      selection: true,
      checked: !isComplete && !this.state.cancel,
    });

    let cancelSelectClasses = classNames({
      selection: true,
      checked: this.state.cancel,
    });

    return (
      <div className="tenant-meld-complete">
        <Modal isOpen={true}>
          <ModalHeader>Close Meld</ModalHeader>

          <div className="modal-body">
            <div className="row">
              <div className="columns">
                <ErrorList errors={this.state.serverErrors} />
                {this.renderWarning()}
                <div>
                  <div className="label-container">
                    <label>Pick when the work was completed:</label>
                  </div>
                  <DateTimePicker
                    defaultValue={this.state.data.get("date")}
                    onChange={ModelUpdateUtils.setWithLabel.call(this, "date")}
                  />
                </div>
                <div className="row">
                  <div className="columns">
                    <div className="label-container">
                      <label>Were you able to complete the work?</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns meld-finish-choices">
                <div
                  className="meld-finish-choice"
                  onClick={() => {
                    this.setState({
                      cancel: false,
                      data: this.state.data.merge({
                        is_complete: true,
                      }),
                    });
                  }}
                >
                  <label htmlFor="finish-yes">Yes</label>
                  <span className="choice-toggle">
                    <input
                      id="finish-yes"
                      readOnly={true}
                      checked={isComplete && !this.state.cancel}
                      name="choice"
                      type="radio"
                    />
                    <span className={yesSelectClasses}>
                      <Check />
                    </span>
                  </span>
                </div>
                <div
                  className="meld-finish-choice"
                  onClick={() => {
                    this.setState({
                      cancel: false,
                      data: this.state.data.merge({
                        is_complete: false,
                      }),
                    });
                  }}
                >
                  <label htmlFor="finish-no">No, I can never complete this meld</label>
                  <span className="choice-toggle">
                    <input
                      id="finish-no"
                      checked={!isComplete && !this.state.cancel}
                      readOnly={true}
                      name="choice"
                      type="radio"
                    />
                    <span className={noSelectClasses}>
                      <Check />
                    </span>
                  </span>
                </div>
                <div
                  className="meld-finish-choice"
                  onClick={() => {
                    this.setState({
                      cancel: true,
                      data: this.state.data.merge({
                        reason: null,
                      }),
                    });
                  }}
                >
                  <label htmlFor="finish-cancel">Not yet, I need more time</label>
                  <span className="choice-toggle">
                    <input id="finish-cancel" checked={this.state.cancel} readOnly={true} name="choice" type="radio" />
                    <span className={cancelSelectClasses}>
                      <Check />
                    </span>
                  </span>
                </div>
              </div>
            </div>

            {this.renderNotes()}

            <div className="row">
              <div className="columns">
                <div className="footer">
                  <ButtonContainer>
                    <Button buttonType={ButtonTypes.Cancel} onClick={this.props.close} disabled={this.state.loading}>
                      Cancel
                    </Button>

                    {Features.isMultipleAppointmentsEnabled() && this.props.meld && (
                      <Link
                        className={classNames("pm-button", ButtonTypes.Neutral)}
                        target="_blank"
                        to={`/assignments/${VendorMeldUtils.getMyCurrentAssignment(this.props.meld).get(
                          "id"
                        )}/schedule/`}
                        query={{
                          addingAppointment: true,
                          markScheduled: true,
                        }}
                      >
                        Add Appointment
                      </Link>
                    )}

                    <Button className="save-meld-btn" buttonType={ButtonTypes.Primary} onClick={this.onSubmit}>
                      {this.state.loading ? "Saving..." : "Save"}
                    </Button>
                  </ButtonContainer>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  renderWarning() {
    if (this.props.bypass) {
      return (
        <div className="row">
          <div className="columns">
            <WarningPanel>
              <span className="warning-panel-svg">
                <Warning />
              </span>
              This meld may not have been scheduled or performed. Make sure the work is actually done before marking as
              complete.
            </WarningPanel>
          </div>
        </div>
      );
    }
  }

  renderNotes() {
    if (!this.state.cancel) {
      const reasonLabel = this.state.data.get("is_complete") ? "Completion notes:" : "Reason for not completing:";
      return (
        <div className="row">
          <div className="columns">
            <div className="label-container">
              <label>{reasonLabel}</label>
            </div>
            <textarea
              placeholder="Enter notes here"
              className="finish-reason"
              onChange={ModelUpdateUtils.onChangeSet.call(this, "reason")}
            >
              {this.state.data.get("reason")}
            </textarea>
          </div>
        </div>
      );
    }
  }

  onSubmit() {
    if (this.state.cancel) {
      this.props.close();
    } else {
      this.setState({ loading: true });
      meldAPI
        .markComplete(this.props.meld.get("id"), this.state.data.toJS())
        .then(() => {
          this.props.onSaved();
        })
        .catch((res: Response) => {
          this.setState({
            serverErrors: res.messages,
            loading: false,
          });
        });
    }
  }

  componentDidMount() {
    track(FinishMeldOpened(this.props.meld.get("id")));
  }
}

export default MarkTicketCompleteModal;
