import React from "react";
import URL from "@pm-shared/utils/url";
import { ErrorHandler } from "@pm-app/utils/ErrorHandler";

interface State {
  error?: Error;
}

export default class ErrorView extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  render() {
    if (!this.state.error) {
      return this.props.children || null;
    }

    return (
      <div className="_500">
        <img src={URL.getStatic("img/_500.png")} />

        <p>
          Looks like an error. We're working on fixing it. Try refreshing your page or{" "}
          <a href="/">view your home page</a>
        </p>
      </div>
    );
  }

  componentDidCatch(error: Error, info: any) {
    ErrorHandler.handleError(error, info);

    this.setState({ error });
  }
}
