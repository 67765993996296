var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import * as C from "../../constants";
import PropTypes from "prop-types";
import React from "react";
import shallowEqual from "shallow-equal/objects";
import Button from "../../../../app/components/buttons/Button";
import ButtonContainer from "../../../../app/components/containers/ButtonContainer";
import { FlexJustifyContent } from "../../../../app/components/utils/constants";
import ForwardButton from "../../../../app/components/buttons/ForwardButton";
import BackButton from "../../../../app/components/buttons/BackButton";
class CalendarControls extends React.Component {
  shouldComponentUpdate(nextProps) {
    const _a = this.props, { startDay } = _a, props = __objRest(_a, ["startDay"]);
    const _b = nextProps, { startDay: startDayNP } = _b, propsNP = __objRest(_b, ["startDay"]);
    return !shallowEqual(propsNP, props) || !startDay.isSame(startDayNP);
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", { className: "date-controls" }, /* @__PURE__ */ React.createElement(Button, { className: "today", onClick: this.props.onTodayClicked }, "Today"), /* @__PURE__ */ React.createElement(ButtonContainer, { justify: FlexJustifyContent.Center }, /* @__PURE__ */ React.createElement(BackButton, { className: "prev-week week-button", onClick: this.props.navPrev }), /* @__PURE__ */ React.createElement("div", { className: "current-week" }, this.renderCurrentTimeSpan()), /* @__PURE__ */ React.createElement(ForwardButton, { className: "next-week week-button", onClick: this.props.navNext })), this.props.showDurationSelect && /* @__PURE__ */ React.createElement("select", { className: "duration-selector", onChange: this.props.onDurationChange, value: this.props.duration }, /* @__PURE__ */ React.createElement("option", { value: C.CalendarDuration.DAY }, "Day"), /* @__PURE__ */ React.createElement("option", { value: C.CalendarDuration.FOUR_DAY }, "4-day"), /* @__PURE__ */ React.createElement("option", { value: C.CalendarDuration.WEEK }, "Week")));
  }
  renderCurrentTimeSpan() {
    const { numDays, startDay } = this.props;
    if (numDays === 1) {
      return startDay.format("MMM Do");
    } else {
      return `${startDay.format("MMM Do")} - ${startDay.clone().add(numDays - 1, "days").format("MMM Do")}`;
    }
  }
}
CalendarControls.propTypes = {
  navNext: PropTypes.func.isRequired,
  navPrev: PropTypes.func.isRequired,
  startDay: PropTypes.object.isRequired,
  numDays: PropTypes.number,
  showDurationSelect: PropTypes.bool
};
CalendarControls.defaultProps = {
  numDays: 1,
  showDurationSelect: false
};
export default CalendarControls;
