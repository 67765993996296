import { axios } from "@pm-shared/axios";
import CommentUtils from "./utils/comment-utils";
import I from "immutable";

type Comment = I.Map<string, any>;

const multitenancy = window.location.pathname.split("/");
export const APIPrefix = `/${multitenancy[1]}/${multitenancy[2]}/${multitenancy[3]}/api`;

export const postSummaryData = (comments: Comment[], meldId: number, cache_check: boolean) => {
  const concatenatedMessages = comments
    .map((comment: Comment) => {
      const userType = CommentUtils.getUserType(comment);
      return comment.get("text") + " (This message was sent from " + userType + ")";
    })
    .join("\n");

  const messages = [
    {
      role: "user",
      content: concatenatedMessages,
    },
  ];
  const postData = {
    messages,
    meldId,
    cache_check,
  };

  const url = `${APIPrefix}/openai/summarize/`;

  return axios
    .post(url, postData)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postTranslateToEnglishData = (text: string, meldId: number) => {
  const messages: any = [];
  messages.push({
    role: "user",
    content: text,
  });

  const postData = {
    messages,
    meldId,
  };

  const url = `${APIPrefix}/openai/translate/toenglish`;

  return axios
    .post(url, postData)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postTranslateEnglishData = (text: string, default_language: string, meldId: number) => {
  const messages: any = [];
  messages.push({
    role: "user",
    content: `The language to translate to is ${default_language}. ${text}`,
  });

  const postData = {
    messages,
    meldId,
  };

  const url = `${APIPrefix}/openai/translate/english`;

  return axios
    .post(url, postData)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const postModifyData = (text: string, selectedTone: string, selectedLength: string, meldId: number) => {
  const modifiedText = `${text} SelectedTone: ${selectedTone}, SelectedLength: ${selectedLength}`;

  const messages: any = [];
  messages.push({
    role: "user",
    content: modifiedText,
  });

  const postData = {
    messages,
    meldId,
  };

  const url = `${APIPrefix}/openai/modify/`;

  return axios
    .post(url, postData)
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
