import I from "immutable";
import { MaybeImmutable } from "../../types/common";
import { asImmer } from "@pm-app/utils/decorators";
import { OwnerNode } from "../../common/graphql/types";
import { getReadableStatus } from "@pm-property-management-hub/src/utils/owners";
import { OwnerHubAccessLevelEnum } from "@pm-shared/owner";

type Owner = OwnerNode | MaybeImmutable;

class OwnerUtils {
  @asImmer()
  static getName(owner: Owner, emptyMsg = "No owner") {
    owner = owner as OwnerNode;

    if (!owner) {
      return emptyMsg;
    }

    return `${owner.first_name || ""} ${owner.last_name || ""}`;
  }

  static getBaseOwnerName(owner: { first_name?: string; last_name?: string }, emptyMsg = "No owner") {
    if (!owner) {
      return emptyMsg;
    }

    return `${owner.first_name || ""} ${owner.last_name || ""}`;
  }

  static getFirstNumber(owner: I.Map<string, any>) {
    const phone =
      owner.getIn(["contact", "business_phone"]) ||
      owner.getIn(["contact", "cell_phone"]) ||
      owner.getIn(["contact", "home_phone"]);
    return phone ? phone : "No number";
  }

  static getCellPhone(owner: I.Map<any, any>) {
    let phone = owner.getIn(["contact", "cell_phone"]);
    if (!phone) {
      phone = "No number";
    }

    return phone;
  }

  static getHomePhone(owner: I.Map<any, any>) {
    let phone = owner.getIn(["contact", "home_phone"]);
    if (!phone) {
      phone = "No number";
    }

    return phone;
  }
  static getOwnerEmail(owner: I.Map<any, any>) {
    return owner.get("email");
  }

  static getBusinessPhone(owner: I.Map<any, any>) {
    let phone = owner.getIn(["contact", "business_phone"]);
    if (!phone) {
      phone = "No number";
    }

    return phone;
  }

  static isEnabled(owner: I.Map<any, any>) {
    return owner.get("hub_access_level") !== OwnerHubAccessLevelEnum.DIRECT_ONLY;
  }

  static getHubAccessLevel(owner: I.Map<any, any>) {
    return getReadableStatus(owner.get("hub_access_level"));
  }
}

export default OwnerUtils;
