var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import { Box, Link as ChakraLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { colors } from "../theme";
export var Width = /* @__PURE__ */ ((Width2) => {
  Width2["SMALL"] = "1024px";
  Width2["MEDIUM"] = "1440px";
  Width2["LARGE"] = "3600px";
  Width2["FULL"] = "100%";
  return Width2;
})(Width || {});
export const Container = (_a) => {
  var _b = _a, { width = "100%" /* FULL */ } = _b, props = __objRest(_b, ["width"]);
  return /* @__PURE__ */ React.createElement(Box, __spreadValues({}, props));
};
Container.displayName = "Container";
export const Content = (_c) => {
  var _d = _c, { width } = _d, props = __objRest(_d, ["width"]);
  return /* @__PURE__ */ React.createElement(Box, __spreadValues({ marginX: "auto", maxWidth: width, paddingX: [4, 6, 8, 16], paddingTop: 6, paddingBottom: 8 }, props));
};
Content.displayName = "Content";
export const Subnav = (_e) => {
  var props = __objRest(_e, []);
  return /* @__PURE__ */ React.createElement(Box, __spreadValues({ paddingX: [10, 10, 10, 20], paddingY: 4, backgroundColor: colors.white, fontSize: "sm", shadow: "sm" }, props));
};
Subnav.displayName = "Subnav";
export const SubnavLink = (_f) => {
  var _g = _f, { history, to } = _g, props = __objRest(_g, ["history", "to"]);
  return /* @__PURE__ */ React.createElement(
    ChakraLink,
    __spreadValues({
      as: Link,
      color: history.location.pathname === to ? "gray.800" : "gray.500",
      to,
      _hover: {
        color: "gray.700"
      }
    }, props)
  );
};
SubnavLink.displayName = "SubnavLink";
