import classNames from "classnames";
import I from "immutable";
import PropTypes from "prop-types";
import React from "react";
import Linkify from "react-linkify";
import SVGInline from "react-svg-inline";
import { MdTranslate } from "react-icons/md";

import DialogBox from "../../../assets/js/dialog-box";
import { SmallLoader } from "../../../assets/js/loaders";
import CommentActions from "../../../assets/js/meld/actions/comment-actions";
import UpdateCommentModal from "../../../assets/js/meld/components/update-comment-modal";
import CommentStore from "../../../assets/js/meld/stores/comment-store";
import { StatusStates } from "../../../assets/js/stores/base-store";
import { LinkHelper } from "@pm-shared/utils/link";
import Panel from "../../components/containers/Panel";
import UserTypeLabel, { UserTypes } from "../../components/labels/UserTypeLabel";
import Avatar from "../../components/prefabs/Avatar";
import UserName from "../../components/prefabs/UserName";
import HeaderBlock, { HeaderSizes } from "../../components/texts/Header";
import TextBlock, { TextBlockSizes } from "../../components/texts/TextBlock";
import DateUtils from "../../utils/DateUtils";
import CommentUtils from "./utils/comment-utils";
import { postTranslateEnglishData } from "./comment-services";
import Features from "@pm-assets/js/common/feature-flags";
import { track, VendorTranslateToOtherClicked, TenantTranslateToOtherClicked } from "@pm-app/utils/analytics";
import { getTranslatedUIPrompt } from "../../../app/views/melds/utils/language_utils";

const DeleteEnvelope = require("../../assets/icons/DeleteEnvelope.svg");
const Envelope = require("../../assets/icons/Envelope.svg");
const OpenEnvelope = require("../../assets/icons/OpenEnvelope.svg");

enum CommentModes {
  Default = "default",
  Delete = "delete",
  Edit = "edit",
}

enum CommentReadIndicators {
  Read = "read",
  DeliveryFailed = "deliveryFailed",
  Unread = "unread",
}

export interface CommentProps {
  comment: I.Map<string, any>;
  show_translation_feature: boolean;
  default_language: string;
  meld_id: number;
  onCommentUpdated?: () => void;
  onCommentDelete?: () => void;
}

interface CommentState {
  expanded: CommentReadIndicators | null;
  mode: CommentModes;
  fetchComplete: boolean;
  translatedText: string | null;
  translatedTo: string | null;
  isTranslating: boolean;
  rateLimitPassed: boolean;
}

export default class Comment extends React.Component<CommentProps, CommentState> {
  static propTypes = {
    comment: PropTypes.object.isRequired,
  };

  constructor(props: CommentProps) {
    super(props);

    this.state = {
      expanded: null,
      mode: CommentModes.Default,
      fetchComplete: false,
      translatedText: null,
      translatedTo: null,
      isTranslating: false,
      rateLimitPassed: false,
    };

    this.onClickIndicator = this.onClickIndicator.bind(this);
    this.handleCommentStoreChange = this.handleCommentStoreChange.bind(this);
    this.handleTranslateClick = this.handleTranslateClick.bind(this);
  }

  render() {
    const isTranslationEnabled = () => {
      const orgType = LinkHelper.getOrgType();
      if (orgType === "v") {
        return Features.isGptTranslateEnabled();
      } else if (orgType === "t") {
        return Features.isResidentGptTranslateEnabled();
      }
      return false;
    };

    const activity = this.props.comment.get("activity");
    let formattedReadStatuses;

    if (activity) {
      formattedReadStatuses = this.getFormattedReadStatuses(activity);
    }

    const userType = CommentUtils.getUserType(this.props.comment);

    const editButtons = this.renderEditButtons();
    const visibility = this.renderVisibility();

    return (
      <div>
        <div className="comment">
          <Panel>
            {userType && this.renderAvatar(userType)}
            <div className="comment-main">
              <div className="comment-header">
                {userType && this.renderUserName(userType)}
                {userType && <UserTypeLabel userType={userType}>{userType}</UserTypeLabel>}
                {this.renderCreatedTime()}
              </div>
              <Linkify>
                {isTranslationEnabled() ? (
                  <div>
                    <HeaderBlock className="comment-text">
                      {this.state.translatedText || this.props.comment.get("text")}
                      {this.state.isTranslating && <SmallLoader />}
                    </HeaderBlock>

                    {(LinkHelper.getOrgType() === "v" || LinkHelper.getOrgType() === "t") &&
                      this.props.show_translation_feature &&
                      this.props.default_language !== null &&
                      this.props.default_language !== "EN" && (
                        <div
                          style={{
                            color: "var(--brand-meld-blue, #1175CC)",
                            cursor: "pointer",
                            marginLeft: "-4px",
                          }}
                          onClick={() => this.handleTranslateClick(this.props.comment.get("text"))}
                        >
                          <MdTranslate />
                          {this.state.rateLimitPassed
                            ? "You have used the maximum translations on this Meld"
                            : this.state.translatedTo === "non-EN"
                            ? getTranslatedUIPrompt("cancel", this.props.default_language)
                            : getTranslatedUIPrompt("translate", this.props.default_language)}
                        </div>
                      )}
                  </div>
                ) : (
                  <HeaderBlock className="comment-text">{this.props.comment.get("text")}</HeaderBlock>
                )}
              </Linkify>

              <div className="comment-footer">
                {this.getReadIndicators()}
                <TextBlock className="comment-subtext">
                  {editButtons}
                  {editButtons && visibility && <div>&bull;</div>}
                  <div>{visibility}</div>
                </TextBlock>
              </div>
            </div>
          </Panel>
          <div
            className={classNames({
              "read-list": true,
              hidden: !this.state.expanded,
            })}
          >
            {formattedReadStatuses ? this.renderReadStatuses(formattedReadStatuses) : this.renderExpanded()}
          </div>
        </div>
        {this.renderMode()}
      </div>
    );
  }

  handleTranslateClick(text: string) {
    this.setState({ isTranslating: true });
    if (this.state.translatedTo === "non-EN") {
      this.setState({
        translatedText: null,
        translatedTo: null,
        isTranslating: false,
      });
    } else {
      if (LinkHelper.getOrgType() === "v") {
        track(VendorTranslateToOtherClicked(this.props.default_language));
      } else if (LinkHelper.getOrgType() === "t") {
        track(TenantTranslateToOtherClicked(this.props.default_language));
      }
      postTranslateEnglishData(text, this.props.default_language, this.props.meld_id)
        .then((response) => {
          this.setState({
            translatedText: response.generated_text,
            translatedTo: "non-EN",
            isTranslating: false,
          });
        })
        .catch((e) => {
          this.setState({
            rateLimitPassed: e.message === "Request failed with status code 429" ? true : false,
            translatedTo: null,
            isTranslating: false,
          });
        });
    }
  }

  renderUserName(userType: UserTypes) {
    switch (userType) {
      case UserTypes.Vendor:
        return (
          <HeaderBlock className="user-name" size={HeaderSizes.Medium}>
            <UserName vendorAgent={this.props.comment.get("agent")} />
          </HeaderBlock>
        );
      case UserTypes.Manager:
        return (
          <HeaderBlock className="user-name" size={HeaderSizes.Medium}>
            <UserName managementAgent={this.props.comment.get("agent")} />
          </HeaderBlock>
        );
      case UserTypes.Owner:
        return (
          <HeaderBlock className="user-name" size={HeaderSizes.Medium}>
            <UserName owner={this.props.comment.get("owner")} />
          </HeaderBlock>
        );
      case UserTypes.Tenant:
        return (
          <HeaderBlock className="user-name" size={HeaderSizes.Medium}>
            <UserName tenant={this.props.comment.get("tenant")} />
          </HeaderBlock>
        );
    }
  }

  renderAvatar(userType: UserTypes) {
    switch (userType) {
      case UserTypes.Vendor:
        return <Avatar vendorAgent={this.props.comment.get("agent")} />;
      case UserTypes.Manager:
        return <Avatar managementAgent={this.props.comment.get("agent")} />;
      case UserTypes.Owner:
        return <Avatar owner={this.props.comment.get("owner")} />;
      case UserTypes.Tenant:
        return <Avatar tenant={this.props.comment.get("tenant")} />;
    }
  }

  renderEditButtons() {
    if (this.props.comment.get("editable") && this.props.comment.get("is_owner")) {
      return (
        <React.Fragment>
          <div>
            <a className="edit" onClick={() => this.setMode(CommentModes.Edit)}>
              Edit
            </a>
          </div>
          <div>&bull;</div>
          <div>
            <a className="delete" onClick={() => this.setMode(CommentModes.Delete)}>
              Delete
            </a>
          </div>
        </React.Fragment>
      );
    }
  }

  renderVisibility() {
    const comment = this.props.comment;
    let visibleTo = ["managers"];

    if (!comment.get("hidden_from_vendor")) {
      visibleTo.push("vendors");
    }
    if (!comment.get("hidden_from_tenant")) {
      visibleTo.push("tenants");
    }
    if (!comment.get("hidden_from_owner")) {
      visibleTo.push("owners");
    }

    return `Visible to: ${visibleTo.join(", ")}`;
  }

  renderCreatedTime() {
    return (
      <TextBlock size={TextBlockSizes.XS} className="comment-timestamp">
        {DateUtils.getDateString(new Date(this.props.comment.get("created")), true)}
      </TextBlock>
    );
  }

  renderMode() {
    if (CommentModes.Delete === this.state.mode) {
      return (
        <DialogBox
          headerText="Delete Comment"
          confirmText="Are you sure you want to delete this comment?"
          dangerBtnText="Yes"
          cancelBtnText="No"
          dangerClick={() => {
            if (this.props.onCommentDelete) {
              this.props.onCommentDelete();
            } else {
              CommentActions.deleteComment(this.props.comment.get("id"));
            }

            this.setMode(CommentModes.Default);
          }}
          closeClick={() => this.setMode(CommentModes.Default)}
        />
      );
    } else if (CommentModes.Edit === this.state.mode) {
      return (
        <UpdateCommentModal
          comment={this.props.comment}
          close={() => {
            this.setMode(CommentModes.Default);
            if (this.props.onCommentUpdated) {
              this.props.onCommentUpdated();
            }
          }}
        />
      );
    }
  }

  renderExpanded() {
    if (!this.state.fetchComplete) {
      return <SmallLoader />;
    } else {
      return <span>There is no status activity for this comment.</span>;
    }
  }

  setMode(mode: CommentModes) {
    this.setState({ mode });
  }

  onClickIndicator(type: CommentReadIndicators) {
    if (this.state.expanded === type) {
      this.setState({ expanded: null });
    } else {
      this.setState({ fetchComplete: false });
      CommentStore.fetchComment(this.props.comment.get("id"), this.props.comment.get("clazz"));
      this.setState({ expanded: type });
    }
  }

  renderReadStatuses({
    read,
    deliveryFailed,
    unread,
  }: {
    read?: JSX.Element;
    deliveryFailed?: JSX.Element;
    unread?: JSX.Element;
  }) {
    const unreadClasses = classNames("read-text", {
      hidden: this.state.expanded !== CommentReadIndicators.Unread,
    });

    const deliveryFailedClasses = classNames("read-text", {
      hidden: this.state.expanded !== CommentReadIndicators.DeliveryFailed,
    });

    const readClasses = classNames("read-text", {
      hidden: this.state.expanded !== CommentReadIndicators.Read,
    });

    return (
      <div>
        <TextBlock className={unreadClasses}>{unread}</TextBlock>
        <TextBlock className={deliveryFailedClasses}>{deliveryFailed}</TextBlock>
        <TextBlock className={readClasses}>{read}</TextBlock>
      </div>
    );
  }

  getFormattedReadStatuses(activity: I.Map<string, any>) {
    let read;
    let deliveryFailed;
    let unread;

    if (activity) {
      const managementAgentReadStatuses = CommentUtils.getReadStatuses(
        this.props.comment.get("read_by_management_agents"),
        activity.get("management_agent_email_statuses"),
        activity.get("management_agent_message_statuses"),
        "management_agent"
      );

      const vendorAgentReadStatuses = CommentUtils.getReadStatuses(
        this.props.comment.get("read_by_vendor_agents"),
        activity.get("vendor_agent_email_statuses"),
        activity.get("vendor_agent_message_statuses"),
        "vendor_agent"
      );

      const tenantReadStatuses = CommentUtils.getReadStatuses(
        this.props.comment.get("read_by_tenants"),
        activity.get("tenant_email_statuses"),
        activity.get("tenant_message_statuses"),
        "tenant"
      );

      const ownerReadStatuses = CommentUtils.getReadStatuses(
        this.props.comment.get("read_by_owners"),
        activity.get("owner_email_statuses"),
        activity.get("owner_message_statuses"),
        "owner"
      );

      if (
        !managementAgentReadStatuses.read.isEmpty() ||
        !vendorAgentReadStatuses.read.isEmpty() ||
        !tenantReadStatuses.read.isEmpty() ||
        !ownerReadStatuses.read.isEmpty()
      ) {
        read = CommentUtils.getFormattedReadStatuses(
          "Read by",
          managementAgentReadStatuses.read,
          vendorAgentReadStatuses.read,
          tenantReadStatuses.read,
          ownerReadStatuses.read
        );
      } else {
        read = <span>This comment has not yet been read by anyone.</span>;
      }

      if (
        !managementAgentReadStatuses.deliveryFailed.isEmpty() ||
        !vendorAgentReadStatuses.deliveryFailed.isEmpty() ||
        !tenantReadStatuses.deliveryFailed.isEmpty() ||
        !ownerReadStatuses.deliveryFailed.isEmpty()
      ) {
        deliveryFailed = CommentUtils.getFormattedReadStatuses(
          "Unable to deliver to",
          managementAgentReadStatuses.deliveryFailed,
          vendorAgentReadStatuses.deliveryFailed,
          tenantReadStatuses.deliveryFailed,
          ownerReadStatuses.deliveryFailed
        );
      } else {
        deliveryFailed = <span>There have been no delivery failures.</span>;
      }

      if (
        !managementAgentReadStatuses.unread.isEmpty() ||
        !vendorAgentReadStatuses.unread.isEmpty() ||
        !tenantReadStatuses.unread.isEmpty() ||
        !ownerReadStatuses.unread.isEmpty()
      ) {
        unread = CommentUtils.getFormattedReadStatuses(
          "Unread by",
          managementAgentReadStatuses.unread,
          vendorAgentReadStatuses.unread,
          tenantReadStatuses.unread,
          ownerReadStatuses.unread
        );
      } else {
        unread = <span>This comment has been delivered to all recipients.</span>;
      }
    }

    return { read, deliveryFailed, unread };
  }

  getReadIndicators() {
    const isManager = LinkHelper.getOrgType() === "m";

    if (isManager) {
      const unreadIconClasses = classNames("info-icon", "unread-indicator", {
        expanded: this.state.expanded === CommentReadIndicators.Unread,
      });

      const deliveryFailedIconClasses = classNames("info-icon", "delivery-failed-indicator", {
        expanded: this.state.expanded === CommentReadIndicators.DeliveryFailed,
      });

      const readIconClasses = classNames("info-icon", "read-indicator", {
        expanded: this.state.expanded === CommentReadIndicators.Read,
      });

      return (
        <div className="comment-read-indicators">
          <div
            className={unreadIconClasses}
            title="Unread"
            onClick={() => this.onClickIndicator(CommentReadIndicators.Unread)}
          >
            <SVGInline className="inline-icon" svg={Envelope} />
          </div>
          <div
            className={deliveryFailedIconClasses}
            title="Delivery failed"
            onClick={() => this.onClickIndicator(CommentReadIndicators.DeliveryFailed)}
          >
            <SVGInline className="inline-icon" svg={DeleteEnvelope} />
          </div>
          <div
            className={readIconClasses}
            title="Read"
            onClick={() => this.onClickIndicator(CommentReadIndicators.Read)}
          >
            <SVGInline className="inline-icon" svg={OpenEnvelope} />
          </div>
        </div>
      );
    }
  }

  handleCommentStoreChange() {
    let status = CommentStore.getStatus("DEFAULT_CID");
    let statusState = status.get("statusState");
    if (statusState === StatusStates.DONE || statusState === StatusStates.FAIL) {
      this.setState({ fetchComplete: true });
    }
  }

  async componentDidMount() {
    CommentStore.listen(this.handleCommentStoreChange);
  }

  componentWillUnmount() {
    CommentStore.unlisten(this.handleCommentStoreChange);
  }
}
