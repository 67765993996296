var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";
class TagApi extends BaseApi {
  static fetchAll({ url, xhrOptions } = {}) {
    if (url) {
      return this.get(url, __spreadValues({ normalizeUrl: false }, xhrOptions));
    }
    return this.get(ApiUrls.meldTagList, xhrOptions);
  }
  static search(query, { tagIdExclusions } = {}) {
    return this.get(ApiUrls.meldTagSearchList, {
      params: {
        query,
        exclude_ids: tagIdExclusions
      }
    });
  }
  static getTags(tags) {
    return this.get(ApiUrls.meldTagList, {
      params: {
        ids: tags.join(",")
      }
    });
  }
  static updateTag(tag) {
    return this.patch(ApiUrls.meldTagDetail(tag.id), tag);
  }
  static deleteTag(id) {
    return this.delete(ApiUrls.meldTagDetail(id));
  }
}
export default TagApi;
