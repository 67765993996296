import EmailAnchor from "../../common/components/email-anchor";
import PhoneAnchor from "../../common/components/phone-anchor";
import PhoneUtils from "../../common/utils/phone-utils";
import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";
import TenantUtils from "../../tenant/utils/tenant-utils";
import Warning from "../../svg/warning-svg";
import WarningPanel from "../../common/components/warning-panel";

let MeldTenantRow = createReactClass({
  render() {
    return (
      <tr className="tenant-row">
        <td data-label="Tenant">{this.props.name}</td>
        <td data-label="Phone">
          <PhoneAnchor number={this.props.phone}>{PhoneUtils.format(this.props.phone)}</PhoneAnchor>
        </td>
        <td data-label="Email" className="long-word">
          <EmailAnchor>{this.props.email}</EmailAnchor>
        </td>
      </tr>
    );
  }
});

let TenantContactsTable = createReactClass({
  propTypes: {
    tenants: PropTypes.any.isRequired
  },

  render() {
    return (
      <div>
        <WarningPanel>
          <span className="warning-panel-svg">
            <Warning />
          </span>
          <span>
            Property Meld was unable to reach the tenants at this location. Please contact them in order to schedule a
            time you both are available to complete the meld.
          </span>
        </WarningPanel>
        <table className="table responsive">
          <thead>
            <tr>
              <th>Tenant</th>
              <th>Phone</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>{this.renderTenantRows()}</tbody>
        </table>
      </div>
    );
  },

  renderTenantRows() {
    return this.props.tenants.map((tenant, i) => {
      return (
        <MeldTenantRow
          name={TenantUtils.getFullName(tenant)}
          phone={TenantUtils.getFirstFoundPhone(tenant)}
          email={TenantUtils.getPrimaryEmail(tenant)}
          key={i}
        />
      );
    });
  }
});

export default TenantContactsTable;
