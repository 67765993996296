var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useMemo, useState, useCallback } from "react";
import { createEditor } from "slate";
import { Editable, Slate, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiTextAlign } from "@elastic/eui";
import { Toolbar } from "./toolbar";
import { withInlines } from "./utils";
import { colors } from "@pm-frontend/styles";
const Element = ({ attributes, children, element }) => {
  const textAlign = element.align;
  switch (element.type) {
    case "heading-one":
      return /* @__PURE__ */ React.createElement(EuiTextAlign, { textAlign }, /* @__PURE__ */ React.createElement("h1", __spreadValues({}, attributes), children));
    case "heading-two":
      return /* @__PURE__ */ React.createElement(EuiTextAlign, { textAlign }, /* @__PURE__ */ React.createElement("h2", __spreadValues({}, attributes), children));
    case "ordered-list":
      return /* @__PURE__ */ React.createElement(EuiTextAlign, { textAlign }, /* @__PURE__ */ React.createElement("ol", __spreadValues({ style: { listStyle: "decimal", listStylePosition: "inside" } }, attributes), children));
    case "unordered-list":
      return /* @__PURE__ */ React.createElement(EuiTextAlign, { textAlign }, /* @__PURE__ */ React.createElement("ul", __spreadValues({ style: { listStyle: "disc", listStylePosition: "inside" } }, attributes), children));
    case "quote":
      return /* @__PURE__ */ React.createElement(EuiTextAlign, { textAlign }, /* @__PURE__ */ React.createElement(
        "div",
        __spreadValues({
          style: {
            borderLeft: "2px solid #ddd",
            paddingLeft: "10px",
            color: colors.neutrals.gray700,
            fontStyle: "italic"
          }
        }, attributes),
        children
      ));
    case "paragraph":
      return /* @__PURE__ */ React.createElement(EuiTextAlign, { textAlign }, /* @__PURE__ */ React.createElement("p", __spreadValues({}, attributes), children));
    case "list-item":
      return /* @__PURE__ */ React.createElement(EuiTextAlign, { textAlign }, /* @__PURE__ */ React.createElement("li", __spreadValues({}, attributes), children));
    case "link":
      return /* @__PURE__ */ React.createElement(EuiLink, __spreadValues({ href: element.url }, attributes), children);
    default:
      return /* @__PURE__ */ React.createElement(React.Fragment, null);
  }
};
const Leaf = ({ attributes, children, leaf }) => {
  let formattedChildren = children;
  if (leaf.bold) {
    formattedChildren = /* @__PURE__ */ React.createElement("span", { style: { fontWeight: "bold" } }, formattedChildren);
  }
  if (leaf.code) {
    formattedChildren = /* @__PURE__ */ React.createElement("pre", null, /* @__PURE__ */ React.createElement("code", null, formattedChildren));
  }
  if (leaf.italic) {
    formattedChildren = /* @__PURE__ */ React.createElement("span", { style: { fontStyle: "italic" } }, formattedChildren);
  }
  if (leaf.underline) {
    formattedChildren = /* @__PURE__ */ React.createElement("span", { style: { textDecoration: "underline" } }, formattedChildren);
  }
  return /* @__PURE__ */ React.createElement("span", __spreadValues({}, attributes), formattedChildren);
};
const PmRichTextEditor = ({ initialValue, readOnly, onChange, tabIndex, dataTestId }) => {
  const [value, setValue] = useState(initialValue);
  const renderElement = useCallback((props) => /* @__PURE__ */ React.createElement(Element, __spreadValues({}, props)), []);
  const renderLeaf = useCallback((props) => /* @__PURE__ */ React.createElement(Leaf, __spreadValues({}, props)), []);
  const editor = useMemo(() => withInlines(withHistory(withReact(createEditor()))), []);
  const onChangeWrapper = (content) => {
    setValue(content);
    if (onChange) {
      onChange(content);
    }
  };
  return /* @__PURE__ */ React.createElement(Slate, { editor, value, onChange: onChangeWrapper }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", alignItems: "stretch", gutterSize: "s" }, !readOnly && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(Toolbar, null)), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true }, /* @__PURE__ */ React.createElement(
    Editable,
    __spreadValues({
      readOnly,
      renderElement,
      renderLeaf,
      spellCheck: true,
      "aria-label": "rich-text-box",
      style: readOnly ? {} : {
        borderRadius: "6px",
        border: `1px solid ${colors.neutrals.gray200}`,
        background: colors.brand.white,
        padding: "8px",
        minHeight: "20vh"
      },
      tabIndex
    }, dataTestId ? { "data-testid": dataTestId } : {})
  ))));
};
export { PmRichTextEditor };
