import React, { MouseEvent } from "react";
import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiText } from "@elastic/eui";
import { Link, matchPath, useLocation } from "react-router-dom";

import { badgeColors, colors } from "@pm-frontend/styles";
import URL from "@pm-shared/utils/url";
import { PmButtonProps, PmFilledButton } from "@pm-frontend/shared/components/Buttons/PmFilledButton";
import { LinkHelper } from "@pm-shared/utils/link";
import { PmBadge } from "@pm-frontend/shared/components/PmBadge";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import { stringify } from "@pm-assets/js/common/utils/location-utils";

interface SidebarRoute {
  text: string;
  badgeText?: string;
  linkProps: {
    to: string;
    queryParams?: Record<string, string | string[]>;
  };
  // routes for which the color of the parent link should change
  highlightRoutes: string[];
  highlightRoutesExact?: boolean;
  // our url structure is diverging from our nav structure, namely around
  // properties, units and owners
  hightlightIgnoreRoutes?: string[];
  dataTestId?: string;
  enabled: boolean;
  onClick?: (e?: MouseEvent) => void;
}

interface ParentItem extends SidebarRoute {
  icon: (arg0: boolean) => React.ReactNode;
  enabled: boolean;
}

interface SidebarRoutes {
  parentItem: ParentItem;
  childrenItems?: SidebarRoute[];
}

const getIsPathMatch = (
  pathname: string,
  globRoutes: string[],
  ignoreRoutes: string[] | undefined,
  highlightExact: boolean | undefined
) => {
  let isPathMatch = globRoutes.some((route) => {
    return matchPath(pathname, {
      path: LinkHelper.normalize(route),
      exact: !!highlightExact,
    });
  });
  if (isPathMatch && ignoreRoutes) {
    return !ignoreRoutes.some((route) => {
      return matchPath(pathname, {
        path: LinkHelper.normalize(route),
        exact: false,
      });
    });
  }
  return isPathMatch;
};

const getUrl = (linkProps: SidebarRoute["linkProps"]): string => {
  const params = linkProps.queryParams ? stringify(linkProps.queryParams) : "";
  return params ? linkProps.to + "?" + params : linkProps.to;
};

type SidebarButtonProps = {
  text: string;
} & PmButtonProps;

interface SidebarButtonPropsItem {
  buttonProps: SidebarButtonProps;
  enabled: boolean;
  paths: string;
  default?: boolean;
}

const getRenderMenuChild = (
  pathname: string,
  isMobile: boolean,
  toggleOpen: () => void
): ((child: SidebarRoute) => React.ReactNode) => {
  return (child) => {
    if (!child.enabled) {
      return null;
    }
    const isHighlighted = getIsPathMatch(
      pathname,
      child.highlightRoutes,
      child.hightlightIgnoreRoutes,
      child.highlightRoutesExact
    );
    const onClick = () => {
      if (isMobile) {
        toggleOpen();
      }
      if (child.onClick) {
        child.onClick();
      }
    };
    return (
      <EuiFlexItem grow={false} key={child.text}>
        <Link to={LinkHelper.normalize(getUrl(child.linkProps))} onClick={onClick} data-testid={child.dataTestId}>
          <EuiFlexGroup
            direction="row"
            justifyContent="spaceBetween"
            gutterSize="xs"
            style={isMobile ? { flexWrap: "nowrap" } : {}}
          >
            <EuiFlexItem grow={true}>
              <EuiText
                color={isHighlighted ? colors.brand.meldBlue : colors.neutrals.gray800}
                size="s"
                style={{ fontWeight: 600 }}
              >
                {child.text}
              </EuiText>
            </EuiFlexItem>
            {child.badgeText && (
              <EuiFlexItem grow={false}>
                <PmBadge
                  data-testid="new-child-badge"
                  bgColor={badgeColors.light.salmon.bg}
                  textColor={badgeColors.light.salmon.text}
                  text={child.badgeText}
                />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </Link>
      </EuiFlexItem>
    );
  };
};

const renderMenuChildren = (
  pathname: string,
  menuOptionChildren: SidebarRoute[],
  isMobile: boolean,
  toggleOpen: () => void
) => {
  return (
    <EuiFlexGroup direction="column" gutterSize="s" style={{ paddingLeft: "24px" }}>
      {menuOptionChildren.map(getRenderMenuChild(pathname, isMobile, toggleOpen))}
    </EuiFlexGroup>
  );
};

const getRenderCollapsedMenuItem = (pathname: string): ((menuOption: SidebarRoutes) => React.ReactNode) => {
  return (menuOption: SidebarRoutes) => {
    if (!menuOption.parentItem.enabled) {
      return null;
    }
    const highlightParentRoute = getIsPathMatch(
      pathname,
      menuOption.parentItem.highlightRoutes,
      menuOption.parentItem.hightlightIgnoreRoutes,
      menuOption.parentItem.highlightRoutesExact
    );

    return (
      <EuiFlexItem grow={false} style={{ height: "24px", justifyContent: "center" }} key={menuOption.parentItem.text}>
        <Link to={LinkHelper.normalize(getUrl(menuOption.parentItem.linkProps))}>
          {menuOption.parentItem.icon(highlightParentRoute)}
        </Link>
      </EuiFlexItem>
    );
  };
};

const renderCollapsedMenuItems = (pathname: string, menuOptions: SidebarRoutes[]) => {
  return (
    <EuiFlexGroup direction="column" alignItems="center" justifyContent="center" style={{ gap: "12px" }}>
      {menuOptions.map(getRenderCollapsedMenuItem(pathname))}
    </EuiFlexGroup>
  );
};

const getRenderMenuOption = (
  pathname: string,
  isMobile: boolean,
  toggleOpen: () => void
): ((menuOption: SidebarRoutes) => React.ReactNode) => {
  return (menuOption: SidebarRoutes) => {
    if (!menuOption.parentItem.enabled) {
      return null;
    }
    const highlightParentRoute = getIsPathMatch(
      pathname,
      menuOption.parentItem.highlightRoutes,
      menuOption.parentItem.hightlightIgnoreRoutes,
      menuOption.parentItem.highlightRoutesExact
    );

    const hasChildBadge = menuOption.childrenItems?.some((child) => child.badgeText);
    const shouldShowParentBadge: boolean =
      !!(menuOption.parentItem.badgeText && !highlightParentRoute && !isMobile) ||
      !!(menuOption.parentItem.badgeText && highlightParentRoute && !hasChildBadge && !isMobile) ||
      !!(menuOption.parentItem.badgeText && isMobile && !hasChildBadge);

    const parsedParentItem = (
      <EuiFlexGroup direction="column" gutterSize="xs">
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" responsive={false} justifyContent="spaceBetween">
            <EuiFlexItem grow={false}>
              <EuiFlexGroup
                direction="row"
                responsive={false}
                gutterSize="s"
                alignItems="center"
                style={{ height: "24px" }}
              >
                <EuiFlexItem grow={false}>{menuOption.parentItem.icon(highlightParentRoute)}</EuiFlexItem>
                <EuiFlexItem grow={true}>
                  <EuiText
                    size="s"
                    color={highlightParentRoute ? colors.brand.meldBlue : colors.neutrals.gray800}
                    style={{ fontWeight: 600 }}
                  >
                    {menuOption.parentItem.text}
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            {shouldShowParentBadge && (
              <EuiFlexItem grow={false}>
                <PmBadge
                  bgColor={badgeColors.light.salmon.bg}
                  textColor={badgeColors.light.salmon.text}
                  text={menuOption.parentItem.badgeText ?? ""}
                  data-testid="new-parent-badge"
                />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    );

    let showChildren = highlightParentRoute;
    if (isMobile) {
      // no reason to show sub routes if there is only one enabled child
      // additionally, showing all children on mobile to avoid having to click
      // multiple times
      showChildren = (menuOption.childrenItems?.filter((child) => child.enabled) || []).length > 1;
    }

    const parsedChildren =
      menuOption.childrenItems && showChildren ? (
        <EuiFlexItem grow={false}>
          {renderMenuChildren(pathname, menuOption.childrenItems, isMobile, toggleOpen)}
        </EuiFlexItem>
      ) : null;

    if (menuOption.parentItem.linkProps) {
      return (
        <React.Fragment key={menuOption.parentItem.text}>
          <Link
            to={LinkHelper.normalize(getUrl(menuOption.parentItem.linkProps))}
            key={menuOption.parentItem.text}
            style={{ cursor: "pointer" }}
            onClick={isMobile ? toggleOpen : undefined}
            data-testid={menuOption.parentItem.dataTestId}
          >
            {parsedParentItem}
          </Link>
          {parsedChildren}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment key={menuOption.parentItem.text}>
        {parsedParentItem}
        {parsedChildren}
      </React.Fragment>
    );
  };
};

const getPrimaryButtonProps = (
  pathname: string,
  buttonPropsList: SidebarButtonPropsItem[]
): SidebarButtonProps | null => {
  let button = buttonPropsList.find((buttonProps) => !!matchPath(pathname, { path: buttonProps.paths }));
  if (button && button.enabled) {
    return button.buttonProps;
  }

  let defaultButton = buttonPropsList.find((buttonProps) => buttonProps.default);
  if (defaultButton && defaultButton.enabled) {
    return defaultButton.buttonProps;
  }
  return null;
};

interface ApplicationSidebarBaseProps {
  isOpen: boolean;
  toggleOpen: () => void;
  primaryButtonList?: SidebarButtonPropsItem[];
  menuOptions: SidebarRoutes[];
  mobileItems?: React.ReactNodeArray;
  desktopItems?: React.ReactNodeArray;
}

const ApplicationSidebarBase = ({
  isOpen,
  toggleOpen,
  primaryButtonList,
  menuOptions,
}: ApplicationSidebarBaseProps) => {
  const location = useLocation();
  const isMobile = useIsMobile();

  const primaryButtonProps = primaryButtonList ? getPrimaryButtonProps(location.pathname, primaryButtonList) : null;

  // desktop sidebar - collapsed view (mobile never gets here if !isOpen)
  if (!isOpen) {
    return (
      <EuiFlexGroup
        direction="column"
        alignItems="flexStart"
        style={{ gap: "16px", paddingTop: "32px", flexWrap: "unset" }}
      >
        {renderCollapsedMenuItems(location.pathname, menuOptions)}
      </EuiFlexGroup>
    );
  }

  // full sidebar view
  return (
    <>
      <EuiFlexItem grow={false} style={{ minWidth: "210px" }}>
        {primaryButtonProps && (
          <EuiFlexGroup
            direction="row"
            alignItems="center"
            justifyContent="spaceBetween"
            gutterSize="s"
            style={{ minHeight: "32px" }}
          >
            <EuiFlexItem grow={true} onClick={isMobile ? toggleOpen : undefined} style={{ maxWidth: "206px" }}>
              {primaryButtonProps.onClick && (
                <PmFilledButton
                  text={primaryButtonProps.text}
                  onClick={primaryButtonProps.onClick}
                  isLoading={false}
                  fontSize="14px"
                  fullWidth={true}
                  padding="6px 12px"
                  data-testid="manager-hub-sidebar-primary-cta-button"
                />
              )}
              {primaryButtonProps.href && (
                <PmFilledButton
                  text={primaryButtonProps.text}
                  href={primaryButtonProps.href}
                  internalLink={true}
                  isLoading={false}
                  fontSize="14px"
                  fullWidth={true}
                  padding="6px 12px"
                  data-testid="manager-hub-sidebar-primary-cta-button"
                />
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction="column" data-testid="management-hub-sidebar" style={{ gap: "12px" }}>
          {menuOptions.map(getRenderMenuOption(location.pathname, isMobile, toggleOpen))}
          {!isMobile && (
            <EuiFlexGroup
              direction="row"
              responsive={false}
              gutterSize="s"
              alignItems="center"
              style={{ height: "24px" }}
            >
              <EuiFlexItem grow={false}>
                <EuiButtonIcon
                  iconType={URL.getStatic("icons/menu_open-1.svg")}
                  iconSize="original"
                  onClick={toggleOpen}
                  aria-label="Close sidebar menu"
                  style={{ width: "20px", height: "20px" }}
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText
                  size="s"
                  style={{ fontWeight: 600, cursor: "pointer" }}
                  onClick={toggleOpen}
                  color={colors.neutrals.gray800}
                >
                  Hide navigation
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
    </>
  );
};
export { ApplicationSidebarBase, ApplicationSidebarBaseProps, SidebarRoute, SidebarRoutes, SidebarButtonPropsItem };
