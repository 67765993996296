import I from "immutable";

import alt from "../../alt";
import BaseStore from "../../stores/base-store";
import messageActions from "../actions/message-actions";

export let MessageTypes = {
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
  EXCEPTION: "EXCEPTION",
  INFO: "INFO"
};

export let Message = I.Record({
  type: null,
  body: ""
});

export class MessageStore extends BaseStore {
  constructor(options) {
    super();

    this.bindListeners({
      markOldest: messageActions.MARK_OLDEST,
      storeError: messageActions.STORE_ERROR,
      storeSuccess: messageActions.STORE_SUCCESS,
      storeInfo: messageActions.STORE_INFO
    });

    this.exportPublicMethods({
      getNext: this.getNext.bind(this)
    });
  }

  getInitialModelState() {
    return I.OrderedSet();
  }

  markOldest() {
    this.setModelState(this.getModelState().rest() || I.fromJS([]));
  }

  getNext() {
    return this.getModelState().first();
  }

  storeError({ body }) {
    this.setModelState(this.getModelState().add(new Message({ body, type: MessageTypes.ERROR })));
  }

  storeSuccess({ body }) {
    this.setModelState(this.getModelState().add(new Message({ body, type: MessageTypes.SUCCESS })));
  }

  storeInfo({ body }) {
    this.setModelState(this.getModelState().add(new Message({ body, type: MessageTypes.INFO })));
  }
}

export default alt.createStore(MessageStore, "MessageStore");
