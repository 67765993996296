import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from "@elastic/eui";
import { Link } from "react-router-dom";

import { colors } from "@pm-frontend/styles";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";

interface MenuOption {
  linkType: "internal" | "external";
  enabled: boolean;
  active: boolean;
  text: string;
  icon: React.ReactNode;
  href: string;
}

const renderMenuOption = (menuOption: MenuOption, toggleMenu: () => void, isMobile: boolean) => {
  if (!menuOption.enabled) {
    return null;
  }
  if (menuOption.linkType === "internal") {
    return (
      <EuiFlexItem grow={false} key={menuOption.text}>
        <Link to={menuOption.href} onClick={toggleMenu}>
          <EuiFlexGroup direction="row" responsive={false} alignItems="center" style={{ gap: "8px" }}>
            <EuiFlexItem grow={false} style={{ width: "16px" }}>
              {typeof menuOption.icon === "string" ? (
                <EuiIcon type={menuOption.icon} size="original" style={{ fill: colors.brand.meldBlue }} />
              ) : (
                menuOption.icon
              )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText
                color={menuOption.active ? colors.brand.meldBlue : colors.neutrals.gray800}
                size="s"
                style={{ fontWeight: isMobile ? 600 : 400 }}
              >
                {menuOption.text}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </Link>
      </EuiFlexItem>
    );
  }
  if (menuOption.linkType === "external") {
    return (
      <EuiFlexItem grow={false} key={menuOption.text}>
        <a href={menuOption.href}>
          <EuiFlexGroup direction="row" responsive={false} alignItems="center" style={{ gap: "8px" }}>
            <EuiFlexItem grow={false} style={{ width: "16px" }}>
              {typeof menuOption.icon === "string" ? (
                <EuiIcon type={menuOption.icon} size="original" style={{ fill: colors.brand.meldBlue }} />
              ) : (
                menuOption.icon
              )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText
                color={menuOption.active ? colors.brand.meldBlue : colors.neutrals.gray800}
                size="s"
                style={{ fontWeight: isMobile ? 600 : 400 }}
              >
                {menuOption.text}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </a>
      </EuiFlexItem>
    );
  }
};

interface SettingsOptionsProps {
  menuOptions: MenuOption[];
  toggleMenu: () => void;
}

/**
 * This is a generic component for showing options in a settings menu
 */
const SettingsOptions = ({ toggleMenu, menuOptions }: SettingsOptionsProps) => {
  const isMobile = useIsMobile();

  return (
    <EuiFlexGroup direction="column" style={{ gap: "16px" }}>
      <EuiFlexItem grow={false}>
        <EuiText color={colors.neutrals.gray900} size="s" style={{ fontWeight: 700 }}>
          Account Options
        </EuiText>
      </EuiFlexItem>
      {menuOptions.map((menuOption) => renderMenuOption(menuOption, toggleMenu, isMobile))}
    </EuiFlexGroup>
  );
};

export { SettingsOptions, MenuOption };
