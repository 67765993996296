import alt from "../../alt";
import AssignmentRequestAPI from "../apis/assignment-request-api";

class AssignmentRequestActions {
  constructor() {
    this.generateActions(
      "fetchPending",
      "fetchPendingDone",
      "fetchPendingFail",
      "rejectDone",
      "rejectFail",
      "acceptDone",
      "acceptFail",
      "updateSegmentsDone",
      "updateSegmentsFail",
      "fetchOne",
      "fetchOneDone",
      "fetchOneFail"
    );
  }

  reject(id, reason, cid) {
    AssignmentRequestAPI.vendorReject(id, reason)
      .then(res => {
        this.rejectDone({ data: res.data, id });
      })
      .catch(res => {
        this.rejectFail({});
      });

    return { id, cid };
  }

  accept(id, data, { cid, options = {} } = {}) {
    AssignmentRequestAPI.vendorAccept(id, data, options)
      .then(res => {
        this.acceptDone({ data: res, cid });
      })
      .catch(res => {
        this.acceptFail({ data: res, cid });
      });

    return { id, cid };
  }

  updateSegments(id, data, { cid, options = {} } = {}) {
    AssignmentRequestAPI.updateSegments(id, data, options)
      .then(res => {
        this.updateSegmentsDone({ data: res, cid });
      })
      .catch(res => {
        this.updateSegmentsFail({ data: res, cid });
      });

    return { id, cid };
  }
}

export default alt.createActions(AssignmentRequestActions);
