import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";

class CardApi extends BaseApi {
  static fetchAll() {
    return this.get(ApiUrls.subscriptionCards);
  }

  static fetchDefault() {
    return this.get(ApiUrls.defaultCard);
  }

  static updateCard(data: any) {
    return this.put(ApiUrls.subscriptionCards, data);
  }

  static fetchVendorCards() {
    return this.get(ApiUrls.vendorCards);
  }

  static deleteVendorCard(cardId: number) {
    return this.delete(ApiUrls.vendorCardDetail(cardId));
  }
}

export default CardApi;
