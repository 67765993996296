var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const WorklogCreated = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement("path", { d: "M35.12 20.82a1 1 0 00-1.42-.05l-10 9.32-4.32-4.66a1 1 0 00-1.47 1.36l5.69 6.12 11.46-10.68a1 1 0 00.06-1.41zM9 29.23H3a1 1 0 01-1-1V3a1 1 0 011-1h16a1 1 0 011 1v6a1 1 0 002 0V3a3 3 0 00-3-3H3a3 3 0 00-3 3v25.23a3 3 0 003 3h6a1 1 0 100-2z" }), /* @__PURE__ */ React.createElement("path", { d: "M26 12a14 14 0 1014 14 14 14 0 00-14-14zm0 26a12 12 0 1112-12 12 12 0 01-12 12zM16 5H6a1 1 0 000 2h10a1 1 0 000-2zM16 10H6a1 1 0 000 2h10a1 1 0 000-2zM11 15H6a1 1 0 000 2h5a1 1 0 000-2z" }));
