import React from "react";
import { Radio, Props as RadioProps } from "./radio";
import { Field, FieldProps } from "formik";

interface Props extends RadioProps {
  /**
   * Gets passed to formik's <Field /> component.
   * Used to match the field to form value for validation and state management.
   */
  match: string;
}

export const FormikRadio: React.FC<Props> = ({ match, ...rest }) => (
  <Field name={match}>
    {({ field, meta }: FieldProps) => (
      <Radio errors={meta.error !== undefined} {...field} {...rest} checked={rest.value === meta.value} />
    )}
  </Field>
);

FormikRadio.displayName = "FormikRadio";
