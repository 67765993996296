import React, { Component } from "react";

import Tag from "../../svg/tag-svg";
import { ImmutableMap } from "@pm-assets/js/types/common";

interface Props {
  meld: ImmutableMap;
}

class MeldCardTags extends Component<Props> {
  render() {
    let tags = this.props.meld.get("tags");
    if (tags.count()) {
      return (
        <div className="card-attributes">
          <span className="card-attribute card-accent">
            <span className="card-icon">
              <Tag />
            </span>
            <span>
              {tags.map((tag: ImmutableMap) => (
                <span className="tag pilled square" key={tag.get("name")}>
                  {tag.get("name")}
                </span>
              ))}
            </span>
          </span>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default MeldCardTags;
