import { create } from "zustand";

interface GlobalBannerState {
  isVisible: boolean;
  setIsVisible: (arg0: boolean) => void;
}

const useBannerStore = create<GlobalBannerState>((set) => ({
  isVisible: true,
  setIsVisible: (newVisibility: boolean) => {
    set({ isVisible: newVisibility });
  },
}));

const useGetGlobalBannerVisibility = () => {
  return useBannerStore((state) => state.isVisible);
};

const useSetGlobalBannerVisibility = () => {
  return useBannerStore((state) => state.setIsVisible);
};

export { useBannerStore, useGetGlobalBannerVisibility, useSetGlobalBannerVisibility };
