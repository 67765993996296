var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import moment from "moment";
import { create } from "zustand";
import { addDays, startOfDay, subDays } from "date-fns";
import { shouldShowCalendarRedesignDueToDesktop } from "@pm-assets/js/utils/redesign-routing";
const TIMEFRAME_LOCAL_STORAGE_KEY = `meld-calendar-timeframe-${window.PM.user.id}-${window.PM.user.multitenantId}`;
const TIMEFRAMES = {
  ONE_DAY: 1,
  THREE_DAY: 3,
  FIVE_DAY: 5
};
const setInitialSelectedTimeFrame = (timeframe) => {
  for (const entry of Object.entries(TIMEFRAMES)) {
    if (timeframe === entry[1]) {
      window.localStorage.setItem(TIMEFRAME_LOCAL_STORAGE_KEY, entry[0]);
      return;
    }
  }
};
const getInitialSelectedTimeFrame = () => {
  const value = window.localStorage.getItem(TIMEFRAME_LOCAL_STORAGE_KEY);
  if (!value) {
    return 1;
  }
  return TIMEFRAMES[value] || 1;
};
const CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY = `meld-calendar-selected-agents-${window.PM.user.id}-${window.PM.user.multitenantId}`;
const setInitialSelectedVendorsOrAgents = (state) => window.localStorage.setItem(CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY, JSON.stringify(state));
const getInitialSelectedVendorsOrAgents = () => {
  const value = window.localStorage.getItem(CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY);
  let result = { agents: [], vendors: [] };
  if (value) {
    try {
      const parsed = JSON.parse(value);
      if (Array.isArray(parsed.agents) && Array.isArray(parsed.vendors)) {
        result = parsed;
      }
    } catch (e) {
    }
  }
  return result;
};
const useCalendarStateStore = create((set) => ({
  activePane: shouldShowCalendarRedesignDueToDesktop.matches ? { type: "meldsToSchedule" } : { type: "mobile-null" },
  selectedDate: startOfDay(/* @__PURE__ */ new Date()).valueOf(),
  calendarOrMap: "calendar",
  selectedCalendarTimeFrame: getInitialSelectedTimeFrame(),
  selectedVendorsAgentsIds: getInitialSelectedVendorsOrAgents(),
  scheduledSegments: [],
  dragAndDropState: null,
  pendingResdientAvailabilities: [],
  pendingRecommendedMeld: null,
  altEventSelectedAgentsTime: null,
  mapEventListRightpaneItems: [],
  priorRightPaneType: null,
  actions: {
    setPriorRightpaneType: (type) => set({ priorRightPaneType: type }),
    setPendingRecommendEvent: (event) => set({ pendingRecommendedMeld: event }),
    setmapEventListRightpaneItems: (items) => set({ mapEventListRightpaneItems: items }),
    setSelectedDate: (newDate) => {
      set({ selectedDate: newDate, pendingRecommendedMeld: null });
    },
    incrementDate: () => set((state) => ({
      selectedDate: addDays(new Date(state.selectedDate), state.selectedCalendarTimeFrame).valueOf(),
      pendingRecommendedMeld: null
    })),
    decrementDate: () => set((state) => ({
      selectedDate: subDays(new Date(state.selectedDate), state.selectedCalendarTimeFrame).valueOf(),
      pendingRecommendedMeldPlaceholder: null
    })),
    setDateToToday: () => set({
      selectedDate: startOfDay(/* @__PURE__ */ new Date()).valueOf(),
      pendingRecommendedMeld: null
    }),
    setCalendarSelectedTimeFrame: (newTimeFrame) => {
      setInitialSelectedTimeFrame(newTimeFrame);
      set({
        selectedCalendarTimeFrame: newTimeFrame,
        // for simplicity on timeframe change we just clear out any recommended meld events
        pendingRecommendedMeld: null
      });
    },
    setSelectedVendorsAgents: (selected) => {
      setInitialSelectedVendorsOrAgents(selected);
      set((state) => {
        if (state.pendingRecommendedMeld) {
          const currentAgentId = state.pendingRecommendedMeld.personaId;
          if (!selected.agents.some((agentId) => agentId === currentAgentId)) {
            return { selectedVendorsAgentsIds: selected, activePane: { type: "meldsToSchedule" } };
          }
        }
        return { selectedVendorsAgentsIds: selected };
      });
    },
    addSelectedVendorsAgents: (agentsVendorsToAdd) => {
      set((state) => {
        const result = {
          agents: state.selectedVendorsAgentsIds.agents,
          vendors: state.selectedVendorsAgentsIds.vendors
        };
        agentsVendorsToAdd.agents.forEach((agent) => {
          if (!result.agents.includes(agent)) {
            result.agents.push(agent);
          }
        });
        agentsVendorsToAdd.vendors.forEach((vendor) => {
          if (!result.vendors.includes(vendor)) {
            result.vendors.push(vendor);
          }
        });
        return { selectedVendorsAgentsIds: result };
      });
    },
    resetState: () => set({
      altEventSelectedAgentsTime: null,
      pendingRecommendedMeld: null,
      pendingResdientAvailabilities: [],
      priorRightPaneType: null,
      mapEventListRightpaneItems: [],
      selectedDate: startOfDay(/* @__PURE__ */ new Date()).valueOf(),
      dragAndDropState: null
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addScheduledSegment: (segment) => set((state) => ({
      scheduledSegments: [...state.scheduledSegments, segment]
    })),
    removeScheduledSegment: (id) => set((state) => ({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      scheduledSegments: state.scheduledSegments.filter((segment) => segment.id !== id)
    })),
    startDraggingMeld: () => set({ dragAndDropState: "draggingMeld" }),
    startDraggingEvent: () => set({ dragAndDropState: "draggingEvent" }),
    stopDragging: () => set({ dragAndDropState: null }),
    startResizingEvent: () => set({ dragAndDropState: "resizingEvent" }),
    stopResizingEvent: () => set({ dragAndDropState: null }),
    // pending resident availability placeholders
    addPendingResidentAvailability: (pendingEventsToAdd) => set((state) => {
      return {
        pendingResdientAvailabilities: [
          ...state.pendingResdientAvailabilities,
          ...pendingEventsToAdd.map(
            (event) => ({
              type: "pending_offered_availability",
              start: event.start,
              end: event.end,
              startDate: new Date(event.start),
              endDate: new Date(event.end),
              start_moment: moment(event.start),
              end_moment: moment(event.end),
              description: "Offer this time to resident",
              personaId: event.agentId,
              personaType: "agent",
              key: event.tempId + event.agentId,
              tempId: event.tempId
            })
          )
        ]
      };
    }),
    removePendingResidentAvailability: (tempId) => set((state) => {
      return {
        pendingResdientAvailabilities: [...state.pendingResdientAvailabilities].filter(
          (e) => e.type !== "pending_offered_availability" || e.tempId !== tempId
        )
      };
    }),
    upsertPendingResidentAvailability: ({ tempId, start, end, agents }) => set((state) => {
      let updatedAnEvent = false;
      const updatedAvailabilities = state.pendingResdientAvailabilities.map((existingEvent) => {
        if (existingEvent.type !== "pending_offered_availability" || existingEvent.tempId !== tempId) {
          return existingEvent;
        }
        updatedAnEvent = true;
        const updatedEvent = __spreadValues({}, existingEvent);
        updatedEvent.end_moment = end.clone();
        updatedEvent.end = end.toISOString();
        updatedEvent.start_moment = start.clone();
        updatedEvent.start = start.toISOString();
        return updatedEvent;
      });
      if (!updatedAnEvent) {
        agents.forEach((agent) => {
          updatedAvailabilities.push({
            type: "pending_offered_availability",
            start: start.toISOString(),
            startDate: start.toDate(),
            end: end.toISOString(),
            endDate: end.toDate(),
            start_moment: start,
            end_moment: end,
            description: "Offer this time to resident",
            personaId: agent.id,
            personaType: "agent",
            key: tempId + agent.id,
            tempId
          });
        });
      }
      return { pendingResdientAvailabilities: updatedAvailabilities };
    }),
    clearPendingResidentAvailabilities: () => set({ pendingResdientAvailabilities: [] }),
    // click to add alt events
    setAltEventSelectedAgentsTime: ({ newAgents, newSelectedTime, eventId, eventDescription }) => set((state) => {
      var _a, _b, _c, _d, _e, _f;
      const selectedAgents = newAgents || ((_a = state.altEventSelectedAgentsTime) == null ? void 0 : _a.selectedAgents) || [];
      const selectedDate = (newSelectedTime && "date" in newSelectedTime ? newSelectedTime.date : (_b = state.altEventSelectedAgentsTime) == null ? void 0 : _b.selectedTime.date) || /* @__PURE__ */ new Date();
      const selectedStart = newSelectedTime && "start" in newSelectedTime ? newSelectedTime.start : (_c = state.altEventSelectedAgentsTime) == null ? void 0 : _c.selectedTime.start;
      const selectedEnd = newSelectedTime && "end" in newSelectedTime ? newSelectedTime.end : (_d = state.altEventSelectedAgentsTime) == null ? void 0 : _d.selectedTime.end;
      if (!newAgents && !newSelectedTime) {
        return { altEventSelectedAgentsTime: null };
      }
      return {
        altEventSelectedAgentsTime: {
          selectedTime: { date: selectedDate, start: selectedStart, end: selectedEnd },
          eventId: eventId || ((_e = state.altEventSelectedAgentsTime) == null ? void 0 : _e.eventId),
          eventDescription: eventDescription || ((_f = state.altEventSelectedAgentsTime) == null ? void 0 : _f.eventDescription),
          selectedAgents
        }
      };
    }),
    clearAltEventSelectedAgentsTime: (removeEvent = false) => {
      if (removeEvent) {
        set(() => ({ altEventSelectedAgentsTime: null }));
      } else {
        set((state) => {
          var _a, _b;
          return {
            altEventSelectedAgentsTime: __spreadProps(__spreadValues({}, state.altEventSelectedAgentsTime || []), {
              selectedAgents: ((_a = state.altEventSelectedAgentsTime) == null ? void 0 : _a.selectedAgents) || [],
              selectedTime: {
                date: ((_b = state.altEventSelectedAgentsTime) == null ? void 0 : _b.selectedTime.date) || /* @__PURE__ */ new Date(),
                start: void 0,
                end: void 0
              }
            })
          };
        });
      }
    }
  }
}));
const useCalendarStatePriorRightpaneType = () => useCalendarStateStore((state) => state.priorRightPaneType);
const useCalendarStatePendingRecommendedMeld = () => useCalendarStateStore((state) => state.pendingRecommendedMeld);
const useCalendarStateAltEventSelectedAgentsTime = () => useCalendarStateStore((state) => state.altEventSelectedAgentsTime);
const useCalendarStateMapEventListRightpaneItems = () => useCalendarStateStore((state) => state.mapEventListRightpaneItems);
const useCalendarStatePendingOfferedAvailabilities = () => useCalendarStateStore((state) => state.pendingResdientAvailabilities);
const useCalendarStateSelectedDate = () => useCalendarStateStore((state) => state.selectedDate);
const useCalendarStateSelectedTimeFrame = (isMobile) => useCalendarStateStore((state) => {
  if (isMobile) {
    return 1;
  }
  return state.selectedCalendarTimeFrame;
});
const useCalendarStateSelectedVendorAgentIds = (isMobile) => useCalendarStateStore((state) => {
  if (isMobile) {
    if (state.selectedVendorsAgentsIds.agents.length > 0) {
      return { agents: [state.selectedVendorsAgentsIds.agents[0]], vendors: [] };
    } else if (state.selectedVendorsAgentsIds.vendors.length > 0) {
      return { agents: [], vendors: [state.selectedVendorsAgentsIds.vendors[0]] };
    }
  }
  return state.selectedVendorsAgentsIds;
});
const useCalendarStateActions = () => useCalendarStateStore((state) => state.actions);
const useCalendarStateScheduledSegments = () => useCalendarStateStore((state) => state.scheduledSegments);
const useCalendarDragAndDropState = () => useCalendarStateStore((state) => state.dragAndDropState);
export {
  useCalendarStatePriorRightpaneType,
  useCalendarStatePendingOfferedAvailabilities,
  useCalendarStateMapEventListRightpaneItems,
  useCalendarStateAltEventSelectedAgentsTime,
  useCalendarStatePendingRecommendedMeld,
  useCalendarStateActions,
  useCalendarStateSelectedDate,
  useCalendarStateSelectedTimeFrame,
  useCalendarStateSelectedVendorAgentIds,
  useCalendarStateScheduledSegments,
  useCalendarDragAndDropState,
  CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY
};
