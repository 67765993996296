var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { ReactRouterDomRoutes } from "@pm-assets/js/utils/redesign-routing";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import {
  useCalendarStateActions,
  useCalendarStateAltEventSelectedAgentsTime,
  useCalendarStatePendingRecommendedMeld,
  useCalendarStateSelectedDate,
  useCalendarStateSelectedTimeFrame
} from "../calendarStateStore";
import { differenceInDays, startOfDay } from "date-fns";
import {
  isValidCompositeId,
  isMaintenanceManagementAgent
} from "@pm-frontend/shared/utils/assignment-utils";
import { CalendarMeldMapOpened, track } from "@pm-app/utils/analytics";
const CALENDAR_URL_PARAM_KEYS = {
  EVENT_ID: "event_id",
  AGENT_ID: "agent_id",
  PANE: "pane",
  MAP_OPEN: "map",
  // saved filters
  FILTER_ID: "filter_id",
  FILTER_TAB: "filter_tab"
};
const CALENDAR_URL_PANE_PARAM_VALUES = {
  SAVED_FILTERS: "saved_filters",
  MELDS_LIST: "melds_list",
  RECOMMEND_MELDS: "recommend",
  ALTERNATIVE_EVENTS: "altevent",
  GOOGLE_EVENT: "google_event",
  OUTLOOK_EVENT: "outlook_event",
  MAP_EVENT_LIST: "map_event_list"
};
const LARGE_MAP_QUERY_PARAM_VALUE = "large_map";
const CALENDAR_PANE_TYPES = {
  MELD_DETAILS: "meldDetails",
  OFFER_AVAILABILITIES: "offerAvailabilities",
  NULL: "null",
  MELD_LIST: "meldsList",
  MELD_FILTERS: "meldFilters",
  RECOMMEND: "recommendedMelds",
  ALTERNATIVE_EVENT: "alternativeEvent",
  GOOGLE_EVENT: "googleCalendarEvent",
  OUTLOOK_EVENT: "outlookCalendarEvent",
  MAP_EVENT_LIST: "mapEventList"
};
const getSearchParamsFromCalendarPaneState = ({
  newRightpaneState,
  newMapState,
  currentMapState,
  location,
  isMobile
}) => {
  const search = new URLSearchParams(location.search);
  Object.values(CALENDAR_URL_PARAM_KEYS).forEach((key) => {
    search.delete(key);
  });
  let pathname = LinkHelper.normalize(RouteUrls.meldCalendarList);
  switch (newRightpaneState.type) {
    case CALENDAR_PANE_TYPES.MELD_DETAILS:
      pathname = LinkHelper.normalize(RouteUrls.meldCalendar({ id: newRightpaneState.meldId }));
      break;
    case CALENDAR_PANE_TYPES.OFFER_AVAILABILITIES:
      pathname = LinkHelper.normalize(RouteUrls.meldCalendarMeldAvailabilities({ id: newRightpaneState.meldId }));
      break;
    case CALENDAR_PANE_TYPES.MELD_FILTERS:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.SAVED_FILTERS);
      if (newRightpaneState.mode === "edit") {
        search.set(CALENDAR_URL_PARAM_KEYS.FILTER_ID, newRightpaneState.filterId);
      }
      search.set(CALENDAR_URL_PARAM_KEYS.FILTER_TAB, newRightpaneState.tab);
      break;
    case CALENDAR_PANE_TYPES.RECOMMEND:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.RECOMMEND_MELDS);
      break;
    case CALENDAR_PANE_TYPES.GOOGLE_EVENT:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.GOOGLE_EVENT);
      search.set(CALENDAR_URL_PARAM_KEYS.EVENT_ID, newRightpaneState.eventId);
      break;
    case CALENDAR_PANE_TYPES.OUTLOOK_EVENT:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.OUTLOOK_EVENT);
      search.set(CALENDAR_URL_PARAM_KEYS.EVENT_ID, newRightpaneState.eventId);
      break;
    case CALENDAR_PANE_TYPES.MAP_EVENT_LIST:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.MAP_EVENT_LIST);
      break;
    case CALENDAR_PANE_TYPES.ALTERNATIVE_EVENT:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.ALTERNATIVE_EVENTS);
      if (newRightpaneState.mode === "edit") {
        search.set(CALENDAR_URL_PARAM_KEYS.EVENT_ID, newRightpaneState.eventId);
      }
      break;
    case CALENDAR_PANE_TYPES.NULL:
      break;
    case CALENDAR_PANE_TYPES.MELD_LIST:
      if (isMobile) {
        search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.MELDS_LIST);
      }
      if (newRightpaneState.filterId) {
        search.set("saved_filter", newRightpaneState.filterId.toString());
      }
      break;
    default:
      break;
  }
  if (newMapState && newMapState !== "calendar") {
    search.set(CALENDAR_URL_PARAM_KEYS.MAP_OPEN, newMapState);
  } else if (!newMapState && currentMapState !== "calendar") {
    search.set(CALENDAR_URL_PARAM_KEYS.MAP_OPEN, currentMapState);
  }
  return { search: search.toString(), pathname };
};
const getCalendarOrMapState = (searchParams, isMobile) => {
  if (isMobile) {
    return "calendar";
  }
  const queryParamValue = searchParams.get(CALENDAR_URL_PARAM_KEYS.MAP_OPEN);
  if (!queryParamValue) {
    return "calendar";
  } else if (queryParamValue === LARGE_MAP_QUERY_PARAM_VALUE) {
    return LARGE_MAP_QUERY_PARAM_VALUE;
  } else if (isValidCompositeId(queryParamValue)) {
    return queryParamValue;
  }
  return "calendar";
};
const useGetSetActivePaneAndMap = () => {
  const eventMetaData = useGetCalendarEventMetaData();
  const isMobile = useIsMobile();
  const { setPriorRightpaneType, setPendingRecommendEvent, clearAltEventSelectedAgentsTime } = useCalendarStateActions();
  const pendingRecommendedEvent = useCalendarStatePendingRecommendedMeld();
  const pendingAltEvent = useCalendarStateAltEventSelectedAgentsTime();
  const detailsPaneMatch = useRouteMatch({ path: ReactRouterDomRoutes.meldCalendarMeldDetails });
  const availabilitiesPaneMatch = useRouteMatch(
    ReactRouterDomRoutes.meldCalendarMeldAvailabilities
  );
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const calendarOrMap = getCalendarOrMapState(searchParams, isMobile);
  const paneUrlValue = searchParams.get(CALENDAR_URL_PARAM_KEYS.PANE);
  let rightPaneState = { type: "null" };
  if (availabilitiesPaneMatch) {
    rightPaneState = { type: "offerAvailabilities", meldId: availabilitiesPaneMatch.params.meldId };
  } else if (detailsPaneMatch) {
    rightPaneState = { type: "meldDetails", meldId: detailsPaneMatch.params.meldId };
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.ALTERNATIVE_EVENTS) {
    const eventId = searchParams.get(CALENDAR_URL_PARAM_KEYS.EVENT_ID);
    if (eventId) {
      rightPaneState = { type: "alternativeEvent", mode: "edit", eventId };
    } else {
      rightPaneState = { type: "alternativeEvent", mode: "add" };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.GOOGLE_EVENT) {
    const eventId = searchParams.get(CALENDAR_URL_PARAM_KEYS.EVENT_ID);
    if (eventId) {
      rightPaneState = { type: "googleCalendarEvent", eventId };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.OUTLOOK_EVENT) {
    const eventId = searchParams.get(CALENDAR_URL_PARAM_KEYS.EVENT_ID);
    if (eventId) {
      rightPaneState = { type: "outlookCalendarEvent", eventId };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.MELDS_LIST) {
    rightPaneState = { type: CALENDAR_PANE_TYPES.MELD_LIST };
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.SAVED_FILTERS) {
    const filterId = searchParams.get(CALENDAR_URL_PARAM_KEYS.FILTER_ID);
    const tab = searchParams.get(CALENDAR_URL_PARAM_KEYS.FILTER_TAB) === "shared" ? "shared" : "private";
    if (filterId) {
      rightPaneState = { type: CALENDAR_PANE_TYPES.MELD_FILTERS, tab, mode: "edit", filterId };
    } else {
      rightPaneState = { type: CALENDAR_PANE_TYPES.MELD_FILTERS, mode: "view", tab };
    }
  } else if (isMobile) {
    rightPaneState = { type: "null" };
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.RECOMMEND_MELDS) {
    rightPaneState = { type: CALENDAR_PANE_TYPES.RECOMMEND };
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.MAP_EVENT_LIST) {
    rightPaneState = { type: CALENDAR_PANE_TYPES.MAP_EVENT_LIST };
  } else {
    rightPaneState = { type: CALENDAR_PANE_TYPES.MELD_LIST };
  }
  const updateRightPaneURL = ({
    newMapState,
    newRightpaneState,
    action = "push"
  }) => {
    if (pendingRecommendedEvent && rightPaneState.type !== "recommendedMelds" && (newRightpaneState == null ? void 0 : newRightpaneState.type) !== "meldDetails") {
      setPendingRecommendEvent(null);
    }
    if (newRightpaneState && newRightpaneState.type !== "alternativeEvent" && pendingAltEvent) {
      clearAltEventSelectedAgentsTime(true);
    }
    const { search, pathname } = getSearchParamsFromCalendarPaneState({
      newRightpaneState: newRightpaneState || rightPaneState,
      newMapState,
      currentMapState: calendarOrMap,
      location,
      isMobile
    });
    if (newMapState) {
      if (newMapState === "large_map") {
        track(CalendarMeldMapOpened(__spreadValues({ type: "large" }, eventMetaData)));
      } else if (isValidCompositeId(calendarOrMap)) {
        track(
          CalendarMeldMapOpened(__spreadValues({
            type: isMaintenanceManagementAgent({ composite_id: calendarOrMap }) ? "small-agent" : "small-vendor"
          }, eventMetaData))
        );
      }
    }
    if (action === "push") {
      if (newRightpaneState) {
        setPriorRightpaneType(rightPaneState.type);
      }
      history.push({
        pathname,
        search
      });
    } else if (action === "replace") {
      history.replace({
        pathname,
        search
      });
    }
  };
  return { rightPaneState, calendarOrMap, updateRightPaneURL };
};
const useGetCalendarEventMetaData = () => {
  const isMobile = useIsMobile();
  const selectedTimeframe = useCalendarStateSelectedTimeFrame(isMobile);
  const selectedDate = useCalendarStateSelectedDate();
  const daysOffsetFromCurrentDay = differenceInDays(startOfDay(new Date(selectedDate)), startOfDay(/* @__PURE__ */ new Date()));
  return {
    isMobile,
    selectedTimeframe,
    daysOffsetFromCurrentDay
  };
};
export {
  useGetCalendarEventMetaData,
  useGetSetActivePaneAndMap,
  CALENDAR_URL_PANE_PARAM_VALUES,
  CALENDAR_PANE_TYPES
};
