import I from "immutable";
import moment from "moment";

import BaseEvent from "../records/event";

export function dtComparator(a: BaseEvent, b: BaseEvent) {
  if (a.dtstart_moment.isBefore(b.dtstart_moment)) {
    return -1;
  }
  if (a.dtstart_moment.isAfter(b.dtstart_moment)) {
    return 1;
  }
  if (a.dtend_moment.isBefore(b.dtend_moment)) {
    return -1;
  }
  if (a.dtend_moment.isAfter(b.dtend_moment)) {
    return 1;
  }
  return 0;
}

class EventUtils {
  static getEventAssignedAgents(event: BaseEvent) {
    let servicers = event.getIn(["managementavailabilitysegment", "meld", "in_house_servicers"]);

    return (servicers || I.List()).map((servicer: I.Map<string, any>) => {
      let agent = servicer.get("agent");
      if (Number.isInteger(agent)) {
        return agent;
      }

      return agent.get("id");
    });
  }

  static getEventAssignedVendor(event: BaseEvent) {
    let vendor = event.getIn(["vendoravailabilitysegment", "assignment_request", "vendor", "id"]);
    if (Number.isInteger(vendor)) {
      return vendor;
    }

    return vendor.get("id");
  }

  static addEventMoment(event: BaseEvent) {
    let dtstart_moment = moment(event.get("dtstart"));
    let dtend_moment = moment(event.get("dtend"));
    return event.set("dtstart_moment", dtstart_moment).set("dtend_moment", dtend_moment);
  }

  static getDTStartMoment(event: I.Map<string, any>) {
    return event.get("dtstart_moment") || moment(event.get("dtstart"));
  }

  static getDTEndMoment(event: I.Map<string, any>) {
    return event.get("dtend_moment") || moment(event.get("dtend"));
  }

  static groupByOverlapping(events: I.List<I.Map<string, any>>) {
    return events.shift().reduce((reduction: I.List<I.List<I.Map<string, any>>>, current: I.Map<string, any>) => {
      const headOfGroup = reduction.last().first();
      const dtStartMoment = EventUtils.getDTStartMoment(current);
      const headStart = EventUtils.getDTStartMoment(headOfGroup);
      const headEnd = EventUtils.getDTEndMoment(headOfGroup);
      if (dtStartMoment.isBetween(headStart, headEnd, null, "[)")) {
        return reduction.set(-1, reduction.get(-1).push(current));
      }
      return reduction.push(I.List([current]));
    }, I.List([events.slice(0, 1) as I.List<I.Map<string, any>>]));
  }
}

export default EventUtils;
