import ErrorRenderingUtils from "../utils/error-rendering-utils";
import I from "immutable";
import ModelUpdateUtils from "../utils/model-update-utils";
import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";
import userAPI from "../authentication/apis/user-api";
import { pd } from "../utils/event-utils";
import Button, { ButtonTypes } from "../../../app/components/buttons/Button";
import ButtonContainer from "../../../app/components/containers/ButtonContainer";
import { FlexJustifyContent } from "../../../app/components/utils/constants";
import { ErrorHandler } from "../../../app/utils/ErrorHandler";

export default createReactClass({
  mixins: [ErrorRenderingUtils, ModelUpdateUtils],

  propTypes: {
    stepForward: PropTypes.func.isRequired,
    stepBackward: PropTypes.func.isRequired,
  },

  getDefaultProps() {
    return {
      constraints: {
        email: {
          presence: {
            message: "Please provide an email",
            allowEmpty: false,
          },
          email: { message: "Please provide a valid email" },
        },
        password: {
          presence: {
            message: "Please provide a password",
            allowEmpty: false,
          },
          length: {
            minimum: 6,
            tooShort: "Password must be at least six characters",
          },
        },
      },
      errors: [],
    };
  },

  getInitialState() {
    return {
      data: I.fromJS({ specialties: I.fromJS([]) }),
      emailTaken: false,
      emailChecked: false,
      emailErrors: [],
      confirmationCodeChecked: false,
    };
  },

  render() {
    let { isLastStep, submitting } = this.props;
    let nextButton;

    if (isLastStep) {
      nextButton = (
        <Button
          buttonType={ButtonTypes.Primary}
          className="step-forward-btn dual-btn"
          disabled={this.props.submitting}
          onClick={pd(this.prepareSubmit(this.stepForward))}
        >
          {submitting ? "Submitting..." : "Submit"}
        </Button>
      );
    } else {
      nextButton = (
        <Button
          buttonType={ButtonTypes.Primary}
          className="step-forward-btn dual-btn"
          disabled={this.props.submitting}
          onClick={pd(this.prepareSubmit(this.stepForward))}
        >
          Next
        </Button>
      );
    }

    return (
      <div className="form-card-container">
        <h3 className="accent">User Information</h3>
        {this.props.children}
        <form onChange={this.props.onFormChange}>
          <input
            type="email"
            placeholder="Email address"
            className="email"
            value={this.state.data.get("email")}
            onChange={this.handleEmailChanged}
            tabIndex="1"
            ref="email"
            onKeyPress={this.onKeyDown}
          />
          <input
            type="password"
            placeholder="Password"
            className="password"
            onChange={this.onChangeSet("password")}
            value={this.state.data.get("password")}
            tabIndex="2"
            onKeyPress={this.onKeyDown}
          />
          <input
            type="text"
            id="confirmation_code"
            placeholder="Confirmation Code"
            className="confirmation_code"
            value={this.state.data.get("confirmation_code")}
            onChange={this.handleConfirmationCodeChanged}
            tabIndex="3"
            onKeyPress={this.onKeyDown}
          />
          {this.renderErrors()}
          <ButtonContainer justify={FlexJustifyContent.Center}>
            <Button className="dual-btn step-backwards-btn" onClick={pd(this.props.stepBackward)}>
              Back
            </Button>
            {nextButton}
          </ButtonContainer>
        </form>
      </div>
    );
  },

  route2(e) {
    e.preventDefault();
    this.context.router.transitionTo("step2");
  },

  stepForward() {
    if (this.isValid()) {
      this.props.stepForward(this.state.data);
    }
  },

  getExtraValidationErrors() {
    if (this.state.emailChecked && this.state.emailTaken) {
      return ["User with this email address already exists."];
    }

    return [];
  },

  onKeyDown(e) {
    if (e.key === "Enter") {
      this.initValidation();
      this.stepForward(e);
      e.preventDefault();
    }
  },

  getExtraErrors() {
    return this.props.errors.concat(this.state.emailErrors);
  },

  handleEmailChanged(e) {
    const email = e.target.value;
    this.setState({ data: this.state.data.set("email", email) });

    if (email) {
      this.setState({ emailChecked: false, emailErrors: [] });
      userAPI
        .getAvailability(email)
        .then((res) => {
          this.setState({ emailTaken: !res.data.is_available });
        })
        .catch((res) => {
          this.setState({ emailErrors: res.data.messages });
        })
        .then(() => {
          this.setState({ emailChecked: true });
        })
        .catch((error) => ErrorHandler.handleAxiosError(error));
    }
  },

  handleConfirmationCodeChanged(e) {
    const confirmationCode = e.target.value;
    this.setState({
      data: this.state.data.set("confirmation_code", confirmationCode),
    });
  },

  componentDidMount() {
    this.refs.email.focus();
  },
});
