import React from "react";
import { colors } from "@pm-frontend/styles";

export const SidebarPropertiesIcon = ({ isActive = false }: { isActive?: boolean }) => {
  const fill = isActive ? colors.brand.meldBlue : colors.neutrals.gray800;
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1378_22357)">
        <path
          d="M11.3333 7.33333V2H4.66667V4.66667H2V14H7.33333V11.3333H8.66667V14H14V7.33333H11.3333ZM4.66667 12.6667H3.33333V11.3333H4.66667V12.6667ZM4.66667 10H3.33333V8.66667H4.66667V10ZM4.66667 7.33333H3.33333V6H4.66667V7.33333ZM7.33333 10H6V8.66667H7.33333V10ZM7.33333 7.33333H6V6H7.33333V7.33333ZM7.33333 4.66667H6V3.33333H7.33333V4.66667ZM10 10H8.66667V8.66667H10V10ZM10 7.33333H8.66667V6H10V7.33333ZM10 4.66667H8.66667V3.33333H10V4.66667ZM12.6667 12.6667H11.3333V11.3333H12.6667V12.6667ZM12.6667 10H11.3333V8.66667H12.6667V10Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1378_22357">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
