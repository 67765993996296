import React from "react";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  children?: React.ReactNode;
  display?: "block" | "inline" | "inline-block";
}

const FormError: React.FC<Props> = props => (
  <span
    className="pm-forms-error"
    style={{ display: props.display ? props.display : "block" }}
    data-test="form-error"
    {...props}
  >
    {props.children}
  </span>
);

FormError.displayName = "FormError";
export default FormError;
