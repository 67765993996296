import React from "react";
import { RadioGroup, Props as RadioGroupProps } from "./radio-group";
import { Field, FieldProps } from "formik";

interface Props extends RadioGroupProps {
  /**
   * Gets passed to formik's <Field /> component.
   * Used to match the field to form value for validation and state management.
   */
  match: string;
}

export const FormikRadioGroup: React.FC<Props> = ({ match, ...rest }) => (
  <Field name={match}>{({ meta }: FieldProps) => <RadioGroup errors={meta.error} {...rest} />}</Field>
);

FormikRadioGroup.displayName = "FormikRadioGroup";
