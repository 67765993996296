import _ from "lodash";
import AltContainer from "alt-container";
import DocumentTitle from "react-document-title";
import I from "immutable";
import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";
import validate from "validate.js";

import AccountContactSettingsTab from "./account-contact-settings-tab";
import AccountAgentSettingsTab from "./account-agent-settings-tab";
import { LargeLoader } from "../loaders";
import ErrorRenderingUtils from "../utils/error-rendering-utils";
import ModelUpdateUtils from "../utils/model-update-utils";
import Status from "../mixins/status";
import UserStore from "./stores/user-store";
import userActions from "./actions/user-actions";
import { AuthUtils } from "@pm-assets/js/utils/auth-utils";
import ResetChangesButtons from "./components/reset-changes-buttons";

validate.validators.user_current_password = function (value, options, key, attributes) {
  if (options.isDirty() && !value) {
    return "Current Password is required for editing account info";
  }
};

let AccountSettingsUserTab = createReactClass({
  mixins: [ErrorRenderingUtils, ModelUpdateUtils, Status],

  propTypes: {
    user: PropTypes.object,
  },

  loadingStore: UserStore,

  getInitialState() {
    let data = this.props.user || I.fromJS({});
    let cid = _.uniqueId("AccountSettingsUserTab_");

    return {
      data: data,
      dirty: false,
      cid,
      constraints: {
        first_name: {
          presence: {
            message: "First name is required",
            allowEmpty: false,
          },
        },

        email: {
          email: {
            message: "Email address is not valid",
          },
          presence: {
            message: "Email is required",
            allowEmpty: false,
          },
        },

        current_password: {
          user_current_password: {
            isDirty: () => this.state.dirty,
          },
        },
      },
    };
  },

  render() {
    return (
      <div>
        <form onSubmit={this.onSubmit} className="update-account" name="update-account-form">
          <div className="row">
            {this.renderErrors()}

            <div className="columns medium-6 large-4">
              <div className="label-container">
                <label>First name</label>
              </div>
              <input
                type="text"
                placeholder="Enter first name"
                value={this.state.data.get("first_name")}
                onChange={this.markDirty(this.onChangeSet("first_name"))}
                name="first name"
              />
            </div>

            <div className="columns medium-6 large-4">
              <div className="label-container">
                <label>Last name</label>
              </div>
              <input
                type="text"
                placeholder="Enter last name"
                value={this.state.data.get("last_name")}
                onChange={this.markDirty(this.onChangeSet("last_name"))}
                name="last name"
              />
            </div>
            <div className="columns medium-6 large-4">
              <div className="label-container">
                <label>Email</label>
              </div>
              <input
                type="email"
                placeholder="Enter email"
                value={this.state.data.get("email")}
                onChange={this.markDirty(this.onChangeSet("email"))}
                name="email"
              />
            </div>

            <div className="columns medium-6 large-4">
              <div className="label-container">
                <label>Current Password</label>
              </div>
              <input
                type="password"
                placeholder="************"
                value={this.state.data.get("current_password")}
                onChange={this.markDirty(this.onChangeSet("current_password"))}
                name="Current Password"
              />
            </div>
            <div className="columns medium-6 large-4">
              <div className="label-container">
                <label>New Password</label>
              </div>
              <input
                type="password"
                placeholder="************"
                value={this.state.data.get("password")}
                onChange={this.markDirty(this.onChangeSet("password"))}
                name="new password"
              />
            </div>
            <div className="columns medium-6 large-4 end">
              <div className="label-container">
                <label>Confirm New Password</label>
              </div>
              <input
                type="password"
                placeholder="************"
                value={this.state.data.get("confirm_password")}
                onChange={this.markDirty(this.onChangeSet("confirm_password"))}
                name="confirm new password"
              />
            </div>
          </div>

          <div className="footer">
            <div className="footer-content">{this.renderActionButtons()}</div>
          </div>
        </form>
      </div>
    );
  },

  renderActionButtons() {
    if (this.state.dirty) {
      return (
        <ResetChangesButtons
          saveClickFn={this.prepareSubmit(this.updateSettings)}
          resetClickFn={this.onResetChanges(this.getOriginalAccountInfo())}
          saveDisabled={this.isLoading()}
          resetDisabled={this.isLoading()}
        />
      );
    }
  },

  updateSettings(e) {
    e.preventDefault();

    if (this.isValid()) {
      let data = this.state.data.toJS();
      userActions.update(data, { cid: this.state.cid });
      this.setState({
        data: this.state.data.delete("current_password").delete("confirm_password").delete("password"),
      });
      this.setState({ dirty: false });
    }
  },

  getOriginalAccountInfo() {
    return this.props.user;
  },
});

export default createReactClass({
  render() {
    return (
      <DocumentTitle title="User Settings">
        <AltContainer
          stores={{
            user() {
              return {
                value: UserStore.getCurrentUser(),
                store: UserStore,
              };
            },
          }}
          render={function (props) {
            if (UserStore.didFail()) {
              return <div>Failed to load user settings</div>;
            } else if (UserStore.isLoading()) {
              return <LargeLoader />;
            }

            return (
              <div>
                <AccountSettingsUserTab {...props} />
                <hr />
                <AccountContactSettingsTab />
                <>
                  {AuthUtils.getActiveOrgType() === "m" && (
                    <>
                      <hr />
                      <AccountAgentSettingsTab />
                    </>
                  )}
                </>
              </div>
            );
          }}
        />
      </DocumentTitle>
    );
  },

  componentWillMount() {
    UserStore.fetchCurrentUser();
  },
});
