import I from "immutable";
import React from "react";
import CancelNote from "./cancel-note";

export interface TenantCancellationRequestsProps {
  meld: I.Map<string, any>;
  canDelete: boolean;
  onDelete?: () => void;
}

export default class TenantCancellationRequests extends React.Component<TenantCancellationRequestsProps> {
  render() {
    let meld = this.props.meld;
    let requests = meld.get("cancellation_requests");

    if (requests && !requests.isEmpty()) {
      return (
        <div>
          <h3>Cancellation Request{requests.count() > 0 ? "s" : ""}</h3>
          <ul className="cancel-notes">
            {requests.map((request: I.Map<string, any>, i: number) => {
              return (
                <li className="copy" key={i}>
                  {this.renderCancelCall(request)}
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  }

  renderCancelCall(request: I.Map<string, any>) {
    if (this.props.onDelete) {
      return <CancelNote note={request} canDelete={this.props.canDelete} onDelete={this.props.onDelete} />;
    } else {
      return <CancelNote note={request} canDelete={this.props.canDelete} />;
    }
  }
}
