import I from "immutable";

import * as C from "../../constants";
import Currency from "../../utils/currency";

export const formattedPaymentMethods = (method: string) => {
  switch (method) {
    case C.PaymentMethods.CC:
      return "Credit Card";
    case C.PaymentMethods.ACH:
      return "ACH";
    case C.PaymentMethods.CHECK:
      return "Check";
    case C.PaymentMethods.CASH:
      return "Cash";
  }
};

let PaymentUtils = {
  getFormattedMethod(payment: I.Map<string, any>): string | null | undefined {
    if (payment) {
      return formattedPaymentMethods(payment.get("method"));
    }
  },

  formatAmount(amount: number): string | null | undefined {
    return Currency.dollar(amount / 100);
  }
};

export default PaymentUtils;
