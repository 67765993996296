import React from "react";

import { Box, BoxProps, Link as ChakraLink, LinkProps as ChakraLinkProps } from "@chakra-ui/react";
import { Link, LinkProps } from "react-router-dom";

import { colors } from "../theme";
import { History } from "history";

export enum Width {
  SMALL = "1024px",
  MEDIUM = "1440px",
  LARGE = "3600px",
  FULL = "100%",
}

export const Container: React.FC<BoxProps> = ({ width = Width.FULL, ...props }) => {
  return <Box {...props} />;
};

Container.displayName = "Container";

interface ContentProps extends BoxProps {
  width: Width;
}

export const Content: React.FC<ContentProps> = ({ width, ...props }) => {
  return <Box marginX="auto" maxWidth={width} paddingX={[4, 6, 8, 16]} paddingTop={6} paddingBottom={8} {...props} />;
};

Content.displayName = "Content";

export const Subnav: React.FC<BoxProps> = ({ ...props }) => (
  <Box paddingX={[10, 10, 10, 20]} paddingY={4} backgroundColor={colors.white} fontSize="sm" shadow="sm" {...props} />
);

Subnav.displayName = "Subnav";

interface SubnavLinkProps {
  history: History;
}

export const SubnavLink: React.FC<SubnavLinkProps & LinkProps & ChakraLinkProps> = ({ history, to, ...props }) => (
  <ChakraLink
    as={Link}
    color={history.location.pathname === to ? "gray.800" : "gray.500"}
    to={to}
    _hover={{
      color: "gray.700",
    }}
    {...props}
  />
);

SubnavLink.displayName = "SubnavLink";
