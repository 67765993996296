import createReactClass from "create-react-class";
import React from "react";

/* tslint:disable:max-line-length */
let Building = createReactClass({
  render() {
    return (
      <svg className={this.props.classes} xmlns="https://www.w3.org/2000/svg" viewBox="-111.2 57 24.5 32">
        <path d="M-88.3 86.1V57.8c0-.4-.4-.8-.8-.8h-19.6c-.4 0-.8.4-.8.8v28.3c0 .4-.4.8-.8.8h-.1c-.4 0-.8.4-.8.8v.3c0 .4.4.8.8.8h22.9c.4 0 .8-.4.8-.8v-.3c0-.4-.4-.8-.8-.8-.5 0-.8-.4-.8-.8zm-11.8-26.6h2.3c.4 0 .8.4.8.8V62c0 .4-.4.8-.8.8h-2.3c-.4 0-.8-.4-.8-.8v-1.8c0-.4.4-.7.8-.7zm0 5.2h2.3c.4 0 .8.4.8.8v1.8c0 .4-.4.8-.8.8h-2.3c-.4 0-.8-.4-.8-.8v-1.8c0-.4.4-.8.8-.8zm0 5.3h2.3c.4 0 .8.4.8.8v1.8c0 .4-.4.8-.8.8h-2.3c-.4 0-.8-.4-.8-.8v-1.8c0-.4.4-.8.8-.8zm0 5.3h2.3c.4 0 .8.4.8.8v1.8c0 .4-.4.8-.8.8h-2.3c-.4 0-.8-.4-.8-.8v-1.8c0-.4.4-.8.8-.8zm-4 8.6h-2.3c-.4 0-.8-.4-.8-.8v-1.8c0-.4.4-.8.8-.8h2.3c.4 0 .8.4.8.8v1.8c0 .5-.4.8-.8.8zm0-5.2h-2.3c-.4 0-.8-.4-.8-.8v-1.8c0-.4.4-.8.8-.8h2.3c.4 0 .8.4.8.8v1.8c0 .4-.4.8-.8.8zm0-5.3h-2.3c-.4 0-.8-.4-.8-.8v-1.8c0-.4.4-.8.8-.8h2.3c.4 0 .8.4.8.8v1.8c0 .4-.4.8-.8.8zm0-5.3h-2.3c-.4 0-.8-.4-.8-.8v-1.8c0-.4.4-.8.8-.8h2.3c.4 0 .8.4.8.8v1.8c0 .4-.4.8-.8.8zm0-5.3h-2.3c-.4 0-.8-.4-.8-.8v-1.8c0-.4.4-.8.8-.8h2.3c.4 0 .8.4.8.8V62c0 .5-.4.8-.8.8zm6.5 24.1h-2.7c-.4 0-.8-.4-.8-.8v-4.7c0-.4.4-.8.8-.8h2.7c.4 0 .8.4.8.8v4.7c0 .4-.3.8-.8.8zm6.4-3h-2.3c-.4 0-.8-.4-.8-.8v-1.8c0-.4.4-.8.8-.8h2.3c.4 0 .8.4.8.8v1.8c-.1.5-.4.8-.8.8zm0-5.2h-2.3c-.4 0-.8-.4-.8-.8v-1.8c0-.4.4-.8.8-.8h2.3c.4 0 .8.4.8.8v1.8c-.1.4-.4.8-.8.8zm0-5.3h-2.3c-.4 0-.8-.4-.8-.8v-1.8c0-.4.4-.8.8-.8h2.3c.4 0 .8.4.8.8v1.8c-.1.4-.4.8-.8.8zm0-5.3h-2.3c-.4 0-.8-.4-.8-.8v-1.8c0-.4.4-.8.8-.8h2.3c.4 0 .8.4.8.8v1.8c-.1.4-.4.8-.8.8zm0-5.3h-2.3c-.4 0-.8-.4-.8-.8v-1.8c0-.4.4-.8.8-.8h2.3c.4 0 .8.4.8.8V62c-.1.5-.4.8-.8.8z" />
      </svg>
    );
  },
});
/* tslint:enable:max-line-length */

export default Building;
