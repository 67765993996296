import DocumentTitle from "react-document-title";
import React from "react";
import Button from "../../../app/components/buttons/Button";

export default class SettingsAccountTabContent extends React.Component {
  render() {
    return (
      <DocumentTitle title={`Account Settings`}>
        <div>
          <div className="footer">
            <div className="footer-content">
              <Button type="submit" className="danger" aria-label="Close">
                <span>Cancel account</span>
              </Button>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}
