import randomColor from "randomcolor";

export const getAccessibleTextColor = (hexColor: string) => {
  // Remove the hash (#) if it exists
  hexColor = hexColor.replace("#", "");

  // Convert the hex color to RGB
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  // Calculate the luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // If luminance is high, return black; otherwise, return white
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
};

class PersonaColorCache {
  cache: Record<string, string>;

  constructor() {
    this.cache = {};
  }

  getColor(seed: string) {
    if (this.cache[seed]) {
      return this.cache[seed];
    } else {
      const color = randomColor({ seed, luminosity: "light" });
      this.cache[seed] = color;
      return color;
    }
  }
}

const colorCache = new PersonaColorCache();

export const getPersonaColor = (user: { created: string }) => {
  return colorCache.getColor(user.created);
};
