import React from "react";
import { ImmutableMap } from "../../types/common";
import Download from "../../svg/download-svg";

interface Props {
  file: ImmutableMap;
}

export default class FileViewerDownloadFile extends React.Component<Props> {
  render() {
    let { file } = this.props;
    return (
      <a className="file-viewer-download-file file-action" title="Download" href={file.get("signed_url")}>
        <Download />
      </a>
    );
  }
}
