import _ from "lodash";
import * as C from "../../constants";
import classNames from "classnames";
import MomentUtils from "../../utils/moment-utils";
import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";

let TimeSegment = createReactClass({
  propTypes: {
    timeSegment: PropTypes.object.isRequired,
    onTimeSegmentMouseEnter: PropTypes.func.isRequired,
    events: PropTypes.array.isRequired,
    onDeleteClicked: PropTypes.func.isRequired,
    active: PropTypes.object,
    disabledTo: PropTypes.object,
    compressedMode: PropTypes.bool.isRequired,
    eventStyleGetter: PropTypes.func,
  },

  render() {
    return (
      <td
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.props.onTimeSegmentMouseLeave}
        onClick={this.onClick}
        className={this.getClassNames()}
      >
        {this.renderScheduleBlock()}
      </td>
    );
  },

  renderScheduleBlock() {
    let eventTypes = new Set(this.props.events.map((event) => event.type));

    let eventsToRender = [];
    let eventsInThisSegment = [];

    if (this.props.compressedMode) {
      eventsInThisSegment = this._getEventsInCompressedMode();
    } else {
      eventsInThisSegment = this._getEventsInFullMode();
    }

    let numEventsInSegment = new Set(eventsInThisSegment.map((event) => event.type)).size;

    eventTypes.forEach((eventType) => {
      let eventToRender = eventsInThisSegment.find((event) => event.type === eventType);

      if (eventToRender) {
        let interval = Math.ceil(this._calcBlockIntervalInMinutes(eventToRender) / 15);
        let scheduledBlockIntervalClass = `scheduled-block-${interval}`;

        let eventClassNames = classNames({
          "scheduled-block": true,
          [scheduledBlockIntervalClass]: true,
          [eventToRender.type]: true,
          [`segment-width-${numEventsInSegment}`]: true,
        });

        let customStyle = {};
        if (this.props.eventStyleGetter) {
          customStyle = this.props.eventStyleGetter(eventToRender);
        }

        eventsToRender.push(
          <div
            key={eventToRender.dtstart + eventToRender.type}
            className={eventClassNames}
            style={customStyle}
          >
            <span className="event-start-to-end">
              <span className="event-time">{eventToRender.dtstart.format("h:mm")}-</span>
              <span className="event-time">{eventToRender.dtend.format("h:mm")}</span>
            </span>

            <a className="delete-event" onClick={(e) => this.onDeleteClicked(e, eventToRender)}>
              x
            </a>
          </div>
        );
      }
    });

    return <div>{eventsToRender}</div>;
  },

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.events.length === nextProps.events.length) {
      let idsEqual = _.isEqual(_.map(this.props.events, "id"), _.map(nextProps.events, "id"));
      let cidsEqual = _.isEqual(_.map(this.props.events, "_cid"), _.map(nextProps.events, "_cid"));

      if (!idsEqual || !cidsEqual) {
        return true;
      }
    } else if (this.props.events.length !== nextProps.events.length) {
      return true;
    }

    if (!this.props.timeSegment.isSame(nextProps.timeSegment)) {
      return true;
    }

    if (this.props.active && nextProps.active) {
      let isNextActive = MomentUtils.isBetweenInclusive(
        this.props.timeSegment,
        nextProps.active.dtstart,
        nextProps.active.dtend
      );
      let isCurrentActive = MomentUtils.isBetweenInclusive(
        this.props.timeSegment,
        this.props.active.dtstart,
        this.props.active.dtend
      );
      return isCurrentActive !== isNextActive;
    }

    if (this.props.eventStyleGetter !== nextProps.eventStyleGetter) {
      return true;
    }

    return false;
  },

  _getEventsInCompressedMode() {
    let { timeSegment } = this.props;

    if (timeSegment.hours() === C.COMPRESSED_CALENDAR_MORNING_START_HOUR && timeSegment.minutes() === 0) {
      return this.props.events.filter((event) =>
        MomentUtils.isBetweenInclusive(timeSegment, event.dtstart, event.dtend)
      );
    }

    return this._getEventsStartingAtThisSegment();
  },

  _getEventsInFullMode() {
    let eventsInThisSegment = this._getEventsStartingAtThisSegment();

    if (!eventsInThisSegment.length) {
      eventsInThisSegment = this._getMultidayEvents();
    }

    return eventsInThisSegment;
  },

  _getEventsStartingAtThisSegment() {
    return this.props.events.filter((event) => {
      let differenceInMinutes = event.dtstart.diff(this.props.timeSegment, "minutes");
      return differenceInMinutes >= 0 && differenceInMinutes < 15;
    });
  },

  _calcBlockIntervalInMinutes(eventToRender) {
    let timeSegment = this.props.timeSegment;

    let interval = eventToRender.dtend.diff(timeSegment, "minutes");

    if (this.props.compressedMode) {
      interval = this._calcCompressedIntervalInMinutes(eventToRender);
    } else if (timeSegment.days() !== eventToRender.dtend.days()) {
      // Shorten the interval based on whether the event spills over on to the next day and difference in minutes
      interval = eventToRender.dtend.clone().startOf("day").diff(timeSegment, "minutes");
    } else {
      interval = eventToRender.dtend.diff(timeSegment, "minutes");
    }

    return interval;
  },

  _calcCompressedIntervalInMinutes(eventToRender) {
    /*
         Calculates the number of minutes to show for this time segment when viewing in "compressed" mode.
         This method accounts for time segments that straddle the the edges of before and after business hours.

         Example:
         this.props.timeSegment = Sun Nov 15 2015 15:30:00
         eventToRender.dtstart = Sun Nov 15 2015 14:30:00
         eventToRender.dtend = Sun Nov 15 2015 20:30:00

         Assuming business hours for the compressed calendar are 6AM to 7PM, we should get the number of minutes from
         15:30 until 19:00. Which is 210 minutes (3.5 hours).
         */
    let timeSegment = this.props.timeSegment;
    let interval;

    let { dtstart, dtend } = eventToRender;

    let dtstartMorningCutoff = dtstart.clone().hours(C.COMPRESSED_CALENDAR_MORNING_START_HOUR).minutes(0);
    let dtstartEveningCutoff = dtstart.clone().hours(C.COMPRESSED_CALENDAR_EVENING_END_HOUR).minutes(0);

    if (
      MomentUtils.isBetweenInclusive(dtstart, dtstartMorningCutoff, dtstartEveningCutoff) &&
      MomentUtils.isBetweenInclusive(dtend, dtstartMorningCutoff, dtstartEveningCutoff)
    ) {
      interval = dtend.diff(timeSegment, "minutes");
    } else if (MomentUtils.isBetweenInclusive(dtstartMorningCutoff, dtstart, dtend)) {
      interval = dtend.diff(dtstartMorningCutoff, "minutes");
    } else if (
      timeSegment.hours() === dtstart.hours() &&
      (dtend.isAfter(dtstartEveningCutoff) || dtend.isSame(dtstartEveningCutoff))
    ) {
      interval = dtstartEveningCutoff.diff(dtstart, "minutes");
    } else {
      interval = 0;
    }

    return interval;
  },

  _getMultidayEvents() {
    let timeSegment = this.props.timeSegment;

    // Check for segments (other than the beginning) which run over to the next day
    return this.props.events.filter((event) => {
      let endsOnDifferentDay = event.dtstart.days() !== event.dtend.days();

      if (endsOnDifferentDay) {
        let isBeginningOfNextDay = timeSegment.hours() === 0 && timeSegment.minutes() < 15;

        if (isBeginningOfNextDay) {
          return timeSegment.isBetween(event.dtstart, event.dtend);
        }
      }

      return false;
    });
  },

  onClick(e) {
    if (!this.isDisabled()) {
      this.props.onSegmentClicked(this.props.timeSegment);
    }
  },

  onMouseEnter(e) {
    this.props.onTimeSegmentMouseEnter({
      timeSegment: this.props.timeSegment,
    });
  },

  onDeleteClicked(e, event) {
    e.preventDefault();
    e.stopPropagation();
    this.props.onDeleteClicked(event);
  },

  isDisabled() {
    return this.props.timeSegment.isBefore(this.props.disabledTo);
  },

  getClassNames() {
    let timeSegment = this.props.timeSegment;
    let active = false;
    let disabled = this.isDisabled();

    if (!disabled && _.keys(this.props.active).length > 0) {
      let { dtstart, dtend } = this.props.active;
      active = MomentUtils.isBetweenInclusive(timeSegment, dtstart, dtend);
    }

    return classNames({
      "time-slot": true,
      active: active,
      disabled: disabled,
    });
  },
});

export default TimeSegment;
