var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { asImmer } from "@pm-app/utils/decorators";
import AddressUtils from "@pm-assets/js/utils/address-utils";
const _PropertyUtils = class {
  static getMaintenanceNotes(prop) {
    return prop == null ? void 0 : prop.maintenance_notes;
  }
  static getName(prop) {
    if (prop) {
      return prop.property_name;
    }
  }
  static getAddressLines(prop, emptyMsg = "") {
    let lines = [
      _PropertyUtils.getAddressLine1(prop),
      _PropertyUtils.getAddressLine2(prop),
      _PropertyUtils.getAddressLine3(prop),
      emptyMsg
    ];
    lines = lines.filter((line) => line);
    return lines.join(", ");
  }
  static getDisplayCityStateZip(prop) {
    if (prop.display_address) {
      return AddressUtils.getFormattedCityStateZip(prop.display_address);
    }
  }
  static getNameOrLine1(prop) {
    if (prop) {
      return prop.property_name || prop.line_1;
    }
  }
  static maybeGetName(prop) {
    return prop.property_name !== prop.line_1 ? prop.property_name : "";
  }
  static getAddressLine1(prop) {
    return prop.line_1 ? prop.line_1 : "";
  }
  static getAddressLine2(prop) {
    return prop.line_2 ? prop.line_2 : "";
  }
  static getAddressLine3(prop) {
    return prop.line_3 ? prop.line_3 : "";
  }
};
let PropertyUtils = _PropertyUtils;
__decorateClass([
  asImmer()
], PropertyUtils, "getMaintenanceNotes", 1);
__decorateClass([
  asImmer()
], PropertyUtils, "getName", 1);
__decorateClass([
  asImmer()
], PropertyUtils, "getAddressLines", 1);
__decorateClass([
  asImmer()
], PropertyUtils, "getDisplayCityStateZip", 1);
__decorateClass([
  asImmer()
], PropertyUtils, "getNameOrLine1", 1);
__decorateClass([
  asImmer()
], PropertyUtils, "maybeGetName", 1);
__decorateClass([
  asImmer()
], PropertyUtils, "getAddressLine1", 1);
__decorateClass([
  asImmer()
], PropertyUtils, "getAddressLine2", 1);
__decorateClass([
  asImmer()
], PropertyUtils, "getAddressLine3", 1);
export default PropertyUtils;
