var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
export default function withPreviewableFiles(Comp) {
  return class WithPreviewableFiles extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
      this.handleNavFile = this.handleNavFile.bind(this);
      this.handleClosePreview = this.handleClosePreview.bind(this);
    }
    render() {
      return /* @__PURE__ */ React.createElement(
        Comp,
        __spreadProps(__spreadValues({}, this.props), {
          previewingFile: this.state.previewingFile,
          navFile: this.handleNavFile,
          closePreview: this.handleClosePreview
        })
      );
    }
    handleNavFile(file) {
      this.setState({ previewingFile: file });
    }
    handleClosePreview() {
      this.setState({ previewingFile: void 0 });
    }
  };
}
