import alt from "../../alt";

import AgentApi from "../apis/agent-api";
import messageActions from "../../common/actions/message-actions";

class AgentActions {
  constructor() {
    this.generateActions(
      "fetchAll",
      "fetchAllDone",
      "fetchAllFail",
      "updateDone",
      "updateFail",
      "deactivateDone",
      "deactivateFail",
      "updateUserGroupsFail"
    );
  }

  deactivate(agentId, data) {
    AgentApi.update(agentId, Object.assign({ deactivate: true }, data))
      .then(result => {
        this.deactivateDone({ data: result.data, id: agentId });
      })
      .catch(result => {
        messageActions.storeError({ body: result.messages[0] });
        this.deactivateFail({ data: result.data });
      });

    return {};
  }

  update(agentId, data, { cid } = {}) {
    AgentApi.update(agentId, data)
      .then(result => {
        messageActions.storeSuccess({ body: "User updated" });
        this.updateDone({ data: result.data, id: agentId });
      })
      .catch(result => {
        messageActions.storeError({ body: result.messages[0] });
        this.updateFail({ data: result.data });
      });

    return { cid };
  }

  updatePropertyGroups(agentId, propertyGroupIds, originalPropertyGroups) {
    AgentApi.updatePropertyGroups(agentId, propertyGroupIds)
      .then(res => {
        this.updatePropertyGroupsDone(agentId, res.data);
      })
      .catch(res => {
        this.updatePropertyGroupsFail(agentId, res.messages[0].data, res.messages[0].errors);
      });

    return { agentId, propertyGroupIds };
  }

  updatePropertyGroupsDone(agentId, data) {
    return { agentId, propertyGroups: data.property_groups };
  }

  updatePropertyGroupsFail(agentId, data, errors) {
    return { agentId, propertyGroups: data.property_groups, error: errors };
  }

  updateUserGroups(agentId, userGroupIds) {
    AgentApi.updateUserGroups(agentId, userGroupIds)
      .then(res => {
        this.updateUserGroupsDone(agentId, res.data);
      })
      .catch(res => {
        this.updateUserGroupsFail(res);
      });

    return { agentId, userGroupIds };
  }

  updateUserGroupsDone(agentId, data) {
    return { agentId, userGroups: data.user_groups };
  }

  toggleTour(bool) {
    AgentApi.updateMe({ show_tour: bool });

    return { showTour: bool };
  }

  completeTourStep(step) {
    return { step };
  }

  resetTour() {
    AgentApi.updateMe({ show_tour: true });

    return {};
  }
}

export default alt.createActions(AgentActions);
