import VendorInvoiceFileActions from "../actions/vendor-invoice-file-actions";
import vendorInvoiceFileApi from "../apis/vendor-invoice-file-api";

let vendorInvoiceFileSource = {
  fetchFilesForMeld() {
    return {
      remote(meldId: number) {
        return vendorInvoiceFileApi.fetchFilesForMeld(meldId);
      },

      shouldFetch() {
        return true;
      },
      success: VendorInvoiceFileActions.fetchVendorInvoiceFileDone,
      error: VendorInvoiceFileActions.fetchVendorInvoiceFileFail,
      loading: VendorInvoiceFileActions.fetchVendorInvoiceFile
    };
  }
};

export default vendorInvoiceFileSource;
