import alt from "../../alt";

import BaseStore, { StatusStates } from "../../stores/base-store";
import TenantSettingsActions from "../actions/tenant-settings-actions";
import TenantSettingsSource from "../sources/tenant-settings-source";

export let ExtraActions = {
  FETCH_CURRENT: "FETCH_CURRENT"
};

class TenantSettingsStore extends BaseStore {
  constructor(options) {
    super(options);

    this.bindListeners({
      fetchingCurrent: TenantSettingsActions.FETCH_CURRENT,
      fetchingCurrentDone: TenantSettingsActions.FETCH_CURRENT_DONE,
      fetchingCurrentFail: TenantSettingsActions.FETCH_CURRENT_FAIL,
      updating: TenantSettingsActions.UPDATE,
      updateDone: TenantSettingsActions.UPDATE_DONE,
      updateFail: TenantSettingsActions.UPDATE_FAIL
    });

    this.exportPublicMethods({
      getCurrent: this.getCurrent.bind(this)
    });

    this.registerAsync(TenantSettingsSource);
  }

  fetchingCurrent(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.FETCH_CURRENT, StatusStates.PENDING);
  }

  fetchingCurrentDone(dispatchData) {
    this.receiveOne(dispatchData);

    this.augmentAndMarkStatus(dispatchData, ExtraActions.FETCH_CURRENT, StatusStates.DONE);
  }

  fetchingCurrentFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.FETCH_CURRENT, StatusStates.FAIL);
  }

  getCurrent() {
    return this.getModelState().first();
  }
}

export default alt.createStore(TenantSettingsStore, "TenantSettingsStore");
