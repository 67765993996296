var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import { uuid } from "uuidv4";
import { Skeleton } from "@chakra-ui/react";
export const SkeletonGroup = (_a) => {
  var _b = _a, { amount = 5, height = 4, spacing = 2, width } = _b, props = __objRest(_b, ["amount", "height", "spacing", "width"]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, Array.from(Array(amount).keys()).map((_, i) => /* @__PURE__ */ React.createElement(
    Skeleton,
    __spreadValues({
      height,
      key: uuid(),
      marginTop: i !== 0 ? spacing : 0,
      width: width || `${Math.random() * (100 - 50) + 50}%`
    }, props)
  )));
};
SkeletonGroup.displayName = "SkeletonGroup";
