import createReactClass from "create-react-class";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { LargeLoader } from "../../loaders";

interface StoreActivityMonitorProps {
  stores: any[];
  loader?: React.ComponentClass | (() => JSX.Element);
  renderChildrenWithFailure?: boolean;
  errorMessage: string;
  errorPage?: JSX.Element;
}

let StoreActivityMonitor = createReactClass<StoreActivityMonitorProps, {}>({
  propTypes: {
    stores: PropTypes.array.isRequired,
    loader: PropTypes.func,
    children: PropTypes.object.isRequired,
    renderChildrenWithFailure: PropTypes.bool,
    errorMessage: PropTypes.string.isRequired,
  },

  getDefaultProps() {
    return {
      loader: LargeLoader,
      renderChildrenWithFailure: false,
      stores: [],
      errorMessage: "There was a problem loading your data.",
    };
  },

  render() {
    let props = Object.assign({}, _.omit(this.props, "children"));

    for (let store of this.props.stores) {
      if (store.isLoading()) {
        return React.createElement(this.props.loader);
      }

      let storeFailure = store.didFail();
      if (storeFailure && !this.props.renderChildrenWithFailure) {
        if (this.props.errorPage) {
          return this.props.errorPage;
        } else {
          return <div className="error">{this.props.errorMessage}</div>;
        }
      } else if (storeFailure) {
        props.storeFailure = true;
      }
    }

    return <span>{React.cloneElement(this.props.children, { ...props })}</span>;
  },
});

export default StoreActivityMonitor;
