import classnames from "classnames";
import Link from "@pm-shared/utils/link";
import React from "react";

interface Props {
  isActive: boolean;
  linkAttrs?: any;
  className?: string;
  dataTest?: string;
  onClick?: (e: React.SyntheticEvent<any>) => void;
}

let getLiProps = function () {
  let classes = classnames({
    "pillbox-section": true,
    active: this.props.isActive,
  });
  classes = `${classes} ${this.props.className}`;

  let liProps: any = {
    className: classes,
  };
  if (this.props.onClick) {
    liProps.onClick = this.props.onClick;
  }
  return liProps;
};

export default class Pill extends React.Component<Props> {
  public static defaultProps = {
    className: "",
  };

  render() {
    let liProps = getLiProps.call(this);

    return (
      <li {...liProps}>
        <Link className="pill-button" {...this.props.linkAttrs}>
          {this.props.children}
        </Link>
      </li>
    );
  }
}

export class NonLinkPill extends React.Component<Props> {
  public static defaultProps = {
    className: "",
  };

  render() {
    let liProps = getLiProps.call(this);

    return (
      <li {...liProps}>
        <div {...this.props} className="pill-button" {...this.props.linkAttrs}>
          {this.props.children}
        </div>
      </li>
    );
  }
}
