/* eslint-disable no-case-declarations*/
import { EuiSuperSelectOption } from "@elastic/eui";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";

import {
  getFormattedIntervalValue,
  getFormattedOlderThanValue,
  getIntervalFromFormattedValue,
} from "@pm-frontend/shared/utils/saved-filter-utils";
import { BaseURLFilter, DeepPartial, SavedFilterObject } from "../BaseFilterClasses";

export const PM_DATE_FILTER_OPERATORS = {
  DATE_RANGE: "In range",
  NOT_DATE_RANGE: "Not in range",
  OLDER_THAN: "Older than",
  IN_THE_PAST: "In the past",
  EQUAL_TO: "Equal to",
  BEFORE: "Before",
  AFTER: "After",
} as const;

export type PmDateFilterAllowedOperators = typeof PM_DATE_FILTER_OPERATORS[keyof typeof PM_DATE_FILTER_OPERATORS];

export const DATE_RANGE_FIRST_SUFFIX = "_gte" as const;
export const DATE_RANGE_SECOND_SUFFIX = "_lte" as const;
const NOT_IN_DATE_RANGE_SUFFIX = "_exclude_range" as const;
const IN_THE_PAST_SUFFIX = "_interval" as const;
const OLDER_THAN_SUFFIX = "_offset_lte" as const;
const EQUAL_TO_SUFFIX = "_equal" as const;

type PmDateFilterSuffixes =
  | typeof DATE_RANGE_FIRST_SUFFIX
  | typeof DATE_RANGE_SECOND_SUFFIX
  | typeof IN_THE_PAST_SUFFIX
  | typeof OLDER_THAN_SUFFIX
  | typeof EQUAL_TO_SUFFIX
  | typeof NOT_IN_DATE_RANGE_SUFFIX;

export type PmDateFilterTimeframeOptions = "Days" | "Weeks" | "Months";

const PM_DATE_FILTER_TIMEFRAME_OPTIONS: Array<EuiSuperSelectOption<PmDateFilterTimeframeOptions>> = [
  { value: "Days", inputDisplay: "Days", dropdownDisplay: "Days" },
  { value: "Weeks", inputDisplay: "Weeks", dropdownDisplay: "Weeks" },
  { value: "Months", inputDisplay: "Months", dropdownDisplay: "Months" },
];

export const PM_DATE_FILTER_INPUT_TYPES = {
  ONE_DATE: "one-date-input",
  TWO_DATE: "two-date-input",
  TIMEFRAME: "timeframe",
} as const;

export type PmDateFilterInputTypes = typeof PM_DATE_FILTER_INPUT_TYPES[keyof typeof PM_DATE_FILTER_INPUT_TYPES];

export type PmDateFilterOperatorValue =
  | ({ filterType: typeof BaseDateFilterClass.type } & (
      | {
          // timeframe
          operator: Extract<PmDateFilterAllowedOperators, "Older than" | "In the past">;
          timeframeValue: PmDateFilterTimeframeOptions;
          numberValue: string;
          type: Extract<PmDateFilterInputTypes, "timeframe">;
        }
      | {
          // two date input
          operator: Extract<PmDateFilterAllowedOperators, "In range" | "Not in range">;
          firstDate: string;
          secondDate: string;
          type: Extract<PmDateFilterInputTypes, "two-date-input">;
        }
      | {
          // one date input
          operator: Extract<PmDateFilterAllowedOperators, "Equal to" | "Before" | "After">;
          value: string;
          type: Extract<PmDateFilterInputTypes, "one-date-input">;
        }
    ))
  | null;

export const getIntervalValuesFromFormattedValue = (formattedValue: string): string => {
  if (!formattedValue) {
    return "";
  }
  const intervalLength = getIntervalFromFormattedValue(formattedValue);
  const interval = moment.duration(formattedValue);
  switch (intervalLength) {
    case "Days":
      return interval.asDays().toString();
    case "Months":
      return interval.asMonths().toString();
    case "Weeks":
      return interval.asWeeks().toString();
    default:
      return "";
  }
};

export const getOffsetFromFormattedValue = (formattedValue: string): PmDateFilterTimeframeOptions => {
  if (!formattedValue) {
    return "Days";
  }
  const lastDigit = formattedValue.charAt(formattedValue.length - 1);
  switch (lastDigit) {
    case "d":
      return "Days";
    case "w":
      return "Weeks";
    case "m":
      return "Months";
    default:
      return "Days";
  }
};

export const getOffsetValueFromFormattedValue = (formattedValue: string) => {
  if (!formattedValue || formattedValue.length < 3) {
    return "";
  }
  return formattedValue.slice(1, formattedValue.length - 1);
};

export interface PmDateFilterProps {
  text: string;
  queryParamKeyPrefix: string;
  filterName: string;
  popoverWidth: string;
  allowedOperators: PmDateFilterAllowedOperators[];
  // some filters have inconsistent key values
  suffixOverrides?: Partial<Record<PmDateFilterSuffixes, string>>;
  componentProps: null;
  alwaysShow: boolean;
}

export class BaseDateFilterClass extends BaseURLFilter<PmDateFilterAllowedOperators, null, PmDateFilterProps> {
  static type = "date_filter" as const;

  constructor(config: PmDateFilterProps, overrides?: DeepPartial<PmDateFilterProps>) {
    super(config, overrides);
  }

  deleteAllParamValues({
    location,
    paramsToMutate,
  }: {
    location: ReturnType<typeof useLocation>;
    paramsToMutate?: URLSearchParams;
  }): URLSearchParams {
    const searchParams = paramsToMutate || new URLSearchParams(location.search);
    for (const operator of this.getAllowedOperators()) {
      if (operator === PM_DATE_FILTER_OPERATORS.DATE_RANGE) {
        continue;
      }
      searchParams.delete(this.getFullQueryParamKey({ operator, target: "url" }));
    }
    return searchParams;
  }

  getCurrentlyAppliedOperatorAndValue({
    savedFilter,
    location,
  }: {
    savedFilter: SavedFilterObject;
    location: ReturnType<typeof useLocation>;
  }): PmDateFilterOperatorValue {
    const currentSearchParams = new URLSearchParams(location.search);
    // DATE_RANGE operator requires both '_lte' and '_gte' param keys, so we check for it first
    if (this.getAllowedOperators().includes(PM_DATE_FILTER_OPERATORS.DATE_RANGE)) {
      let firstDate = "";
      let secondDate = "";
      if (savedFilter) {
        firstDate =
          this.getSavedFilterValue({
            savedFilter,
            operator: PM_DATE_FILTER_OPERATORS.AFTER,
          }) || "";
        secondDate =
          this.getSavedFilterValue({
            savedFilter,
            operator: PM_DATE_FILTER_OPERATORS.BEFORE,
          }) || "";
      } else {
        firstDate = this.getSearchParamValue({ currentSearchParams, operator: PM_DATE_FILTER_OPERATORS.AFTER }) || "";
        secondDate = this.getSearchParamValue({ currentSearchParams, operator: PM_DATE_FILTER_OPERATORS.BEFORE }) || "";
      }
      if (firstDate && secondDate) {
        return {
          operator: PM_DATE_FILTER_OPERATORS.DATE_RANGE,
          firstDate,
          secondDate,
          type: PM_DATE_FILTER_INPUT_TYPES.TWO_DATE,
          filterType: BaseDateFilterClass.type,
        };
      }
    }
    for (const operator of this.getAllowedOperators()) {
      // handled above
      if (operator === PM_DATE_FILTER_OPERATORS.DATE_RANGE) {
        continue;
      }
      const searchParamValue = savedFilter
        ? this.getSavedFilterValue({ savedFilter, operator })
        : this.getSearchParamValue({ currentSearchParams, operator });
      if (!searchParamValue || (Array.isArray(searchParamValue) && searchParamValue.length === 0)) {
        continue;
      }

      switch (operator) {
        case PM_DATE_FILTER_OPERATORS.AFTER:
        case PM_DATE_FILTER_OPERATORS.BEFORE:
        case PM_DATE_FILTER_OPERATORS.EQUAL_TO:
          return {
            operator,
            type: PM_DATE_FILTER_INPUT_TYPES.ONE_DATE,
            value: searchParamValue,
            filterType: BaseDateFilterClass.type,
          };
        case PM_DATE_FILTER_OPERATORS.NOT_DATE_RANGE:
          const [firstDate, secondDate] = Array.isArray(searchParamValue)
            ? searchParamValue
            : searchParamValue.split(",");
          return {
            operator,
            type: PM_DATE_FILTER_INPUT_TYPES.TWO_DATE,
            firstDate,
            secondDate,
            filterType: BaseDateFilterClass.type,
          };

        case PM_DATE_FILTER_OPERATORS.IN_THE_PAST:
          let timeframeValue = getIntervalFromFormattedValue(searchParamValue);
          let numberValue = searchParamValue ? getIntervalValuesFromFormattedValue(searchParamValue) : "";
          return {
            operator,
            type: PM_DATE_FILTER_INPUT_TYPES.TIMEFRAME,
            timeframeValue,
            numberValue,
            filterType: BaseDateFilterClass.type,
          };
        case PM_DATE_FILTER_OPERATORS.OLDER_THAN:
          timeframeValue = getOffsetFromFormattedValue(searchParamValue);
          numberValue = searchParamValue ? getOffsetValueFromFormattedValue(searchParamValue) : "";
          return {
            operator,
            type: PM_DATE_FILTER_INPUT_TYPES.TIMEFRAME,
            timeframeValue,
            numberValue,
            filterType: BaseDateFilterClass.type,
          };
        default:
          continue;
      }
    }
    return null;
  }

  getNewValueFromNewOperator({
    newOperator,
    currentPendingOperatorAndValue,
  }: {
    newOperator: PmDateFilterAllowedOperators;
    currentPendingOperatorAndValue: NonNullable<PmDateFilterOperatorValue>;
  }): NonNullable<PmDateFilterOperatorValue> {
    const oldType = currentPendingOperatorAndValue.type;
    let type: PmDateFilterInputTypes = PM_DATE_FILTER_INPUT_TYPES.ONE_DATE;
    switch (newOperator) {
      case PM_DATE_FILTER_OPERATORS.EQUAL_TO:
      case PM_DATE_FILTER_OPERATORS.BEFORE:
      case PM_DATE_FILTER_OPERATORS.AFTER:
        if (oldType === PM_DATE_FILTER_INPUT_TYPES.ONE_DATE) {
          return {
            operator: newOperator,
            value: currentPendingOperatorAndValue.value,
            type,
            filterType: BaseDateFilterClass.type,
          };
        } else if (oldType === PM_DATE_FILTER_INPUT_TYPES.TWO_DATE) {
          return {
            operator: newOperator,
            value: currentPendingOperatorAndValue.firstDate,
            type,
            filterType: BaseDateFilterClass.type,
          };
        } else if (oldType === PM_DATE_FILTER_INPUT_TYPES.TIMEFRAME) {
          return { operator: newOperator, value: "", type, filterType: BaseDateFilterClass.type };
        }
        break;
      case PM_DATE_FILTER_OPERATORS.DATE_RANGE:
      case PM_DATE_FILTER_OPERATORS.NOT_DATE_RANGE:
        type = PM_DATE_FILTER_INPUT_TYPES.TWO_DATE;
        if (oldType === PM_DATE_FILTER_INPUT_TYPES.ONE_DATE) {
          return {
            operator: newOperator,
            firstDate: currentPendingOperatorAndValue.value,
            secondDate: "",
            type,
            filterType: BaseDateFilterClass.type,
          };
        } else if (oldType === PM_DATE_FILTER_INPUT_TYPES.TWO_DATE) {
          return {
            operator: newOperator,
            firstDate: currentPendingOperatorAndValue.firstDate,
            secondDate: currentPendingOperatorAndValue.secondDate,
            type,
            filterType: BaseDateFilterClass.type,
          };
        } else if (oldType === PM_DATE_FILTER_INPUT_TYPES.TIMEFRAME) {
          return { operator: newOperator, firstDate: "", secondDate: "", type, filterType: BaseDateFilterClass.type };
        }
        break;
      case PM_DATE_FILTER_OPERATORS.OLDER_THAN:
      case PM_DATE_FILTER_OPERATORS.IN_THE_PAST:
        type = PM_DATE_FILTER_INPUT_TYPES.TIMEFRAME;
        if (oldType === PM_DATE_FILTER_INPUT_TYPES.TIMEFRAME) {
          let timeframeValue = currentPendingOperatorAndValue.timeframeValue;
          // the interval functions can't handle weeks
          if (newOperator === PM_DATE_FILTER_OPERATORS.IN_THE_PAST && timeframeValue === "Weeks") {
            timeframeValue = "Days";
          }
          return {
            operator: newOperator,
            timeframeValue,
            numberValue: currentPendingOperatorAndValue.numberValue,
            type,
            filterType: BaseDateFilterClass.type,
          };
        } else {
          return {
            operator: newOperator,
            timeframeValue: "Days",
            numberValue: "",
            type,
            filterType: BaseDateFilterClass.type,
          };
        }
      default:
        break;
    }
    throw new Error("Need to implement new input type for getNewValueFromNewOperator");
  }

  getOnApplyClick({
    location,
    closePopover,
    history,
    currentPendingOperatorValue,
    savedFilter,
  }: {
    location: ReturnType<typeof useLocation>;
    history: ReturnType<typeof useHistory>;
    closePopover: () => void;
    currentPendingOperatorValue: NonNullable<PmDateFilterOperatorValue>;
    savedFilter: SavedFilterObject;
  }) {
    return () => {
      const savedSearchParams = this.getQueryParamsFromSavedFilter({ savedFilter, location });
      this.deleteAllParamValues({ paramsToMutate: savedSearchParams, location });

      switch (currentPendingOperatorValue.operator) {
        case PM_DATE_FILTER_OPERATORS.DATE_RANGE:
          if (!currentPendingOperatorValue.firstDate || !currentPendingOperatorValue.secondDate) {
            // don't apply changes
            return;
          }
          const firstKey = this.getFullQueryParamKey({ operator: PM_DATE_FILTER_OPERATORS.AFTER, target: "url" });
          const secondKey = this.getFullQueryParamKey({ operator: PM_DATE_FILTER_OPERATORS.BEFORE, target: "url" });
          savedSearchParams.set(firstKey, currentPendingOperatorValue.firstDate);
          savedSearchParams.set(secondKey, currentPendingOperatorValue.secondDate);
          break;
        case PM_DATE_FILTER_OPERATORS.NOT_DATE_RANGE:
          const notInRangeKey = this.getFullQueryParamKey({
            operator: currentPendingOperatorValue.operator,
            target: "url",
          });
          if (!currentPendingOperatorValue.firstDate || !currentPendingOperatorValue.secondDate) {
            // don't apply changes
            return;
          }
          const value = [currentPendingOperatorValue.firstDate, currentPendingOperatorValue.secondDate].join(",");
          savedSearchParams.set(notInRangeKey, value);
          break;
        case PM_DATE_FILTER_OPERATORS.AFTER:
        case PM_DATE_FILTER_OPERATORS.BEFORE:
        case PM_DATE_FILTER_OPERATORS.EQUAL_TO:
          if (!currentPendingOperatorValue.value) {
            return;
          }
          const oneDateKey = this.getFullQueryParamKey({
            operator: currentPendingOperatorValue.operator,
            target: "url",
          });
          savedSearchParams.set(oneDateKey, currentPendingOperatorValue.value);
          break;
        case PM_DATE_FILTER_OPERATORS.OLDER_THAN:
          if (!currentPendingOperatorValue.numberValue) {
            return;
          }
          const olderThanKey = this.getFullQueryParamKey({
            operator: currentPendingOperatorValue.operator,
            target: "url",
          });
          const olderThanValue = getFormattedOlderThanValue(
            currentPendingOperatorValue.numberValue,
            currentPendingOperatorValue.timeframeValue
          );
          savedSearchParams.set(olderThanKey, olderThanValue);
          break;
        case PM_DATE_FILTER_OPERATORS.IN_THE_PAST:
          if (!currentPendingOperatorValue.numberValue) {
            return;
          }
          const intervalKey = this.getFullQueryParamKey({
            operator: currentPendingOperatorValue.operator,
            target: "url",
          });
          const intervalValue = getFormattedIntervalValue(
            currentPendingOperatorValue.numberValue,
            currentPendingOperatorValue.timeframeValue
          );
          savedSearchParams.set(intervalKey, intervalValue);
          break;
      }
      history.replace({
        pathname: location.pathname,
        search: savedSearchParams.toString(),
      });
      closePopover();
    };
  }

  // currently all filters allow all options. If this changes we'll have to move
  // this settings to the config
  getSelectedTimeframeOptions({ selectedOperator }: { selectedOperator: PmDateFilterAllowedOperators }) {
    if (selectedOperator === PM_DATE_FILTER_OPERATORS.OLDER_THAN) {
      return PM_DATE_FILTER_TIMEFRAME_OPTIONS;
    } else if (selectedOperator === PM_DATE_FILTER_OPERATORS.IN_THE_PAST) {
      // the interval functions can't handle weeks
      return PM_DATE_FILTER_TIMEFRAME_OPTIONS.filter((opt) => opt.value !== "Weeks");
    } else {
      throw new Error(
        `getSelectedTimeframeOptions should only be called with OLDER_THAN or IN_THE_PAST, you used: ${selectedOperator}`
      );
    }
  }

  getAppliedFilterCount({ currentOperatorAndValue }: { currentOperatorAndValue: PmDateFilterOperatorValue }): number {
    if (!currentOperatorAndValue) {
      return 0;
    }
    return 1;
  }

  protected getFullQueryParamKey({
    operator,
    target,
  }: {
    operator: PmDateFilterAllowedOperators;
    target: "savedFilter" | "url";
  }): string {
    const prefix = this.getQueryParamKeyPrefix();
    const suffixOverrides = this.getSuffixOverrides();
    switch (operator) {
      // note that DATE_RANGE does not have a single query param key, but uses GREATER and LESSER
      case PM_DATE_FILTER_OPERATORS.DATE_RANGE:
        throw new Error("DATE_RANGE does not have a single param, do not call this function with this operator");
      case PM_DATE_FILTER_OPERATORS.NOT_DATE_RANGE:
        const bracketString = target === "savedFilter" ? "" : "[]";
        return prefix + (suffixOverrides?.[NOT_IN_DATE_RANGE_SUFFIX] || NOT_IN_DATE_RANGE_SUFFIX) + bracketString;
      case PM_DATE_FILTER_OPERATORS.EQUAL_TO:
        return prefix + (suffixOverrides?.[EQUAL_TO_SUFFIX] || EQUAL_TO_SUFFIX);
      case PM_DATE_FILTER_OPERATORS.OLDER_THAN:
        return prefix + (suffixOverrides?.[OLDER_THAN_SUFFIX] || OLDER_THAN_SUFFIX);
      case PM_DATE_FILTER_OPERATORS.IN_THE_PAST:
        return prefix + (suffixOverrides?.[IN_THE_PAST_SUFFIX] || IN_THE_PAST_SUFFIX);
      case PM_DATE_FILTER_OPERATORS.BEFORE:
        return prefix + (suffixOverrides?.[DATE_RANGE_SECOND_SUFFIX] || DATE_RANGE_SECOND_SUFFIX);
      case PM_DATE_FILTER_OPERATORS.AFTER:
        return prefix + (suffixOverrides?.[DATE_RANGE_FIRST_SUFFIX] || DATE_RANGE_FIRST_SUFFIX);
      default:
        return prefix;
    }
  }

  private getSuffixOverrides() {
    return this.getConfig().suffixOverrides;
  }
}
