var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import styled from "@emotion/styled";
import React from "react";
import URL from "@pm-shared/utils/url";
const EmptyDiv = styled.div`
  text-align: center;
  margin-top: 30px;
`;
const EmptySprite = styled.span`
  overflow: hidden;
  display: block;
  background: url(${URL.getStatic("static/img/empty-view.png")}) no-repeat;
  background-size: contain;
  max-width: 367px;
  width: 100%;
  height: 174px;
  margin: auto;
`;
const H4 = styled.h4`
  color: #4e515e;
  font-family: Open Sans, Helvetica, Roboto, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.375rem;
`;
const EmptyView = (props) => /* @__PURE__ */ React.createElement(EmptyDiv, __spreadValues({ "data-test": "empty-view" }, props), /* @__PURE__ */ React.createElement(EmptySprite, null), props.message && /* @__PURE__ */ React.createElement(H4, null, props.message), props.children || /* @__PURE__ */ React.createElement(React.Fragment, null));
export default EmptyView;
