import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

class TenantMeldCancellationRequestAPI extends BaseApi {
  static deleteNote(id: number) {
    return this.delete(ApiUrls.managerTenantCancellationRequest(id));
  }
}

export default TenantMeldCancellationRequestAPI;
