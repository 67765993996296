import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiLink } from "@elastic/eui";
import { css } from "@emotion/react";

import { getRowStyle, HIGHLIGHT_KEYBOARD_COLOR } from "@pm-frontend/shared/utils/search-utils";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import { useGlobalSearchKeyboardActions } from "./hooks";

interface BaseSearchResultRowProps {
  _id: string;
  _index: string;
  fields: { viewable: boolean[] };
}

interface InnerComponentProps<T> {
  onClick: (subResultType: string | undefined) => void;
  item: T;
  style: React.CSSProperties;
  searchResultIndex: number;
}

type AppType = "management" | "vendor";

interface SiteSearchDesktopBaseResultsProps<T extends BaseSearchResultRowProps> {
  items: T[];
  onResultClicked: (subResultType: string | undefined) => void;
  Component: React.FC<InnerComponentProps<T>>;
  hasNextPage: boolean | undefined;
  fetchNextPage: () => void;
  isFetching: boolean;
  highlightedIndex: number | undefined;
  setHighlightedIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  appType: AppType;
}

/***
 * This component handles displaying individual search rows.
 *
 * @param items an array of items of type T[]
 *
 * @param Component the component to render each search row - its props must
 * be onClose and item: T (which must match the items param)
 */
function SiteSearchDesktopBaseResults<T extends BaseSearchResultRowProps>({
  items,
  Component,
  onResultClicked,
  hasNextPage,
  fetchNextPage,
  isFetching,
  highlightedIndex,
  setHighlightedIndex,
  appType,
}: SiteSearchDesktopBaseResultsProps<T>) {
  const isMobile = useIsMobile();

  useGlobalSearchKeyboardActions(items, highlightedIndex, setHighlightedIndex, (_: string) =>
    onResultClicked(undefined)
  );

  const element = document.getElementById(`search-result-index-${highlightedIndex}`);
  if (element && highlightedIndex) {
    element.scrollIntoView({ behavior: "auto", block: "center" });
  }

  if (items.length === 0) {
    return null;
  }
  const fetchMoreResults = () => {
    if (hasNextPage && !isFetching) {
      fetchNextPage();
    }
  };

  const resultsContainerCSS = isMobile
    ? css`
        gap: 12px;
      `
    : css`
        border-radius: 6px;
        border: 1px solid #ffffff;
        gap: 0px;
        #search-result-index-${highlightedIndex} {
          background-color: ${HIGHLIGHT_KEYBOARD_COLOR};
        }
      `;

  return (
    <EuiFlexGroup direction="column" css={resultsContainerCSS}>
      {items.map((item, index, arr) => {
        return (
          <EuiFlexItem grow={false} key={item._id + item._index}>
            <Component
              onClick={onResultClicked}
              item={item}
              style={getRowStyle(item, index, arr, appType)}
              searchResultIndex={index}
            />
          </EuiFlexItem>
        );
      })}
      {hasNextPage && (
        <EuiFlexItem
          grow={false}
          style={{
            height: "48px",
            padding: "0px 0px 0px 44px",
            justifyContent: "center",
            alignSelf: "flex-start",
          }}
        >
          <EuiLink onClick={fetchMoreResults}>View More</EuiLink>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
}

export { SiteSearchDesktopBaseResults, SiteSearchDesktopBaseResultsProps, AppType, BaseSearchResultRowProps };
