import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Text,
  Box,
  Flex,
  Image,
  Button,
} from "@chakra-ui/react";
import URL from "@pm-shared/utils/url";
import Storage from "@pm-assets/js/common/storage";
import { IoShareOutline } from "react-icons/io5";
import { colors } from "@pm-frontend/styles";
import { fetchCurrentVendorAgent } from "@pm-property-management-hub/src/utils/api/vendors";

interface IBeforeInstallPromptEvent extends Event {
  prompt(): Promise<void>;
}

export function useAddToHomescreenPrompt(): [IBeforeInstallPromptEvent | null, () => void] {
  const [prompt, setState] = React.useState<IBeforeInstallPromptEvent | null>(null);
  const [requested, setRequested] = React.useState(false);
  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }

    setRequested(true);
  };

  useEffect(() => {
    const ready = (e: IBeforeInstallPromptEvent) => {
      e.preventDefault();
      setState(e);

      if (requested) {
        e.prompt();
      }
    };

    window.addEventListener("beforeinstallprompt", ready as any);

    return () => {
      window.removeEventListener("beforeinstallprompt", ready as any);
    };
  }, []);

  return [prompt, promptToInstall];
}

function detectAndroid() {
  const toMatch = [/Android/i];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

function detectApple() {
  const toMatch = [/iPhone/i, /iPad/i, /iPod/i];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

function isRunningStandalone() {
  return window.matchMedia("(display-mode: standalone)").matches;
}

export const PwaInstallModal = ({
  PwaInstallClosed,
  setState,
}: {
  PwaInstallClosed: string;
  setState: (x: any) => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  const { data: agentDetails } = fetchCurrentVendorAgent();

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (prompt) {
      setIsOpen(true);
    }
  }, [prompt]);

  if (PwaInstallClosed || agentDetails?.prompt_for_mobile) {
    return null;
  } else if ((detectApple() || detectAndroid()) && !isRunningStandalone()) {
    return (
      <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <div>
              <Box textAlign="center">
                <Text>Add to Home Screen</Text>
                <Image boxSize="100px" src={URL.getStatic("img/PM-App-Icon-v2.png")} />
              </Box>
            </div>
          </ModalHeader>
          {detectAndroid() ? (
            <ModalCloseButton
              style={{
                color: "var(--brand-meld-blue, #1175CC)",
                background: "transparent",
                cursor: "pointer",
              }}
            />
          ) : null}
          <ModalBody>
            <Text textAlign="center">Please download the app to your home screen for a better mobile experience.</Text>
            {detectAndroid() ? <Text textAlign="center">Tap Download then "Install App"</Text> : <></>}
            {detectApple() ? (
              <Text textAlign="center" size="lg">
                Tap <IoShareOutline size="2em" color={colors.brand.meldBlue} /> then "Add to Home Screen"
              </Text>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Box width="100%">
              <Flex flexDirection="column" alignItems="center">
                {detectAndroid() ? (
                  <Button
                    cursor="pointer"
                    onClick={() => {
                      promptToInstall();
                    }}
                  >
                    Download
                  </Button>
                ) : null}
                {detectApple() ? (
                  <Button
                    cursor="pointer"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Close
                  </Button>
                ) : null}
                <Text
                  cursor="pointer"
                  textAlign="center"
                  textDecoration="underline"
                  onClick={() => {
                    setState({ PwaInstallClosed: true });
                    Storage.setItem("PWAInstallClosed", "true");
                    handleClose();
                  }}
                >
                  Do not show again.
                </Text>
              </Flex>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  return null;
};
