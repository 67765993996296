import I from "immutable";

import AddressUtils from "../../utils/address-utils";
import { asImmer } from "../../../../app/utils/decorators";
import * as C from "../../constants";
import { ImmutableMap } from "../../types/common";
import { VendorNode } from "../../common/graphql/types";

type Vendor = ImmutableMap | VendorNode;

class VendorUtils {
  static vendorSpecialties = Object.keys(C.VendorSpecialtiesText).sort();

  static getEmailAddress(vendor: I.Map<string, string>, emptyMsg = "No email") {
    return vendor.get("email") || emptyMsg;
  }

  @asImmer()
  static getPhone(vendor: Vendor, emptyMsg = "No phone") {
    vendor = vendor as VendorNode;

    if (!vendor) {
      return emptyMsg;
    }
    return vendor.phone || emptyMsg;
  }

  static getFax(vendor: I.Map<string, string>, emptyMsg = "No Fax") {
    return vendor.get("fax") || emptyMsg;
  }

  static getAddressLine1(vendor: I.Map<string, any>, emptyMsg = "No Address") {
    return AddressUtils.getAddressLine1(vendor.get("address"), emptyMsg);
  }

  static getAddressLine2(vendor: I.Map<string, any>, ...args: any[]) {
    return AddressUtils.getAddressLine2(vendor.get("address"), ...args);
  }

  static getAddressLine3(vendor: I.Map<string, any>, ...args: any[]) {
    return AddressUtils.getAddressLine3(vendor.get("address"), ...args);
  }

  static getFormattedCityStateZip(vendor: I.Map<string, any>) {
    return AddressUtils.getFormattedCityStateZip(vendor.get("address"));
  }
}

export default VendorUtils;
