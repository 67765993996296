var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import classnames from "classnames";
import Link from "@pm-shared/utils/link";
import React from "react";
let getLiProps = function() {
  let classes = classnames({
    "pillbox-section": true,
    active: this.props.isActive
  });
  classes = `${classes} ${this.props.className}`;
  let liProps = {
    className: classes
  };
  if (this.props.onClick) {
    liProps.onClick = this.props.onClick;
  }
  return liProps;
};
export default class Pill extends React.Component {
  render() {
    let liProps = getLiProps.call(this);
    return /* @__PURE__ */ React.createElement("li", __spreadValues({}, liProps), /* @__PURE__ */ React.createElement(Link, __spreadValues({ className: "pill-button" }, this.props.linkAttrs), this.props.children));
  }
}
Pill.defaultProps = {
  className: ""
};
export class NonLinkPill extends React.Component {
  render() {
    let liProps = getLiProps.call(this);
    return /* @__PURE__ */ React.createElement("li", __spreadValues({}, liProps), /* @__PURE__ */ React.createElement("div", __spreadValues(__spreadProps(__spreadValues({}, this.props), { className: "pill-button" }), this.props.linkAttrs), this.props.children));
  }
}
NonLinkPill.defaultProps = {
  className: ""
};
