import alt from "../../alt";

import TenantSettingsApi from "../apis/tenant-settings-api";
import messageActions from "../../common/actions/message-actions";

class TenantSettingsActions {
  constructor() {
    this.generateActions("fetchCurrent", "fetchCurrentDone", "fetchCurrentFail", "updateDone", "updateFail");
  }

  update(data, { cid } = {}) {
    TenantSettingsApi.update(data)
      .then(res => {
        this.updateDone({ data: res.data, cid });
      })
      .catch(res => {
        if (res.messages.length) {
          messageActions.storeError({ body: res.messages[0] });
        }
        this.updateFail({ data: res, cid });
      });

    return { cid };
  }
}

export default alt.createActions(TenantSettingsActions);
