import alt from "../../alt";

import OwnerApi from "../apis/owner-api";
import messageActions from "../../common/actions/message-actions";

class OwnerActions {
  constructor() {
    this.generateActions(
      "fetchOwners",
      "fetchOwnersDone",
      "fetchOwnersFail",
      "fetchOwner",
      "fetchOwnerDone",
      "fetchOwnerFail",
      "deleteOwnerDone",
      "deleteOwnerFail",
      "saveOwnerDone",
      "saveOwnerFail",
      "updateOwnerDone",
      "updateOwnerFail"
    );
  }

  saveOwner(owner, cid) {
    OwnerApi.saveOwner(owner, cid)
      .then(res => {
        this.saveOwnerDone({ data: res.data, cid: cid });
      })
      .catch(res => {
        if (res.messages.length) {
          messageActions.storeError({ body: res.messages[0] });
        }
        this.saveOwnerFail({ data: res, cid });
      });
    return { cid };
  }

  updateOwner(owner, cid) {
    OwnerApi.updateOwner(owner, cid)
      .then(res => {
        this.updateOwnerDone({ data: res.data, cid: cid });
      })
      .catch(res => {
        if (res.messages.length) {
          messageActions.storeError({ body: res.messages[0] });
        }
        this.updateOwnerFail({ data: res, cid });
      });
    return { cid };
  }

  deleteOwner(id, cid) {
    OwnerApi.deleteOwner(id)
      .then(res => {
        this.deleteOwnerDone({ id, cid });
      })
      .catch(res => {
        if (res.messages.length) {
          messageActions.storeError({ body: res.messages[0] });
        }
        this.deleteOwnerFail({ data: res, id, cid });
      });

    return { cid };
  }
}

export default alt.createActions(OwnerActions);
