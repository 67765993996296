import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiSpacer } from "@elastic/eui";

const PmPageLoading = ({ size = "xxl" }: { size?: "s" | "m" | "l" | "xl" | "xxl" }) => (
  <>
    <EuiSpacer size="l" />
    <EuiFlexGroup alignItems="center" justifyContent="spaceAround" direction="column" gutterSize="s">
      <EuiFlexItem grow={false} data-testid="page-loading-indicator">
        <EuiLoadingSpinner size={size} />
      </EuiFlexItem>
    </EuiFlexGroup>
  </>
);

export { PmPageLoading };
