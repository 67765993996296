import actions from "../actions/integration-partner-actions";
import alt from "../../alt";
import BaseStore from "../../stores/base-store";
import IntegrationPartnerSource from "../sources/integration-partner-source";

function bySlug(slug) {
  return integration => integration.get("slug") === slug;
}

export class IntegrationPartnerStore extends BaseStore {
  constructor() {
    super({ idField: "slug" });

    this.registerAsync(IntegrationPartnerSource);

    this.bindListeners({
      fetchingAll: actions.fetchAll,
      fetchAllDone: actions.fetchAllDone,
      fetchAllFail: actions.fetchAllFail,
      fetchDone: actions.fetchBySlugDone,
      fetchFail: actions.fetchBySlugFail,
      sync: actions.sync,
      syncFail: actions.syncFail
    });

    this.exportPublicMethods({
      getBySlug: this.getBySlug.bind(this)
    });
  }

  getBySlug(slug) {
    return this.getModelState().find(bySlug(slug));
  }

  sync({ slug }) {
    let [index, model] = this.getModelState().findEntry(bySlug(slug));

    let updatedModel = model.setIn(["integration", "is_syncing"], true).setIn(["integration", "enabled"], true);

    this.setModelState(this.getModelState().set(index, updatedModel));
  }

  syncFail({ slug }) {
    let [index, model] = this.getModelState().findEntry(bySlug(slug));

    let updatedModel = model.setIn(["integration", "is_syncing"], false);

    this.setModelState(this.getModelState().set(index, updatedModel));
  }
}

export default alt.createStore(IntegrationPartnerStore, "IntegrationPartnerStore");
