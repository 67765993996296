import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

class CommentAPI extends BaseApi {
  static fetchComments(options: any) {
    return this.get(ApiUrls.commentList, options);
  }

  static fetchComment(id: number, clazz: string) {
    return this.get(ApiUrls.commentDetail(id) + `?clazz=${clazz}`);
  }

  static createComment(comment: any) {
    return this.post(ApiUrls.commentList, comment);
  }

  static updateComment(comment: any) {
    return this.patch(ApiUrls.commentDetail(comment.id), comment);
  }

  static deleteComment(id: number) {
    return this.delete(ApiUrls.commentDetail(id));
  }
}

export default CommentAPI;
