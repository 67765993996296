import React from "react";

import { AlertProps } from "../components/alert/alert";
import { showRedesignNavigation } from "@pm-assets/js/utils/redesign-routing";

export enum BackgroundColor {
  White = "background-white",
  Grey = "background-grey",
  LightGrey = "background-light-grey",
}

export interface AppContextProviderState {
  actions: {
    setBackgroundColor: (color: BackgroundColor) => void;
    setAlert: (alert: React.ReactElement<AlertProps>) => void;
    clearAlert: () => void;
  };

  backgroundColor: BackgroundColor;
  alert: React.ReactElement<AlertProps> | undefined;
}

const AppContext = React.createContext<Partial<AppContextProviderState>>({});

export class AppContextProvider extends React.Component<{}, AppContextProviderState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      actions: {
        setBackgroundColor: this.setBackgroundColor.bind(this),
        setAlert: this.setAlert.bind(this),
        clearAlert: this.clearAlert.bind(this),
      },

      backgroundColor: BackgroundColor.White,
      alert: undefined,
    };
  }

  setBackgroundColor(backgroundColor: BackgroundColor) {
    if (showRedesignNavigation() && backgroundColor === BackgroundColor.Grey) {
      return;
    }
    this.setState({ backgroundColor });
  }

  setAlert(alert: React.ReactElement<AlertProps>) {
    this.setState({ alert });
  }

  clearAlert() {
    this.setState({ alert: undefined });
  }

  render() {
    const context = this.state;

    return <AppContext.Provider value={{ ...context }}>{this.props.children}</AppContext.Provider>;
  }
}

export default AppContext;
