import React from "react";

/* tslint:disable:max-line-length */
export default class StampSVG extends React.Component<any> {
  render() {
    return (
      <svg xmlns="https://www.w3.org/2000/svg" viewBox="-21 0 511 511.999" className="stamp-svg">
        <path d="M469.527 364.824c0-44.687-36.355-81.043-81.043-81.043H291.86v-53.058c0-24.817 7.829-48.489 22.047-66.653 14.09-18.004 21.535-39.625 21.535-62.53 0-26.919-10.421-52.274-29.347-71.407-18.914-19.117-44.125-29.817-71-30.125-26.64-.293-51.973 9.71-71.324 28.21-19.364 18.512-30.497 43.372-31.352 70-.762 23.833 6.894 47.255 21.559 65.946 15.046 19.18 23.335 42.816 23.335 66.559v53.058H81.543C36.855 283.781.5 320.137.5 364.821v76.523h35.328V512H434.2v-70.656h35.328zM177.578 145.648c-10.324-13.16-15.715-29.664-15.176-46.472 1.235-38.59 33.664-69.582 72.348-69.176 38.98.45 70.695 32.543 70.695 71.54 0 16.136-5.242 31.37-15.164 44.042-18.324 23.418-28.418 53.652-28.418 85.14v53.06H207.31v-53.06c0-30.425-10.559-60.64-29.73-85.074zm226.625 336.356H65.824v-40.66h338.38zM30.496 411.348v-46.524c0-28.148 22.899-51.047 51.047-51.047h306.941c28.149 0 51.047 22.899 51.047 51.047v46.524zm0 0" />
      </svg>
    );
  }
}
/* tslint:enable:max-line-length */
