import { History, Location } from "history";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import { FeatureFlagClientHelper } from "../feature-flags";
import { LargeLoader } from "../../loaders";
import { LinkUtils } from "@pm-shared/utils/link";
import RouteUtils from "../utils/route-utils";
import AppContext, { AppContextProvider } from "../../../../app/contexts/AppContext";
import { page } from "@pm-app/utils/analytics";

type RouteChangeProps = RouteComponentProps<any>;

interface RouteChangeState {
  forceUpdateOnRouteChanged: boolean;
}

class RouteChange extends React.Component<RouteChangeProps, RouteChangeState> {
  constructor(props: RouteChangeProps) {
    super(props);

    this.state = {
      forceUpdateOnRouteChanged: false,
    };
  }

  render() {
    if (!FeatureFlagClientHelper.isClientReady) {
      return <LargeLoader />;
    }

    return (
      <AppContextProvider>
        <AppContext.Consumer>
          {(context) => {
            if (context) {
              return <div className={context.backgroundColor}>{this.props.children}</div>;
            }
          }}
        </AppContext.Consumer>
      </AppContextProvider>
    );
  }

  componentDidMount() {
    FeatureFlagClientHelper.onClientReady(() => {
      this.forceUpdate();
    });

    FeatureFlagClientHelper.onClientChange(() => {
      this.setState({ forceUpdateOnRouteChanged: true });
    });
  }

  componentWillUpdate(nextProps: any) {
    if (this.props.location !== nextProps.location) {
      this.onRouteChanged(nextProps.location, nextProps.history);
    }
  }

  onRouteChanged(location: Location, history: History) {
    LinkUtils.reloadIfVersionOld(location, history);
    page();

    if (this.state.forceUpdateOnRouteChanged) {
      this.setState({ forceUpdateOnRouteChanged: false });
      this.forceUpdate();
    }
  }

  componentWillReceiveProps() {
    RouteUtils.savePrevRoute(this.props.location);
  }
}

export default withRouter(RouteChange);
