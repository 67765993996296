var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const Edit = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement(
  "path",
  {
    d: "M33 40H3a3 3 0 01-3-3V7a3 3 0 013-3h16.75a1 1 0 010 2H3a1 1 0 00-1 1v30a1 1 0 001 1h30a1 1 0 001-1V20.25a1 1 0 012 0V37a3 3 0 01-3 3z",
    transform: "translate(0 -.59)"
  }
), /* @__PURE__ */ React.createElement(
  "path",
  {
    d: "M11.87 33.13h-4a1 1 0 01-1-1v-4a1 1 0 01.29-.71L27.71 6.88a1 1 0 011.41 0l4 4a1 1 0 010 1.41L12.58 32.84a1 1 0 01-.71.29zm-3-2h2.58L31 11.59 28.41 9 8.87 28.55zM34.87 10.13a1 1 0 01-.71-.29l-4-4a1 1 0 010-1.42L33.71.88a1 1 0 011.41 0l4 4a1 1 0 010 1.41l-3.54 3.55a1 1 0 01-.71.29zm-2.59-5l2.59 2.59L37 5.59 34.41 3z",
    transform: "translate(0 -.59)"
  }
));
