import DocumentTitle from "react-document-title";
import I from "immutable";
import React from "react";

import Activity from "./records/activity";
import Button, { ButtonTypes } from "../../../app/components/buttons/Button";
import ButtonContainer from "../../../app/components/containers/ButtonContainer";
import connect from "../common/connect";
import EmptyView from "@pm-shared/components/empty/empty-view";
import { ImmutableMap } from "../types/common";
import { LargeLoader } from "../loaders";
import MultiButton from "../../../app/components/buttons/MultiButton";
import { NonLinkPagination } from "../pagination";
import Notification from "./records/notification";
import NotificationApi from "./api/notification-api";
import NotificationComponent from "./notification-component";
import NotificationStore from "./stores/notification-store";
import NotificationsUnreadStore from "./stores/notifications-unread-store";
import { ErrorHandler } from "../../../app/utils/ErrorHandler";

interface NotificationsProps {
  notifications: I.List<ImmutableMap>;
}

interface NotificationsState {
  failed?: boolean;
  retry?: () => void;
}

class Notifications extends React.Component<NotificationsProps, NotificationsState> {
  constructor(props: NotificationsProps) {
    super(props);

    this.fetch = this.fetch.bind(this);
    this.markPageRead = this.markPageRead.bind(this);
  }

  render() {
    if (NotificationStore.fetchAllResult.loading) {
      return <LargeLoader />;
    }

    if (this.state.failed) {
      return (
        <EmptyView message="There was an issue retrieving your notifications">
          <Button buttonType={ButtonTypes.Primary} onClick={this.state.retry}>
            Click here to retry
          </Button>
        </EmptyView>
      );
    }

    let notifications: React.ReactFragment = this.props.notifications.map((notification: Notification) => {
      return <NotificationComponent notification={notification} key={notification.get("id")} />;
    });

    if (!this.props.notifications.size) {
      notifications = <div className="notification message">There's no activity yet.</div>;
    }

    return (
      <DocumentTitle title="Notifications">
        <div className="main row">
          <div className="columns notification-heading-container">
            <h1 className="notifications-header">Notifications</h1>
            <ButtonContainer inline={true}>
              <MultiButton
                buttonType={ButtonTypes.Primary}
                mainLabel="Mark All as Read"
                onMainClicked={this.markAllRead}
              >
                <Button onClick={this.markPageRead}>Mark Page as Read</Button>
              </MultiButton>
            </ButtonContainer>
          </div>
          <div className="columns">{notifications}</div>
          <NonLinkPagination
            hasPrev={NotificationStore.canFetchPrevious()}
            hasNext={NotificationStore.canFetchNext()}
            onPrev={() => this.fetch(NotificationStore.fetchPrevious)}
            onNext={() => this.fetch(NotificationStore.fetchNext)}
          />
        </div>
      </DocumentTitle>
    );
  }

  fetch(fetcher: () => { catch: (res: any) => void }) {
    this.setState({ failed: false, retry: () => this.fetch(fetcher) });

    fetcher().catch(() => this.setState({ failed: true }));
  }

  markAllRead() {
    NotificationApi.markAllRead()
      .then(NotificationStore.refetch)
      .then(NotificationsUnreadStore.fetchUnread)
      .catch((error) => ErrorHandler.handleAxiosError(error));
  }

  markPageRead() {
    let notificationIds = this.props.notifications
      .reduce((ids: I.List<string>, n: Notification) => {
        return ids.concat(n.get("activities").map((a: Activity) => a.get("id")));
      }, I.List<string>())
      .toJS();
    void NotificationApi.markRead(notificationIds);
    NotificationStore.markActivitiesRead(I.Set(notificationIds));
  }

  componentWillMount() {
    this.fetch(NotificationStore.fetchAll);
  }
}

export default connect(Notifications, {
  stores: [NotificationStore],
  props: {
    notifications() {
      return NotificationStore.getFetchedModels();
    },
  },
});
