var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiFlyout } from "@elastic/eui";
import { ApplicationSidebarBase } from "./ApplicationSidebarBase";
import { useSwipe } from "@pm-frontend/shared/hooks/useSwipe";
const ApplicationSidebarMobile = (props) => {
  useSwipe({ handleSwipeLeft: props.toggleOpen, handleSwipeRight: () => {
  } });
  return /* @__PURE__ */ React.createElement(EuiFlyout, { onClose: props.toggleOpen, side: "left", maxWidth: false }, /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "column",
      style: { padding: "19px 24px 64px 24px", gap: "32px", scrollbarGutter: "stable" },
      className: "eui-yScroll"
    },
    /* @__PURE__ */ React.createElement(ApplicationSidebarBase, __spreadValues({}, props)),
    props.mobileItems && props.mobileItems.map((item, index) => /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false, key: index }, item))
  ));
};
export { ApplicationSidebarMobile };
