import DetailBody from "../../common/components/detail-body";
import DetailHeader from "../../common/components/detail-header";
import DetailView from "../../common/components/detail-view";
import DocumentTitle from "react-document-title";
import EmptyView from "../../../../web/shared/components/empty/empty-view";
import Link from "../../../../web/shared/utils/link";
import MeldUtils from "../../meld/utils/meld-utils";
import React from "react";

export default class CantScheduleWarning extends React.Component {
  render() {
    let { meld } = this.props;

    return (
      <DocumentTitle title="Schedule Availability">
        <div className="main row">
          <DetailView>
            <DetailHeader>
              <h2 className="detail-title">Schedule {meld.get("brief_description")}</h2>
            </DetailHeader>
            <DetailBody>
              <EmptyView>
                <div>
                  <h4>This meld has been closed and can no longer be scheduled.</h4>
                  <p>
                    Need to send a message?{" "}
                    <Link to={`/meld/${meld.get("id")}/messages/`} className="chat-link">
                      Start chatting here.
                    </Link>
                  </p>
                  <div>
                    <Link to="/melds/melding/" className="melds-link" query={MeldUtils.defaultVendorMeldQuery}>
                      View Melds
                    </Link>
                    •
                    <Link to="/melds/incoming/" className="incoming-requests-link">
                      View Meld Requests
                    </Link>
                  </div>
                </div>
              </EmptyView>
            </DetailBody>
          </DetailView>
        </div>
      </DocumentTitle>
    );
  }
}
