import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

class VendorAPI extends BaseApi {
  static finishRegistration(data: any) {
    return this.post(ApiUrls.finishVendorRegistration, data);
  }

  static fetchAll() {
    return this.get(ApiUrls.vendorList);
  }

  static fetchOne(id: number) {
    return this.get(ApiUrls.vendorDetail(id));
  }

  static deleteVendor(id: number) {
    return this.delete(ApiUrls.vendorDetail(id));
  }

  static fetchAbbreviated() {
    return this.get(ApiUrls.abbreviatedVendorList);
  }

  static updateExcludedPropertyGroups(id: number, excludedPropertyGroupIds: number[]) {
    return this.patch(ApiUrls.vendorExcludedPropertyGroupsList(id), {
      excluded_property_groups: excludedPropertyGroupIds,
    });
  }

  static update(vendor: any) {
    return this.patch(ApiUrls.vendorDetail(vendor.id), vendor);
  }

  static updateNotes(vendor: any) {
    return this.put(ApiUrls.vendorDetail(vendor.id), vendor);
  }
}

export default VendorAPI;
