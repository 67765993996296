import React from "react";
import classnames from "classnames";
import { isArray } from "lodash";
import FormError from "../form-error/form-error";
import { MdCheck, MdRemove } from "react-icons/md";

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  /**
   * Validation errors.
   */
  errors?: string | string[] | null;
  /**
   * @default false
   */
  inline?: boolean;
  partial?: boolean;
}

/**
 * Due to how errors are handled, there are 2 ways to use this component.
 * This guarantees that the errors are shown in a predictable way.
 *
 * If not inline:
 *
 * ~~~jsx
 * <Label>
 *   Remember password?
 *
 *   <Checkbox />
 * </Label>
 * ~~~
 *
 * If inline:
 *
 * ~~~jsx
 * <Label>
 *   <Checkbox inline={inline}>
 *     Remember password?
 *   </Checkbox>
 * </Label>
 * ~~~
 */
export const Checkbox: React.FC<Props> = ({
  errors,
  inline = false,
  partial = false,
  children,
  className,
  ...elementProps
}) => {
  const classes = classnames(
    "pm-forms-checkbox-wrap",
    {
      "pm-forms-checkbox-inline": inline
    },
    className ? className.split(" ") : ""
  );

  return (
    <>
      <div className={classes}>
        <input type="checkbox" className="pm-forms-true-checkbox" {...elementProps} />

        <span className={`pm-forms-checkbox ${errors ? "pm-forms-checkbox-error" : ""}`}>
          {partial ? <MdRemove /> : <MdCheck />}
        </span>
      </div>

      {children}

      {errors && <FormError>{isArray(errors) ? errors.join(", ") : errors}</FormError>}
    </>
  );
};

Checkbox.displayName = "Checkbox";

export default Checkbox;
