var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import classnames from "classnames";
import React from "react";
import SVGInline from "react-svg-inline";
export var ButtonTypes = /* @__PURE__ */ ((ButtonTypes2) => {
  ButtonTypes2["Primary"] = "primary";
  ButtonTypes2["Danger"] = "danger";
  ButtonTypes2["Cancel"] = "cancel";
  ButtonTypes2["Neutral"] = "neutral";
  return ButtonTypes2;
})(ButtonTypes || {});
const loader = require("../../assets/icons/Load.svg");
const check = require("../../assets/icons/Check.svg");
class Button extends React.Component {
  render() {
    const _a = this.props, {
      buttonType,
      justifyContent,
      flexColumns,
      showSuccess,
      className,
      submit,
      children,
      showLoading
    } = _a, props = __objRest(_a, [
      "buttonType",
      "justifyContent",
      "flexColumns",
      "showSuccess",
      "className",
      "submit",
      "children",
      "showLoading"
    ]);
    const classes = classnames(
      "pm-button",
      {
        [buttonType]: !!buttonType,
        [justifyContent]: !!justifyContent,
        "flex-columns": flexColumns,
        loading: showLoading,
        success: showSuccess
      },
      className
    );
    const rawType = submit ? "submit" : "button";
    return /* @__PURE__ */ React.createElement("button", __spreadValues({ type: rawType, className: classes }, props), /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "button-children-container" }, children), showLoading && /* @__PURE__ */ React.createElement("div", { className: "indication-container" }, /* @__PURE__ */ React.createElement(SVGInline, { className: "button-indication", svg: loader })), showSuccess && /* @__PURE__ */ React.createElement("div", { className: "indication-container success-indication" }, /* @__PURE__ */ React.createElement(SVGInline, { className: "button-indication", svg: check }))));
  }
}
export default Button;
