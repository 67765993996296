import Activity from "./records/activity";
import CalendarUtils from "../utils/calendar-utils";
import Check from "../svg/check-svg";
import classnames from "classnames";
import Link from "@pm-shared/utils/link";
import moment from "moment";
import NotificationApi from "./api/notification-api";
import notificationStore from "./stores/notification-store";
import NotificationUtils from "./utils/notification-utils";
import Popout from "../svg/popout-svg";
import React from "react";
import truncate from "truncate";

interface ActivityComponentProps {
  activity: Activity;
  hidden?: boolean;
}

class ActivityComponent extends React.Component<ActivityComponentProps> {
  constructor(props: ActivityComponentProps) {
    super(props);

    this.markRead = this.markRead.bind(this);
  }

  render() {
    let url = NotificationUtils.getUrl(this.props.activity);
    const markedRead = this.props.activity.isRead();
    let activityClasses = classnames({
      activity: true,
      "unread-activity": !markedRead,
      visible: !this.props.hidden,
    });

    let dayMonthTime = CalendarUtils.formatDayMonthTime(this.props.activity.get("time"), { timeFmt: "h:mma" });
    return (
      <div className={activityClasses}>
        <div className="activity-line-container">
          <div className="activity-line">
            <Link
              to={url}
              className="link"
              onClick={this.markRead}
              title={NotificationUtils.getText(this.props.activity)}
            >
              {truncate(NotificationUtils.getText(this.props.activity), 100)}
            </Link>
            <Link
              to={url}
              target="_blank"
              className="notification-popout"
              onClick={this.markRead}
              title="Open in new window"
            >
              <Popout />
            </Link>
          </div>
          <i className="copy time" title={dayMonthTime}>
            {moment.utc(this.props.activity.get("time")).fromNow()}
          </i>
        </div>
        <div className="buttons">
          {markedRead || (
            <button className="mark-read" onClick={this.markRead}>
              <Check />
            </button>
          )}
        </div>
      </div>
    );
  }

  markRead() {
    const activityId = this.props.activity.get("id");
    void NotificationApi.markRead([activityId]);
    notificationStore.markActivityRead(activityId);
  }

  componentWillReceiveProps(nextProps: ActivityComponentProps) {
    if (nextProps.activity.isRead()) {
      this.setState({ markedRead: true });
    }
  }
}

export default ActivityComponent;
