import React from "react";
import SVGInline from "react-svg-inline";
import classnames from "classnames";

function getRotationClass(rotation: number) {
  if (rotation === 90 || rotation === 180) {
    return `rotate-${rotation}`;
  }

  return "";
}

export interface SvgButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  withBorder?: boolean;
}

/**
 * Returns a pm-button object made of the provided SVG image
 * @param props The button props
 * @param icon The svg to use as the image
 * @param rotate An optional setting to rotate the svg to your needs (90 | 180 | 270)
 */
function SvgButton(props: SvgButtonProps, icon: any, rotate: 0 | 90 | 180 | 270 = 0) {
  const classes = classnames("pm-svgbutton", "pm-button", props.className, {
    "with-border": props.withBorder
  });
  return (
    <button type="button" className={classes} onClick={props.onClick} disabled={props.disabled}>
      <SVGInline className={`svg-${getRotationClass(rotate)}`} svg={icon} />
    </button>
  );
}

export default SvgButton;
