import I from "immutable";
import _ from "lodash";
import moment from "moment";

import { asImmer } from "../../../../app/utils/decorators";
import { ErrorHandler } from "../../../../app/utils/ErrorHandler";
import { MeldNode, WorkEntryNode } from "../../common/graphql/types";
import { hasPerm } from "../../common/utils/permission-utils";
import PhoneUtils from "../../common/utils/phone-utils";
import * as C from "../../constants";
import AgentUtils from "../../management/utils/agent-utils";
import UnitUtils from "../../property/utils/unit-utils";
import { ImmutableMap } from "../../types/common";
import CalendarUtils from "../../utils/calendar-utils";
import { LinkHelper } from "@pm-shared/utils/link";
import VendorUtils from "../../vendor/utils/vendor-utils";
import VendorMeldUtils from "../../vendor_app/meld/utils/vendor-meld-utils";
import AssignmentRequestUtils from "./assignment-request-utils";
import SettingsStore from "../../management/stores/settings-store";
import { Meld as Melding } from "@pm-owner-hub/src/utils/api-types";
import PropertyUtils from "@pm-assets/js/property/utils/property-utils";
import AddressUtils from "@pm-assets/js/utils/address-utils";
import Features from "@pm-assets/js/common/feature-flags";
import {
  ManagementAppointmentSerializer,
  VendorAppointmentSerializer,
} from "@pm-frontend/shared/types/api/meld/serializers/meld_detail_view_serializer";
type Meld = ImmutableMap | MeldNode | { [key: string]: any };

class MeldUtils {
  static inProgressStatuses = [
    C.MeldStatuses.PENDING_COMPLETION,
    C.MeldStatuses.OPEN,
    C.MeldStatuses.PENDING_TENANT_AVAILABILITY,
    C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY,
    C.MeldStatuses.PENDING_MORE_MANAGEMENT_AVAILABILITY,
    C.MeldStatuses.PENDING_VENDOR,
  ].sort();

  static nonClosedStatuses = MeldUtils.inProgressStatuses.concat(C.MeldStatuses.PENDING_ASSIGNMENT).sort();

  static inactiveStatuses = [
    C.MeldStatuses.MANAGER_CANCELED,
    C.MeldStatuses.TENANT_CANCELED,
    C.MeldStatuses.COMPLETED,
    C.MeldStatuses.VENDOR_COULD_NOT_COMPLETE,
    C.MeldStatuses.MAINTENANCE_COULD_NOT_COMPLETE,
  ].sort();

  static tenantCompleteStatuses = [
    C.MeldStatuses.COMPLETED,
    C.MeldStatuses.MANAGER_CANCELED,
    C.MeldStatuses.TENANT_CANCELED,
    C.MeldStatuses.VENDOR_COULD_NOT_COMPLETE,
    C.MeldStatuses.MAINTENANCE_COULD_NOT_COMPLETE,
  ].sort();

  static closedStatuses = [C.MeldStatuses.MANAGER_CANCELED, C.MeldStatuses.TENANT_CANCELED].sort();

  static schedulableStatuses = new Set([
    C.MeldStatuses.PENDING_COMPLETION,
    C.MeldStatuses.OPEN,
    C.MeldStatuses.PENDING_TENANT_AVAILABILITY,
    C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY,
    C.MeldStatuses.PENDING_MORE_MANAGEMENT_AVAILABILITY,
  ]);

  static requestMoreAvailabilityStatuses = [
    C.MeldStatuses.OPEN,
    C.MeldStatuses.PENDING_TENANT_AVAILABILITY,
    C.MeldStatuses.PENDING_COMPLETION,
  ].sort();

  static defaultQuery = { saved_filter: "default" };

  static overdueFilter = {
    scheduled__lte: moment().startOf("hour").toDate().getTime(),
    status: MeldUtils.nonClosedStatuses,
    ordering: C.DEFAULT_MELD_SORT,
  };

  static defaultVendorMeldQuery = {
    status: MeldUtils.inProgressStatuses,
    ordering: C.DEFAULT_VENDOR_TICKET_SORT,
  };

  static isImmutableMap(obj: I.Map<string, any> | any): obj is I.Map<string, any> {
    if (obj === undefined) {
      return false;
    }

    return (obj as I.Map<string, any>).asImmutable !== undefined;
  }

  static immutableMapOrObject(obj: I.Map<string, any> | any) {
    let isI = true;

    if (!this.isImmutableMap(obj)) {
      obj = I.fromJS(obj);
      isI = false;
    }

    return [obj, isI];
  }

  @asImmer()
  static getMeldID(meld: ImmutableMap | MeldNode | any) {
    meld = meld as MeldNode;
    return meld.id;
  }

  @asImmer()
  static getAllManagementAppointmentStartDates(meld: ImmutableMap | MeldNode | any) {
    const events = meld.managementappointment
      ?.map((appointment: ManagementAppointmentSerializer) => appointment?.availability_segment?.event?.dtstart)
      .filter((dtstart: Date) => dtstart !== undefined);

    return events;
  }

  @asImmer()
  static getAllVendorAppointmentStartDates(meld: ImmutableMap | MeldNode | any) {
    const events = meld.vendorappointment
      ?.map((appointment: VendorAppointmentSerializer) => appointment?.availability_segment?.event?.dtstart)
      .filter((dtstart: Date) => dtstart !== undefined);

    return events;
  }

  static getReminders(meld: I.Map<string, any> | any) {
    let reminders;
    [meld] = this.immutableMapOrObject(meld);
    reminders = meld.get("reminders");
    return reminders;
  }

  @asImmer()
  static getMeldProject(meld: ImmutableMap | MeldNode) {
    meld = meld as MeldNode;
    return meld.project;
  }

  @asImmer()
  static getMeldProjectName(meld: ImmutableMap | MeldNode) {
    meld = meld as MeldNode;
    return meld.project?.name;
  }

  @asImmer()
  static getMeldProjectID(meld: ImmutableMap | MeldNode) {
    meld = meld as MeldNode;
    return meld.project?.id;
  }

  @asImmer()
  static getMeldDueDate(meld: ImmutableMap | MeldNode) {
    meld = meld as MeldNode;
    return meld.due_date;
  }

  @asImmer()
  static getUnit(meld: Meld): any {
    meld = meld as MeldNode;
    return meld.unit;
  }

  @asImmer()
  static getMeldReferenceID(meld: I.Map<string, any> | MeldNode) {
    meld = meld as MeldNode;
    return meld.reference_id;
  }

  static getNotifyOwner(meld: I.Map<string, any> | any) {
    let notifyOwner;
    [meld] = this.immutableMapOrObject(meld);
    notifyOwner = meld.get("notify_owner");
    return notifyOwner;
  }

  static getTenants(meld: I.Map<string, any> | any) {
    let tenants;
    [meld] = this.immutableMapOrObject(meld);
    tenants = meld.get("tenants");
    return tenants;
  }

  @asImmer()
  static getTenantPresenceRequired(meld: I.Map<string, any> | MeldNode) {
    meld = meld as MeldNode;
    return meld.tenant_presence_required;
  }

  @asImmer()
  static getMeldStatus(meld: I.Map<string, any> | MeldNode) {
    meld = meld as MeldNode;
    return meld.status;
  }

  @asImmer()
  static getMeldMaintenanceNotes(meld: I.Map<string, any> | MeldNode) {
    meld = meld as MeldNode;
    return meld.maintenance_notes;
  }

  @asImmer()
  static getMeldCompletionNotes(meld: I.Map<string, any> | MeldNode) {
    meld = meld as MeldNode;
    return meld.completion_notes;
  }

  static getMeldInvoice(meld: I.Map<string, any> | any) {
    let invoice;
    let isI;
    [meld, isI] = this.immutableMapOrObject(meld);
    invoice = meld.get("meld_invoice");
    return isI ? invoice : invoice?.toJS();
  }

  @asImmer()
  static getMeldCreated(meld: ImmutableMap | MeldNode) {
    meld = meld as MeldNode;
    return meld.created;
  }

  @asImmer()
  static getOwnerApprovedBy(meld: ImmutableMap | MeldNode) {
    meld = meld as MeldNode;
    return meld.owner_approved_by;
  }

  @asImmer()
  static getMeldManagerCancelled(meld: ImmutableMap | MeldNode) {
    meld = meld as MeldNode;
    return meld.manager_cancelled;
  }

  @asImmer()
  static getMeldManagerCancellationReason(meld: ImmutableMap | MeldNode) {
    meld = meld as MeldNode;
    return meld.manager_cancellation_reason;
  }

  @asImmer()
  static getMeldTenantRequest(meld: ImmutableMap | MeldNode) {
    meld = meld as MeldNode;
    return meld.tenant_request;
  }

  @asImmer()
  static getMeldManagerCanceller(meld: ImmutableMap | MeldNode) {
    meld = meld as MeldNode;
    return meld.manager_canceller;
  }

  @asImmer()
  static getMeldCompletionDate(meld: ImmutableMap | MeldNode) {
    meld = meld as MeldNode;
    return meld.completion_date;
  }

  static getMeldWorkEntry(meld: I.Map<string, any> | any) {
    let workEntry;
    [meld] = this.immutableMapOrObject(meld);
    workEntry = meld.get("open_work_entry");
    return workEntry;
  }

  static getWorkEntryCheckIn(workEntry: ImmutableMap | WorkEntryNode) {
    workEntry = workEntry as WorkEntryNode;
    return workEntry.checkin;
  }

  static getMeldOwners(meld: I.Map<string, any> | any) {
    let owners;
    [meld] = this.immutableMapOrObject(meld);
    owners = meld.get("owners");
    return owners;
  }

  /**
   * Returns the name of the user that closed the meld; full name for managers, vendor name for vendors.
   * Will return undefined if no closer exists
   * @param {Map<string, any>} meld
   * @returns {string | undefined}
   */
  @asImmer()
  static getNameOfCloserForMeld(meld: I.Map<string, any> | MeldNode): string | undefined {
    meld = meld as MeldNode;
    let closerString;
    const managementAgentCloser = meld.management_agent_closer;
    const vendorCloser = meld.vendor_closer;

    if (managementAgentCloser) {
      closerString = `by ${AgentUtils.getFullName(managementAgentCloser)}`;
    } else if (vendorCloser) {
      closerString = `by ${vendorCloser.name}`;
    }
    return closerString;
  }

  @asImmer()
  static getMarkedCompleteString(meld: ImmutableMap | MeldNode) {
    meld = meld as MeldNode;
    if (meld.marked_complete) {
      return CalendarUtils.formatDayMonthTime(meld.marked_complete, { timeFmt: "h:mma" });
    }
  }

  @asImmer()
  static getMeldManagementRequester(meld: ImmutableMap | MeldNode) {
    meld = meld as MeldNode;
    return meld.management_requester;
  }

  static getFormattedAddress(meld: I.Map<string, any>) {
    return meld.getIn(["unit", "address", "line_1"]);
  }

  static getPriority(meld: I.Map<string, any>) {
    return meld.get("priority");
  }

  @asImmer()
  static getDescription(meld: I.Map<string, any> | { description: string }) {
    meld = meld as MeldNode;
    return meld.description;
  }

  @asImmer()
  static getBriefDescription(meld: I.Map<string, any> | any) {
    meld = meld as MeldNode;
    return meld.brief_description;
  }

  @asImmer()
  static getVendorAssignmentRequests(meld: I.Map<string, any> | MeldNode) {
    meld = meld as MeldNode;
    return meld.vendor_assignment_requests;
  }

  static getLastVendorRejection(meld: I.Map<string, any> | any) {
    let lastRejection;
    let isI;
    [meld, isI] = this.immutableMapOrObject(meld);

    let vendAssignReqsts = meld.get("vendor_assignment_requests");
    if (vendAssignReqsts && vendAssignReqsts.size) {
      lastRejection = vendAssignReqsts
        .filter((req: I.Map<string, any>) => req.get("rejected") && req.get("reject_reason"))
        .last();
    }

    return isI ? lastRejection : lastRejection?.toJS();
  }

  @asImmer()
  static getFormattedStatus(meld: Meld) {
    meld = meld as MeldNode;

    let status = meld.status.toString();

    switch (status) {
      case C.MeldStatuses.COMPLETED:
        return "Completed";
      case C.MeldStatuses.MANAGER_CANCELED:
        return "Manager canceled";
      case C.MeldStatuses.PENDING_COMPLETION:
        return "In progress";
      case C.MeldStatuses.TENANT_CANCELED:
        return "Tenant canceled";
      case C.MeldStatuses.PENDING_TENANT_AVAILABILITY:
        return "Pending tenant availability";
      case C.MeldStatuses.OPEN:
        return "Open";
      case C.MeldStatuses.VENDOR_COULD_NOT_COMPLETE:
        return "Vendor could not complete";
      case C.MeldStatuses.MAINTENANCE_COULD_NOT_COMPLETE:
        return "Maintenance could not complete";
      case C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY:
        return "More availability requested";
      case C.MeldStatuses.PENDING_MORE_MANAGEMENT_AVAILABILITY:
        return "More availability requested";
      case C.MeldStatuses.PENDING_ASSIGNMENT:
        return "Pending assignment";
      case C.MeldStatuses.PENDING_VENDOR:
        return "Pending vendor acceptance";
      case C.MeldStatuses.PENDING_ESTIMATES:
        return "Pending Estimates";
      default:
        ErrorHandler.handleError(new Error(`Unknown meld status ${status}`));
    }
  }

  static getFormattedAddressFields(meld: I.Map<string, any>) {
    if (!meld.get("unit")) {
      return "";
    }
    return UnitUtils.getDisplayAddressLines(meld.get("unit"));
  }

  static getFormattedPropertyMajorFields(meld: I.Map<string, any>) {
    if (!meld.get("unit")) {
      return "";
    }
    return UnitUtils.getDisplayCityStateZip(meld.get("unit"));
  }

  static getFormattedPropertyMinorFields(meld: I.Map<string, any>, emptyMsg: string) {
    if (!meld.get("unit")) {
      return "";
    }
    return UnitUtils.getFormattedBuildingFloorAndUnit(meld.get("unit"), emptyMsg);
  }

  @asImmer()
  static getApptEventStart(meld: I.Map<string, any> | MeldNode, index: number = 0) {
    meld = meld as MeldNode;

    let event = meld.vendorappointment?.[index]?.availability_segment?.event?.dtstart;

    if (!event) {
      event = meld.managementappointment?.[index]?.availability_segment?.event?.dtstart;
    }

    return event;
  }

  static formatScheduledStartAndEnd(
    meld: I.Map<string, any>,
    emptyMsg = "Not scheduled",
    index: number = 0,
    previous: boolean = false,
    tenant_app: boolean = false,
    vendor_app: boolean = false
  ) {
    const dtstart = MeldUtils.getApptEventStart(meld, index);
    const dtend = MeldUtils.getApptEventEnd(meld, index);

    if (!dtstart || !dtend) {
      return emptyMsg;
    }

    if (Features.isMultipleAppointmentsEnabled() && (tenant_app || vendor_app)) {
      const now = new Date();

      if (!previous && new Date(dtstart) < now) {
        return;
      } else if (previous && new Date(dtstart) > now) {
        return;
      }
    }

    let start = CalendarUtils.formatDayMonthTime(dtstart, {
      separator: ", ",
      timeFmt: "h:mma",
    });

    let end = CalendarUtils.renderFormattedTime(dtend);

    return `${start} - ${end}`;
  }

  static formatScheduledStartAndEndMultiples(meld: I.Map<string, any>, emptyMsg = "Not scheduled") {
    const dtstart = MeldUtils.getApptEventStart(meld);
    const dtend = MeldUtils.getApptEventEnd(meld);

    if (!dtstart || !dtend) {
      return emptyMsg;
    }

    let start = CalendarUtils.formatDayMonthTime(MeldUtils.getApptEventStart(meld), {
      separator: ", ",
      timeFmt: "h:mma",
    });

    let end = CalendarUtils.renderFormattedTime(MeldUtils.getApptEventEnd(meld));

    return `${start} - ${end}`;
  }

  static getApptEventEnd(meld: I.Map<string, any>, index: number = 0) {
    const getFirstDtend = (appointments: any) => {
      if (appointments && appointments.size > 0) {
        return appointments.getIn([index, "availability_segment", "event", "dtend"]);
      }
      return null;
    };

    let vendorAppointments = meld.get("vendorappointment");
    if (vendorAppointments && vendorAppointments.size > 0) {
      let event = getFirstDtend(vendorAppointments);
      if (event) {
        return event;
      }
    }

    let managementAppointments = meld.get("managementappointment");
    if (managementAppointments && managementAppointments.size > 0) {
      let event = getFirstDtend(managementAppointments);
      if (event) {
        return event;
      }
    }

    return null;
  }

  static doesNeedAvailability(meld: I.Map<string, any>) {
    let status = meld.get("status");
    return (
      status === C.MeldStatuses.PENDING_TENANT_AVAILABILITY ||
      status === C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY
    );
  }

  static isInProgress(meld: I.Map<string, any>) {
    let status = meld.get("status");
    let inProgressStatuses = MeldUtils.inProgressStatuses;

    return _.includes(inProgressStatuses, status);
  }

  static isClosed(meld: I.Map<string, any>) {
    let status = meld.get("status");
    let closedStatuses = MeldUtils.closedStatuses;

    return _.includes(closedStatuses, status);
  }

  static canMgrEdit(meld: I.Map<string, any>) {
    let editableStatuses = new Set([
      C.MeldStatuses.PENDING_COMPLETION,
      C.MeldStatuses.OPEN,
      C.MeldStatuses.PENDING_TENANT_AVAILABILITY,
      C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY,
      C.MeldStatuses.PENDING_MORE_MANAGEMENT_AVAILABILITY,
      C.MeldStatuses.PENDING_VENDOR,
      C.MeldStatuses.PENDING_ASSIGNMENT,
    ]);

    return editableStatuses.has(meld.get("status"));
  }

  static canVendorScheduleAvailability(meld: I.Map<string, any>) {
    let status = meld.get("status");

    return (
      status === C.MeldStatuses.PENDING_TENANT_AVAILABILITY ||
      status === C.MeldStatuses.OPEN ||
      status === C.MeldStatuses.PENDING_COMPLETION ||
      status === C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY
    );
  }

  static canTenantReview(meld: I.Map<string, any>) {
    return meld.get("status") === C.MeldStatuses.COMPLETED;
  }

  @asImmer()
  static canManagerMarkComplete(meld: Meld) {
    meld = meld as MeldNode;

    return meld.managementappointment && meld.status.toString() === C.MeldStatuses.PENDING_COMPLETION;
  }

  static canManagerBypassAndMarkComplete(meld: I.Map<string, any>) {
    return _.includes(MeldUtils.nonClosedStatuses, meld.get("status"));
  }

  static canEditAvailabilities(meld: I.Map<string, any>) {
    return meld.get("tenant_presence_required") && meld.get("has_registered_tenant") && MeldUtils.isInProgress(meld);
  }

  static canMgrEditAvailabilities(meld: I.Map<string, any>) {
    return meld.get("managementappointment") && MeldUtils.canEditAvailabilities(meld);
  }

  static canVendorEditAvailabilities(meld: I.Map<string, any>) {
    return (
      meld.get("vendorappointment") && MeldUtils.getOpenVendorAssignment(meld) && MeldUtils.canEditAvailabilities(meld)
    );
  }

  static canEditAppointment(meld: I.Map<string, any>) {
    return !meld.get("tenant_presence_required") && MeldUtils.isInProgress(meld);
  }

  static canMgrEditAppointment(meld: I.Map<string, any>) {
    return meld.get("managementappointment") && MeldUtils.canEditAppointment(meld);
  }

  static canVendorEditAppointment(meld: I.Map<string, any>) {
    return (
      meld.get("vendorappointment") && MeldUtils.getOpenVendorAssignment(meld) && MeldUtils.canEditAppointment(meld)
    );
  }

  static canManagerCancel(meld: I.Map<string, any>) {
    return _.includes(MeldUtils.nonClosedStatuses, meld.get("status"));
  }

  static canVendorInvoice(meld: I.Map<string, any>) {
    return _.includes([C.MeldStatuses.COMPLETED, C.MeldStatuses.VENDOR_COULD_NOT_COMPLETE], meld.get("status"));
  }

  static canVendorMarkComplete(meld: I.Map<string, any>) {
    return _.includes(
      [
        C.MeldStatuses.PENDING_COMPLETION,
        C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY,
        C.MeldStatuses.PENDING_TENANT_AVAILABILITY,
      ],
      meld.get("status")
    );
  }

  static canVendorBypassAndMarkComplete(meld: I.Map<string, any>) {
    return _.includes(
      [C.MeldStatuses.PENDING_MORE_VENDOR_AVAILABILITY, C.MeldStatuses.PENDING_TENANT_AVAILABILITY],
      meld.get("status")
    );
  }

  static canAssignmentChange(meld: I.Map<string, any>) {
    return _.includes(MeldUtils.nonClosedStatuses, meld.get("status"));
  }

  static canReassign(meld: I.Map<string, any>) {
    return (
      hasPerm(C.Perms.CAN_CHANGE_MELDS) && MeldUtils.canAssignmentChange(meld) && MeldUtils.getAssignedMaint(meld).size
    );
  }

  static canEdit(meld: I.Map<string, any>) {
    return MeldUtils.isInProgress(meld.get("meld"));
  }

  @asImmer()
  static canAssign(meld: Meld) {
    meld = meld as MeldNode;

    let status = meld.status.toString();
    return hasPerm(C.Perms.CAN_CHANGE_MELDS) && status === C.MeldStatuses.PENDING_ASSIGNMENT;
  }

  static canAddAvailabilities(meld: I.Map<string, any>) {
    return (
      meld.get("tenant_presence_required") &&
      meld.get("has_registered_tenant") &&
      MeldUtils.isInProgress(meld) &&
      !MeldUtils.isScheduledNotProjects(meld) &&
      !MeldUtils.getAssignedVendor(meld)
    );
  }

  @asImmer()
  static isSchedulable(meld: Meld) {
    meld = meld as MeldNode;

    return MeldUtils.schedulableStatuses.has(meld.status.toString() as C.MeldStatuses);
  }

  static isCompleteForTenant(meld: I.Map<string, any>) {
    let status = meld.get("status");
    let completeStatuses = MeldUtils.tenantCompleteStatuses;

    return _.includes(completeStatuses, status);
  }

  static getStatus(meld: I.Map<string, any> | any) {
    let status;
    [meld] = this.immutableMapOrObject(meld);
    status = meld.get("status");
    return status;
  }

  static getCompletionNotes(meld: I.Map<string, any> | any) {
    let completionNotes;
    [meld] = this.immutableMapOrObject(meld);
    completionNotes = meld.get("completion_notes");
    return completionNotes;
  }

  static getReasonCannotComplete(meld: I.Map<string, any> | any) {
    let reasonCannotComplete;
    [meld] = this.immutableMapOrObject(meld);
    reasonCannotComplete = meld.get("reason_cannot_complete");
    return reasonCannotComplete;
  }

  @asImmer()
  static getTenantRating(meld: I.Map<string, any> | MeldNode) {
    meld = meld as MeldNode;
    return meld.tenant_rating;
  }

  @asImmer()
  static getTenantReview(meld: I.Map<string, any> | MeldNode) {
    meld = meld as MeldNode;
    return meld.tenant_review;
  }

  @asImmer()
  static getTenantReviewDate(meld: I.Map<string, any> | MeldNode) {
    meld = meld as MeldNode;
    return meld.tenant_review_date;
  }

  @asImmer()
  static isScheduled(meld: Meld) {
    meld = meld as MeldNode;

    const managementAppointmentEdge = meld.managementappointment?.edges?.[0]?.node?.availability_segment;
    const vendorAppointmentEdge = meld.vendorappointment?.edges?.[0]?.node?.availability_segment;

    return managementAppointmentEdge || vendorAppointmentEdge;
  }

  @asImmer()
  static isScheduledNotProjects(meld: Meld) {
    meld = meld as MeldNode;
    return (
      (meld.managementappointment?.[0] && meld.managementappointment?.[0]?.availability_segment) ||
      (meld.vendorappointment?.[0] && meld.vendorappointment?.[0]?.availability_segment)
    );
  }

  static getScheduledDate(meld: Meld) {
    meld = meld as MeldNode;

    if (meld.managementappointment?.edges?.[0] && meld.managementappointment.edges[0].node.availability_segment) {
      return meld.managementappointment.edges[0].node.availability_segment.event.dtstart;
    } else if (meld.vendorappointment?.edges?.[0] && meld.vendorappointment.edges[0].node.availability_segment) {
      return meld.vendorappointment.edges[0].node.availability_segment.event.dtstart;
    }
  }

  @asImmer()
  static isInactive(meld: ImmutableMap | MeldNode) {
    meld = meld as MeldNode;

    return new Set(MeldUtils.inactiveStatuses).has(meld.status.toString() as C.MeldStatuses);
  }

  static canMgrSelectAppointment(meld: I.Map<string, any>) {
    return meld.get("can_mgr_choose_appt") && MeldUtils.isInProgress(meld);
  }

  static canAcknowledgeApproval(meld: I.Map<string, any> | any) {
    [meld] = this.immutableMapOrObject(meld);
    return (
      !meld.get("owner_approval_acknowledged") &&
      [C.OwnerApprovalStatuses.OWNER_APPROVAL_APPROVED, C.OwnerApprovalStatuses.OWNER_APPROVAL_NOT_APPROVED].includes(
        meld.get("owner_approval_status")
      )
    );
  }

  @asImmer()
  static isInHouse(meld: Meld) {
    meld = meld as MeldNode;

    return meld.in_house_servicers && meld.in_house_servicers.length > 0;
  }

  @asImmer()
  static getAbbreviatedPropertyListLine1(meld: Meld) {
    let unit = MeldUtils.getUnit(meld);
    if (unit) {
      return UnitUtils.getDisplayStreetAndUnit(unit);
    } else {
      return AddressUtils.getAddressLine1(MeldUtils.getMeldProperty(meld));
    }
  }

  @asImmer()
  static getAbbreviatedPropertyListLine2(meld: I.Map<string, any>) {
    let unit = MeldUtils.getUnit(meld);
    if (unit) {
      return UnitUtils.getDisplayCityStateZip(unit);
    } else {
      return AddressUtils.getFormattedCityStateZip(MeldUtils.getMeldProperty(meld));
    }
  }

  static getOpenVendorAssignment(meld: I.Map<string, any>) {
    return meld.get("vendor_assignment_requests").find((assignment: I.Map<string, any>) => {
      return AssignmentRequestUtils.isAssigned(assignment);
    });
  }

  static getOpenVendorInviteAssignment(meld: I.Map<string, any>) {
    return meld.get("invite_assignments").find((assignment: I.Map<string, any>) => {
      return !assignment.get("canceled");
    });
  }

  static getAssignedVendor(meld: I.Map<string, any> | any) {
    let vendor;
    let isI;
    [meld, isI] = this.immutableMapOrObject(meld);

    let vendAssignReqsts = meld.get("vendor_assignment_requests");
    if (vendAssignReqsts && vendAssignReqsts.size) {
      let openAssignment = MeldUtils.getOpenVendorAssignment(meld);

      if (openAssignment) {
        vendor = openAssignment.get("vendor");
      }
    }

    return isI ? vendor : vendor?.toJS();
  }

  static getAssignedInvite(meld: I.Map<string, any>) {
    let inviteAssignments = meld.get("invite_assignments");
    if (inviteAssignments && inviteAssignments.size) {
      let openAssignment = MeldUtils.getOpenVendorInviteAssignment(meld);

      if (openAssignment) {
        return openAssignment;
      }
    }
  }

  static getAssignedMaintenanceName(meld: I.Map<string, any> | any, emptyMsg = "No assigned maintenance") {
    [meld] = MeldUtils.immutableMapOrObject(meld);
    let maint = MeldUtils.getAssignedMaint(meld);

    if (maint.size) {
      return maint
        .map((m: I.Map<string, any>) => {
          switch (
            m.get("type") // eslint-disable-line default-case
          ) {
            case C.MaintTypes.MANAGEMENT_AGENT:
              return AgentUtils.getFullName(m);
            case C.MaintTypes.VENDOR:
              let name = m.get("name");
              let email = m.get("email");

              if (name) {
                return name;
              }
              if (email) {
                return email;
              }
              break;
            case C.MaintTypes.INVITED_VENDOR:
              return m.getIn(["invite_group", "invite", "email"]);
          }
        })
        .join(", ");
    }

    return emptyMsg;
  }

  static getAssignedMaint(meld: I.Map<string, any>) {
    let servicers = MeldUtils.getAssignedAgents(meld);
    let vendor = MeldUtils.getAssignedVendor(meld);
    let inviteAssignment = MeldUtils.getAssignedInvite(meld);

    if (servicers.size) {
      return servicers.map((servicer: I.Map<string, any>) => servicer.set("type", C.MaintTypes.MANAGEMENT_AGENT));
    } else if (vendor) {
      return I.List([vendor.set("type", C.MaintTypes.VENDOR)]);
    } else if (inviteAssignment) {
      return I.List([inviteAssignment.set("type", C.MaintTypes.INVITED_VENDOR)]);
    }

    return I.List();
  }

  static getAssignedMaintUrl(meld: I.Map<string, any> | any) {
    let assigned;

    [meld] = this.immutableMapOrObject(meld);
    assigned = MeldUtils.getAssignedMaint(meld).first();
    // Ensure the assigned maintenance type is a vendor, and that the vendor is still part of MeldUtils management account
    if (
      assigned &&
      assigned.get("type") === C.MaintTypes.VENDOR &&
      assigned.get("managements").includes(Number.parseInt(LinkHelper.getOrgId(), 10))
    ) {
      return `/vendors/${assigned.get("id")}/summary/`;
    }
  }

  @asImmer()
  static getWorkCategory(meld: ImmutableMap | { work_category: string }) {
    meld = meld as MeldNode;
    return _.capitalize(meld.work_category.toLowerCase()).replace(/_/g, " ");
  }

  @asImmer()
  static getWorkLocation(meld: ImmutableMap | { work_location: string }) {
    meld = meld as MeldNode;
    return _.capitalize(meld.work_location.toLowerCase());
  }

  static getMaintenanceCompanyPhone(meld: I.Map<string, any>) {
    if (MeldUtils.isInHouse(meld)) {
      return meld.getIn(["management", "contact", "home_phone"]);
    } else {
      return VendorUtils.getPhone(MeldUtils.getAssignedVendor(meld));
    }
  }

  static getCancellationNotes(meld: I.Map<string, any>, emptyMsg = "No cancellation notes") {
    return meld.get("manager_cancellation_reason") || emptyMsg;
  }

  static getAssignerName(meld: I.Map<string, any>, emptyMsg: string) {
    return AgentUtils.getFullName(meld.get("assigner"), emptyMsg);
  }

  static getAssignerBusinessFormattedPhone(meld: I.Map<string, any>, emptyMsg = "No Phone") {
    return MeldUtils._getFormattedPhone(meld, AgentUtils.getBusinessPhone, emptyMsg);
  }

  static getAssignerCellFormattedPhone(meld: I.Map<string, any>, emptyMsg?: string) {
    return MeldUtils._getFormattedPhone(meld, AgentUtils.getCellPhone, emptyMsg);
  }

  static getAssignerHomeFormattedPhone(meld: I.Map<string, any>, emptyMsg?: string) {
    return MeldUtils._getFormattedPhone(meld, AgentUtils.getHomePhone, emptyMsg);
  }
  static getCoordinatorName(meld: I.Map<string, any>, emptyMsg: string) {
    return AgentUtils.getFullName(meld.get("coordinator"), emptyMsg);
  }

  static getCoordinatorBusinessFormattedPhone(meld: I.Map<string, any>, emptyMsg = "No Phone") {
    return MeldUtils._getFormattedPhone(meld, AgentUtils.getBusinessPhone, emptyMsg, "coordinator");
  }

  static getCoordinatorCellFormattedPhone(meld: I.Map<string, any>, emptyMsg?: string) {
    return MeldUtils._getFormattedPhone(meld, AgentUtils.getCellPhone, emptyMsg, "coordinator");
  }

  static getCoordinatorHomeFormattedPhone(meld: I.Map<string, any>, emptyMsg?: string) {
    return MeldUtils._getFormattedPhone(meld, AgentUtils.getHomePhone, emptyMsg, "coordinator");
  }

  static _getFormattedPhone(
    meld: I.Map<string, any>,
    phoneFinderFunc: (assigner: ImmutableMap, emptyMsg: undefined) => string | null | undefined,
    emptyMsg = "No Phone",
    agent_type = "assigner"
  ) {
    let phone = phoneFinderFunc(meld.get(agent_type), undefined);

    if (phone) {
      return PhoneUtils.format(phone);
    } else {
      return emptyMsg;
    }
  }

  static getSchedulerLink(meld: I.Map<string, any>) {
    const url = `/calendar/melds/${meld.get("id")}/book/`;
    let openVendorAssignment = MeldUtils.getOpenVendorAssignment(meld);

    if (openVendorAssignment) {
      return VendorMeldUtils.getSchedulerLink(meld, openVendorAssignment);
    } else {
      return url;
    }
  }

  static getTenantWhoRequested(meld: I.Map<string, any>) {
    return meld.getIn(["tenant_request", "tenant"]);
  }

  static isTenantPresenceRequired(meld: I.Map<string, any>) {
    return meld.get("tenant_presence_required");
  }

  static hasRegisteredTenant(meld: I.Map<string, any>) {
    return (
      meld.get("has_registered_tenant") ||
      (!meld.get("started") && meld.get("tenants").some((tenant: I.Map<string, any>) => tenant.get("user")))
    );
  }

  static getRegisteredTenants(meld: I.Map<string, any>) {
    let isI;
    [meld, isI] = this.immutableMapOrObject(meld);

    let tenants = meld.get("tenants").filter((tenant: I.Map<string, any>) => tenant.get("user"));

    return isI ? tenants : tenants.toJS();
  }

  static hasAppointment(meld: I.Map<string, any>) {
    return !!MeldUtils.getAppointmentAvailability(meld);
  }

  static getAppointmentAvailability(meld: I.Map<string, any>) {
    return (
      meld.getIn(["managementappointment", "availability_segment"]) ||
      meld.getIn(["vendorappointment", "availability_segment"])
    );
  }

  @asImmer()
  static getPetInfo(meld: any, { unknownMessage = "Not listed", noPetsMessage = "No animals" } = {}) {
    meld = meld as MeldNode;
    let info = unknownMessage;
    const hasPets: boolean = (meld as any).has_pets;

    if (hasPets === true) {
      info = meld.pets;
    } else if (hasPets === false) {
      info = noPetsMessage;
    }
    return info;
  }

  static getAssignedAgents(meld: I.Map<string, any>) {
    return (meld.get("in_house_servicers") || I.List()).map((servicer: I.Map<string, any>) => servicer.get("agent"));
  }

  static canTenantChangePresenceRequired(meld: ImmutableMap) {
    // Only allow tenants to change if the assignment hasn't been finalized and it wasn't created by the property manager.
    const presenceNeverRequired = SettingsStore.getCurrent().get("tenant_presence_required") === "NEVER";
    return (
      meld.get("tenant_request") &&
      !presenceNeverRequired &&
      new Set([C.MeldStatuses.PENDING_ASSIGNMENT, C.MeldStatuses.PENDING_VENDOR]).has(meld.get("status"))
    );
  }

  static getStatusColor = (meld: Melding) => {
    if (meld.status === "VENDOR_COULD_NOT_COMPLETE" || meld.status === "MAINTENANCE_COULD_NOT_COMPLETE") {
      return "negative";
    }

    if (["COMPLETED", "MANAGER_CANCELED", "TENANT_CANCELED"].includes(meld.status)) {
      return "complete";
    }

    if (
      ["PENDING_TENANT_AVAILABILITY", "PENDING_COMPLETION", "PENDING_MORE_VENDOR_AVAILABILITY"].includes(meld.status)
    ) {
      return "affirmative";
    }

    return "neutral";
  };

  @asImmer()
  static getMeldProperty(meld: any): any {
    return meld.prop ? meld.prop : meld.unit.prop;
  }

  @asImmer()
  static getDisplayProperty(meld: any): any {
    return meld.prop ? meld.prop : meld.unit.display_address;
  }

  @asImmer()
  static getPropertyMaintenanceNotes(meld: Meld) {
    let meldProperty = MeldUtils.getMeldProperty(meld);
    return PropertyUtils.getMaintenanceNotes(meldProperty);
  }

  @asImmer()
  static getDirectionsUrlString(meld: Meld) {
    let meldProperty = MeldUtils.getDisplayProperty(meld);
    return AddressUtils.getDirectionsUrlString(meldProperty);
  }

  @asImmer()
  static getMeldUnitName(meld: Meld) {
    let unit = MeldUtils.getUnit(meld);
    if (unit) {
      return `UNIT ${unit.unit}`;
    }
  }

  @asImmer()
  static getAddressLine1(meld: Meld) {
    return AddressUtils.getAddressLine1(MeldUtils.getMeldProperty(meld));
  }

  @asImmer()
  static getDisplayAddressLine1(meld: Meld) {
    return AddressUtils.getAddressLine1(MeldUtils.getDisplayProperty(meld));
  }

  @asImmer()
  static propertyName(meld: Meld) {
    return PropertyUtils.maybeGetName(MeldUtils.getMeldProperty(meld));
  }

  @asImmer()
  static canChangeResidents(meld: Meld) {
    return Boolean(MeldUtils.getUnit(meld));
  }
}

export default MeldUtils;
