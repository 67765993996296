import React from "react";
import StrUtils from "../utils/str-utils";

/* tslint:disable:max-line-length */
export default class ListSvg extends React.Component<{ className?: string }> {
  render() {
    return (
      <svg
        xmlns="https://www.w3.org/2000/svg"
        viewBox="0 0 40 30"
        preserveAspectRatio="xMidYMid"
        className={StrUtils.combineWords(this.props.className, "list-svg")}
      >
        <path d="M30 14H2a2 2 0 0 1 0-4h28a2 2 0 1 1 0 4zm0-10H2a2 2 0 0 1 0-4h28a2 2 0 1 1 0 4zM2 20h28a2 2 0 0 1 0 4H2a2 2 0 0 1 0-4z" />
      </svg>
    );
  }
}
/* tslint:enable:max-line-length */
