import { useEffect, useRef } from "react";

const MIN_SWIPE_DISTANCE = 50;

/**
 * Custom hook for detecting horizontal swipes in mobile and
 * and invoking specified callbacks for left and right swipes
 */
const useSwipe = ({
  handleSwipeLeft,
  handleSwipeRight,
}: {
  handleSwipeLeft: () => void;
  handleSwipeRight: () => void;
}) => {
  const startXRef = useRef<number>(0);
  const endXRef = useRef<number>(0);
  const startYRef = useRef<number>(0);
  const endYRef = useRef<number>(0);

  const handleSwipe = () => {
    const startX = startXRef.current;
    const endX = endXRef.current;
    const distanceX = startXRef.current - endXRef.current;
    const distanceY = startYRef.current - endYRef.current;
    const isLeftSwipe = distanceX > MIN_SWIPE_DISTANCE;
    const isRightSwipe = distanceX < -1 * MIN_SWIPE_DISTANCE;

    // ignore vertical swipes
    const validSwipe = startX !== 0 && endX !== 0 && Math.abs(distanceX) > Math.abs(distanceY);

    if (validSwipe) {
      if (isRightSwipe) {
        // Right swipe
        handleSwipeRight();
      } else if (isLeftSwipe) {
        // Left swipe
        handleSwipeLeft();
      }
      // Reset positions
      startXRef.current = 0;
      endXRef.current = 0;
    }
  };

  useEffect(() => {
    const handleTouchStart = (e: TouchEvent) => {
      startXRef.current = e.touches[0].clientX;
      startYRef.current = e.touches[0].clientY;
    };

    const handleTouchEnd = (e: TouchEvent) => {
      endXRef.current = e.changedTouches[0].clientX;
      endYRef.current = e.changedTouches[0].clientY;
      handleSwipe();
    };

    // Attach event listeners
    document.addEventListener("touchstart", handleTouchStart);
    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      // Clean up event listeners
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [handleSwipeRight, handleSwipeLeft]);
};

export { useSwipe };
