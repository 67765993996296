export const INTEGRATIONS = {
  APPFOLIO: "AppFolio",
  BUILDIUM: "Buildium",
  PROPERTYWARE: "Propertyware",
  RENTMANAGER: "Rent Manager",
} as const;

export type INTEGRATIONS = typeof INTEGRATIONS[keyof typeof INTEGRATIONS];

export const RentManagerModelSyncStatus = {
  LINK: "LINK",
  IMPORT: "IMPORT",
  NOT_SYNCED: "NOT_SYNCED",
} as const;

export type RentManagerModelSyncStatus = typeof RentManagerModelSyncStatus[keyof typeof RentManagerModelSyncStatus];

export interface BaseIntegrationPartnerSerializer {
  id: number;
  name: string;
  slug: string;
  logo_url: string;
  created: string;
  created_by: string;
  updated: string;
  updated_by: string;
}

export interface AppfolioAPIPartnerSerializer extends BaseIntegrationPartnerSerializer {
  integration: {
    enabled: boolean;
  };
}
export interface BaseBuildiumIntegrationPartnerSerializer extends BaseIntegrationPartnerSerializer {
  integration: {
    enabled: boolean;
  };
}
export interface GatherKudosPartnerSerializer extends BaseIntegrationPartnerSerializer {
  integration: {
    enabled: boolean;
  };
}
export interface GradeUsPartnerSerializer extends BaseIntegrationPartnerSerializer {
  integration: {
    enabled: boolean;
  };
}
export interface PropertywarePartnerSerializer extends BaseIntegrationPartnerSerializer {
  integration: {
    enabled: boolean;
  };
}
export interface RentManagerPartnerSerializer extends BaseIntegrationPartnerSerializer {
  integration: {
    enabled: boolean;
  };
}

export type IntegrationPartner =
  | AppfolioAPIPartnerSerializer
  | BaseBuildiumIntegrationPartnerSerializer
  | GradeUsPartnerSerializer
  | GatherKudosPartnerSerializer
  | PropertywarePartnerSerializer
  | RentManagerPartnerSerializer;

export type IntegrationPartnersList = IntegrationPartner[];
