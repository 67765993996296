import moment, { Moment } from "moment";
import { create } from "zustand";
import { addDays, startOfDay, subDays } from "date-fns";

import { shouldShowCalendarRedesignDueToDesktop } from "@pm-assets/js/utils/redesign-routing";
import { AggregatedCalendarEvent } from "./utils/aggregated-calendar-events-utils";
import { CalendarMapMarkerType } from "./maps/utils";
import { CALENDAR_PANE_TYPES } from "./utils/hooks";

// just a utility type for grabbing two specific variants of AggregatedCalendarEvent union
type ExtractMember<U, T extends U[keyof U]> = U extends { type: T } ? U : never;

const TIMEFRAME_LOCAL_STORAGE_KEY = `meld-calendar-timeframe-${window.PM.user.id}-${window.PM.user.multitenantId}`;

const TIMEFRAMES: Record<string, number> = {
  ONE_DAY: 1,
  THREE_DAY: 3,
  FIVE_DAY: 5,
} as const;

const setInitialSelectedTimeFrame = (timeframe: number) => {
  for (const entry of Object.entries(TIMEFRAMES)) {
    if (timeframe === entry[1]) {
      window.localStorage.setItem(TIMEFRAME_LOCAL_STORAGE_KEY, entry[0]);
      return;
    }
  }
};

const getInitialSelectedTimeFrame = (): number => {
  const value = window.localStorage.getItem(TIMEFRAME_LOCAL_STORAGE_KEY);
  if (!value) {
    return 1;
  }
  return TIMEFRAMES[value] || 1;
};

const CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY = `meld-calendar-selected-agents-${window.PM.user.id}-${window.PM.user.multitenantId}`;

const setInitialSelectedVendorsOrAgents = (state: CalendarSelectedAgentsVendorsIds) =>
  window.localStorage.setItem(CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY, JSON.stringify(state));

const getInitialSelectedVendorsOrAgents = (): CalendarSelectedAgentsVendorsIds => {
  const value = window.localStorage.getItem(CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY);

  let result: CalendarSelectedAgentsVendorsIds = { agents: [], vendors: [] };

  if (value) {
    try {
      const parsed = JSON.parse(value);
      if (Array.isArray(parsed.agents) && Array.isArray(parsed.vendors)) {
        result = parsed;
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }

  return result;
};

interface CalendarSelectedAgentsVendorsIds {
  agents: number[];
  vendors: number[];
}

interface CalendarRecommendEvent {
  personaId: number;
  personaType: "agent" | "vendor";
  previousEvent: {
    id: number;
    type: "management_scheduled" | "alternative_event_scheduled";
  } | null;
  selectedAppointment: {
    start: moment.Moment;
    end: moment.Moment;
  };
}

interface AltEventSelectedAgentsTimes {
  selectedAgents: number[];
  // set if we are editing an event
  eventId?: number;
  eventDescription?: string;
  selectedTime: {
    date: Date;
    start?: Date | undefined;
    end?: Date | undefined;
  };
}
interface MapBoxLocation {
  mapbox_id: string;
  original_string: string;
  name: string;
  full_address: string;
  latitude: number;
  longitude: number;
}

interface GoogleCalendarEvent {
  id: string;
  summary: string | undefined;
  location: MapBoxLocation | string | undefined;
  start: {
    dateTime?: string;
    date?: string;
  };
  end: {
    dateTime?: string;
    date?: string;
  };
}

interface OutlookCalendarEvent {
  id: string;
  isAllDay: boolean;
  isCancelled: boolean;
  subject: string | undefined;
  locations: object[] | undefined;
  start: {
    dateTime?: string;
    timeZone?: string;
  };
  end: {
    dateTime?: string;
    timeZone?: string;
  };
}

interface CalendarState {
  selectedDate: number;
  selectedCalendarTimeFrame: number;
  selectedVendorsAgentsIds: CalendarSelectedAgentsVendorsIds;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scheduledSegments: any;
  dragAndDropState: "resizingEvent" | "draggingMeld" | "draggingEvent" | null;
  pendingResdientAvailabilities: Array<ExtractMember<AggregatedCalendarEvent, "pending_offered_availability">>;
  altEventSelectedAgentsTime: AltEventSelectedAgentsTimes | null;
  pendingRecommendedMeld: CalendarRecommendEvent | null;
  priorRightPaneType: CALENDAR_PANE_TYPES | null;
  // passing this via url is such a pain we keep it in state
  mapEventListRightpaneItems: CalendarMapMarkerType[];
  actions: {
    setPriorRightpaneType: (type: CALENDAR_PANE_TYPES | null) => void;
    setPendingRecommendEvent: (event: CalendarRecommendEvent | null) => void;
    setmapEventListRightpaneItems: (items: CalendarMapMarkerType[]) => void;
    // date and timeframe
    setSelectedDate: (newDate: number, isMobile: boolean) => void;
    incrementDate: () => void;
    decrementDate: () => void;
    setDateToToday: () => void;
    setCalendarSelectedTimeFrame: (newTimeFrame: number) => void;
    // selected vendors and agents - add is for extending the current lists
    setSelectedVendorsAgents: (selected: CalendarSelectedAgentsVendorsIds) => void;
    addSelectedVendorsAgents: (selected: CalendarSelectedAgentsVendorsIds) => void;
    // when the component unmounts we want to reset the state
    resetState: () => void;
    // selected resident availabilities
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    addScheduledSegment: (segment: any) => void;
    removeScheduledSegment: (id: number) => void;
    // drag and drop state
    startDraggingMeld: () => void;
    startDraggingEvent: () => void;
    stopDragging: () => void;
    startResizingEvent: () => void;
    stopResizingEvent: () => void;
    // click to add availbailities and their calendar placeholders
    addPendingResidentAvailability: (
      eventsToAdd: Array<{
        tempId: string;
        agentId: number;
        start: string;
        end: string;
      }>
    ) => void;
    upsertPendingResidentAvailability: (props: {
      tempId: string;
      start: Moment;
      end: Moment;
      agents: Array<{ id: number }>;
    }) => void;
    removePendingResidentAvailability: (tempId: string) => void;
    clearPendingResidentAvailabilities: () => void;
    // click to set alternative event form
    setAltEventSelectedAgentsTime: (props: {
      newAgents?: AltEventSelectedAgentsTimes["selectedAgents"];
      newSelectedTime?: Partial<AltEventSelectedAgentsTimes["selectedTime"]>;
      eventId?: number;
      eventDescription?: string;
    }) => void;
    clearAltEventSelectedAgentsTime: (arg0?: boolean) => void;
  };
}

// do not export - actions are the interface for interacting with the state
const useCalendarStateStore = create<CalendarState>((set) => ({
  activePane: shouldShowCalendarRedesignDueToDesktop.matches ? { type: "meldsToSchedule" } : { type: "mobile-null" },
  selectedDate: startOfDay(new Date()).valueOf(),
  calendarOrMap: "calendar",
  selectedCalendarTimeFrame: getInitialSelectedTimeFrame(),
  selectedVendorsAgentsIds: getInitialSelectedVendorsOrAgents(),
  scheduledSegments: [],
  dragAndDropState: null,
  pendingResdientAvailabilities: [],
  pendingRecommendedMeld: null,
  altEventSelectedAgentsTime: null,
  mapEventListRightpaneItems: [],
  priorRightPaneType: null,
  actions: {
    setPriorRightpaneType: (type) => set({ priorRightPaneType: type }),
    setPendingRecommendEvent: (event) => set({ pendingRecommendedMeld: event }),
    setmapEventListRightpaneItems: (items) => set({ mapEventListRightpaneItems: items }),
    setSelectedDate: (newDate) => {
      set({ selectedDate: newDate, pendingRecommendedMeld: null });
    },
    incrementDate: () =>
      set((state) => ({
        selectedDate: addDays(new Date(state.selectedDate), state.selectedCalendarTimeFrame).valueOf(),
        pendingRecommendedMeld: null,
      })),
    decrementDate: () =>
      set((state) => ({
        selectedDate: subDays(new Date(state.selectedDate), state.selectedCalendarTimeFrame).valueOf(),
        pendingRecommendedMeldPlaceholder: null,
      })),
    setDateToToday: () =>
      set({
        selectedDate: startOfDay(new Date()).valueOf(),
        pendingRecommendedMeld: null,
      }),
    setCalendarSelectedTimeFrame: (newTimeFrame) => {
      setInitialSelectedTimeFrame(newTimeFrame);
      set({
        selectedCalendarTimeFrame: newTimeFrame,
        // for simplicity on timeframe change we just clear out any recommended meld events
        pendingRecommendedMeld: null,
      });
    },
    setSelectedVendorsAgents: (selected) => {
      setInitialSelectedVendorsOrAgents(selected);
      set((state) => {
        // if the recommended meld agent isn't among the selected agent we revert to the melds list page
        if (state.pendingRecommendedMeld) {
          const currentAgentId = state.pendingRecommendedMeld.personaId;
          if (!selected.agents.some((agentId) => agentId === currentAgentId)) {
            return { selectedVendorsAgentsIds: selected, activePane: { type: "meldsToSchedule" } };
          }
        }
        return { selectedVendorsAgentsIds: selected };
      });
    },
    addSelectedVendorsAgents: (agentsVendorsToAdd) => {
      set((state) => {
        const result = {
          agents: state.selectedVendorsAgentsIds.agents,
          vendors: state.selectedVendorsAgentsIds.vendors,
        };
        agentsVendorsToAdd.agents.forEach((agent) => {
          if (!result.agents.includes(agent)) {
            result.agents.push(agent);
          }
        });
        agentsVendorsToAdd.vendors.forEach((vendor) => {
          if (!result.vendors.includes(vendor)) {
            result.vendors.push(vendor);
          }
        });

        return { selectedVendorsAgentsIds: result };
      });
    },
    resetState: () =>
      set({
        altEventSelectedAgentsTime: null,
        pendingRecommendedMeld: null,
        pendingResdientAvailabilities: [],
        priorRightPaneType: null,
        mapEventListRightpaneItems: [],
        selectedDate: startOfDay(new Date()).valueOf(),
        dragAndDropState: null,
      }),

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addScheduledSegment: (segment: any) =>
      set((state) => ({
        scheduledSegments: [...state.scheduledSegments, segment],
      })),
    removeScheduledSegment: (id: number) =>
      set((state) => ({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        scheduledSegments: state.scheduledSegments.filter((segment: any) => segment.id !== id),
      })),
    startDraggingMeld: () => set({ dragAndDropState: "draggingMeld" }),
    startDraggingEvent: () => set({ dragAndDropState: "draggingEvent" }),
    stopDragging: () => set({ dragAndDropState: null }),
    startResizingEvent: () => set({ dragAndDropState: "resizingEvent" }),
    stopResizingEvent: () => set({ dragAndDropState: null }),
    // pending resident availability placeholders
    addPendingResidentAvailability: (pendingEventsToAdd) =>
      set((state) => {
        return {
          pendingResdientAvailabilities: [
            ...state.pendingResdientAvailabilities,
            ...pendingEventsToAdd.map(
              (event) =>
                ({
                  type: "pending_offered_availability",
                  start: event.start,
                  end: event.end,
                  startDate: new Date(event.start),
                  endDate: new Date(event.end),
                  start_moment: moment(event.start),
                  end_moment: moment(event.end),
                  description: "Offer this time to resident",
                  personaId: event.agentId,
                  personaType: "agent",
                  key: event.tempId + event.agentId,
                  tempId: event.tempId,
                } as const)
            ),
          ],
        };
      }),
    removePendingResidentAvailability: (tempId) =>
      set((state) => {
        return {
          pendingResdientAvailabilities: [...state.pendingResdientAvailabilities].filter(
            (e) => e.type !== "pending_offered_availability" || e.tempId !== tempId
          ),
        };
      }),
    upsertPendingResidentAvailability: ({ tempId, start, end, agents }) =>
      set((state) => {
        let updatedAnEvent = false;
        // we map the existing set because if a meld is assigned to multiple techs there can be multiple events
        // with the same tempId
        const updatedAvailabilities = state.pendingResdientAvailabilities.map((existingEvent) => {
          if (existingEvent.type !== "pending_offered_availability" || existingEvent.tempId !== tempId) {
            return existingEvent;
          }
          updatedAnEvent = true;
          const updatedEvent = { ...existingEvent };
          updatedEvent.end_moment = end.clone();
          updatedEvent.end = end.toISOString();
          updatedEvent.start_moment = start.clone();
          updatedEvent.start = start.toISOString();
          return updatedEvent;
        });
        // handle insertions
        if (!updatedAnEvent) {
          agents.forEach((agent) => {
            updatedAvailabilities.push({
              type: "pending_offered_availability",
              start: start.toISOString(),
              startDate: start.toDate(),
              end: end.toISOString(),
              endDate: end.toDate(),
              start_moment: start,
              end_moment: end,
              description: "Offer this time to resident",
              personaId: agent.id,
              personaType: "agent",
              key: tempId + agent.id,
              tempId,
            });
          });
        }
        return { pendingResdientAvailabilities: updatedAvailabilities };
      }),
    clearPendingResidentAvailabilities: () => set({ pendingResdientAvailabilities: [] }),
    // click to add alt events
    setAltEventSelectedAgentsTime: ({ newAgents, newSelectedTime, eventId, eventDescription }) =>
      set((state) => {
        const selectedAgents = newAgents || state.altEventSelectedAgentsTime?.selectedAgents || [];
        const selectedDate =
          (newSelectedTime && "date" in newSelectedTime
            ? newSelectedTime.date
            : state.altEventSelectedAgentsTime?.selectedTime.date) || new Date();
        const selectedStart =
          newSelectedTime && "start" in newSelectedTime
            ? newSelectedTime.start
            : state.altEventSelectedAgentsTime?.selectedTime.start;
        const selectedEnd =
          newSelectedTime && "end" in newSelectedTime
            ? newSelectedTime.end
            : state.altEventSelectedAgentsTime?.selectedTime.end;

        if (!newAgents && !newSelectedTime) {
          return { altEventSelectedAgentsTime: null };
        }
        return {
          altEventSelectedAgentsTime: {
            selectedTime: { date: selectedDate, start: selectedStart, end: selectedEnd },
            eventId: eventId || state.altEventSelectedAgentsTime?.eventId,
            eventDescription: eventDescription || state.altEventSelectedAgentsTime?.eventDescription,
            selectedAgents,
          },
        };
      }),
    clearAltEventSelectedAgentsTime: (removeEvent = false) => {
      if (removeEvent) {
        set(() => ({ altEventSelectedAgentsTime: null }));
      } else {
        set((state) => ({
          altEventSelectedAgentsTime: {
            ...(state.altEventSelectedAgentsTime || []),
            selectedAgents: state.altEventSelectedAgentsTime?.selectedAgents || [],
            selectedTime: {
              date: state.altEventSelectedAgentsTime?.selectedTime.date || new Date(),
              start: undefined,
              end: undefined,
            },
          },
        }));
      }
    },
  },
}));

const useCalendarStatePriorRightpaneType = () => useCalendarStateStore((state) => state.priorRightPaneType);
const useCalendarStatePendingRecommendedMeld = () => useCalendarStateStore((state) => state.pendingRecommendedMeld);
const useCalendarStateAltEventSelectedAgentsTime = () =>
  useCalendarStateStore((state) => state.altEventSelectedAgentsTime);
const useCalendarStateMapEventListRightpaneItems = () =>
  useCalendarStateStore((state) => state.mapEventListRightpaneItems);
const useCalendarStatePendingOfferedAvailabilities = () =>
  useCalendarStateStore((state) => state.pendingResdientAvailabilities);
const useCalendarStateSelectedDate = () => useCalendarStateStore((state) => state.selectedDate);
const useCalendarStateSelectedTimeFrame = (isMobile: boolean) =>
  useCalendarStateStore((state) => {
    if (isMobile) {
      return 1;
    }
    return state.selectedCalendarTimeFrame;
  });
const useCalendarStateSelectedVendorAgentIds = (isMobile: boolean): CalendarSelectedAgentsVendorsIds =>
  useCalendarStateStore((state) => {
    if (isMobile) {
      if (state.selectedVendorsAgentsIds.agents.length > 0) {
        return { agents: [state.selectedVendorsAgentsIds.agents[0]], vendors: [] };
      } else if (state.selectedVendorsAgentsIds.vendors.length > 0) {
        return { agents: [], vendors: [state.selectedVendorsAgentsIds.vendors[0]] };
      }
    }
    return state.selectedVendorsAgentsIds;
  });
const useCalendarStateActions = () => useCalendarStateStore((state) => state.actions);
const useCalendarStateScheduledSegments = () => useCalendarStateStore((state) => state.scheduledSegments);
const useCalendarDragAndDropState = () => useCalendarStateStore((state) => state.dragAndDropState);

export {
  useCalendarStatePriorRightpaneType,
  useCalendarStatePendingOfferedAvailabilities,
  useCalendarStateMapEventListRightpaneItems,
  useCalendarStateAltEventSelectedAgentsTime,
  useCalendarStatePendingRecommendedMeld,
  useCalendarStateActions,
  useCalendarStateSelectedDate,
  useCalendarStateSelectedTimeFrame,
  useCalendarStateSelectedVendorAgentIds,
  useCalendarStateScheduledSegments,
  useCalendarDragAndDropState,
  CalendarRecommendEvent,
  GoogleCalendarEvent,
  OutlookCalendarEvent,
  CalendarSelectedAgentsVendorsIds,
  AltEventSelectedAgentsTimes,
  CALENDAR_SELECTED_VENDOR_AGENTS_LOCAL_STORAGE_KEY,
  CalendarState,
  MapBoxLocation,
};
