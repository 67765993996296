import { Button, IconButton } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { Box, Flex, Stack } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { SkeletonGroup } from "@pm-owner-hub/src/components/skeleton-group";
import { WorkflowCondition, WorkflowConfig } from "@pm-property-management-hub/src/utils/api";
import React, { useEffect, useState } from "react";
import { IoRemoveCircleOutline } from "react-icons/io5";
import { BooleanCondition } from "./conditions/boolean-condition";
import { ChoiceCondition } from "./conditions/choice-condition";
import { DecimalCondition } from "./conditions/decimal-condition";
import { IntegerCondition } from "./conditions/integer-condition";
import { TextCondition } from "./conditions/text-condition";
import { TimestampCondition } from "./conditions/timestamp-condition";
import nextId from "react-id-generator";
import { Condition } from "./tree";
import Features from "@pm-assets/js/common/feature-flags";
import { omit } from "lodash";

export const ConditionOpType = (props: any) => {
  const { change, valueIndex, lookup_name, operator_key, values } = props;

  const targetChange = (e: any) => {
    const clone = [...values];
    clone[valueIndex] = e.target.value;
    change(lookup_name, operator_key, clone);
  };

  switch (props.mapped_config?.condition_type) {
    case "choice":
      return <ChoiceCondition onChange={targetChange} {...props} />;
    case "text":
      return <TextCondition onChange={targetChange} {...props} />;
    case "decimal":
      return <DecimalCondition onChange={(number: any) => targetChange({ target: { value: number } })} {...props} />;
    case "integer":
      return <IntegerCondition onChange={(number: any) => targetChange({ target: { value: number } })} {...props} />;
    case "boolean":
      return <BooleanCondition onChange={targetChange} {...props} />;
    case "timestamp":
      const intervalOps = ["greater_than_past", "lesser_than_past", "greater_than_future", "lesser_than_future"];

      return (
        <TimestampCondition
          onChange={(value?: any) => {
            if (value) {
              if (intervalOps.includes(operator_key) && typeof value === "string") {
                targetChange({ target: { value } });
              }
              if (!intervalOps.includes(operator_key) && value?.toISOString) {
                const date = value?.toISOString();

                if (values[valueIndex] !== date) {
                  targetChange({ target: { value: date } });
                }
              }
              if (!intervalOps.includes(operator_key) && value.start_day) {
                targetChange({ target: { value } });
              }
            } else {
              targetChange({ target: { value: "" } });
            }
          }}
          {...props}
        />
      );
    default:
      return <>ConditionOpType missing condition_type</>;
  }
};

export const conditionSupportsAnd = (type: string, operator?: string, index?: number, values?: any[]) => {
  if (
    Number.isInteger(index) &&
    ["in_range", "not_in_range", "between"].includes(operator || "") &&
    index === 0 &&
    values?.length &&
    values.length < 2
  ) {
    return true;
  }
  // Handle default operator with no value
  // when a new condition is created.
  if (!operator || operator === "") {
    return false;
  }

  if (type === "text") {
    return true;
  }
  return type === "choice" && !["missing", "present"].includes(operator || "");
};

export const And = ({ valueIndex, value, condition_type, operator_key, clickAnd }: any) => {
  if (conditionSupportsAnd(condition_type, operator_key, valueIndex, value)) {
    return (
      <Button
        data-test={"workflows.form.select.block_operator.action.and"}
        onClick={clickAnd}
        marginLeft={2}
        size="xs"
        textTransform="uppercase"
        variant="primary"
      >
        And
      </Button>
    );
  }
  return <></>;
};

export const RemoveIcon = ({ show, clickRemove }: any) => {
  if (show) {
    return (
      <IconButton
        data-test={"workflows.form.select.block_operator.action.remove"}
        aria-label="Remove Value"
        fontSize="lg"
        icon={<Icon as={IoRemoveCircleOutline} />}
        onClick={clickRemove}
        padding={0}
        variant="ghost"
        _hover={{ shadow: "none" }}
      />
    );
  }
  return <></>;
};

function GetWorkflowOperators(mapped_config: WorkflowCondition): WorkflowCondition {
  let filteredMappedConfig = Object.assign({}, mapped_config);
  if (Features.isInRecurringRangeEnabled() && mapped_config) {
    filteredMappedConfig.operators = omit(filteredMappedConfig.operators, "in_recurring_range");
  }
  return filteredMappedConfig;
}

export const BlockCondition: React.FC<{
  lookup_name: Condition["lookup_name"];
  value: Condition["value"];
  conditionCount: number;
  conditionCountId: number;
  operator_key?: string;
  mapped_config: WorkflowCondition;
  config_list: WorkflowConfig["conditions_list"];
  groupIndex: number;
  remove: (groupIndex: number, id: number) => void;
  change: (condition: string, operator_key?: string, value?: string[]) => void;
  error?: any;
}> = ({
  lookup_name,
  operator_key,
  value,
  mapped_config,
  config_list,
  conditionCount,
  conditionCountId,
  groupIndex,
  remove,
  change,
  error,
}) => {
  if (!config_list) {
    return <SkeletonGroup />;
  }
  const [mappedConfig, setMappedConfig] = useState<WorkflowCondition>(mapped_config);

  useEffect(() => {
    if (mapped_config) {
      const filteredMappedConfig = GetWorkflowOperators(mapped_config);
      setMappedConfig(filteredMappedConfig);
    }
  }, [mapped_config]);
  return (
    <Stack spacing={0} _notLast={{ borderBottomWidth: 1, borderBottomColor: "blue.50", pb: 4 }}>
      <Flex alignItems="center" justifyContent="space-between">
        <Select
          data-test={"workflows.form.select.block_condition"}
          onChange={(e) => {
            change(e.target.value, "", [""]);
          }}
          placeholder="Select an Optional Condition"
          value={lookup_name || ""}
        >
          {config_list &&
            config_list.map((c) => {
              return (
                <option
                  key={c.lookup_name}
                  value={c.lookup_name}
                  data-test={"workflows.form.select.block_condition.option"}
                >
                  {c.readable}
                </option>
              );
            })}
        </Select>

        {conditionCount > 1 && (
          <IconButton
            data-test={"workflows.form.action.block_condition.remove"}
            aria-label="Remove Condition"
            fontSize="lg"
            icon={<Icon as={IoRemoveCircleOutline} />}
            onClick={() => remove(groupIndex, conditionCountId)}
            padding={0}
            variant="ghost"
            _hover={{ shadow: "none" }}
          />
        )}
      </Flex>
      {lookup_name && mapped_config && mappedConfig && (
        <Box key={nextId(`box-${lookup_name}-${operator_key}-${conditionCountId}-`)} paddingLeft={4} paddingTop={2}>
          {/*This select is used to choose what the block operator type is */}
          <Flex alignItems="center">
            <Select
              isInvalid={!!error}
              data-test={"workflows.form.select.block_operator"}
              onChange={(e) => {
                if (!["missing", "present"].includes(e.target.value)) {
                  change(lookup_name, e.target.value, [""]);
                } else {
                  change(lookup_name, e.target.value, []);
                }
              }}
              placeholder="Select an Operator"
              value={operator_key}
            >
              {Object.keys(mappedConfig.operators)
                .filter((o) => mapped_config.operators[o]?.human_label)
                .map((o: string) => (
                  <option
                    key={nextId(`option-${lookup_name}-${operator_key}-${conditionCountId}-${o}-`)}
                    value={o}
                    data-test={"workflows.form.select.block_operator.option"}
                  >
                    {mapped_config.operators[o].human_label}
                  </option>
                ))}
            </Select>
          </Flex>

          <Stack spacing={2}>
            {value &&
              (Array.isArray(value) ? value : [value]).map((v: any, valueIndex: number, items) => {
                const clickAnd = () => {
                  const clone = [...value, ""];
                  change(lookup_name, operator_key, clone);
                };

                const clickRemove = () => {
                  const clone = [...value];
                  clone.splice(valueIndex, 1);
                  change(lookup_name, operator_key, clone);
                };
                // Once the block operator type is picked we then surface the block conditionals
                // as well as a way to AND more conditionals
                return (
                  <Flex
                    alignItems="center"
                    key={`condition-${lookup_name}-${operator_key}-${conditionCountId}-${valueIndex}-`}
                    paddingLeft={4}
                    paddingTop={2}
                    width="100%"
                  >
                    <Flex alignItems="center" justifyContent="space-between" width="100%">
                      <ConditionOpType
                        lookup_name={lookup_name}
                        value={v}
                        values={items}
                        conditionCount={conditionCount}
                        conditionCountId={conditionCountId}
                        operator_key={operator_key}
                        mapped_config={mapped_config}
                        config_list={config_list}
                        valueIndex={valueIndex}
                        change={change}
                        error={error}
                      />

                      <Stack isInline>
                        <And
                          {...{
                            valueIndex,
                            value,
                            condition_type: mapped_config.condition_type,
                            operator_key,
                            change,
                            clickAnd,
                          }}
                        />

                        <RemoveIcon {...{ show: Array.isArray(value) && value?.length > 1, clickRemove }} />
                      </Stack>
                    </Flex>
                  </Flex>
                );
              })}
          </Stack>
        </Box>
      )}
    </Stack>
  );
};
