var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React from "react";
import I from "immutable";
import AssignmentRequestAPI from "../../assets/js/meld/apis/assignment-request-api";
import VendorMeldUtils from "../../assets/js/vendor_app/meld/utils/vendor-meld-utils";
import MeldOwnerApprovalAPI from "../../assets/js/meld/apis/owner-approval-api";
import { OwnerApprovalStatuses } from "../utils/Constants";
import messageActions from "../../assets/js/common/actions/message-actions";
import MeldAPI from "@pm-assets/js/meld/apis/meld-api";
const MeldContext = React.createContext({});
export class MeldContextProvider extends React.Component {
  constructor(props) {
    super(props);
    if (props.meld) {
      this.state = {
        meld: props.meld,
        loading: false,
        error: void 0,
        loadedNewMeld: false,
        actions: {
          refreshMeld: this.refreshMeld.bind(this),
          rejectMeld: this.rejectMeld.bind(this),
          acceptMeld: this.acceptMeld.bind(this),
          updateTags: this.updateTags.bind(this),
          unassignVendors: this.unassignVendors.bind(this),
          cancelApprovalRequest: this.cancelApprovalRequest.bind(this)
        }
      };
    } else {
      this.state = {
        loading: true,
        error: void 0,
        loadedNewMeld: false,
        actions: {
          refreshMeld: this.refreshMeld.bind(this),
          rejectMeld: this.rejectMeld.bind(this),
          acceptMeld: this.acceptMeld.bind(this),
          updateTags: this.updateTags.bind(this),
          unassignVendors: this.unassignVendors.bind(this),
          cancelApprovalRequest: this.cancelApprovalRequest.bind(this)
        }
      };
    }
  }
  clearErrors() {
    this.setState({
      error: void 0
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.meldId !== prevProps.meldId && !this.state.loadedNewMeld) {
      this.setState({
        loading: true,
        loadedNewMeld: true
      });
      void this.loadMeld();
    }
  }
  componentDidMount() {
    if (!this.state.meld) {
      void this.loadMeld();
    }
  }
  refreshMeld(showLoader = false) {
    if (showLoader) {
      this.setState({
        loading: true
      });
    }
    void this.loadMeld();
  }
  loadMeld() {
    return __async(this, null, function* () {
      this.clearErrors();
      const { meldId } = this.props;
      try {
        const result = yield MeldAPI.getOne(meldId);
        this.setState({
          meld: I.fromJS(result.data),
          loading: false,
          loadedNewMeld: false
        });
      } catch (error) {
        this.setState({
          loading: false,
          error,
          loadedNewMeld: false
        });
      }
    });
  }
  rejectMeld(reason) {
    const { meld } = this.state;
    if (meld) {
      return AssignmentRequestAPI.vendorReject(VendorMeldUtils.getMyCurrentAssignment(meld).get("id"), reason);
    }
  }
  acceptMeld() {
    const { meld } = this.state;
    if (meld) {
      return AssignmentRequestAPI.vendorAccept(VendorMeldUtils.getMyCurrentAssignment(meld).get("id"));
    }
  }
  updateTags(meld) {
    return MeldAPI.updateTags(meld);
  }
  unassignVendors(meld) {
    let clearMaint = { maintenance: [], user_groups: [] };
    return MeldAPI.assignMaintenance(meld.get("id"), clearMaint);
  }
  cancelApprovalRequest(meld, onComplete) {
    MeldOwnerApprovalAPI.finishRequest(meld.get("id"), OwnerApprovalStatuses.OWNER_APPROVAL_NOT_REQUESTED).then(() => {
      onComplete();
      void this.loadMeld();
    }).catch((response) => {
      messageActions.storeError({ body: response.messages });
    });
  }
  render() {
    const context = this.state;
    return /* @__PURE__ */ React.createElement(MeldContext.Provider, { value: __spreadValues({}, context) }, this.props.children);
  }
}
export default MeldContext;
