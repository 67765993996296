import I from "immutable";
import produce from "immer";

export function asImmer() {
  return (_target: any, _propertyKey: string | symbol, descriptor: PropertyDescriptor) => {
    const originalFn = descriptor.value;

    descriptor.value = (...args: any[]) => {
      const immerArgs = args.map(arg => {
        // https://github.com/immutable-js/immutable-js/issues/1165#issuecomment-292650855
        if (I.Iterable.isIterable(arg)) {
          return produce(arg, (immutable: any) => immutable.toJS());
        } else {
          return arg;
        }
      });

      return originalFn(...immerArgs);
    };

    return descriptor;
  };
}
