import React from "react";
import { Select, Props as SelectProps } from "./select";
import { Field, FieldProps } from "formik";

interface Props extends SelectProps {
  /**
   * Gets passed to formik's <Field /> component.
   * Used to match the field to form value for validation and state management.
   */
  match: string;
}

export const FormikSelect: React.FC<Props> = ({ match, ...rest }) => (
  <Field name={match}>{({ field, meta }: FieldProps) => <Select errors={meta.error} {...field} {...rest} />}</Field>
);

FormikSelect.displayName = "FormikSelect";
