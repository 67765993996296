import React from "react";
import classnames from "classnames";

export default function Panel(props: React.HTMLProps<HTMLDivElement>) {
  const { className, children, ...otherProps } = props;
  const classes = classnames("pm-panel", props.className);

  return (
    <div className={classes} {...otherProps}>
      {children}
    </div>
  );
}
