import alt from "../../alt";

import BaseStore, { StatusStates } from "../../stores/base-store";
import ManagementAppointmentSource from "../sources/management-appointment-source";
import managementAppointmentActions from "../actions/management-appointment-actions";

let ExtraActions = {
  schedule: "SCHEDULE",
  requestMoreAvailability: "REQUEST_MORE_AVAILABILITY"
};

class ManagementAppointmentStore extends BaseStore {
  constructor(options) {
    super(options);

    this.registerAsync(ManagementAppointmentSource);

    this.bindListeners({
      fetching: managementAppointmentActions.FETCH_ONE,
      fetchDone: managementAppointmentActions.FETCH_ONE_DONE,
      fetchFail: managementAppointmentActions.FETCH_ONE_FAIL,
      schedule: managementAppointmentActions.SCHEDULE,
      scheduleDone: managementAppointmentActions.SCHEDULE_DONE,
      scheduleFail: managementAppointmentActions.SCHEDULE_FAIL,
      requestMoreAvailability: managementAppointmentActions.REQUEST_MORE_AVAILABILITY,
      requestMoreAvailabilityDone: managementAppointmentActions.REQUEST_MORE_AVAILABILITY_DONE,
      requestMoreAvailabilityFail: managementAppointmentActions.REQUEST_MORE_AVAILABILITY_FAIL
    });
  }

  schedule(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.schedule, StatusStates.PENDING);
  }

  scheduleDone(dispatchData) {
    this.receiveOne(dispatchData);
    this.augmentAndMarkStatus(dispatchData, ExtraActions.schedule, StatusStates.DONE);
  }

  scheduleFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.schedule, StatusStates.FAIL);
  }

  requestMoreAvailability(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.requestMoreAvailability, StatusStates.PENDING);
  }

  requestMoreAvailabilityDone(dispatchData) {
    this.receiveOne(dispatchData);
    this.augmentAndMarkStatus(dispatchData, ExtraActions.requestMoreAvailability, StatusStates.DONE);
  }

  requestMoreAvailabilityFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.requestMoreAvailability, StatusStates.FAIL);
  }
}

export default alt.createStore(ManagementAppointmentStore, "ManagementEventStore");
