var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const AssignmentUnassigned = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement("path", { d: "M34 6h-4a3 3 0 00-3-3h-3.13a4 4 0 00-7.74 0H13a3 3 0 00-3 3H6a3 3 0 00-3 3v28a3 3 0 003 3h28a3 3 0 003-3V9a3 3 0 00-3-3zM12 6a1 1 0 011-1h4a1 1 0 001-1 2 2 0 014 0 1 1 0 001 1h4a1 1 0 011 1v4a1 1 0 01-1 1H13a1 1 0 01-1-1zm23 31a1 1 0 01-1 1H6a1 1 0 01-1-1V9a1 1 0 011-1h4v2a3 3 0 003 3h14a3 3 0 003-3V8h4a1 1 0 011 1z" }), /* @__PURE__ */ React.createElement("path", { d: "M28.71 16.29a1 1 0 00-1.42 0L20 23.59l-7.29-7.3a1 1 0 00-1.42 1.42l7.3 7.29-7.3 7.29a1 1 0 000 1.42 1 1 0 001.42 0l7.29-7.3 7.29 7.3a1 1 0 001.42 0 1 1 0 000-1.42L21.41 25l7.3-7.29a1 1 0 000-1.42z" }));
