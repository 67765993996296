import React, { forwardRef } from "react";
import { EuiFilePicker } from "@elastic/eui";
import { CSSObject } from "@emotion/react";

import { colors } from "@pm-frontend/styles";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";

interface PmFileUploaderProps {
  id: string;
  display?: "default" | "large" | undefined;
  onChange: any;
  prompt?: string;
  isLoading?: boolean;
  multiple?: boolean;
  ref?: React.RefObject<EuiFilePicker>;
}

const cssStyles: CSSObject = {
  "& + .euiFilePicker__prompt > svg.euiFilePicker__icon": {
    color: colors.brand.meldBlue,
  },
  "& + .euiFilePicker__prompt": {
    border: "none",
    background: `${colors.neutrals.gray50} !important`,
  },
  "& + .euiFilePicker__prompt > .euiFilePicker__promptText": {
    fontSize: "14px",
    lineHeight: "18px",
    color: `${colors.brand.meldBlue}`,
  },
  "& + .euiFilePicker__prompt > .euiProgress::before": {
    backgroundColor: `${colors.brand.meldBlue}`,
  },
};

// this component utilizes `forwardRef` to enable automatically clearing the
// selected files when the upload settles
const PmFileUploader = forwardRef<EuiFilePicker, PmFileUploaderProps>(
  (
    {
      id,
      display = "large",
      onChange,
      prompt = "Drop files here or upload files from your device",
      isLoading,
      multiple = true,
    },
    ref
  ) => {
    const isMobile = useIsMobile();

    return (
      <EuiFilePicker
        fullWidth={true}
        id={id}
        multiple={multiple}
        ref={ref}
        initialPromptText={isMobile ? "Upload files from your device" : prompt}
        display={display}
        onChange={onChange}
        compressed={display === "large"}
        isLoading={isLoading}
        css={cssStyles}
      />
    );
  }
);

export { PmFileUploader };
