import I from "immutable";

import { asImmer } from "../../../../app/utils/decorators";
import { AllUnitNode, UnitNode } from "../../common/graphql/types";
import MeldUtils from "../../meld/utils/meld-utils";
import { ImmutableMap } from "../../types/common";
import AddressUtils from "../../utils/address-utils";
import Features from "@pm-assets/js/common/feature-flags";

class UnitUtils {
  static getSummaryLink(unit: I.Map<string, any>) {
    return `/properties/${unit.get("id")}/summary/`;
  }

  static getFormattedStreetAndUnit(unit: I.Map<string, string>) {
    return [UnitUtils.getAddressLine1(unit), UnitUtils.getFormattedUnit(unit, "")].join(" ").trim();
  }

  @asImmer()
  static getID(unit: any) {
    unit = unit as UnitNode | AllUnitNode;
    return unit.id;
  }

  @asImmer()
  static getUnitMaintenanceNotes(unit: any) {
    unit = unit as UnitNode | AllUnitNode;
    return unit.maintenance_notes;
  }

  @asImmer()
  static getPropertyMaintenanceNotes(unit: any) {
    unit = unit as UnitNode | AllUnitNode;
    return unit.prop?.maintenance_notes;
  }

  @asImmer()
  static getUnitProp(unit: any) {
    unit = unit as UnitNode | AllUnitNode;
    return unit.prop;
  }

  @asImmer()
  static getPropertyName(unit: any, emptyMsg: string = "No property name") {
    unit = unit as UnitNode | AllUnitNode;
    let property_name = unit.prop.property_name;
    return property_name !== "" ? property_name : emptyMsg;
  }

  @asImmer()
  static displayPropertyName(unit: any) {
    let property_name = UnitUtils.getPropertyName(unit, "");
    return property_name !== UnitUtils.getDisplayAddressLine1(unit) ? property_name : "";
  }

  @asImmer()
  static getPropPropertyGroup(unit: any) {
    unit = unit as UnitNode | AllUnitNode;
    return unit.prop.property_groups.edges;
  }

  @asImmer()
  static getDisplayStreetAndUnit(unit: any) {
    unit = unit as UnitNode | AllUnitNode;

    return [UnitUtils.getDisplayAddressLine1(unit), UnitUtils.getFormattedUnit(unit, "")].join(" ").trim();
  }

  @asImmer()
  static getDisplayPropertyNameStreetAndUnitWithState(unit: any) {
    unit = unit as UnitNode | AllUnitNode;
    let line_1 =
      Features.isPropertyNameEnabled() && UnitUtils.displayPropertyName(unit) !== ""
        ? `${UnitUtils.displayPropertyName(unit)} - ${UnitUtils.getDisplayAddressLine1(unit)}`
        : UnitUtils.getDisplayAddressLine1(unit);
    return [line_1, UnitUtils.getFormattedUnit(unit, ""), UnitUtils.getDisplayCityStateZip(unit)].join(" ").trim();
  }

  static getFormattedBuilding(unit: I.Map<string, string> | any, emptyMsg = "No building number") {
    [unit] = MeldUtils.immutableMapOrObject(unit);
    return unit.getIn(["building", "number"]) || emptyMsg;
  }

  static getFormattedFloor(unit: I.Map<string, string> | any, emptyMsg = "No floor number") {
    [unit] = MeldUtils.immutableMapOrObject(unit);
    return unit.getIn(["floor", "number"]) || emptyMsg;
  }

  @asImmer()
  static getFormattedUnit(unit: any, emptyMsg = "No unit number") {
    unit = unit as UnitNode | AllUnitNode;

    return (
      [
        { prefix: "APT", number: unit.apartment },
        { prefix: "STE", number: unit.suite },
        { prefix: "UNIT", number: unit.unit },
        { prefix: "RM", number: unit.room },
        { prefix: "DEPT", number: unit.department },
      ]
        .filter((designator) => {
          return !!designator.number;
        })
        .map((designator) => {
          return [designator.prefix, designator.number].join(" ");
        })
        .join(", ") || emptyMsg
    );
  }

  static getFormattedBuildingFloorAndUnit(unit: I.Map<string, string>, emptyMsg = "No building, floor, or unit") {
    return (
      [
        { prefix: "BLDG", number: unit.getIn(["building", "number"]) },
        { prefix: "FL", number: unit.getIn(["floor", "number"]) },
        { prefix: "APT", number: unit.get("apartment") },
        { prefix: "STE", number: unit.get("suite") },
        { prefix: "UNIT", number: unit.get("unit") },
        { prefix: "RM", number: unit.get("room") },
        { prefix: "DEPT", number: unit.get("department") },
      ]
        .filter((designator) => {
          return !!designator.number;
        })
        .map((designator) => {
          return [designator.prefix, designator.number].join(" ");
        })
        .join(", ") || emptyMsg
    );
  }

  static getFormattedBuildingFloorAndUnitCityState(
    unit: I.Map<string, string>,
    emptyMsg = "No building, floor, or unit"
  ) {
    return (
      [UnitUtils.getFormattedBuildingFloorAndUnit(unit, emptyMsg), UnitUtils.getDisplayCityStateZip(unit)]
        .join(" ")
        .trim() || emptyMsg
    );
  }

  static getFormattedCityStateZip(unit: any) {
    unit = unit as UnitNode | AllUnitNode;

    return AddressUtils.getFormattedCityStateZip(unit.prop);
  }

  @asImmer()
  static getDisplayCityStateZip(unit: any) {
    unit = unit as UnitNode | AllUnitNode;

    if (unit.display_address) {
      return AddressUtils.getFormattedCityStateZip(unit.display_address);
    }
  }

  static getUnitAddressCityStateZip(unit: I.Map<string, any>) {
    return AddressUtils.getFormattedCityStateZip(unit.get("unit_address"));
  }

  static getFormattedAddressLines(unit: I.Map<string, string>, emptyMsg = "") {
    let lines = [
      UnitUtils.getAddressLine1(unit, emptyMsg),
      UnitUtils.getAddressLine2(unit, emptyMsg),
      UnitUtils.getAddressLine3(unit),
      emptyMsg,
    ];
    lines = lines.filter((line) => line);
    return lines.join(", ");
  }

  @asImmer()
  static getDisplayAddressLines(unit: any, emptyMsg = "") {
    let lines = [
      UnitUtils.getDisplayAddressLine1(unit),
      UnitUtils.getDisplayAddressLine2(unit),
      UnitUtils.getDisplayAddressLine3(unit),
      emptyMsg,
    ];
    lines = lines.filter((line) => line);
    return lines.join(", ");
  }

  @asImmer()
  static getNumDesignators(unit: any) {
    unit = unit as UnitNode | AllUnitNode;
    return [unit.apartment, unit.suite, unit.unit, unit.room, unit.department].reduce((acc, designator) => {
      return designator ? acc + 1 : acc;
    }, 0);
  }

  static getAddressLine1(unit: I.Map<string, any>, emptyMsg = "") {
    return AddressUtils.getAddressLine1(unit.get("prop"), emptyMsg);
  }

  static getAddressLine2(unit: I.Map<string, any>, emptyMsg = "") {
    return AddressUtils.getAddressLine2(unit.get("prop"), emptyMsg);
  }

  static getAddressLine3(unit: I.Map<string, any>, emptyMsg = "") {
    return AddressUtils.getAddressLine3(unit.get("prop"), emptyMsg);
  }

  @asImmer()
  static getDisplayAddressLine1(unit: any) {
    unit = unit as UnitNode | AllUnitNode;

    if (unit.display_address) {
      return AddressUtils.getAddressLine1(unit.display_address);
    }
  }

  @asImmer()
  static getDisplayAddressLine2(unit: any) {
    unit = unit as UnitNode | AllUnitNode;

    if (unit.display_address) {
      return AddressUtils.getAddressLine2(unit.display_address);
    }
  }

  @asImmer()
  static getDisplayAddressLine3(unit: any) {
    unit = unit as UnitNode | AllUnitNode;

    if (unit.display_address) {
      return AddressUtils.getAddressLine3(unit.display_address);
    }
  }

  static getOwners(unit: I.Map<string, string>) {
    return unit.getIn(["prop", "owners"]);
  }

  static getPropertyGroups(unit: I.Map<string, string>) {
    return unit.getIn(["prop", "property_groups"]);
  }

  static getPropertyGroupIDs(unit: I.Map<string, string>) {
    // ElasticSearch format
    if (unit.get("prop_groups")) {
      return unit.get("prop_groups");
    }

    // None found in normal location
    if (!unit.getIn(["prop", "property_groups"])) {
      return I.List();
    }

    // Get IDs from list of ids or list of objects
    return unit.getIn(["prop", "property_groups"]).map((pg: number | ImmutableMap) => {
      if (typeof pg === "number") {
        return pg;
      }

      return (pg as ImmutableMap).get("id");
    });
  }

  static getDirectionsUrlString(unit: I.Map<string, any>) {
    return AddressUtils.getDirectionsUrlString(unit.get("display_address"));
  }

  static getAncillaryUnitInfo(unit: I.Map<string, any>) {
    const cityStateZip = UnitUtils.getDisplayCityStateZip(unit);
    const bflu = UnitUtils.getFormattedBuildingFloorAndUnit(unit, "");

    return [cityStateZip, bflu].filter((d) => d).join(" | ");
  }
}
export default UnitUtils;
