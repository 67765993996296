import createReactClass from "create-react-class";
import React from "react";

/* tslint:disable:max-line-length */
let Chat = createReactClass<{ classes?: string }, {}>({
  render() {
    return (
      <svg
        className={this.props.classes}
        xmlns="https://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
        width="17"
        height="17"
        viewBox="0 0 34 34"
      >
        <path
          d="M29.457 2.966c.853 0 1.546.693 1.546 1.546v17c0 .85-.693 1.544-1.546 1.544H16.01l-6.737 4.938v-1.72-3.218h-4.73c-.853 0-1.546-.693-1.546-1.545V4.513c0-.853.693-1.546 1.546-1.546h24.914M29.364 0H4.636C2.076 0 0 2.076 0 4.636v16.72c0 2.56 1.89 4.636 4.45 4.636h1.545V34L17 25.992h12.364c2.56 0 4.636-2.077 4.636-4.637V4.635C34 2.076 31.924 0 29.364 0z"
          id="path-1"
          fillRule="evenodd"
        />
      </svg>
    );
  },
});
/* tslint:enable:max-line-length */

export default Chat;
