import React from "react";

import { ImmutableMap } from "../../../assets/js/types/common";
import ManagementAgentUtils from "../../../assets/js/management/utils/agent-utils";
import OwnerUtils from "../../../assets/js/property/utils/owner-utils";
import TenantUtils from "../../../assets/js/tenant/utils/tenant-utils";
import UserUtils from "../../../assets/js/authentication/utils/user-utils";
import VendorAgentUtils from "../../../assets/js/vendor/utils/agent-utils";

export interface AvatarProps {
  managementAgent?: ImmutableMap;
  owner?: ImmutableMap;
  tenant?: ImmutableMap;
  vendorAgent?: ImmutableMap;
}

function Avatar(props: AvatarProps) {
  let color;
  let initials;
  let name = "?";

  if (props.managementAgent) {
    color = UserUtils.calcColor(props.managementAgent);
    name = ManagementAgentUtils.getFullName(props.managementAgent, props.managementAgent.getIn(["management", "name"]));
  } else if (props.owner) {
    color = UserUtils.calcColor(props.owner);
    name = OwnerUtils.getName(props.owner);
  } else if (props.tenant) {
    color = UserUtils.calcColor(props.tenant);
    name = TenantUtils.getFullName(props.tenant);
  } else if (props.vendorAgent) {
    color = UserUtils.calcColor(props.vendorAgent);
    name = VendorAgentUtils.getFullName(props.vendorAgent, props.vendorAgent.getIn(["vendor", "name"]));
  }

  const names = name.split(" ");

  if (names.length > 1) {
    initials = `${names[0].charAt(0)}${names.slice(-1)[0].charAt(0)}`;
  } else {
    initials = names[0].charAt(0);
  }

  return (
    <div className="pm-avatar" style={{ backgroundColor: color }}>
      {initials}
    </div>
  );
}

export default Avatar;
