import mitt, { Emitter, Handler } from "mitt";

class Store {
  emitter: Emitter;
  state: any;

  constructor() {
    this.emitter = new mitt();
    this.state = {};

    this.setState = this.setState.bind(this);
  }

  on(handler: Handler) {
    this.emitter.on("change", handler);
  }

  onChange(handler: Handler) {
    return this.on(handler);
  }

  off(handler: Handler) {
    this.emitter.off("change", handler);
  }

  setState(state: any) {
    this.state = Object.assign(this.state, state);
    this.emitter.emit("change");
  }

  clear() {
    this.state = {};
    this.emitter.emit("change");
  }

  initRequestFunctions(functionsObj: { [key: string]: any }) {
    Object.keys(functionsObj).forEach((functionName) => {
      // TODO: Can we make this arbitrary indexing type-safe?
      // @ts-ignore
      this[functionName] = (...args) => {
        this.setState({
          [`${functionName}` + "Loading"]: true,
          [`${functionName}` + "Errors"]: [],
        });
        // @ts-ignore
        return new Promise((resolve, reject) => {
          return functionsObj[functionName]
            .call(this, ...args)
            .catch((res: any) => {
              // @ts-ignore
              this[`${functionName}` + "Errors"] = res.messages;
              reject(res);
              return res;
            })
            .then((res: any) => {
              this.setState({
                [`${functionName}` + "Loading"]: false,
                [`${functionName}` + "Response"]: res,
              });
              resolve(res);
            });
        });
      };

      this.setState({
        [`${functionName}` + "Loading"]: false,
        [`${functionName}` + "Errors"]: [],
      });
      Object.defineProperties(this, {
        [`${functionName}` + "Result"]: {
          get() {
            return {
              loading: this.state[`${functionName}` + "Loading"],
              errors: this.state[`${functionName}` + "Errors"],
              response: this.state[`${functionName}` + "Response"],
            };
          },
        },
      });
    });
  }
}

export default Store;
