import React from "react";

/* tslint:disable:max-line-length */
class Warning extends React.Component<any> {
  render() {
    return (
      <svg
        className={this.props.classes}
        xmlns="https://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 44 40"
      >
        <path
          d="M41.143 40H2.857C1.28 40 0 38.73 0 37.167L19.845 1.22C20.295.464 21.115 0 22 0c.885 0 1.705.464 2.155 1.22L44 37.167C44 38.73 42.72 40 41.143 40zM22 5.74L5.326 35.99h33.348L22 5.74zM22 16c1.105 0 2 .895 2 2v6c0 1.105-.895 2-2 2s-2-.895-2-2v-6c0-1.105.895-2 2-2zm0 13c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2z"
          fillRule="evenodd"
        />
      </svg>
    );
  }
}

/* tslint:enable:max-line-length */

export default Warning;
