// @ts-nocheck

// this file exists to share UI Redesign configuration across different parts of the site
// because we aren't wrapping the the application in the top-level
// This should be removed someday

import createCache from "@emotion/cache";

import { appendIconComponentCache } from "@elastic/eui/es/components/icon/icon";
import { icon as EuiIconQuestionInCircle } from "@elastic/eui/es/components/icon/assets/question_in_circle";
import { icon as EuiIconCalendar } from "@elastic/eui/es/components/icon/assets/calendar";
import { icon as EuiIconArrowDown } from "@elastic/eui/es/components/icon/assets/arrow_down";
import { icon as PmArrowBack } from "@pm-frontend/assets/icons/components/PmArrowBack.js";
import { icon as EuiIconBoxesHorizontal } from "@elastic/eui/es/components/icon/assets/boxes_horizontal";
import { icon as EuiIconHome } from "@elastic/eui/es/components/icon/assets/home";
import { icon as PmArrowForward } from "@pm-frontend/assets/icons/components/PmArrowForward.js";
import { icon as EuiIconArrowStart } from "@elastic/eui/es/components/icon/assets/arrowStart";
import { icon as EuiIconDot } from "@elastic/eui/es/components/icon/assets/dot";
import { icon as EuiIconDocument } from "@elastic/eui/es/components/icon/assets/document";
import { icon as EuiIconDocuments } from "@elastic/eui/es/components/icon/assets/documents";
import { icon as EuiIconImport } from "@elastic/eui/es/components/icon/assets/import";
import { icon as EuiIconUsers } from "@elastic/eui/es/components/icon/assets/users";
import { icon as EuiIconArrowEnd } from "@elastic/eui/es/components/icon/assets/arrowEnd";
import { icon as EuiIconAlert } from "@elastic/eui/es/components/icon/assets/alert";
import { icon as EuiIconReturnKey } from "@elastic/eui/es/components/icon/assets/return_key";
import { icon as PmSortDownArrow } from "@pm-frontend/assets/icons/components/PmSortDownArrow.js";
import { icon as PmSortUpArrow } from "@pm-frontend/assets/icons/components/PmSortUpArrow.js";
import { icon as PmIconSearch } from "@pm-frontend/assets/icons/components/PmSearchIcon.js";
import { icon as PmCloseIcon } from "@pm-frontend/assets/icons/components/PmCloseIcon.js";
import { icon as EuiIconCheck } from "@elastic/eui/es/components/icon/assets/check";
import { icon as EuiIconClock } from "@elastic/eui/es/components/icon/assets/clock";
import { icon as EuiIconWarn } from "@elastic/eui/es/components/icon/assets/warning";
import { icon as EuiIconDownload } from "@elastic/eui/es/components/icon/assets/download";
import { icon as EuiIconEyeClosed } from "@elastic/eui/es/components/icon/assets/eye_closed";
import { icon as EuiIconPopout } from "@elastic/eui/es/components/icon/assets/popout";
import { icon as EuiIconFullScreen } from "@elastic/eui/es/components/icon/assets/full_screen";
import { icon as EuiIconFullScreenExit } from "@elastic/eui/es/components/icon/assets/fullScreenExit";
import { icon as EuiIconTip } from "@elastic/eui/es/components/icon/assets/iInCircle";
import { icon as EuiIconSortRight } from "@elastic/eui/es/components/icon/assets/sortRight";
import { icon as EuiIconPlayFilled } from "@elastic/eui/es/components/icon/assets/playFilled";
import { icon as EuiIconGrab } from "@elastic/eui/es/components/icon/assets/grab";
import { icon as EuiIconError } from "@elastic/eui/es/components/icon/assets/error";
import { icon as EuiKeyboard } from "@elastic/eui/es/components/icon/assets/keyboard";
import { icon as EuiBoxesVertical } from "@elastic/eui/es/components/icon/assets/boxes_vertical";
import { icon as EuiTableDensityNormal } from "@elastic/eui/es/components/icon/assets/table_density_normal";
import { icon as EuiSortable } from "@elastic/eui/es/components/icon/assets/sortable";
import { icon as EuiControlsHorizontal } from "@elastic/eui/es/components/icon/assets/controls_horizontal";
import { icon as EuiTokenDate } from "@elastic/eui/es/components/icon/assets/tokenDate";
import { icon as EuiTokenString } from "@elastic/eui/es/components/icon/assets/tokenString";
import { icon as EuiSortLeft } from "@elastic/eui/es/components/icon/assets/sortLeft";

class SingletonCache {
  constructor() {
    if (SingletonCache._instance) {
      return SingletonCache._instance;
    }
    SingletonCache._instance = this;
    appendIconComponentCache({
      alert: EuiIconAlert,
      arrowDown: EuiIconArrowDown,
      arrowEnd: EuiIconArrowEnd,
      arrowLeft: PmArrowBack,
      arrowRight: PmArrowForward,
      arrowStart: EuiIconArrowStart,
      boxesHorizontal: EuiIconBoxesHorizontal,
      boxes_vertical: EuiBoxesVertical,
      calendar: EuiIconCalendar,
      check: EuiIconCheck,
      clock: EuiIconClock,
      controls_horizontal: EuiControlsHorizontal,
      cross: PmCloseIcon,
      document: EuiIconDocument,
      documents: EuiIconDocuments,
      dot: EuiIconDot,
      download: EuiIconDownload,
      error: EuiIconError,
      eyeClosed: EuiIconEyeClosed,
      fullScreen: EuiIconFullScreen,
      fullScreenExit: EuiIconFullScreenExit,
      grab: EuiIconGrab,
      home: EuiIconHome,
      iconTip: EuiIconTip,
      import: EuiIconImport,
      keyboard: EuiKeyboard,
      playFilled: EuiIconPlayFilled,
      popout: EuiIconPopout,
      question: EuiIconQuestionInCircle,
      return_key: EuiIconReturnKey,
      search: PmIconSearch,
      sortable: EuiSortable,
      sortDown: PmSortDownArrow,
      sortLeft: EuiSortLeft,
      sortRight: EuiIconSortRight,
      sortUp: PmSortUpArrow,
      table_density_normal: EuiTableDensityNormal,
      tokenDate: EuiTokenDate,
      tokenString: EuiTokenString,
      users: EuiIconUsers,
      warning: EuiIconWarn,
    });

    this.euiCache = createCache({
      key: "eui",
      container: document.querySelector('meta[name="eui-style-insert"]'),
    });
    this.euiCache.compat = true;
  }

  getEuiCache() {
    return this.euiCache;
  }
}

export { SingletonCache };
