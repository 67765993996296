import { AxiosPromise } from "axios";
import I from "immutable";

import createModelStore from "./create-model-store";
import RecordUtils from "./record-utils";

function paginateStore(store: new () => any) {
  return class extends createModelStore(store) {
    constructor(modelClass: any, modelFields: I.Set<string>) {
      super();

      this.MODEL_CLASS = modelClass;
      this.MODEL_FIELDS = modelFields;

      this.state.fetchedModels = I.List();
      this.state.models = I.List();
      this.state.url = null;
      this.state.next = null;
      this.state.previous = null;
    }

    canFetchNext() {
      return !!this.state.next;
    }

    canFetchPrevious() {
      return !!this.state.previous;
    }

    getFetchedModels() {
      return this.state.fetchedModels;
    }

    fetch(xhr: AxiosPromise) {
      return xhr.then(res => {
        let models = this._makeModels(res.data.results);
        this.setState({
          fetchedModels: models,
          models: this._receive(models),
          next: res.data.next,
          previous: res.data.previous,
          url: res.config.url
        });
      });
    }

    fetchOne(xhr: AxiosPromise) {
      return xhr.then(res => {
        let models = this._makeModels([res.data]);
        this.setState({
          fetchedModels: models,
          models: this._receive(models),
          url: res.config.url
        });
      });
    }

    _receive(models: I.List<I.Map<string, any>>) {
      // Filter out models that haven't changed
      let changedModels = models.filter(m => {
        return !this.getAll().includes(m);
      });

      // Only worry about merging state if there are new or updated models
      if (changedModels.size) {
        let existingUpdates = changedModels
          .filter((model: I.Map<string, any>) => {
            const mId = model.get("id");
            return this.getAll().find((m: I.Map<string, any>) => m.get("id") === mId);
          })
          .map((model: I.Map<string, any>) => {
            return this.getAll()
              .find((m: I.Map<string, any>) => {
                return m.get("id") === model.get("id");
              })
              .mergeDeep(model);
          });

        let newModels = models.filter((model: I.Map<string, any>) => {
          return !this.getAll().find((m: I.Map<string, any>) => m.get("id") === model.get("id"));
        });

        let newAndUpdated = newModels.concat(existingUpdates);

        return this.getAll()
          .filterNot((model: I.Map<string, any>) =>
            newAndUpdated.find((m: I.Map<string, any>) => m.get("id") === model.get("id"))
          )
          .concat(newAndUpdated);
      } else {
        return this.getAll();
      }
    }

    _makeModels(models: any[]) {
      return I.List(
        models.map(model => {
          return RecordUtils.initFromJS(new this.MODEL_CLASS(), model, this.MODEL_FIELDS);
        })
      );
    }
  };
}

export default paginateStore;
