import _ from "lodash";
import URLUtils from "../../utils/url-utils";
import { parseQuery } from "../utils/location-utils";

let PaginationHelper = {
  getPageDataStores(propLabel, store, url) {
    let lastFetchedUrl = this._getLastFetchedUrl(url);

    return {
      [propLabel]() {
        return {
          value: store.getByUrl(lastFetchedUrl),
          store: store
        };
      }
    };
  },

  getPageStores(store, url) {
    let lastFetchedUrl = this._getLastFetchedUrl(url);

    return {
      nextPage() {
        return {
          value: store.getNextPage(lastFetchedUrl),
          store: store
        };
      },

      prevPage() {
        return {
          value: store.getPrevPage(lastFetchedUrl),
          store: store
        };
      }
    };
  },

  _getLastFetchedUrl(url) {
    return URLUtils.makeUrl(url, parseQuery(this.props.location));
  },

  componentWillMount() {
    this.fetchData(this.props);
  },

  componentWillUpdate(nextProps) {
    if (!_.isEqual(parseQuery(nextProps.location), parseQuery(this.props.location))) {
      this.fetchData(nextProps);
    }
  }
};

export default PaginationHelper;
