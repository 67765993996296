import React from "react";
import _ from "lodash";

import OwnerApi from "../apis/owner-api";
import TypeAhead from "../../common/components/typeahead";

class OwnerChoiceRow extends React.Component {
  render() {
    return (
      <span className="option-content">
        <div className="unit-name">
          {this.props.item.first_name} {this.props.item.last_name}
        </div>
      </span>
    );
  }
}

class UnitOwnerSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.ownerSource = this.ownerSource.bind(this);
  }

  render() {
    return (
      <div className="item-multiselect">
        <TypeAhead
          source={_.debounce(this.ownerSource, 100)}
          onItemChosen={this.props.onOwnerChosen}
          displayComponent={OwnerChoiceRow}
          onOpen={this.onUnselectedOpen.bind(this)}
          onClose={this.onUnselectedClose.bind(this)}
          multiselect={false}
          isOpen={this.state.isOpen}
          placeholderText={this.props.value || "Enter the name of the property owner"}
          inputId="new-owner"
        />
      </div>
    );
  }

  ownerSource(query, process) {
    return OwnerApi.search(query, {
      ownerIdExclusions: this.props.ownerIdExclusions
    }).then(result => process(result.data));
  }

  onUnselectedClose(query) {
    this.setState({ isOpen: false });
    this.props.onOwnerChosen(query);
  }

  onUnselectedOpen() {
    this.open();
  }

  open() {
    this.setState({ isOpen: true });
  }
}

export default UnitOwnerSelect;
