import { isArray } from "lodash";
import React from "react";
import { MdArrowDropDown } from "react-icons/md";

import { Select as ChakraSelect, SelectProps as ChakraSelectProps } from "@chakra-ui/react";

import { colors, shadows } from "../../theme";
import FormError from "../form-error/form-error";

export interface Props extends ChakraSelectProps {
  /**
   * Validation errors.
   */
  errors?: string | string[] | null;
}

export const Select: React.FC<Props> = ({ errors, className, ...elementProps }) => {
  return (
    <div>
      <ChakraSelect
        backgroundImage="none !important"
        borderColor={errors ? colors.pmRed : colors.pmGreyLight}
        borderRadius={0}
        className={className}
        color={colors.pmGreyMedium}
        icon={<MdArrowDropDown />}
        iconSize="2em"
        iconColor={colors.pmBlue}
        _hover={{
          borderColor: errors ? colors.pmRed : colors.pmBlue,
          boxShadow: shadows.pmDropShadow,
        }}
        _focus={{
          borderColor: errors ? colors.pmRed : colors.pmBlue,
          boxShadow: shadows.pmDropShadow,
          outline: "none",
        }}
        {...elementProps}
      />

      {errors && <FormError>{isArray(errors) ? errors.join(", ") : errors}</FormError>}
    </div>
  );
};

Select.displayName = "Select";

export default Select;
