import React from "react";

/* tslint:disable:max-line-length */
class Information extends React.Component<{ classes?: string }> {
  render() {
    return (
      <svg
        className={this.props.classes}
        xmlns="https://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
        width="15"
        height="18"
        viewBox="0 0 30 36"
      >
        <path
          d="M25 3.273h-1.957C22.355 1.367 20.51 0 18.333 0h-6.666C9.49 0 7.645 1.367 6.957 3.273H5c-2.762 0-5 2.197-5 4.91V31.09C0 33.804 2.238 36 5 36h20c2.762 0 5-2.197 5-4.91V8.183c0-2.712-2.238-4.91-5-4.91zM11.667 2.99h6.666c.92 0 1.667 1.015 1.667 1.92 0 .903-.746 2.065-1.667 2.065h-6.666C10.747 6.975 10 5.813 10 4.91c0-.905.746-1.92 1.667-1.92zM26.98 31.34c0 .905-.746 1.637-1.667 1.637H4.688c-.92 0-1.667-.732-1.667-1.636V8.183c0-.904.747-1.46 1.668-1.46l2.27.03c.686 1.906 2.533 3.223 4.71 3.223h6.665c2.177 0 4.022-1.348 4.71-3.254h2.27c.92 0 1.666.558 1.666 1.462v23.16z"
          id="path-1"
          fillRule="evenodd"
        />
      </svg>
    );
  }
}
/* tslint:enable:max-line-length */

export default Information;
