import { asImmer } from "@pm-app/utils/decorators";
import AddressUtils from "@pm-assets/js/utils/address-utils";

class PropertyUtils {
  @asImmer()
  static getMaintenanceNotes(prop: any) {
    return prop?.maintenance_notes;
  }

  @asImmer()
  static getName(prop: any) {
    if (prop) {
      return prop.property_name;
    }
  }

  @asImmer()
  static getAddressLines(prop: any, emptyMsg = "") {
    let lines = [
      PropertyUtils.getAddressLine1(prop),
      PropertyUtils.getAddressLine2(prop),
      PropertyUtils.getAddressLine3(prop),
      emptyMsg,
    ];
    lines = lines.filter((line) => line);
    return lines.join(", ");
  }

  @asImmer()
  static getDisplayCityStateZip(prop: any) {
    if (prop.display_address) {
      return AddressUtils.getFormattedCityStateZip(prop.display_address);
    }
  }

  @asImmer()
  static getNameOrLine1(prop: any) {
    if (prop) {
      return prop.property_name || prop.line_1;
    }
  }

  @asImmer()
  static maybeGetName(prop: any) {
    // Return the property name if it's different then line 1 of the address
    return prop.property_name !== prop.line_1 ? prop.property_name : "";
  }

  @asImmer()
  static getAddressLine1(prop: any) {
    return prop.line_1 ? prop.line_1 : "";
  }

  @asImmer()
  static getAddressLine2(prop: any) {
    return prop.line_2 ? prop.line_2 : "";
  }

  @asImmer()
  static getAddressLine3(prop: any) {
    return prop.line_3 ? prop.line_3 : "";
  }
}

export default PropertyUtils;
