var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import {
  EuiInMemoryTable,
  EuiText,
  EuiFlexItem,
  EuiFlexGroup,
  EuiSpacer
} from "@elastic/eui";
import { TableStyles } from "@pm-frontend/shared/components/Tables/TableStyles";
import { colors } from "@pm-frontend/styles";
import { PmBulkActionButton } from "../Buttons/PmBulkActionButton";
const DEFAULT_PAGE_SIZE = 10;
const InMemorySearchableTable = ({
  columns,
  items = [],
  searchable,
  search,
  showCountSummary = false,
  itemLabel,
  sorting,
  "data-testid": dataTestId,
  selectionProps,
  tableLayout = "fixed",
  emptyMessage,
  compressed = false,
  itemsPerPage = DEFAULT_PAGE_SIZE
}) => {
  const PAGE_SIZE = itemsPerPage || DEFAULT_PAGE_SIZE;
  const [pagination, setPagination] = useState({ pageIndex: 0 });
  const onTableChange = ({ page: { index } }) => {
    setPagination({ pageIndex: index });
  };
  let bulkActionButton;
  if (selectionProps == null ? void 0 : selectionProps.bulkActionsProps) {
    bulkActionButton = (selectionProps == null ? void 0 : selectionProps.selectedItems) && selectionProps.selectedItems.length > 0 || (selectionProps == null ? void 0 : selectionProps.bulkActionsProps.bulkActionDisplayState) === "success" || (selectionProps == null ? void 0 : selectionProps.bulkActionsProps.bulkActionDisplayState) === "error" ? /* @__PURE__ */ React.createElement(EuiFlexItem, null, /* @__PURE__ */ React.createElement(
      PmBulkActionButton,
      {
        numFilters: selectionProps.selectedItems.length,
        body: selectionProps.bulkActionsProps.bulkActionButtonText,
        color: selectionProps.bulkActionsProps.bulkActionButtonColor,
        bulkActions: selectionProps.bulkActionsProps.bulkActions,
        successText: selectionProps.bulkActionsProps.bulkActionSuccessText,
        displayState: selectionProps.bulkActionsProps.bulkActionDisplayState,
        "data-testid": selectionProps.bulkActionsProps.bulkActionDataTestId
      }
    ), !showCountSummary && /* @__PURE__ */ React.createElement(EuiSpacer, { size: "s" })) : null;
  }
  const resultsCount = `${PAGE_SIZE * pagination.pageIndex + 1}-${Math.min(
    PAGE_SIZE * pagination.pageIndex + PAGE_SIZE,
    items.length
  )} of ${items.length}`;
  const composedSearch = __spreadProps(__spreadValues({
    "data-test-subj": "table-search-input"
  }, search ? search : {}), {
    box: __spreadValues({
      placeholder: `Search For ${itemLabel}`,
      incremental: true,
      schema: true
    }, (search == null ? void 0 : search.box) ? search.box : {})
  });
  const selection = {
    onSelectionChange: (selectedItems) => selectionProps == null ? void 0 : selectionProps.setSelectedItems(selectedItems),
    selectable: (selectionProps == null ? void 0 : selectionProps.selectable) || (() => true),
    selectableMessage: selectionProps == null ? void 0 : selectionProps.selectableMessage,
    selected: selectionProps == null ? void 0 : selectionProps.selectedItems
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(TableStyles, null), /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", alignItems: "flexStart", gutterSize: "s" }, !(search || searchable) && bulkActionButton ? /* @__PURE__ */ React.createElement(EuiFlexItem, null, bulkActionButton) : null, !(search || searchable) && showCountSummary && items.length > 0 && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
    EuiText,
    {
      size: "xs",
      "data-testid": "table-item-count-summary",
      color: colors.neutrals.gray800,
      style: { lineHeight: "18px", marginBottom: "8px" }
    },
    "Showing ",
    resultsCount,
    " ",
    /* @__PURE__ */ React.createElement("span", { style: { fontWeight: 700 } }, itemLabel)
  )), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true }, /* @__PURE__ */ React.createElement(
    EuiInMemoryTable,
    __spreadValues({
      message: emptyMessage,
      className: "euiSearchableTable",
      items,
      columns,
      itemId: (selectionProps == null ? void 0 : selectionProps.itemId) || void 0,
      isSelectable: !!selectionProps,
      selection: selectionProps ? selection : void 0,
      search: searchable && composedSearch,
      pagination: __spreadProps(__spreadValues({}, pagination), {
        pageSize: PAGE_SIZE,
        showPerPageOptions: false
      }),
      sorting: sorting || true,
      tableLayout,
      onTableChange,
      compressed,
      childrenBetween: /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", alignItems: "flexStart", gutterSize: "s" }, search || searchable ? bulkActionButton : null, showCountSummary && items.length > 0 && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
        EuiText,
        {
          size: "xs",
          "data-testid": "table-item-count-summary",
          color: colors.neutrals.gray800,
          style: { lineHeight: "18px", marginBottom: "8px" }
        },
        "Showing ",
        resultsCount,
        " ",
        /* @__PURE__ */ React.createElement("span", { style: { fontWeight: 700 } }, itemLabel)
      )))
    }, dataTestId ? { "data-testid": dataTestId } : {})
  ))));
};
export { InMemorySearchableTable };
