import * as React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

export enum IconAlignments {
  LEFT = "left",
  RIGHT = "right"
}

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  /**
   * Which icon to show.
   */
  icon: any;
  /**
   * Passed directly to the underling `a` tag.
   */
  href?: string;
  /**
   * ReactRouter-friendly path.
   */
  path?: string;
  /**
   * Function to call when link is clicked.
   */
  onLinkClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  /**
   * Is the link disabled?
   * @default false
   */
  inactive?: boolean;
  /**
   * Where the icon should be in relation to the text. Can be LEFT or RIGHT.
   * @default IconAlignments.LEFT.
   */
  iconAligned?: IconAlignments;
}

export const IconLink: React.SFC<Props> = props => {
  const { icon, href, path, onLinkClick, inactive, iconAligned, children, className, ...elementProps } = props;

  const classes = classnames(
    "pm-icon-link",
    {
      "pm-icon-link-inactive": inactive
    },
    className ? className.split(" ") : ""
  );

  let linkContent;

  if (iconAligned === IconAlignments.RIGHT) {
    linkContent = (
      <>
        {children}
        <span className="pm-icon-link-icon pm-icon-link-icon-right">{icon()}</span>
      </>
    );
  } else {
    linkContent = (
      <>
        <span className="pm-icon-link-icon">{icon()}</span>
        {children}
      </>
    );
  }

  return (
    <div className={classes} {...elementProps}>
      {(() => {
        if (inactive) {
          return linkContent;
        }

        if (href) {
          return (
            <a target="_blank" href={href}>
              {linkContent}
            </a>
          );
        }

        if (path) {
          return <Link to={path}>{linkContent}</Link>;
        }

        return (
          <a href="#" onClick={onLinkClick}>
            {linkContent}
          </a>
        );
      })()}
    </div>
  );
};

IconLink.displayName = "IconLink";

export default IconLink;
