var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import classnames from "classnames";
import React from "react";
export var LabelSize = /* @__PURE__ */ ((LabelSize2) => {
  LabelSize2["Medium"] = "md";
  LabelSize2["Small"] = "sm";
  return LabelSize2;
})(LabelSize || {});
export const Label = (_a) => {
  var _b = _a, {
    display,
    errors,
    required,
    popover,
    size,
    children,
    className
  } = _b, elementProps = __objRest(_b, [
    "display",
    "errors",
    "required",
    "popover",
    "size",
    "children",
    "className"
  ]);
  const classDisplay = `pm-forms-label-display-${display}`;
  const wrapClasses = classnames(
    {
      "pm-forms-label": display !== null && display !== void 0,
      [classDisplay]: display !== null && display !== void 0
    },
    popover ? "pm-forms-label-wrap" : ""
  );
  const classes = classnames(
    "pm-forms-label",
    {
      "pm-forms-label-error": errors !== null && errors !== void 0,
      [classDisplay]: display !== null && display !== void 0,
      "pm-forms-label-display-block": display === null || display === void 0
    },
    size,
    className ? className.split(" ") : ""
  );
  return /* @__PURE__ */ React.createElement("div", { className: wrapClasses }, /* @__PURE__ */ React.createElement("label", __spreadValues({ className: classes }, elementProps), children, required === true && /* @__PURE__ */ React.createElement("span", { className: "pm-forms-label-required" }, "*"), required === false && /* @__PURE__ */ React.createElement("span", { className: "pm-forms-label-optional" }, "(optional)")), popover && /* @__PURE__ */ React.createElement("div", { className: "pm-forms-label-popover" }, popover));
};
Label.displayName = "Label";
export default Label;
