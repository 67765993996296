// @ts-nocheck
import History from "history";
import { LinkProps } from "react-router-dom";
import { parseQuery } from "./location-utils";

let prevRoute: History.LocationDescriptorObject;

let RouteUtils = {
  savePrevRoute(route: History.LocationDescriptorObject) {
    prevRoute = route;
  },

  isPrevRoutePathEqualTo(path: string) {
    return prevRoute && prevRoute.pathname === path;
  },

  getBackLinkState(config: LinkProps) {
    /* Returns the `Link` config need to go either
         1) Go back one place in a browser's history or
         2) Go to the path of a fallback url that is passed in
         */

    let pathname = "";

    if (config.to && typeof config.to !== "string" && config.to.pathname) {
      pathname = config.to.pathname;
    } else if (config.to && typeof config.to === "string") {
      pathname = config.to;
    }

    if (prevRoute && prevRoute.pathname && prevRoute.pathname.endsWith(pathname)) {
      return { to: config.to, query: parseQuery(prevRoute) };
    }

    return config;
  },
};

export default RouteUtils;
