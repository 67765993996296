import alt from "../../alt";

import BaseStore, { StatusStates } from "../../stores/base-store";
import ContactSettingsActions from "../actions/contact-settings-actions";
import ContactSettingsSource from "../sources/contact-settings-source";

export let ExtraActions = {
  FETCH_CURRENT: "FETCH_CURRENT"
};

class ContactSettingsStore extends BaseStore {
  constructor(options) {
    super(options);

    this.bindListeners({
      fetchingCurrent: ContactSettingsActions.FETCH_CURRENT,
      fetchingCurrentDone: ContactSettingsActions.FETCH_CURRENT_DONE,
      fetchingCurrentFail: ContactSettingsActions.FETCH_CURRENT_FAIL,
      updating: ContactSettingsActions.UPDATE,
      updateDone: ContactSettingsActions.UPDATE_DONE,
      updateFail: ContactSettingsActions.UPDATE_FAIL
    });

    this.exportPublicMethods({
      getCurrent: this.getCurrent.bind(this)
    });

    this.registerAsync(ContactSettingsSource);
  }

  fetchingCurrent(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.FETCH_CURRENT, StatusStates.PENDING);
  }

  fetchingCurrentDone(dispatchData) {
    this.receiveOne(dispatchData);

    this.augmentAndMarkStatus(dispatchData, ExtraActions.FETCH_CURRENT, StatusStates.DONE);
  }

  fetchingCurrentFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.FETCH_CURRENT, StatusStates.FAIL);
  }

  getCurrent() {
    return this.getModelState().first();
  }
}

export default alt.createStore(ContactSettingsStore, "ContactSettingsStore");
