import I from "immutable";
import React from "react";

import { Group } from "./Group";
import { ImmutableMap } from "../../../../assets/js/types/common";
import TextBlock, { TextBlockSizes } from "../../texts/TextBlock";

export interface GroupedSelectionProps {
  checkValues: (values: I.Set<string>) => void;
  columns: I.List<string>;
  columnsSelectAll: I.List<string>;
  columnsUnselectAll: I.List<string>;
  disabledValues: I.Set<string>;
  groups: I.List<ImmutableMap>;
  selectedValues: I.Set<string>;
  toggleValue: (value: string) => void;
  uncheckValues: (values: I.Set<string>) => void;
}

export class GroupedSelection extends React.Component<GroupedSelectionProps> {
  render() {
    return (
      <React.Fragment>
        {this.renderSelectAll()}
        {this.props.groups.map((group: ImmutableMap, i) => {
          return (
            <Group
              key={i}
              checkValues={this.props.checkValues}
              columns={this.props.columns}
              disabledValues={this.props.disabledValues}
              items={group.get("items")}
              selectedValues={this.props.selectedValues}
              title={group.get("title")}
              toggleValue={this.props.toggleValue}
              uncheckValues={this.props.uncheckValues}
            />
          );
        })}
      </React.Fragment>
    );
  }

  renderSelectAll() {
    return (
      <div className="pm-select-all">
        {this.props.columns.map((_, i: number) => {
          const values = this.props.groups
            .map((group: ImmutableMap) => {
              return group.get("items").map((item: ImmutableMap) => {
                return item.getIn(["values", i]);
              });
            })
            .flatten()
            .filter(value => value && !this.props.disabledValues.contains(value))
            .toSet();

          const unselectAll = values.every(value => !value || this.props.selectedValues.contains(value));

          const disabled = values.every(value => !value || this.props.disabledValues.contains(value));

          if (unselectAll) {
            return (
              <TextBlock size={TextBlockSizes.MD} key={i}>
                <a className={disabled ? "disabled" : ""} onClick={() => disabled || this.props.uncheckValues(values)}>
                  {this.props.columnsUnselectAll.get(i)}
                </a>
              </TextBlock>
            );
          } else {
            return (
              <TextBlock size={TextBlockSizes.MD} key={i}>
                <a className={disabled ? "disabled" : ""} onClick={() => disabled || this.props.checkValues(values)}>
                  {this.props.columnsSelectAll.get(i)}
                </a>
              </TextBlock>
            );
          }
        })}
      </div>
    );
  }
}
