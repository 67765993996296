import alt from "../../alt";
import BaseStore from "../../stores/base-store";
import tenantMeldFileSource from "../sources/tenant-meld-file-source";
import tenantMeldFileActions from "../actions/tenant-meld-file-actions";

class TenantMeldFileStore extends BaseStore {
  constructor() {
    super();

    this.registerAsync(tenantMeldFileSource);

    this.bindListeners({
      receive: tenantMeldFileActions.FETCH_FILES_DONE,
      saveDone: tenantMeldFileActions.CREATE_FILE_DONE,
      saving: tenantMeldFileActions.CREATE_FILE,
      deleteDone: tenantMeldFileActions.DELETE_FILE_DONE,
      deleting: tenantMeldFileActions.DELETE_FILE
    });

    this.exportPublicMethods({
      getFilesForMeld: this.getFilesForMeld.bind(this)
    });
  }

  getFilesForMeld(meldId) {
    let id = parseInt(meldId);
    return this.getModelState().filter(file => file.get("meld") === id);
  }
}

export default alt.createStore(TenantMeldFileStore, "TenantMeldFileStore");
