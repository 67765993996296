import React from "react";
import { Redirect as RRedirect } from "react-router-dom";
import { LinkHelper } from "../../../../web/shared/utils/link";

export default function Redirect(props) {
  let { to, ...p } = props;

  let t = LinkHelper.normalize(to);
  return <RRedirect to={t} {...p} />;
}
