import createReactClass from "create-react-class";
import React from "react";

/* tslint:disable:max-line-length */
let Plus = createReactClass<{ classes?: string }, {}>({
  render() {
    return (
      <svg
        xmlns="https://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
        width="40"
        height="40"
        className={`plus-svg ${this.props.classes}`}
        viewBox="0 0 40 40"
      >
        <path
          d="M20 0C8.955 0 0 8.954 0 20s8.955 20 20 20 20-8.954 20-20S31.045 0 20 0zm0 36.364c-9.037 0-16.364-7.327-16.364-16.364S10.963 3.636 20 3.636 36.364 10.963 36.364 20 29.037 36.364 20 36.364zm7.273-18.182h-5.455v-5.455c0-1.004-.814-1.818-1.818-1.818s-1.818.813-1.818 1.817v5.455h-5.455c-1.004 0-1.818.814-1.818 1.818s.813 1.818 1.817 1.818h5.455v5.455c0 1.004.814 1.818 1.818 1.818s1.818-.813 1.818-1.817v-5.455h5.455c1.004 0 1.818-.814 1.818-1.818s-.813-1.818-1.817-1.818z"
          fillRule="evenodd"
        />
      </svg>
    );
  },
});

export default Plus;
/* tslint:enable:max-line-length */
