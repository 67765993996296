import axios from "axios";

export default {
  source() {
    return axios.CancelToken.source();
  },

  isCancel(thrown: any) {
    return axios.isCancel(thrown);
  }
};
