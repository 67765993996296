var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { asImmer } from "../../../app/utils/decorators";
const _AddressUtils = class {
  static getLine1AndCity(address) {
    address = address;
    return _AddressUtils.formatCityStateZip(address.city, address.county_province, address.postcode);
  }
  static getFormattedCityStateZip(address, emptyMsg = "") {
    address = address;
    return _AddressUtils.formatCityStateZip(address.city, address.county_province, address.postcode) || emptyMsg;
  }
  static formatCityStateZip(city, state, postcode, emptyMsg = "") {
    if (!city && !postcode && !state) {
      return emptyMsg;
    }
    if (city) {
      return `${city}, ${state} ${postcode}`;
    }
    return `${state} ${postcode}`;
  }
  static getPropertyName(address, emptyMsg = "No property name") {
    address = address;
    let property_name = address.property_name;
    return property_name !== "" ? property_name : emptyMsg;
  }
  static displayPropertyName(address) {
    let property_name = _AddressUtils.getPropertyName(address, "");
    return property_name !== _AddressUtils.getAddressLine1(address) ? property_name : "";
  }
  static getAddressLine1(address, emptyMsg = "") {
    address = address;
    return address.line_1 || emptyMsg;
  }
  static getAddressLine2(address, emptyMsg = "") {
    address = address;
    return address.line_2 || emptyMsg;
  }
  static getAddressLine3(address, emptyMsg = "") {
    address = address;
    return address.line_3 || emptyMsg;
  }
  static getDisplayAddressLines(address, emptyMsg = "") {
    address = address;
    let lines = [
      _AddressUtils.getAddressLine1(address, emptyMsg),
      _AddressUtils.getAddressLine2(address, emptyMsg),
      _AddressUtils.getAddressLine3(address, emptyMsg),
      emptyMsg
    ];
    lines = lines.filter((line) => line);
    return lines.join(", ");
  }
  static getID(address) {
    address = address;
    return address.id;
  }
  static getDirectionsUrlString(address) {
    address = address;
    const addressString = [_AddressUtils.getAddressLine1(address), _AddressUtils.getFormattedCityStateZip(address)].join(" ").trim();
    const encodedAddress = encodeURI(addressString);
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return `https://maps.apple.com/?&daddr=${encodedAddress}&dirflg=d`;
    }
    return `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}&travelmode=driving`;
  }
  static getAllAddressComponents(address) {
    const part1 = [_AddressUtils.displayPropertyName(address), _AddressUtils.getAddressLine1(address)].filter((addressPart) => !!addressPart).join(" ");
    return `${part1} ${_AddressUtils.getFormattedCityStateZip(address)}`;
  }
};
let AddressUtils = _AddressUtils;
__decorateClass([
  asImmer()
], AddressUtils, "getLine1AndCity", 1);
__decorateClass([
  asImmer()
], AddressUtils, "getFormattedCityStateZip", 1);
__decorateClass([
  asImmer()
], AddressUtils, "getPropertyName", 1);
__decorateClass([
  asImmer()
], AddressUtils, "displayPropertyName", 1);
__decorateClass([
  asImmer()
], AddressUtils, "getAddressLine1", 1);
__decorateClass([
  asImmer()
], AddressUtils, "getAddressLine2", 1);
__decorateClass([
  asImmer()
], AddressUtils, "getAddressLine3", 1);
__decorateClass([
  asImmer()
], AddressUtils, "getDisplayAddressLines", 1);
__decorateClass([
  asImmer()
], AddressUtils, "getID", 1);
__decorateClass([
  asImmer()
], AddressUtils, "getDirectionsUrlString", 1);
__decorateClass([
  asImmer()
], AddressUtils, "getAllAddressComponents", 1);
export default AddressUtils;
