import classnames from "classnames";
import React from "react";

export enum LabelSize {
  Medium = "md",
  Small = "sm"
}

export interface Props extends React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  /**
   * @default block
   */
  display?: "block" | "inline" | "inline-block";
  /**
   * Validation errors.
   */
  errors?: string | string[] | boolean | null;
  /**
   * Whether or not the field is required.
   * true = shows `*`
   * false = shows `(optional)`
   * undefined = shows nothing
   * @default undefined
   */
  required?: boolean;
  /**
   * A Popover to be positioned next to the
   * the label.
   * @default undefined
   */
  popover?: JSX.Element;
  /**
   * Can be Medium or Small.
   */
  size: LabelSize;
}

export const Label: React.FC<Props> = ({
  display,
  errors,
  required,
  popover,
  size,
  children,
  className,
  ...elementProps
}) => {
  const classDisplay = `pm-forms-label-display-${display}`;
  const wrapClasses = classnames(
    {
      "pm-forms-label": display !== null && display !== undefined,
      [classDisplay]: display !== null && display !== undefined
    },
    popover ? "pm-forms-label-wrap" : ""
  );

  const classes = classnames(
    "pm-forms-label",
    {
      "pm-forms-label-error": errors !== null && errors !== undefined,
      [classDisplay]: display !== null && display !== undefined,
      "pm-forms-label-display-block": display === null || display === undefined
    },
    size,
    className ? className.split(" ") : ""
  );

  return (
    <div className={wrapClasses}>
      <label className={classes} {...elementProps}>
        {children}
        {required === true && <span className="pm-forms-label-required">*</span>}
        {required === false && <span className="pm-forms-label-optional">(optional)</span>}
      </label>

      {popover && <div className="pm-forms-label-popover">{popover}</div>}
    </div>
  );
};

Label.displayName = "Label";

export default Label;
