import React from "react";
import { Label, Props as LabelProps } from "./label";
import { Field, FieldProps } from "formik";

interface Props extends LabelProps {
  /**
   * Gets passed to formik's <Field /> component.
   * Used to match the field to form value for validation and state management.
   */
  match: string;
}

export const FormikLabel: React.FC<Props> = ({ match, ...rest }) => (
  <Field name={match}>{({ meta }: FieldProps) => <Label errors={meta.error} {...rest} />}</Field>
);

FormikLabel.displayName = "FormikLabel";
