import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";

class TagApi extends BaseApi {
  static fetchAll({ url, xhrOptions }: { url?: string; xhrOptions?: any } = {}) {
    if (url) {
      return this.get(url, { normalizeUrl: false, ...xhrOptions });
    }
    return this.get(ApiUrls.meldTagList, xhrOptions);
  }

  static search(query: string, { tagIdExclusions }: { tagIdExclusions?: string } = {}) {
    return this.get(ApiUrls.meldTagSearchList, {
      params: {
        query,
        exclude_ids: tagIdExclusions
      }
    });
  }

  static getTags(tags: Array<(string | number)>) {
    return this.get(ApiUrls.meldTagList, {
      params: {
        ids: tags.join(",")
      }
    })
  }

  static updateTag(tag: { id: number }) {
    return this.patch(ApiUrls.meldTagDetail(tag.id), tag);
  }

  static deleteTag(id: number) {
    return this.delete(ApiUrls.meldTagDetail(id));
  }
}

export default TagApi;
