import React from "react";

export default function withFileRefresher<ChildProps>(
  Comp: React.ComponentType<ChildProps>,
  fetchFn: (props: Readonly<ChildProps>) => void
) {
  return class WithFileRefresher extends React.Component<ChildProps> {
    fetchInterval: any;

    render(): JSX.Element {
      return <Comp {...this.props} />;
    }

    componentWillMount() {
      // Refresh every 8 hours so that signed urls don't become invalid if a window is left open
      this.fetchInterval = setInterval(() => fetchFn(this.props), 1000 * 60 * 60 * 8);
    }

    componentWillUnmount() {
      clearInterval(this.fetchInterval);
    }
  };
}
