import React from "react";
import { Box, BoxProps, Flex, Icon, Text } from "@chakra-ui/react";
import { IoMdPin } from "react-icons/io";
import { stylesForSemiboldText } from "../theme";
import { getFullDisplayAddress, getPropertyName } from "@pm-shared/utils/meld";
import { Meld } from "@pm-owner-hub/src/utils/api-types";

interface Props extends BoxProps {
  meld: Meld;
}

export function LocationLine({ meld, ...props }: Props) {
  return (
    <Box color="gray.500" fontSize="sm" {...props}>
      <Flex as="span" alignItems="top" direction="row">
        <Icon as={IoMdPin} fontSize="lg" color="green.600" marginRight={1} marginTop={0.5} />
        <div data-test="owner_approvals.approval_card.location_line">
          {getPropertyName(meld) ? <Text {...stylesForSemiboldText}>{`${getPropertyName(meld)}`}</Text> : null}
          <Text>{getFullDisplayAddress(meld)}</Text>
        </div>
      </Flex>
    </Box>
  );
}
