import React, { Component } from "react";

interface DetailViewProps {
  className?: string;
  smallClass?: string;
  mediumClass?: string;
  largeClass?: string;
}

class DetailView extends Component<DetailViewProps> {
  render() {
    let { smallClass, mediumClass, largeClass } = this.props;
    return (
      <div className={`main row ${this.props.className}`}>
        <div className="detail-view">
          <div
            className={`small-centered small-${smallClass || 12} medium-${mediumClass || 11} large-${largeClass ||
              9} columns detail-content`}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default DetailView;
