import classnames from "classnames";
import React, { ButtonHTMLAttributes } from "react";
import SVGInline from "react-svg-inline";

import { FlexJustifyContent } from "../utils/constants";

export enum ButtonTypes {
  Primary = "primary",
  Danger = "danger",
  Cancel = "cancel",
  Neutral = "neutral"
}

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: any;
  buttonType?: ButtonTypes;
  disabled?: boolean;
  className?: string;
  flexColumns?: boolean;
  justifyContent?: FlexJustifyContent;
  submit?: boolean;
  attributes?: {};
  autoFocus?: boolean;
  showLoading?: boolean;
  showSuccess?: boolean;
}

const loader = require("../../assets/icons/Load.svg");
const check = require("../../assets/icons/Check.svg");

class Button extends React.Component<ButtonProps> {
  render() {
    const {
      buttonType,
      justifyContent,
      flexColumns,
      showSuccess,
      className,
      submit,
      children,
      showLoading,
      ...props
    } = this.props;

    const classes = classnames(
      "pm-button",
      {
        [buttonType as string]: !!buttonType,
        [justifyContent as string]: !!justifyContent,
        "flex-columns": flexColumns,
        loading: showLoading,
        success: showSuccess
      },
      className
    );

    const rawType = submit ? "submit" : "button";

    return (
      <button type={rawType} className={classes} {...props}>
        <React.Fragment>
          <div className="button-children-container">{children}</div>

          {showLoading && (
            <div className="indication-container">
              <SVGInline className="button-indication" svg={loader} />
            </div>
          )}

          {showSuccess && (
            <div className="indication-container success-indication">
              <SVGInline className="button-indication" svg={check} />
            </div>
          )}
        </React.Fragment>
      </button>
    );
  }
}

export default Button;
