import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

class AbbreviatedCommentTemplateAPI extends BaseApi {
  static fetchAll() {
    return this.get(ApiUrls.abbreviatedCommentTemplateList);
  }
}

export default AbbreviatedCommentTemplateAPI;
