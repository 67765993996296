import { Select } from "@chakra-ui/react";
import React from "react";

export const BooleanCondition: React.FC<any> = ({ onChange, operator_key, value, error }) => {
  if (["is_value"].includes(operator_key)) {
    return (
      <Select
        isInvalid={!!error}
        data-test={"workflows.form.select.block_operator.condition_type.boolean"}
        onChange={onChange}
        placeholder="Select"
        value={value}
      >
        <option value="true" data-test={"workflows.form.select.block_operator.condition_type.boolean.option.true"}>
          True
        </option>
        <option value="false" data-test={"workflows.form.select.block_operator.condition_type.boolean.option.false"}>
          False
        </option>
      </Select>
    );
  }

  return <></>;
};
