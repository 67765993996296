import NotificationSettingsActions from "../actions/notification-settings-actions";
import NotificationSettingsApi from "../apis/notification-settings-api";

let NotificationSettingsSource = {
  fetchCurrent() {
    return {
      remote(state, { cid } = {}) {
        return NotificationSettingsApi.fetchCurrent();
      },

      shouldFetch(state, force) {
        return true;
      },

      success: NotificationSettingsActions.fetchCurrentDone,
      error: NotificationSettingsActions.fetchCurrentFail
    };
  }
};

export default NotificationSettingsSource;
