import I from "immutable";
import React from "react";

import Activity from "./records/activity";
import ActivityComponent from "./activity-component";

interface ActivityGroupProps {
  icon?: React.ComponentClass;
  title?: string;
  activities: I.List<Activity>;
  max: number;
}

class ActivityGroup extends React.Component<ActivityGroupProps, { expanded: boolean }> {
  constructor(props: ActivityGroupProps) {
    super(props);

    this.state = {
      expanded: false
    };

    this.onToggle = this.onToggle.bind(this);
  }

  render() {
    if (!this.props.activities.isEmpty()) {
      let latestActivities: I.Iterable<number, Activity>;
      let olderActivities: I.Iterable<number, Activity>;

      if (this.props.activities.count() > this.props.max) {
        latestActivities = this.props.activities.take(this.props.max);
        olderActivities = this.props.activities.skip(this.props.max).filter((activity: Activity) => !activity.isRead());
      } else {
        latestActivities = this.props.activities;
        olderActivities = I.List<Activity>();
      }

      return (
        <div className="activity-group">
          <div>
            {this.props.icon && (
              <div className="svg-container">
                <this.props.icon />
              </div>
            )}
            {this.renderTitle()}
          </div>
          {latestActivities.map((activity: Activity, i: number) => (
            <ActivityComponent activity={activity} key={i} />
          ))}
          {this.renderExpand(olderActivities.count())}
          {olderActivities.map((activity: Activity, i: number) => (
            <ActivityComponent activity={activity} key={i} hidden={!this.state.expanded} />
          ))}
        </div>
      );
    } else {
      return null;
    }
  }

  renderTitle() {
    if (this.props.title) {
      let unread = this.props.activities.filter((activity: Activity) => !activity.isRead()).size;
      return (
        <span className="activity-heading-text">
          {this.props.title} <b>{unread ? `(${unread} unread)` : ""}</b>
        </span>
      );
    }
  }

  renderExpand(count: number) {
    if (count) {
      return (
        <a className="activity visible" onClick={this.onToggle}>
          {this.state.expanded ? "-" : "+"} {count} more unread
        </a>
      );
    }
  }

  onToggle() {
    this.setState({ expanded: !this.state.expanded });
  }
}

export default ActivityGroup;
