import React, { useState } from "react";
import {
  EuiInMemoryTable,
  EuiInMemoryTableProps,
  EuiBasicTableColumn,
  EuiSearchBarProps,
  EuiText,
  CriteriaWithPagination,
  EuiTableSelectionType,
  EuiFlexItem,
  EuiFlexGroup,
  EuiSpacer,
} from "@elastic/eui";

import { TableStyles } from "@pm-frontend/shared/components/Tables/TableStyles";
import { colors } from "@pm-frontend/styles";
import { PmBulkActionButton, PmBulkActionButtonProps } from "../Buttons/PmBulkActionButton";

const DEFAULT_PAGE_SIZE = 10;

interface InMemorySearchableTableProps<T extends object> {
  columns: Array<EuiBasicTableColumn<T>>;
  items: T[];
  searchable?: boolean;
  search?: EuiSearchBarProps;
  showCountSummary?: boolean;
  itemLabel?: string;
  sorting?: EuiInMemoryTableProps<T>["sorting"];
  "data-testid"?: string;
  selectionProps?: {
    setSelectedItems: (arg0: T[]) => void;
    selectedItems: T[];
    selectable?: (item: T) => boolean;
    selectableMessage?: (selectable: boolean) => string;
    itemId: string;
    bulkActionsProps?: {
      bulkActions: PmBulkActionButtonProps["bulkActions"];
      bulkActionButtonText: string;
      bulkActionButtonColor: "success" | "danger" | "warning";
      bulkActionSuccessText: string;
      bulkActionDisplayState: PmBulkActionButtonProps["displayState"];
      bulkActionDataTestId?: string;
    };
  };
  tableLayout?: EuiInMemoryTableProps<T>["tableLayout"];
  loading?: boolean;
  emptyMessage: string;
  itemsPerPage?: number;
  compressed?: boolean;
}

const InMemorySearchableTable = ({
  columns,
  items = [],
  searchable,
  search,
  showCountSummary = false,
  itemLabel,
  sorting,
  "data-testid": dataTestId,
  selectionProps,
  tableLayout = "fixed",
  emptyMessage,
  compressed = false,
  itemsPerPage = DEFAULT_PAGE_SIZE,
}: InMemorySearchableTableProps<any>) => {
  const PAGE_SIZE = itemsPerPage || DEFAULT_PAGE_SIZE;
  const [pagination, setPagination] = useState({ pageIndex: 0 });

  const onTableChange = ({ page: { index } }: CriteriaWithPagination<any>) => {
    setPagination({ pageIndex: index });
  };

  let bulkActionButton: React.ReactNode;
  if (selectionProps?.bulkActionsProps) {
    bulkActionButton =
      (selectionProps?.selectedItems && selectionProps.selectedItems.length > 0) ||
      selectionProps?.bulkActionsProps.bulkActionDisplayState === "success" ||
      selectionProps?.bulkActionsProps.bulkActionDisplayState === "error" ? (
        <EuiFlexItem>
          <PmBulkActionButton
            numFilters={selectionProps.selectedItems.length}
            body={selectionProps.bulkActionsProps.bulkActionButtonText}
            color={selectionProps.bulkActionsProps.bulkActionButtonColor}
            bulkActions={selectionProps.bulkActionsProps.bulkActions}
            successText={selectionProps.bulkActionsProps.bulkActionSuccessText}
            displayState={selectionProps.bulkActionsProps.bulkActionDisplayState}
            data-testid={selectionProps.bulkActionsProps.bulkActionDataTestId}
          />
          {!showCountSummary && <EuiSpacer size="s" />}
        </EuiFlexItem>
      ) : null;
  }

  const resultsCount = `${PAGE_SIZE * pagination.pageIndex + 1}-${Math.min(
    PAGE_SIZE * pagination.pageIndex + PAGE_SIZE,
    items.length
  )} of ${items.length}`;

  const composedSearch: EuiSearchBarProps = {
    "data-test-subj": "table-search-input",
    ...(search ? search : {}),
    box: {
      placeholder: `Search For ${itemLabel}`,
      incremental: true,
      schema: true,
      ...(search?.box ? search.box : {}),
    },
  };

  const selection: EuiTableSelectionType<typeof items[0]> = {
    onSelectionChange: (selectedItems) => selectionProps?.setSelectedItems(selectedItems),
    selectable: selectionProps?.selectable || (() => true),
    selectableMessage: selectionProps?.selectableMessage,
    selected: selectionProps?.selectedItems,
  };

  return (
    <>
      <TableStyles />
      <EuiFlexGroup direction="column" alignItems="flexStart" gutterSize="s">
        {!(search || searchable) && bulkActionButton ? <EuiFlexItem>{bulkActionButton}</EuiFlexItem> : null}
        {!(search || searchable) && showCountSummary && items.length > 0 && (
          <EuiFlexItem grow={false}>
            <EuiText
              size="xs"
              data-testid="table-item-count-summary"
              color={colors.neutrals.gray800}
              style={{ lineHeight: "18px", marginBottom: "8px" }}
            >
              Showing {resultsCount} <span style={{ fontWeight: 700 }}>{itemLabel}</span>
            </EuiText>
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={true}>
          <EuiInMemoryTable
            message={emptyMessage}
            className="euiSearchableTable"
            items={items}
            columns={columns}
            itemId={selectionProps?.itemId || undefined}
            isSelectable={!!selectionProps}
            selection={selectionProps ? selection : undefined}
            search={searchable && composedSearch}
            pagination={{
              ...pagination,
              pageSize: PAGE_SIZE,
              showPerPageOptions: false,
            }}
            sorting={sorting || true}
            tableLayout={tableLayout}
            onTableChange={onTableChange}
            compressed={compressed}
            childrenBetween={
              <EuiFlexGroup direction="column" alignItems="flexStart" gutterSize="s">
                {search || searchable ? bulkActionButton : null}
                {showCountSummary && items.length > 0 && (
                  <EuiFlexItem grow={false}>
                    <EuiText
                      size="xs"
                      data-testid="table-item-count-summary"
                      color={colors.neutrals.gray800}
                      style={{ lineHeight: "18px", marginBottom: "8px" }}
                    >
                      Showing {resultsCount} <span style={{ fontWeight: 700 }}>{itemLabel}</span>
                    </EuiText>
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            }
            {...(dataTestId ? { "data-testid": dataTestId } : {})}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export { InMemorySearchableTable };
