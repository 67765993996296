import _ from "lodash";
import AltContainer from "alt-container";
import ContactSettingsActions from "./actions/contact-settings-actions";
import ContactSettingsStore from "./stores/contact-settings-store";
import DocumentTitle from "react-document-title";
import ErrorRenderingUtils from "../utils/error-rendering-utils";
import I from "immutable";
import ModelUpdateUtils from "../utils/model-update-utils";
import NotificationSettingsStore from "./stores/notification-settings-store";
import createReactClass from "create-react-class";
import React from "react";
import { LargeLoader } from "../loaders";
import ResetChangesButtons from "./components/reset-changes-buttons";

let AccountContactSettingsTab = createReactClass({
  mixins: [ErrorRenderingUtils, ModelUpdateUtils],

  loadingStore: ContactSettingsStore,

  getInitialState() {
    return {
      cid: _.uniqueId("AccountContactSettingsTab_"),
      data: this.props.contactSettings || I.fromJS({}),
      dirty: false
    };
  },

  render() {
    return (
      <div>
        <form onSubmit={this.onSubmit} className="update-account" name="update-account-form">
          <div className="row">
            {this.renderErrors()}

            <div className="columns medium-12 large-4">
              <div className="label-container">
                <label>Home Phone</label>
              </div>
              <input
                type="text"
                placeholder="Enter home phone"
                value={this.state.data.get("home_phone")}
                onChange={this.markDirty(this.onChangeSet("home_phone"))}
              />
            </div>

            <div className="columns medium-12 large-4">
              <div className="label-container">
                <label>Cell Phone</label>
              </div>
              <input
                type="text"
                placeholder="Enter cell phone"
                value={this.state.data.get("cell_phone")}
                onChange={this.markDirty(this.onChangeSet("cell_phone"))}
              />
            </div>
            <div className="columns medium-12 large-4">
              <div className="label-container">
                <label>Business Phone</label>
              </div>
              <input
                type="text"
                placeholder="Enter business phone"
                value={this.state.data.get("business_phone")}
                onChange={this.markDirty(this.onChangeSet("business_phone"))}
              />
            </div>
          </div>

          {this.renderSMSConsent()}

          <div className="footer">
            <div className="footer-content">{this.renderActionButtons()}</div>
          </div>
        </form>
      </div>
    );
  },

  renderSMSConsent() {
    if (this.props.notificationSettings.get("sms_notifications")) {
      return (
        <div className="row">
          <div className="columns">
            By entering your cell phone number here, you agree to receive automated text notifications pertaining to
            your account. If you do not agree, you can turn off text notifications under the Notifications tab above.
          </div>
        </div>
      );
    }
  },

  renderActionButtons() {
    if (this.state.dirty) {
      return (
        <ResetChangesButtons
          resetClickFn={this.onResetChanges(this.props.contactSettings)}
          saveClickFn={this.prepareSubmit(this.updateSettings)}
        />
      );
    }
  },

  updateSettings(e) {
    e.preventDefault();

    if (this.isValid()) {
      let data = this.state.data.toJS();
      ContactSettingsActions.update(data, { cid: this.state.cid });
      this.setState({ dirty: false });
    }
  }
});

export default createReactClass({
  render() {
    return (
      <DocumentTitle title="Contact Settings">
        <AltContainer
          stores={{
            contactSettings() {
              return {
                value: ContactSettingsStore.getCurrent(),
                store: ContactSettingsStore
              };
            },
            notificationSettings() {
              return {
                value: NotificationSettingsStore.getCurrent(),
                store: NotificationSettingsStore
              };
            }
          }}
          render={function(props) {
            if (ContactSettingsStore.didFail() || NotificationSettingsStore.didFail()) {
              return <div>Failed to load contact settings</div>;
            } else if (ContactSettingsStore.isLoading() || NotificationSettingsStore.isLoading()) {
              return <LargeLoader />;
            }

            return <AccountContactSettingsTab {...props} />;
          }}
        />
      </DocumentTitle>
    );
  },

  componentWillMount() {
    ContactSettingsStore.fetchCurrent();
    NotificationSettingsStore.fetchCurrent();
  }
});
