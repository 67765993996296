import React from "react";
import ButtonContainer from "../../../../app/components/containers/ButtonContainer";
import Button, { ButtonTypes } from "../../../../app/components/buttons/Button";

interface ResetChangesButtonsProps {
  resetClickFn: (event: React.MouseEvent<HTMLButtonElement>) => void;
  saveClickFn: (event: React.MouseEvent<HTMLButtonElement>) => void;
  resetDisabled?: boolean;
  saveDisabled?: boolean;
}

export default function ResetChangesButtons(props: ResetChangesButtonsProps) {
  return (
    <ButtonContainer>
      <Button
        buttonType={ButtonTypes.Cancel}
        className="settings-reset-btn"
        onClick={props.resetClickFn}
        disabled={props.resetDisabled}
      >
        Reset Changes
      </Button>
      <Button
        buttonType={ButtonTypes.Primary}
        className="settings-save-btn"
        onClick={props.saveClickFn}
        disabled={props.saveDisabled}
      >
        Save
      </Button>
    </ButtonContainer>
  );
}
