import React from "react";

import ButtonGroup from "@pm-app/components/forms/button-group/button-group";
import Button, { ButtonTypes } from "@pm-app/components/forms/button/button";
import { FormGroup } from "@pm-app/components/forms/form-group/form-group";
import { Textarea } from "@pm-app/components/forms/textarea/textarea";
import { Modal, ModalBody, ModalFooter } from "@pm-app/components/modal/modal";

interface CancelMeldFormProps {
  loading: boolean;
  onCancelMeld: () => void;
  onClose: () => void;
  onNotesChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  serverErrors: string[];
  title: string;
  body: React.ReactNode;
  label: React.ReactNode;
  primaryText: string;
  secondaryText: string;
}

export default class CancelMeldForm extends React.Component<CancelMeldFormProps> {
  constructor(props: CancelMeldFormProps) {
    super(props);

    this.onCancelMeld = this.onCancelMeld.bind(this);
  }

  render() {
    let {
      title,
      onClose,
      serverErrors,
      loading,
      onNotesChange,
      onCancelMeld,
      body,
      label,
      primaryText,
      secondaryText
    } = this.props;
    return (
      <Modal header={title} isOpen={true} onClose={onClose}>
        <ModalBody>
          <FormGroup>
            <div className={"cancel-meld-form-body"}>{body}</div>
          </FormGroup>
          <FormGroup>
            {label}
            <Textarea
              data-test="notes"
              errors={Array.isArray(serverErrors) && serverErrors.join("").length ? serverErrors : null}
              onChange={onNotesChange}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button buttonType={ButtonTypes.Primary} onClick={onCancelMeld} disabled={loading}>
              {primaryText}
            </Button>
            <Button buttonType={ButtonTypes.Neutral} onClick={onClose} disabled={loading}>
              {secondaryText}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </Modal>
    );
  }

  onCancelMeld(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    this.props.onCancelMeld();
  }
}
