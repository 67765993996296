import appointmentRequestAPI from "../apis/appointment-request-api";
import React from "react";
import {
  containerStateForChildren,
  defaultContainerState,
  loadingFailed,
  loadingSucceeded
} from "../../common/utils/container-utils";

export default function withAppointmentRequestFromAssignment(Comp, getAssignmentId) {
  return class withSaveAppointmentRequestFromAssignmentContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = defaultContainerState();
    }

    render() {
      return <Comp {...containerStateForChildren(this)} {...this.props} data={this.state.data} />;
    }

    componentWillMount() {
      appointmentRequestAPI
        .fetchForAssignment(getAssignmentId(this.props))
        .then(loadingSucceeded.bind(this))
        .catch(res => {
          if (!res.response || res.response.status !== 404) {
            loadingFailed.call(this, res);
          } else {
            this.setState({ loading: false });
          }
        });
    }
  };
}
