import File from "../../common/components/file";
import FilePreview from "../../common/components/file-preview";
import FileUtils from "../../common/utils/file-utils";
import FileViewerDeleteFile from "../../common/components/file-viewer-delete-file";
import FileViewerDownloadFile from "../../common/components/file-viewer-download-file";
import FileViewerNewWindow from "../../common/components/file-viewer-new-window";
import FileViewerShareFile from "../../common/components/file-viewer-share-file";
import React from "react";
import withPreviewableFiles, { Injected } from "../../common/components/with-previewable-files";
import { ImmutableList, ImmutableMap } from "../../types/common";
import { SmallLoader } from "../../loaders";
import SelectAllSelector from "../../common/components/select-all-selector";

export interface MeldFilesProps {
  files: ImmutableList;
  selectedFilesCount?: number;
  onSelectedAll?: (files: ImmutableList) => void;
  onSelectedNone?: () => void;
  allowDelete: boolean;
  deleteFile?: (file: ImmutableMap, cid: string) => void;
  emptyText?: string;
  isDownloadEnabled?: boolean;
  isDropdownEnabled?: boolean;
  isSelected?: (file: ImmutableMap) => boolean;
  onSelectionToggled?: (file: ImmutableMap) => void;
  selectable?: boolean;
  setExpiration?: () => void;
  shareFile?: (file: ImmutableMap) => void;
  isLoading: boolean;
}

type Props = MeldFilesProps & Injected;

class MeldFiles extends React.Component<Props> {
  static defaultProps = {
    isDownloadEnabled: true,
  };

  constructor(props: Props) {
    super(props);

    this.handleViewerDeleteFile = this.handleViewerDeleteFile.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.renderFilePreviewActions = this.renderFilePreviewActions.bind(this);
    this.handleCheckSelectedAll = this.handleCheckSelectedAll.bind(this);
    this.handleSelectedAll = this.handleSelectedAll.bind(this);
  }

  render() {
    if (this.props.files.size === 0 && this.props.isLoading) {
      return <SmallLoader />;
    }

    if (this.props.files.size === 0) {
      return <div className="copy">{this.props.emptyText}</div>;
    }

    let files = this.props.files.map((file: ImmutableMap) => {
      let props = {
        ...this.props,
        deleteFile: this.props.allowDelete ? this.handleDeleteFile : undefined,
        onThumbnailClicked: this.props.navFile,
        file,
      };
      return <File key={file.get("id")} {...props} />;
    });

    return (
      <React.Fragment>
        {this.renderSelector()}

        <ul className="files">{files}</ul>
        {this.props.previewingFile && (
          <FilePreview
            files={this.props.files}
            file={this.props.previewingFile}
            onClose={this.props.closePreview}
            navFile={this.props.navFile}
            renderActions={this.renderFilePreviewActions}
          />
        )}
      </React.Fragment>
    );
  }

  renderSelector() {
    if (this.props.selectedFilesCount !== undefined) {
      const totalItemCount = this.props.files.size;
      const selectedAll = totalItemCount === this.props.selectedFilesCount;

      return (
        <SelectAllSelector
          selectedAll={selectedAll}
          numberOfSelectedItems={this.props.selectedFilesCount}
          onSelectedAll={this.handleCheckSelectedAll}
          onSelectedAllPages={this.handleSelectedAll}
          itemName={"file"}
          nonSingularItemName={"files"}
          totalItemCount={totalItemCount}
        />
      );
    }
  }

  renderFilePreviewActions(file: ImmutableMap) {
    return (
      <React.Fragment>
        {this.props.shareFile && <FileViewerShareFile file={file} onShare={this.props.shareFile} />}
        {this.props.allowDelete && <FileViewerDeleteFile file={file} onDelete={this.handleViewerDeleteFile} />}
        {FileUtils.isOpenable(file) && <FileViewerNewWindow file={file} />}
        {this.props.isDownloadEnabled && <FileViewerDownloadFile file={file} />}
      </React.Fragment>
    );
  }

  handleDeleteFile(file: ImmutableMap, cid: string) {
    if (this.props.deleteFile) {
      this.props.deleteFile(file, cid);
      this.props.closePreview();
    }
  }

  handleViewerDeleteFile(file: ImmutableMap) {
    this.handleDeleteFile(file, "");
  }

  handleSelectedAll() {
    if (this.props.onSelectedAll) {
      this.props.onSelectedAll(this.props.files);
    }
  }

  handleCheckSelectedAll() {
    if (this.props.selectedFilesCount === 0) {
      if (this.props.onSelectedAll) {
        this.props.onSelectedAll(this.props.files);
      }
    } else if (this.props.onSelectedNone) {
      this.props.onSelectedNone();
    }
  }
}

export default withPreviewableFiles<MeldFilesProps>(MeldFiles);
