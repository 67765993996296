var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import AddressUtils from "@pm-assets/js/utils/address-utils";
import * as C from "@pm-assets/js/constants";
import ContactUtils from "@pm-assets/js/utils/contact-utils";
import { asImmer } from "@pm-app/utils/decorators";
const _TenantUtils = class {
  static getLastInvite(tenant) {
    return tenant.get("last_invite");
  }
  static getID(tenant) {
    tenant = tenant;
    return tenant.id;
  }
  static getFullName(tenant, emptyMsg = "") {
    tenant = tenant;
    return tenant ? _TenantUtils.formatFullName(tenant.first_name, tenant.last_name, tenant.middle_name) : emptyMsg;
  }
  static getFullNameBase(tenant, emptyMsg = "") {
    return tenant ? _TenantUtils.formatFullName(tenant.first_name || "", tenant.last_name || "", tenant.middle_name || "") : emptyMsg;
  }
  static formatFullName(firstName, lastName, middleName) {
    return [firstName, middleName, lastName].filter((name) => name).join(" ");
  }
  static getFormattedTenantString(tenants) {
    let tents = tenants.toJS ? tenants.toJS() : tenants;
    if (tents.length) {
      let firstTenant = tents[0];
      let plusMore = "";
      if (tents.length > 1) {
        plusMore = ` +${tents.length - 1}`;
      }
      return `${_TenantUtils.formatFullName(
        firstTenant.first_name,
        firstTenant.last_name,
        firstTenant.middle_name
      )}${plusMore}`;
    } else {
      return "No tenants";
    }
  }
  static getPhone(tenant) {
    return ContactUtils.getPrimaryPhone(tenant.get("contact"));
  }
  static getPrimaryEmail(tenant, defaultMsg = "No Email") {
    return _TenantUtils._getEmail(tenant.getIn(["contact", "primary_email"]), defaultMsg);
  }
  static getSecondaryEmail(tenant, defaultMsg = "No Email") {
    return _TenantUtils._getEmail(tenant.getIn(["contact", "secondary_email"]), defaultMsg);
  }
  static getAdditionalEmail(tenant, defaultMsg = "No Email") {
    return _TenantUtils._getEmail(tenant.getIn(["contact", "tertiary_email"]), defaultMsg);
  }
  static _getEmail(email, defaultMsg) {
    if (!email) {
      return defaultMsg;
    }
    return email;
  }
  static getCellPhone(tenant) {
    let phone = tenant.getIn(["contact", "cell_phone"]);
    if (!phone) {
      phone = "No number";
    }
    return phone;
  }
  static getHomePhone(tenant) {
    let phone = tenant.getIn(["contact", "home_phone"]);
    if (!phone) {
      phone = "No number";
    }
    return phone;
  }
  static getBusinessPhone(tenant) {
    let phone = tenant.getIn(["contact", "business_phone"]);
    if (!phone) {
      phone = "No number";
    }
    return phone;
  }
  static getAddressLine1AndCity(tenant) {
    let address = tenant.get("address");
    if (!address) {
      return "No address";
    }
    return AddressUtils.getLine1AndCity(address);
  }
  static getNotes(tenant) {
    return tenant.get("notes") || "No notes";
  }
  static getFirstFoundPhone(tenant) {
    return ContactUtils.getFirstFoundPhone(tenant.get("contact"));
  }
  static isTenantRegistered(tenant) {
    return tenant.get("user");
  }
  static isTenantInvited(tenant) {
    return tenant.get("invited");
  }
  static isActive(tenant) {
    return tenant.get("is_active");
  }
  static getRegisteredEmail(tenant, defaultMsg = "Not registered") {
    return tenant.getIn(["user", "email"]) || defaultMsg;
  }
  static tenantNameComparator(tenant1, tenant2) {
    let lastFirstAndMiddle = (tenant) => {
      return _TenantUtils.formatFullName(
        tenant.get("last_name").trim(),
        tenant.get("first_name").trim(),
        tenant.get("middle_name").trim()
      ).toUpperCase();
    };
    return lastFirstAndMiddle(tenant1).localeCompare(lastFirstAndMiddle(tenant2));
  }
};
let TenantUtils = _TenantUtils;
TenantUtils.defaultQuery = {
  ordering: C.DEFAULT_TENANT_SORT
};
__decorateClass([
  asImmer()
], TenantUtils, "getID", 1);
__decorateClass([
  asImmer()
], TenantUtils, "getFullName", 1);
export default TenantUtils;
