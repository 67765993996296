import AddressUtils from "@pm-assets/js/utils/address-utils";
import * as C from "@pm-assets/js/constants";
import ContactUtils from "@pm-assets/js/utils/contact-utils";
import I from "immutable";
import { ImmutableMap, None } from "@pm-assets/js/types/common";
import { TenantNode } from "@pm-assets/js/common/graphql/types";
import { asImmer } from "@pm-app/utils/decorators";

type Tenant = TenantNode | ImmutableMap;

class TenantUtils {
  static defaultQuery = {
    ordering: C.DEFAULT_TENANT_SORT
  };

  static getLastInvite(tenant: I.Map<string, any>) {
    return tenant.get("last_invite");
  }

  @asImmer()
  static getID(tenant: Tenant) {
    tenant = tenant as TenantNode;

    return tenant.id;
  }

  @asImmer()
  static getFullName(tenant: Tenant, emptyMsg = "") {
    tenant = tenant as TenantNode;

    return tenant ? TenantUtils.formatFullName(tenant.first_name, tenant.last_name, tenant.middle_name) : emptyMsg;
  }

  static getFullNameBase(tenant: { first_name?: string; last_name?: string; middle_name?: string }, emptyMsg = "") {
    return tenant
      ? TenantUtils.formatFullName(tenant!.first_name! || "", tenant!.last_name! || "", tenant!.middle_name! || "")
      : emptyMsg;
  }

  static formatFullName(firstName: string, lastName: string, middleName: string) {
    // The filter ensures each name is truthy, so we don't get extra spaces if no middle name
    return [firstName, middleName, lastName].filter(name => name).join(" ");
  }

  static getFormattedTenantString(tenants: any) {
    let tents = tenants.toJS ? tenants.toJS() : tenants;
    if (tents.length) {
      let firstTenant = tents[0];
      let plusMore = "";
      if (tents.length > 1) {
        plusMore = ` +${tents.length - 1}`;
      }
      return `${TenantUtils.formatFullName(
        firstTenant.first_name,
        firstTenant.last_name,
        firstTenant.middle_name
      )}${plusMore}`;
    } else {
      return "No tenants";
    }
  }

  static getPhone(tenant: I.Map<string, any>) {
    return ContactUtils.getPrimaryPhone(tenant.get("contact"));
  }

  static getPrimaryEmail(tenant: I.Map<string, any>, defaultMsg = "No Email") {
    return TenantUtils._getEmail(tenant.getIn(["contact", "primary_email"]), defaultMsg);
  }

  static getSecondaryEmail(tenant: I.Map<string, any>, defaultMsg = "No Email") {
    return TenantUtils._getEmail(tenant.getIn(["contact", "secondary_email"]), defaultMsg);
  }

  static getAdditionalEmail(tenant: I.Map<string, any>, defaultMsg = "No Email") {
    return TenantUtils._getEmail(tenant.getIn(["contact", "tertiary_email"]), defaultMsg);
  }

  static _getEmail(email: string | None, defaultMsg: string) {
    if (!email) {
      return defaultMsg;
    }

    return email;
  }

  static getCellPhone(tenant: I.Map<string, any>) {
    let phone = tenant.getIn(["contact", "cell_phone"]);
    if (!phone) {
      phone = "No number";
    }

    return phone;
  }

  static getHomePhone(tenant: I.Map<string, any>) {
    let phone = tenant.getIn(["contact", "home_phone"]);
    if (!phone) {
      phone = "No number";
    }

    return phone;
  }

  static getBusinessPhone(tenant: I.Map<string, any>) {
    let phone = tenant.getIn(["contact", "business_phone"]);
    if (!phone) {
      phone = "No number";
    }

    return phone;
  }

  static getAddressLine1AndCity(tenant: I.Map<string, any>) {
    let address = tenant.get("address");
    if (!address) {
      return "No address";
    }
    return AddressUtils.getLine1AndCity(address);
  }

  static getNotes(tenant: I.Map<string, any>) {
    return tenant.get("notes") || "No notes";
  }

  static getFirstFoundPhone(tenant: I.Map<string, any>) {
    return ContactUtils.getFirstFoundPhone(tenant.get("contact"));
  }

  static isTenantRegistered(tenant: I.Map<string, any>) {
    return tenant.get("user");
  }

  static isTenantInvited(tenant: I.Map<string, any>) {
    return tenant.get("invited");
  }

  static isActive(tenant: I.Map<string, any>) {
    return tenant.get("is_active");
  }

  static getRegisteredEmail(tenant: I.Map<string, any>, defaultMsg = "Not registered") {
    return tenant.getIn(["user", "email"]) || defaultMsg;
  }

  static tenantNameComparator(tenant1: I.Map<string, any>, tenant2: I.Map<string, any>) {
    let lastFirstAndMiddle = (tenant: I.Map<string, any>) => {
      return TenantUtils.formatFullName(
        tenant.get("last_name").trim(),
        tenant.get("first_name").trim(),
        tenant.get("middle_name").trim()
      ).toUpperCase();
    };
    return lastFirstAndMiddle(tenant1).localeCompare(lastFirstAndMiddle(tenant2));
  }
}

export default TenantUtils;
