import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";
import * as C from "../../constants";

class MeldOwnerApprovalAPI extends BaseApi {
  static getApprovalMessage(meldId: number) {
    return this.get(ApiUrls.ownerApprovalMessage(meldId));
  }

  static createRequest(meldId: number, data: { message: string; owner_approval_dollar_amount: number }) {
    return this.patch(ApiUrls.ownerApprovalRequest(meldId), data);
  }

  static finishRequest(meldId: number, approval_status: C.OwnerApprovalStatuses, ownerId?: number, message?: string) {
    return this.patch(ApiUrls.ownerApprovalFinish(meldId), {
      approval_status: approval_status.toString(),
      owner: ownerId,
      message
    });
  }

  static markAcknowledged(meldId: number, acknowledged: boolean) {
    return this.patch(ApiUrls.acknowledgeOwnerApproval(meldId), {
      is_acknowledged: acknowledged
    });
  }
}

export default MeldOwnerApprovalAPI;
