import React from "react";
import DocumentTitle from "react-document-title";
import { IconButton, Text, useMediaQuery, Button, Flex, Box, Heading, Link as ChakraLink } from "@chakra-ui/react";
import { AiFillPlusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

import { Container, Content, Width } from "@pm-components/container";
import { hasPerm } from "../../../../assets/js/common/utils/permission-utils";
import { LinkHelper } from "@pm-shared/utils/link";
import { Perms } from "../../../../assets/js/constants";
import { track, PCPCreateServiceForm } from "@pm-app/utils/analytics";
import useQuery from "../../../apps/property-management-hub/pages/owner-services/hooks/useQuery";

import { ArrowBackIcon } from "@chakra-ui/icons";

interface OwnerServicesListViewProps {
  summaryNode: React.ReactNode;
  servicesNode: React.ReactNode;
}

export const OwnerServicesListView: React.FC<OwnerServicesListViewProps> = ({ summaryNode, servicesNode }) => {
  const [isLargerThan600] = useMediaQuery("(min-width: 600px)");
  let query = useQuery();

  let addServiceButton;
  if (LinkHelper.getOrgType() === "m" && hasPerm(Perms.CAN_ADD_OWNER_SERVICES)) {
    if (isLargerThan600) {
      addServiceButton = (
        <ChakraLink as={Link} to="new/" size="sm" height={7} onClick={() => track(PCPCreateServiceForm())}>
          <Button variant="primary" id="add-services.button">
            Add Service
          </Button>
        </ChakraLink>
      );
    } else {
      addServiceButton = (
        <ChakraLink as={Link} to="new/" onClick={() => track(PCPCreateServiceForm())}>
          <IconButton
            aria-label="add service"
            color="blue.600"
            icon={<AiFillPlusCircle />}
            size="sm"
            variant="ghost"
            fontSize={48}
          />
        </ChakraLink>
      );
    }
  }
  return (
    <DocumentTitle title="PropertyCare+">
      <Container marginTop={4} data-test="service-list-container">
        <Content width={Width.MEDIUM}>
          <Box marginBottom={120} maxW={1200}>
            {query.get("templates") && (
              <>
                <ChakraLink
                  data-test="new-service-return-to-index-button"
                  as={Link}
                  to={LinkHelper.normalize("owner-services/")}
                >
                  <Text fontSize="md" fontWeight="semibold" color="blue.600">
                    <ArrowBackIcon /> Back
                  </Text>
                </ChakraLink>
                <Box marginBottom={50} />
              </>
            )}
            <Flex justify="space-between">
              <Heading id={"owner-services.main.title"} fontSize={[25, 35]} marginBottom={10}>
                PropertyCare+ {query.get("templates") ? " Templates" : ""}
              </Heading>
              <Box />
              {query.get("templates") ? "" : addServiceButton}
            </Flex>
            {summaryNode}
          </Box>

          {servicesNode}
        </Content>
      </Container>
    </DocumentTitle>
  );
};
