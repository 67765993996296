var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import * as React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
export var IconAlignments = /* @__PURE__ */ ((IconAlignments2) => {
  IconAlignments2["LEFT"] = "left";
  IconAlignments2["RIGHT"] = "right";
  return IconAlignments2;
})(IconAlignments || {});
export const IconLink = (props) => {
  const _a = props, { icon, href, path, onLinkClick, inactive, iconAligned, children, className } = _a, elementProps = __objRest(_a, ["icon", "href", "path", "onLinkClick", "inactive", "iconAligned", "children", "className"]);
  const classes = classnames(
    "pm-icon-link",
    {
      "pm-icon-link-inactive": inactive
    },
    className ? className.split(" ") : ""
  );
  let linkContent;
  if (iconAligned === "right" /* RIGHT */) {
    linkContent = /* @__PURE__ */ React.createElement(React.Fragment, null, children, /* @__PURE__ */ React.createElement("span", { className: "pm-icon-link-icon pm-icon-link-icon-right" }, icon()));
  } else {
    linkContent = /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("span", { className: "pm-icon-link-icon" }, icon()), children);
  }
  return /* @__PURE__ */ React.createElement("div", __spreadValues({ className: classes }, elementProps), (() => {
    if (inactive) {
      return linkContent;
    }
    if (href) {
      return /* @__PURE__ */ React.createElement("a", { target: "_blank", href }, linkContent);
    }
    if (path) {
      return /* @__PURE__ */ React.createElement(Link, { to: path }, linkContent);
    }
    return /* @__PURE__ */ React.createElement("a", { href: "#", onClick: onLinkClick }, linkContent);
  })());
};
IconLink.displayName = "IconLink";
export default IconLink;
