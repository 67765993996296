import React from "react";
import { EuiTextProps } from "@elastic/eui/src/components/text/text";
import { EuiText } from "@elastic/eui";
import { colors, fontSizes, fontWeights } from "@pm-frontend/styles";
import Linkify from "react-linkify";

type PmTextProps = {
  fontWeight?: "regular" | "semiBold" | "bold";
  fontSize?: "p1" | "p2" | "p3";
} & EuiTextProps;

const fontWeightMapping: Record<Required<PmTextProps>["fontWeight"], number> = {
  regular: fontWeights.regular,
  semiBold: fontWeights.semiBold,
  bold: fontWeights.bold,
} as const;

const fontSizeMapping: Record<Required<PmTextProps>["fontSize"], string> = {
  p1: fontSizes.p1,
  p2: fontSizes.p2,
  p3: fontSizes.p3,
} as const;

/**
 * Similar to EuiText with our styling set
 * https://eui.elastic.co/#/display/text
 */
const PmText = ({
  fontWeight = "regular",
  fontSize = "p1",
  children,
  color = colors.neutrals.gray800,
  style,
  ...rest
}: PmTextProps) => {
  const customTextStyles: React.CSSProperties = {
    ...style,
    fontWeight: fontWeightMapping[fontWeight],
    fontSize: fontSizeMapping[fontSize],
  };

  return (
    <Linkify className="inline-link">
      <EuiText style={customTextStyles} {...rest} color={color}>
        {children}
      </EuiText>
    </Linkify>
  );
};

export { PmText };
