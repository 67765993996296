import useSWR, { ConfigInterface } from "swr";

import { APIPrefix } from "../api";
import { axios } from "@pm-shared/axios";

// Fetch Agents

export interface Agent {
  id: number;
  user: any;
  role: any;
  property_groups: [];
  user_groups: [];
  first_name: string;
  last_name: string;
  created: any;
  propertyware_vendor_user: boolean;
  rent_manager_user: boolean;
}
export const fetchAgents = (options?: ConfigInterface) => {
  return useSWR<Agent[]>(
    `${APIPrefix}/agents/`,
    () => axios.get(`${APIPrefix}/abbrev-agents/`).then((response) => response.data),
    options
  );
};
