import React from "react";
import classNames from "classnames";
import I from "immutable";

export interface ListViewProps {
  items: any;
  listItemClass: new (props: ListItemProps) => React.Component<ListItemProps>;
  linkPath?: string;
  linkPathSuffix?: string;
  className?: string;
  selectedItems?: number[];
  invertStatus?: boolean;
  onItemSelected?: (id: number) => void;
  listItemProps?: any;
  selectionValidator?: (item: I.Map<string, any>) => any[];
  customSelectionValidator?: (item: I.Map<string, any>) => any[];
}

export interface ListItemProps {
  className: string;
  listItem: I.Map<string, any>;
  isActive?: boolean;
  key: number;
  link?: string;
  onItemSelected?: (id: number) => void;
  onItemDelete?: (item: I.Map<string, any>) => void;
  onItemReactivate?: (item: I.Map<string, any>) => void;
  isSelected?: boolean;
  errors: any[];
  customErrors?: any[];
}

export default class ListView extends React.Component<ListViewProps, any> {
  render() {
    let className = "list-v";

    if (this.props.className) {
      className += " " + this.props.className;
    }

    const listItems = this.props.items.map((item: I.Map<string, any>) => {
      const itemId = item.get("id");

      const classes = {
        "header-col": true,
        selector: true,
        selected: false,
      };

      let selected = false;
      let validationErrors = [];
      let customValidationErrors = [];
      // Passed in via <SelectableItemsView {...props} />
      // attached as part of a React.clone
      if (this.props.selectionValidator) {
        validationErrors = this.props.selectionValidator(item);
      }

      // Passed in via <SelectableItemsView {...props} />
      // attached as part of a React.clone
      if (this.props.customSelectionValidator) {
        customValidationErrors = this.props.customSelectionValidator(item);
      }

      if (this.props.selectedItems) {
        const isIncluded = this.props.selectedItems.includes(itemId);
        selected = this.props.invertStatus ? !isIncluded : isIncluded;
      }

      classes.selected = selected && validationErrors.length + customValidationErrors.length === 0;

      const selectedClasses = classNames(classes);

      let props: ListItemProps = {
        className: selectedClasses,
        listItem: item,
        isSelected: selected && validationErrors.length + customValidationErrors.length === 0,
        key: itemId,
        errors: validationErrors,
        customErrors: customValidationErrors,
      };
      if (item.get("is_active") !== undefined) {
        props.isActive = item.get("is_active");
      }
      if (this.props.onItemSelected) {
        props.onItemSelected =
          validationErrors.length + customValidationErrors.length === 0
            ? this.props.onItemSelected
            : () => {
                return;
              };
      }

      if (this.props.linkPath) {
        const suffix = this.props.linkPathSuffix ? this.props.linkPathSuffix : "";
        props.link = this.props.linkPath + `/${itemId}/` + suffix;
      }

      if (this.props.listItemProps) {
        props = Object.assign(this.props.listItemProps, props);
      }

      return React.createElement(this.props.listItemClass, props);
    });

    return (
      <div className="row">
        <div className="columns small-12">
          {className !== "list-v unit-list-view selectable" && <div className={className}>{listItems}</div>}
          {className === "list-v unit-list-view selectable" && (
            <div
              className={className}
              style={{ gridTemplateColumns: "min-content min-content auto auto  auto auto auto min-content" }}
            >
              {listItems}
            </div>
          )}
        </div>
      </div>
    );
  }
}
