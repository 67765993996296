import { useQuery } from "@tanstack/react-query";

import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { apiFetch } from "@pm-frontend/shared/utils/apiFetch";
import { LinkHelper } from "@pm-shared/utils/link";
import { ManagementListViewSerializer } from "@pm-frontend/shared/types/api/manager/serializers/management_list_view_serializers";

export const useGetManagements = () => {
  return useQuery<ManagementListViewSerializer[]>({
    queryKey: ["managements"],
    queryFn: () => apiFetch(LinkHelper.normalize(ApiUrls.managementList)),
    cacheTime: Infinity,
  });
};
