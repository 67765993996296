import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";
import { numOrStr } from "../../types/common";

class AgentApi extends BaseApi {
  static fetchAll() {
    return this.get(ApiUrls.agentList);
  }

  static fetchAbbreviated() {
    return this.get(ApiUrls.agentAbbrevList);
  }

  static getAgent(agentId: number | string) {
    return this.get(ApiUrls.agentDetail(agentId));
  }

  static update(agentId: number | string, data: object) {
    return this.patch(ApiUrls.agentDetail(agentId), data);
  }

  static updatePropertyGroups(agentId: numOrStr, propertyGroupIds: numOrStr[]) {
    return this.patch(ApiUrls.agentPropertyGroupsList(agentId), {
      property_groups: propertyGroupIds
    });
  }

  static updateUserGroups(agentId: numOrStr, userGroupIds: numOrStr[]) {
    return this.patch(ApiUrls.agentUserGroupsList(agentId), {
      user_groups: userGroupIds
    });
  }

  static updateMe(agentData: any) {
    return this.patch(ApiUrls.agentMeDetail, agentData);
  }

  static fetchMaintenanceAgents() {
    return this.fetchAbbreviated();
  }

  static search(query: any, { agentIdExclusions }: { agentIdExclusions?: numOrStr[] } = {}) {
    return this.get(ApiUrls.agentSearchList, {
      params: { query, exclude_ids: agentIdExclusions }
    });
  }
}

export default AgentApi;
