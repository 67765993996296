import React, { useEffect } from "react";
import { EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from "@elastic/eui";

import URL from "@pm-shared/utils/url";
import { colors } from "@pm-frontend/styles";
import { useSwipe } from "@pm-frontend/shared/hooks/useSwipe";

interface SiteSearchMobileBaseProps {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  body: React.ReactNode;
  filter: React.ReactNode;
  checkbox?: React.ReactNode;
  iconString?: string;
  title: string | null;
  onClose: () => void;
  isFetching: boolean;
}

/**
 * This component handles rendering the mobile search component
 * It covers up existing page content (without unmounting)
 */
const SiteSearchMobileBase = ({
  searchText,
  setSearchText,
  body,
  filter,
  checkbox,
  iconString,
  title,
  onClose,
  isFetching,
}: SiteSearchMobileBaseProps) => {
  useSwipe({ handleSwipeLeft: () => {}, handleSwipeRight: onClose });
  useEffect(() => {
    const inputElement = document.getElementById("site-search-text-box");
    if (inputElement) {
      inputElement.focus();
    }
  }, []);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <EuiFlexGroup direction="column" responsive={false} style={{ overflow: "hidden", flex: 1 }}>
      <EuiFlexGroup
        direction="row"
        responsive={false}
        justifyContent="spaceBetween"
        alignItems="center"
        style={{ flexGrow: 0 }}
      >
        <EuiFlexItem grow={false}>
          <EuiText color={colors.neutrals.gray800} style={{ fontSize: "24px", fontWeight: 700 }}>
            Meld Search
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiIcon type={URL.getStatic("icons/close.svg")} size="l" onClick={onClose} />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexItem grow={false}>
        <EuiFieldSearch
          id="site-search-text-box"
          fullWidth={true}
          style={{ color: colors.neutrals.gray700, fontSize: "16px", lineHeight: "24px" }}
          incremental={true}
          isLoading={isFetching}
          isClearable={false}
          value={searchText}
          onChange={(e) => onChange(e)}
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction="column" gutterSize="s">
          <EuiFlexItem grow={false}>{filter}</EuiFlexItem>
          {checkbox && (
            <EuiFlexItem grow={false} style={{ marginLeft: "16px" }}>
              {checkbox}
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexGroup direction="column" style={{ gap: "16px" }}>
        {title && (
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="row" alignItems="center" gutterSize="s" responsive={false}>
              {iconString && (
                <EuiFlexItem grow={false}>
                  <EuiIcon type={iconString} />
                </EuiFlexItem>
              )}
              <EuiFlexItem grow={false}>
                <EuiText color={colors.neutrals.gray800} style={{ fontWeight: 700, whiteSpace: "nowrap" }}>
                  {title}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false} data-testid="global-search-results">
          {body}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexGroup>
  );
};

export { SiteSearchMobileBase };
