import I from "immutable";

import abbreviatedCommentTemplateAPI from "../apis/abbreviated-comment-template-api";
import Store from "../../common/stores/store";

class AbbreviatedCommentTemplateStore extends Store {
  constructor() {
    super();

    this.state.models = I.List();

    this.initRequestFunctions({
      fetchAll: this.fetchAll
    });
  }

  fetchAll() {
    return abbreviatedCommentTemplateAPI.fetchAll().then(res => {
      this.setState({ models: I.fromJS(res.data) });
      return res;
    });
  }

  getAll() {
    return this.state.models;
  }
}

export default new AbbreviatedCommentTemplateStore();
