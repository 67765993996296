import CCCvc from "./cc-cvc";
import CCExpiration from "./cc-expiration";
import CCNumber from "./cc-number";
import DetailFooter from "../../common/components/detail-footer";
import Modal from "../../modal";
import ModalBody from "../../common/components/modal-body";
import ModalHeader from "../../common/components/modal-header";
import React from "react";
import { ErrorList } from "../../common/components/errors";
import CancelSaveButtons from "../../common/components/cancel-save-buttons";

class CreditCardModal extends React.Component {
  constructor(props) {
    super(props);

    this.trySave = this.trySave.bind(this);
  }

  render() {
    let { dirty, data, saving, onDataChanged, errors, onClose } = this.props;

    return (
      <Modal isOpen={true}>
        <ModalHeader>Update Credit Card</ModalHeader>
        <ModalBody>
          <div className="cc-info">
            <div className="row">
              <div className="columns small-12 medium-6">
                <CCNumber cardNumber={data.cardNumber} onDataChanged={onDataChanged} />
              </div>
              <div className="columns small-12 medium-6">
                <CCExpiration onDataChanged={onDataChanged} expYear={data.expYear} expMonth={data.expMonth} />
                <CCCvc onDataChanged={onDataChanged} cvc={data.cvc} />
              </div>
            </div>
          </div>
          {dirty && <ErrorList errors={errors} />}
          <DetailFooter>
            <CancelSaveButtons
              onCancelFn={onClose}
              onSaveFn={this.trySave}
              cancelDisabled={saving}
              saveDisabled={saving}
              saveText={saving ? "Saving..." : "Save"}
            />
          </DetailFooter>
        </ModalBody>
      </Modal>
    );
  }

  trySave(e) {
    e.preventDefault();

    this.props.trySave();
  }
}

export default CreditCardModal;
