var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const Reminder = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement("path", { d: "M20 0a20 20 0 1020 20A20 20 0 0020 0zm0 38a18 18 0 1118-18 18 18 0 01-18 18z" }), /* @__PURE__ */ React.createElement("path", { d: "M30 22.32L29 17c-.94-4.21-2.8-6.95-5.54-8.15v-.48a3.5 3.5 0 00-7 0v.43c-3.7 1.61-5.09 5.77-5.61 8.21l-1 5.3a4.25 4.25 0 01-.36 1.11A5.58 5.58 0 009 26.11c0 2.4 4 4 7.59 4.65a3.74 3.74 0 00-.09.74 3.5 3.5 0 007 0 3.85 3.85 0 00-.09-.76C27 30 31 28.38 31 26.11a5.34 5.34 0 00-.52-2.58 6.74 6.74 0 01-.48-1.21zm-8.5 9.18a1.5 1.5 0 01-3 0 1.44 1.44 0 01.08-.46c.51.05 1 .07 1.42.07s.91 0 1.42-.07a1.39 1.39 0 01.08.46zM20 29.11c-4.48 0-9-2.05-9-3a3.7 3.7 0 01.36-1.87 6.37 6.37 0 00.5-1.55l1-5.28c.37-1.73 1.58-5.87 5-7a1.08 1.08 0 00.69-1v-1a1.5 1.5 0 013 0v1.1a1 1 0 00.68 1c2.41.8 4.06 3.14 4.9 6.92l1 5.37a9.49 9.49 0 00.56 1.58 3.39 3.39 0 01.31 1.73c-.1.89-4.78 3-9 3z" }));
