import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";

class CompanySettingsApi extends BaseApi {
  static fetchCurrent() {
    return this.get(ApiUrls.companySettings);
  }

  static update(data: any) {
    return this.put(ApiUrls.companySettings, data);
  }
}

export default CompanySettingsApi;
