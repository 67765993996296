import _ from "lodash";
import { Location } from "history";
import { parseQuery } from "./location-utils";

interface LocationFilterProps {
  location: Location;
  filterGroup?: object;
}

let SavedFilterUtils = {
  getFiltersFromObject(configObj: { [key: string]: string }, paramKeys: string[]): { [key: string]: string } {
    let updatedConfig: { [key: string]: string } = {};

    Object.keys(configObj).forEach(key => {
      const filterValue = configObj[key];

      // Empty arrays are not considered valid filter values
      if (Array.isArray(filterValue) && filterValue.length === 0) {
        return;
      }

      if (filterValue || filterValue === null) {
        updatedConfig[key] = filterValue;
      }
    });
    return _.pick(updatedConfig, paramKeys);
  },

  haveFiltersChanged(curProps: LocationFilterProps, nextProps: LocationFilterProps) {
    return (
      !_.isEqual(curProps.filterGroup, nextProps.filterGroup) ||
      !_.isEqual(parseQuery(curProps.location), parseQuery(nextProps.location))
    );
  }
};

export default SavedFilterUtils;
