import React from "react";

export enum Types {
  Body = "body",
  Info = "info",
  Error = "error"
}

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
  /**
   * Element to use. Can currently only be Body.
   * @default Types.Body
   */
  type?: Types;
}

export const TextBlock: React.FC<Props> = ({ type = Types.Body, children, ...elementProps }) => {
  return (
    <p className={`pm-text pm-text-${type}`} {...elementProps}>
      {children}
    </p>
  );
};

TextBlock.displayName = "TextBlock";

export default TextBlock;
