import vendorMeldFileActions from "../actions/vendor-meld-file-actions";
import vendorMeldFileApi from "../apis/vendor-meld-file-api";

let VendorMeldFileSource = {
  fetchFilesForMeld() {
    return {
      remote(state, meldId) {
        return vendorMeldFileApi.fetchFilesForMeld(meldId);
      },

      shouldFetch(state, meldId, force) {
        return true;
      },

      success: vendorMeldFileActions.fetchFilesDone,
      error: vendorMeldFileActions.fetchFilesFail,
      loading: vendorMeldFileActions.fetchFiles
    };
  }
};

export default VendorMeldFileSource;
