import { matchPath, useLocation } from "react-router-dom";

import { isRedesignToggleEnabled } from "@pm-frontend/shared/store/redesignToggle";
import { normalizePath } from "@pm-frontend/shared/utils/api-helpers";
import Features from "../common/feature-flags";
import { getOrgType } from "@pm-frontend/shared/utils/api-helpers";
import { useCalendarRedesignToggleStore } from "@pm-frontend/routes/Calendar/utils/utils";

// eventually these should be moved to frontend/
export const ReactRouterDomRoutes = {
  accountSettings: normalizePath("account-settings/"),
  expenditureDetail: normalizePath("melds/expenditures/:expenditureId(\\d+)/summary/"),
  feedback: normalizePath("feedback/"),
  integrationSettings: normalizePath("integrations/"),
  invoiceDetail: normalizePath("melds/payments/:id(\\d+)/summary/"),
  meldCalendar: normalizePath("calendar/melds/"),
  // no longer used, but included here to for backwards compatibility
  meldCalendarTech: normalizePath("calendar/techs/"),
  meldCalendarMeldDetails: normalizePath("calendar/melds/:meldId(\\d+)/"),
  // no longer used, but included here to for backwards compatibility
  meldCalendarMeldDetailsBook: normalizePath("calendar/melds/:meldId(\\d+)/book/"),
  meldCalendarMeldAvailabilities: normalizePath("calendar/melds/:meldId(\\d+)/availability/"), //
  meldCreateEditForm: normalizePath("melds/new-meld/"),
  meldDetail: normalizePath("meld/:meldId(\\d+)/summary/"),
  meldDetailActivity: normalizePath("meld/:meldId(\\d+)/activity/"),
  meldDetailChat: normalizePath("meld/:meldId(\\d+)/messages/"),
  meldDetailWorkLog: normalizePath("meld/:meldId(\\d+)/log/"),
  meldDetailContacts: normalizePath("meld/:meldId(\\d+)/contacts/"),
  meldDetailReminders: normalizePath("meld/:meldId(\\d+)/reminders/"),
  meldDetailRemindersAdd: normalizePath("meld/:meldId(\\d+)/add-reminder/"),
  meldDetailRemindersEdit: normalizePath("meld/:meldId(\\d+)/reminders/:reminderId(\\d+)/edit"),
  meldDetailEstimates: normalizePath("meld/:meldId(\\d+)/estimates/"),
  meldList: normalizePath("melds/melding/"),
  nexusRequestsList: normalizePath("nexus/requests/"),
  nexusAPIKeysList: normalizePath("nexus/api-keys/"),
  nexusSettings: normalizePath("nexus/settings/"),
  ownerDetail: normalizePath("properties/owners/:ownerId(\\d+)/"),
  ownerDetailSummary: normalizePath("properties/owners/:ownerId(\\d+)/summary/"),
  ownerDetailEdit: normalizePath("properties/owners/:ownerId(\\d+)/edit/"),
  ownerDetailProperties: normalizePath("properties/owners/:ownerId(\\d+)/properties/"),
  pcpDetailEdit: normalizePath("owner-services/:pcpId(\\d+)/edit"),
  pcpDetail: normalizePath("owner-services/:pcpId(\\d+)/"),
  projectList: normalizePath("projects/"),
  // this shouldn't be needed, but something is funky with the routing
  propertyDetail_extra: normalizePath("properties/addresses/:propertyId(\\d+)/"),
  propertyDetail: normalizePath("properties/addresses/:propertyId(\\d+)/"),
  propertyDetailSummary: normalizePath("properties/addresses/:propertyId(\\d+)/summary/"),
  propertyDetailEdit: normalizePath("properties/addresses/:propertyId(\\d+)/edit/"),
  propertyDetailMelds: normalizePath("properties/addresses/:propertyId(\\d+)/melds/"),
  // left in to keep legacy links operational
  propertyDetailBuildings: normalizePath("properties/addresses/:propertyId(\\d+)/buildings/"),
  // left in to keep legacy links operational
  propertyDetailFloors: normalizePath("properties/addresses/:propertyId(\\d+)/floors/"),
  propertyGroupDetail: normalizePath("properties/groups/:propertyGroupId(\\d+)/addresses/"),
  recurringMeldDetail: normalizePath("melds/recurring/:meldId(\\d+)"),
  reports: normalizePath("reporting/"),
  reportsV2: normalizePath("reportingV2/"),
  residentDetailEdit: normalizePath("tenants/:residentId(\\d+)/edit"),
  residentDetailMelds: normalizePath("tenants/:residentId(\\d+)/melds/"),
  residentDetail: normalizePath("tenants/:residentId(\\d+)/"),
  users: normalizePath("users/"),
  unitDetail: normalizePath("properties/:id(\\d+)/"),
  unitDetailEdit: normalizePath("properties/:id(\\d+)/edit/"),
  unitDetailSummary: normalizePath("properties/:id(\\d+)/summary/"),
  unitDetailMelds: normalizePath("properties/:id(\\d+)/melds/"),
  unitDetailInvoices: normalizePath("properties/:id(\\d+)/invoices/"),
  unitDetailResidents: normalizePath("properties/:id(\\d+)/tenants/"),
  unitDetailContacts: normalizePath("properties/:id(\\d+)/contacts/"),
  vendorDetail: normalizePath("vendors/:vendorId(\\d+)/summary"),
  vendorSearchList: normalizePath(`vendors/search/`),
  vendorSearchDetail: normalizePath(`vendors/search/:id(\\d+)/`),
} as const;

const productionRoutes = [
  ReactRouterDomRoutes.expenditureDetail,
  ReactRouterDomRoutes.integrationSettings,
  ReactRouterDomRoutes.invoiceDetail,
  ReactRouterDomRoutes.meldDetail,
  ReactRouterDomRoutes.meldDetailActivity,
  ReactRouterDomRoutes.meldDetailChat,
  ReactRouterDomRoutes.meldDetailContacts,
  ReactRouterDomRoutes.meldDetailEstimates,
  ReactRouterDomRoutes.meldDetailReminders,
  ReactRouterDomRoutes.meldDetailRemindersAdd,
  ReactRouterDomRoutes.meldDetailRemindersEdit,
  ReactRouterDomRoutes.meldDetailWorkLog,
  ReactRouterDomRoutes.ownerDetail,
  ReactRouterDomRoutes.ownerDetailEdit,
  ReactRouterDomRoutes.ownerDetailProperties,
  ReactRouterDomRoutes.ownerDetailSummary,
  ReactRouterDomRoutes.pcpDetail,
  ReactRouterDomRoutes.pcpDetailEdit,
  ReactRouterDomRoutes.propertyDetail,
  ReactRouterDomRoutes.propertyDetailBuildings,
  ReactRouterDomRoutes.propertyDetailEdit,
  ReactRouterDomRoutes.propertyDetailFloors,
  ReactRouterDomRoutes.propertyDetailMelds,
  ReactRouterDomRoutes.propertyDetailSummary,
  ReactRouterDomRoutes.propertyDetail_extra,
  ReactRouterDomRoutes.propertyGroupDetail,
  ReactRouterDomRoutes.recurringMeldDetail,
  ReactRouterDomRoutes.reportsV2,
  ReactRouterDomRoutes.residentDetail,
  ReactRouterDomRoutes.residentDetailEdit,
  ReactRouterDomRoutes.residentDetailMelds,
  ReactRouterDomRoutes.unitDetail,
  ReactRouterDomRoutes.unitDetailContacts,
  ReactRouterDomRoutes.unitDetailEdit,
  ReactRouterDomRoutes.unitDetailInvoices,
  ReactRouterDomRoutes.unitDetailMelds,
  ReactRouterDomRoutes.unitDetailResidents,
  ReactRouterDomRoutes.unitDetailSummary,
  ReactRouterDomRoutes.vendorDetail,
];

const betaRoutes: string[] = [];

const developmentRoutes: string[] = [ReactRouterDomRoutes.meldList, ReactRouterDomRoutes.projectList];

const nexusMgmtRoutes: string[] = [
  // Management App Routes
  ReactRouterDomRoutes.vendorSearchList,
  ReactRouterDomRoutes.vendorSearchDetail,
];

const schedulerRoutes: string[] = [
  ReactRouterDomRoutes.meldCalendar,
  ReactRouterDomRoutes.meldCalendarTech,
  ReactRouterDomRoutes.meldCalendarMeldDetails,
  ReactRouterDomRoutes.meldCalendarMeldDetailsBook,
  ReactRouterDomRoutes.meldCalendarMeldAvailabilities,
];

const meldFormRoutes: string[] = [ReactRouterDomRoutes.meldCreateEditForm];

// a hook usable in function components
export const useShowRedesignOrOriginalPage = (): "new" | "old" => {
  const location = useLocation();
  return showRedesignOrOriginalPage({ path: location.pathname });
};

// a non-hook way to query window size - won't cause re-renders on screen
// re-sizes, which is good enough for our current needs until we implement the
// mobile designs of the new page.  800px is a rough breakpoint for when the current
// calendar redesign gets pretty wonky.
export const shouldShowCalendarRedesignDueToDesktop = window.matchMedia(`(min-width: 800px)`);

export const showRedesignOrOriginalPage = ({ path }: { path: string }): "new" | "old" => {
  // this is a non-hook way to access the store, so can be used in non-functional components
  const { enabled: calendarRedesignEnabled } = useCalendarRedesignToggleStore.getState();
  // need this hack while the management app is in transistion from old to new
  if (getOrgType() === "n") {
    return "new";
  }
  if (getOrgType() === "e") {
    return "new";
  }
  if (getOrgType() === "v") {
    return "new";
  }
  const redesignToggleEnabled = isRedesignToggleEnabled();
  if (!redesignToggleEnabled) {
    return "old";
  }

  const showProductionRoutes =
    Features.isUIRedesignEnabledProduction() ||
    Features.isUIRedesignEnabledBeta() ||
    Features.isUIRedesignEnabledDevelopment();

  const showBetaRoutes = Features.isUIRedesignEnabledBeta() || Features.isUIRedesignEnabledDevelopment();

  const calendarEnabledOnMobile =
    !shouldShowCalendarRedesignDueToDesktop.matches &&
    (Features.isUIRedesignSchedulerMobileSunset() ||
      (Features.isUIRedesignSchedulerMobile() && calendarRedesignEnabled));
  const calendarEnabledOnDesktop =
    shouldShowCalendarRedesignDueToDesktop.matches &&
    (Features.isUIRedesignSchedulerSunset() || (Features.isUIRedesignScheduler() && calendarRedesignEnabled));

  // NOTE: add any other feature flag controlled routes here
  const allRoutes = [
    ...(showProductionRoutes ? productionRoutes : []),
    ...(showBetaRoutes ? betaRoutes : []),
    ...(Features.isUIRedesignEnabledDevelopment() ? developmentRoutes : []),
    ...(calendarEnabledOnMobile || calendarEnabledOnDesktop ? schedulerRoutes : []),
    // Include Nexus Management routes if Vendor Search feature is enabled
    ...(Features.isVendorSearchEnabled() ? nexusMgmtRoutes : []),
    ...(Features.isCreateMeldFormEnabled() ? meldFormRoutes : []),
  ];

  const matchNewRoute = matchPath(path, {
    path: allRoutes,
    exact: true,
  });

  return matchNewRoute ? "new" : "old";
};

export const showRedesignToggle = ({ path }: { path: string }): boolean => {
  const redesignToggleEnabled = isRedesignToggleEnabled();
  if (redesignToggleEnabled) {
    return false;
  }

  if (!Features.isUIRedesignEnabledDevelopmentOrProduction()) {
    return false;
  }

  if (Features.isUIRedesignEnabledProduction()) {
    for (const prodRoute of productionRoutes) {
      if (
        matchPath(path, {
          path: prodRoute,
          exact: true,
        })
      ) {
        return true;
      }
    }
  }
  if (Features.isUIRedesignEnabledBeta()) {
    for (const route of [...productionRoutes, ...betaRoutes]) {
      if (
        matchPath(path, {
          path: route,
          exact: true,
        })
      ) {
        return true;
      }
    }
  }

  if (Features.isUIRedesignEnabledDevelopment()) {
    for (const devRoute of [...developmentRoutes, ...betaRoutes, ...productionRoutes]) {
      if (
        matchPath(path, {
          path: devRoute,
          exact: true,
        })
      ) {
        return true;
      }
    }
  }
  return false;
};

// new nav is now always shown with toggle on
export const showRedesignNavigation = (): boolean => {
  if (getOrgType() === "n") {
    return true;
  }
  if (getOrgType() === "e") {
    return true;
  }
  if (getOrgType() === "v") {
    return true;
  }
  return isRedesignToggleEnabled();
};
