import React from "react";
import I from "immutable";
import AssignmentRequestAPI from "../../assets/js/meld/apis/assignment-request-api";
import VendorMeldUtils from "../../assets/js/vendor_app/meld/utils/vendor-meld-utils";
import { AxiosPromise, AxiosError } from "axios";
import MeldOwnerApprovalAPI from "../../assets/js/meld/apis/owner-approval-api";
import { OwnerApprovalStatuses } from "../utils/Constants";
import messageActions from "../../assets/js/common/actions/message-actions";
import MeldAPI from "@pm-assets/js/meld/apis/meld-api";

interface MeldContextProviderProps {
  meldId: number;
  meld?: I.Map<string, any>;
}

export interface MeldContextProviderState {
  loading: boolean;
  error: AxiosError | undefined;
  loadedNewMeld: boolean;
  actions: {
    refreshMeld: (showLoader?: boolean) => void;
    acceptMeld: () => AxiosPromise;
    rejectMeld: (reason: string) => AxiosPromise;
    updateTags: (meld: I.Map<string, any>) => AxiosPromise;
    unassignVendors: (meld: I.Map<string, any>) => AxiosPromise;
    cancelApprovalRequest: (meld: I.Map<string, any>, onComplete: () => void) => void;
  };
  meld?: I.Map<string, any>;
}

const MeldContext = React.createContext<Partial<MeldContextProviderState>>({});

export class MeldContextProvider extends React.Component<MeldContextProviderProps, MeldContextProviderState> {
  constructor(props: MeldContextProviderProps) {
    super(props);

    // skip loading meld if passed in from props (manager chat tab)
    if (props.meld) {
      this.state = {
        meld: props.meld,
        loading: false,
        error: undefined,
        loadedNewMeld: false,
        actions: {
          refreshMeld: this.refreshMeld.bind(this),
          rejectMeld: this.rejectMeld.bind(this),
          acceptMeld: this.acceptMeld.bind(this),
          updateTags: this.updateTags.bind(this),
          unassignVendors: this.unassignVendors.bind(this),
          cancelApprovalRequest: this.cancelApprovalRequest.bind(this),
        },
      };
    } else {
      this.state = {
        loading: true,
        error: undefined,
        loadedNewMeld: false,
        actions: {
          refreshMeld: this.refreshMeld.bind(this),
          rejectMeld: this.rejectMeld.bind(this),
          acceptMeld: this.acceptMeld.bind(this),
          updateTags: this.updateTags.bind(this),
          unassignVendors: this.unassignVendors.bind(this),
          cancelApprovalRequest: this.cancelApprovalRequest.bind(this),
        },
      };
    }
  }

  clearErrors() {
    this.setState({
      error: undefined,
    });
  }

  componentDidUpdate(prevProps: MeldContextProviderProps) {
    if (this.props.meldId !== prevProps.meldId && !this.state.loadedNewMeld) {
      this.setState({
        loading: true,
        loadedNewMeld: true,
      });
      void this.loadMeld();
    }
  }

  componentDidMount() {
    if (!this.state.meld) {
      void this.loadMeld();
    }
  }

  refreshMeld(showLoader: boolean = false) {
    if (showLoader) {
      this.setState({
        loading: true,
      });
    }

    void this.loadMeld();
  }

  async loadMeld() {
    this.clearErrors();
    const { meldId } = this.props;
    try {
      const result = await MeldAPI.getOne(meldId);
      this.setState({
        meld: I.fromJS(result.data),
        loading: false,
        loadedNewMeld: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
        error,
        loadedNewMeld: false,
      });
    }
  }

  rejectMeld(reason: string): AxiosPromise | undefined {
    const { meld } = this.state;

    if (meld) {
      return AssignmentRequestAPI.vendorReject(VendorMeldUtils.getMyCurrentAssignment(meld).get("id"), reason);
    }
  }

  acceptMeld(): AxiosPromise | undefined {
    const { meld } = this.state;

    if (meld) {
      return AssignmentRequestAPI.vendorAccept(VendorMeldUtils.getMyCurrentAssignment(meld).get("id"));
    }
  }

  updateTags(meld: any): AxiosPromise {
    return MeldAPI.updateTags(meld);
  }

  unassignVendors(meld: I.Map<string, any>) {
    let clearMaint = { maintenance: [], user_groups: [] };

    return MeldAPI.assignMaintenance(meld.get("id"), clearMaint);
  }

  cancelApprovalRequest(meld: I.Map<string, any>, onComplete: () => void) {
    MeldOwnerApprovalAPI.finishRequest(meld.get("id"), OwnerApprovalStatuses.OWNER_APPROVAL_NOT_REQUESTED)
      .then(() => {
        onComplete();
        void this.loadMeld();
      })
      .catch((response) => {
        messageActions.storeError({ body: response.messages });
      });
  }

  render() {
    const context = this.state;
    return <MeldContext.Provider value={{ ...context }}>{this.props.children}</MeldContext.Provider>;
  }
}

export default MeldContext;
