import { ImmutableMap, None } from "../types/common";
import { AddressNode, DisplayAddress } from "../common/graphql/types";
import { asImmer } from "../../../app/utils/decorators";

type Address = DisplayAddress | ImmutableMap;

class AddressUtils {
  @asImmer()
  static getLine1AndCity(address: Address) {
    address = address as DisplayAddress;

    return AddressUtils.formatCityStateZip(address.city, address.county_province, address.postcode);
  }

  @asImmer()
  static getFormattedCityStateZip(address: Address, emptyMsg = "") {
    address = address as DisplayAddress;

    return AddressUtils.formatCityStateZip(address.city, address.county_province, address.postcode) || emptyMsg;
  }

  static formatCityStateZip(city: string | None, state: string | None, postcode: string | None, emptyMsg = "") {
    if (!city && !postcode && !state) {
      return emptyMsg;
    }

    if (city) {
      return `${city}, ${state} ${postcode}`;
    }

    return `${state} ${postcode}`;
  }

  @asImmer()
  static getPropertyName(address: Address, emptyMsg: string = "No property name") {
    address = address as DisplayAddress;
    let property_name = address.property_name;
    return property_name !== "" ? property_name : emptyMsg;
  }

  @asImmer()
  static displayPropertyName(address: Address) {
    let property_name = AddressUtils.getPropertyName(address, "");
    return property_name !== AddressUtils.getAddressLine1(address) ? property_name : "";
  }

  @asImmer()
  static getAddressLine1(address: Address, emptyMsg = "") {
    address = address as DisplayAddress;

    return address.line_1 || emptyMsg;
  }

  @asImmer()
  static getAddressLine2(address: Address, emptyMsg = "") {
    address = address as DisplayAddress;

    return address.line_2 || emptyMsg;
  }

  @asImmer()
  static getAddressLine3(address: Address, emptyMsg = "") {
    address = address as DisplayAddress;

    return address.line_3 || emptyMsg;
  }

  @asImmer()
  static getDisplayAddressLines(address: Address, emptyMsg = "") {
    address = address as DisplayAddress;

    let lines = [
      AddressUtils.getAddressLine1(address, emptyMsg),
      AddressUtils.getAddressLine2(address, emptyMsg),
      AddressUtils.getAddressLine3(address, emptyMsg),
      emptyMsg,
    ];
    lines = lines.filter((line) => line);
    return lines.join(", ");
  }

  @asImmer()
  static getID(address: AddressNode) {
    address = address as AddressNode;
    return address.id;
  }

  @asImmer()
  static getDirectionsUrlString(address: Address) {
    address = address as DisplayAddress;

    const addressString = [AddressUtils.getAddressLine1(address), AddressUtils.getFormattedCityStateZip(address)]
      .join(" ")
      .trim();

    const encodedAddress = encodeURI(addressString);

    const userAgent = navigator.userAgent || navigator.vendor;

    // iOS detection from: https://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return `https://maps.apple.com/?&daddr=${encodedAddress}&dirflg=d`;
    }

    return `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}&travelmode=driving`;
  }

  @asImmer()
  static getAllAddressComponents(address: Address) {
    const part1 = [AddressUtils.displayPropertyName(address), AddressUtils.getAddressLine1(address)]
      .filter((addressPart) => !!addressPart)
      .join(" ");
    return `${part1} ${AddressUtils.getFormattedCityStateZip(address)}`;
  }
}

export default AddressUtils;
