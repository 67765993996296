import _ from "lodash";
import * as C from "../../constants";
import React from "react";

function calcOptimalNumDays() {
  let numDays = 7;
  if (window.innerWidth < C.SMALL_BREAKPOINT) {
    numDays = 1;
  } else if (window.innerWidth < C.MEDIUM_BREAKPOINT) {
    numDays = 4;
  }

  return numDays;
}

interface Props {
  numDays: number;
}

export default function withOptimalNumDays(
  Comp: React.ComponentClass<Props>,
  { optimalDaysFn = calcOptimalNumDays } = {}
) {
  return class WithOptimalNumDaysClass extends React.Component<Props, { numDays: number }> {
    constructor(props: Props) {
      super(props);
      this.state = {
        numDays: optimalDaysFn()
      };

      this.handleWindowResized = this.handleWindowResized.bind(this);
    }

    render() {
      const { numDays, ...props } = this.props;
      return <Comp numDays={this.state.numDays} {...props} />;
    }

    componentWillMount() {
      this.handleWindowResized = _.debounce(this.handleWindowResized, 200);
      window.addEventListener("resize", this.handleWindowResized, false);
    }

    componentWillUnmount() {
      (this.handleWindowResized as any).cancel();
      window.removeEventListener("resize", this.handleWindowResized, false);
    }

    handleWindowResized() {
      this.setState({ numDays: optimalDaysFn() });
    }
  };
}
