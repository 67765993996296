import I from "immutable";

import Activity from "./activity";
import Document from "../../svg/document-svg";
import Meld from "../../svg/meld-svg";
import Tenant from "../../svg/tenant-svg";
import Chat from "../../svg/chat-svg";

enum NotificationType {
  MELD,
  MELDS_NEED_AVAILABILITY,
  EXPIRING_FILE,
  REQUEST_TENANT_INVITE,
  NEXUS_CONNECTION_ACCEPTED,
  NEXUS_CONNECTION_REJECTED,
}

export let notificationFields = I.Set(["id", "group", "activities"]);

let NotificationRecord = I.Record({
  id: null,
  group: null,
  activities: I.List<Activity>(),
});

class Notification extends NotificationRecord {
  getTime() {
    return this.get("activities").first().get("time");
  }

  getTitle() {
    switch (+this.getType()) {
      case NotificationType.MELD:
        let extra_context = this.getIn(["activities", 0, "extra_context"]);
        if (extra_context.has("address_name")) {
          return `${extra_context.get("meld_name")} | ${extra_context.get("address_name")} ${extra_context.get(
            "unit_name"
          )}`;
        } else {
          return extra_context.get("meld_name");
        }
      case NotificationType.MELDS_NEED_AVAILABILITY:
        return "Melds need availability";
      case NotificationType.EXPIRING_FILE:
        return "Vendor files";
      case NotificationType.REQUEST_TENANT_INVITE:
        return "Tenant invite requests";
      case NotificationType.NEXUS_CONNECTION_ACCEPTED:
        return "Nexus connection accepted";
      case NotificationType.NEXUS_CONNECTION_REJECTED:
        return "Nexus connection rejected";
    }
  }

  getType() {
    return NotificationType[this.get("group").split(":")[0]];
  }

  getIcon() {
    switch (+this.getType()) {
      case NotificationType.MELD:
      case NotificationType.MELDS_NEED_AVAILABILITY:
        return Meld;
      case NotificationType.REQUEST_TENANT_INVITE:
        return Tenant;
      case NotificationType.EXPIRING_FILE:
        return Document;
      // NB: the icon is required, but not used
      case NotificationType.NEXUS_CONNECTION_REJECTED:
      case NotificationType.NEXUS_CONNECTION_ACCEPTED:
        return Chat;
    }
  }

  isRead() {
    return this.get("activities").every((activity: Activity) => {
      if (activity) {
        return activity.isRead();
      } else {
        return false;
      }
    });
  }
}

export default Notification;
