var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
export default function connect(Comp, storesConfig) {
  return class Connect extends React.Component {
    constructor(props) {
      super(props);
      this.handleUpdate = this.handleUpdate.bind(this);
      this.state = this.propsFromStores;
    }
    render() {
      return /* @__PURE__ */ React.createElement(Comp, __spreadValues(__spreadValues({}, this.props), this.propsFromStores));
    }
    componentWillMount() {
      storesConfig.stores.forEach((store) => store.on(this.handleUpdate));
      if (storesConfig.componentWillMount) {
        storesConfig.componentWillMount(this.props);
      }
    }
    componentWillUnmount() {
      storesConfig.stores.forEach((store) => store.off(this.handleUpdate));
      if (storesConfig.componentWillUnmount) {
        storesConfig.componentWillUnmount();
      }
    }
    handleUpdate() {
      this.forceUpdate();
    }
    get propsFromStores() {
      return Object.keys(storesConfig.props).reduce((result, key) => {
        result[key] = storesConfig.props[key](this.props);
        return result;
      }, {});
    }
  };
}
