import ApiUtils from "./api-utils";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosPromise, AxiosHeaders } from "axios";
import { LinkHelper } from "@pm-shared/utils/link";
import { ERROR_SUPPORT_MESSAGE, ERROR_403, ERROR_404 } from "../constants";
import { decrementXHRCount, incrementXHRCount } from "./xhr-counter";
import { AuthUtils } from "./auth-utils";

export enum BaseApiCustomCodes {
  RequestCancelled = "pm499",
}

axios.interceptors.response.use(
  (response) => {
    decrementXHRCount();
    return response;
  },
  (error) => {
    decrementXHRCount();

    // tslint:disable-next-line: triple-equals
    if (error == "Cancel") {
      error.code = BaseApiCustomCodes.RequestCancelled;
      error.message = `${BaseApiCustomCodes.RequestCancelled} - Request was canceled`;
      return Promise.reject(error);
    }

    let errorsMessages;
    if (error && error.response) {
      if (error.response.status === 403) {
        errorsMessages = [ERROR_403];
      } else if (error.response.status === 404) {
        errorsMessages = [ERROR_404];
      } else {
        errorsMessages = ApiUtils.transformErrors(error.response);
      }
    }
    if (!errorsMessages || !errorsMessages.length) {
      errorsMessages = [ERROR_SUPPORT_MESSAGE];
    }
    error.messages = errorsMessages;
    // TODO Remove this line after converting base store to only use `messages` instead of `msgs`
    error.msgs = errorsMessages;
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(
  (config) => {
    incrementXHRCount();
    return config;
  },
  (error) => {
    decrementXHRCount();
    return Promise.reject(error);
  }
);

class BaseApi {
  static get(url: string, options?: any) {
    let xhrOptions = Object.assign({ normalizeUrl: true }, options || {});

    let realUrl = url;

    if (xhrOptions.normalizeUrl && !xhrOptions.isPublicUrl) {
      realUrl = this._getRealUrl(url);
    }

    return axios.get(realUrl, xhrOptions);
  }

  static post(url: string, data?: any, options?: any) {
    let xhrOptions = Object.assign({}, options || {});
    let xhrData = Object.assign({}, data || {});

    xhrOptions.headers = this._getAdditionalHeaders();

    let realUrl = url;

    if (!xhrOptions.isPublicUrl) {
      realUrl = this._getRealUrl(url);
    }

    return axios.post(realUrl, xhrData, xhrOptions);
  }

  static put(url: string, data?: object, options?: any) {
    let xhrOptions = Object.assign({}, options || {});
    let xhrData = Object.assign({}, data || {});

    xhrOptions.headers = this._getAdditionalHeaders();
    return axios.put(this._getRealUrl(url), xhrData, xhrOptions);
  }

  static patch(url: string, data?: object, options?: any) {
    let xhrOptions = Object.assign({}, options || {});
    let xhrData = Object.assign({}, data || {});

    xhrOptions.headers = this._getAdditionalHeaders();
    return axios.patch(this._getRealUrl(url), xhrData, xhrOptions);
  }

  static delete(url: string, options?: object) {
    let xhrOptions: AxiosRequestConfig = Object.assign({}, options || {});

    xhrOptions.headers = this._getAdditionalHeaders();
    return axios.delete(this._getRealUrl(url), xhrOptions);
  }

  static _getAdditionalHeaders() {
    let headers = new AxiosHeaders({
      "X-CSRFToken": AuthUtils.getCsrfToken(),
    });

    return headers;
  }

  static _getRealUrl(url: string) {
    return LinkHelper.normalize(url);
  }
}

export default BaseApi;

export interface Response extends AxiosResponse {
  messages: string[];
}

export type RequestConfig = AxiosRequestConfig;

export type Promise = AxiosPromise;
