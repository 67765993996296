import AltContainer from "alt-container";
import I from "immutable";
import MeldFiles, { MeldFilesProps } from "./meld-files";
import React from "react";
import tenantMeldFileActions from "../actions/tenant-meld-file-actions";
import tenantMeldFileStore from "../stores/tenant-meld-file-store";
import withPreviewableFiles, { Injected } from "../../common/components/with-previewable-files";
import { ImmutableList, Omit } from "../../types/common";

type Props = MeldFilesProps & Injected;

export class TenantMeldFiles extends React.Component<Props> {
  render() {
    return (
      <MeldFiles
        {...this.props}
        isLoading={this.props.isLoading}
        deleteFile={this.props.deleteFile || this.deleteFile}
      />
    );
  }

  deleteFile(file: I.Map<string, any>) {
    tenantMeldFileActions.deleteFile(file.get("id"));
  }
}

const PreviewableTenantMeldFiles = withPreviewableFiles<MeldFilesProps>(TenantMeldFiles);

interface MeldFilesContainerProps extends Omit<MeldFilesProps, "files" | "isLoading"> {
  files?: ImmutableList;
  meldId?: number;
}

export default class extends React.Component<MeldFilesContainerProps> {
  render() {
    const { files, meldId } = this.props;

    return (
      <AltContainer
        stores={[tenantMeldFileStore]}
        render={() => {
          return (
            <PreviewableTenantMeldFiles
              {...this.props}
              isLoading={tenantMeldFileStore.isLoading()}
              files={meldId ? tenantMeldFileStore.getFilesForMeld(meldId) : files}
            />
          );
        }}
      />
    );
  }
}
