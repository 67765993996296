import React from "react";

interface Store {
  on: (handler: () => void) => void;
  off: (handler: () => void) => void;
}

interface StoresConfig {
  props: { [key: string]: any };
  stores: Store[];
  componentWillMount?: (props: { [key: string]: any }) => void;
  componentWillUnmount?: () => void;
}

export default function connect(Comp: React.ComponentClass, storesConfig: StoresConfig) {
  return class Connect extends React.Component<any, {}> {
    constructor(props: any) {
      super(props);
      this.handleUpdate = this.handleUpdate.bind(this);
      this.state = this.propsFromStores;
    }

    render() {
      return <Comp {...this.props} {...this.propsFromStores} />;
    }

    componentWillMount() {
      storesConfig.stores.forEach(store => store.on(this.handleUpdate));
      if (storesConfig.componentWillMount) {
        storesConfig.componentWillMount(this.props);
      }
    }

    componentWillUnmount() {
      storesConfig.stores.forEach(store => store.off(this.handleUpdate));
      if (storesConfig.componentWillUnmount) {
        storesConfig.componentWillUnmount();
      }
    }

    handleUpdate() {
      this.forceUpdate();
    }

    get propsFromStores() {
      return Object.keys(storesConfig.props).reduce((result: { [key: string]: any }, key) => {
        result[key] = storesConfig.props[key](this.props);

        return result;
      }, {});
    }
  };
}
