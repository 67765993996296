import React from "react";

export default class Star extends React.Component {
  render() {
    return (
      /* tslint:disable:max-line-length */
      <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 426.656 426.656" className="star-svg">
        <path d="M425.04 140.672c-3.285-7.957-11.093-13.163-19.712-13.163H290.533L232.42 11.306c-7.23-14.443-30.932-14.443-38.164 0L136.144 127.51H21.328c-8.62 0-16.427 5.205-19.712 13.163-3.285 7.98-1.472 17.173 4.63 23.253l97.79 97.792-39.21 137.28c-2.453 8.597.704 17.813 7.915 23.083 7.254 5.292 17.004 5.462 24.428.513l116.16-77.44 116.16 77.44c3.584 2.39 7.723 3.584 11.84 3.584 4.416 0 8.832-1.365 12.587-4.096 7.21-5.268 10.368-14.484 7.915-23.082l-39.21-137.28 97.79-97.792c6.102-6.08 7.915-15.275 4.63-23.254zm-141.46 99.755c-5.484 5.46-7.574 13.483-5.42 20.95l26.71 93.524-79.702-53.14c-3.584-2.368-7.723-3.584-11.84-3.584s-8.256 1.216-11.84 3.584l-79.7 53.14 26.708-93.524c2.133-7.445.064-15.467-5.42-20.95l-70.25-70.25h76.5c8.086 0 15.468-4.565 19.094-11.797l44.907-89.836 44.907 89.835c3.627 7.23 11.008 11.796 19.093 11.796h76.5l-70.248 70.25z" />
      </svg>
      /* tslint:enable:max-line-length */
    );
  }
}
