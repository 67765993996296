var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { IoMdPin } from "react-icons/io";
import { stylesForSemiboldText } from "../theme";
import { getFullDisplayAddress, getPropertyName } from "@pm-shared/utils/meld";
export function LocationLine(_a) {
  var _b = _a, { meld } = _b, props = __objRest(_b, ["meld"]);
  return /* @__PURE__ */ React.createElement(Box, __spreadValues({ color: "gray.500", fontSize: "sm" }, props), /* @__PURE__ */ React.createElement(Flex, { as: "span", alignItems: "top", direction: "row" }, /* @__PURE__ */ React.createElement(Icon, { as: IoMdPin, fontSize: "lg", color: "green.600", marginRight: 1, marginTop: 0.5 }), /* @__PURE__ */ React.createElement("div", { "data-test": "owner_approvals.approval_card.location_line" }, getPropertyName(meld) ? /* @__PURE__ */ React.createElement(Text, __spreadValues({}, stylesForSemiboldText), `${getPropertyName(meld)}`) : null, /* @__PURE__ */ React.createElement(Text, null, getFullDisplayAddress(meld)))));
}
