var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import classnames from "classnames";
import React from "react";
import { MdCheckCircle, MdClose, MdError, MdInfo } from "react-icons/md";
import IconLink from "../icon-link/icon-link";
const getIcon = (type) => {
  switch (type) {
    case "confirm" /* Confirm */:
      return /* @__PURE__ */ React.createElement(MdCheckCircle, null);
    case "error" /* Error */:
      return /* @__PURE__ */ React.createElement(MdError, null);
    case "inline-error" /* InlineError */:
      return /* @__PURE__ */ React.createElement(MdError, null);
    default:
      return /* @__PURE__ */ React.createElement(MdInfo, null);
  }
};
export var AlertTypes = /* @__PURE__ */ ((AlertTypes2) => {
  AlertTypes2["Confirm"] = "confirm";
  AlertTypes2["Info"] = "info";
  AlertTypes2["Error"] = "error";
  AlertTypes2["InlineError"] = "inline-error";
  AlertTypes2["InvertInfo"] = "invert-info";
  return AlertTypes2;
})(AlertTypes || {});
export const Alert = (_a) => {
  var _b = _a, {
    type = "info" /* Info */,
    className,
    children,
    onDismiss
  } = _b, elementProps = __objRest(_b, [
    "type",
    "className",
    "children",
    "onDismiss"
  ]);
  const classes = classnames("pm-alert", `pm-alert-${type}`, className ? className : "");
  return /* @__PURE__ */ React.createElement("div", __spreadValues({ className: classes }, elementProps), /* @__PURE__ */ React.createElement("div", { className: "pm-alert-icon" }, getIcon(type)), /* @__PURE__ */ React.createElement("div", { className: "pm-alert-children" }, children), onDismiss && /* @__PURE__ */ React.createElement(IconLink, { className: "pm-alert-dismiss", icon: /* @__PURE__ */ React.createElement(MdClose, null), onClick: onDismiss }, "Dismiss"));
};
Alert.displayName = "Alert";
export default Alert;
