var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";
class CommentTemplateAPI extends BaseApi {
  static create(template) {
    return this.post(ApiUrls.commentTemplateList, template);
  }
  static fetchAll({ url, xhrOptions } = {}) {
    if (url) {
      return this.get(url, __spreadValues({ normalizeUrl: false }, xhrOptions));
    }
    return this.get(ApiUrls.commentTemplateList, xhrOptions);
  }
  static fetchOne(id) {
    return this.get(ApiUrls.commentTemplateDetail(id));
  }
  static updateCommentTemplate(template) {
    return this.patch(ApiUrls.commentTemplateDetail(template.id), template);
  }
  static deleteCommentTemplate(id) {
    return this.delete(ApiUrls.commentTemplateDetail(id));
  }
}
export default CommentTemplateAPI;
