import _ from "lodash";
import React from "react";

import { ErrorHandler } from "../../../../app/utils/ErrorHandler";
import Meld from "../../svg/meld-svg";
import Pin from "../../svg/pin-svg";
import Tenant from "../../svg/tenant-svg";
import TenantUtils from "../../tenant/utils/tenant-utils";
import TruckSvg from "@pm-assets/js/svg/truck-svg";
import { IoEyeOffSharp } from "react-icons/io5";
import Features from "@pm-assets/js/common/feature-flags";

function label(strings: TemplateStringsArray, value: string) {
  let lab = strings[0];

  if (value) {
    return `${lab}: ${value}`;
  }
}

interface Props {
  hit: any;
  propGroupsVisible: boolean;
}

const PropGroupVisible = ({ visible }: { visible: boolean }) => (
  <span
    style={{
      display: "table-cell",
      verticalAlign: "middle",
      marginTop: "10px",
      float: "right",
    }}
  >
    {!visible && (
      <div style={{ marginLeft: "32px", color: "#8a95a5" }}>
        <IoEyeOffSharp />
      </div>
    )}
  </span>
);

class SearchResultRow extends React.Component<Props> {
  render() {
    let { _source: source, _index: index } = this.props.hit;
    switch (index) {
      case "tenant":
        return (
          <div className="search-row-content">
            <PropGroupVisible visible={this.props.propGroupsVisible} />
            <span className="search-row-icon">
              <Tenant />
            </span>
            <span className="search-row-text">
              <span className="primary-text">
                {TenantUtils.formatFullName(source.first_name, source.last_name, source.middle_name)}
              </span>
              {Features.isPropertyNameEnabled() && source.unit && source.unit.property_name !== "" && (
                <div className="ancillary-text">{source.unit.property_name}</div>
              )}
              <div className="ancillary-text">{_.get(source, "unit.display_address.line_1")}</div>
              <div className="ancillary-text">{this.getUnitSubFieldsString(source.unit)}</div>
            </span>
          </div>
        );
      case "unit":
        return (
          <div className="search-row-content">
            <PropGroupVisible visible={this.props.propGroupsVisible} />
            <span className="search-row-icon">
              <Pin />
            </span>
            <span className="search-row-text">
              {Features.isPropertyNameEnabled() && source.prop && source.prop.property_name !== "" && (
                <>
                  <span className="primary-text">{source.prop.property_name}</span>
                  <div className="ancillary-text">{source.display_address.line_1}</div>
                </>
              )}
              {Features.isPropertyNameEnabled() && source.prop && source.prop.property_name === "" && (
                <div className="primary-text">{source.display_address.line_1}</div>
              )}
              {!Features.isPropertyNameEnabled() && <div className="primary-text">{source.display_address.line_1}</div>}
              <div className="ancillary-text">{this.getUnitSubFieldsString(source)}</div>
            </span>
          </div>
        );
      case "meld":
        return (
          <div className="search-row-content">
            <PropGroupVisible visible={this.props.propGroupsVisible} />
            <span className="search-row-icon">
              <Meld />
            </span>
            <span className="search-row-text">
              <span className="primary-text">{source.brief_description}</span>
              {Features.isPropertyNameEnabled() && source.unit.prop && source.unit.prop.property_name !== "" && (
                <div className="ancillary-text">{source.unit.prop.property_name}</div>
              )}
              <div className="ancillary-text">{source.unit.display_address.line_1}</div>
            </span>
          </div>
        );
      case "owner":
        return (
          <div className="search-row-content">
            <PropGroupVisible visible={this.props.propGroupsVisible} />
            <span className="search-row-icon">
              <Tenant />
            </span>
            <span className="search-row-text">
              <span className="primary-text">
                {source.first_name} {source.last_name}
              </span>
              <div className="ancillary-text">Owner</div>
            </span>
          </div>
        );
      case "vendor":
        return (
          <div className="search-row-content">
            <PropGroupVisible visible={this.props.propGroupsVisible} />
            <span className="search-row-icon">
              <TruckSvg />
            </span>
            <span className="search-row-text">
              <span className="primary-text">{source.name}</span>
              <div className="ancillary-text">Vendor</div>
            </span>
          </div>
        );
      default:
        ErrorHandler.handleError(new Error(`Unable to find index to render search row result: ${index}`));
    }

    return <div />;
  }

  getUnitSubFieldsString(unit?: any) {
    if (!unit) {
      return "";
    }

    let labels = [];

    labels.push(label`Apartment${unit.apartment}`);
    labels.push(label`Suite${unit.suite}`);
    labels.push(label`Unit${unit.unit}`);
    labels.push(label`Floor${_.get(unit, "floor.number")}`);
    labels.push(label`Building${_.get(unit, "building.number")}`);
    labels.push(label`Room${unit.room}`);
    labels.push(label`Department${unit.department}`);

    return labels.filter((lab) => lab).join(" | ");
  }
}

export default SearchResultRow;
