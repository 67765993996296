import React from "react";
import ButtonContainer from "../../../../app/components/containers/ButtonContainer";
import Button, { ButtonTypes } from "../../../../app/components/buttons/Button";
import { FlexJustifyContent } from "../../../../app/components/utils/constants";
import classnames from "classnames";

interface CancelSaveButtonsProps {
  onCancelFn: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onSaveFn: (event: React.MouseEvent<HTMLButtonElement>) => void;
  cancelDisabled?: boolean;
  saveDisabled?: boolean;
  cancelClassName?: string;
  saveClassName?: string;
  justifyContainer?: FlexJustifyContent;
  saveText?: string;
  cancelText?: string;
  swapButtonOrder?: boolean;
}

export default function CancelSaveButtons(props: CancelSaveButtonsProps) {
  const saveText = props.saveText || "Save";
  const cancelText = props.cancelText || "Cancel";
  const saveClasses = classnames("save", props.saveClassName);
  const cancelClasses = classnames(props.cancelClassName);
  const cancel = (
    <Button
      buttonType={ButtonTypes.Cancel}
      onClick={props.onCancelFn}
      disabled={props.cancelDisabled}
      className={cancelClasses}
    >
      {cancelText}
    </Button>
  );
  const save = (
    <Button
      buttonType={ButtonTypes.Primary}
      submit={true}
      onClick={props.onSaveFn}
      disabled={props.saveDisabled}
      className={saveClasses}
    >
      {saveText}
    </Button>
  );
  let buttons = (
    <React.Fragment>
      {cancel}
      {save}
    </React.Fragment>
  );
  if (props.swapButtonOrder) {
    buttons = (
      <React.Fragment>
        {save}
        {cancel}
      </React.Fragment>
    );
  }
  return <ButtonContainer justify={props.justifyContainer}>{buttons}</ButtonContainer>;
}
