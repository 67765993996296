var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { authParams, vApi } from "../../common/api";
import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";
class AssignmentRequestAPI extends BaseApi {
  static vendorReject(id, reason) {
    return vApi.vOIApiAssignmentsRejectPartialUpdate(__spreadValues({
      id,
      data: { reject_reason: reason }
    }, authParams));
  }
  static vendorAccept(id) {
    return this.patch(ApiUrls.vendorAcceptAssignment(id));
  }
  static fetchPending(options) {
    return this.get(ApiUrls.assignmentRequestList, options);
  }
  static fetchOne(id, options) {
    return this.get(ApiUrls.assignmentRequestDetail(id), options);
  }
  static updateSegments(id, data, options) {
    return this.patch(ApiUrls.vendorUpdateSegments(id), data, options);
  }
}
export default AssignmentRequestAPI;
