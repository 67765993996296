var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const BackArrow = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement("path", { d: "M20 0a20 20 0 1020 20A20 20 0 0020 0zm11 21.5H11.89L21 29.9a1.5 1.5 0 01-2 2.2L8.57 22.52A3.5 3.5 0 017.5 20a3.46 3.46 0 011-2.48L19 7.9a1.5 1.5 0 112 2.2l-9.13 8.4H31a1.5 1.5 0 010 3z" }));
