import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";

class IntegrationPartnerApi extends BaseApi {
  static fetchAll() {
    return this.get(ApiUrls.integrationPartnerList);
  }

  static fetchBySlug(slug: string) {
    return this.get(ApiUrls.integrationPartnerDetail(slug));
  }
}

export default IntegrationPartnerApi;
