import _ from "lodash";
import { AxiosResponse } from "axios";
import { ERROR_SUPPORT_MESSAGE } from "../constants";

let ApiUtils = {
  transformErrors(xhr: AxiosResponse) {
    if (xhr.status === 0) {
      return ["Error: No connection"];
    }

    if (xhr.status === 404) {
      return ["Not found: Error 404"];
    }

    if (xhr.status >= 400 && xhr.status < 500) {
      return this._get400Errors(xhr.data);
    } else if (xhr.status >= 500) {
      return [ERROR_SUPPORT_MESSAGE];
    }
  },

  // Example: field = "current_tenants", error = "This field is required". Should return
  // "Current tenants: This field is require"
  _getFormattedFieldError(field: string, error: string) {
    let fieldNameParts = _.map(field.split("_"));
    fieldNameParts[0] = _.capitalize(fieldNameParts[0]);
    let fieldName = fieldNameParts.join(" ");
    return `${fieldName}: ${error}`;
  },

  _get400Errors(data: any) {
    /*
            Recurses through a nested response to build an array of errors of the form {"Error name": "Error message"}
            Handles a variety of formats the can be returned from the api. See api-utils.tests.js for examples of errors

         */

    if (_.isArray(data)) {
      return data;
    }

    if (typeof data === "string" || data instanceof String) {
      return [
        "An error has occurred, possibly due to network connectivity issues. " +
          "Please reload the page, and if the problem persists please contact us at support@propertymeld.com."
      ];
    }

    let errors = Object.keys(data).map(key => {
      let error = data[key];
      if (_.isString(error)) {
        if (key === "detail") {
          return `Error: ${error}`;
        }

        return `${key}: ${error}`;
      } else if (_.isArray(error)) {
        return error.map(dataKey => {
          if (_.isObject(dataKey)) {
            return this._get400Errors(dataKey);
          } else {
            if (key === "non_field_errors" || key === "detail") {
              return "Error: " + dataKey;
            }

            let errorKey = `${_.capitalize(key)}: `;
            return errorKey + dataKey;
          }
        });
      } else {
        return this._get400Errors(error);
      }
    });

    return _.flattenDeep(errors);
  }
};

export default ApiUtils;
