var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import classnames from "classnames";
import { MdTranslate } from "react-icons/md";
import _ from "lodash";
import AltContainer from "alt-container";
import * as C from "../constants";
import CommentView from "../../../app/views/melds/CommentView";
import CommentSummaryView from "../../../app/views/melds/CommentSummaryView";
import CommentActions from "./actions/comment-actions";
import CommentStore from "./stores/comment-store";
import CommentTemplateDropdown from "./components/comment-template-dropdown";
import CommentVisibilitySelector, { Visibility } from "./components/comment-visibility-selector";
import createReactClass from "create-react-class";
import { ErrorHandler } from "../../../app/utils/ErrorHandler";
import I from "immutable";
import Link, { LinkHelper } from "@pm-shared/utils/link";
import MeldUtils from "./utils/meld-utils";
import { ModelUpdate } from "../utils/model-update-utils";
import OwnerAddModal from "../property/owner-add-modal";
import OwnerUtils from "../property/utils/owner-utils";
import PropTypes from "prop-types";
import React from "react";
import SetIntervalWhileActive from "../common/components/set-interval-while-active";
import { SmallLoader } from "../loaders";
import Status from "../mixins/status";
import TenantSettingsStore from "../tenant_app/stores/tenant-settings-store";
import TenantUtils from "../tenant/utils/tenant-utils";
import { Actions } from "../stores/base-store";
import { fitToContent } from "../common/utils/textarea-utils";
import { hasPerm } from "../common/utils/permission-utils";
import { Prompt } from "react-router-dom";
import Button, { ButtonTypes } from "../../../app/components/buttons/Button";
import ButtonContainer from "../../../app/components/containers/ButtonContainer";
import DropdownButton from "../../../app/components/buttons/DropdownButton";
import OptionsButton from "../../../app/components/buttons/OptionsButton";
import MenuList from "../../../app/components/texts/MenuList";
import { FlexJustifyContent } from "../../../app/components/utils/constants";
import MeldContext, { MeldContextProvider } from "../../../app/contexts/MeldContext";
import {
  track,
  VendorChatReceiverSelected,
  VendorChatSent,
  VendorTranslateToEnglishClicked,
  TenantTranslateToEnglishClicked
} from "@pm-app/utils/analytics";
import { AuthUtils } from "@pm-assets/js/utils/auth-utils";
import Features from "@pm-assets/js/common/feature-flags";
import { postTranslateToEnglishData } from "../../../app/views/melds/comment-services";
import { fetchCurrentVendorAgentNonSWR } from "@pm-property-management-hub/src/utils/api/vendors";
import { fetchCurrentTenantLanguageNonSWR } from "@pm-property-management-hub/src/utils/api/tenants";
import MessageModifyComponent from "@pm-property-management-hub/src/components/melds/message-modify";
import { getTranslatedUIPrompt } from "../../../app/views/melds/utils/language_utils";
var CommentsModes = /* @__PURE__ */ ((CommentsModes2) => {
  CommentsModes2["DEFAULT"] = "DEFAULT";
  CommentsModes2["ADD_OWNER"] = "ADD_OWNER";
  return CommentsModes2;
})(CommentsModes || {});
export let Comments = createReactClass({
  loadingStore: CommentStore,
  propTypes: {
    meld: PropTypes.object.isRequired
  },
  mixins: [Status],
  getInitialState() {
    this.textRef = React.createRef();
    this.mergeData = this.props.mergeData.bind(this);
    this.setVal = this.props.setVal.bind(this);
    return {
      show_translation_feature: false,
      default_language: "en",
      rateLimitPassed: false,
      cid: _.uniqueId("Comments_"),
      creatingComment: false,
      data: I.fromJS({
        hidden_from_tenant: false,
        hidden_from_vendor: false,
        hidden_from_owner: true,
        text: "",
        translatedText: null,
        translatedTo: null,
        isTranslating: false,
        is_translated: false
      }),
      mode: "DEFAULT" /* DEFAULT */
    };
  },
  handleTranslateClick() {
    this.setState({ data: this.state.data.set("isTranslating", true) });
    if (this.state.data.get("translatedTo") === "en") {
      this.setState({
        data: this.state.data.set("translatedText", null).set("translatedTo", null).set("isTranslating", false)
      });
    } else {
      if (LinkHelper.getOrgType() === "v") {
        track(VendorTranslateToEnglishClicked());
      }
      if (LinkHelper.getOrgType() === "t") {
        track(TenantTranslateToEnglishClicked());
      }
      postTranslateToEnglishData(this.state.data.get("text"), this.props.meld.get("id")).then((response) => {
        this.setState({
          data: this.state.data.set("translatedText", response.generated_text).set("translatedTo", "en").set("isTranslating", false)
        });
      }).catch((e) => {
        this.setState({
          rateLimitPassed: e.message === "Request failed with status code 429" ? true : false,
          data: this.state.data.set("translatedTo", null).set("isTranslating", false)
        });
      });
    }
  },
  handleTextChange(value) {
    if (this.state.data.get("translatedText")) {
      this.setState({ data: this.state.data.set("translatedText", value) });
    } else {
      this.setState({ data: this.state.data.set("text", value) });
    }
  },
  render() {
    if (this.props.loading) {
      return /* @__PURE__ */ React.createElement(SmallLoader, null);
    }
    const isTranslationEnabled = () => {
      const orgType = LinkHelper.getOrgType();
      if (orgType === "v") {
        return Features.isGptTranslateEnabled();
      } else if (orgType === "t") {
        return Features.isResidentGptTranslateEnabled();
      }
      return false;
    };
    const attemptMentions = this.state.data.get("text").includes(" @") || this.state.data.get("text").startsWith("@");
    const mentionDisabledStyles = {
      marginTop: "8px",
      color: "var(--interface-red-default, #B2250F)"
    };
    const belowTextMessage = () => {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, Features.isLegacyCustomer() && attemptMentions && /* @__PURE__ */ React.createElement("p", { style: mentionDisabledStyles }, "@Mentions aren't available on your pricing tier"), Features.isMeldMentionsEnabled() && attemptMentions && /* @__PURE__ */ React.createElement(React.Fragment, null, this.props.meld.get("meld_type") === "ESTIMATE" && /* @__PURE__ */ React.createElement("p", { style: mentionDisabledStyles }, "@Mentions aren't available on estimates yet"), this.props.meld.get("meld_type") === "MELD" && LinkHelper.getOrgType() === "m" && /* @__PURE__ */ React.createElement("p", { style: mentionDisabledStyles }, "Toggle on Meld 2.0 to @mention someone")));
    };
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classnames("comments", "OLDCSS", { "no-comments": !this.props.comments.size }),
        ref: (node) => this.comments = node
      },
      /* @__PURE__ */ React.createElement(
        Prompt,
        {
          when: !!this.state.data.get("text"),
          message: () => "Are you sure you want to leave? Any unsaved messages will be lost."
        }
      ),
      this.renderSummary(),
      this.renderComments(),
      /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns" }, /* @__PURE__ */ React.createElement(
        "textarea",
        {
          name: "New comment",
          ref: this.textRef,
          className: "new-comment",
          "data-test": "new-comment",
          value: this.state.data.get("translatedText") || this.state.data.get("text"),
          onChange: (event) => this.handleTextChange(event.target.value),
          placeholder: "Enter a new message",
          disabled: this.state.creatingComment || this.state.data.get("isTranslating") || this.state.data.get("translatedTo") === "en"
        }
      ), this.state.data.get("isTranslating") && /* @__PURE__ */ React.createElement(SmallLoader, null), belowTextMessage(), (LinkHelper.getOrgType() === "v" || LinkHelper.getOrgType() === "t") && this.state.show_translation_feature && this.state.default_language !== null && this.state.default_language !== "EN" && isTranslationEnabled() && /* @__PURE__ */ React.createElement(
        "div",
        {
          style: { color: "var(--brand-meld-blue, #1175CC)", cursor: "pointer", marginLeft: "-4px" },
          onClick: this.handleTranslateClick
        },
        /* @__PURE__ */ React.createElement(MdTranslate, null),
        this.state.rateLimitPassed ? "You have used the maximum translations on this Meld" : this.state.data.get("translatedTo") === "en" ? getTranslatedUIPrompt("cancel", this.state.default_language) : getTranslatedUIPrompt("translate to english", this.state.default_language)
      ), LinkHelper.getOrgType() === "m" && Features.isGptChatModifyEnabled() && /* @__PURE__ */ React.createElement(
        MessageModifyComponent,
        {
          text: this.state.data.get("text"),
          handleTextChange: this.handleTextChange,
          meld_id: this.props.meld.get("id")
        }
      ))),
      /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns small-12 comment-action-buttons" }, this.renderTemplateConfig(), /* @__PURE__ */ React.createElement(
        ButtonContainer,
        {
          className: "comment-button-container send-message-container",
          justify: FlexJustifyContent.FlexStart
        },
        /* @__PURE__ */ React.createElement(
          Button,
          {
            buttonType: ButtonTypes.Primary,
            className: "send-message",
            onClick: this.submitMsg,
            disabled: !this.state.data.get("hidden_from_owner") && !this.getOwnersCount() || this.state.creatingComment
          },
          "Send Message to"
        ),
        this.renderVisibilitySelect()
      ))),
      /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "columns dropdown-description" }, this.renderVisibilityDescription())),
      this.renderMode()
    );
  },
  getOwnersCount() {
    const owners = this.props.meld.getIn(["owners"]);
    if (owners) {
      return owners.count();
    }
  },
  renderComments() {
    if (this.props.comments.size === 0) {
      if (CommentStore.isLoading()) {
        return /* @__PURE__ */ React.createElement(SmallLoader, null);
      } else {
        return /* @__PURE__ */ React.createElement("div", { className: "row" }, /* @__PURE__ */ React.createElement("div", { className: "column" }, /* @__PURE__ */ React.createElement("p", null, "No messages.")));
      }
    }
    return this.props.comments.map((comment, i) => {
      return /* @__PURE__ */ React.createElement(
        CommentView,
        {
          comment,
          key: i,
          show_translation_feature: this.state.show_translation_feature,
          default_language: this.state.default_language,
          meld_id: this.props.meld.get("id")
        }
      );
    });
  },
  renderSummary() {
    if (Features.isGptSummaryEnabled() && LinkHelper.getOrgType() === "m" && this.props.comments.size > 3) {
      return /* @__PURE__ */ React.createElement(CommentSummaryView, { meld_id: this.props.meld.get("id"), comments: this.props.comments });
    }
  },
  renderTemplateConfig() {
    if (LinkHelper.getOrgType() === "m") {
      if (this.state.templateMenuOpen) {
        return /* @__PURE__ */ React.createElement("div", { className: "comment-button-container comment-dropdown-container", "data-test": "comment-dropdown-container" }, /* @__PURE__ */ React.createElement(
          CommentTemplateDropdown,
          {
            onSelectTemplate: this.handleTemplateSelected,
            onBlur: this.handleTemplateSelectBlur
          }
        ));
      } else {
        return /* @__PURE__ */ React.createElement(
          ButtonContainer,
          {
            forceInlineButtons: true,
            className: "comment-button-container",
            justify: FlexJustifyContent.FlexStart
          },
          /* @__PURE__ */ React.createElement(
            Button,
            {
              style: { marginTop: "4px" },
              className: "template-menu-btn",
              "data-test": "template-menu-btn",
              onClick: this.handleTemplateMenuButtonClicked
            },
            "Use Template"
          ),
          /* @__PURE__ */ React.createElement(
            DropdownButton,
            {
              className: "template-dropdown",
              renderButton: () => /* @__PURE__ */ React.createElement(OptionsButton, { className: "menu-button", withBorder: true }),
              renderContents: () => /* @__PURE__ */ React.createElement(MenuList, null, /* @__PURE__ */ React.createElement("li", { className: "menu-item" }, /* @__PURE__ */ React.createElement(Link, { to: "/account-settings/templates/", className: "action" }, "Edit Templates")))
            }
          )
        );
      }
    }
  },
  getVisibility() {
    if (this.state.data.get("hidden_from_tenant")) {
      if (this.state.data.get("hidden_from_vendor")) {
        if (!this.state.data.get("hidden_from_owner")) {
          return Visibility.ManagersAndOwnersOnly;
        } else {
          return Visibility.ManagersOnly;
        }
      } else {
        return Visibility.ManagersAndVendorsOnly;
      }
    } else if (this.state.data.get("hidden_from_vendor")) {
      return Visibility.ManagersAndTenantsOnly;
    } else {
      return Visibility.Everyone;
    }
  },
  onVisibilityChange(visibility) {
    if (this.props.pageName && AuthUtils.getActiveOrgType() === "v") {
      track(VendorChatReceiverSelected({ meldId: this.props.meld.get("id"), pageName: this.props.pageName }));
    }
    switch (visibility) {
      case Visibility.Everyone:
        this.mergeData({
          hidden_from_vendor: false,
          hidden_from_tenant: false,
          hidden_from_owner: true
        });
        break;
      case Visibility.ManagersAndVendorsOnly:
        this.mergeData({
          hidden_from_vendor: false,
          hidden_from_tenant: true,
          hidden_from_owner: true
        });
        break;
      case Visibility.ManagersAndTenantsOnly:
        this.mergeData({
          hidden_from_vendor: true,
          hidden_from_tenant: false,
          hidden_from_owner: true
        });
        break;
      case Visibility.ManagersOnly:
        this.mergeData({
          hidden_from_vendor: true,
          hidden_from_tenant: true,
          hidden_from_owner: true
        });
        break;
      case Visibility.ManagersAndOwnersOnly:
        this.mergeData({
          hidden_from_vendor: true,
          hidden_from_tenant: true,
          hidden_from_owner: false
        });
        break;
      default:
        ErrorHandler.handleError(new Error(`Unknown visibility setting ${visibility}`));
    }
  },
  shouldDisableOwnerMessaging() {
    return LinkHelper.getOrgType() === "m" && !hasPerm(C.Perms.CAN_ADD_EDIT_DEACTIVATE_OWNERS) && this.props.meld.getIn(["owners"]).isEmpty();
  },
  renderVisibilitySelect() {
    let orgType = LinkHelper.getOrgType();
    return /* @__PURE__ */ React.createElement(
      CommentVisibilitySelector,
      {
        onVisibilityChange: this.onVisibilityChange,
        selectedVisibility: this.getVisibility(),
        disableOwners: this.shouldDisableOwnerMessaging(),
        showManagers: orgType === "m",
        showOwners: orgType === "m",
        showTenants: ["m", "t"].includes(orgType),
        showVendors: ["m", "v"].includes(orgType)
      }
    );
  },
  renderVisibilityDescription() {
    let meld = this.props.meld;
    let orgType = LinkHelper.getOrgType();
    let entities = I.List();
    let registeredTenants = MeldUtils.getTenants(meld).filter((tenant) => !!tenant.get("user"));
    if (orgType !== "v" && !this.state.data.get("hidden_from_vendor")) {
      let vendor = MeldUtils.getAssignedVendor(meld);
      if (vendor) {
        entities = entities.push(vendor.get("name"));
      } else {
        entities = entities.push("Assigned Vendor");
      }
    }
    if (orgType === "t") {
      let currentId = null;
      if (this.props.tenantSettings) {
        currentId = this.props.tenantSettings.get("id");
      }
      entities = entities.push(meld.getIn(["management", "name"]));
      if (!this.state.data.get("hidden_from_tenant")) {
        entities = entities.concat(
          registeredTenants.filter((tenant) => tenant.get("id") !== currentId).map((tenant) => "Tenant " + TenantUtils.getFullName(tenant))
        ).toList();
      }
    } else {
      if (!this.state.data.get("hidden_from_tenant")) {
        entities = entities.concat(registeredTenants.map((tenant) => "Tenant " + TenantUtils.getFullName(tenant))).toList();
      }
      if (orgType === "m" && !this.state.data.get("hidden_from_owner")) {
        let owners = meld.getIn(["owners"]);
        if (owners.count()) {
          entities = entities.concat(owners.map((owner) => "Owner " + OwnerUtils.getName(owner))).toList();
        } else {
          return /* @__PURE__ */ React.createElement("a", { className: "add-owner", onClick: () => this.setMode("ADD_OWNER" /* ADD_OWNER */) }, "There is no owner at this meld's property. Click here if you wish to add one.");
        }
      }
      entities = entities.push(meld.getIn(["management", "name"]));
    }
    if (entities.size > 2) {
      return /* @__PURE__ */ React.createElement("div", null, "Will be seen by", " ", entities.pop().map((entity, i) => /* @__PURE__ */ React.createElement("span", { key: i }, /* @__PURE__ */ React.createElement("b", null, entity), ",", " ")), "and ", /* @__PURE__ */ React.createElement("b", null, entities.last()));
    } else {
      return /* @__PURE__ */ React.createElement("div", null, "Will be seen by ", /* @__PURE__ */ React.createElement("b", null, entities.first()), " ", entities.size === 2 ? /* @__PURE__ */ React.createElement("span", null, "and ", /* @__PURE__ */ React.createElement("b", null, entities.last())) : "");
    }
  },
  renderMode() {
    if ("ADD_OWNER" /* ADD_OWNER */ === this.state.mode) {
      return /* @__PURE__ */ React.createElement(
        OwnerAddModal,
        {
          propertyId: this.props.meld.getIn(["unit", "prop", "id"]),
          isModalOpen: true,
          close: () => {
            this.setMode("DEFAULT" /* DEFAULT */);
            this.props.refreshMeld();
          }
        }
      );
    }
  },
  setMode(mode) {
    this.setState({ mode });
  },
  submitMsg() {
    if (this.state.data.get("translatedTo") === "en") {
      this.setState(
        {
          data: this.state.data.set("text", this.state.data.get("translatedText")).set("translatedTo", null).set("translatedText", null).set("is_translated", true)
        },
        () => this.createCommentIfTextExists()
      );
    } else {
      this.createCommentIfTextExists();
    }
  },
  createCommentIfTextExists() {
    if (this.state.data.get("text")) {
      this.setState({ creatingComment: true }, () => {
        let meldId = this.props.meld.get("id");
        CommentActions.createComment(this.state.data.merge({ meld: meldId }).toJS(), this.state.cid);
        if (this.props.pageName && AuthUtils.getActiveOrgType() === "v") {
          track(VendorChatSent({ meldId, pageName: this.props.pageName }));
        }
      });
    }
  },
  handleTemplateSelected(template) {
    const text = this.state.data.get("text").concat(template.text, "\n");
    if (template.change_visibility && (template.hidden_from_owner || !this.shouldDisableOwnerMessaging())) {
      this.mergeData({
        hidden_from_vendor: template.hidden_from_vendor,
        hidden_from_tenant: template.hidden_from_tenant,
        hidden_from_owner: template.hidden_from_owner,
        text
      });
    } else {
      this.setVal("text", text);
    }
    this.setState({ templateMenuOpen: false });
  },
  handleTemplateMenuButtonClicked() {
    this.setState({ templateMenuOpen: true });
  },
  handleTemplateSelectBlur() {
    this.setState({ templateMenuOpen: false });
  },
  componentWillMount() {
    return __async(this, null, function* () {
      if (LinkHelper.getOrgType() === "v") {
        const result = yield fetchCurrentVendorAgentNonSWR();
        this.setState({ show_translation_feature: result.default_language !== "EN" });
        this.setState({ default_language: result.default_language });
      }
      if (LinkHelper.getOrgType() === "t") {
        const result = yield fetchCurrentTenantLanguageNonSWR();
        this.setState({ show_translation_feature: result.default_language !== "EN" });
        this.setState({ default_language: result.default_language });
      }
    });
  },
  componentDidUpdate(prevProps, prevState) {
    if (this.comments && prevProps.comments.size !== this.props.comments.size) {
      this.comments.scrollTop = this.comments.scrollHeight - this.comments.getBoundingClientRect().height;
    }
    if (prevProps.comments.size !== this.props.comments.size) {
      if (!this.state.data.get("text")) {
        const lastComment = this.props.comments.last();
        if (lastComment) {
          this.mergeData({
            hidden_from_vendor: lastComment.get("hidden_from_vendor"),
            hidden_from_tenant: lastComment.get("hidden_from_tenant"),
            hidden_from_owner: lastComment.get("hidden_from_owner")
          });
        }
      }
    }
    if (this.state.creatingComment) {
      if (this.didActionSucceed(this.state, Actions.SAVE)) {
        this.setState({
          creatingComment: false,
          data: this.state.data.set("text", "")
        });
      } else if (this.didActionFail(this.state, Actions.SAVE)) {
        this.setState({ creatingComment: false });
      }
    }
    if (this.state.data.get("text") !== prevState.data.get("text")) {
      fitToContent(this.textRef, 500, 68);
    }
  }
});
let WrappedComments = ModelUpdate(Comments);
class CommentsWrapper extends React.Component {
  render() {
    let props = this.props;
    return /* @__PURE__ */ React.createElement(MeldContextProvider, { meldId: this.props.meld.get("id"), meld: props.meld }, /* @__PURE__ */ React.createElement(MeldContext.Consumer, null, (context) => {
      if (!context) {
        return;
      }
      const { meld, loading, actions } = context;
      return /* @__PURE__ */ React.createElement(
        AltContainer,
        {
          stores: {
            comments() {
              return {
                value: CommentStore.getComments().filter((comment) => {
                  return comment.get("meld") === props.meld.get("id");
                }),
                store: CommentStore
              };
            },
            tenantSettings() {
              return {
                value: TenantSettingsStore.getCurrent(),
                store: TenantSettingsStore
              };
            }
          }
        },
        /* @__PURE__ */ React.createElement(
          WrappedComments,
          __spreadProps(__spreadValues({}, this.props), {
            meld: meld || this.props.meld,
            loading,
            refreshMeld: actions.refreshMeld
          })
        )
      );
    }));
  }
  fetchComments() {
    this.props.callAndSetInterval(() => {
      CommentStore.fetchComments({
        params: {
          meld: this.props.meld.get("id")
        }
      });
    }, 6e4);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.meld.get("id") !== this.props.meld.get("id")) {
      this.props.clearIntervals();
      this.fetchComments();
    }
  }
  componentWillMount() {
    if (LinkHelper.getOrgType() === "t") {
      TenantSettingsStore.fetchCurrent();
    }
    this.fetchComments();
  }
}
export default SetIntervalWhileActive(CommentsWrapper);
