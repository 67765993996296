import alt from "../../alt";
import TenantMeldFileApi from "../apis/tenant-meld-file-api";

class TenantMeldFileActions {
  constructor() {
    this.generateActions(
      "fetchFiles",
      "fetchFilesDone",
      "fetchFilesFail",
      "createFileDone",
      "createFileFail",
      "deleteFileDone",
      "deleteFileFail"
    );
  }

  createFile(data, meldId, cid) {
    TenantMeldFileApi.create(data, meldId)
      .then(result => {
        this.createFileDone({ data: result, cid });
      })
      .catch(result => {
        this.createFileFail({ data: result, cid });
      });

    return { cid };
  }

  deleteFile(fileId, cid) {
    TenantMeldFileApi.deleteFile(fileId)
      .then(result => {
        this.deleteFileDone({ id: fileId, cid });
      })
      .catch(result => {
        this.deleteFileFail({ data: result, cid });
      });

    return { cid };
  }
}

export default alt.createActions(TenantMeldFileActions);
