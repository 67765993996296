import ownerAPI from "../apis/owner-api";
import React from "react";
import {
  containerStateForChildren,
  defaultSaveContainerState,
  loadingFailed,
  loadingSucceeded,
  startLoading
} from "../../common/utils/container-utils";

export default function withSaveOwnerProperties(Comp) {
  return class WithSaveOwnerProperties extends React.Component {
    constructor(props) {
      super(props);

      this.handleUpdate = this.handleUpdate.bind(this);
      this.handleSave = this.handleSave.bind(this);
      this.handleAddProperty = this.handleAddProperty.bind(this);

      this.state = defaultSaveContainerState();
    }

    render() {
      return (
        <Comp
          {...this.props}
          {...containerStateForChildren(this)}
          onUpdateOwnerProperties={this.handleUpdate}
          handleAddOwnerProperty={this.handleAddProperty}
          onOwnerSave={this.handleSave}
        />
      );
    }

    handleSave(data) {
      startLoading.call(this);

      ownerAPI
        .saveOwner(data)
        .then(res => {
          loadingSucceeded.call(this, res);
          if (this.props.onOwnerCreated) {
            this.props.onOwnerCreated();
          }
        })
        .catch(loadingFailed.bind(this));
    }

    handleUpdate(ownerId, propertyIds) {
      startLoading.call(this);

      ownerAPI
        .updateProperties(ownerId, propertyIds)
        .then(res => {
          loadingSucceeded.call(this, res);
          if (this.props.onOwnerPropertiesUpdated) {
            this.props.onOwnerPropertiesUpdated();
          }
        })
        .catch(loadingFailed.bind(this));
    }

    handleAddProperty(ownerId, propertyId) {
      ownerAPI
        .addProperty(ownerId, propertyId)
        .then(res => {
          loadingSucceeded.call(this, res);
          if (this.props.onOwnerPropertiesUpdated) {
            this.props.onOwnerPropertiesUpdated();
          }
        })
        .catch(loadingFailed.bind(this));
    }
  };
}
