import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Flex,
  Text,
  useToast,
  ModalFooter,
  Select,
  Box,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { MdTranslate } from "react-icons/md";

type LanguageOption = "EN" | "ES" | "ZH" | "KO" | "PT" | "FR" | "RU" | "JA";

interface LanguageSelectModalProps {
  tenant: boolean;
  updateLanguageSettings: Function;
  userDetails: any;
}
const languageMap = {
  EN: "English",
  ES: "Spanish",
  ZH: "Chinese",
  KO: "Korean",
  PT: "Portuguese",
  FR: "French",
  RU: "Russian",
  JA: "Japanese",
};

export const LanguageSelectModal = ({ userDetails, updateLanguageSettings, tenant }: LanguageSelectModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageOption>("EN");
  const [languageSet, setLanguageSet] = useState<boolean>(false);
  const toast = useToast();
  const history = useHistory();

  useEffect(() => {
    if (userDetails && !localStorage.getItem(`languageSet_${userDetails.id}`)) {
      if (!userDetails.default_language) {
        if (!userDetails.prompt_for_mobile) {
          setIsOpen(true);
        }
      }
    }
  }, [userDetails]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleNavigateToSettings = () => {
    const multiTenancy = location.pathname.split("/");
    const meldRoutePrefix = `/${multiTenancy[1]}/${multiTenancy[2]}/${multiTenancy[3]}`;
    history.push(`${meldRoutePrefix}/account-settings/language/`);
    handleClose();
  };

  const handleSuccess = () => {
    localStorage.setItem(`languageSet_${userDetails.id}`, "true");
    setLanguageSet(true);
  };

  const handleError = () => {
    toast({
      title: "Error",
      description: "There was an error setting your language preference. Please contact support.",
      status: "error",
      position: "top",
      duration: 5000,
      isClosable: true,
    });
    handleClose();
  };

  const updateSettingsWithLanguage = async (language: LanguageOption) => {
    setSubmitting(true);
    try {
      if (tenant) {
        await updateLanguageSettings(language);
      } else {
        const updatedSettings = { ...userDetails!, default_language: language };
        await updateLanguageSettings(updatedSettings);
      }
      handleSuccess();
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false} data-test="language-modal">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex align="center">
            <MdTranslate style={{ color: "var(--brand-meld-blue, #1175CC)" }} />
            <Text marginLeft={4} data-test="header-text">
              {languageSet ? "Language Set!" : "Preferred Language"}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody mt={-6}>
          {!languageSet && (
            <>
              <Text data-test="modal-body-text" fontSize={16}>
                Property Meld offers some features for other languages. Please confirm your preferred language.
              </Text>
              <Select
                placeholder="Select language"
                value={selectedLanguage || ""}
                onChange={(e) => setSelectedLanguage(e.target.value as LanguageOption)}
                isDisabled={submitting}
                marginTop={4}
                data-test="language-select"
              >
                {Object.entries(languageMap).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Select>
            </>
          )}
          {languageSet && (
            <Text data-test="modal-body-text" fontSize={16}>
              {selectedLanguage &&
                `Your preferred language for chat communication has been set to ${languageMap[selectedLanguage]}.`}
            </Text>
          )}
        </ModalBody>
        <ModalFooter>
          <Box display="flex" justifyContent="flex-end">
            {languageSet && (
              <>
                <Button
                  colorScheme="blue"
                  cursor="pointer"
                  onClick={handleNavigateToSettings}
                  mr={3}
                  data-test="go-to-settings-button"
                >
                  Go to Settings
                </Button>
                <Button
                  variant="ghost"
                  onClick={handleClose}
                  data-test="close-button"
                  colorScheme="blue"
                  cursor={"pointer"}
                >
                  Close
                </Button>
              </>
            )}
            {!languageSet && (
              <>
                <Button
                  colorScheme="blue"
                  isLoading={submitting}
                  onClick={() => selectedLanguage && updateSettingsWithLanguage(selectedLanguage)}
                  data-test="confirm-button"
                  mr={3}
                  cursor={"pointer"}
                  disabled={!selectedLanguage}
                >
                  Confirm
                </Button>
              </>
            )}
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
