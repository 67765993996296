import alt from "../../alt";

import AgentDetailAPI from "../apis/agent-detail-api";
import messageActions from "../../common/actions/message-actions";
// import AgentAPI from "../../management/apis/agent-api";

class AgentDetailActions {
  constructor() {
    this.generateActions("fetchCurrent", "fetchCurrentDone", "fetchCurrentFail", "updateDone", "updateFail");
  }

  update(data, { cid } = {}) {
    AgentDetailAPI.update(data)
      .then((res) => {
        this.updateDone({ data: res.data, cid });
      })
      .catch((res) => {
        if (res.messages.length) {
          messageActions.storeError({ body: res.messages[0] });
        }
        this.updateFail({ data: res, cid });
      });

    return { cid };
  }
}

export default alt.createActions(AgentDetailActions);
