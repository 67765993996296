import meldActions from "../actions/meld-actions";
import MeldAPI from "../apis/meld-api";

let MeldSource = {
  fetchMelds() {
    return {
      remote(state, { cid, xhrOptions = {} } = {}) {
        return MeldAPI.getAll(xhrOptions);
      },

      shouldFetch(state, force) {
        return true;
      },

      success: meldActions.fetchMeldsDone,
      error: meldActions.fetchMeldsFail
    };
  },

  fetchMeld() {
    return {
      remote(state, id) {
        return MeldAPI.getOne(id);
      },

      shouldFetch(state, id, force) {
        return true;
      },

      success: meldActions.fetchMeldDone,
      error: meldActions.fetchMeldFail
    };
  },

  fetchMeldsByUnit() {
    return {
      remote(state, unitId) {
        return MeldAPI.fetchMeldsByUnit(unitId);
      },

      shouldFetch(state, id, force) {
        return true;
      },

      success: meldActions.fetchMeldsByUnitDone,
      error: meldActions.fetchMeldsByUnitFail
    };
  }
};

export default MeldSource;
