import React from "react";

export class EmbeddedErrorView extends React.Component {
  render() {
    return (
      <div className="embedded-error">
        <h1>Oops...</h1>
        <div>We could not complete your request.</div>
        <div>
          <a onClick={() => window.location.reload()}>Try refreshing the page.</a>
        </div>
      </div>
    );
  }
}
