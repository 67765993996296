import React from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiText,
} from "@elastic/eui";

import { colors } from "@pm-frontend/styles";
import { PmEmptyButton } from "../Buttons/PmEmptyButton";
import { PmFilledButton } from "../Buttons/PmFilledButton";

interface PmConfirmModalProps {
  title?: string;
  body?: string | string[] | JSX.Element | JSX.Element[];
  primaryButtonText: string;
  primaryButtonOnClick: () => void;
  primaryButtonLoading: boolean;
  primaryButtonColor?: "primary" | "danger";
  secondaryButtonText?: string;
  closeModal: () => void;
  confirmButtonDataTestId?: string;
  hideConfirmButton?: boolean;
  disablePrimaryButton?: boolean;
}

const parseBody = (body: Required<PmConfirmModalProps>["body"]) => {
  if (!Array.isArray(body)) {
    if (typeof body === "string") {
      return <EuiText style={{ color: colors.neutrals.gray800, fontSize: "16px", padding: "0px" }}>{body}</EuiText>;
    } else {
      return body;
    }
  } else {
    // add <br/> between elements
    const result: JSX.Element[] = [];
    body.forEach((line: string | JSX.Element, index: number) => {
      if (typeof line === "string") {
        result.push(
          <EuiText key={line + index} style={{ color: colors.neutrals.gray800, fontSize: "16px", padding: "0px" }}>
            {line}
          </EuiText>
        );
      } else {
        result.push(line);
      }
      if (index < body.length - 1) {
        result.push(<br key={index} />);
      }
    });
    return result;
  }
};

const PmConfirmModal = ({
  closeModal,
  title,
  body,
  primaryButtonText,
  primaryButtonOnClick,
  primaryButtonLoading,
  primaryButtonColor = "primary",
  secondaryButtonText = "Cancel",
  confirmButtonDataTestId,
  hideConfirmButton = false,
  disablePrimaryButton = false,
}: PmConfirmModalProps) => {
  return (
    <EuiModal
      maxWidth="448px"
      className="pmConfirmModal"
      onClose={closeModal}
      style={{ padding: "24px", gap: "16px", position: "relative" }}
    >
      {title ? (
        <EuiModalHeader style={{ padding: "0px" }}>
          <EuiText color={colors.neutrals.gray900} style={{ fontSize: "24px", fontWeight: "700" }}>
            {title}
          </EuiText>
        </EuiModalHeader>
      ) : null}
      {body ? <EuiModalBody>{parseBody(body)}</EuiModalBody> : null}
      <EuiModalFooter style={{ padding: "0px" }}>
        <EuiFlexGroup direction="row" alignItems="flexStart" style={{ gap: "16px" }}>
          {!hideConfirmButton && (
            <EuiFlexItem grow={false}>
              <PmFilledButton
                color={primaryButtonColor}
                padding="10px 12px"
                onClick={primaryButtonOnClick}
                isLoading={primaryButtonLoading}
                {...(confirmButtonDataTestId ? { "data-testid": confirmButtonDataTestId } : {})}
                text={primaryButtonText}
                isEnabled={!disablePrimaryButton}
              />
            </EuiFlexItem>
          )}
          <EuiFlexItem grow={false}>
            <PmEmptyButton onClick={closeModal} text={secondaryButtonText} padding="9.5px 8px" />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalFooter>
    </EuiModal>
  );
};
export { PmConfirmModal, PmConfirmModalProps };
