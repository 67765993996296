import React from "react";

/*tslint:disable:max-line-length */
export default class ClockSVG extends React.Component {
  render() {
    return (
      <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512.021 512.021" className="clock-svg">
        <path d="M256.011 0c-22.123 0-43.499 3.093-64 8.427v44.096c20.224-6.379 41.707-9.835 64-9.835 117.653 0 213.333 95.701 213.333 213.333 0 117.653-95.68 213.333-213.333 213.333-117.632 0-213.333-95.68-213.333-213.333 0-59.499 24.576-113.195 64-151.893v66.539h42.667V42.688h-128v42.667h44.352C24.971 130.645.011 190.421.011 256.021c0 141.163 114.859 256 256 256 141.163 0 256-114.837 256-256S397.173 0 256.011 0z" />
        <path d="M234.677 106.667v158.186l91.584 91.606 30.166-30.166-79.083-79.104V106.667z" />
      </svg>
    );
  }
}
/*tslint:enable:max-line-length */
