import React from "react";
import { ImmutableMap } from "../../types/common";
import DeleteFilesModal from "@pm-app/components/modal/delete-files-modal";
import Trash from "../../svg/trash-svg";

interface Props {
  file: ImmutableMap;
  onDelete: (file: ImmutableMap) => void;
}

interface State {
  shouldDelete: boolean;
}

export default class FileViewerDeleteFile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      shouldDelete: false
    };
  }

  render() {
    return (
      <React.Fragment>
        <a
          className="file-viewer-delete-file file-action"
          title="Delete"
          onClick={() => this.setState({ shouldDelete: true })}
        >
          <Trash />
        </a>
        <DeleteFilesModal
          files={[this.props.file.toJS()]}
          isOpen={this.state.shouldDelete}
          onClose={() => {
            this.setState({ shouldDelete: false });
          }}
          onDelete={this.props.onDelete.bind(this, this.props.file)}
        />
      </React.Fragment>
    );
  }
}
