var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { EuiBadge, EuiText } from "@elastic/eui";
import URL from "@pm-shared/utils/url";
const styles = {
  padding: "1px 8px",
  width: "fit-content"
};
const PmBadge = ({
  text,
  bgColor,
  textColor,
  textSize = "xs",
  "data-testid": dataTestId,
  iconOnClick,
  iconOnClickAriaLabel = ""
}) => {
  const dataTestProp = dataTestId ? { "data-testid": dataTestId } : {};
  const body = /* @__PURE__ */ React.createElement(EuiText, { size: textSize, color: textColor, css: { fontWeight: 600 } }, text);
  if (iconOnClick && iconOnClickAriaLabel) {
    return /* @__PURE__ */ React.createElement(
      EuiBadge,
      __spreadProps(__spreadValues({
        color: bgColor,
        style: styles
      }, dataTestProp), {
        iconOnClick: iconOnClick || void 0,
        iconOnClickAriaLabel,
        iconType: URL.getStatic("icons/close_white.svg"),
        iconSide: "right"
      }),
      body
    );
  }
  return /* @__PURE__ */ React.createElement(EuiBadge, __spreadValues({ color: bgColor, style: styles }, dataTestProp), body);
};
export { PmBadge };
