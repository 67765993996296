import React from "react";
import classnames from "classnames";
import { Link as ReactRouterLink } from "react-router-dom";
import { ButtonTypes } from "../forms/button/button";

import { History } from "history";

interface Props {
  /**
   * ReactRouter-friendly path.
   */
  path?: History.LocationDescriptor;
  /**
   * Function to call when link is clicked.
   */
  onLinkClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  /**
   * Is the link disabled?
   * @default false
   */
  inactive?: boolean;
  /**
   * You can style a link as a button by passing
   * in a valid ButtonType.
   */
  buttonStyle?: ButtonTypes;
  /**
   * Extra classes to append to the underlying link.
   */
  className?: string;
}

export const Link: React.FC<Props> = ({ path, onLinkClick, inactive, buttonStyle, className, children }) => {
  let classes: string;

  if (buttonStyle) {
    classes = classnames(
      "pm-forms-button",
      `pm-forms-button-${buttonStyle}`,
      {
        "pm-link-inactive": inactive
      },
      className ? className.split(" ") : ""
    );
  } else {
    classes = classnames(
      "pm-link",
      {
        "pm-link-inactive": inactive
      },
      className ? className.split(" ") : ""
    );
  }

  if (inactive) {
    return <div className={classes}>{children}</div>;
  }

  if (path) {
    return (
      <ReactRouterLink to={path} className={classes}>
        {children}
      </ReactRouterLink>
    );
  }

  return (
    <a href="#" onClick={onLinkClick} className={classes}>
      {children}
    </a>
  );
};

Link.displayName = "Link";

export default Link;
