import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

class UserAPI extends BaseApi {
  static update(user: { id: number }) {
    return this.put(ApiUrls.userDetail(user.id), user);
  }

  static fetchCurrentUser() {
    return this.get(ApiUrls.currentUser);
  }

  static getAvailability(email: string) {
    return this.get(ApiUrls.userAvailability, {
      params: { email },
      normalizeUrl: false
    });
  }
}

export default UserAPI;
