var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import createReactClass from "create-react-class";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { LargeLoader } from "../../loaders";
let StoreActivityMonitor = createReactClass({
  propTypes: {
    stores: PropTypes.array.isRequired,
    loader: PropTypes.func,
    children: PropTypes.object.isRequired,
    renderChildrenWithFailure: PropTypes.bool,
    errorMessage: PropTypes.string.isRequired
  },
  getDefaultProps() {
    return {
      loader: LargeLoader,
      renderChildrenWithFailure: false,
      stores: [],
      errorMessage: "There was a problem loading your data."
    };
  },
  render() {
    let props = Object.assign({}, _.omit(this.props, "children"));
    for (let store of this.props.stores) {
      if (store.isLoading()) {
        return React.createElement(this.props.loader);
      }
      let storeFailure = store.didFail();
      if (storeFailure && !this.props.renderChildrenWithFailure) {
        if (this.props.errorPage) {
          return this.props.errorPage;
        } else {
          return /* @__PURE__ */ React.createElement("div", { className: "error" }, this.props.errorMessage);
        }
      } else if (storeFailure) {
        props.storeFailure = true;
      }
    }
    return /* @__PURE__ */ React.createElement("span", null, React.cloneElement(this.props.children, __spreadValues({}, props)));
  }
});
export default StoreActivityMonitor;
