var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import URL from "@pm-shared/utils/url";
class SmallLoader extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement("img", { src: URL.getStatic("static/img/loader@03x.gif"), className: "loader", alt: this.props.alt });
  }
}
class LargeLoader extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement("img", { src: URL.getStatic("static/img/loader.gif"), className: "loader", alt: this.props.alt });
  }
}
class FloatingLargeLoader extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement("div", { className: "floating-loader" }, /* @__PURE__ */ React.createElement(LargeLoader, __spreadValues({}, this.props)));
  }
}
export { SmallLoader, LargeLoader, FloatingLargeLoader };
