import React from "react";

/* tslint:disable:max-line-length */
class RentManager extends React.Component<{ title?: string }> {
  render() {
    return (
      <svg id="Layer_1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 93.1 92.9" className="rent-manager-svg">
        <path
          className="st0"
          d="M44.4 7.1l34 22.9h10.4L73.6 19.7V9H57.8L44.4 0 31 9H15.2v10.7L0 30h10.4"
          fill="#3978bd"
        />
        <path
          className="st1"
          d="M55.9 73.7c-.1-.3-.2-.7-.2-1.1v-4.4c0-1.6 1.3-2.9 2.9-2.9H63c1.6 0 2.9 1.3 2.9 2.9v.4c2.7-1.6 5.4-3.3 7.8-5v-33H15.2v48c7.9 4.4 23.9 2.7 40.7-4.9m-.2-34.6c0-1.6 1.3-2.9 2.9-2.9H63c1.6 0 2.9 1.3 2.9 2.9v4.4c0 1.6-1.3 2.9-2.9 2.9h-4.4c-1.6 0-2.9-1.3-2.9-2.9v-4.4zm0 14.6c0-1.6 1.3-2.9 2.9-2.9H63c1.6 0 2.9 1.3 2.9 2.9v4.4c0 1.6-1.3 2.9-2.9 2.9h-4.4c-1.6 0-2.9-1.3-2.9-2.9v-4.4zM39.3 39.1c0-1.6 1.3-2.9 2.9-2.9h4.4c1.6 0 2.9 1.3 2.9 2.9v4.4c0 1.6-1.3 2.9-2.9 2.9h-4.4c-1.6 0-2.9-1.3-2.9-2.9v-4.4zm0 14.6c0-1.6 1.3-2.9 2.9-2.9h4.4c1.6 0 2.9 1.3 2.9 2.9v4.4c0 1.6-1.3 2.9-2.9 2.9h-4.4c-1.6 0-2.9-1.3-2.9-2.9v-4.4zm0 14.5c0-1.6 1.3-2.9 2.9-2.9h4.4c1.6 0 2.9 1.3 2.9 2.9v4.4c0 1.6-1.3 2.9-2.9 2.9h-4.4c-1.6 0-2.9-1.3-2.9-2.9v-4.4zM23 39.1c0-1.6 1.3-2.9 2.9-2.9h4.4c1.6 0 2.9 1.3 2.9 2.9v4.4c0 1.6-1.3 2.9-2.9 2.9h-4.4c-1.6 0-2.9-1.3-2.9-2.9v-4.4zm0 14.6c0-1.6 1.3-2.9 2.9-2.9h4.4c1.6 0 2.9 1.3 2.9 2.9v4.4c0 1.6-1.3 2.9-2.9 2.9h-4.4c-1.6 0-2.9-1.3-2.9-2.9v-4.4zm0 14.5c0-1.6 1.3-2.9 2.9-2.9h4.4c1.6 0 2.9 1.3 2.9 2.9v4.4c0 1.6-1.3 2.9-2.9 2.9h-4.4c-1.6 0-2.9-1.3-2.9-2.9v-4.4z"
          fill="#f58220"
        />
        <path
          className="st0"
          d="M60.4 74.4C37.7 86.2 15.2 87.8 10 77.9c-1.5-2.8-1.3-6.2.1-10-5 6.8-6.9 13.4-4.4 18.1 5.4 10.4 29.1 8.7 52.9-3.7 22.5-11.8 37.1-28.9 34-39.5-2.3 9.8-14.7 22.4-32.2 31.6"
          fill="#3978bd"
        />
        <title>{this.props.title}</title>
      </svg>
    );
  }
}

/* tslint:enable:max-line-length */

export default RentManager;
