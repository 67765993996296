var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import classnames from "classnames";
import React from "react";
import { Keys } from "../../utils/key-utils";
export default class SearchSelect extends React.Component {
  constructor(options) {
    super(options);
    this.state = {
      inputFocused: false,
      currentValue: this.props.defaultValue,
      focusedItem: null
    };
    this.handleInputClicked = this.handleInputClicked.bind(this);
    this.handleInputBlurred = this.handleInputBlurred.bind(this);
    this.handleItemClicked = this.handleItemClicked.bind(this);
    this.handleTextEntered = this.handleTextEntered.bind(this);
    this.handleInputFocused = this.handleInputFocused.bind(this);
    this.handleItemMouseOver = this.handleItemMouseOver.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }
  render() {
    let { inputFocused, currentValue } = this.state;
    let { activeItem, data } = this.props;
    let inputStyles = {};
    const showResults = inputFocused;
    if (!inputFocused && activeItem) {
      inputStyles.left = -1e4;
      inputStyles.position = "absolute";
    }
    const inputWrapperClasses = classnames({
      single: true,
      "select-input": true,
      "select-input-item-selected": !inputFocused && activeItem
    });
    const searchSelectClasses = classnames({
      "search-select": true,
      "show-results": showResults,
      "has-results": data.length > 0
    });
    return /* @__PURE__ */ React.createElement("div", { className: searchSelectClasses, onBlur: this.handleInputBlurred, onFocus: this.handleInputFocused }, /* @__PURE__ */ React.createElement("div", { className: inputWrapperClasses, onClick: this.handleInputClicked }, this.renderSelectedItem(), /* @__PURE__ */ React.createElement(
      "input",
      {
        type: "text",
        ref: (node) => this.input = node,
        style: inputStyles,
        value: currentValue,
        onChange: this.handleTextEntered,
        onKeyDown: this.handleKeyDown
      }
    )), this.renderResultList());
  }
  renderSelectedItem() {
    let { displayComponent: DisplayComponent, displayKey, activeItem } = this.props;
    if (DisplayComponent) {
      return /* @__PURE__ */ React.createElement("div", { className: "selected-item" }, /* @__PURE__ */ React.createElement(DisplayComponent, { item: activeItem }));
    } else if (displayKey) {
      return /* @__PURE__ */ React.createElement("div", { className: "selected-item" }, activeItem ? activeItem[displayKey] : "");
    }
  }
  renderResultList() {
    let { displayComponent: DisplayComponent, displayKey, data } = this.props;
    if (data.length === 0) {
      return null;
    }
    let items = data.map((item, i) => {
      let extraProps = {};
      let renderedItem;
      const isFocused = this.state.focusedItem === item;
      let classes = classnames({
        "result-list-item": true,
        "focused-item": isFocused
      });
      if (isFocused) {
        extraProps.ref = (node) => this.focusedItem = node;
      }
      if (DisplayComponent) {
        renderedItem = /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(DisplayComponent, { item }));
      } else if (displayKey) {
        renderedItem = /* @__PURE__ */ React.createElement("div", null, item[displayKey]);
      }
      return /* @__PURE__ */ React.createElement(
        "li",
        __spreadValues({
          key: i,
          onMouseDown: this.handleItemClicked.bind(this, item),
          className: classes,
          onMouseOver: this.handleItemMouseOver.bind(this, item)
        }, extraProps),
        renderedItem
      );
    });
    return /* @__PURE__ */ React.createElement("ul", { className: "result-list", ref: (node) => this.resultList = node }, items);
  }
  handleItemMouseOver(item) {
    this.setState({ focusedItem: item });
  }
  handleItemClicked(item, e) {
    this.selectItem(item);
    e.preventDefault();
  }
  handleInputClicked() {
    this.handleInputFocused();
  }
  handleInputBlurred() {
    this.setState({ inputFocused: false });
  }
  handleTextEntered(e) {
    let { allowNew } = this.props;
    if (this.state.inputFocused) {
      if (allowNew && this.props.onItemUpdated) {
        this.props.onItemUpdated(null);
      }
      this.setState({ currentValue: e.target.value });
      if (this.props.onSearch) {
        this.props.onSearch(e.target.value);
      }
    }
  }
  handleKeyDown(e) {
    let { data } = this.props;
    let { inputFocused } = this.state;
    let focusedItemIndex = data.findIndex((item) => item === this.state.focusedItem);
    if (inputFocused && focusedItemIndex !== -1) {
      if (e.keyCode === Keys.KEY_DOWN) {
        if (focusedItemIndex !== data.length - 1) {
          this.setState({ focusedItem: data[focusedItemIndex + 1] });
        }
        e.preventDefault();
      } else if (e.keyCode === Keys.KEY_UP) {
        if (focusedItemIndex !== 0) {
          this.setState({ focusedItem: data[focusedItemIndex - 1] });
        }
        e.preventDefault();
      } else if (e.keyCode === Keys.ENTER) {
        this.selectItem(data[focusedItemIndex]);
        e.preventDefault();
      } else if (e.keyCode === Keys.ESCAPE) {
        this.setState({ inputFocused: false });
      }
    }
  }
  selectItem(item) {
    if (this.props.onItemUpdated) {
      this.props.onItemUpdated(item);
    }
    this.setState({ inputFocused: false });
  }
  handleInputFocused() {
    let { data } = this.props;
    this.setState({
      inputFocused: true,
      focusedItem: data ? data[0] : null
    });
  }
  componentDidUpdate() {
    if (this.state.inputFocused) {
      this.input.focus();
      if (this.focusedItem) {
        const resultRect = this.resultList.getBoundingClientRect();
        const focusedItemRect = this.focusedItem.getBoundingClientRect();
        if (focusedItemRect.bottom > resultRect.bottom) {
          this.resultList.scrollTop = this.resultList.scrollTop + this.focusedItem.clientHeight;
        } else if (focusedItemRect.top < resultRect.top) {
          this.resultList.scrollTop = this.resultList.scrollTop - this.focusedItem.clientHeight;
        }
      }
    } else {
      this.input.blur();
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({ focusedItem: nextProps.data[0] });
    }
  }
}
SearchSelect.defaultProps = {
  data: [],
  allowNew: true
};
