import React from "react";
import { colors } from "@pm-frontend/styles";

export const SidebarInsightsIcon = ({ isActive = false }: { isActive?: boolean }) => {
  const fill = isActive ? colors.brand.meldBlue : colors.neutrals.gray800;
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1378_22181)">
        <path
          d="M13.9998 5.33325C13.0332 5.33325 12.4932 6.29325 12.7132 7.00659L10.3465 9.37992C10.1465 9.31992 9.85317 9.31992 9.65317 9.37992L7.95317 7.67992C8.17984 6.96659 7.63984 5.99992 6.6665 5.99992C5.69984 5.99992 5.15317 6.95992 5.37984 7.67992L2.33984 10.7133C1.6265 10.4933 0.666504 11.0333 0.666504 11.9999C0.666504 12.7333 1.2665 13.3333 1.99984 13.3333C2.9665 13.3333 3.5065 12.3733 3.2865 11.6599L6.31984 8.61992C6.51984 8.67992 6.81317 8.67992 7.01317 8.61992L8.71317 10.3199C8.4865 11.0333 9.0265 11.9999 9.99984 11.9999C10.9665 11.9999 11.5132 11.0399 11.2865 10.3199L13.6598 7.95325C14.3732 8.17325 15.3332 7.63325 15.3332 6.66659C15.3332 5.93325 14.7332 5.33325 13.9998 5.33325Z"
          fill={fill}
        />
        <path d="M10 6L10.6267 4.62L12 4L10.6267 3.38L10 2L9.38667 3.38L8 4L9.38667 4.62L10 6Z" fill={fill} />
        <path
          d="M2.33317 7.33333L2.6665 6L3.99984 5.66667L2.6665 5.33333L2.33317 4L1.99984 5.33333L0.666504 5.66667L1.99984 6L2.33317 7.33333Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1378_22181">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
