import HistoryUtils from "../common/utils/history-utils";
import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";
import URL from "../../../web/shared/utils/url";
import { LargeLoader, SmallLoader } from "../loaders";
import { Actions, StatusStates } from "../stores/base-store";

let Status = {
  propStpyes: {
    loadingStore: PropTypes.object.isRequired,
  },

  getInitialState() {
    return {};
  },

  isLoading() {
    let status = this.getStatus();
    return (status && status.get("statusState") === StatusStates.PENDING) || this._getLoadingStore().isLoading();
  },

  didFail() {
    let status = this.getStatus();
    return status && status.get("statusState") === StatusStates.FAIL;
  },

  renderFail() {
    let FailComponent = this.props.failComponent;
    if (FailComponent) {
      return <FailComponent>{this.renderDefaultFail()}</FailComponent>;
    }
    return this.renderDefaultFail();
  },

  renderDefaultFail() {
    return (
      <div className="_500">
        <img src={URL.getStatic("img/_500.png")} />
        <p>Looks like an error. We're working on fixing it.</p>
      </div>
    );
  },

  // TODO - Deprecated
  isStoreActivityFinished(newStatus) {
    let status = newStatus || this.getStatus();
    return status && status.get("statusState") === StatusStates.DONE;
  },

  didRequestSucceed() {
    let status = this.getStatus();
    return status && status.get("statusState") === StatusStates.DONE;
  },

  didDeleteSucceed(status) {
    let statusToCheck = status || this.getStatus();
    return (
      statusToCheck &&
      statusToCheck.get("statusState") === StatusStates.DONE &&
      statusToCheck.get("action") === Actions.DELETE
    );
  },

  didActionSucceed(nextState, action) {
    let status = this.getStatus(nextState);

    if (status) {
      return status.get("statusState") === StatusStates.DONE && status.get("action") === action;
    }

    return false;
  },

  didActionFail(nextState, action) {
    let status = this.getStatus(nextState);

    if (status) {
      return status.get("statusState") === StatusStates.FAIL && status.get("action") === action;
    }

    return false;
  },

  componentWillMount() {
    let loadingStore = this._getLoadingStore();

    if (loadingStore) {
      loadingStore.listen(this._storeChanged);
    }
  },

  componentWillUnmount() {
    let loadingStore = this._getLoadingStore();

    if (loadingStore) {
      loadingStore.unlisten(this._storeChanged);
    }
  },

  getStatus() {
    let loadingStore = this.loadingStore || this.props.loadingStore;

    if (!loadingStore) {
      throw new Error(`No loading store defined for: ${this.constructor.displayName}`);
    }

    return loadingStore.getStatus(this._getCid());
  },

  redirectIfDeleted({ state, redirectLocation }) {
    if (this.didDeleteSucceed(this.getStatus(state))) {
      HistoryUtils.replace(this.props.history, redirectLocation);
    }
  },

  _storeChanged() {
    let status = this._getLoadingStore().getStatus(this._getCid());

    if (status.size) {
      this.setState({ status });
    }
  },

  _getLoadingStore() {
    return this.loadingStore || this.props.loadingStore;
  },

  _getCid() {
    return this.state.cid || this.props.cid;
  },
};

export let Loaders = {
  LARGE: "LARGE",
  SMALL: "SMALL",
};

export let Substates = createReactClass({
  mixins: [Status],

  propTypes: {
    loader: PropTypes.string,
  },

  render() {
    let loader = this.props.loader;

    if (this.isLoading()) {
      if (!loader || loader === Loaders.LARGE) {
        return <LargeLoader />;
      } else if (loader === Loaders.SMALL) {
        return <SmallLoader />;
      }
    }

    if (this.didFail()) {
      return this.renderFail();
    }

    return this.props.children;
  },
});

export default Status;
