declare global {
  interface Window {
    asknicelySettings: any;
    askNicelyConversation: any;
  }
}

export enum Segment {
  INAPP = "inapp",
  INAPP_VENDOR = "inapp-vendor"
}

const NPS = {
  survey(segment: Segment) {
    const asknicelySettings = window.asknicelySettings;

    if (asknicelySettings && window.askNicelyConversation) {
      try {
        window.askNicelyConversation({
          customData: {...window.PM.npsCustomData, segment},
          config: asknicelySettings
        });
      } catch (error) {
        // Not interested in the errors ask nicely throws after the daily limit is reached
      }
    }
  }
};

export { NPS };
