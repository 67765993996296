import React from "react";
import { colors } from "@pm-frontend/styles";

export const SidebarReportsIcon = ({ isActive = false }: { isActive?: boolean }) => {
  const fill = isActive ? colors.brand.meldBlue : colors.neutrals.gray800;
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2202_276768)">
        <path
          d="M5.33464 10.6654H10.668V11.9987H5.33464V10.6654ZM5.33464 7.9987H10.668V9.33203H5.33464V7.9987ZM9.33464 1.33203H4.0013C3.26797 1.33203 2.66797 1.93203 2.66797 2.66536V13.332C2.66797 14.0654 3.2613 14.6654 3.99464 14.6654H12.0013C12.7346 14.6654 13.3346 14.0654 13.3346 13.332V5.33203L9.33464 1.33203ZM12.0013 13.332H4.0013V2.66536H8.66797V5.9987H12.0013V13.332Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2202_276768">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
