import React from "react";

/* tslint:disable:max-line-length */
export default class Propertyware extends React.Component<{}> {
  render() {
    return (
      <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 321.2 295.8" className="propertyware-svg">
        <path
          d="M157.9 5c27.2.1 54.4 0 81.5.1 2.7 0 5.4 0 8 .3 8.2.7 17.4 1 25.2 3.8 7.2 2.4 14.1 6.3 20.2 10.8 12.6 9.8 20.6 24.6 22.8 40.3 1.5 14.6-.4 29.2-6.9 42.5-6.4 13-16.8 24.6-29.9 31.1-11.5 5.7-24.7 8.2-37.5 8.1h-43.5c-4.6 26.4-8.9 52.9-13.3 79.3h-62.9C133.9 149.2 145.8 77 157.9 5z"
          stroke="#0c3a5c"
          strokeWidth="10"
        />
        <path
          d="M213.1 52.1c9.7.2 20-.9 29.2 2.5 6.1 2.3 10.9 7.2 11.8 13.8.4 4.8.6 10.4-1.9 14.7-2.9 5.8-8.6 9-14.6 10.7-10.9 3-20.7 1.8-31.8 2 2.3-14.6 4.8-29.1 7.3-43.7z"
          stroke="#fff"
          strokeWidth="10"
        />
        <path
          d="M139.2 61.2c-.5 7.8-2.4 15.6-3.5 23.3-13.2.1-24.6 8.2-30.8 19.6-8.4 14.9-7.5 34.1-1.5 49.7 2.7 7.5 5.2 15 7.9 22.5-8.1-4.1-16.1-8.4-24.1-12.5-11.4-5.4-24.1-7.2-35.8-1.5-14.8 7.2-22.8 24.2-22.6 40.1-.6 13 3.6 26.3 10.4 37.3 6.9 11 16.8 20.2 29 24.9 7.5 2.9 16.3 3.1 24.3 3h132c13.4-.8 26.8-.3 40.1-.4 2.2-.2 2.1 1.6 2.4 3.2 1 6.8 1.9 13.6 3 20.4H89.5c-12.5.6-25.3-3.2-36.3-9C25 266.3 6.3 235.4 5.1 203.4c-.6-14.4 2.8-29.6 10.8-41.7 7.7-12.1 19.9-21.2 33.9-24.5 8.5-2.2 17.5-1.4 26.1-.9-1.6-9.9-.7-20.3 1.8-29.9 4-15.5 13.4-29.3 27.3-37.5 10.3-6.2 22.2-8.3 34.2-7.7z"
          stroke="#1b75bc"
          strokeWidth="10"
        />
        <path
          d="M157.9 5c27.2.1 54.4 0 81.5.1 2.7 0 5.4 0 8 .3 8.2.7 17.4 1 25.2 3.8 7.2 2.4 14.1 6.3 20.2 10.8 12.6 9.8 20.6 24.6 22.8 40.3 1.5 14.6-.4 29.2-6.9 42.5-6.4 13-16.8 24.6-29.9 31.1-11.5 5.7-24.7 8.2-37.5 8.1h-43.5c-4.6 26.4-8.9 52.9-13.3 79.3h-62.9C133.9 149.2 145.8 77 157.9 5z"
          fill="#0c3a5c"
        />
        <path
          d="M213.1 52.1c9.7.2 20-.9 29.2 2.5 6.1 2.3 10.9 7.2 11.8 13.8.4 4.8.6 10.4-1.9 14.7-2.9 5.8-8.6 9-14.6 10.7-10.9 3-20.7 1.8-31.8 2 2.3-14.6 4.8-29.1 7.3-43.7z"
          fill="#fff"
        />
        <path
          d="M139.2 61.2c-.5 7.8-2.4 15.6-3.5 23.3-13.2.1-24.6 8.2-30.8 19.6-8.4 14.9-7.5 34.1-1.5 49.7 2.7 7.5 5.2 15 7.9 22.5-8.1-4.1-16.1-8.4-24.1-12.5-11.4-5.4-24.1-7.2-35.8-1.5-14.8 7.2-22.8 24.2-22.6 40.1-.6 13 3.6 26.3 10.4 37.3 6.9 11 16.8 20.2 29 24.9 7.5 2.9 16.3 3.1 24.3 3h132c13.4-.8 26.8-.3 40.1-.4 2.2-.2 2.1 1.6 2.4 3.2 1 6.8 1.9 13.6 3 20.4H89.5c-12.5.6-25.3-3.2-36.3-9C25 266.3 6.3 235.4 5.1 203.4c-.6-14.4 2.8-29.6 10.8-41.7 7.7-12.1 19.9-21.2 33.9-24.5 8.5-2.2 17.5-1.4 26.1-.9-1.6-9.9-.7-20.3 1.8-29.9 4-15.5 13.4-29.3 27.3-37.5 10.3-6.2 22.2-8.3 34.2-7.7z"
          fill="#1b75bc"
        />
      </svg>
    );
  }
}

/* tslint:enable:max-line-length */
