import _ from "lodash";
import CommentActions from "../actions/comment-actions";
import CommentStore from "../stores/comment-store";
import ErrorRenderingUtils from "../../utils/error-rendering-utils";
import I from "immutable";
import Modal from "../../modal";
import ModalBody from "../../common/components/modal-body";
import ModalHeader from "../../common/components/modal-header";
import { ModelUpdate } from "../../utils/model-update-utils";
import createReactClass from "create-react-class";
import React from "react";
import { StatusStates } from "../../stores/base-store";
import CancelSaveButtons from "../../common/components/cancel-save-buttons";
import { FlexJustifyContent } from "../../../../app/components/utils/constants";

interface UpdateCommentModalState {
  cid?: string;
  data?: I.Map<string, any>;
  status?: I.Map<string, any>;
  submissionTried?: boolean;
}

let UpdateCommentModal = createReactClass<{}, UpdateCommentModalState>({
  mixins: [ErrorRenderingUtils],

  getInitialState() {
    this.markDirty = this.props.markDirty.bind(this);
    this.onChangeSet = this.props.onChangeSet.bind(this);

    const cid = _.uniqueId("updateComment_");

    return {
      cid,
      data: this.props.comment,
      status: CommentStore.getStatus(cid)
    };
  },

  render() {
    return (
      <Modal isOpen={true}>
        <ModalHeader>Edit comment</ModalHeader>
        <div className="dialog-box">
          <ModalBody>
            <div className="row">
              <div className="columns small-12 small-centered">
                <div className="row center">
                  <div className="columns">{this.renderErrors()}</div>
                </div>

                <div className="row center">
                  <div className="columns">
                    <textarea
                      className="new-comment"
                      placeholder="Enter a new message"
                      value={this.state.data.get("text")}
                      onChange={this.markDirty(this.onChangeSet("text"))}
                    />
                  </div>
                </div>

                <div className="row last action-btns center">
                  <div className="columns">
                    <div className="footer">
                      <div className="footer-content">
                        <CancelSaveButtons
                          onCancelFn={this.props.close}
                          onSaveFn={this.onSubmit}
                          cancelDisabled={this.isPendingSave()}
                          saveDisabled={this.isPendingSave()}
                          justifyContainer={FlexJustifyContent.Center}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    );
  },

  onSubmit() {
    this.initValidation();
    if (this.isValid()) {
      CommentActions.updateComment(this.state.data.toJS(), this.state.cid);
    }
  },

  isPendingSave() {
    return this.state.pendingSave;
  },

  componentWillMount() {
    CommentStore.listen(this.handleCommentStoreChange);
  },

  componentWillUnmount() {
    CommentStore.unlisten(this.handleCommentStoreChange);
  },

  handleCommentStoreChange() {
    let status = CommentStore.getStatus(this.state.cid);
    this.setState({ status });

    if (status.get("statusState") === StatusStates.DONE) {
      this.props.close();
    }
  }
});

export default ModelUpdate(UpdateCommentModal);
