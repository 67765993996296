import alt from "../../alt";

import SettingsApi from "../apis/settings-api";

class SettingsActions {
  constructor() {
    this.generateActions("fetchCurrent", "fetchCurrentDone", "fetchCurrentFail", "updateDone", "updateFail");
  }

  update(data, { cid } = {}) {
    SettingsApi.update(data)
      .then(res => {
        this.updateDone({ data: res.data, cid });
      })
      .catch(res => {
        this.updateFail({ data: res, cid });
      });

    return { cid };
  }
}

export default alt.createActions(SettingsActions);
