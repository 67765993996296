import managementAppointmentActions from "../actions/management-appointment-actions";
import managementAppointmentApi from "../apis/management-appointment-api";

let ManagementAppointmentSource = {
  fetchOne() {
    return {
      remote(state, id, options) {
        return managementAppointmentApi.fetchOne(id, options);
      },

      shouldFetch(state, force) {
        return true;
      },

      success: managementAppointmentActions.fetchOneDone,
      error: managementAppointmentActions.fetchOneFail
    };
  }
};

export default ManagementAppointmentSource;
