import assignmentRequestActions from "../actions/assignment-request-actions";
import assignmentRequestsAPI from "../apis/assignment-request-api";

let AssignmentRequestSource = {
  fetchPending() {
    return {
      remote(state, { cid, xhrOptions } = {}) {
        return assignmentRequestsAPI.fetchPending(xhrOptions);
      },

      shouldFetch(state, force) {
        return true;
      },

      success: assignmentRequestActions.fetchPendingDone,
      error: assignmentRequestActions.fetchPendingFail
    };
  },

  fetchOne() {
    return {
      remote(state, id, { cid, options } = {}) {
        return assignmentRequestsAPI.fetchOne(id, { options });
      },

      shouldFetch(state, force) {
        return true;
      },

      success: assignmentRequestActions.fetchOneDone,
      error: assignmentRequestActions.fetchOneFail
    };
  }
};

export default AssignmentRequestSource;
