import React, { useRef } from "react";
import { EuiFieldText, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from "@elastic/eui";
import { css } from "@emotion/react";

import URL from "@pm-shared/utils/url";
import { colors } from "@pm-frontend/styles";

interface SiteSearchDesktopBaseProps {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  body: React.ReactNode;
  filter: React.ReactNode;
  checkbox?: React.ReactNode;
  iconString?: string;
  title: string | null;
  isFetching: boolean;
  isError: boolean;
}

const HOVER_MOUSE_COLOR = colors.neutrals.gray300;

export const searchResultHoverCss = css`
  & .global-search-result:hover {
    background: ${HOVER_MOUSE_COLOR};
  }
`;

/**
 * This component handles rendering the desktop search component
 */
const SiteSearchDesktopBase = ({
  searchText,
  setSearchText,
  body,
  filter,
  checkbox,
  iconString,
  title,
  isFetching,
  isError,
}: SiteSearchDesktopBaseProps) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const searchInputRef = useRef<HTMLInputElement>(null);

  return (
    <EuiFlexGrid responsive={false} columns={1} style={{ overflow: "hidden", flex: 1 }}>
      <EuiFlexItem grow={false}>
        <EuiFlexGroup
          direction="row"
          responsive={false}
          justifyContent="spaceBetween"
          style={{ padding: "0px 24px 0px 12px" }}
        >
          <EuiFlexItem grow={true}>
            <EuiFlexGroup direction="row" responsive={false} alignItems="center" style={{ gap: "12px" }}>
              <EuiFlexItem grow={false}>
                <EuiIcon type={URL.getStatic("icons/search-header_blue.svg")} size="l" />
              </EuiFlexItem>
              <EuiFlexItem grow={true}>
                <EuiFieldText
                  id="site-search-text-box"
                  value={searchText}
                  onChange={(e) => onChange(e)}
                  fullWidth={true}
                  style={{ color: colors.neutrals.gray700, fontSize: "16px", lineHeight: "24px" }}
                  isLoading={isFetching}
                  isInvalid={isError}
                  inputRef={searchInputRef}
                  autoComplete="off"
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="row" responsive={false} alignItems="center" gutterSize="s">
              <EuiFlexItem grow={false}>
                <EuiText
                  style={{
                    borderRadius: "6px",
                    border: `1.5px solid ${colors.neutrals.gray600}`,
                    padding: "4px 8px",
                    fontWeight: 600,
                  }}
                  size="xs"
                  color={colors.neutrals.gray600}
                >
                  ESC
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText size="s" color={colors.neutrals.gray600}>
                  to exit
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexGrid responsive={false} style={{ gridTemplateColumns: "min-content 1fr", overflow: "hidden" }}>
        <EuiFlexItem grow={false}>{filter}</EuiFlexItem>
        <EuiFlexGrid columns={1} style={{ padding: "24px 0px 24px 0px", overflow: "hidden", alignContent: "baseline" }}>
          {title && (
            <EuiFlexItem grow={false}>
              <EuiFlexGroup
                direction="row"
                justifyContent="spaceBetween"
                responsive={false}
                style={{ padding: "0px 32px 0px 32px" }}
                alignItems="center"
              >
                <EuiFlexItem grow={false}>
                  <EuiFlexGroup direction="row" alignItems="center" gutterSize="s">
                    {iconString && (
                      <EuiFlexItem grow={false}>
                        <EuiIcon type={iconString} />
                      </EuiFlexItem>
                    )}
                    <EuiFlexItem grow={false}>
                      <EuiText color={colors.neutrals.gray800} style={{ fontWeight: 700, whiteSpace: "nowrap" }}>
                        {title}
                      </EuiText>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
                {checkbox && <EuiFlexItem grow={false}>{checkbox}</EuiFlexItem>}
              </EuiFlexGroup>
            </EuiFlexItem>
          )}
          <EuiFlexItem
            grow={false}
            className="eui-yScroll"
            data-testid="global-search-results"
            css={searchResultHoverCss}
          >
            {body}
          </EuiFlexItem>
        </EuiFlexGrid>
      </EuiFlexGrid>
    </EuiFlexGrid>
  );
};

export { SiteSearchDesktopBase };
