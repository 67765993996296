import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";

class VendorInvoiceFileApi extends BaseApi {
  static create(data: any, meldId: number) {
    return this.post(ApiUrls.vendorInvoiceFileList(meldId), data);
  }

  static fetchFilesForMeld(meldId: number) {
    return this.get(ApiUrls.vendorInvoiceFileList(meldId));
  }

  static deleteFile(fileId: string) {
    return this.delete(ApiUrls.vendorInvoiceFileDetail(fileId));
  }
}

export default VendorInvoiceFileApi;
