import React from "react";
import classNames from "classnames";

let MODAL_ID = "modal";

interface ModalProps {
  columnClasses?: string;
  isOpen?: boolean;
  onModalClicked?: () => void;
  style?: any;
}

class Modal extends React.Component<ModalProps, { open: boolean }> {
  public static defaultProps: Partial<ModalProps> = {
    columnClasses: "small-centered small-12 medium-10 large-8 columns",
  };

  constructor(props: ModalProps) {
    super(props);

    this.state = {
      open: false,
    };

    this.onModalClicked = this.onModalClicked.bind(this);
  }

  render() {
    let modalClasses = classNames({
      in: this.props.isOpen,
      modal: true,
      active: this.props.isOpen,
    });

    if (this.props.isOpen) {
      return (
        <div className="modal-open">
          <div
            id={MODAL_ID}
            className={modalClasses}
            tabIndex={-1}
            role="dialog"
            onClick={this.onModalClicked}
            aria-labelledby=""
            aria-hidden="false"
          >
            <div
              className={`modal-container ${this.props.columnClasses}`}
              style={this.props.style ? this.props.style : {}}
            >
              <div className="modal-dialog ">
                <div className="modal-content">{this.props.children}</div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  onModalClicked(e: React.MouseEvent<any>) {
    if (e.currentTarget.id === MODAL_ID && this.props.onModalClicked) {
      this.props.onModalClicked();
    }
  }
}

export default Modal;
