var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const OwnerRejected = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement("path", { d: "M12 26a9 9 0 10-9-9 9 9 0 009 9zm0-16a7 7 0 11-7 7 7 7 0 017-7zM12 27A24.12 24.12 0 00.52 29.93a1 1 0 00-.52.88V39a1 1 0 001 1h29a1 1 0 001-1c0-5.29-8.95-12-19-12zM2 38v-6.59A22.43 22.43 0 0112 29c8.46 0 15.7 5.16 16.84 9zM32.41 9l7.3-7.29A1 1 0 1038.29.29L31 7.59 23.71.29a1 1 0 00-1.42 1.42L29.59 9l-7.3 7.29a1 1 0 000 1.42 1 1 0 001.42 0l7.29-7.3 7.29 7.3a1 1 0 001.42 0 1 1 0 000-1.42z" }));
