import alt from "../../alt";
import assignmentRequestActions from "../actions/assignment-request-actions";
import assignmentRequestSource from "../sources/assignment-request-source";
import BaseStore, { StatusStates } from "../../stores/base-store";

export const ExtraActions = {
  pendingAssignments: "PENDING_ASSIGNMENTS",
  reject: "REJECT",
  accept: "ACCEPT",
  updateSegments: "UPDATE_SEGMENTS"
};

class AssignmentRequestStore extends BaseStore {
  constructor(options) {
    super(options);

    this.bindListeners({
      fetchingPending: assignmentRequestActions.FETCH_PENDING,
      fetchPendingAllDone: assignmentRequestActions.FETCH_PENDING_DONE,
      fetchPendingAllFail: assignmentRequestActions.FETCH_PENDING_FAIL,
      fetching: assignmentRequestActions.FETCH_ONE,
      fetchDone: assignmentRequestActions.FETCH_ONE_DONE,
      fetchFail: assignmentRequestActions.FETCH_ONE_FAIL,
      accepting: assignmentRequestActions.ACCEPT,
      acceptDone: assignmentRequestActions.ACCEPT_DONE,
      acceptFail: assignmentRequestActions.ACCEPT_FAIL,
      rejecting: assignmentRequestActions.REJECT,
      rejectDone: assignmentRequestActions.REJECT_DONE,
      rejectFail: assignmentRequestActions.REJECT_FAIL,
      updatingSegments: assignmentRequestActions.UPDATE_SEGMENTS,
      updateSegmentsDone: assignmentRequestActions.UPDATE_SEGMENTS_DONE,
      updateSegmentsFail: assignmentRequestActions.UPDATE_SEGMENTS_FAIL
    });

    this.registerAsync(assignmentRequestSource);
  }

  fetchingPending(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.pendingAssignments, StatusStates.PENDING);
  }

  fetchPendingAllDone(dispatchData) {
    this.receive(dispatchData);
    this.augmentAndMarkStatus(dispatchData, ExtraActions.pendingAssignments, StatusStates.DONE);
  }

  fetchPendingAllFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.pendingAssignments, StatusStates.FAIL);
  }

  rejecting(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.reject, StatusStates.PENDING);
  }

  rejectDone(dispatchData) {
    this.remove(dispatchData.id);
    this.augmentAndMarkStatus(dispatchData, ExtraActions.reject, StatusStates.DONE);
  }

  rejectFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.reject, StatusStates.FAIL);
  }

  accepting(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.accept, StatusStates.PENDING);
  }

  acceptDone(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.accept, StatusStates.DONE);
  }

  acceptFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.accept, StatusStates.FAIL);
  }

  updatingSegments(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.updateSegments, StatusStates.PENDING);
  }

  updateSegmentsDone(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.updateSegments, StatusStates.DONE);
  }

  updateSegmentsFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.updateSegments, StatusStates.FAIL);
  }
}

export default alt.createStore(AssignmentRequestStore, "AssignmentRequestStore");
