var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import I from "immutable";
import { asImmer } from "../../../../app/utils/decorators";
import * as C from "../../constants";
import { ErrorHandler } from "../../../../app/utils/ErrorHandler";
import { LinkHelper } from "@pm-shared/utils/link";
const _InvoiceUtils = class {
  static hasBeenSubmitted(invoice) {
    invoice = invoice;
    let status = invoice.status;
    return status ? status.toString() !== C.InvoiceStatuses.DRAFT : false;
  }
  static getFormattedStatus(invoice) {
    const status = invoice.get("status");
    switch (status) {
      case C.InvoiceStatuses.DRAFT:
        return "Draft";
      case C.InvoiceStatuses.SUBMITTED:
        return "Submitted";
      case C.InvoiceStatuses.PAID:
        return "Paid";
      case C.InvoiceStatuses.HOLD:
        return "Hold";
      case C.InvoiceStatuses.IN_REVIEW:
        return "In Review";
      case C.InvoiceStatuses.DECLINED:
        return "Declined";
      case C.InvoiceStatuses.APPROVED:
        return "Approved";
      default:
        ErrorHandler.handleError(new Error(`Unknown invoice status ${status}`));
        return "";
    }
  }
  static getDownloadUrl(invoice) {
    return LinkHelper.normalize(`/invoices/${invoice.get("id")}/download/`);
  }
  static getViewUrl(invoice) {
    return LinkHelper.normalize(`/invoices/${invoice.get("id")}/view/`);
  }
  static getID(invoice) {
    invoice = invoice;
    return invoice.id;
  }
  static getTitle(invoice) {
    return invoice.getIn(["meld", "brief_description"]);
  }
  static sumInvoices(invoices) {
    return invoices.reduce((total, invoice) => total + Number.parseFloat(invoice.get("amount")), 0);
  }
  static getPaymentNotes(invoice, emptyMsg = "No notes recorded") {
    return invoice.getIn(["payment", "notes"]) || emptyMsg;
  }
  static calcLineItemPrice(cost, {
    rentMgrInvItem
  } = {}) {
    if (rentMgrInvItem) {
      if (rentMgrInvItem.Markup && rentMgrInvItem.IsMarkupPercentage) {
        return cost + rentMgrInvItem.Markup * cost / 100;
      }
      if (rentMgrInvItem.Markup) {
        return cost + rentMgrInvItem.Markup;
      }
    }
    return cost;
  }
  static getHoldReason(invoice) {
    const holdReasons = invoice.get("hold_reasons");
    if (!holdReasons.isEmpty()) {
      return holdReasons.last().get("reason");
    }
  }
  static getDeclineReason(invoice) {
    const declineReasons = invoice.get("decline_reasons");
    if (!declineReasons.isEmpty()) {
      return declineReasons.last().get("reason");
    }
  }
  static canManagerReview(invoice) {
    return ![C.InvoiceStatuses.DRAFT, C.InvoiceStatuses.IN_REVIEW, C.InvoiceStatuses.PAID].includes(
      invoice.get("status")
    );
  }
  static canManagerHold(invoice) {
    return ![C.InvoiceStatuses.DRAFT, C.InvoiceStatuses.HOLD, C.InvoiceStatuses.PAID].includes(invoice.get("status"));
  }
  static canManagerDecline(invoice) {
    return ![C.InvoiceStatuses.DRAFT, C.InvoiceStatuses.DECLINED, C.InvoiceStatuses.PAID].includes(
      invoice.get("status")
    );
  }
  static canManagerApprove(invoice) {
    return ![C.InvoiceStatuses.DRAFT, C.InvoiceStatuses.APPROVED, C.InvoiceStatuses.PAID].includes(
      invoice.get("status")
    );
  }
  static canManagerPay(invoice) {
    return ![C.InvoiceStatuses.DRAFT, C.InvoiceStatuses.PAID].includes(invoice.get("status"));
  }
  static getInvoiceFile(invoice) {
    return invoice.get("vendorinvoicefile") || I.fromJS({ pdf_file_url: _InvoiceUtils.getViewUrl(invoice) });
  }
};
let InvoiceUtils = _InvoiceUtils;
InvoiceUtils.defaultQuery = { saved_filter: "default" };
__decorateClass([
  asImmer()
], InvoiceUtils, "hasBeenSubmitted", 1);
__decorateClass([
  asImmer()
], InvoiceUtils, "getID", 1);
export {
  InvoiceUtils as default
};
