import React from "react";

interface LineItemDescriptionProps {
  onDescriptionChanged: any;
  lineItem: Map<string, any> | any;
  disabled?: boolean;
}

class LineItemDescription extends React.Component<LineItemDescriptionProps> {
  render() {
    let { lineItem } = this.props;

    return (
      <div style={{ width: "100%", marginTop: "7px" }}>
        <label className="line-item-label">Description</label>
        <textarea
          rows={1}
          data-test="line-item-description.input"
          className="line-item-description-input"
          placeholder="Line item description"
          value={lineItem.get("description")}
          onChange={this.props.onDescriptionChanged}
          disabled={this.props.disabled}
          style={{ maxWidth: "100%", minHeight: "2.9rem" }}
          data-testid="expenditure-description-input"
        />
      </div>
    );
  }
}

export default LineItemDescription;
