import React from "react";

/* tslint:disable:max-line-length */
export default class Phone extends React.Component<any> {
  render() {
    return (
      <svg
        className={this.props.classes}
        xmlns="https://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 20 32"
      >
        <path
          d="M18.277 32.014H1.717C.77 32.014 0 31.244 0 30.296V1.716C0 .77.77 0 1.717 0h16.56c.948 0 1.717.77 1.717 1.717v28.58c0 .948-.77 1.717-1.717 1.717zm-7.89-1.968c.633 0 1.596-.563 1.645-1.488.047-.864-.606-1.574-1.613-1.55-1.033.022-1.49.62-1.49 1.55 0 .943.825 1.488 1.458 1.488zm6.61-26.05h-.574H3.57 3V25.01h13.996V3.998z"
          fillRule="evenodd"
        />
      </svg>
    );
  }
}

/* tslint:enable:max-line-length */
