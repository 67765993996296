var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var _a;
import { create } from "zustand";
import { track, UIRedesignToggleDisabled, UIRedesignToggleEnabled } from "@pm-app/utils/analytics";
import AgentPreferencesApi from "@pm-assets/js/management/apis/agent-preferences-api";
import Features from "@pm-assets/js/common/feature-flags";
const REDESIGN_TOGGLE_SESSION_STORAGE_KEY = `ui-redesign-enabled-${((_a = window.PM.user) == null ? void 0 : _a.id) || "karma-test-fix"}`;
const syncToggleStateWithAPI = (toggleState) => __async(void 0, null, function* () {
  try {
    if (window.PM.redesign_enabled === void 0) {
      window.PM.redesign_enabled = toggleState;
      const agent = (yield AgentPreferencesApi.fetchMe()).data;
      if (toggleState !== agent.redesign_enabled) {
        if (toggleState === null || agent.redesign_enabled !== null) {
          window.localStorage.setItem(REDESIGN_TOGGLE_SESSION_STORAGE_KEY, JSON.stringify(agent.redesign_enabled));
        } else {
          yield AgentPreferencesApi.updateMe({
            redesign_enabled: toggleState
          });
        }
        location.reload();
      }
    } else if (toggleState !== window.PM.redesign_enabled) {
      yield AgentPreferencesApi.updateMe({
        redesign_enabled: toggleState
      });
    }
  } catch (e) {
    window.PM.redesign_enabled = void 0;
  }
});
const readToggleStateFromLocalStorageWithoutSyncing = () => {
  try {
    const rawKey = window.localStorage.getItem(REDESIGN_TOGGLE_SESSION_STORAGE_KEY);
    if (rawKey !== null) {
      return !!JSON.parse(rawKey);
    }
  } catch (e) {
    return null;
  }
  return null;
};
const readToggleStateFromLocalStorage = () => {
  const toggleState = readToggleStateFromLocalStorageWithoutSyncing();
  syncToggleStateWithAPI(toggleState);
  return !!toggleState;
};
const writeToggleStateToLocalStorage = (value) => {
  try {
    window.localStorage.setItem(REDESIGN_TOGGLE_SESSION_STORAGE_KEY, JSON.stringify(value));
    return syncToggleStateWithAPI(value);
  } catch (e) {
    return;
  }
};
const useRedesignToggle = create((set) => {
  var _a2, _b;
  return {
    // WARN: if you update this function you need to also update the localStorage call
    // in the template below to ensure refiner receives the correct data
    // propertymeld/templates/base-js.html
    // if the ui redesign is sunset we skip syncing with the api
    // however this initialization occurs before `Features` is initialized, so we use the bootstrap data
    enabled: ((_b = (_a2 = window.PM.featureFlagBootstrap) == null ? void 0 : _a2.bootstrapData) == null ? void 0 : _b["features.ui-redesign-2023-sunset"]) ? true : readToggleStateFromLocalStorage(),
    toggleValue: () => {
      set((state) => {
        const sanitizedPathName = location.pathname.replace(/[\d]+/g, "_");
        const newState = !state.enabled;
        if (newState) {
          track(UIRedesignToggleEnabled(sanitizedPathName));
        } else {
          track(UIRedesignToggleDisabled(sanitizedPathName));
        }
        writeToggleStateToLocalStorage(newState);
        return {
          enabled: newState
        };
      });
      location.reload();
    }
  };
});
const isRedesignToggleEnabled = () => {
  if (Features.isUIRedesignSunset()) {
    return true;
  }
  return readToggleStateFromLocalStorage();
};
const useGetRedesignIsEnabled = () => {
  const redesignToggleState = useRedesignToggle((state) => state.enabled);
  if (Features.isUIRedesignSunset()) {
    return true;
  }
  return redesignToggleState;
};
const useToggleRedesignIsEnabled = () => useRedesignToggle((state) => state.toggleValue);
export {
  useGetRedesignIsEnabled,
  useToggleRedesignIsEnabled,
  isRedesignToggleEnabled,
  readToggleStateFromLocalStorageWithoutSyncing
};
