var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { isArray } from "lodash";
import React from "react";
import { MdArrowDropDown } from "react-icons/md";
import { Select as ChakraSelect } from "@chakra-ui/react";
import { colors, shadows } from "../../theme";
import FormError from "../form-error/form-error";
export const Select = (_a) => {
  var _b = _a, { errors, className } = _b, elementProps = __objRest(_b, ["errors", "className"]);
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
    ChakraSelect,
    __spreadValues({
      backgroundImage: "none !important",
      borderColor: errors ? colors.pmRed : colors.pmGreyLight,
      borderRadius: 0,
      className,
      color: colors.pmGreyMedium,
      icon: /* @__PURE__ */ React.createElement(MdArrowDropDown, null),
      iconSize: "2em",
      iconColor: colors.pmBlue,
      _hover: {
        borderColor: errors ? colors.pmRed : colors.pmBlue,
        boxShadow: shadows.pmDropShadow
      },
      _focus: {
        borderColor: errors ? colors.pmRed : colors.pmBlue,
        boxShadow: shadows.pmDropShadow,
        outline: "none"
      }
    }, elementProps)
  ), errors && /* @__PURE__ */ React.createElement(FormError, null, isArray(errors) ? errors.join(", ") : errors));
};
Select.displayName = "Select";
export default Select;
