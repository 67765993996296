import { ReactRouterDomRoutes } from "@pm-assets/js/utils/redesign-routing";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import {
  useCalendarStateActions,
  useCalendarStateAltEventSelectedAgentsTime,
  useCalendarStatePendingRecommendedMeld,
  useCalendarStateSelectedDate,
  useCalendarStateSelectedTimeFrame,
} from "../calendarStateStore";
import { differenceInDays, startOfDay } from "date-fns";
import {
  CompositeId,
  isValidCompositeId,
  isMaintenanceManagementAgent,
} from "@pm-frontend/shared/utils/assignment-utils";
import { CalendarMeldMapOpened, track } from "@pm-app/utils/analytics";

const CALENDAR_URL_PARAM_KEYS = {
  EVENT_ID: "event_id",
  AGENT_ID: "agent_id",
  PANE: "pane",
  MAP_OPEN: "map",
  // saved filters
  FILTER_ID: "filter_id",
  FILTER_TAB: "filter_tab",
} as const;

const CALENDAR_URL_PANE_PARAM_VALUES = {
  SAVED_FILTERS: "saved_filters",
  MELDS_LIST: "melds_list",
  RECOMMEND_MELDS: "recommend",
  ALTERNATIVE_EVENTS: "altevent",
  GOOGLE_EVENT: "google_event",
  OUTLOOK_EVENT: "outlook_event",
  MAP_EVENT_LIST: "map_event_list",
} as const;

const LARGE_MAP_QUERY_PARAM_VALUE = "large_map";

const CALENDAR_PANE_TYPES = {
  MELD_DETAILS: "meldDetails",
  OFFER_AVAILABILITIES: "offerAvailabilities",
  NULL: "null",
  MELD_LIST: "meldsList",
  MELD_FILTERS: "meldFilters",
  RECOMMEND: "recommendedMelds",
  ALTERNATIVE_EVENT: "alternativeEvent",
  GOOGLE_EVENT: "googleCalendarEvent",
  OUTLOOK_EVENT: "outlookCalendarEvent",
  MAP_EVENT_LIST: "mapEventList",
} as const;

type CALENDAR_PANE_TYPES = typeof CALENDAR_PANE_TYPES[keyof typeof CALENDAR_PANE_TYPES];

type CalendarPaneState =
  | {
      type: Extract<CALENDAR_PANE_TYPES, "meldDetails">;
      meldId: string;
    }
  | {
      type: Extract<CALENDAR_PANE_TYPES, "offerAvailabilities">;
      meldId: string;
    }
  | {
      // on mobile we need a state where no rightpanes are open
      type: Extract<CALENDAR_PANE_TYPES, "null">;
    }
  | {
      type: Extract<CALENDAR_PANE_TYPES, "meldsList">;
      filterId?: number;
    }
  | ({
      type: Extract<CALENDAR_PANE_TYPES, "meldFilters">;
      tab: "shared" | "private";
    } & (
      | {
          mode: "edit";
          filterId: string;
        }
      | {
          mode: "view";
          filterId?: never;
        }
    ))
  | {
      // event details kept in calendarStateStore
      type: Extract<CALENDAR_PANE_TYPES, "recommendedMelds">;
    }
  | ({
      type: Extract<CALENDAR_PANE_TYPES, "alternativeEvent">;
    } & (
      | {
          eventId?: never;
          mode: "add";
        }
      | {
          eventId: string;
          mode: "edit";
        }
    ))
  | {
      type: Extract<CALENDAR_PANE_TYPES, "googleCalendarEvent">;
      eventId: string;
    }
  | {
      type: Extract<CALENDAR_PANE_TYPES, "outlookCalendarEvent">;
      eventId: string;
    }
  | {
      type: Extract<CALENDAR_PANE_TYPES, "mapEventList">;
    };

const getSearchParamsFromCalendarPaneState = ({
  newRightpaneState,
  newMapState,
  currentMapState,
  location,
  isMobile,
}: {
  newMapState: UseGetSetActivePaneAndMapReturn["calendarOrMap"] | undefined;
  currentMapState: UseGetSetActivePaneAndMapReturn["calendarOrMap"];
  newRightpaneState: CalendarPaneState;
  location: ReturnType<typeof useLocation>;
  isMobile: boolean;
}): { pathname: string; search: string } => {
  const search = new URLSearchParams(location.search);
  // remove all params related to this state
  Object.values(CALENDAR_URL_PARAM_KEYS).forEach((key) => {
    search.delete(key);
  });

  let pathname = LinkHelper.normalize(RouteUrls.meldCalendarList); // add relevant params for new state

  switch (newRightpaneState.type) {
    case CALENDAR_PANE_TYPES.MELD_DETAILS:
      pathname = LinkHelper.normalize(RouteUrls.meldCalendar({ id: newRightpaneState.meldId }));
      break;
    case CALENDAR_PANE_TYPES.OFFER_AVAILABILITIES:
      pathname = LinkHelper.normalize(RouteUrls.meldCalendarMeldAvailabilities({ id: newRightpaneState.meldId }));
      break;
    case CALENDAR_PANE_TYPES.MELD_FILTERS:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.SAVED_FILTERS);
      if (newRightpaneState.mode === "edit") {
        search.set(CALENDAR_URL_PARAM_KEYS.FILTER_ID, newRightpaneState.filterId);
      }
      search.set(CALENDAR_URL_PARAM_KEYS.FILTER_TAB, newRightpaneState.tab);
      break;
    case CALENDAR_PANE_TYPES.RECOMMEND:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.RECOMMEND_MELDS);
      break;
    case CALENDAR_PANE_TYPES.GOOGLE_EVENT:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.GOOGLE_EVENT);
      search.set(CALENDAR_URL_PARAM_KEYS.EVENT_ID, newRightpaneState.eventId);
      break;
    case CALENDAR_PANE_TYPES.OUTLOOK_EVENT:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.OUTLOOK_EVENT);
      search.set(CALENDAR_URL_PARAM_KEYS.EVENT_ID, newRightpaneState.eventId);
      break;
    case CALENDAR_PANE_TYPES.MAP_EVENT_LIST:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.MAP_EVENT_LIST);
      break;
    case CALENDAR_PANE_TYPES.ALTERNATIVE_EVENT:
      search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.ALTERNATIVE_EVENTS);
      if (newRightpaneState.mode === "edit") {
        search.set(CALENDAR_URL_PARAM_KEYS.EVENT_ID, newRightpaneState.eventId);
      }
      break;
    case CALENDAR_PANE_TYPES.NULL:
      break;
    case CALENDAR_PANE_TYPES.MELD_LIST:
      // meld list is the default view on desktop, but must be set on mobile
      if (isMobile) {
        search.set(CALENDAR_URL_PARAM_KEYS.PANE, CALENDAR_URL_PANE_PARAM_VALUES.MELDS_LIST);
      }
      if (newRightpaneState.filterId) {
        search.set("saved_filter", newRightpaneState.filterId.toString());
      }
      break;
    default:
      break;
  }
  // we don't need to set the 'calendar' value at it is the default
  if (newMapState && newMapState !== "calendar") {
    search.set(CALENDAR_URL_PARAM_KEYS.MAP_OPEN, newMapState);
  } else if (!newMapState && currentMapState !== "calendar") {
    search.set(CALENDAR_URL_PARAM_KEYS.MAP_OPEN, currentMapState);
  }

  return { search: search.toString(), pathname };
};

const getCalendarOrMapState = (
  searchParams: URLSearchParams,
  isMobile: boolean
): UseGetSetActivePaneAndMapReturn["calendarOrMap"] => {
  if (isMobile) {
    return "calendar";
  }
  const queryParamValue = searchParams.get(CALENDAR_URL_PARAM_KEYS.MAP_OPEN);

  if (!queryParamValue) {
    return "calendar";
  } else if (queryParamValue === LARGE_MAP_QUERY_PARAM_VALUE) {
    return LARGE_MAP_QUERY_PARAM_VALUE;
  } else if (isValidCompositeId(queryParamValue)) {
    return queryParamValue;
  }
  return "calendar";
};

interface UseGetSetActivePaneAndMapReturn {
  rightPaneState: CalendarPaneState;
  // the string variant is the composite id of the open
  // agent/vendor on the calendar view
  calendarOrMap: typeof LARGE_MAP_QUERY_PARAM_VALUE | "calendar" | CompositeId;
  updateRightPaneURL: (arg0: {
    newMapState?: typeof LARGE_MAP_QUERY_PARAM_VALUE | "calendar" | CompositeId;
    newRightpaneState?: CalendarPaneState;
    action?: "push" | "replace";
  }) => void;
}

// currently whether the rightpane is open is a mix of url state and in-memory state
const useGetSetActivePaneAndMap = (): UseGetSetActivePaneAndMapReturn => {
  const eventMetaData = useGetCalendarEventMetaData();
  const isMobile = useIsMobile();
  const { setPriorRightpaneType, setPendingRecommendEvent, clearAltEventSelectedAgentsTime } =
    useCalendarStateActions();
  const pendingRecommendedEvent = useCalendarStatePendingRecommendedMeld();
  const pendingAltEvent = useCalendarStateAltEventSelectedAgentsTime();

  const detailsPaneMatch = useRouteMatch<{ meldId: string }>({ path: ReactRouterDomRoutes.meldCalendarMeldDetails });
  const availabilitiesPaneMatch = useRouteMatch<{ meldId: string }>(
    ReactRouterDomRoutes.meldCalendarMeldAvailabilities
  );
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const calendarOrMap = getCalendarOrMapState(searchParams, isMobile);

  const paneUrlValue = searchParams.get(CALENDAR_URL_PARAM_KEYS.PANE);
  let rightPaneState: CalendarPaneState = { type: "null" };

  // panes used on mobile and desktop
  if (availabilitiesPaneMatch) {
    rightPaneState = { type: "offerAvailabilities", meldId: availabilitiesPaneMatch.params.meldId };
  } else if (detailsPaneMatch) {
    rightPaneState = { type: "meldDetails", meldId: detailsPaneMatch.params.meldId };
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.ALTERNATIVE_EVENTS) {
    const eventId = searchParams.get(CALENDAR_URL_PARAM_KEYS.EVENT_ID);
    if (eventId) {
      rightPaneState = { type: "alternativeEvent", mode: "edit", eventId };
    } else {
      rightPaneState = { type: "alternativeEvent", mode: "add" };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.GOOGLE_EVENT) {
    const eventId = searchParams.get(CALENDAR_URL_PARAM_KEYS.EVENT_ID);
    if (eventId) {
      rightPaneState = { type: "googleCalendarEvent", eventId };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.OUTLOOK_EVENT) {
    const eventId = searchParams.get(CALENDAR_URL_PARAM_KEYS.EVENT_ID);
    if (eventId) {
      rightPaneState = { type: "outlookCalendarEvent", eventId };
    }
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.MELDS_LIST) {
    // meld list pane url value isn't needed on desktop (as it is default) but is on mobile
    rightPaneState = { type: CALENDAR_PANE_TYPES.MELD_LIST };
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.SAVED_FILTERS) {
    const filterId = searchParams.get(CALENDAR_URL_PARAM_KEYS.FILTER_ID);
    const tab = searchParams.get(CALENDAR_URL_PARAM_KEYS.FILTER_TAB) === "shared" ? "shared" : "private";
    if (filterId) {
      rightPaneState = { type: CALENDAR_PANE_TYPES.MELD_FILTERS, tab, mode: "edit", filterId };
    } else {
      rightPaneState = { type: CALENDAR_PANE_TYPES.MELD_FILTERS, mode: "view", tab };
    }
  } else if (isMobile) {
    // everything below this isn't implemented for mobile yet
    rightPaneState = { type: "null" };
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.RECOMMEND_MELDS) {
    rightPaneState = { type: CALENDAR_PANE_TYPES.RECOMMEND };
  } else if (paneUrlValue === CALENDAR_URL_PANE_PARAM_VALUES.MAP_EVENT_LIST) {
    rightPaneState = { type: CALENDAR_PANE_TYPES.MAP_EVENT_LIST };
  } else {
    rightPaneState = { type: CALENDAR_PANE_TYPES.MELD_LIST };
  }

  const updateRightPaneURL: UseGetSetActivePaneAndMapReturn["updateRightPaneURL"] = ({
    newMapState,
    newRightpaneState,
    action = "push",
  }) => {
    // we clear out any pending recommended melds except for this transition
    if (
      pendingRecommendedEvent &&
      rightPaneState.type !== "recommendedMelds" &&
      newRightpaneState?.type !== "meldDetails"
    ) {
      setPendingRecommendEvent(null);
    }

    // we clear out any pending altevent changes melds except for this transition
    if (newRightpaneState && newRightpaneState.type !== "alternativeEvent" && pendingAltEvent) {
      clearAltEventSelectedAgentsTime(true);
    }

    // if caller doesn't provide new state we just use existing state
    const { search, pathname } = getSearchParamsFromCalendarPaneState({
      newRightpaneState: newRightpaneState || rightPaneState,
      newMapState,
      currentMapState: calendarOrMap,
      location,
      isMobile,
    });
    if (newMapState) {
      if (newMapState === "large_map") {
        track(CalendarMeldMapOpened({ type: "large", ...eventMetaData }));
      } else if (isValidCompositeId(calendarOrMap)) {
        track(
          CalendarMeldMapOpened({
            type: isMaintenanceManagementAgent({ composite_id: calendarOrMap }) ? "small-agent" : "small-vendor",
            ...eventMetaData,
          })
        );
      }
    }

    if (action === "push") {
      if (newRightpaneState) {
        setPriorRightpaneType(rightPaneState.type);
      }
      history.push({
        pathname,
        search,
      });
    } else if (action === "replace") {
      history.replace({
        pathname,
        search,
      });
    }
  };

  return { rightPaneState, calendarOrMap, updateRightPaneURL };
};

// convienience hook for getting the state used in all calendar events
const useGetCalendarEventMetaData = () => {
  const isMobile = useIsMobile();
  const selectedTimeframe = useCalendarStateSelectedTimeFrame(isMobile);
  const selectedDate = useCalendarStateSelectedDate();
  const daysOffsetFromCurrentDay = differenceInDays(startOfDay(new Date(selectedDate)), startOfDay(new Date()));

  return {
    isMobile,
    selectedTimeframe,
    daysOffsetFromCurrentDay,
  };
};

export {
  useGetCalendarEventMetaData,
  useGetSetActivePaneAndMap,
  CALENDAR_URL_PANE_PARAM_VALUES,
  CalendarPaneState,
  CALENDAR_PANE_TYPES,
  UseGetSetActivePaneAndMapReturn,
};
