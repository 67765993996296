import React from "react";
import { colors } from "@pm-frontend/styles";

export const SidebarFinancesIcon = ({ isActive = false }: { isActive?: boolean }) => {
  const fill = isActive ? colors.brand.meldBlue : colors.neutrals.gray800;
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1378_22492)">
        <path
          d="M12.6667 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2ZM12.6667 12.6667H3.33333V3.33333H12.6667V12.6667Z"
          fill={fill}
        />
        <path d="M7.49984 5.14673H4.1665V6.14673H7.49984V5.14673Z" fill={fill} />
        <path d="M11.9998 10.5H8.6665V11.5H11.9998V10.5Z" fill={fill} />
        <path d="M11.9998 8.83325H8.6665V9.83325H11.9998V8.83325Z" fill={fill} />
        <path
          d="M5.33333 11.9999H6.33333V10.6666H7.66667V9.66659H6.33333V8.33325H5.33333V9.66659H4V10.6666H5.33333V11.9999Z"
          fill={fill}
        />
        <path
          d="M9.39319 7.3L10.3332 6.36L11.2732 7.3L11.9799 6.59333L11.0399 5.64667L11.9799 4.70667L11.2732 4L10.3332 4.94L9.39319 4L8.68652 4.70667L9.62652 5.64667L8.68652 6.59333L9.39319 7.3Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1378_22492">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
