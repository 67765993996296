var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const Files = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement("path", { d: "M37 8.5H20.48l-3.7-4.62A1 1 0 0016 3.5H3a3 3 0 00-3 3v27a3 3 0 003 3h34a3 3 0 003-3v-22a3 3 0 00-3-3zm-34-3h12.52l2.4 3H3a3 3 0 00-1 .18V6.5a1 1 0 011-1zm35 28a1 1 0 01-1 1H3a1 1 0 01-1-1v-22a1 1 0 011-1h34a1 1 0 011 1z" }));
