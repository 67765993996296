export enum OwnerHubAccessLevelEnum {
  DIRECT_ONLY = "DIRECT_ONLY",
  ALL_NOTIFICATIONS = "ALL_NOTIFICATIONS",
  INVITED_TO_HUB = "INVITED_TO_HUB",
  HUB_ACCESS = "HUB_ACCESS",
  NONE = "NONE", //DEPRECATED REMOVE AFTER PHRASING DATA CHANGE
  NOTIFICATIONS_ONLY = "NOTIFICATIONS_ONLY", //DEPRECATED REMOVE AFTER PHRASING DATA CHANGE
}

export enum OwnerHubAccessLevelEnumReadable {
  DIRECT_ONLY = "Direct Only",
  ALL_NOTIFICATIONS = "All Notifications",
  INVITED_TO_HUB = "Invited To Hub",
  HUB_ACCESS = "Hub Access",
  NONE = "None", //DEPRECATED REMOVE AFTER PHRASING DATA CHANGE
  NOTIFICATIONS_ONLY = "Notifications Only", //DEPRECATED REMOVE AFTER PHRASING DATA CHANGE
}

export interface Owner {
  id: number;
  first_name: string;
  email: string;
  last_name: string;
  hub_access_level:
    | OwnerHubAccessLevelEnum.DIRECT_ONLY
    | OwnerHubAccessLevelEnum.ALL_NOTIFICATIONS
    | OwnerHubAccessLevelEnum.INVITED_TO_HUB
    | OwnerHubAccessLevelEnum.HUB_ACCESS
    | OwnerHubAccessLevelEnum.NONE //DEPRECATED REMOVE AFTER PHRASING DATA CHANGE
    | OwnerHubAccessLevelEnum.NOTIFICATIONS_ONLY; //DEPRECATED REMOVE AFTER PHRASING DATA CHANGE
  properties: number[];
}
