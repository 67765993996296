import { AuthUtils } from "../../utils/auth-utils";
import ErrorRenderingUtils from "../../utils/error-rendering-utils";
import I from "immutable";
import ModelUpdateUtils from "../../utils/model-update-utils";
import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";
import StateSelect from "../../property/components/state-select";
import { pd } from "../../utils/event-utils";
import ButtonContainer from "../../../../app/components/containers/ButtonContainer";
import { FlexJustifyContent } from "../../../../app/components/utils/constants";
import Button, { ButtonTypes } from "../../../../app/components/buttons/Button";

let Step2 = createReactClass({
  propTypes: {
    stepForward: PropTypes.func.isRequired,
    stepBackward: PropTypes.func.isRequired,
    locationData: PropTypes.object.isRequired
  },

  mixins: [ErrorRenderingUtils, ModelUpdateUtils],

  getDefaultProps() {
    return {
      constraints: {
        "address.line_1": {
          presence: {
            message: "Please enter the address line 1",
            allowEmpty: false
          }
        },
        "address.city": {
          presence: {
            message: "Please enter the city",
            allowEmpty: false
          }
        },
        "address.county_province": {
          presence: {
            message: "Please enter the state",
            allowEmpty: false
          }
        }
      },
      errors: []
    };
  },

  getInitialState() {
    let data = this.props.data || I.fromJS({});

    return {
      data,
      submitting: false,
      errors: []
    };
  },

  render() {
    let nextButton;

    let sumbitButtonText = this.props.submitting ? "Submitting..." : "Submit";

    if (AuthUtils.isAuthenticated()) {
      nextButton = (
        <Button
          buttonType={ButtonTypes.Primary}
          className="step-forward-btn dual-btn"
          onClick={pd(this.prepareSubmit(this.stepForward))}
        >
          {sumbitButtonText}
        </Button>
      );
    } else {
      nextButton = (
        <Button buttonType={ButtonTypes.Primary} className="step-forward-btn dual-btn" onClick={pd(this.stepForward)}>
          Next
        </Button>
      );
    }

    return (
      <div>
        <h3 className="accent sub-header">Select Company</h3>
        {this.props.children}
        <form>
          <div className="company-choices">
            <div>
              <input
                type="text"
                placeholder="Address line 1"
                id="address-line-1"
                ref="addressLine1"
                maxLength="500"
                value={this.state.data.getIn(["address", "line_1"])}
                onChange={this.onChangeSetIn(["address", "line_1"])}
              />
            </div>
            <div>
              <input
                type="text"
                placeholder="City"
                id="city"
                maxLength="100"
                onChange={this.onChangeSetIn(["address", "city"])}
                value={this.state.data.getIn(["address", "city"])}
              />
            </div>
            <div>
              <StateSelect
                inputAttrs={{
                  onChange: this.onChangeSetIn(["address", "county_province"]),
                  value: this.state.data.getIn(["address", "county_province"]),
                  placeholder: "State",
                  id: "state"
                }}
              />
            </div>
          </div>
          <div className="form-card-container">
            {this.renderErrors()}
            <ButtonContainer justify={FlexJustifyContent.Center}>
              <Button className="dual-btn step-backwards-btn" onClick={pd(this.props.stepBackward)}>
                Back
              </Button>
              {nextButton}
            </ButtonContainer>
          </div>
        </form>
      </div>
    );
  },

  stepForward() {
    this.initValidation();

    if (this.isValid()) {
      this.props.stepForward(this.state.data);
    }
  },

  componentDidMount() {
    this.refs.addressLine1.focus();
  },

  getExtraErrors() {
    return this.props.errors;
  }
});

export default Step2;
