import React from "react";

/* tslint:disable:max-line-length */
export class Download extends React.Component<any> {
  render() {
    return (
      <svg className="download-ico" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 29.978 29.978">
        <path d="M25.462 19.105v6.848H4.515v-6.848H.489v8.861c0 1.111.9 2.012 2.016 2.012h24.967c1.115 0 2.016-.9 2.016-2.012v-8.861h-4.026zM14.62 18.426l-5.764-6.965s-.877-.828.074-.828h3.248V9.217.494S12.049 0 12.793 0h4.572c.536 0 .524.416.524.416V10.424h2.998c1.154 0 .285.867.285.867s-4.904 6.51-5.588 7.193c-.492.495-.964-.058-.964-.058z" />
      </svg>
    );
  }
}
/* tslint:enable:max-line-length */

export default Download;
