import React from "react";
import { EuiAvatar } from "@elastic/eui";

import { getPersonaColor } from "@pm-frontend/shared/utils/color-utils";
import { colors } from "@pm-frontend/styles";

type PmAvatarProps = {
  name: string;
} & (
  | {
      persona: { created: string };
      color?: never;
    }
  | {
      persona?: never;
      color: string;
    }
);

/**
 * A colored circle with the users initials
 *
 * Prefer passing 'persona: {created}', as this ensures consistent colors
 * for a specific agent/vendor/resident etc across the app
 */
const PmAvatar = (props: PmAvatarProps) => {
  return (
    <EuiAvatar
      className="pm-header-avatar"
      name={props.name}
      color={props.persona ? getPersonaColor(props.persona) : props.color}
      style={{
        height: "28px",
        width: "28px",
        fontFamily: '"Open Sans", Helvetica, Roboto, Arial, sans-serif',
        color: colors.neutrals.gray900,
      }}
    />
  );
};

export { PmAvatar };
