let WindowClickedMixin = {
  componentWillMount() {
    window.addEventListener("click", this.handleWindowClicked, false);
  },

  componentWillUnmount() {
    window.removeEventListener("click", this.handleWindowClicked, false);
  }
};

export default WindowClickedMixin;

export let WindowClickUtils = {
  addListener(callback: EventListener) {
    window.addEventListener("click", callback, false);
  },

  removeListener(callback: EventListener) {
    window.removeEventListener("click", callback, false);
  }
};
