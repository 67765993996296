import React, { useEffect } from "react";
import { EuiFlexGrid, EuiFlexItem, EuiPageTemplate } from "@elastic/eui";
import { useIsMobile } from "../hooks/useIsMobile";
import {
  InnerPageContentHeight,
  MobileInnerPageContentHeight,
  PageContentHeight,
  PageContentMaxWidth,
} from "@pm-frontend/styles";
import { showRedesignNavigation } from "@pm-assets/js/utils/redesign-routing";
import Features from "@pm-assets/js/common/feature-flags";
import { GlobalBanners } from "@pm-frontend/shared/layouts/GlobalBanners";

const ApplicationContent = () => <></>;

/***
 *  For wrapping Old UI pages
 */
const OldUI = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <EuiPageTemplate.Section
        className="eui-yScroll"
        contentProps={{ style: { padding: "0px" } }}
        restrictWidth={false}
      >
        {Features.isWorkLogBannerEnabled() && <GlobalBanners />}
        {children}
      </EuiPageTemplate.Section>
    );
  }

  return (
    <EuiPageTemplate.Section
      className="eui-yScroll"
      contentProps={{ style: { padding: "0px 16px" } }}
      restrictWidth={false}
      style={{
        maxHeight: PageContentHeight,
        height: PageContentHeight,
        paddingBottom: "8px",
      }}
      id="application-content-oldui"
    >
      {Features.isWorkLogBannerEnabled() && <GlobalBanners />}
      {children}
    </EuiPageTemplate.Section>
  );
};

/***
 * Wrap page content you want to be scrollable (most pages probably)
 *
 * @param documentTitle sets the document title property (the text shows up on the browser tab)
 */
const Scrollable = ({
  alwaysShowScroll = false,
  children,
  documentTitle,
}: {
  alwaysShowScroll?: boolean;
  children: React.ReactNode;
  documentTitle: string;
}) => {
  const isMobile = useIsMobile();
  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  if (isMobile) {
    const paddingMobile = "4px 16px 64px 16px";
    return (
      <EuiPageTemplate.Section
        className="eui-scrollBar"
        contentProps={{ style: { padding: paddingMobile } }}
        restrictWidth={true}
        style={{ overflowY: alwaysShowScroll ? "scroll" : "auto" }}
      >
        {Features.isWorkLogBannerEnabled() && <GlobalBanners />}
        {children}
      </EuiPageTemplate.Section>
    );
  }

  // the height settings on new pages are different based on whether they
  // are wrapped in the new UI or old UI.  This can be removed once we stop
  // wrapping new pages in the old UI
  const showUIRedesignNavigation = showRedesignNavigation();
  // padding strategy
  // always 4px at the top
  // gap between banner/content is always shown
  // banner components return a spacer if nothing shown
  const paddingDesktop = "4px 0px";
  return (
    <EuiPageTemplate.Section
      className="eui-scrollBar"
      contentProps={{ style: { padding: paddingDesktop } }}
      restrictWidth={PageContentMaxWidth}
      style={{
        maxHeight: showUIRedesignNavigation ? PageContentHeight : undefined,
        padding: "0px 8px 0px 16px",
        // leaving this logic here in case we decide to alter later
        overflowY: alwaysShowScroll ? "scroll" : "scroll",
      }}
      id="application-content-scrollable"
    >
      <EuiFlexGrid
        style={{
          gap: "16px",
          height: "100%",
          maxHeight: "100%",
          gridTemplateRows: "auto 1fr",
          gridTemplateColumns: "100%",
        }}
      >
        <EuiFlexItem grow={false}>{Features.isWorkLogBannerEnabled() && <GlobalBanners />}</EuiFlexItem>
        <EuiFlexItem grow={true}>{children}</EuiFlexItem>
      </EuiFlexGrid>
    </EuiPageTemplate.Section>
  );
};

/***
 * Wrap page content you do not want to be scrollable
 * Examples are the large chat view and the large file preview view
 * The container will stretch to fill all available pages
 *
 * @param documentTitle sets the document title (<title>) property (the text shows up on the browser tab)
 *
 */
const NonScrollable = ({
  children,
  forceMobileNoScroll = false,
  documentTitle,
}: {
  children: React.ReactNode;
  forceMobileNoScroll?: boolean;
  documentTitle: string;
}) => {
  const isMobile = useIsMobile();
  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  const paddingMobile = "4px 16px 16px 16px";
  // all pages scroll on mobile unless overridden
  if (isMobile && !forceMobileNoScroll) {
    return (
      <EuiPageTemplate.Section className="eui-yScroll" contentProps={{ style: { padding: paddingMobile } }}>
        {Features.isWorkLogBannerEnabled() && <GlobalBanners />}
        {children}
      </EuiPageTemplate.Section>
    );
  }

  // padding strategy
  // always 4px at the top
  // gap between banner/content is always shown
  // banner components return a spacer if nothing shown
  const paddingDesktop = "4px 0px 0px 0px";
  return (
    <EuiPageTemplate.Section
      contentProps={{
        style: {
          height: isMobile ? MobileInnerPageContentHeight : InnerPageContentHeight,
          padding: isMobile ? paddingMobile : paddingDesktop,
        },
      }}
      restrictWidth={isMobile ? undefined : PageContentMaxWidth}
    >
      <EuiFlexGrid
        gutterSize="m"
        style={{
          gap: isMobile ? "0px" : "16px",
          overflow: "hidden",
          height: "100%",
          maxHeight: "100%",
          gridTemplateRows: "auto 1fr",
          gridTemplateColumns: "100%",
        }}
      >
        <EuiFlexItem grow={false}>{Features.isWorkLogBannerEnabled() ? <GlobalBanners /> : null}</EuiFlexItem>
        <EuiFlexItem grow={true} style={{ overflow: "hidden" }}>
          {children}
        </EuiFlexItem>
      </EuiFlexGrid>
    </EuiPageTemplate.Section>
  );
};

ApplicationContent.Scrollable = Scrollable;
ApplicationContent.NonScrollable = NonScrollable;
ApplicationContent.OldUI = OldUI;

export { ApplicationContent };
