import MeldFileActions from "../actions/meld-file-actions";
import meldFileApi from "../apis/meld-file-api";

let MeldFileSource = {
  fetchFilesForMeld() {
    return {
      remote(state, meldId) {
        return meldFileApi.fetchFilesForMeld(meldId);
      },

      shouldFetch(state, meldId, force) {
        return true;
      },

      success: MeldFileActions.fetchFilesDone,
      error: MeldFileActions.fetchFilesFail,
      loading: MeldFileActions.fetchFiles
    };
  }
};

export default MeldFileSource;
