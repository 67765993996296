import React from "react";

export function fitToContent(ref: React.RefObject<any>, maxHeight: number, minHeight: number) {
  let text = ref.current;
  if (!text) {
    return;
  }

  if (text.scrollHeight < minHeight) {
    return;
  }

  /* Accounts for rows being deleted, pixel value may need adjusting */
  if (text.clientHeight === text.scrollHeight) {
    text.style.height = "30px";
  }

  let adjustedHeight = text.clientHeight;
  if (!maxHeight || maxHeight > adjustedHeight) {
    adjustedHeight = Math.max(text.scrollHeight, adjustedHeight);
    if (maxHeight) {
      adjustedHeight = Math.min(maxHeight, adjustedHeight);
    }
    if (adjustedHeight > text.clientHeight) {
      text.style.height = adjustedHeight + 4 + "px";
    }
  }
}
