var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import AddressUtils from "../../utils/address-utils";
import { asImmer } from "../../../../app/utils/decorators";
import * as C from "../../constants";
class VendorUtils {
  static getEmailAddress(vendor, emptyMsg = "No email") {
    return vendor.get("email") || emptyMsg;
  }
  static getPhone(vendor, emptyMsg = "No phone") {
    vendor = vendor;
    if (!vendor) {
      return emptyMsg;
    }
    return vendor.phone || emptyMsg;
  }
  static getFax(vendor, emptyMsg = "No Fax") {
    return vendor.get("fax") || emptyMsg;
  }
  static getAddressLine1(vendor, emptyMsg = "No Address") {
    return AddressUtils.getAddressLine1(vendor.get("address"), emptyMsg);
  }
  static getAddressLine2(vendor, ...args) {
    return AddressUtils.getAddressLine2(vendor.get("address"), ...args);
  }
  static getAddressLine3(vendor, ...args) {
    return AddressUtils.getAddressLine3(vendor.get("address"), ...args);
  }
  static getFormattedCityStateZip(vendor) {
    return AddressUtils.getFormattedCityStateZip(vendor.get("address"));
  }
}
VendorUtils.vendorSpecialties = Object.keys(C.VendorSpecialtiesText).sort();
__decorateClass([
  asImmer()
], VendorUtils, "getPhone", 1);
export default VendorUtils;
