import createReactClass from "create-react-class";
import React from "react";

/* tslint:disable:max-line-length */
export default createReactClass<{ classes?: string }, {}>({
  render() {
    return (
      <svg
        className={this.props.classes}
        xmlns="https://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 20 26"
      >
        <path
          d="M10 26S0 15.134 0 9.75 4.478 0 10 0c5.523 0 10 4.366 10 9.75S10 26 10 26zm0-22.75c-3.682 0-6.667 2.91-6.667 6.5s2.985 6.5 6.667 6.5 6.667-2.91 6.667-6.5-2.985-6.5-6.667-6.5zM10 13c-1.84 0-3.333-1.455-3.333-3.25S8.16 6.5 10 6.5c1.84 0 3.333 1.455 3.333 3.25S11.84 13 10 13z"
          id="path-1"
          fillRule="evenodd"
        />
      </svg>
    );
  },
});
/* tslint:enable:max-line-length */
