import ActionDropdown from "../../action-dropdown";
import DownArrow from "../../svg/down-arrow-svg";
import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";
import SortField from "./sort-field";
import { parseQuery } from "../utils/location-utils";
import { withRouter } from "react-router-dom";

let TicketSorter = createReactClass({
  contextTypes: {
    location: PropTypes.object,
  },

  render() {
    return (
      <span className="filter-sort-panel">
        <ActionDropdown
          containerPositionClass="left-container"
          toggleButton={this.getToggleButton()}
          onClose={this.onActionDropdownClose}
        >
          <span onClick={this.onDropdownClicked}>{this.renderSortFields()}</span>
        </ActionDropdown>
      </span>
    );
  },

  renderSortFields() {
    let sortFields = [];

    // eslint-disable-next-line no-unused-vars
    for (let [field, humanReadable] of this.props.sortFields.entries()) {
      sortFields.push(
        <SortField
          pageName={this.props.pageName}
          fieldName={field}
          key={field}
          ordering={this.getCurrentOrdering()}
          onSortFieldClicked={this.props.onSortFieldClicked}
        >
          {humanReadable}
        </SortField>
      );
    }

    return sortFields;
  },

  getToggleButton() {
    let currentSortField = this.props.sortFields.get(this.getCurrentOrdering());

    return (
      <span>
        Sort by <span className="current-sort-field">{currentSortField} </span>
        <span className="toggler">
          <DownArrow />
        </span>
      </span>
    );
  },

  getCurrentOrdering() {
    return this.props.ordering || parseQuery(this.props.location).ordering;
  },
});

export default withRouter(TicketSorter);
