window.PM.xhr = {};
window.PM.xhr.activeCount = 0;

export function decrementXHRCount() {
  window.PM.xhr.activeCount--;
}

export function incrementXHRCount() {
  window.PM.xhr.activeCount++;
}
