import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiHeaderSectionItemButton, EuiPopover, EuiText } from "@elastic/eui";
import { css } from "@emotion/react";

import { BorderColor, colors } from "@pm-frontend/styles";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import { PmAvatar } from "@pm-frontend/shared/components/Avatar/PmAvatar";

const settingsSectionButtonCss = css`
  & {
    span.euiButtonEmpty__content {
      padding: 0px;
    }
  }
`;

interface HeaderSettingsItemProps {
  children: React.ReactNode;
  isOpen: boolean;
  setSettingsIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  avatarProps: {
    name: string;
    color: string;
  };
  managementName: string;
}

const HeaderSettingsItem = ({
  children,
  avatarProps,
  isOpen,
  setSettingsIsOpen,
  managementName,
}: HeaderSettingsItemProps) => {
  const isMobile = useIsMobile();

  const avatarSettingsButton = <PmAvatar name={avatarProps.name} color={avatarProps.color} />;

  const settingsAvatarButton = isMobile ? (
    <EuiHeaderSectionItemButton
      aria-label="Settings"
      onClick={() => setSettingsIsOpen((state) => !state)}
      css={settingsSectionButtonCss}
    >
      {avatarSettingsButton}
    </EuiHeaderSectionItemButton>
  ) : (
    <EuiHeaderSectionItemButton
      aria-label="Settings"
      style={{ padding: "0px" }}
      onClick={() => setSettingsIsOpen((state) => !state)}
      css={settingsSectionButtonCss}
    >
      <EuiFlexGroup
        direction="row"
        responsive={false}
        alignItems="center"
        style={{
          gap: "26px",
          padding: "8px 12px",
          border: `1px solid ${BorderColor}`,
          borderRadius: "8px",
        }}
      >
        <EuiFlexItem grow={false}>
          <EuiText
            size="xs"
            color={colors.neutrals.gray700}
            style={{ fontWeight: 600, fontFamily: '"Open Sans", Helvetica, Roboto, Arial, sans-serif' }}
          >
            {managementName}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>{avatarSettingsButton}</EuiFlexItem>
      </EuiFlexGroup>
    </EuiHeaderSectionItemButton>
  );

  if (isMobile) {
    return (
      <>
        {settingsAvatarButton}
        {isOpen && <EuiFlyout onClose={() => setSettingsIsOpen(false)}>{children}</EuiFlyout>}
      </>
    );
  }

  return (
    <EuiPopover
      button={settingsAvatarButton}
      isOpen={isOpen}
      closePopover={() => setSettingsIsOpen(false)}
      hasArrow={false}
    >
      {children}
    </EuiPopover>
  );
};

export { HeaderSettingsItem };
