import React from "react";
import { BaseIcon, IconProps } from "../base-icon";

export const WorklogDeleted: React.FC<IconProps> = props => (
  <BaseIcon {...props}>
    <path d="M9 29.23H3a1 1 0 01-1-1V3a1 1 0 011-1h16a1 1 0 011 1v6a1 1 0 002 0V3a3 3 0 00-3-3H3a3 3 0 00-3 3v25.23a3 3 0 003 3h6a1 1 0 100-2z" />
    <path d="M26 12a14 14 0 1014 14 14 14 0 00-14-14zm0 26a12 12 0 1112-12 12 12 0 01-12 12zM16 5H6a1 1 0 000 2h10a1 1 0 000-2zM16 10H6a1 1 0 000 2h10a1 1 0 000-2zM11 15H6a1 1 0 000 2h5a1 1 0 000-2z" />
    <path d="M32.71 19.29a1 1 0 00-1.42 0L26 24.59l-5.29-5.3a1 1 0 00-1.42 1.42l5.3 5.29-5.3 5.29a1 1 0 000 1.42 1 1 0 001.42 0l5.29-5.3 5.29 5.3a1 1 0 001.42 0 1 1 0 000-1.42L27.41 26l5.3-5.29a1 1 0 000-1.42z" />
  </BaseIcon>
);
