import { Field, FieldProps } from "formik";
import React from "react";

import { Input, Props as InputProps } from "./input";

interface Props extends InputProps {
  /**
   * Gets passed to formik's <Field /> component.
   * Used to match the field to form value for validation and state management.
   */
  match: string;
}

export const FormikInput: React.FC<Props> = ({ match, ...rest }) => (
  <Field name={match}>{({ field, meta }: FieldProps) => <Input errors={meta.error} {...field} {...rest} />}</Field>
);

FormikInput.displayName = "FormikInput";
