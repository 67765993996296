import ErrorUtils from "./error-utils";

import React from "react";

let ErrorRenderingUtils = {
  getInitialState() {
    return {
      submissionTried: false
    };
  },

  prepareSubmit(func: (...args: any[]) => void) {
    return (...args: any[]) => {
      this.setState({ submissionTried: true });
      func(...args);
    };
  },

  isValid() {
    return this.getValidationErrors().length === 0;
  },

  renderErrors() {
    if (this.state.submissionTried || (this.isReadyToShowErrors && this.isReadyToShowErrors())) {
      return ErrorUtils.transformErrorsToHtml(this.getErrors());
    }
  },

  getErrors() {
    let errors = this.getServerErrors() || [];
    errors = errors.concat(this.getValidationErrors());
    if (this.getExtraErrors) {
      errors = errors.concat(this.getExtraErrors());
    }

    return errors;
  },

  getValidationErrors() {
    let errors: string[] = [];

    let constraints = this.getConstraints ? this.getConstraints() : this.props.constraints || this.state.constraints;
    if (constraints) {
      errors = errors.concat(ErrorUtils.getValidationErrorList(this.getModelState(), constraints));
    }

    if (this.getExtraValidationErrors) {
      errors = errors.concat(this.getExtraValidationErrors());
    }

    return errors;
  },

  displayErrors(errors: any[]) {
    /* Differs from `renderErrors`, in that we just want to render some errors without
         checking the usual places or checking if they should be shown */

    if (errors) {
      return ErrorUtils.transformErrorsToHtml(errors);
    }
  },

  getErrorString(errors: any[], prefix: string = "") {
    return `${prefix} ${errors.join(", ")}`;
  },

  getServerErrors() {
    let errors = this.state.serverErrors || [];

    if (this.state.status) {
      errors = errors.concat(this.state.status.get("msgs") || []);
    }

    return errors;
  },

  getModelState() {
    return this.state.data.toJS();
  },

  resetValidation() {
    this.setState({ submissionTried: false });
  },

  initValidation() {
    this.setState({ submissionTried: true });
  }
};

export default ErrorRenderingUtils;

export interface ErrorUtilsProps {
  initValidation: () => void;
  isDataValid: (data: any, constraints: any) => boolean;
  resetValidation: () => void;
  submissionTried: boolean;
}

export function ErrorUtilsHOC(ComposedComponent: React.ComponentClass<ErrorUtilsProps>) {
  return class extends React.Component<any, { submissionTried: boolean }> {
    constructor(props: any) {
      super(props);

      this.state = {
        submissionTried: false
      };

      this.initValidation = this.initValidation.bind(this);
      this.isDataValid = this.isDataValid.bind(this);
    }

    render() {
      return (
        <ComposedComponent
          {...this.props}
          {...this.state}
          initValidation={this.initValidation}
          resetValidation={this.resetValidation}
          isDataValid={this.isDataValid}
        />
      );
    }

    resetValidation() {
      this.setState({ submissionTried: false });
    }

    initValidation() {
      this.setState({ submissionTried: true });
    }

    isDataValid(data: any, constraints: any) {
      return ErrorUtils.getValidationErrorList(data, constraints).length === 0;
    }
  };
}
