import UserActions from "../actions/user-actions";
import UserAPI from "../apis/user-api";

let UserSource = {
  fetchCurrentUser() {
    return {
      remote(state, { cid } = {}) {
        return UserAPI.fetchCurrentUser();
      },

      shouldFetch(state, force) {
        return true;
      },

      success: UserActions.fetchCurrentDone,
      error: UserActions.fetchCurrentFail
    };
  }
};

export default UserSource;
