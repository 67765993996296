import classnames from "classnames";
import React from "react";

import QuestionMark from "../../svg/question-mark-svg";

export interface TooltipProps {
  message: React.ReactNode;
  preventDefault?: boolean;
}

class Tooltip extends React.Component<TooltipProps, { hover: boolean }> {
  clicked: boolean;

  constructor(props: TooltipProps) {
    super(props);

    this.state = { hover: false };
    this.handleTooltipClicked = this.handleTooltipClicked.bind(this);
    this.handleWindowClicked = this.handleWindowClicked.bind(this);
  }

  render() {
    const messageClasses = classnames({
      message: true,
      visible: this.state.hover
    });

    const iconClasses = classnames({
      "tooltip-circle": true,
      open: this.state.hover
    });

    return (
      <div className="tooltip" onClick={this.handleTooltipClicked}>
        <div className={messageClasses}>{this.props.message}</div>
        <div className={iconClasses}>{this.props.children || <QuestionMark />}</div>
      </div>
    );
  }

  handleTooltipClicked(e: React.MouseEvent<HTMLDivElement>) {
    if (this.props.preventDefault) {
      e.preventDefault();
    }

    this.clicked = true;
    this.setState({ hover: !this.state.hover });
  }

  handleWindowClicked() {
    if (!this.clicked) {
      this.setState({ hover: false });
    } else {
      this.clicked = false;
    }
  }

  componentWillMount() {
    window.addEventListener("click", this.handleWindowClicked);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleWindowClicked);
  }
}

export default Tooltip;
