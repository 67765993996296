var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import classnames from "classnames";
import { isArray } from "lodash";
import FormError from "../form-error/form-error";
import { MdCheck, MdRemove } from "react-icons/md";
export const Checkbox = (_a) => {
  var _b = _a, {
    errors,
    inline = false,
    partial = false,
    children,
    className
  } = _b, elementProps = __objRest(_b, [
    "errors",
    "inline",
    "partial",
    "children",
    "className"
  ]);
  const classes = classnames(
    "pm-forms-checkbox-wrap",
    {
      "pm-forms-checkbox-inline": inline
    },
    className ? className.split(" ") : ""
  );
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: classes }, /* @__PURE__ */ React.createElement("input", __spreadValues({ type: "checkbox", className: "pm-forms-true-checkbox" }, elementProps)), /* @__PURE__ */ React.createElement("span", { className: `pm-forms-checkbox ${errors ? "pm-forms-checkbox-error" : ""}` }, partial ? /* @__PURE__ */ React.createElement(MdRemove, null) : /* @__PURE__ */ React.createElement(MdCheck, null))), children, errors && /* @__PURE__ */ React.createElement(FormError, null, isArray(errors) ? errors.join(", ") : errors));
};
Checkbox.displayName = "Checkbox";
export default Checkbox;
