import React from "react";
import { Box, Flex, Icon, Link as ChakraLink, Spacer, Text, VStack, Divider } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { IoCheckmark } from "react-icons/io5";
import { SeverityBadge } from "./severity-badge";
import { OwnerServiceDetailsProps } from "../../types/owner-services.types";
import { H2, RichTextEditor } from "@pmeld/components";

import { LinkHelper } from "@pm-shared/utils/link";
import { deserialize } from "@pm-shared/utils/slate";
import { SERVICE_FREQUENCIES } from "../../utils/owner-services/constants";
import Currency from "@pm-assets/js/utils/currency";
import { track, PCPServiceEditFormOpened } from "@pm-app/utils/analytics";

export const Details: React.FC<OwnerServiceDetailsProps> = ({
  ownerServiceData,
  backQuery,
}: OwnerServiceDetailsProps) => {
  return (
    <VStack data-test="details-container" spacing={8} align="stretch">
      <Flex justifyContent="space-between">
        <SeverityBadge
          data-test="details-severity"
          severity={ownerServiceData.severity}
          alignItems="center"
          display="flex"
          fontWeight="semibold"
          fontSize={"sm"}
          justifyContent="center"
          borderRadius={32}
          padding={3}
          height={7}
          color="white"
        />
        <Spacer />
        {LinkHelper.getOrgType() === "m" && (
          <Box>
            <ChakraLink
              onClick={() => track(PCPServiceEditFormOpened())}
              as={Link}
              to={
                backQuery
                  ? {
                      pathname: LinkHelper.normalize(`owner-services/${ownerServiceData.id}/edit`),
                      state: {
                        backQuery,
                      },
                    }
                  : {
                      pathname: LinkHelper.normalize(`owner-services/${ownerServiceData.id}/edit`),
                    }
              }
            >
              <Text fontSize="md" fontWeight="semibold" color="blue.600">
                Edit
              </Text>
            </ChakraLink>
          </Box>
        )}
      </Flex>
      <Box>
        <Text color="gray.400" data-test="details-frequency" fontWeight="400" marginBottom={1} mt={4}>
          {SERVICE_FREQUENCIES[ownerServiceData.frequency]}
        </Text>
        <H2 id={"owner-services.title"} as="h3" size="md" marginBottom={2}>
          {ownerServiceData.title}
        </H2>
        <Flex>
          <Box display="flex">
            <Box data-test="details-price" fontWeight="700" fontSize={20}>
              {Currency.dollar(Number(ownerServiceData.price))}
            </Box>
            <Box color="gray.400" fontWeight="semibold" letterSpacing="wide" fontSize="xs" ml="2" marginTop={2}>
              Estimated Per Unit
            </Box>
          </Box>
          <Spacer />
          {ownerServiceData?.owner_opted_in && (
            <Box color="green.500" fontSize="xs" ml="2" marginTop={2}>
              <Icon as={IoCheckmark} /> Opted-In
            </Box>
          )}
        </Flex>
      </Box>

      <Divider borderColor={"blue.50"} />

      <Box>
        <Box fontWeight="bold" fontSize="sm" marginBottom={2}>
          Description
        </Box>
        <RichTextEditor readOnly={true} initialValue={deserialize(ownerServiceData.description)} testPrefix="test" />
      </Box>
    </VStack>
  );
};
