import React from "react";
import { EuiSpacer } from "@elastic/eui";

import { AuthUtils } from "@pm-frontend/shared/utils/auth-utils";
import { ManagementGlobalBanners } from "@pm-frontend/apps/management/subcomponents/ManagementGlobalBanners";

const GlobalBannerEmptySpace = () => {
  return (
    <div>
      <EuiSpacer style={{ height: "12px" }} />
    </div>
  );
};

const GlobalBanners = () => {
  if (AuthUtils.getActiveOrgType() === "m") {
    return <ManagementGlobalBanners />;
  }
  return <GlobalBannerEmptySpace />;
};

export { GlobalBanners, GlobalBannerEmptySpace };
