import React from "react";

interface EmailAnchorProps {
  email?: string;
}

export default class EmailAnchor extends React.Component<EmailAnchorProps, any> {
  render() {
    let email = this.props.email || this.props.children;
    return <a href={`mailto:${email}`}>{this.props.children}</a>;
  }
}
