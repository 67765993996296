var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React from "react";
import { authParams, mApi } from "@pm-assets/js/common/api";
import Button, { ButtonTypes } from "@pm-app/components/forms/button/button";
import ButtonGroup from "@pm-app/components/forms/button-group/button-group";
import Modal, { ModalBody, ModalFooter } from "@pm-app/components/modal/modal";
export const deleteMeldFiles = (files, onDelete, onError) => __async(void 0, null, function* () {
  yield Promise.all(
    files.map((file) => __async(void 0, null, function* () {
      try {
        yield mApi.mOIApiMeldsFilesDelete(__spreadValues({ id: file.id }, authParams));
      } catch (error) {
        if (onError) {
          onError(error);
        }
      }
    }))
  );
  if (onDelete) {
    onDelete();
  }
});
export const DeleteFilesModal = (_a) => {
  var _b = _a, { files, multiple = false, onDelete } = _b, props = __objRest(_b, ["files", "multiple", "onDelete"]);
  var _a2;
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    }
  };
  return /* @__PURE__ */ React.createElement(Modal, __spreadValues({ header: "Delete Confirmation" }, props), /* @__PURE__ */ React.createElement(ModalBody, null, !multiple && /* @__PURE__ */ React.createElement(React.Fragment, null, "You are about to delete the file ", /* @__PURE__ */ React.createElement("strong", null, (_a2 = files[0]) == null ? void 0 : _a2.filename), ", which cannot be undone. Are you sure?"), multiple && /* @__PURE__ */ React.createElement(React.Fragment, null, "You are about to delete ", /* @__PURE__ */ React.createElement("strong", null, files.length), " file(s), which cannot be undone. Are you sure?")), /* @__PURE__ */ React.createElement(ModalFooter, null, /* @__PURE__ */ React.createElement(ButtonGroup, null, /* @__PURE__ */ React.createElement(Button, { buttonType: ButtonTypes.Primary, onClick: handleDelete }, "Delete File", multiple ? "(s)" : ""), /* @__PURE__ */ React.createElement(Button, { buttonType: ButtonTypes.Neutral, onClick: handleClose }, "Cancel"))));
};
export default DeleteFilesModal;
