import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiFlyout } from "@elastic/eui";

import { ApplicationSidebarBase, ApplicationSidebarBaseProps } from "./ApplicationSidebarBase";
import { useSwipe } from "@pm-frontend/shared/hooks/useSwipe";

const ApplicationSidebarMobile = (props: ApplicationSidebarBaseProps) => {
  useSwipe({ handleSwipeLeft: props.toggleOpen, handleSwipeRight: () => {} });
  return (
    <EuiFlyout onClose={props.toggleOpen} side="left" maxWidth={false}>
      <EuiFlexGroup
        direction="column"
        style={{ padding: "19px 24px 64px 24px", gap: "32px", scrollbarGutter: "stable" }}
        className="eui-yScroll"
      >
        <ApplicationSidebarBase {...props} />
        {props.mobileItems &&
          props.mobileItems.map((item, index) => (
            <EuiFlexItem grow={false} key={index}>
              {item}
            </EuiFlexItem>
          ))}
      </EuiFlexGroup>
    </EuiFlyout>
  );
};

export { ApplicationSidebarMobile };
