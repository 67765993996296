import React from "react";
import { None } from "../../types/common";

const DEFAULT_MODE = "_DEFAULT";

interface Modes {
  [key: string]: string;
}
type defaultFunctionType = (props: any) => string | None;

interface WithModesState {
  mode: string;
}

export interface ModeInjectedProps {
  mode: string;
  onModeChange: (mode: string) => void;
  onResetMode: () => void;
}

export default function withModes<ChildProps, ParentProps = {}>(
  Comp: React.ComponentType<ChildProps & ParentProps & ModeInjectedProps>,
  modes: Modes,
  defaultModeFn?: defaultFunctionType
) {
  return class WithModes extends React.Component<ChildProps & ParentProps, WithModesState> {
    constructor(props: ChildProps & ParentProps) {
      super(props);

      if (!modes) {
        throw Error("`modes` is required");
      }

      let mode = DEFAULT_MODE;

      if (defaultModeFn) {
        mode = defaultModeFn(this.props) || mode;
      }

      this.state = { mode };

      this.changeMode = this.changeMode.bind(this);
      this.resetMode = this.resetMode.bind(this);
    }

    render() {
      return (
        <Comp mode={this.state.mode} {...this.props} onModeChange={this.changeMode} onResetMode={this.resetMode} />
      );
    }

    changeMode(newMode: string) {
      this.setState({ mode: newMode });
    }

    resetMode() {
      this.setState({ mode: DEFAULT_MODE });
    }
  };
}
