import AltContainer from "alt-container";
import React from "react";
import vendorInvoiceFileStore from "../stores/vendor-invoice-file-store";
import { ImmutableList, ImmutableMap, Omit } from "../../types/common";
import { SmallLoader } from "../../loaders";
import File from "../../common/components/file";
import FilePreview from "../../common/components/file-preview";
import FileViewerShareFile from "../../common/components/file-viewer-share-file";
import FileViewerDeleteFile from "../../common/components/file-viewer-delete-file";
import FileUtils from "../../common/utils/file-utils";
import FileViewerNewWindow from "../../common/components/file-viewer-new-window";
import FileViewerDownloadFile from "../../common/components/file-viewer-download-file";
import withPreviewableFiles, { Injected } from "../../common/components/with-previewable-files";

export interface VendorInvoiceFileProps {
  files: ImmutableList;
  allowDelete: boolean;
  deleteFile?: (file: ImmutableMap, cid: string) => void;
  emptyText?: string;
  isDropdownEnabled?: boolean;
  isSelected?: (file: ImmutableMap) => boolean;
  setExpiration?: () => void;
  shareFile?: (file: ImmutableMap) => void;
  isLoading?: boolean;
}

type Props = VendorInvoiceFileProps & Injected;

class InvoiceFile extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.handleViewerDeleteFile = this.handleViewerDeleteFile.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.renderFilePreviewActions = this.renderFilePreviewActions.bind(this);
  }

  render() {
    if (this.props.files.size === 0 && this.props.isLoading) {
      return <SmallLoader />;
    }

    if (this.props.files.size === 0) {
      return <div className="copy">{this.props.emptyText}</div>;
    }

    const f = this.props.files.map((file: ImmutableMap) => {
      const props = {
        ...this.props,
        deleteFile: this.props.allowDelete ? this.handleDeleteFile : undefined,
        onThumbnailClicked: this.props.navFile,
        file
      };
      return <File key={file.get("id")} {...props} />;
    });

    return (
      <React.Fragment>
        <ul className="files">{f}</ul>
        {this.props.previewingFile && (
          <FilePreview
            files={this.props.files}
            file={this.props.previewingFile}
            onClose={this.props.closePreview}
            navFile={this.props.navFile}
            renderActions={this.renderFilePreviewActions}
          />
        )}
      </React.Fragment>
    );
  }

  renderFilePreviewActions(file: ImmutableMap) {
    return (
      <React.Fragment>
        {this.props.shareFile && <FileViewerShareFile file={file} onShare={this.props.shareFile} />}
        {this.props.allowDelete && <FileViewerDeleteFile file={file} onDelete={this.handleViewerDeleteFile} />}
        {FileUtils.isOpenable(file) && <FileViewerNewWindow file={file} />}
        <FileViewerDownloadFile file={file} />
      </React.Fragment>
    );
  }

  handleDeleteFile(file: ImmutableMap, cid: string) {
    if (this.props.deleteFile) {
      this.props.deleteFile(file, cid);
      this.props.closePreview();
    }
  }

  handleViewerDeleteFile(file: ImmutableMap) {
    this.handleDeleteFile(file, "");
  }
}

const PreviewableVendorInvoiceFile = withPreviewableFiles<VendorInvoiceFileProps>(InvoiceFile);

export class VendorInvoiceFile extends React.Component<VendorInvoiceFileProps> {
  render() {
    return (
      <PreviewableVendorInvoiceFile
        {...this.props}
        isLoading={this.props.isLoading}
        deleteFile={this.props.deleteFile}
      />
    );
  }
}

interface VendorInvoiceFileContainerProps extends Omit<VendorInvoiceFileProps, "files" | "isLoading"> {
  meldId: number;
  files?: any;
}

export default class extends React.Component<VendorInvoiceFileContainerProps> {
  render() {
    return <AltContainer stores={[vendorInvoiceFileStore]} render={this.renderVendorInvoiceFile()} />;
  }

  renderVendorInvoiceFile() {
    const meldId = this.props.meldId;
    return (
      <VendorInvoiceFile
        {...this.props}
        isLoading={vendorInvoiceFileStore.isLoading()}
        files={vendorInvoiceFileStore.getInvoiceFileForMeld(meldId)}
      />
    );
  }
}
