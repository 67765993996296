import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

class VendorAppointmentAPI extends BaseApi {
  static fetchOne(id: number, options = {}) {
    return this.get(ApiUrls.vendorAppointmentDetail(id), options);
  }

  static schedule(id: number, data: any, options = {}) {
    return this.patch(ApiUrls.vendorAppointmentSchedule(id), data, options);
  }

  static requestMoreAvailability(id: number, data: any, options = {}) {
    return this.patch(ApiUrls.vendorAppointmentRequestMoreAvailability(id), data, options);
  }

  static fetchByAssignment(assignmentId: number) {
    return this.get(ApiUrls.vendorAppointmentDetailAlt(assignmentId));
  }
}

export default VendorAppointmentAPI;
