import classnames from "classnames";
import React from "react";

export interface LabelProps {
  className?: string;
}

const Label: React.FC<LabelProps> = props => {
  const classes = classnames("pm-label", props.className);
  return <div className={classes}>{props.children}</div>;
};

export default Label;
