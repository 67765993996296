var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import Linkify from "react-linkify";
import SVGInline from "react-svg-inline";
import { MdTranslate } from "react-icons/md";
import DialogBox from "../../../assets/js/dialog-box";
import { SmallLoader } from "../../../assets/js/loaders";
import CommentActions from "../../../assets/js/meld/actions/comment-actions";
import UpdateCommentModal from "../../../assets/js/meld/components/update-comment-modal";
import CommentStore from "../../../assets/js/meld/stores/comment-store";
import { StatusStates } from "../../../assets/js/stores/base-store";
import { LinkHelper } from "@pm-shared/utils/link";
import Panel from "../../components/containers/Panel";
import UserTypeLabel, { UserTypes } from "../../components/labels/UserTypeLabel";
import Avatar from "../../components/prefabs/Avatar";
import UserName from "../../components/prefabs/UserName";
import HeaderBlock, { HeaderSizes } from "../../components/texts/Header";
import TextBlock, { TextBlockSizes } from "../../components/texts/TextBlock";
import DateUtils from "../../utils/DateUtils";
import CommentUtils from "./utils/comment-utils";
import { postTranslateEnglishData } from "./comment-services";
import Features from "@pm-assets/js/common/feature-flags";
import { track, VendorTranslateToOtherClicked, TenantTranslateToOtherClicked } from "@pm-app/utils/analytics";
import { getTranslatedUIPrompt } from "../../../app/views/melds/utils/language_utils";
const DeleteEnvelope = require("../../assets/icons/DeleteEnvelope.svg");
const Envelope = require("../../assets/icons/Envelope.svg");
const OpenEnvelope = require("../../assets/icons/OpenEnvelope.svg");
var CommentModes = /* @__PURE__ */ ((CommentModes2) => {
  CommentModes2["Default"] = "default";
  CommentModes2["Delete"] = "delete";
  CommentModes2["Edit"] = "edit";
  return CommentModes2;
})(CommentModes || {});
var CommentReadIndicators = /* @__PURE__ */ ((CommentReadIndicators2) => {
  CommentReadIndicators2["Read"] = "read";
  CommentReadIndicators2["DeliveryFailed"] = "deliveryFailed";
  CommentReadIndicators2["Unread"] = "unread";
  return CommentReadIndicators2;
})(CommentReadIndicators || {});
export default class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: null,
      mode: "default" /* Default */,
      fetchComplete: false,
      translatedText: null,
      translatedTo: null,
      isTranslating: false,
      rateLimitPassed: false
    };
    this.onClickIndicator = this.onClickIndicator.bind(this);
    this.handleCommentStoreChange = this.handleCommentStoreChange.bind(this);
    this.handleTranslateClick = this.handleTranslateClick.bind(this);
  }
  render() {
    const isTranslationEnabled = () => {
      const orgType = LinkHelper.getOrgType();
      if (orgType === "v") {
        return Features.isGptTranslateEnabled();
      } else if (orgType === "t") {
        return Features.isResidentGptTranslateEnabled();
      }
      return false;
    };
    const activity = this.props.comment.get("activity");
    let formattedReadStatuses;
    if (activity) {
      formattedReadStatuses = this.getFormattedReadStatuses(activity);
    }
    const userType = CommentUtils.getUserType(this.props.comment);
    const editButtons = this.renderEditButtons();
    const visibility = this.renderVisibility();
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "comment" }, /* @__PURE__ */ React.createElement(Panel, null, userType && this.renderAvatar(userType), /* @__PURE__ */ React.createElement("div", { className: "comment-main" }, /* @__PURE__ */ React.createElement("div", { className: "comment-header" }, userType && this.renderUserName(userType), userType && /* @__PURE__ */ React.createElement(UserTypeLabel, { userType }, userType), this.renderCreatedTime()), /* @__PURE__ */ React.createElement(Linkify, null, isTranslationEnabled() ? /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(HeaderBlock, { className: "comment-text" }, this.state.translatedText || this.props.comment.get("text"), this.state.isTranslating && /* @__PURE__ */ React.createElement(SmallLoader, null)), (LinkHelper.getOrgType() === "v" || LinkHelper.getOrgType() === "t") && this.props.show_translation_feature && this.props.default_language !== null && this.props.default_language !== "EN" && /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          color: "var(--brand-meld-blue, #1175CC)",
          cursor: "pointer",
          marginLeft: "-4px"
        },
        onClick: () => this.handleTranslateClick(this.props.comment.get("text"))
      },
      /* @__PURE__ */ React.createElement(MdTranslate, null),
      this.state.rateLimitPassed ? "You have used the maximum translations on this Meld" : this.state.translatedTo === "non-EN" ? getTranslatedUIPrompt("cancel", this.props.default_language) : getTranslatedUIPrompt("translate", this.props.default_language)
    )) : /* @__PURE__ */ React.createElement(HeaderBlock, { className: "comment-text" }, this.props.comment.get("text"))), /* @__PURE__ */ React.createElement("div", { className: "comment-footer" }, this.getReadIndicators(), /* @__PURE__ */ React.createElement(TextBlock, { className: "comment-subtext" }, editButtons, editButtons && visibility && /* @__PURE__ */ React.createElement("div", null, "\u2022"), /* @__PURE__ */ React.createElement("div", null, visibility))))), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames({
          "read-list": true,
          hidden: !this.state.expanded
        })
      },
      formattedReadStatuses ? this.renderReadStatuses(formattedReadStatuses) : this.renderExpanded()
    )), this.renderMode());
  }
  handleTranslateClick(text) {
    this.setState({ isTranslating: true });
    if (this.state.translatedTo === "non-EN") {
      this.setState({
        translatedText: null,
        translatedTo: null,
        isTranslating: false
      });
    } else {
      if (LinkHelper.getOrgType() === "v") {
        track(VendorTranslateToOtherClicked(this.props.default_language));
      } else if (LinkHelper.getOrgType() === "t") {
        track(TenantTranslateToOtherClicked(this.props.default_language));
      }
      postTranslateEnglishData(text, this.props.default_language, this.props.meld_id).then((response) => {
        this.setState({
          translatedText: response.generated_text,
          translatedTo: "non-EN",
          isTranslating: false
        });
      }).catch((e) => {
        this.setState({
          rateLimitPassed: e.message === "Request failed with status code 429" ? true : false,
          translatedTo: null,
          isTranslating: false
        });
      });
    }
  }
  renderUserName(userType) {
    switch (userType) {
      case UserTypes.Vendor:
        return /* @__PURE__ */ React.createElement(HeaderBlock, { className: "user-name", size: HeaderSizes.Medium }, /* @__PURE__ */ React.createElement(UserName, { vendorAgent: this.props.comment.get("agent") }));
      case UserTypes.Manager:
        return /* @__PURE__ */ React.createElement(HeaderBlock, { className: "user-name", size: HeaderSizes.Medium }, /* @__PURE__ */ React.createElement(UserName, { managementAgent: this.props.comment.get("agent") }));
      case UserTypes.Owner:
        return /* @__PURE__ */ React.createElement(HeaderBlock, { className: "user-name", size: HeaderSizes.Medium }, /* @__PURE__ */ React.createElement(UserName, { owner: this.props.comment.get("owner") }));
      case UserTypes.Tenant:
        return /* @__PURE__ */ React.createElement(HeaderBlock, { className: "user-name", size: HeaderSizes.Medium }, /* @__PURE__ */ React.createElement(UserName, { tenant: this.props.comment.get("tenant") }));
    }
  }
  renderAvatar(userType) {
    switch (userType) {
      case UserTypes.Vendor:
        return /* @__PURE__ */ React.createElement(Avatar, { vendorAgent: this.props.comment.get("agent") });
      case UserTypes.Manager:
        return /* @__PURE__ */ React.createElement(Avatar, { managementAgent: this.props.comment.get("agent") });
      case UserTypes.Owner:
        return /* @__PURE__ */ React.createElement(Avatar, { owner: this.props.comment.get("owner") });
      case UserTypes.Tenant:
        return /* @__PURE__ */ React.createElement(Avatar, { tenant: this.props.comment.get("tenant") });
    }
  }
  renderEditButtons() {
    if (this.props.comment.get("editable") && this.props.comment.get("is_owner")) {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("a", { className: "edit", onClick: () => this.setMode("edit" /* Edit */) }, "Edit")), /* @__PURE__ */ React.createElement("div", null, "\u2022"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("a", { className: "delete", onClick: () => this.setMode("delete" /* Delete */) }, "Delete")));
    }
  }
  renderVisibility() {
    const comment = this.props.comment;
    let visibleTo = ["managers"];
    if (!comment.get("hidden_from_vendor")) {
      visibleTo.push("vendors");
    }
    if (!comment.get("hidden_from_tenant")) {
      visibleTo.push("tenants");
    }
    if (!comment.get("hidden_from_owner")) {
      visibleTo.push("owners");
    }
    return `Visible to: ${visibleTo.join(", ")}`;
  }
  renderCreatedTime() {
    return /* @__PURE__ */ React.createElement(TextBlock, { size: TextBlockSizes.XS, className: "comment-timestamp" }, DateUtils.getDateString(new Date(this.props.comment.get("created")), true));
  }
  renderMode() {
    if ("delete" /* Delete */ === this.state.mode) {
      return /* @__PURE__ */ React.createElement(
        DialogBox,
        {
          headerText: "Delete Comment",
          confirmText: "Are you sure you want to delete this comment?",
          dangerBtnText: "Yes",
          cancelBtnText: "No",
          dangerClick: () => {
            if (this.props.onCommentDelete) {
              this.props.onCommentDelete();
            } else {
              CommentActions.deleteComment(this.props.comment.get("id"));
            }
            this.setMode("default" /* Default */);
          },
          closeClick: () => this.setMode("default" /* Default */)
        }
      );
    } else if ("edit" /* Edit */ === this.state.mode) {
      return /* @__PURE__ */ React.createElement(
        UpdateCommentModal,
        {
          comment: this.props.comment,
          close: () => {
            this.setMode("default" /* Default */);
            if (this.props.onCommentUpdated) {
              this.props.onCommentUpdated();
            }
          }
        }
      );
    }
  }
  renderExpanded() {
    if (!this.state.fetchComplete) {
      return /* @__PURE__ */ React.createElement(SmallLoader, null);
    } else {
      return /* @__PURE__ */ React.createElement("span", null, "There is no status activity for this comment.");
    }
  }
  setMode(mode) {
    this.setState({ mode });
  }
  onClickIndicator(type) {
    if (this.state.expanded === type) {
      this.setState({ expanded: null });
    } else {
      this.setState({ fetchComplete: false });
      CommentStore.fetchComment(this.props.comment.get("id"), this.props.comment.get("clazz"));
      this.setState({ expanded: type });
    }
  }
  renderReadStatuses({
    read,
    deliveryFailed,
    unread
  }) {
    const unreadClasses = classNames("read-text", {
      hidden: this.state.expanded !== "unread" /* Unread */
    });
    const deliveryFailedClasses = classNames("read-text", {
      hidden: this.state.expanded !== "deliveryFailed" /* DeliveryFailed */
    });
    const readClasses = classNames("read-text", {
      hidden: this.state.expanded !== "read" /* Read */
    });
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(TextBlock, { className: unreadClasses }, unread), /* @__PURE__ */ React.createElement(TextBlock, { className: deliveryFailedClasses }, deliveryFailed), /* @__PURE__ */ React.createElement(TextBlock, { className: readClasses }, read));
  }
  getFormattedReadStatuses(activity) {
    let read;
    let deliveryFailed;
    let unread;
    if (activity) {
      const managementAgentReadStatuses = CommentUtils.getReadStatuses(
        this.props.comment.get("read_by_management_agents"),
        activity.get("management_agent_email_statuses"),
        activity.get("management_agent_message_statuses"),
        "management_agent"
      );
      const vendorAgentReadStatuses = CommentUtils.getReadStatuses(
        this.props.comment.get("read_by_vendor_agents"),
        activity.get("vendor_agent_email_statuses"),
        activity.get("vendor_agent_message_statuses"),
        "vendor_agent"
      );
      const tenantReadStatuses = CommentUtils.getReadStatuses(
        this.props.comment.get("read_by_tenants"),
        activity.get("tenant_email_statuses"),
        activity.get("tenant_message_statuses"),
        "tenant"
      );
      const ownerReadStatuses = CommentUtils.getReadStatuses(
        this.props.comment.get("read_by_owners"),
        activity.get("owner_email_statuses"),
        activity.get("owner_message_statuses"),
        "owner"
      );
      if (!managementAgentReadStatuses.read.isEmpty() || !vendorAgentReadStatuses.read.isEmpty() || !tenantReadStatuses.read.isEmpty() || !ownerReadStatuses.read.isEmpty()) {
        read = CommentUtils.getFormattedReadStatuses(
          "Read by",
          managementAgentReadStatuses.read,
          vendorAgentReadStatuses.read,
          tenantReadStatuses.read,
          ownerReadStatuses.read
        );
      } else {
        read = /* @__PURE__ */ React.createElement("span", null, "This comment has not yet been read by anyone.");
      }
      if (!managementAgentReadStatuses.deliveryFailed.isEmpty() || !vendorAgentReadStatuses.deliveryFailed.isEmpty() || !tenantReadStatuses.deliveryFailed.isEmpty() || !ownerReadStatuses.deliveryFailed.isEmpty()) {
        deliveryFailed = CommentUtils.getFormattedReadStatuses(
          "Unable to deliver to",
          managementAgentReadStatuses.deliveryFailed,
          vendorAgentReadStatuses.deliveryFailed,
          tenantReadStatuses.deliveryFailed,
          ownerReadStatuses.deliveryFailed
        );
      } else {
        deliveryFailed = /* @__PURE__ */ React.createElement("span", null, "There have been no delivery failures.");
      }
      if (!managementAgentReadStatuses.unread.isEmpty() || !vendorAgentReadStatuses.unread.isEmpty() || !tenantReadStatuses.unread.isEmpty() || !ownerReadStatuses.unread.isEmpty()) {
        unread = CommentUtils.getFormattedReadStatuses(
          "Unread by",
          managementAgentReadStatuses.unread,
          vendorAgentReadStatuses.unread,
          tenantReadStatuses.unread,
          ownerReadStatuses.unread
        );
      } else {
        unread = /* @__PURE__ */ React.createElement("span", null, "This comment has been delivered to all recipients.");
      }
    }
    return { read, deliveryFailed, unread };
  }
  getReadIndicators() {
    const isManager = LinkHelper.getOrgType() === "m";
    if (isManager) {
      const unreadIconClasses = classNames("info-icon", "unread-indicator", {
        expanded: this.state.expanded === "unread" /* Unread */
      });
      const deliveryFailedIconClasses = classNames("info-icon", "delivery-failed-indicator", {
        expanded: this.state.expanded === "deliveryFailed" /* DeliveryFailed */
      });
      const readIconClasses = classNames("info-icon", "read-indicator", {
        expanded: this.state.expanded === "read" /* Read */
      });
      return /* @__PURE__ */ React.createElement("div", { className: "comment-read-indicators" }, /* @__PURE__ */ React.createElement(
        "div",
        {
          className: unreadIconClasses,
          title: "Unread",
          onClick: () => this.onClickIndicator("unread" /* Unread */)
        },
        /* @__PURE__ */ React.createElement(SVGInline, { className: "inline-icon", svg: Envelope })
      ), /* @__PURE__ */ React.createElement(
        "div",
        {
          className: deliveryFailedIconClasses,
          title: "Delivery failed",
          onClick: () => this.onClickIndicator("deliveryFailed" /* DeliveryFailed */)
        },
        /* @__PURE__ */ React.createElement(SVGInline, { className: "inline-icon", svg: DeleteEnvelope })
      ), /* @__PURE__ */ React.createElement(
        "div",
        {
          className: readIconClasses,
          title: "Read",
          onClick: () => this.onClickIndicator("read" /* Read */)
        },
        /* @__PURE__ */ React.createElement(SVGInline, { className: "inline-icon", svg: OpenEnvelope })
      ));
    }
  }
  handleCommentStoreChange() {
    let status = CommentStore.getStatus("DEFAULT_CID");
    let statusState = status.get("statusState");
    if (statusState === StatusStates.DONE || statusState === StatusStates.FAIL) {
      this.setState({ fetchComplete: true });
    }
  }
  componentDidMount() {
    return __async(this, null, function* () {
      CommentStore.listen(this.handleCommentStoreChange);
    });
  }
  componentWillUnmount() {
    CommentStore.unlisten(this.handleCommentStoreChange);
  }
}
Comment.propTypes = {
  comment: PropTypes.object.isRequired
};
