import AgentUtils from "../management/utils/agent-utils";
import * as C from "../constants";
import I from "immutable";
import ImmutableUtils from "../common/utils/immutable-utils";

let MaintenanceUtils = {
  getMaintenanceName(maintenance: any) {
    let m = maintenance;

    if (!(m instanceof I.Map)) {
      m = I.fromJS(m);
    }
    let type = m.get("type");

    if (type === C.MaintTypes.MANAGEMENT_AGENT) {
      return `${AgentUtils.getFullName(m)} (Maintenance)`;
    } else if (type === C.MaintTypes.VENDOR) {
      return `${m.get("name")} (Vendor)`;
    } else if (type === C.MaintTypes.VENDOR_PREFERENCE_LIST) {
      return `${m.get("name")} (Vendor Preference List)`;
    } else if (type === C.MaintTypes.INVITED_VENDOR) {
      return `${m.get("email")} (Invited vendor)`;
    }
  },

  filterDisabledOnly(
    maintList: I.List<I.Map<string, any>>,
    propertyGroups: I.Set<I.Map<string, any>>,
    selected: I.Set<I.Map<string, any>>
  ) {
    let maintenance = I.List();

    if (selected && selected.first()) {
      const firstElement = selected.first();
      const type = firstElement.get("type");

      if (type !== C.MaintTypes.MANAGEMENT_AGENT) {
        maintenance = maintList.filter((m: I.Map<string, any>) => {
          return m.get("id") !== firstElement.get("id");
        }) as I.List<I.Map<string, any>>;
      } else {
        maintenance = maintList.filter((m: I.Map<string, any>) => {
          return m.get("type") !== C.MaintTypes.MANAGEMENT_AGENT;
        }) as I.List<I.Map<string, any>>;
      }
    }

    if (propertyGroups && propertyGroups.size > 0) {
      maintenance = maintenance.concat(
        maintList.filter((maint: I.Map<string, any>) => {
          const type = maint.get("type");

          if (type === C.MaintTypes.MANAGEMENT_AGENT) {
            let agentPgs = ImmutableUtils.mapIDs(maint.get("property_groups"));
            return propertyGroups.intersect(agentPgs.toSet()).size === 0;
          } else if (type === C.MaintTypes.VENDOR) {
            const excluded = ImmutableUtils.mapIDs(maint.get("excluded_property_groups"));
            if (excluded.size && propertyGroups && propertyGroups.size) {
              return propertyGroups.subtract(excluded).size === 0;
            }
          }
          return false;
        })
      ) as I.List<I.Map<string, any>>;
    }

    return maintenance.toSet().toList();
  },

  filterNotSelected(maintList: I.List<I.Map<string, any>>, selected: I.List<I.Map<string, any>>) {
    if (selected) {
      // If they are already assigned (selected) then don't disable them. Otherwise they can't be removed
      // from the list
      const selectedKeys = selected
        .map((maint: I.Map<string, any>) => I.Map({ type: maint.get("type"), id: maint.get("id") }))
        .toSet();
      return maintList.filter((maint: I.Map<string, any>) => {
        const maintKey = I.Map({
          type: maint.get("type"),
          id: maint.get("id")
        });
        return !selectedKeys.has(maintKey);
      });
    }
    return maintList;
  },

  getBusinessPhone(maintenance: I.Map<string, any>) {
    let type = maintenance.get("type");

    if (type === C.MaintTypes.MANAGEMENT_AGENT) {
      return AgentUtils.getBusinessPhone(maintenance);
    }
  }
};

export default MaintenanceUtils;
