var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import classnames from "classnames";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdCheck } from "react-icons/md";
import { FlexJustifyContent } from "../../utils/constants";
export var ButtonTypes = /* @__PURE__ */ ((ButtonTypes2) => {
  ButtonTypes2["Primary"] = "primary";
  ButtonTypes2["Neutral"] = "neutral";
  ButtonTypes2["Tertiary"] = "tertiary";
  ButtonTypes2["Danger"] = "danger";
  return ButtonTypes2;
})(ButtonTypes || {});
export const Button = React.forwardRef((props, ref) => {
  const _a = props, {
    submit = false,
    buttonType = "neutral" /* Neutral */,
    flexColumns = false,
    justifyContent = FlexJustifyContent,
    showLoading = false,
    showSuccess = false,
    children,
    className
  } = _a, elementProps = __objRest(_a, [
    "submit",
    "buttonType",
    "flexColumns",
    "justifyContent",
    "showLoading",
    "showSuccess",
    "children",
    "className"
  ]);
  const classes = classnames(
    "pm-forms-button",
    `pm-forms-button-${buttonType}`,
    {
      "flex-columns": flexColumns,
      "pm-forms-button-loading": showLoading,
      "pm-forms-button-success": showSuccess
    },
    className ? className.split(" ") : ""
  );
  return /* @__PURE__ */ React.createElement("button", __spreadValues({ ref, className: classes, type: submit ? "submit" : "button" }, elementProps), /* @__PURE__ */ React.createElement("div", { className: "pm-forms-button-children-container" }, children), showLoading && /* @__PURE__ */ React.createElement("div", { className: "pm-forms-button-indication-container" }, /* @__PURE__ */ React.createElement(AiOutlineLoading3Quarters, { className: "pm-forms-button-indication" })), showSuccess && /* @__PURE__ */ React.createElement("div", { className: "pm-forms-button-indication-container" }, /* @__PURE__ */ React.createElement(MdCheck, { className: "pm-forms-button-indication" })));
});
Button.displayName = "Button";
export default Button;
