import _ from "lodash";
import cardAPI from "../apis/card-api";
import I from "immutable";
import React from "react";
import { ErrorHandler } from "../../../../app/utils/ErrorHandler";

export default function withActiveCreditCard(Comp) {
  return class WithActiveCreditCardContainer extends React.Component {
    constructor(options) {
      super(options);
      this.state = {
        card: null,
        loading: true,
        errors: []
      };

      this.fetchCard = this.fetchCard.bind(this);
    }

    render() {
      return (
        <Comp
          {...this.props}
          loading={this.state.loading}
          card={this.state.card}
          errors={this.state.errors}
          onCardUpdated={this.fetchCard}
        />
      );
    }

    fetchCard() {
      this.setState({ loading: true, errors: [] });

      cardAPI
        .fetchDefault()
        .then(res => {
          if (!_.isEmpty(res.data)) {
            this.setState({ card: I.fromJS(res.data) });
          }
        })
        .catch(res => {
          this.setState({ errors: res.messages });
        })
        .then(res => {
          this.setState({ loading: false });
        })
        .catch(error => ErrorHandler.handleAxiosError(error));
    }

    componentWillMount() {
      this.fetchCard();
    }
  };
}
