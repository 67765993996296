import AddPropertyOwnerForm from "./add-property-owner-form";
import Modal from "../modal";
import ModalHeader from "../common/components/modal-header";
import OwnerStore from "./stores/owner-store";
import React from "react";
import { LargeLoader } from "../loaders";

interface OwnerAddModalProps {
  close: () => void;
  data?: any;
  isModalOpen: boolean;
  ownerIdExclusions?: number[];
  propertyId: number;
}

class OwnerAddModal extends React.Component<OwnerAddModalProps> {
  render() {
    return (
      <Modal isOpen={this.props.isModalOpen}>
        <ModalHeader>Add Owner</ModalHeader>
        <div className="modal-body">{this.renderBody()}</div>
      </Modal>
    );
  }

  renderBody() {
    if (OwnerStore.isLoading()) {
      return <LargeLoader />;
    }

    return (
      <AddPropertyOwnerForm
        data={this.props.data}
        propertyId={this.props.propertyId}
        ownerIdExclusions={this.props.ownerIdExclusions}
        close={this.props.close}
        onOwnerPropertiesUpdated={this.props.close}
        onOwnerCreated={this.props.close}
      />
    );
  }
}

export default OwnerAddModal;
