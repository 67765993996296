import { withRouter } from "react-router-dom";
import { FloatingLargeLoader } from "../../loaders";
import EmptyView from "@pm-shared/components/empty/empty-view";
import React from "react";
import { History } from "history";
import { estimateLineItemsURL } from "../../meld/apis/estimates-api";
import axios from "axios";
import messageActions from "../../common/actions/message-actions";
import I from "immutable";
import Meld from "../../svg/meld-svg";
import Link from "@pm-shared/utils/link";
import Pin from "../../svg/pin-svg";
import MeldUtils from "../../meld/utils/meld-utils";
import CreateEstimateLineItemsHeaderRow from "./estimate-line-items-header";
import Plus from "../../svg/plus-svg";
import Currency from "../../utils/currency";
import ButtonContainer from "../../../../app/components/containers/ButtonContainer";
import Button, { ButtonTypes } from "../../../../app/components/buttons/Button";
import EstimateLineItem from "../meld/estimate-line-items";
import HistoryUtils from "../../common/utils/history-utils";
import _ from "lodash";
import { BaseLineItemCostTypeEnum } from "../../common/openapi";
import EstimateLineItemsApi from "../../meld/apis/estimate-line-items-api";
import { EstimateStatuses } from "../../../../assets/js/constants";
import ErrorUtils from "@pm-assets/js/utils/error-utils";
import { ImmutableMap } from "@pm-assets/js/types/common";
import MeldAPI from "@pm-assets/js/meld/apis/meld-api";
import { Estimate } from "@pm-assets/js/meld/shared/estimate";

interface EstimateDetailTabContentProps {
  meld: ImmutableMap | any;
  estimateID: number;
  history: History;
  vendorHub?: boolean;
  refreshMeld: (showLoader?: boolean) => void;
}

interface EstimateDetailTabContentState {
  saving: boolean;
  submitted: boolean;
  errors: string[];
  loading: boolean;
  estimate_line_items: Map<string, any> | any;
  hasLineItems: boolean;
  notes: string;
  days_to_complete?: number;
  days_until_start?: number;
  pm_fee?: number;
  estimate?: Estimate;
}

class EstimateDetailTabContent extends React.Component<EstimateDetailTabContentProps, EstimateDetailTabContentState> {
  constructor(props: any) {
    super(props);

    this.state = {
      saving: false,
      submitted: false,
      errors: [],
      estimate_line_items: I.fromJS([this.createFreshLineItem()]),
      notes: "",
      // eslint-disable-next-line no-undefined
      days_to_complete: undefined,
      // eslint-disable-next-line no-undefined
      days_until_start: undefined,
      // eslint-disable-next-line no-undefined
      pm_fee: undefined,
      loading: true,
      hasLineItems: false,
    };

    this.handleDeleteRequested = this.handleDeleteRequested.bind(this);
    this.handleAddNewLineItem = this.handleAddNewLineItem.bind(this);
    this.createFreshLineItem = this.createFreshLineItem.bind(this);
    this.renderLineItems = this.renderLineItems.bind(this);
    this.handleLineItemChange = this.handleLineItemChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.calcTotal = this.calcTotal.bind(this);
    this.getExtraErrors = this.getExtraErrors.bind(this);
    this.onChangeSetNotes = this.onChangeSetNotes.bind(this);
    this.onChangeSetDaysToComplete = this.onChangeSetDaysToComplete.bind(this);
    this.onChangeSetDaysUntilStart = this.onChangeSetDaysUntilStart.bind(this);
  }

  render() {
    const meld = this.props.meld;
    if (meld) {
      const estimate = meld.get("cost_estimate");

      if (
        (!this.state.hasLineItems || estimate.get("estimate_status") === EstimateStatuses.ESTIMATE_IN_PROGRESS) &&
        !this.props.vendorHub
      ) {
        return <EmptyView data-test={"estimates_line_items_view.empty_content"} message="No Line Items" />;
      }

      const disableLineItemInput = !(
        this.props.vendorHub && estimate?.get("estimate_status") === EstimateStatuses.ESTIMATE_IN_PROGRESS
      );
      const disableFeeInput = !(
        !this.props.vendorHub && estimate?.get("estimate_status") === EstimateStatuses.ESTIMATE_SUBMITTED
      );

      return (
        <div className="create-invoice">
          <div className="detail-view">
            <div className="small-centered small-12 columns detail-content">
              <div className="detail-header">
                <div className="row">
                  <div className="columns small-12 heading">
                    <h2 className="detail-title">Add Items</h2>
                  </div>
                </div>
              </div>
              <div className="detail-body" data-test="detail-view.estimate-line-items-view">
                <div className="invoice-summary">
                  <span className="invoice-meld-description">
                    <Meld />
                    <span>
                      <Link to={`/meld/${meld.get("id")}/summary/`}>{meld.get("brief_description")}</Link>
                    </span>
                  </span>
                  <span className="invoice-meld-address">
                    <Pin />
                    <span>{MeldUtils.getAbbreviatedPropertyListLine1(meld)} </span>
                    <span>{MeldUtils.getAbbreviatedPropertyListLine2(meld)}</span>
                  </span>
                </div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="invoice-table">
                    <div className="row invoice-header-row">
                      <CreateEstimateLineItemsHeaderRow />
                    </div>
                    <div className="invoice-line-items">{this.renderLineItems(disableLineItemInput)}</div>
                    {/* vendors are not allowed to add line items if the estimate was already submitted */}
                    {!disableLineItemInput && (
                      <div
                        className="add-item-row"
                        onClick={this.handleAddNewLineItem}
                        data-test="vendor-estimate-details.add-line-item"
                      >
                        <div className="line-item-add">
                          <span className="line-item-add-icon">
                            <Plus />
                          </span>
                          <span>Add line item</span>
                        </div>
                      </div>
                    )}
                    <div className="total-separator" />
                    <div className="total row">
                      <div className="columns small-12 large-2 large-push-10">
                        <div className="total">
                          <div className="total-label">Total</div>
                          <div className="total-amount">{Currency.dollar(this.calcTotal())}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ marginBottom: "20px" }}>
                    <div className="columns small-12 large-7 text-right">
                      <label>How many days will you need before beginning the work?</label>
                    </div>
                    <div className="columns small-12 large-5 text-left">
                      <input
                        type="number"
                        data-test="estimate-detail.line-items.lead-time"
                        value={this.state.days_until_start ? this.state.days_until_start : ""}
                        onChange={this.onChangeSetDaysUntilStart()}
                        placeholder="Please type the number of days."
                        disabled={disableLineItemInput}
                      />
                    </div>
                  </div>

                  <div className="row" style={{ marginBottom: "20px" }}>
                    <div className="columns small-12 large-7 text-right">
                      <label>How many days will you need to complete the work?</label>
                    </div>
                    <div className="columns small-12 large-5 text-left">
                      <input
                        type="number"
                        data-test="estimate-detail.line-items.completion-time"
                        value={this.state.days_to_complete ? this.state.days_to_complete : ""}
                        onChange={this.onChangeSetDaysToComplete()}
                        placeholder="Please type the number of days."
                        disabled={disableLineItemInput}
                      />
                    </div>
                  </div>

                  {!this.props.vendorHub && (
                    <div className="row" style={{ marginBottom: "20px" }}>
                      <div className="columns small-12 large-7 text-right">
                        <label>PM fee:</label>
                      </div>
                      <div className="columns small-12 large-5 text-left">
                        <input
                          type="number"
                          data-test="pm-fee-input"
                          value={this.state.pm_fee}
                          onFocus={
                            this.state.pm_fee?.toString() === "0.00"
                              ? (e) => {
                                  e.target.value = "";
                                }
                              : () => {
                                  return;
                                }
                          }
                          onChange={this.onChangeSetPMFee()}
                          placeholder="Please type the PM fee."
                          disabled={disableFeeInput}
                        />
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="columns small-12">
                      <div className="label-container">
                        <label>Notes</label>
                      </div>
                      <textarea
                        name="notes"
                        data-test="estimate-detail.line-items.notes"
                        className="invoice-notes"
                        placeholder="Enter notes"
                        value={this.state.notes ? this.state.notes : ""}
                        onChange={this.onChangeSetNotes()}
                        disabled={disableLineItemInput}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="columns">{this.renderErrors()}</div>
                  </div>

                  <div className="footer">
                    <div className="footer-content">
                      <ButtonContainer>
                        {/* vendors are not allowed to edit line items if the estimate was already submitted */}
                        {(!disableLineItemInput || !disableFeeInput) && (
                          <>
                            <Button
                              className="expenditures-cancel-btn"
                              buttonType={ButtonTypes.Cancel}
                              onClick={this.cancel}
                              disabled={this.state.saving}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="expenditures-save-btn"
                              data-test={"estimate-save-draft-btn"}
                              buttonType={ButtonTypes.Primary}
                              onClick={() => this.handleSubmit(true)}
                              disabled={this.state.saving}
                            >
                              Save Draft
                            </Button>
                            <Button
                              className="expenditures-save-btn"
                              data-test={"estimate-submit-btn"}
                              buttonType={ButtonTypes.Primary}
                              onClick={() => this.handleSubmit(false)}
                              disabled={this.state.saving}
                            >
                              Save and Submit
                            </Button>
                          </>
                        )}
                      </ButtonContainer>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.loading) {
      return <FloatingLargeLoader />;
    }

    if (this.state.errors) {
      return <EmptyView message="The requested line items could not be found." />;
    }
  }

  componentDidMount() {
    if (this.props.meld) {
      let estimate = this.props.meld.get("cost_estimate");

      if (estimate) {
        this.setState({
          notes: estimate.get("notes"),
          days_to_complete: estimate.get("days_to_complete"),
          days_until_start: estimate.get("days_until_start"),
          pm_fee: estimate.get("pm_fee"),
        });
      }

      const lineItemsURL = estimateLineItemsURL(estimate.get("id"));

      axios
        .get(lineItemsURL)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.setState({
              estimate_line_items: I.fromJS(response.data),
              loading: false,
              hasLineItems: true,
            });
          }
        })
        .catch((result) => {
          this.setState({ loading: false, errors: result.messages });
          messageActions.storeError({ body: result.messages[0] });
        });
    }
  }

  createFreshLineItem() {
    const estimateID = this.props.estimateID;
    return I.fromJS({
      cost_estimate: estimateID,
      quantity: 1,
      unit_cost: 0,
      unit_price: 0,
      line_item_type: BaseLineItemCostTypeEnum.LABOR,
      _cid: _.uniqueId("line_item_"),
    });
  }

  handleAddNewLineItem() {
    let updatedLineItems = this.state.estimate_line_items.push(this.createFreshLineItem());
    this.setState({ estimate_line_items: updatedLineItems });
  }

  handleDeleteRequested(lineItem: Map<string, any> | any) {
    let itemIndex = this._findItemIndex(lineItem);

    let lineItems = this.state.estimate_line_items;

    let updatedLineItems = lineItems.slice(0, itemIndex).concat(lineItems.slice(itemIndex + 1));

    this.setState({ estimate_line_items: updatedLineItems });
  }

  handleLineItemChange(lineItem: Map<string, any> | any) {
    let itemIndex = this._findItemIndex(lineItem);
    let updatedLineItems = this.state.estimate_line_items.set(itemIndex, lineItem);

    this.setState({ estimate_line_items: updatedLineItems });
  }

  handleSubmit(isDraft: boolean) {
    this.setState({ errors: [] });
    if (this.isValid()) {
      this.setState({ saving: true });
      let xhr;
      let meld = this.props.meld;
      const estimate = meld.get("cost_estimate");
      let estimate_status = estimate.get("estimate_status");

      if (this.state.hasLineItems) {
        xhr = EstimateLineItemsApi.update(estimate.get("id"), this.state.estimate_line_items);
      } else {
        xhr = EstimateLineItemsApi.createLineItems(estimate.get("id"), this.state.estimate_line_items);
      }

      xhr
        .then(() => {
          this.setState({ saving: false, submitted: true });
        })
        .catch((result) => {
          this.setState({ saving: false });
          this.setState({ saving: false, errors: result.messages });
        });

      if (!isDraft && estimate_status === EstimateStatuses.ESTIMATE_IN_PROGRESS) {
        estimate_status = EstimateStatuses.ESTIMATE_SUBMITTED;
        let data = {
          is_complete: true,
          date: new Date(),
        };

        MeldAPI.markComplete(meld.get("id"), data)
          .then(() => {
            this.setState({ submitted: true });
          })
          .catch((result) => {
            this.setState({ saving: false, errors: result.messages });
          });
      }

      EstimateLineItemsApi.updateEstimate(estimate.get("id"), {
        estimate_status,
        notes: this.state.notes,
        days_to_complete: this.state.days_to_complete,
        days_until_start: this.state.days_until_start,
        pm_fee: this.state.pm_fee,
      })
        .then(() => {
          this.setState({ saving: false });
          HistoryUtils.replace(this.props.history, `meld/${meld.get("id")}/summary`);
          this.props.refreshMeld();
        })
        .catch((result) => {
          this.setState({ saving: false, errors: result.messages });
        });
    }
  }

  renderLineItems(disableInput: boolean) {
    return this.state.estimate_line_items.map((lineItem: any) => {
      const estimateLineItemsProps = {
        lineItem,
        onLineItemChanged: this.handleLineItemChange,
        onDeleteRequested: this.handleDeleteRequested,
        disableInput,
      };
      return <EstimateLineItem key={lineItem.get("id") || lineItem.get("_cid")} {...estimateLineItemsProps} />;
    });
  }

  cancel() {
    HistoryUtils.replace(this.props.history, `melds/melding/?saved_filter=default`);
  }

  _findItemIndex(lineItem: Map<string, any> | any) {
    let _cid = lineItem.get("_cid");

    let id = lineItem.get("id");

    return this.state.estimate_line_items.findIndex((lItem: Map<string, any>) => {
      if (_cid) {
        return _cid === lItem.get("_cid");
      } else {
        return id === lItem.get("id");
      }
    });
  }

  calcTotal() {
    let total = this.state.estimate_line_items.reduce(
      (sum: any, lineItem: any) => sum + lineItem.get("unit_cost") * lineItem.get("quantity"),
      0
    );
    return total || 0;
  }

  getExtraErrors() {
    return this.state.errors;
  }

  renderErrors() {
    if (this.state.errors) {
      return (
        <div className="row">
          <div className="columns small-centered">
            <div className="error" data-test="estimate_detail.error">
              {this.state.errors}
            </div>
          </div>
        </div>
      );
    }
  }

  onChangeSetNotes() {
    return (e: React.ChangeEvent<any>) => {
      this.setState({ notes: e.target.value });
    };
  }

  onChangeSetDaysToComplete() {
    return (e: React.ChangeEvent<any>) => {
      this.setState({ days_to_complete: e.target.value === "" ? undefined : e.target.value });
    };
  }

  onChangeSetDaysUntilStart() {
    return (e: React.ChangeEvent<any>) => {
      this.setState({ days_until_start: e.target.value === "" ? undefined : e.target.value });
    };
  }

  onChangeSetPMFee() {
    return (e: React.ChangeEvent<any>) => {
      this.setState({ pm_fee: e.target.value });
    };
  }

  isValid() {
    const constraints = {
      estimate_line_items: {
        lineItems: {},
      },
      notes: {
        length: {
          maximum: 15000,
          tooLong: "^ Notes must be under 15,000 characters",
        },
      },
      days_until_start: {
        estimateDays: {
          message: "^ The number of days until starting the estimate must be greater than or equal to zero.",
        },
      },

      days_to_complete: {
        estimateDays: {
          message: "^ The number of days to complete the estimate must be greater than or equal to zero.",
        },
      },

      pm_fee: {
        estimatePMFee: {
          message: "^ The PM fee estimate must be greater than or equal to zero.",
        },
      },
    };

    const errors = I.fromJS(
      ErrorUtils.getValidationErrorList(
        {
          estimate_line_items: this.state.estimate_line_items.toJS(),
          notes: this.state.notes,
          days_until_start: this.state.days_until_start,
          days_to_complete: this.state.days_to_complete,
          pm_fee: this.state.pm_fee,
        },
        constraints
      )
    );

    this.setState({ errors });

    return errors.size === 0;
  }
}

export default withRouter(EstimateDetailTabContent);
