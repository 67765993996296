let MomentUtils = {
  isBetweenInclusive(toCheck, start, end) {
    return toCheck.isSame(start) || toCheck.isBetween(start, end);
  },

  intersects(start1, end1, start2, end2) {
    return (
      start2.isBetween(start1, end1) ||
      end2.isBetween(start1, end1) ||
      start1.isBetween(start2, end2) ||
      end1.isBetween(start2, end2) ||
      (start1.isSame(start2) && end1.isSame(end2))
    );
  }
};

export default MomentUtils;
