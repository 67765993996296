import BackArrow from "./svg/back-arrow-svg";
import createReactClass from "create-react-class";
import ForwardArrow from "./svg/forward-arrow-svg";
import Link from "@pm-shared/utils/link";
import PureRenderMixin from "react-addons-pure-render-mixin";
import React from "react";
import classNames from "classnames";

let Pagination = createReactClass<{ nextPage: string; prevPage: string }, {}>({
  mixins: [PureRenderMixin],

  render() {
    return (
      <div className="pagination">
        <div className="pagination-controls">
          {this.renderPrev()}
          {this.renderNext()}
        </div>
      </div>
    );
  },

  renderPrev() {
    if (this.props.prevPage) {
      return (
        <Link to={this.props.prevPage} className="pagination-control prev" normalize={false}>
          <BackArrow />
        </Link>
      );
    }
  },

  renderNext() {
    if (this.props.nextPage) {
      return (
        <Link to={this.props.nextPage} className="pagination-control next" normalize={false}>
          <ForwardArrow />
        </Link>
      );
    }
  },
});

export default Pagination;

interface NonLinkPaginationProps {
  hasPrev: boolean;
  hasNext: boolean;
  onNext: () => void;
  onPrev: () => void;
  pageCount?: number;
  currentPage?: number;
  onPageSelect?: (page: number) => void;
}

let NonLinkPagination = createReactClass<NonLinkPaginationProps, {}>({
  mixins: [PureRenderMixin],

  render() {
    return (
      <div className="pagination">
        <div className="pagination-controls">
          {this.renderPrev()}
          {this.renderPageNumbers()}
          {this.renderNext()}
        </div>
      </div>
    );
  },

  renderPageNumbers() {
    const pageNumberElements = [];
    const firstPage = 1;
    const lastPage = this.props.pageCount;
    const margin = 2;

    const maxBubbles = margin * 2 + 3; // each side of margin plus current page plus ends

    for (let i = 0; i < this.props.pageCount; i++) {
      const num = i + 1;
      const classes = classNames({
        "pagination-control": true,
        active: this.props.currentPage === num,
      });

      let element;
      if (
        this.props.pageCount <= maxBubbles ||
        num === firstPage ||
        num === lastPage ||
        (num <= this.props.currentPage + margin && num >= this.props.currentPage - margin)
      ) {
        element = (
          <span
            className={classes}
            key={i}
            onClick={() => {
              this.props.onPageSelect(i);
            }}
          >
            {num}
          </span>
        );
      } else if (num === this.props.currentPage + margin + 1 || num === this.props.currentPage - margin - 1) {
        element = <span className="pagination-control pagination-ellipses">...</span>;
      }

      if (element) {
        pageNumberElements.push(element);
      }
    }

    return pageNumberElements;
  },

  renderPrev() {
    const classes = classNames({
      "pagination-control": true,
      disabled: !this.props.hasPrev,
      prev: true,
    });

    return (
      <span onClick={this.props.onPrev} className={classes}>
        <BackArrow />
      </span>
    );
  },

  renderNext() {
    const classes = classNames({
      "pagination-control": true,
      disabled: !this.props.hasNext,
      next: true,
    });

    return (
      <span onClick={this.props.onNext} className={classes}>
        <ForwardArrow />
      </span>
    );
  },
});

export { NonLinkPagination };
