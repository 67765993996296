import I from "immutable";
import alt from "../../alt";
import BaseStore from "../../stores/base-store";
import AgentSource from "../sources/agent-source";
import agentActions from "../actions/agent-actions";
import userStore from "../../authentication/stores/user-store";

const TOUR_STEPS_KEY = "tourSteps";

export class ManagementAgentStore extends BaseStore {
  constructor() {
    super();

    this.registerAsync(AgentSource);

    this.bindListeners({
      fetchingAll: agentActions.fetchAll,
      fetchAllDone: agentActions.fetchAllDone,
      fetchAllFail: agentActions.fetchAllFail,
      updating: agentActions.update,
      updateDone: agentActions.updateDone,
      updateFail: agentActions.updateFail,
      deleteDone: agentActions.deactivateDone,
      deleteFail: agentActions.deactivateFail,
      receiveAgentPropertyGroups: agentActions.updatePropertyGroupsDone,
      receiveAgentPropertyGroupFail: agentActions.updatePropertyGroupsFail,
      receiveAgentUserGroups: agentActions.updateUserGroupsDone,
      toggleTour: agentActions.toggleTour,
      completeTourStep: agentActions.completeTourStep,
      resetTour: agentActions.resetTour
    });

    this.exportPublicMethods({
      getCurrentAgent: this.getCurrentAgent.bind(this),
      shouldShowTour: this.shouldShowTour.bind(this),
      isTourStepComplete: this.isTourStepComplete.bind(this),
      getCompletedTourSteps: this.getCompletedTourSteps.bind(this),
      getAgentsWithPropertyGroups: this.getAgentsWithPropertyGroups.bind(this)
    });

    this.state.showTour = window.PM.manager.showTour;
  }

  getAgentsWithPropertyGroups(pgs) {
    let currentPropertyGroups = pgs.toSet();

    return this.getModelState().filter(agent => {
      let propertyGroups = agent.get("property_groups").toSet();
      return propertyGroups.size === 0 || propertyGroups.intersect(currentPropertyGroups).size > 0;
    });
  }

  receiveAgentPropertyGroups(data) {
    let { agentId, propertyGroups } = data;

    let agentToUpdate = this.getAgentToUpdate(agentId, propertyGroups);

    let newState = this.getNewState(agentId, agentToUpdate);

    this.setModelState(newState.sort(this.defaultComparator));
  }

  receiveAgentPropertyGroupFail(data) {
    let { agentId, propertyGroups, error } = data;

    let agentToUpdate = this.getAgentToUpdate(agentId, propertyGroups, error);

    let newState = this.getNewState(agentId, agentToUpdate);

    this.setModelState(newState.sort(this.defaultComparator));
  }

  receiveAgentUserGroups(data) {
    let { agentId, userGroups } = data;

    let agentToUpdate = this.getModelState()
      .find(agent => agent.get("id") === agentId)
      .set("user_groups", I.fromJS(userGroups));

    let newState = this.getModelState()
      .filter(agent => agent.get("id") !== agentId)
      .concat(I.List([agentToUpdate]));

    this.setModelState(newState.sort(this.defaultComparator));
  }

  getAgentToUpdate(agentId, propertyGroups, error = "") {
    return this.getModelState()
      .find(agent => agent.get("id") === agentId)
      .set("property_groups", I.fromJS(propertyGroups))
      .set("error", error);
  }

  getNewState(agentId, agentToUpdate) {
    return this.getModelState()
      .filter(agent => agent.get("id") !== agentId)
      .concat(I.fromJS([agentToUpdate.toJS()]));
  }

  getCurrentAgent() {
    const id = userStore.getCurrentUserId();

    return this.getModelState().find(agent => {
      return agent.getIn(["user", "id"]) === id;
    });
  }

  shouldShowTour() {
    return this.state.showTour;
  }

  toggleTour(payload) {
    this.setState(payload);
  }

  completeTourStep(payload) {
    this.setCompletedTourStep(payload.step);
  }

  isTourStepComplete(step) {
    return this.getCompletedTourSteps()[step] === true;
  }

  getCompletedTourSteps() {
    let tourSteps = window.localStorage.getItem(TOUR_STEPS_KEY);

    if (tourSteps) {
      return JSON.parse(tourSteps);
    } else {
      return {};
    }
  }

  setCompletedTourStep(step) {
    let tourSteps = this.getCompletedTourSteps();
    tourSteps[step] = true;

    if (tourSteps) {
      window.localStorage.setItem(TOUR_STEPS_KEY, JSON.stringify(tourSteps));
    }
  }

  resetTour(payload) {
    window.localStorage.removeItem(TOUR_STEPS_KEY);
    this.setState({ showTour: true });
  }
}

export default alt.createStore(ManagementAgentStore, "ManagementAgentStore");
