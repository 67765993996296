import React from "react";

/* tslint:disable:max-line-length */
export default class Close extends React.Component<{}> {
  render() {
    return (
      <svg className="close-svg" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 401.685 401.685">
        <path d="M401.685 60.34L341.333.01l-140.48 140.502L60.353.012 0 60.34l140.5 140.5L0 341.345l60.352 60.33 140.5-140.5 140.48 140.5 60.353-60.33-140.5-140.5" />
      </svg>
    );
  }
}
/* tslint:enable:max-line-length */
