import React from "react";
import CardUtils from "../../utils/card-utils";

export interface CardDateProps {
  label: string;
  date?: string;
  emptyMessage?: string;
  timeFormat?: string | boolean;
  multi?: boolean;
}

export default class CardDate extends React.Component<CardDateProps> {
  public static defaultProps: Partial<CardDateProps> = {
    emptyMessage: "",
    timeFormat: "h:mma",
  };

  render() {
    let { date, label, emptyMessage, multi } = this.props;

    let time;

    if (this.props.timeFormat) {
      time = (
        <span className="card-accent">
          {" "}
          at {CardUtils.getFormattedTime(date, this.props.timeFormat as string | undefined)}
        </span>
      );
    }

    if (date) {
      return !multi ? (
        <span className="card-date">
          <span className="card-date-label">{label}:</span> {CardUtils.getFormattedDayMonth(date)} {time}
        </span>
      ) : (
        <>
          <span className="card-date">
            {CardUtils.getFormattedDayMonth(date)} {time}
          </span>
          <br />
        </>
      );
    }

    return (
      <span className="card-date">
        <span>{emptyMessage}</span>
      </span>
    );
  }
}
