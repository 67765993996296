/*eslint-disable no-extend-native */
Set.prototype.isSuperset = function(subset) {
  for (let elem of subset) {
    if (!this.has(elem)) {
      return false;
    }
  }
  return true;
};

Set.prototype.union = function(setB) {
  let union = new Set(this);
  for (let elem of setB) {
    union.add(elem);
  }
  return union;
};

Set.prototype.intersection = function(setB) {
  let intersection = new Set();
  for (let elem of setB) {
    if (this.has(elem)) {
      intersection.add(elem);
    }
  }
  return intersection;
};

Set.prototype.difference = function(setB) {
  let difference = new Set(this);
  for (let elem of setB) {
    difference.delete(elem);
  }
  return difference;
};

Set.prototype.symmetricDifference = function(setB) {
  let a = setB.difference(this);
  let b = this.difference(setB);
  return a.union(b);
};
/*eslint-enable no-extend-native */
