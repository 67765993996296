import React from "react";
import { Select } from "@chakra-ui/react";

export enum Visibility {
  Everyone,
  ManagersAndVendorsOnly,
  ManagersAndTenantsOnly,
  ManagersOnly,
  ManagersAndOwnersOnly
}

export interface CommentVisibilitySelectorProps {
  onVisibilityChange: (visibility: Visibility) => void;
  selectedVisibility: Visibility;
  disableOwners?: boolean;
  showManagers?: boolean;
  showOwners?: boolean;
  showVendors?: boolean;
  showTenants?: boolean;
}

export default class CommentVisibilitySelector extends React.Component<CommentVisibilitySelectorProps> {
  constructor(props: CommentVisibilitySelectorProps) {
    super(props);

    this.onVisibilityChange = this.onVisibilityChange.bind(this);
  }

  render() {
    return (
      <Select
        className="message-visibility"
        marginTop="10px"
        onChange={this.onVisibilityChange}
        value={this.props.selectedVisibility}
        width="auto"
      >
        <option value={Visibility.Everyone}>Managers, Vendors, Tenants</option>
        {this.props.showVendors && <option value={Visibility.ManagersAndVendorsOnly}>Managers, Vendors</option>}
        {this.props.showTenants && <option value={Visibility.ManagersAndTenantsOnly}>Managers, Tenants</option>}
        {this.props.showOwners && (
          <option value={Visibility.ManagersAndOwnersOnly} disabled={this.props.disableOwners}>
            Managers, Owners
          </option>
        )}
        {this.props.showManagers && <option value={Visibility.ManagersOnly}>Managers</option>}
      </Select>
    );
  }

  onVisibilityChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.props.onVisibilityChange(+e.currentTarget.value);
  }
}
