import AltContainer from "alt-container";
import I from "immutable";
import meldFileActions from "../actions/meld-file-actions";
import MeldFiles, { MeldFilesProps } from "./meld-files";
import meldFileStore from "../stores/meld-file-store";
import React from "react";
import { Omit } from "../../types/common";
import withPreviewableFiles, { Injected } from "../../common/components/with-previewable-files";

class ManagerMeldFiles extends React.Component<MeldFilesProps & Injected> {
  render() {
    return <MeldFiles {...this.props} deleteFile={this.deleteFile} isLoading={this.props.isLoading} />;
  }

  deleteFile(file: I.Map<string, any>) {
    meldFileActions.deleteFile(file.get("id"));
  }
}

const PreviewableMeldFiles = withPreviewableFiles<MeldFilesProps>(ManagerMeldFiles);

interface MeldFilesContainerProps extends Omit<MeldFilesProps, "files" | "isLoading"> {
  meldId: number;
}

export default class extends React.Component<MeldFilesContainerProps> {
  render() {
    const meldId = this.props.meldId;

    return (
      <AltContainer
        stores={[meldFileStore]}
        render={() => {
          return (
            <PreviewableMeldFiles
              {...this.props}
              isLoading={meldFileStore.isLoading()}
              files={meldFileStore.getFilesForMeld(meldId)}
            />
          );
        }}
      />
    );
  }
}
