var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const DatePicker = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement("path", { d: "M37 6h-6V1a1 1 0 00-2 0v5H11V1a1 1 0 00-2 0v5H3a3 3 0 00-3 3v28a3 3 0 003 3h34a3 3 0 003-3V9a3 3 0 00-3-3zM3 8h6v1.66a1 1 0 002 0V8h18v1.66a1 1 0 002 0V8h6a1 1 0 011 1v5H2V9a1 1 0 011-1zm34 30H3a1 1 0 01-1-1V16h36v21a1 1 0 01-1 1z" }), /* @__PURE__ */ React.createElement("path", { d: "M31 31h-7a1 1 0 01-1-1v-7a1 1 0 011-1h7a1 1 0 011 1v7a1 1 0 01-1 1zm-6-2h5v-5h-5z" }), /* @__PURE__ */ React.createElement("rect", { x: "8", y: "24.5", width: "4", height: "4", rx: "1" }), /* @__PURE__ */ React.createElement("rect", { x: "15.5", y: "24.5", width: "4", height: "4", rx: "1" }));
