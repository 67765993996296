import _ from "lodash";
import AltContainer from "alt-container";
import AppointmentSchedulerMixin from "../mixins/appointment-scheduler-mixin";
import managementAppointmentActions from "../actions/management-appointment-actions";
import ManagementAppointmentStore from "../stores/management-appointment-store";
import moment from "moment";
import createReactClass from "create-react-class";
import React from "react";
import SetIntervalWhileActive from "../../common/components/set-interval-while-active";
import StoreActivityMonitor from "../../common/components/store-activity-monitor";
import { withRouter } from "react-router-dom";

let ManagementAppointmentScheduler = createReactClass({
  mixins: [AppointmentSchedulerMixin],

  loadingStore: ManagementAppointmentStore,

  getSegments() {
    return this.props.appointment.getIn(["meld", "management_availability_segments"]);
  },

  getInitialState() {
    return {
      cid: _.uniqueId("ManagementAppointmentScheduler_")
    };
  },

  schedule(appointmentId, toUpdate) {
    managementAppointmentActions.schedule(appointmentId, toUpdate, {
      cid: this.state.cid
    });
  },

  requestMoreAvailability() {
    managementAppointmentActions.requestMoreAvailability(this.props.appointment.get("id"), { cid: this.state.cid });
  },

  refetch() {
    ManagementAppointmentStore.fetchOne(this.props.appointment.get("id"));
  }
});

export default withRouter(
  SetIntervalWhileActive(
    createReactClass({
      render() {
        let appointmentId = this.props.match.params.id;
        let self = this;

        return (
          <AltContainer
            stores={{
              appointment() {
                let appointments = ManagementAppointmentStore.getOne(appointmentId);
                appointments = self.momentizeStartAndEndDates(appointments);
                return {
                  store: ManagementAppointmentStore,
                  value: appointments
                };
              }
            }}
          >
            <StoreActivityMonitor
              {...this.props}
              stores={[ManagementAppointmentStore]}
              errorMessage="There was a problem loading appointments."
            >
              <ManagementAppointmentScheduler {...this.props} />
            </StoreActivityMonitor>
          </AltContainer>
        );
      },

      momentizeStartAndEndDates(appointments) {
        if (appointments) {
          let newSegments = appointments.getIn(["meld", "management_availability_segments"]).map(appt => {
            let newAppt = appt.setIn(["event", "dtstart"], moment(appt.getIn(["event", "dtstart"])));
            return newAppt.setIn(["event", "dtend"], moment(appt.getIn(["event", "dtend"])));
          });

          return appointments.setIn(["meld", "management_availability_segments"], newSegments);
        }
      },

      componentWillMount() {
        this.props.callAndSetInterval(() => {
          ManagementAppointmentStore.fetchOne(this.props.match.params.id);
        }, 30000);
      }
    })
  )
);
