import I from "immutable";
import jstz from "jstz";
import { Prompt } from "react-router-dom";
import React from "react";
import SVGInline from "react-svg-inline";

import Checkbox from "../common/components/checkbox";
import { EmbeddedErrorView } from "../common/components/embedded-error-view";
import { ErrorList } from "../common/components/errors";
import { GroupedSelection } from "../../../app/components/prefabs/grouped-selection/GroupedSelection";
import { ImmutableMap } from "../types/common";
import { LargeLoader } from "../loaders";
import NotificationSettingsApi from "./apis/notification-settings-api";
import ResetChangesButtons from "./components/reset-changes-buttons";
import TextBlock, { TextBlockSizes } from "../../../app/components/texts/TextBlock";
import Tooltip from "../common/components/tooltip";
import { AuthUtils } from "../utils/auth-utils";

const PopoutIcon = require("../../../assets/svg/Popout.svg");

interface AccountNotificationSettingsProps {
  errors: string[];
  notificationGroups: any;
  refresh: () => void;
  loading?: boolean;
  settings?: ImmutableMap;
}

interface AccountNotificationSettingsState {
  dirty: boolean;
  errors: string[];
  saving: boolean;
  selectedValues: I.Set<string>;
  valueList: I.Set<string>;
  smsNotifications?: boolean;
  timezone?: string;
}

class AccountNotificationSettings extends React.Component<
  AccountNotificationSettingsProps,
  AccountNotificationSettingsState
> {
  constructor(props: AccountNotificationSettingsProps) {
    super(props);

    this.state = {
      dirty: false,
      errors: [],
      saving: false,
      selectedValues: I.Set([]),
      valueList: I.fromJS(this.props.notificationGroups)
        .map((group: ImmutableMap) => {
          return group
            .get("items")
            .map((item: ImmutableMap) => {
              return item.get("values").filter((value: string) => !!value);
            })
            .flatten();
        })
        .flatten()
        .toSet()
    };

    this.toggleValue = this.toggleValue.bind(this);
    this.checkValues = this.checkValues.bind(this);
    this.uncheckValues = this.uncheckValues.bind(this);
    this.initForm = this.initForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  render() {
    if (this.props.errors) {
      return <EmbeddedErrorView />;
    }

    if (this.props.loading && !this.props.settings) {
      return <LargeLoader />;
    }

    if (this.props.settings) {
      const cellPhone = this.props.settings.get("cell_phone");

      const groups = I.fromJS(this.props.notificationGroups);

      const disabled =
        cellPhone && this.state.smsNotifications
          ? I.Set<string>()
          : groups
              .map((group: ImmutableMap) => {
                return group
                  .get("items")
                  .map((item: ImmutableMap) => {
                    return item.get("values").filter((value: string) => value && value.endsWith("_text"));
                  })
                  .flatten();
              })
              .flatten();

      return (
        <div className="notification-settings">
          <Prompt
            when={this.state.dirty}
            message={() =>
              "You have made changes to your settings. Are you sure you want to leave? Any unsaved changes will be lost."
            }
          />
          <TextBlock size={TextBlockSizes.XL} className="section-header">
            Notifications
          </TextBlock>

          <TextBlock size={TextBlockSizes.LG}>
            You can use the following options to enable or disable each of the email or text notifications you want to
            receive.
          </TextBlock>

          <TextBlock size={TextBlockSizes.LG} className="learn-more">
            <a href="https://propertymeld.zendesk.com/hc/en-us/articles/115004845474" target="_blank">
              Learn more about notifications
              <SVGInline svg={PopoutIcon} className="popout-icon" />
            </a>
          </TextBlock>

          <hr className="settings-divider" />

          <TextBlock size={TextBlockSizes.LG} className="section-header">
            Destination and timezone
          </TextBlock>

          <TextBlock size={TextBlockSizes.LG}>
            Text: {cellPhone || "No cell phone"}
            <Tooltip message="Update your cell phone number by selecting the User tab under Account Settings" />
          </TextBlock>

          {cellPhone && (
            <div className="indented">
              <Checkbox
                checked={this.state.smsNotifications}
                onClick={() =>
                  this.setState({
                    dirty: true,
                    smsNotifications: !this.state.smsNotifications
                  })
                }
              />
              <TextBlock size={TextBlockSizes.LG} className="checkbox-label">
                Receive text notifications
              </TextBlock>
            </div>
          )}

          <TextBlock size={TextBlockSizes.LG} className="email">
            Email: {AuthUtils.getUserEmail()}
          </TextBlock>

          <TextBlock size={TextBlockSizes.LG}>Select your timezone for email notifications</TextBlock>
          <select
            value={this.state.timezone}
            onChange={e =>
              this.setState({
                dirty: true,
                timezone: e.target.value
              })
            }
          >
            {I.OrderedMap(jstz.olson.timezones)
              .toOrderedSet()
              .sort()
              .map(timezone => (
                <option key={timezone} value={timezone}>
                  {timezone}
                </option>
              ))}
          </select>

          <hr className="settings-divider" />

          <TextBlock size={TextBlockSizes.LG} className="section-header">
            Customized settings
          </TextBlock>

          <TextBlock size={TextBlockSizes.LG}>
            Toggle on or off each of the following email notification settings to opt in or out of receiving each type
            of email notification.
          </TextBlock>

          <GroupedSelection
            checkValues={this.checkValues}
            columns={I.List(["Email", "Text"])}
            columnsSelectAll={I.List(["Select all email", "Select all text"])}
            columnsUnselectAll={I.List(["Unselect all email", "Unselect all text"])}
            disabledValues={disabled}
            groups={groups}
            selectedValues={this.state.selectedValues}
            toggleValue={this.toggleValue}
            uncheckValues={this.uncheckValues}
          />

          <ErrorList errors={this.state.errors} />

          {this.state.dirty && (
            <ResetChangesButtons
              resetClickFn={this.initForm}
              saveClickFn={this.submitForm}
              resetDisabled={this.state.saving}
              saveDisabled={this.state.saving}
            />
          )}
        </div>
      );
    }
  }

  toggleValue(value: string) {
    if (this.state.selectedValues.contains(value)) {
      this.setState({
        dirty: true,
        selectedValues: this.state.selectedValues.remove(value)
      });
    } else {
      this.setState({
        dirty: true,
        selectedValues: this.state.selectedValues.add(value)
      });
    }
  }

  checkValues(values: I.Set<string>) {
    this.setState({
      dirty: true,
      selectedValues: this.state.selectedValues.union(values)
    });
  }

  uncheckValues(values: I.Set<string>) {
    this.setState({
      dirty: true,
      selectedValues: this.state.selectedValues.subtract(values)
    });
  }

  initForm() {
    if (this.props.settings) {
      this.setState({
        dirty: false,
        selectedValues: this.state.valueList.filter(value => (this.props.settings as ImmutableMap).get(value)).toSet(),
        smsNotifications: this.props.settings.get("sms_notifications"),
        timezone: this.props.settings.get("timezone")
      });
    }
  }

  submitForm() {
    this.setState({ saving: true });

    let data: { [index: string]: boolean | string | undefined } = {
      sms_notifications: this.state.smsNotifications,
      timezone: this.state.timezone
    };

    this.state.valueList.map((value: string) => (data[value] = this.state.selectedValues.contains(value)));

    NotificationSettingsApi.update(data)
      .then(() => {
        this.setState({ dirty: false, errors: [], saving: false });
        this.props.refresh();
      })
      .catch(err => this.setState({ errors: [err.message], saving: false }));
  }

  componentDidUpdate(prevProps: AccountNotificationSettingsProps) {
    if (
      (!prevProps.settings && this.props.settings) ||
      (prevProps.settings &&
        this.props.settings &&
        prevProps.settings.get("sms_notifications_changed") !== this.props.settings.get("sms_notifications_changed"))
    ) {
      this.initForm();
    }
  }
}

export interface AccountNotificationSettingsTabProps {
  notificationGroups: any;
}

interface AccountNotificationSettingsTabState {
  errors: string[];
  loading?: boolean;
  settings?: ImmutableMap;
}

export class AccountNotificationSettingsTab extends React.Component<
  AccountNotificationSettingsTabProps,
  AccountNotificationSettingsTabState
> {
  constructor(props: AccountNotificationSettingsTabProps) {
    super(props);

    this.setState({
      errors: []
    });

    this.refresh = this.refresh.bind(this);
  }

  render() {
    return (
      <AccountNotificationSettings
        errors={this.state.errors}
        loading={this.state.loading}
        notificationGroups={this.props.notificationGroups}
        refresh={this.refresh}
        settings={this.state.settings}
      />
    );
  }

  refresh() {
    this.setState({ loading: true });
    NotificationSettingsApi.fetchCurrent()
      .then(res => {
        this.setState({ loading: false, settings: I.fromJS(res.data) });
      })
      .catch(err => {
        this.setState({ errors: [err.message], loading: false });
      });
  }

  componentWillMount() {
    this.refresh();
  }
}
