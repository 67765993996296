import React from "react";
import { uuid } from "uuidv4";

import { Skeleton, SkeletonProps } from "@chakra-ui/react";

interface Props extends SkeletonProps {
  /**
   * How many <Skeleton /> components to render.
   * @default 5
   */
  amount?: number;
  /**
   * Spacing (in Chakra units) between <Skeleton /> components.
   * @default 2
   */
  spacing?: number;
}

export const SkeletonGroup: React.FC<Props> = ({ amount = 5, height = 4, spacing = 2, width, ...props }) => (
  <>
    {Array.from(Array(amount).keys()).map((_, i) => (
      <Skeleton
        height={height}
        key={uuid()}
        marginTop={i !== 0 ? spacing : 0}
        width={width || `${Math.random() * (100 - 50) + 50}%`}
        {...props}
      />
    ))}
  </>
);

SkeletonGroup.displayName = "SkeletonGroup";
