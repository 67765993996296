import createReactClass from "create-react-class";
import React from "react";
import StrUtils from "../utils/str-utils";

/* tslint:disable:max-line-length */
let Cog = createReactClass<{ classes?: string }, {}>({
  render() {
    return (
      <svg
        className={StrUtils.joinWithSpace(this.props.classes, "cog")}
        xmlns="https://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 36 36"
      >
        <path
          d="M31.5 22.5c2.485 0 4.5-2.015 4.5-4.5s-2.015-4.5-4.5-4.5h-.768c-.16-.452-.343-.892-.548-1.32l.544-.544c1.757-1.757 1.757-4.607 0-6.364-1.757-1.757-4.607-1.757-6.364 0l-.543.544c-.428-.205-.868-.388-1.32-.548V4.5C22.5 2.015 20.485 0 18 0s-4.5 2.015-4.5 4.5v.768c-.452.16-.892.343-1.32.548l-.544-.544c-1.758-1.757-4.607-1.757-6.364 0-1.757 1.757-1.757 4.607 0 6.364l.544.543c-.205.428-.388.868-.548 1.32H4.5C2.015 13.5 0 15.515 0 18s2.015 4.5 4.5 4.5h.768c.16.45.343.892.548 1.32l-.544.544c-1.757 1.757-1.757 4.607 0 6.364 1.757 1.757 4.606 1.757 6.364 0l.544-.544c.428.205.868.388 1.32.548v.768c0 2.485 2.015 4.5 4.5 4.5s4.5-2.015 4.5-4.5v-.768c.452-.16.892-.343 1.32-.548l.544.544c1.757 1.757 4.607 1.757 6.364 0 1.757-1.757 1.757-4.607 0-6.364l-.544-.543c.205-.428.388-.87.548-1.32h.768zm-3.106-3c-.255 1.78-.955 3.414-1.984 4.788l2.138 2.138.06.06c.584.585.584 1.535 0 2.12-.587.586-1.537.586-2.123 0l-.06-.058-2.137-2.138c-1.374 1.03-3.01 1.73-4.788 1.984V31.5c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5v-3.106c-1.78-.255-3.414-.955-4.788-1.984l-2.14 2.138-.057.06c-.586.584-1.536.584-2.122 0-.585-.587-.585-1.537 0-2.123l.06-.06L9.59 24.29c-1.03-1.374-1.73-3.01-1.984-4.788H4.5c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5h3.106c.255-1.78.955-3.414 1.984-4.788l-2.138-2.14-.06-.057c-.584-.586-.584-1.536 0-2.122.587-.585 1.537-.585 2.123 0l.058.06 2.14 2.137c1.373-1.03 3.008-1.73 4.787-1.984V4.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5v3.106c1.78.255 3.414.955 4.788 1.984l2.138-2.138.06-.06c.585-.584 1.535-.584 2.12 0 .586.587.586 1.537 0 2.123l-.058.058-2.138 2.14c1.03 1.373 1.73 3.008 1.984 4.787H31.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5h-3.106zM18 12c-3.314 0-6 2.686-6 6s2.686 6 6 6 6-2.686 6-6-2.686-6-6-6zm0 9c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"
          id="path-1"
          fillRule="evenodd"
        />
      </svg>
    );
  },

  getDefaultProps() {
    return {
      classes: "",
    };
  },
});
/* tslint:enable:max-line-length */

export default Cog;
