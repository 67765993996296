import React from "react";

function ModalHeader(props: { children: any }) {
  return (
    <div className="modal-header">
      <div className="modal-header-content">
        <h2 className="modal-title">{props.children}</h2>
      </div>
    </div>
  );
}

export default ModalHeader;

export function CenteredModalHeader(props: { children: any }) {
  return (
    <div className="modal-header">
      <div className="modal-header-content centered-header-content">
        <h2 className="modal-title">{props.children}</h2>
      </div>
    </div>
  );
}
