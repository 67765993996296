import I from "immutable";
import moment from "moment";

import CalendarUtils from "../../utils/calendar-utils";
import EventUtils from "./event-utils";

class AvailabilityUtils {
  static getDTStartMoment(avail: I.Map<string, any>) {
    return EventUtils.getDTStartMoment(avail.get("event"));
  }

  static getDTEndMoment(avail: I.Map<string, any>) {
    return EventUtils.getDTEndMoment(avail.get("event"));
  }

  static dtstartDifference(avail1: I.Map<string, any>, avail2: I.Map<string, any>) {
    return AvailabilityUtils.getDTStartMoment(avail1).diff(AvailabilityUtils.getDTStartMoment(avail2));
  }

  static dtstartMonth(avail: I.Map<string, any>) {
    return CalendarUtils.month(AvailabilityUtils.getDTStartMoment(avail));
  }

  static getFullDate(avail: I.Map<string, any>) {
    return CalendarUtils.renderFormattedDayMonthYear(AvailabilityUtils.getDTStartMoment(avail));
  }

  static dtstartDayOfMonth(avail: I.Map<string, any>) {
    return CalendarUtils.day(AvailabilityUtils.getDTStartMoment(avail));
  }

  static isAvailabilityScheduleable(availability: I.Map<string, any>) {
    return availability && AvailabilityUtils.getDTEndMoment(availability).isAfter(moment());
  }
}

export default AvailabilityUtils;
