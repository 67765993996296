import classnames from "classnames";
import moment from "moment";
import React from "react";

import Checkmark from "../../svg/checkmark";
import Button, { ButtonTypes } from "../../../../app/components/buttons/Button";
import { FlexJustifyContent } from "../../../../app/components/utils/constants";

interface TimeSlotCheckboxProps {
  start: moment.Moment;
  end: moment.Moment;
  selected: boolean;
  unavailable: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const mql = window.matchMedia(`(max-width: 490px)`);

export default class TimeSlotCheckbox extends React.Component<TimeSlotCheckboxProps> {
  constructor(props: TimeSlotCheckboxProps) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    let { start, end, selected, unavailable } = this.props;

    let timeEventClasses = classnames({
      "time-slot-checkbox": true,
      selected,
      unavailable,
    });

    const buttonType = (() => {
      if (selected) {
        return ButtonTypes.Primary;
      } else if (unavailable) {
        return ButtonTypes.Danger;
      }
    })();

    return (
      <Button
        buttonType={buttonType}
        disabled={unavailable}
        className={timeEventClasses}
        onClick={this.handleClick}
        justifyContent={FlexJustifyContent.SpaceBetween}
        style={{
          padding: mql.matches ? "1em 0.5em" : "1em 3em",
        }}
      >
        <div className="valign-middle-columns">
          <span className="start-to-end-times">
            <div>{start.format("h:mm A")}</div>
            <div>{end.format("h:mm A")}</div>
          </span>
        </div>
        <div className="valign-middle-columns">
          <Checkmark />
        </div>
      </Button>
    );
  }

  handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (!this.props.unavailable) {
      this.props.onClick(e);
    }
  }
}
