import React from "react";

export interface IconProps extends React.SVGAttributes<SVGElement> {
  /**
   * While we usually style via CSS and font-size,
   * helpful to have this.
   *
   * @default 1em
   */
  size?: string | number;
  /**
   * For accessibility, not shown on screen.
   */
  title?: string;
}

export const BaseIcon: React.FC<IconProps> = ({ children, size = "1em", title, ...rest }) => {
  return (
    <svg
      xmlns="https://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      width={size}
      height={size}
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      {...rest}
    >
      {title && <title>{title}</title>}
      {children}
    </svg>
  );
};
