import CompanySettingsActions from "../actions/company-settings-actions";
import CompanySettingsApi from "../apis/company-settings-api";

let CompanySettingsSource = {
  fetchCurrent() {
    return {
      remote(state, { cid } = {}) {
        return CompanySettingsApi.fetchCurrent();
      },

      shouldFetch(state, force) {
        return state.modelState.size === 0;
      },

      success: CompanySettingsActions.fetchCurrentDone,
      error: CompanySettingsActions.fetchCurrentFail
    };
  }
};

export default CompanySettingsSource;
