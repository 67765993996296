import React from "react";

export default class CreateEstimateLineItemsHeaderRow extends React.Component {
  render() {
      return (
        <span>
          <div className="columns small-12 large-4">
            <div>Description</div>
          </div>
          <div className="columns small-12 large-2">
            <div>Quantity</div>
          </div>
          <div className="columns small-12 large-2">
            <div>Cost</div>
          </div>
          <div className="columns small-12 large-2">
              <div>Type</div>
            </div>
          <div className="columns small-12 large-2">
            <div>Total</div>
          </div>
        </span>
     );
  }
}

