import React from "react";

export default class DetailHeader extends React.Component {
  render() {
    return (
      <div className="detail-header">
        <div className="detail-header-content">
          <div className="row">
            <div className="columns small-12 heading">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}
