var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import AltContainer from "alt-container";
import meldFileActions from "../actions/meld-file-actions";
import MeldFiles from "./meld-files";
import meldFileStore from "../stores/meld-file-store";
import React from "react";
import withPreviewableFiles from "../../common/components/with-previewable-files";
class ManagerMeldFiles extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement(MeldFiles, __spreadProps(__spreadValues({}, this.props), { deleteFile: this.deleteFile, isLoading: this.props.isLoading }));
  }
  deleteFile(file) {
    meldFileActions.deleteFile(file.get("id"));
  }
}
const PreviewableMeldFiles = withPreviewableFiles(ManagerMeldFiles);
export default class extends React.Component {
  render() {
    const meldId = this.props.meldId;
    return /* @__PURE__ */ React.createElement(
      AltContainer,
      {
        stores: [meldFileStore],
        render: () => {
          return /* @__PURE__ */ React.createElement(
            PreviewableMeldFiles,
            __spreadProps(__spreadValues({}, this.props), {
              isLoading: meldFileStore.isLoading(),
              files: meldFileStore.getFilesForMeld(meldId)
            })
          );
        }
      }
    );
  }
}
