import I from "immutable";
import PropTypes from "prop-types";
import React, { Component } from "react";

import BaseTag from "../records/tag";
import Link from "@pm-shared/utils/link";
import Pen from "../../svg/pen-svg";
import Tag from "../../svg/tag-svg";

class MeldSummaryTags extends Component<{
  editable?: boolean;
  meld: I.Map<string, any>;
  onEdit?: () => void;
}> {
  static propTypes = {
    editable: PropTypes.bool,
    meld: PropTypes.object.isRequired,
    onEdit: PropTypes.func,
  };

  render() {
    return (
      <>
        <h3>Tags</h3>
        <span className="info-icon">
          <Tag />
        </span>
        {this.renderTags()}
        {this.renderEdit()}
      </>
    );
  }

  renderTags() {
    let tags = this.props.meld.get("tags");
    if (tags.count()) {
      return (
        <span>
          {tags.map((tag: BaseTag, i: number) => (
            <Link to="/melds/melding/" query={{ "tags[]": tag.get("id") }} className="tag pilled square" key={i}>
              {tag.get("name")}
            </Link>
          ))}
        </span>
      );
    } else {
      return "No tags";
    }
  }

  renderEdit() {
    if (this.props.editable && this.props.onEdit) {
      return (
        <span className="info-icon edit-tags" onClick={this.props.onEdit}>
          <Pen />
        </span>
      );
    }
  }
}

export default MeldSummaryTags;
