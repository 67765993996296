import React from "react";

function DetailViewHeading(props: { children: any }) {
  return (
    <div className="row">
      <div className="columns heading-items">{props.children}</div>
    </div>
  );
}

export default DetailViewHeading;
