export enum FlexJustifyContent {
  FlexStart = "justify-flex-start",
  FlexEnd = "justify-flex-end",
  Center = "justify-center",
  SpaceAround = "justify-space-around",
  SpaceBetween = "justify-space-between",
  SpaceEvenly = "justify-space-evenly"
}

export enum FlexAlignItems {
  FlexStart = "align-items-flex-start",
  FlexEnd = "align-items-flex-end",
  Center = "align-items-center",
  Baseline = "align-items-baseline",
  Stretch = "align-itmes-stretch"
}

export const DebounceTime = 200;
