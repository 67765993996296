import I from "immutable";
import React from "react";

import Checkbox from "../../../../assets/js/common/components/checkbox";
import { ImmutableMap } from "../../../../assets/js/types/common";
import Panel from "../../containers/Panel";
import TextBlock, { TextBlockSizes } from "../../texts/TextBlock";
import Tooltip from "../../../../assets/js/common/components/tooltip";

export interface GroupProps {
  checkValues: (values: I.Set<string>) => void;
  columns: I.List<string>;
  disabledValues: I.Set<string>;
  items: I.List<ImmutableMap>;
  selectedValues: I.Set<string>;
  title: string;
  toggleValue: (value: string) => void;
  uncheckValues: (values: I.Set<string>) => void;
}

export class Group extends React.Component<GroupProps> {
  render() {
    return (
      <div className="row">
        <div className="columns">
          <Panel className="pm-selection-group">
            <TextBlock size={TextBlockSizes.LG}>{this.props.title}</TextBlock>
            <div className="pm-group-multiselect">
              {this.props.columns.map((columnName: string, i) => {
                const values = this.props.items
                  .map((item: ImmutableMap) => item.getIn(["values", i]))
                  .filter(value => value)
                  .toSet();

                const checked = values.every(value => {
                  return !value || this.props.selectedValues.contains(value);
                });

                const indeterminate =
                  !checked &&
                  values.some(value => {
                    return !value || this.props.selectedValues.contains(value);
                  });

                const disabled = values.every(value => {
                  return !value || this.props.disabledValues.contains(value);
                });

                return (
                  <div key={i} className={values.isEmpty() ? "hidden" : ""}>
                    <Checkbox
                      checked={checked}
                      disabled={disabled}
                      indeterminate={indeterminate}
                      onClick={() => {
                        if (checked || indeterminate) {
                          this.props.uncheckValues(values.subtract(this.props.disabledValues));
                        } else {
                          this.props.checkValues(values.subtract(this.props.disabledValues));
                        }
                      }}
                    />
                    <TextBlock size={TextBlockSizes.MD}>{columnName}</TextBlock>
                  </div>
                );
              })}
            </div>
          </Panel>
          {this.props.items.map((item: ImmutableMap, i) => {
            const description = item.get("description");

            return (
              <Panel key={i} className="pm-selection-item">
                <div className="pm-selection-item-text">
                  <TextBlock className="title-container" size={TextBlockSizes.LG}>
                    <span className="title">{item.get("title")}</span>
                    {description && <Tooltip message={description} />}
                  </TextBlock>
                  <TextBlock size={TextBlockSizes.MD} className="description">
                    {description}
                  </TextBlock>
                </div>
                <div className="pm-item-multiselect">
                  {item.get("values").map((value: string, j: number) => {
                    const disabled = this.props.disabledValues.contains(value);

                    return (
                      <React.Fragment key={j}>
                        <Checkbox
                          checked={this.props.selectedValues.contains(value)}
                          className={value ? "" : "hidden"}
                          disabled={disabled}
                          onClick={() => disabled || this.props.toggleValue(value)}
                        />
                        <TextBlock size={TextBlockSizes.MD} className={value ? "" : "hidden"}>
                          {this.props.columns.get(j)}
                        </TextBlock>
                      </React.Fragment>
                    );
                  })}
                </div>
              </Panel>
            );
          })}
        </div>
      </div>
    );
  }
}
