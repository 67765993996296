import React from "react";
import classnames from "classnames";
import ReactDOM from "react-dom";

interface DropdownButtonProps {
  renderButton: () => JSX.Element;
  renderContents: () => JSX.Element;
  className?: string;
  contentLeft?: boolean;
  testId?: string;
}

interface DropdownButtonState {
  isActive: boolean;
}

export default class DropdownButton extends React.Component<DropdownButtonProps, DropdownButtonState> {
  constructor(props: DropdownButtonProps) {
    super(props);
    this.state = {
      isActive: false,
    };
    this.onButtonClicked = this.onButtonClicked.bind(this);
    this.onWindowClick = this.onWindowClick.bind(this);
  }

  componentDidUpdate() {
    if (this.state.isActive) {
      window.addEventListener("click", this.onWindowClick, false);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onWindowClick);
  }

  onWindowClick(e: MouseEvent) {
    let target = e.target;
    const domNode = ReactDOM.findDOMNode(this);
    // for when clicking outside the container check
    if (!(domNode && target instanceof Node && domNode.contains(target))) {
      if (this.state.isActive) {
        this.setState({ isActive: false });
      }
      window.removeEventListener("click", this.onWindowClick, false);
      return;
    }
    // for when clicking inside the container
    const orig = target as Element | null;
    let el = orig as Element | null;
    while (el) {
      el = el.parentElement;
      let cls = el && el.getAttribute("class");
      if (cls && cls.search("pm-dropdown-button-container") !== -1) {
        if (
          this.state.isActive &&
          orig &&
          orig.getAttribute &&
          orig.getAttribute("class") &&
          orig.getAttribute("class") === "action" &&
          orig.tagName === "A"
        ) {
          this.setState({ isActive: false });
          window.removeEventListener("click", this.onWindowClick, false);
          return;
        }
        break;
      }
    }
  }

  render() {
    const classes = classnames(
      "pm-dropdown-button-container pm-button",
      { "pm-content-left": this.props.contentLeft },
      this.props.className
    );
    const buttonContainerClasses = classnames("pm-dropdown-buttons-content-button-container");
    return (
      <div className={classes} data-testid={this.props.testId}>
        <div className="pm-dropdown-button-btn" onClick={this.onButtonClicked}>
          {this.props.renderButton()}
        </div>
        {this.state.isActive && (
          <div className="pm-dropdown-button-content-container">
            <div onClick={this.onButtonClicked} className={buttonContainerClasses}>
              {this.props.renderButton()}
            </div>
            <div className="pm-dropdown-button-content">{this.props.renderContents()}</div>
          </div>
        )}
      </div>
    );
  }

  onButtonClicked() {
    this.setState({
      isActive: !this.state.isActive,
    });
  }
}
