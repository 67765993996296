import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

class PropertywareAccountsAPI extends BaseApi {
  static accountList({ query }: { query?: string } = {}) {
    return this.get(ApiUrls.pwAccountList, { params: { query } });
  }
}

export default PropertywareAccountsAPI;
