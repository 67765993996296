import classnames from "classnames";
import React from "react";
import { MdCheckCircle, MdClose, MdError, MdInfo } from "react-icons/md";

import IconLink from "../icon-link/icon-link";

const getIcon = (type: AlertTypes) => {
  switch (type) {
    case AlertTypes.Confirm:
      return <MdCheckCircle />;
    case AlertTypes.Error:
      return <MdError />;
    case AlertTypes.InlineError:
      return <MdError />;
    default:
      return <MdInfo />;
  }
};

export enum AlertTypes {
  Confirm = "confirm",
  Info = "info",
  Error = "error",
  InlineError = "inline-error",
  InvertInfo = "invert-info"
}

export interface AlertProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  /**
   * Function to call when dismiss button is clicked.  If no function is provided, dismiss button will not be shown.
   */
  onDismiss?: () => void;
  /**
   * Color variant to use. Can be Confirm, Info, Error, or InlineError.
   * @default AlertTypes.Info
   */
  type?: AlertTypes;
}

/** @todo When replacing with Chakra, use the integration-link Alert as a starting point. */
export const Alert: React.FC<AlertProps> = ({
  type = AlertTypes.Info,
  className,
  children,
  onDismiss,
  ...elementProps
}) => {
  const classes = classnames("pm-alert", `pm-alert-${type}`, className ? className : "");

  return (
    <div className={classes} {...elementProps}>
      <div className="pm-alert-icon">{getIcon(type)}</div>

      <div className="pm-alert-children">{children}</div>

      {onDismiss && (
        <IconLink className="pm-alert-dismiss" icon={<MdClose />} onClick={onDismiss}>
          Dismiss
        </IconLink>
      )}
    </div>
  );
};

Alert.displayName = "Alert";

export default Alert;
