import React from "react";

/* tslint:disable:max-line-length */
class Check extends React.Component<any> {
  render() {
    return (
      <svg
        className={`${this.props.classes} check-svg`}
        version="1.1"
        id="Layer_1"
        xmlns="https://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="-286 365 13 11"
        enableBackground="new -286 365 13 11"
      >
        <g>
          <path
            id="path-1"
            d="M-275.7,365.5l-5.5,6.2l-2.2-2.5c-0.6-0.7-1.6-0.7-2.2,0c-0.6,0.7-0.6,1.8,0,2.5l3.3,3.7 c0.6,0.7,1.6,0.7,2.2,0l6.6-7.5c0.6-0.7,0.6-1.8,0-2.5C-274.1,364.8-275.1,364.8-275.7,365.5z"
          />
        </g>
      </svg>
    );
  }
}
/* tslint:enable:max-line-length */

export default Check;
