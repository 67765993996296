import { AxiosRequestConfig } from "axios";

import { numOrStr } from "../../types/common";
import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

class OwnerApi extends BaseApi {
  static fetchAll(xhrOptions?: AxiosRequestConfig) {
    return this.get(ApiUrls.ownerList, xhrOptions);
  }

  static fetchOne(id: numOrStr) {
    return this.get(ApiUrls.ownerDetail(id));
  }

  static saveOwner(owner: any) {
    return this.post(ApiUrls.ownerList, owner);
  }

  static updateOwner(owner: any) {
    return this.patch(ApiUrls.ownerDetail(owner.id), owner);
  }

  static deleteOwner(id: numOrStr) {
    return this.delete(ApiUrls.ownerDetail(id));
  }

  static updateProperties(ownerId: numOrStr, propertyIds: numOrStr[]) {
    return this.patch(ApiUrls.ownerPropertiesList(ownerId), {
      properties: propertyIds,
    });
  }

  static unlinkProperty(ownerId: numOrStr, propertyId: numOrStr) {
    return this.delete(ApiUrls.ownerPropertyUnlink(ownerId, propertyId));
  }

  static addProperty(ownerId: numOrStr, propertyId: numOrStr) {
    return this.patch(ApiUrls.ownerPropertyUnlink(ownerId, propertyId), {});
  }

  static reinvite(id: numOrStr) {
    return this.post(ApiUrls.ownerReinvite(id));
  }

  static search(query: string, { ownerIdExclusions }: { ownerIdExclusions?: numOrStr[] } = {}) {
    return this.get(ApiUrls.ownerSearchList, {
      params: { query, exclude_ids: ownerIdExclusions },
    });
  }

  static setNotificationSettings(
    includeIds: number[],
    excludeIds: number[],
    hubAccessLevel: "DIRECT_ONLY" | "ALL_NOTIFICATIONS"
  ) {
    return this.patch(ApiUrls.ownerNotificationSettings, {
      include_ids: includeIds,
      exclude_ids: excludeIds,
      hub_access_level: hubAccessLevel,
    });
  }
}

export default OwnerApi;
