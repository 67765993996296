import React from "react";
import { Grid } from "@chakra-ui/react";

interface ServiceCardListProps {
  serviceCards: React.ReactNode;
}

export const ServiceCardList: React.FC<ServiceCardListProps> = ({ serviceCards }) => {
  return (
    <Grid templateColumns="repeat(auto-fit, minmax(389px, 389px))" alignItems="start" columnGap={4} rowGap={7}>
      {serviceCards}
    </Grid>
  );
};
