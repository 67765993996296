var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const Rating = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement("path", { d: "M20 0a20 20 0 1020 20A20 20 0 0020 0zm0 38a18 18 0 1118-18 18 18 0 01-18 18z" }), /* @__PURE__ */ React.createElement("path", { d: "M33 16a1 1 0 00-1-.69h-8.44L21 7.28a1 1 0 00-1.9 0l-2.61 8H8a1 1 0 00-.59 1.8l6.83 5-2.61 8a1 1 0 00.37 1.14 1 1 0 001.17 0l6.83-5 6.83 5a1 1 0 001.17 0 1 1 0 00.37-1.12l-2.61-8 6.83-5A1 1 0 0033 16zm-9 4.89a1 1 0 00-.37 1.11l1.88 5.79-4.92-3.57a1 1 0 00-1.18 0l-4.92 3.57L16.37 22a1 1 0 00-.37-1.11l-4.92-3.58h6.09a1 1 0 00.95-.7L20 10.82l1.88 5.79a1 1 0 00.95.7h6.09z" }));
