import alt from "../../alt";
import VendorInvoiceFileApi from "../apis/vendor-invoice-file-api";
import vendorInvoiceFileActions from "../../common/actions/message-actions";

class VendorInvoiceFileActions {
  constructor() {
    this.generateActions(
      "fetchVendorInvoiceFile",
      "fetchVendorInvoiceFileDone",
      "fetchVendorInvoiceFileFail",
      "createVendorInvoiceFile",
      "createVendorInvoiceFileDone",
      "createVendorInvoiceFileFail",
      "deleteVendorInvoiceFile",
      "deleteVendorInvoiceFileDone",
      "deleteVendorInvoiceFileFail"
    );
  }

  createVendorInvoiceFiles(file, meldId, cid) {
    VendorInvoiceFileApi.create(file, meldId)
      .then(result => {
        this.createVendorInvoiceFileDone({ data: result.data, cid });
      })
      .catch(result => {
        vendorInvoiceFileActions.storeError({
          body: result.messages[0]
        });
        this.createVendorInvoiceFileFail({ data: result, cid });
      });
    return { cid };
  }

  deleteVendorInvoiceFile(id, cid) {
    VendorInvoiceFileApi.delete(id)
      .then(result => {
        this.deleteVendorInvoiceFileDone({
          data: result.data,
          id,
          cid
        });
      })
      .catch(result => {
        this.deleteVendorInvoiceFileFail({ data: result, cid });
      });
    return { cid };
  }
}

export default alt.createActions(VendorInvoiceFileActions);
