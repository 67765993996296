import moment from "moment";
import { useLocation } from "react-router-dom";
import { PmDateFilterTimeframeOptions } from "../components/FilterButtons/PmDateFilter/BaseDateFilterClass";

const SAVED_FILTER_IGNORED_FIELDS = [
  "id",
  "name",
  "filterset_fields",
  "position",
  "private",
  "current_agent_default",
  "limit",
  "offset",
];

export const getQueryParamsFromSavedFilter = (
  savedFilter: Record<string, string | string[] | null> | undefined,
  location: ReturnType<typeof useLocation>
) => {
  if (savedFilter === undefined) {
    const newParams = new URLSearchParams(location.search);
    // handles cases where the `saved_filter` param value isn't a real filter id
    // or is 'default' and the user doesn't have a default filter
    newParams.delete("saved_filter");
    return newParams;
  }
  const searchParams = new URLSearchParams();
  for (const entry of Object.entries(savedFilter)) {
    const [key, value] = entry;
    if (SAVED_FILTER_IGNORED_FIELDS.includes(key)) {
      continue;
    }
    if (value === "" || value === null || (Array.isArray(value) && value.length === 0)) {
      continue;
    }

    if (Array.isArray(value)) {
      value.forEach((val) => searchParams.append(key + "[]", val));
    } else {
      searchParams.append(key, value);
    }
  }

  return searchParams;
};

export const getFilterPropertiesFromQueryParams = (
  location: ReturnType<typeof useLocation>
): Record<string, string | string[] | null> | undefined => {
  const params = new URLSearchParams(location.search);
  const result: Record<string, string | string[] | null> | undefined = {};
  // @ts-expect-error bad ts inference
  for (const [key, value] of params) {
    if (SAVED_FILTER_IGNORED_FIELDS.includes(key)) {
      continue;
    }
    // handle arrays
    if (typeof key === "string" && key.endsWith("[]")) {
      const newKey = key.replace("[]", "");
      // create the array if it doesn't exist, otherwise push to it
      result[newKey] = value.split(",");
    } else {
      result[key] = value;
    }
  }
  return result;
};

export const getFormattedIntervalValue = (value: string, period: PmDateFilterTimeframeOptions) => {
  if (value) {
    return moment.duration({ [period.toLowerCase()]: value }).toISOString();
  }
  return "";
};

export const getIntervalFromFormattedValue = (formattedValue: string): PmDateFilterTimeframeOptions => {
  if (!formattedValue) {
    return "Days";
  }
  const lastDigit = formattedValue.charAt(formattedValue.length - 1);
  switch (lastDigit) {
    case "D":
      return "Days";
    case "M":
      return "Months";
    case "Y":
      return "Months";
    default:
      return "Days";
  }
};

const OlderThanMapping: Record<PmDateFilterTimeframeOptions, "m" | "w" | "d"> = {
  Days: "d",
  Weeks: "w",
  Months: "m",
};

export const getFormattedOlderThanValue = (value: string, period: PmDateFilterTimeframeOptions) => {
  if (!value || value.length === 0) {
    return "";
  }
  return `-${value}${OlderThanMapping[period]}`;
};

/**
 * locationPathname usually comes from
 *  @example
 *    const location  = useLocation()
 *    getMeldListUrlWithSavedFilter(location.pathname, filterId)
 */
export const getMeldListUrlWithSavedFilter = (locationPathname: string, filterId: "default" | number): string => {
  return `${locationPathname}?saved_filter=${filterId}`;
};

/**
 * used to filter an array of filters
 * `filters.filter(getSavedFilterFilterFunction("private))`
 */
export const getSavedFilterFilterFunction = (type: "private" | "shared") => {
  if (type === "private") {
    return (filter: { private: boolean }) => filter.private;
  } else {
    return (filter: { private: boolean }) => !filter.private;
  }
};
