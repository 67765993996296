import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

class InvoiceApi extends BaseApi {
  static createMeldInvoice(invoice: any) {
    return this.post(ApiUrls.meldInvoiceList, invoice);
  }

  static createInvoiceFile(vendorId: number, data: any) {
    return this.post(ApiUrls.vendorInvoiceFileList(vendorId), data);
  }

  static deleteInvoiceFile(vendorId: number, data: any) {
    return this.delete(ApiUrls.vendorInvoiceFileDetail(vendorId), data);
  }

  static fetchAll(options: any) {
    return this.get(ApiUrls.meldInvoiceList, options);
  }

  static fetchOne(invoiceId: number) {
    return this.get(ApiUrls.meldInvoiceDetail(invoiceId));
  }

  static update(invoiceId: number, data: any) {
    return this.patch(ApiUrls.meldInvoiceDetail(invoiceId), data);
  }

  static deleteDraft(invoiceId: number) {
    return this.delete(ApiUrls.meldInvoiceDetail(invoiceId));
  }

  static fetchInvoicesByUnit(unitId: number) {
    return this.get(ApiUrls.unitMeldInvoiceList(unitId));
  }

  static hold(invoiceId: number, data: any) {
    return this.patch(ApiUrls.meldInvoiceHold(invoiceId), data);
  }

  static decline(invoiceId: number, data: any) {
    return this.patch(ApiUrls.meldInvoiceDecline(invoiceId), data);
  }

  static approve(invoiceId: number) {
    return this.patch(ApiUrls.meldInvoiceApprove(invoiceId));
  }

  static review(invoiceId: number) {
    return this.patch(ApiUrls.meldInvoiceReview(invoiceId));
  }
}

export default InvoiceApi;
