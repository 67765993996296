var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import I from "immutable";
import { asImmer } from "../../../../app/utils/decorators";
import MeldUtils from "../../meld/utils/meld-utils";
import AddressUtils from "../../utils/address-utils";
import Features from "@pm-assets/js/common/feature-flags";
const _UnitUtils = class {
  static getSummaryLink(unit) {
    return `/properties/${unit.get("id")}/summary/`;
  }
  static getFormattedStreetAndUnit(unit) {
    return [_UnitUtils.getAddressLine1(unit), _UnitUtils.getFormattedUnit(unit, "")].join(" ").trim();
  }
  static getID(unit) {
    unit = unit;
    return unit.id;
  }
  static getUnitMaintenanceNotes(unit) {
    unit = unit;
    return unit.maintenance_notes;
  }
  static getPropertyMaintenanceNotes(unit) {
    var _a;
    unit = unit;
    return (_a = unit.prop) == null ? void 0 : _a.maintenance_notes;
  }
  static getUnitProp(unit) {
    unit = unit;
    return unit.prop;
  }
  static getPropertyName(unit, emptyMsg = "No property name") {
    unit = unit;
    let property_name = unit.prop.property_name;
    return property_name !== "" ? property_name : emptyMsg;
  }
  static displayPropertyName(unit) {
    let property_name = _UnitUtils.getPropertyName(unit, "");
    return property_name !== _UnitUtils.getDisplayAddressLine1(unit) ? property_name : "";
  }
  static getPropPropertyGroup(unit) {
    unit = unit;
    return unit.prop.property_groups.edges;
  }
  static getDisplayStreetAndUnit(unit) {
    unit = unit;
    return [_UnitUtils.getDisplayAddressLine1(unit), _UnitUtils.getFormattedUnit(unit, "")].join(" ").trim();
  }
  static getDisplayPropertyNameStreetAndUnitWithState(unit) {
    unit = unit;
    let line_1 = Features.isPropertyNameEnabled() && _UnitUtils.displayPropertyName(unit) !== "" ? `${_UnitUtils.displayPropertyName(unit)} - ${_UnitUtils.getDisplayAddressLine1(unit)}` : _UnitUtils.getDisplayAddressLine1(unit);
    return [line_1, _UnitUtils.getFormattedUnit(unit, ""), _UnitUtils.getDisplayCityStateZip(unit)].join(" ").trim();
  }
  static getFormattedBuilding(unit, emptyMsg = "No building number") {
    [unit] = MeldUtils.immutableMapOrObject(unit);
    return unit.getIn(["building", "number"]) || emptyMsg;
  }
  static getFormattedFloor(unit, emptyMsg = "No floor number") {
    [unit] = MeldUtils.immutableMapOrObject(unit);
    return unit.getIn(["floor", "number"]) || emptyMsg;
  }
  static getFormattedUnit(unit, emptyMsg = "No unit number") {
    unit = unit;
    return [
      { prefix: "APT", number: unit.apartment },
      { prefix: "STE", number: unit.suite },
      { prefix: "UNIT", number: unit.unit },
      { prefix: "RM", number: unit.room },
      { prefix: "DEPT", number: unit.department }
    ].filter((designator) => {
      return !!designator.number;
    }).map((designator) => {
      return [designator.prefix, designator.number].join(" ");
    }).join(", ") || emptyMsg;
  }
  static getFormattedBuildingFloorAndUnit(unit, emptyMsg = "No building, floor, or unit") {
    return [
      { prefix: "BLDG", number: unit.getIn(["building", "number"]) },
      { prefix: "FL", number: unit.getIn(["floor", "number"]) },
      { prefix: "APT", number: unit.get("apartment") },
      { prefix: "STE", number: unit.get("suite") },
      { prefix: "UNIT", number: unit.get("unit") },
      { prefix: "RM", number: unit.get("room") },
      { prefix: "DEPT", number: unit.get("department") }
    ].filter((designator) => {
      return !!designator.number;
    }).map((designator) => {
      return [designator.prefix, designator.number].join(" ");
    }).join(", ") || emptyMsg;
  }
  static getFormattedBuildingFloorAndUnitCityState(unit, emptyMsg = "No building, floor, or unit") {
    return [_UnitUtils.getFormattedBuildingFloorAndUnit(unit, emptyMsg), _UnitUtils.getDisplayCityStateZip(unit)].join(" ").trim() || emptyMsg;
  }
  static getFormattedCityStateZip(unit) {
    unit = unit;
    return AddressUtils.getFormattedCityStateZip(unit.prop);
  }
  static getDisplayCityStateZip(unit) {
    unit = unit;
    if (unit.display_address) {
      return AddressUtils.getFormattedCityStateZip(unit.display_address);
    }
  }
  static getUnitAddressCityStateZip(unit) {
    return AddressUtils.getFormattedCityStateZip(unit.get("unit_address"));
  }
  static getFormattedAddressLines(unit, emptyMsg = "") {
    let lines = [
      _UnitUtils.getAddressLine1(unit, emptyMsg),
      _UnitUtils.getAddressLine2(unit, emptyMsg),
      _UnitUtils.getAddressLine3(unit),
      emptyMsg
    ];
    lines = lines.filter((line) => line);
    return lines.join(", ");
  }
  static getDisplayAddressLines(unit, emptyMsg = "") {
    let lines = [
      _UnitUtils.getDisplayAddressLine1(unit),
      _UnitUtils.getDisplayAddressLine2(unit),
      _UnitUtils.getDisplayAddressLine3(unit),
      emptyMsg
    ];
    lines = lines.filter((line) => line);
    return lines.join(", ");
  }
  static getNumDesignators(unit) {
    unit = unit;
    return [unit.apartment, unit.suite, unit.unit, unit.room, unit.department].reduce((acc, designator) => {
      return designator ? acc + 1 : acc;
    }, 0);
  }
  static getAddressLine1(unit, emptyMsg = "") {
    return AddressUtils.getAddressLine1(unit.get("prop"), emptyMsg);
  }
  static getAddressLine2(unit, emptyMsg = "") {
    return AddressUtils.getAddressLine2(unit.get("prop"), emptyMsg);
  }
  static getAddressLine3(unit, emptyMsg = "") {
    return AddressUtils.getAddressLine3(unit.get("prop"), emptyMsg);
  }
  static getDisplayAddressLine1(unit) {
    unit = unit;
    if (unit.display_address) {
      return AddressUtils.getAddressLine1(unit.display_address);
    }
  }
  static getDisplayAddressLine2(unit) {
    unit = unit;
    if (unit.display_address) {
      return AddressUtils.getAddressLine2(unit.display_address);
    }
  }
  static getDisplayAddressLine3(unit) {
    unit = unit;
    if (unit.display_address) {
      return AddressUtils.getAddressLine3(unit.display_address);
    }
  }
  static getOwners(unit) {
    return unit.getIn(["prop", "owners"]);
  }
  static getPropertyGroups(unit) {
    return unit.getIn(["prop", "property_groups"]);
  }
  static getPropertyGroupIDs(unit) {
    if (unit.get("prop_groups")) {
      return unit.get("prop_groups");
    }
    if (!unit.getIn(["prop", "property_groups"])) {
      return I.List();
    }
    return unit.getIn(["prop", "property_groups"]).map((pg) => {
      if (typeof pg === "number") {
        return pg;
      }
      return pg.get("id");
    });
  }
  static getDirectionsUrlString(unit) {
    return AddressUtils.getDirectionsUrlString(unit.get("display_address"));
  }
  static getAncillaryUnitInfo(unit) {
    const cityStateZip = _UnitUtils.getDisplayCityStateZip(unit);
    const bflu = _UnitUtils.getFormattedBuildingFloorAndUnit(unit, "");
    return [cityStateZip, bflu].filter((d) => d).join(" | ");
  }
};
let UnitUtils = _UnitUtils;
__decorateClass([
  asImmer()
], UnitUtils, "getID", 1);
__decorateClass([
  asImmer()
], UnitUtils, "getUnitMaintenanceNotes", 1);
__decorateClass([
  asImmer()
], UnitUtils, "getPropertyMaintenanceNotes", 1);
__decorateClass([
  asImmer()
], UnitUtils, "getUnitProp", 1);
__decorateClass([
  asImmer()
], UnitUtils, "getPropertyName", 1);
__decorateClass([
  asImmer()
], UnitUtils, "displayPropertyName", 1);
__decorateClass([
  asImmer()
], UnitUtils, "getPropPropertyGroup", 1);
__decorateClass([
  asImmer()
], UnitUtils, "getDisplayStreetAndUnit", 1);
__decorateClass([
  asImmer()
], UnitUtils, "getDisplayPropertyNameStreetAndUnitWithState", 1);
__decorateClass([
  asImmer()
], UnitUtils, "getFormattedUnit", 1);
__decorateClass([
  asImmer()
], UnitUtils, "getDisplayCityStateZip", 1);
__decorateClass([
  asImmer()
], UnitUtils, "getDisplayAddressLines", 1);
__decorateClass([
  asImmer()
], UnitUtils, "getNumDesignators", 1);
__decorateClass([
  asImmer()
], UnitUtils, "getDisplayAddressLine1", 1);
__decorateClass([
  asImmer()
], UnitUtils, "getDisplayAddressLine2", 1);
__decorateClass([
  asImmer()
], UnitUtils, "getDisplayAddressLine3", 1);
export default UnitUtils;
