var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState } from "react";
import { MdRotateRight, MdInfoOutline, MdClose } from "react-icons/md";
import { SmallLoader } from "@pm-assets/js/loaders";
import { postModifyData } from "@pm-app/views/melds/comment-services";
import { track, ChatModifyClicked } from "@pm-app/utils/analytics";
const buttonStyles = {
  base: {
    padding: "8px 12px",
    margin: "4px 4px 2px 4px",
    cursor: "pointer",
    borderRadius: "20px",
    borderWidth: "1px",
    borderStyle: "solid"
  },
  selected: {
    color: "#1175CC",
    borderColor: "#1175CC"
  },
  default: {
    color: "gray",
    borderColor: "gray"
  }
};
const MessageModifyComponent = ({ handleTextChange, text, meld_id }) => {
  const [selectedTone, setSelectedTone] = useState("");
  const [selectedLength, setSelectedLength] = useState("");
  const [rewrite, setRewrite] = useState(false);
  const [showAssistant, setShowAssistant] = useState(false);
  const [previewText, setPreviewText] = useState("");
  const [loading, setLoading] = useState(false);
  const resetState = () => {
    setSelectedTone("");
    setSelectedLength("");
    setRewrite(false);
    setShowAssistant(false);
    setPreviewText("");
    setLoading(false);
  };
  const handleClose = () => {
    resetState();
    setShowAssistant(false);
  };
  const generatePreviewText = () => {
    setLoading(true);
    track(ChatModifyClicked());
    postModifyData(text, selectedTone, selectedLength, meld_id).then((response) => {
      setPreviewText(response.generated_text);
      setLoading(false);
    }).catch((e) => {
      {
        e.message === "Request failed with status code 429" ? setPreviewText("Your organization has used the maximum writing assistant attempts for this Meld.") : setPreviewText("Error generating preview. Please reload page and try again.");
      }
      setLoading(false);
    });
  };
  const handleRewriteToggle = () => {
    if (rewrite) {
      handleTextChange(previewText);
      resetState();
      return;
    }
    generatePreviewText();
    setRewrite(true);
  };
  const renderButton = (value, selectedValue, setDataTest, onClickHandler, label) => /* @__PURE__ */ React.createElement(
    "button",
    {
      "data-test": setDataTest,
      style: __spreadValues(__spreadValues({}, buttonStyles.base), value === selectedValue ? buttonStyles.selected : buttonStyles.default),
      onClick: () => onClickHandler(value)
    },
    label
  );
  const renderChatRewrite = () => /* @__PURE__ */ React.createElement("div", { style: { marginTop: "30px" } }, /* @__PURE__ */ React.createElement(
    "button",
    {
      style: __spreadProps(__spreadValues(__spreadValues({}, buttonStyles.base), buttonStyles.selected), {
        padding: "8px 24px"
      }),
      onClick: handleRewriteToggle,
      disabled: loading,
      "data-test": "rewrite-toggle"
    },
    loading ? /* @__PURE__ */ React.createElement(SmallLoader, null) : rewrite ? "Use Text" : "Rewrite"
  ), rewrite && /* @__PURE__ */ React.createElement(
    "div",
    {
      "data-test": "rewrite-text",
      style: {
        textDecoration: "underline",
        display: "flex",
        marginLeft: "20px",
        marginTop: "5px",
        cursor: "pointer"
      },
      onClick: generatePreviewText
    },
    /* @__PURE__ */ React.createElement("span", { style: { marginTop: "6px" } }, /* @__PURE__ */ React.createElement(MdRotateRight, null)),
    /* @__PURE__ */ React.createElement("span", null, "Rewrite")
  ));
  if (!showAssistant) {
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        "data-test": "assistant-toggle",
        style: { cursor: "pointer", display: "flex", alignItems: "center", margin: "10px" },
        onClick: () => setShowAssistant(true)
      },
      /* @__PURE__ */ React.createElement(MdInfoOutline, { style: { marginRight: "10px" } }),
      "Need help writing this text?",
      /* @__PURE__ */ React.createElement("span", { style: { textDecoration: "underline", marginLeft: "10px" } }, "Open AI Assistant")
    );
  }
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "20px",
        border: "1px solid lightgray",
        backgroundColor: "transparent",
        borderRadius: "8px",
        margin: "10px 0"
      }
    },
    /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      "div",
      {
        style: {
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "20px"
        }
      },
      /* @__PURE__ */ React.createElement(
        "div",
        {
          style: {
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer"
          },
          onClick: handleClose,
          "data-test": "close-button"
        },
        /* @__PURE__ */ React.createElement(MdClose, { size: 24 })
      ),
      /* @__PURE__ */ React.createElement("div", { style: { marginRight: "5px" } }, /* @__PURE__ */ React.createElement("p", { "data-test": "message-tone", style: { margin: "0 0 8px 0", color: "black" } }, "Message Tone"), /* @__PURE__ */ React.createElement("div", null, renderButton("professional", selectedTone, "tone-professional", setSelectedTone, "Professional"), renderButton("friendly", selectedTone, "tone-friendly", setSelectedTone, "Friendly"), renderButton("urgent", selectedTone, "tone-urgent", setSelectedTone, "Urgent"))),
      /* @__PURE__ */ React.createElement("div", { style: { marginRight: "5px" } }, /* @__PURE__ */ React.createElement("p", { style: { margin: "0 0 8px 0", color: "black" } }, "Message Length"), /* @__PURE__ */ React.createElement("div", null, renderButton("short", selectedLength, "length-short", setSelectedLength, "Short"), renderButton("medium", selectedLength, "length-medium", setSelectedLength, "Medium"))),
      /* @__PURE__ */ React.createElement("div", null, renderChatRewrite()),
      /* @__PURE__ */ React.createElement("div", null)
    ), previewText && /* @__PURE__ */ React.createElement("div", { style: { margin: "20px 0" } }, /* @__PURE__ */ React.createElement("span", null, previewText)))
  );
};
export default MessageModifyComponent;
