import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";
import _ from "lodash";

let CheckboxFilter = createReactClass({
  propTypes: {
    filterName: PropTypes.string.isRequired,
    humanReadableValues: PropTypes.array.isRequired,
    possibleValues: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    onToggleFilter: PropTypes.func.isRequired,
  },

  render() {
    let { name, humanReadableValues, filterName, isFilterOpen } = this.props;

    let choices = this.props.possibleValues.map((value, i) => {
      return (
        <div className="filter-choice" key={i}>
          <input
            type="checkbox"
            name={name}
            id={value}
            checked={_.includes(this.props.filtersSelected, value)}
            data-test={`meld-filters.${filterName}-filter.${value}-checkbox`}
            onChange={() => this.props.toggleCheckboxFilter(this.props.filterName, value)}
          />
          <label htmlFor={value} className="filter-label">
            {humanReadableValues[i]}
          </label>
        </div>
      );
    });

    return (
      <div>
        <div className="filter-type">
          <input
            type="checkbox"
            data-test={`meld-filters.${filterName}-filter`}
            id={`${filterName}-filter`}
            checked={isFilterOpen}
            onChange={() => this.props.onToggleFilter(filterName)}
          />
          <label htmlFor={`${filterName}-filter`}>{this.props.children}</label>
        </div>
        <div className="filter-choices">{isFilterOpen ? choices : null}</div>
      </div>
    );
  },
});

export default CheckboxFilter;
