export interface LatLongCoordinates {
  latitude: number;
  longitude: number;
}

const DEFAULT_COORDINATES = { latitude: 0, longitude: 0 };

export const parseWKT = (wkt: string): LatLongCoordinates => {
  if (typeof wkt !== "string") {
    return DEFAULT_COORDINATES;
  }

  const match = wkt.match(/POINT \(([^ ]+) ([^ ]+)\)/);
  const parsedLong = match ? parseFloat(match[1]) : undefined;
  const parsedLat = match ? parseFloat(match[2]) : undefined;

  if (!parsedLong || Number.isNaN(parsedLong) || !parsedLat || Number.isNaN(parsedLat)) {
    return DEFAULT_COORDINATES;
  }

  return { latitude: parsedLat, longitude: parsedLong };
};

export const getCoordinatesFromLocation = (location: string) => parseWKT(location);
