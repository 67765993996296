import React, { CSSProperties } from "react";
import { EuiBadge, EuiBadgeProps, EuiText } from "@elastic/eui";

import URL from "@pm-shared/utils/url";

type PmBadgeProps = {
  text: string;
  bgColor: string;
  textColor: string;
  textSize?: "xs" | "s" | "m";
  "data-testid"?: string;
} & (
  | {
      iconOnClick: EuiBadgeProps["iconOnClick"];
      iconOnClickAriaLabel: EuiBadgeProps["iconOnClickAriaLabel"];
    }
  | {
      iconOnClick?: never;
      iconOnClickAriaLabel?: never;
    }
);

const styles: CSSProperties = {
  padding: "1px 8px",
  width: "fit-content",
};

const PmBadge = ({
  text,
  bgColor,
  textColor,
  textSize = "xs",
  "data-testid": dataTestId,
  iconOnClick,
  iconOnClickAriaLabel = "",
}: PmBadgeProps) => {
  const dataTestProp = dataTestId ? { "data-testid": dataTestId } : {};

  const body = (
    <EuiText size={textSize} color={textColor} css={{ fontWeight: 600 }}>
      {text}
    </EuiText>
  );
  if (iconOnClick && iconOnClickAriaLabel) {
    return (
      <EuiBadge
        color={bgColor}
        style={styles}
        {...dataTestProp}
        iconOnClick={iconOnClick || undefined}
        iconOnClickAriaLabel={iconOnClickAriaLabel}
        iconType={URL.getStatic("icons/close_white.svg")}
        iconSide="right"
      >
        {body}
      </EuiBadge>
    );
  }
  return (
    <EuiBadge color={bgColor} style={styles} {...dataTestProp}>
      {body}
    </EuiBadge>
  );
};

export { PmBadge, PmBadgeProps };
