import alt from "../../alt";
import BaseStore from "../../stores/base-store";
import MeldFileSource from "../sources/meld-file-source";
import meldFileActions from "../actions/meld-file-actions";

class MeldFileStore extends BaseStore {
  constructor() {
    super();

    this.registerAsync(MeldFileSource);

    this.bindListeners({
      receive: meldFileActions.FETCH_FILES_DONE,
      saveDone: meldFileActions.CREATE_FILE_DONE,
      saving: meldFileActions.CREATE_FILE,
      deleteDone: meldFileActions.DELETE_FILE_DONE,
      deleting: meldFileActions.DELETE_FILE
    });

    this.exportPublicMethods({
      getFilesForMeld: this.getFilesForMeld.bind(this),
      getStatus: this.getStatus.bind(this)
    });
  }

  getFilesForMeld(meldId) {
    return this.getModelState().filter(function(file) {
      return file.get("meld") === meldId;
    });
  }
}

export default alt.createStore(MeldFileStore, "MeldFileStore");
