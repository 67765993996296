var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import _ from "lodash";
import * as C from "../../constants";
import React from "react";
function calcOptimalNumDays() {
  let numDays = 7;
  if (window.innerWidth < C.SMALL_BREAKPOINT) {
    numDays = 1;
  } else if (window.innerWidth < C.MEDIUM_BREAKPOINT) {
    numDays = 4;
  }
  return numDays;
}
export default function withOptimalNumDays(Comp, { optimalDaysFn = calcOptimalNumDays } = {}) {
  return class WithOptimalNumDaysClass extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        numDays: optimalDaysFn()
      };
      this.handleWindowResized = this.handleWindowResized.bind(this);
    }
    render() {
      const _a = this.props, { numDays } = _a, props = __objRest(_a, ["numDays"]);
      return /* @__PURE__ */ React.createElement(Comp, __spreadValues({ numDays: this.state.numDays }, props));
    }
    componentWillMount() {
      this.handleWindowResized = _.debounce(this.handleWindowResized, 200);
      window.addEventListener("resize", this.handleWindowResized, false);
    }
    componentWillUnmount() {
      this.handleWindowResized.cancel();
      window.removeEventListener("resize", this.handleWindowResized, false);
    }
    handleWindowResized() {
      this.setState({ numDays: optimalDaysFn() });
    }
  };
}
