import useSWR from "swr";

import { APIPrefix } from "../api";
import { axios } from "@pm-shared/axios";

interface CommentTemplate {
  id: number;
  name: string;
}

export const fetchCommentTemplates = () => {
  return useSWR<{ results: CommentTemplate[] }>(`${APIPrefix}/comment-templates/`, () =>
    axios.get(`${APIPrefix}/comment-templates/`).then((response) => response.data)
  );
};

export const fetchAbbreviatedCommentTemplates = () => {
  return useSWR<CommentTemplate[]>(`${APIPrefix}/comment-templates/`, () =>
    axios.get(`${APIPrefix}/comment-templates/abbrev/`).then((response) => response.data)
  );
};
