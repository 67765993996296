import React from "react";
import ReactDOM from "react-dom";

export interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  indeterminate?: boolean;
  className?: string;
  onClick?: (e: any) => void;
  disabled?: boolean;
}

export default class Checkbox extends React.Component<CheckboxProps, any> {
  public static defaultProps: Partial<CheckboxProps> = {
    indeterminate: false
  };

  constructor(props: CheckboxProps) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  render() {
    let classString = "custom-checkbox-container";

    if (this.props.className) {
      classString += " " + this.props.className;
    }

    return (
      <div className={classString} onClick={this.onClick}>
        <input
          type="checkbox"
          className="custom-checkbox"
          checked={this.props.checked}
          id={this.props.id}
          readOnly={true}
          disabled={this.props.disabled}
        />
        <label />
      </div>
    );
  }

  onClick(e: any) {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  componentDidMount() {
    if (this.props.indeterminate) {
      this.updateIndeterminant();
    }
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.indeterminate !== this.props.indeterminate) {
      this.updateIndeterminant();
    }
  }

  /**
   * Updates the indeterminate status of the checkbox input
   *
   * Although accessing the DOM isn't the way things are typically done in React
   * indeterminate can only be set through js and not as an html attribute.
   */
  updateIndeterminant() {
    const node = ReactDOM.findDOMNode(this);

    if (node instanceof HTMLElement) {
      const input = node.querySelector(".custom-checkbox") as HTMLInputElement;
      input.indeterminate = this.props.indeterminate ? this.props.indeterminate : false;
    }
  }
}
