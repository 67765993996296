import React, { useState } from "react";

import { useGetGlobalBannerVisibility, useSetGlobalBannerVisibility } from "@pm-frontend/shared/store/globalBanner";
import { useGetOpenWorkEntries } from "@pm-frontend/routes/Melds/queries";
import { ManagementWorkLogCheckoutBanner } from "@pm-frontend/apps/management/banners/ManagementWorkLogCheckoutBanner";
import { GlobalBannerEmptySpace } from "@pm-frontend/shared/layouts/GlobalBanners";

const ManagementGlobalBanners = () => {
  const isBannerVisible = useGetGlobalBannerVisibility();
  const { data: openWorkEntries } = useGetOpenWorkEntries(isBannerVisible);
  const [workLogIdsToIgnore, setWorkLogIdsToIgnore] = useState<Record<number, boolean>>(() => {
    const savedIds = window.localStorage.getItem("workLogIdsToIgnore");
    return savedIds ? JSON.parse(savedIds) : {};
  });
  const setGlobalManagementBannerVisibility = useSetGlobalBannerVisibility();

  const handleOnCloseWorkLog = (id: number) => {
    setWorkLogIdsToIgnore((state) => {
      const newState = { ...state, [id]: true };
      window.localStorage.setItem("workLogIdsToIgnore", JSON.stringify(newState));
      const nonIgnoredWorkEntriesLength = openWorkEntries?.filter((entry) => !newState[entry.id]).length;
      if (nonIgnoredWorkEntriesLength === 0) {
        setGlobalManagementBannerVisibility(false);
      }
      return newState;
    });
  };

  if (!isBannerVisible) {
    return <GlobalBannerEmptySpace />;
  }

  if (openWorkEntries && openWorkEntries.length > 0) {
    const filteredOpenWorkEntries = openWorkEntries.filter((entry) => !workLogIdsToIgnore[entry.id]);

    if (filteredOpenWorkEntries.length > 0) {
      return (
        <ManagementWorkLogCheckoutBanner workEntries={filteredOpenWorkEntries} onCloseWorkLog={handleOnCloseWorkLog} />
      );
    }
  }
  return <GlobalBannerEmptySpace />;
};

export { ManagementGlobalBanners };
