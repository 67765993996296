var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import KeyUtils from "./key-utils";
import React from "react";
let ModelUpdateUtils = {
  setVal(label, value) {
    this.setState({ data: this.state.data.set(label, value) });
  },
  deleteVal(label, value) {
    this.setState({ data: this.state.data.delete(label, value) });
  },
  mergeData(data) {
    this.setState({ data: this.state.data.merge(data) });
  },
  setInVal(labelArr, value) {
    this.setState({ data: this.state.data.setIn(labelArr, value) });
  },
  setWithLabel(label) {
    return (value) => {
      ModelUpdateUtils.setVal.call(this, label, value);
    };
  },
  deleteWithLabel(label) {
    return (value) => {
      ModelUpdateUtils.deleteVal.call(this, label, value);
    };
  },
  setInWithLabel(labelArr) {
    return (value) => {
      ModelUpdateUtils.setInVal.call(this, labelArr, value);
    };
  },
  onChangeSetIn(labelArr) {
    return (e) => {
      this.setState({
        data: this.state.data.setIn(labelArr, e.target.value)
      });
    };
  },
  setFromEvent(label, e) {
    this.setState({ data: this.state.data.set(label, e.target.value) });
  },
  onChangeSet(label) {
    return (e) => {
      this.setState({ data: this.state.data.set(label, e.target.value) });
    };
  },
  onChangeToggle(label) {
    return () => {
      this.setState({
        data: this.state.data.set(label, !this.state.data.get(label))
      });
    };
  },
  onChangeToggleIn(labelArr) {
    return () => {
      this.setState({
        data: this.state.data.setIn(labelArr, !this.state.data.getIn(labelArr))
      });
    };
  },
  forwardOnChange(e) {
    this.props.onChange(e);
  },
  onEventSet(label) {
    return (value) => {
      ModelUpdateUtils.setVal.call(this, label, value);
    };
  },
  submitOnEnter(submitFunc) {
    return (e) => {
      if (KeyUtils.isEnterKey(e)) {
        submitFunc();
      }
    };
  },
  markDirty(func) {
    return (...args) => {
      this.setState({ dirty: true });
      return func(...args);
    };
  },
  onResetChanges(originalData) {
    return (e) => {
      e.preventDefault();
      this.setState({
        data: originalData,
        dirty: false
      });
    };
  }
};
export default ModelUpdateUtils;
export function ModelUpdate(WrapperComponent) {
  return class extends React.Component {
    render() {
      return /* @__PURE__ */ React.createElement(WrapperComponent, __spreadValues(__spreadValues({}, this.props), ModelUpdateUtils));
    }
  };
}
