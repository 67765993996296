import React from "react";
import PureCheckbox from "../../common/components/pure-checkbox";
import classNames from "classnames";

export interface SelectAllSelectorProps {
  selectedAll: boolean;
  numberOfSelectedItems: number;
  onSelectedAll: (e: any) => void;
  onSelectedAllPages?: (e: any) => void;
  itemName: string;
  nonSingularItemName: string;
  totalItemCount: number;
  enableMultiplePageSelection?: boolean;
  filterSortPanelClass?: boolean;
}

export default class SelectAllSelector extends React.Component<SelectAllSelectorProps> {
  static defaultProps: Partial<SelectAllSelectorProps> = {
    enableMultiplePageSelection: true
  };

  shouldRenderCheck() {
    return this.props.selectedAll && this.props.numberOfSelectedItems > 0;
  }

  shouldRenderMinus() {
    return this.props.numberOfSelectedItems >= 1 && !this.shouldRenderCheck();
  }

  getItemString(count: number) {
    if (count === 1) {
      return this.props.itemName;
    }

    return this.props.nonSingularItemName;
  }

  renderNumberOfSelected() {
    const selectedCount = this.props.numberOfSelectedItems;

    const meldString = this.getItemString(selectedCount);

    const allSelected = selectedCount === this.props.totalItemCount ? "All " : "";
    return allSelected + selectedCount + " " + meldString + " selected";
  }

  renderSelectAcrossPages() {
    if (this.props.numberOfSelectedItems < this.props.totalItemCount && this.props.numberOfSelectedItems !== 0) {
      const selectString =
        "Select all " + this.props.totalItemCount + " " + this.getItemString(this.props.totalItemCount);
      return <div onClick={this.props.onSelectedAllPages}>{selectString}</div>;
    }
  }

  render() {
    let tags;
    let classes = classNames({
      "select-all-container": true,
      "filter-sort-panel": !!this.props.filterSortPanelClass
    });
    if (!!this.props.filterSortPanelClass) {
      tags = <span className={classes}>{this.renderRow()}</span>;
    } else {
      tags = <div className={classes}>{this.renderRow()}</div>;
    }
    return tags;
  }

  renderRow() {
    return (
      <React.Fragment>
        <div className="header-col">
          <PureCheckbox
            type="checkbox"
            className="select-all-selector"
            checked={this.shouldRenderCheck()}
            indeterminate={this.shouldRenderMinus()}
            onClick={this.props.onSelectedAll}
          />
        </div>
        <div className="select-all-label" onClick={this.props.onSelectedAll}>
          {this.renderNumberOfSelected()}
        </div>
        {this.props.enableMultiplePageSelection && (
          <div className="select-all-pages">{this.renderSelectAcrossPages()}</div>
        )}
      </React.Fragment>
    );
  }
}
