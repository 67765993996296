var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const StatusChange = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement("path", { d: "M37 22h-9a1 1 0 000 2h9a1 1 0 011 1v12a1 1 0 01-1 1H3a1 1 0 01-1-1V25a1 1 0 011-1h9a1 1 0 000-2H3a3 3 0 00-3 3v12a3 3 0 003 3h34a3 3 0 003-3V25a3 3 0 00-3-3zM37 0H3a3 3 0 00-3 3v12a3 3 0 003 3h9a1 1 0 000-2H3a1 1 0 01-1-1V3a1 1 0 011-1h34a1 1 0 011 1v12a1 1 0 01-1 1h-9a1 1 0 000 2h9a3 3 0 003-3V3a3 3 0 00-3-3z" }), /* @__PURE__ */ React.createElement("path", { d: "M20 31.56a1 1 0 001-1V9.27l5.3 5.2a1 1 0 001.41 0 1 1 0 000-1.42l-6-5.89a2.4 2.4 0 00-3.39 0l-6 5.9a1 1 0 000 1.42 1 1 0 00.71.3 1 1 0 00.7-.29L19 9.26v21.3a1 1 0 001 1z" }));
