import alt from "../../alt";

import BaseStore, { StatusStates } from "../../stores/base-store";
import userActions from "../actions/user-actions";
import UserSource from "../sources/user-source";

export let ExtraActions = {
  FETCH_CURRENT: "FETCH_CURRENT"
};

class UserStore extends BaseStore {
  constructor(options) {
    super(options);

    this.bindListeners({
      fetchingCurrent: userActions.FETCH_CURRENT,
      fetchingCurrentDone: userActions.FETCH_CURRENT_DONE,
      fetchingCurrentFail: userActions.FETCH_CURRENT_FAIL,
      updating: userActions.UPDATE,
      updateDone: userActions.UPDATE_DONE,
      updateFail: userActions.UPDATE_FAIL
    });

    this.exportPublicMethods({
      getCurrentUserId: this.getCurrentUserId.bind(this),
      getCurrentUser: this.getCurrentUser.bind(this)
    });

    this.registerAsync(UserSource);
  }

  fetchingCurrent(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.FETCH_CURRENT, StatusStates.PENDING);
  }

  fetchingCurrentDone(dispatchData) {
    this.receiveOne(dispatchData);

    this.augmentAndMarkStatus(dispatchData, ExtraActions.FETCH_CURRENT, StatusStates.DONE);
  }

  fetchingCurrentFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.FETCH_CURRENT, StatusStates.FAIL);
  }

  getCurrentUserId() {
    return window.PM.user.id;
  }

  getCurrentUser() {
    return this.getModelState().find(user => user.get("id") === this.getCurrentUserId());
  }
}

export default alt.createStore(UserStore, "UserStore");
