import React from "react";

export const PmEditorBold = ({ fill = "#323232" }: { fill?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="format_bold" clip-path="url(#clip0_2072_256269)">
        <path
          id="Vector"
          d="M13 8.99168C13.8084 8.43334 14.375 7.51668 14.375 6.66668C14.375 4.78334 12.9167 3.33334 11.0417 3.33334H5.83337V15H11.7C13.4417 15 14.7917 13.5833 14.7917 11.8417C14.7917 10.575 14.075 9.49168 13 8.99168ZM8.33337 5.41668H10.8334C11.525 5.41668 12.0834 5.97501 12.0834 6.66668C12.0834 7.35834 11.525 7.91668 10.8334 7.91668H8.33337V5.41668ZM11.25 12.9167H8.33337V10.4167H11.25C11.9417 10.4167 12.5 10.975 12.5 11.6667C12.5 12.3583 11.9417 12.9167 11.25 12.9167Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2072_256269">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PmEditorItalic = ({ fill = "#323232" }: { fill?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="format_italic" clip-path="url(#clip0_2072_256271)">
        <path
          id="Vector"
          d="M8.33333 3.33334V5.83334H10.175L7.325 12.5H5V15H11.6667V12.5H9.825L12.675 5.83334H15V3.33334H8.33333Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2072_256271">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PmEditorUnderline = ({ fill = "#323232" }: { fill?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="format_underlined" clip-path="url(#clip0_2072_256273)">
        <path
          id="Vector"
          d="M9.99996 14.1667C12.7583 14.1667 15 11.925 15 9.16667V2.5H12.9166V9.16667C12.9166 10.775 11.6083 12.0833 9.99996 12.0833C8.39163 12.0833 7.08329 10.775 7.08329 9.16667V2.5H4.99996V9.16667C4.99996 11.925 7.24163 14.1667 9.99996 14.1667ZM4.16663 15.8333V17.5H15.8333V15.8333H4.16663Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2072_256273">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PmEditorQuote = ({ fill = "#323232" }: { fill?: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17_1772)">
        <path
          d="M18.62 18H13.38L15.38 14H13V6H21V13.24L18.62 18ZM16.62 16H17.38L19 12.76V8H15V12H18.62L16.62 16ZM8.62 18H3.38L5.38 14H3V6H11V13.24L8.62 18ZM6.62 16H7.38L9 12.76V8H5V12H8.62L6.62 16Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_17_1772">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PmEditorOrderedList = ({ fill = "#323232" }: { fill?: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17_1743)">
        <path
          d="M2 17H4V17.5H3V18.5H4V19H2V20H5V16H2V17ZM3 8H4V4H2V5H3V8ZM2 11H3.8L2 13.1V14H5V13H3.2L5 10.9V10H2V11ZM7 5V7H21V5H7ZM7 19H21V17H7V19ZM7 13H21V11H7V13Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_17_1743">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PmEditorUnorderedList = ({ fill = "#323232" }: { fill?: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17_1733)">
        <path
          d="M4 10.5C3.17 10.5 2.5 11.17 2.5 12C2.5 12.83 3.17 13.5 4 13.5C4.83 13.5 5.5 12.83 5.5 12C5.5 11.17 4.83 10.5 4 10.5ZM4 4.5C3.17 4.5 2.5 5.17 2.5 6C2.5 6.83 3.17 7.5 4 7.5C4.83 7.5 5.5 6.83 5.5 6C5.5 5.17 4.83 4.5 4 4.5ZM4 16.5C3.17 16.5 2.5 17.18 2.5 18C2.5 18.82 3.18 19.5 4 19.5C4.82 19.5 5.5 18.82 5.5 18C5.5 17.18 4.83 16.5 4 16.5ZM7 19H21V17H7V19ZM7 13H21V11H7V13ZM7 5V7H21V5H7Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_17_1733">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PmEditorAlignLeft = ({ fill = "#323232" }: { fill?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="format_align_left" clip-path="url(#clip0_2072_256276)">
        <path
          id="Vector"
          d="M12.5 12.5H2.5V14.1667H12.5V12.5ZM12.5 5.83333H2.5V7.5H12.5V5.83333ZM2.5 10.8333H17.5V9.16667H2.5V10.8333ZM2.5 17.5H17.5V15.8333H2.5V17.5ZM2.5 2.5V4.16667H17.5V2.5H2.5Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2072_256276">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PmEditorAlignCenter = ({ fill = "#323232" }: { fill?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="format_align_center" clip-path="url(#clip0_2072_256280)">
        <path
          id="Vector"
          d="M5.83333 12.5V14.1667H14.1667V12.5H5.83333ZM2.5 17.5H17.5V15.8333H2.5V17.5ZM2.5 10.8333H17.5V9.16667H2.5V10.8333ZM5.83333 5.83333V7.5H14.1667V5.83333H5.83333ZM2.5 2.5V4.16667H17.5V2.5H2.5Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2072_256280">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PmEditorAlignRight = ({ fill = "#323232" }: { fill?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="format_align_right" clip-path="url(#clip0_2072_256282)">
        <path
          id="Vector"
          d="M2.5 17.5H17.5V15.8333H2.5V17.5ZM7.5 14.1667H17.5V12.5H7.5V14.1667ZM2.5 10.8333H17.5V9.16667H2.5V10.8333ZM7.5 7.5H17.5V5.83333H7.5V7.5ZM2.5 2.5V4.16667H17.5V2.5H2.5Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2072_256282">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PmEditorAlignJustify = ({ fill = "#323232" }: { fill?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="format_align_justify" clip-path="url(#clip0_2072_256278)">
        <path
          id="Vector"
          d="M2.5 17.5H17.5V15.8333H2.5V17.5ZM2.5 14.1667H17.5V12.5H2.5V14.1667ZM2.5 10.8333H17.5V9.16667H2.5V10.8333ZM2.5 7.5H17.5V5.83333H2.5V7.5ZM2.5 2.5V4.16667H17.5V2.5H2.5Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2072_256278">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PmEditorInsertLink = ({ fill = "#323232" }: { fill?: string }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="insert_link" clip-path="url(#clip0_2072_256284)">
        <path
          id="Vector"
          d="M3.24996 10C3.24996 8.57501 4.40829 7.41668 5.83329 7.41668H9.16663V5.83334H5.83329C3.53329 5.83334 1.66663 7.70001 1.66663 10C1.66663 12.3 3.53329 14.1667 5.83329 14.1667H9.16663V12.5833H5.83329C4.40829 12.5833 3.24996 11.425 3.24996 10ZM6.66663 10.8333H13.3333V9.16668H6.66663V10.8333ZM14.1666 5.83334H10.8333V7.41668H14.1666C15.5916 7.41668 16.75 8.57501 16.75 10C16.75 11.425 15.5916 12.5833 14.1666 12.5833H10.8333V14.1667H14.1666C16.4666 14.1667 18.3333 12.3 18.3333 10C18.3333 7.70001 16.4666 5.83334 14.1666 5.83334Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2072_256284">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
