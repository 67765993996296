import createReactClass from "create-react-class";
import React from "react";

/* tslint:disable:max-line-length */
let Cash = createReactClass({
  render() {
    return (
      <svg
        xmlns="https://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
        width="100"
        height="64"
        viewBox="0 0 100 64"
        className="cash"
      >
        <g fillRule="evenodd">
          <path d="M7 2.03h86c2.76 0 5 2.24 5 5v50c0 2.763-2.24 5-5 5H7c-2.76 0-5-2.237-5-5v-50c0-2.76 2.24-5 5-5z" />
          <path d="M91.786 17.53c-5.68-.097-10.362-4.935-10.286-11.023.07-5.694-3.774-4.997 10-4.976 5.68.01 5.998.01 6 4.977.007 13.334.408 11.13-5.714 11.024zm-3.286 15c0 1.106-.895 2-2 2h-20c-1.105 0-2-.894-2-2 0-1.103.895-2 2-2h20c1.105 0 2 .897 2 2zm-56 2h-20c-1.105 0-2-.894-2-2 0-1.103.895-2 2-2h20c1.105 0 2 .897 2 2 0 1.106-.895 2-2 2zm-25.286-17c-6.122.107-5.72 2.31-5.714-11.023.002-4.967.32-4.967 6-4.976 13.774-.02 9.928-.717 10 4.977.076 6.088-4.606 10.926-10.286 11.024zm0 28c5.68.1 10.362 4.937 10.286 11.025-.072 5.694 3.774 4.998-10 4.976-5.68-.008-5.998-.007-6-4.975-.007-13.333-.408-11.13 5.714-11.024zm84.572 0c6.122-.105 5.72-2.308 5.714 11.025-.002 4.968-.32 4.967-6 4.976-13.774.023-9.93.72-10-4.975-.076-6.088 4.606-10.925 10.286-11.024z" />
          <path d="M55.76 31.146c-.817-.904-2.29-1.764-4.42-2.58l-1.277-.485v-6.094c1.737.134 3.485.547 5.244 1.24l1.227-3.066c-2.04-.837-4.196-1.295-6.47-1.373v-2.763h-2.236v2.813c-2.062.213-3.695.815-4.9 1.81-1.204.993-1.806 2.26-1.806 3.8 0 1.44.423 2.65 1.27 3.626.845.977 2.186 1.817 4.024 2.52l1.412.52v6.263c-1.02-.01-2.174-.176-3.462-.494-1.29-.32-2.415-.712-3.378-1.18v3.532c1.78.804 4.06 1.23 6.84 1.273v3.534h2.235v-3.633c2.24-.246 3.955-.893 5.143-1.943 1.188-1.05 1.782-2.383 1.782-4.002 0-1.307-.41-2.412-1.227-3.316zm-5.697 6.096V31.9c1.064.39 1.824.795 2.277 1.214.454.418.68.952.68 1.6 0 1.383-.985 2.226-2.957 2.528zm-4.286-14.37c.47-.412 1.154-.685 2.05-.82v5.21c-1.04-.414-1.76-.833-2.16-1.257-.397-.424-.595-.955-.595-1.59 0-.615.235-1.13.705-1.542z" />
        </g>
      </svg>
    );
  },
});
/* tslint:enable:max-line-length */

export default Cash;
