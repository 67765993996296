import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

class AppointmentRequestAPI extends BaseApi {
  static fetchAll() {
    return this.get(ApiUrls.appointmentRequests);
  }

  static fetchForMeld(meldId: number) {
    return this.get(ApiUrls.meldAppointmentRequestDetail(meldId));
  }

  static fetchForAssignment(assignmentId: number) {
    return this.get(ApiUrls.assignmentAppointmentRequestDetail(assignmentId));
  }

  static create(data: any) {
    return this.post(ApiUrls.appointmentRequests, data);
  }

  static update(data: any, id: number) {
    return this.patch(ApiUrls.appointmentRequestDetail(id), data);
  }
}

export default AppointmentRequestAPI;
