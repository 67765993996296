import * as C from "../../constants";
import { ErrorHandler } from "../../../../app/utils/ErrorHandler";

let SubscriptionUtils = {
  isTrialing(subscription) {
    return subscription.status === C.SubscriptionStatuses.TRIALING;
  },

  getCostPerUnitPerMonth(numUnits, plans, interval) {
    let plan = this.findDefaultPlan(numUnits, plans, interval);

    let amount = plan.metadata.per_unit_cost || plan.amount;

    if (interval === "year") {
      return amount / 12;
    }
    return amount;
  },

  findDefaultPlan(numUnits, plans, interval) {
    return plans
      .filter(p => p.interval === interval)
      .filter(p => numUnits < p.metadata.units_lt)
      .sort((p1, p2) => p1.metadata.units_lt - p2.metadata.units_lt)[0];
  },

  getCostPerMonth(numUnits, plan, { coupon } = {}) {
    let amount = plan.amount;
    let interval = 1;

    if (plan.interval === "year") {
      interval = 12;
    }

    if (plan.metadata.billing === C.BillingTypes.PER_UNIT_BILLING) {
      amount = plan.amount * numUnits;
      if (interval === 12) {
        amount = amount / 12;
      }
    } else {
      amount = amount / (interval / plan.interval_count);
    }

    if (coupon && coupon.valid) {
      amount = amount - this.calcCouponDiscount(amount, coupon);
    }

    return amount;
  },

  calcAmountDueToday(numUnits, plan, { coupon } = {}) {
    let amount;

    if (plan.metadata.billing === C.BillingTypes.FLAT_BILLING) {
      amount = plan.amount;
    } else {
      amount = plan.amount * numUnits;
    }

    if (coupon && coupon.valid) {
      if (coupon.free_trial) {
        amount = 0;
      } else {
        amount -= this.calcCouponDiscount(amount, coupon);
      }
    }

    return amount;
  },

  calcCouponDiscount(cost, coupon) {
    if (coupon.percent_off) {
      return cost - (cost * (100 - coupon.percent_off)) / 100;
    } else {
      return coupon.amount_off;
    }
  },

  formatBillingCycle(plan) {
    switch (plan.interval) {
      case "month":
        return "Monthly";
      case "year":
        return "Annual";
      default:
        ErrorHandler.handleError(
          new Error(`Trying to format billing cycle for unknown plan interval: ${plan.interval}`)
        );
    }
  }
};

export default SubscriptionUtils;
