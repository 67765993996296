var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import FileUtils from "@pm-assets/js/common/utils/file-utils";
import DocumentSvg from "@pm-assets/js/svg/document-svg";
import { Box, Image } from "@chakra-ui/react";
import React from "react";
import Axios from "axios";
import { parseString } from "xml2js";
export class RenderFileUtils {
  static renderFilePreview(file) {
    if (FileUtils.isPDF(file)) {
      return /* @__PURE__ */ React.createElement(
        "object",
        {
          height: "100%",
          width: "100%",
          data: file.get("openable_signed_url"),
          type: "application/pdf",
          className: "pdf-file-preview"
        },
        /* @__PURE__ */ React.createElement(DocumentSvg, null)
      );
    } else if (!FileUtils.isPDF(file) && FileUtils.isPreviewAvailable(file)) {
      return /* @__PURE__ */ React.createElement(Image, { margin: "0 auto", src: file.get("full_compressed") });
    } else {
      return /* @__PURE__ */ React.createElement(Box, { display: "flex", flexDir: "column", height: "100%", paddingBottom: "50px", paddingTop: "50px" }, /* @__PURE__ */ React.createElement(Box, { width: "auto", padding: "10px", margin: "auto" }, /* @__PURE__ */ React.createElement(DocumentSvg, null)));
    }
  }
}
export const uploadToS3 = (file, policy) => __async(void 0, null, function* () {
  let formData = new FormData();
  Object.entries(policy.fields).forEach(([key, value]) => {
    formData.append(key, value);
  });
  formData.append("file", file);
  const uploadResponse = yield Axios.post(policy.url, formData);
  let uploadResponseData;
  parseString(uploadResponse.data, (error, result) => {
    if (error) {
      throw error;
    }
    uploadResponseData = result;
  });
  return uploadResponseData;
});
