import alt from "../../alt";
import BaseStore from "../../stores/base-store";
import OwnerActions from "../actions/owner-actions";
import OwnerSource from "../sources/owner-source";

export class OwnerStore extends BaseStore {
  constructor() {
    super();

    this.registerAsync(OwnerSource);

    this.bindListeners({
      receive: OwnerActions.FETCH_OWNERS_DONE,
      fetching: OwnerActions.FETCH_OWNER,
      fetchDone: OwnerActions.FETCH_OWNER_DONE,
      fetchFail: OwnerActions.FETCH_OWNER_FAIL,
      deleting: OwnerActions.DELETE_OWNER,
      deleteDone: OwnerActions.DELETE_OWNER_DONE,
      deleteFail: OwnerActions.DELETE_OWNER_FAIL,
      saving: OwnerActions.SAVE_OWNER,
      saveDone: OwnerActions.SAVE_OWNER_DONE,
      saveFail: OwnerActions.SAVE_OWNER_FAIL,
      updateDone: OwnerActions.UPDATE_OWNER_DONE,
      updateFail: OwnerActions.UPDATE_OWNER_FAIL,
      updating: OwnerActions.UPDATE_OWNER
    });
  }
}

export default alt.createStore(OwnerStore, "OwnerStore");
