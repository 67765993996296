import createReactClass from "create-react-class";
import React from "react";

/* tslint:disable:max-line-length */
let Trash = createReactClass({
  render() {
    return (
      <svg
        className={"trash-svg" + (this.props.classes || " ")}
        xmlns="https://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 26 29"
      >
        <path
          d="M24.7 6.02h-5.2v-.988C19.5 2.822 17.755 0 15.6 0h-5.2C8.245 0 6.5 2.822 6.5 5.032v.99H1.3c-.718 0-1.3.72-1.3 1.457C0 8.215.582 9.03 1.3 9.03h1.675v16c0 2.21 1.37 4 3.525 4h12.594c2.154 0 3.9-1.79 3.9-4v-16h1.3c.718 0 1.706-.816 1.706-1.553s-.582-1.46-1.3-1.46zM9.975 5.033c0-.737.582-2.022 1.3-2.022h3.42c.717 0 1.3 1.285 1.3 2.022v.99h-6.02v-.99zm10.012 19.655c0 .737-.58 1.334-1.3 1.334H7.312c-.718 0-1.3-.596-1.3-1.333V9.032h13.975v15.655z"
          fillRule="evenodd"
        />
      </svg>
    );
  },
});
/* tslint:enable:max-line-length */

export default Trash;
