import _ from "lodash";
import ErrorRenderingUtils from "../../utils/error-rendering-utils";
import I from "immutable";
import PaymentValidationUtils from "../utils/validation-utils";
import React from "react";
import Status from "../../mixins/status";

/*
 Users of this mixin MUST define `handleCardUpdated` and `handleCardReadyToUpdate`.
 */
let SubscriptionCreditCard = {
  mixins: [Status, ErrorRenderingUtils],

  getInitialState() {
    return {
      data: I.Map(),
      cid: _.uniqueId("SubscriptionCreditCard_"),
      errors: []
    };
  },

  getExtraValidationErrors() {
    return PaymentValidationUtils.validateCC(this.state.data.toJS());
  },

  getExtraErrors() {
    return this.state.errors;
  },

  handleCCSave(e) {
    if (this.isValid()) {
      this.setState({ saving: true });
      let data = this.state.data.toJS();

      Stripe.card.createToken(
        {
          number: data.cardNumber,
          cvc: data.cvc,
          exp_month: data.expMonth,
          exp_year: data.expYear
        },
        this.handleStripeResponse
      );
    }
  },

  handleStripeResponse(status, response) {
    if (response.error) {
      this.setState({ errors: [response.error.message] });
    } else {
      this.handleCardReadyToUpdate({ token: response.id });
    }
  },

  componentWillReceiveProps(props) {
    if (this.didRequestSucceed()) {
      this.handleCardUpdated();
    } else if (this.didFail()) {
      this.setState({ saving: false });
    }
  }
};

export default SubscriptionCreditCard;

export function withCreditCardMaker(Comp) {
  return class withCreditCardMakerContainer extends React.Component {
    constructor(options) {
      super(options);

      this.handleCreateTokenResponse = this.handleCreateTokenResponse.bind(this);
      this.createToken = this.createToken.bind(this);
    }

    render() {
      return <Comp {...this.props} createToken={this.createToken} />;
    }

    createToken(cardData, success, fail) {
      Stripe.card.createToken(
        {
          number: cardData.cardNumber,
          cvc: cardData.cvc,
          exp_month: cardData.expMonth,
          exp_year: cardData.expYear
        },
        this.handleCreateTokenResponse.bind(this, success, fail)
      );
    }

    handleCreateTokenResponse(success, fail, status, response) {
      if (response.error) {
        fail(response.error.message, response);
      } else {
        success(response.id, response);
      }
    }
  };
}
