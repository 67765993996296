import I from "immutable";

let AssignmentRequestUtils = {
  isOpen(assignment: I.Map<string, string>) {
    return (
      assignment.get("rejected") === null && assignment.get("canceled") === null && assignment.get("accepted") === null
    );
  },

  isAssigned(assignment: I.Map<string, string>) {
    return !assignment.get("rejected") && !assignment.get("canceled");
  }
};

export default AssignmentRequestUtils;
