import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import React from "react";

export const DecimalCondition: React.FC<any> = ({ error, index, lookup_name, onChange, operator_key, value }) => {
  if (
    [
      "equal",
      "not_equal",
      "greater_than",
      "less_than",
      "in_range",
      "between",
      "greater_or_equal",
      "lesser_or_equal",
    ].includes(operator_key)
  ) {
    return (
      <NumberInput
        isInvalid={!!error}
        key={`decimal-${lookup_name}-${index}`}
        data-test={"workflows.form.select.block_operator.condition_type.decimal"}
        onChange={onChange}
        value={value}
        width="100%"
      >
        <NumberInputField data-test={"workflows.form.select.block_operator.condition_type.decimal.input"} />

        <NumberInputStepper>
          <NumberIncrementStepper
            data-test={"workflows.form.select.block_operator.condition_type.decimal.stepper.up"}
          />

          <NumberDecrementStepper
            data-test={"workflows.form.select.block_operator.condition_type.decimal.stepper.down"}
          />
        </NumberInputStepper>
      </NumberInput>
    );
  }

  return <></>;
};
