import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";

import DetailLabel from "../../common/components/detail-label";

let CCExpiration = createReactClass({
  propTypes: {
    onDataChanged: PropTypes.func.isRequired,
    expYear: PropTypes.string,
    expMonth: PropTypes.string
  },

  render() {
    return (
      <div className="expiration-info">
        <DetailLabel>Expiration Date</DetailLabel>
        <div className="expiration-input-container">
          <input
            type="text"
            className="card-expiration cc-month-exp"
            placeholder="MM"
            value={this.props.expMonth}
            autoComplete="cc-exp-month"
            onChange={this.handleMonthExpInputChange}
          />
          &nbsp;
          <span className="expiration-separator">/</span>
          &nbsp;
          <input
            type="text"
            className="card-expiration cc-year-exp"
            placeholder="YY"
            autoComplete="cc-exp-year"
            value={this.props.expYear}
            onChange={this.handleYearExpInputChange}
          />
        </div>
      </div>
    );
  },

  handleYearExpInputChange(e) {
    this.props.onDataChanged({ expYear: e.target.value.trim() });
  },

  handleMonthExpInputChange(e) {
    this.props.onDataChanged({ expMonth: e.target.value.trim() });
  }
});

export default CCExpiration;
