import { useHistory, useLocation } from "react-router-dom";
import { EuiComboBoxOptionOption, EuiComboBoxProps } from "@elastic/eui";

import { getPersonaColor } from "@pm-frontend/shared/utils/color-utils";
import { colors } from "@pm-frontend/styles";
import { BaseURLFilter, DeepPartial, SavedFilterObject } from "../BaseFilterClasses";

const ANY_OF_SUFFIX = "" as const;
const NONE_OF_SUFFIX = "_exclude" as const;
const MISSING_PRESENT_SUFFIX = "_exists" as const;

export const PM_COMBOBOX_FILTER_OPERATORS = {
  ANY_OF: "Any of",
  NONE_OF: "None of",
  NOT_CONTAINS_ALL: "Not contains all",
  CONTAINS_ALL: "Contains all",
  ALL_OF: "All of",
  NOT_ALL_OF: "Not all of",
  MISSING: "Missing",
  PRESENT: "Present",
} as const;

export type PmComboboxFilterAllowedOperators =
  typeof PM_COMBOBOX_FILTER_OPERATORS[keyof typeof PM_COMBOBOX_FILTER_OPERATORS];

export const PM_COMBOBOX_FILTER_INPUT_TYPES = { COMBOBOX: "combobox", BOOLEAN: "boolean" } as const;

export type PmComboboxFilterInputTypes =
  typeof PM_COMBOBOX_FILTER_INPUT_TYPES[keyof typeof PM_COMBOBOX_FILTER_INPUT_TYPES];

export type PmComboboxFilterOperatorValue =
  | ({
      filterType: typeof BaseComboboxFilterClass.type;
    } & (
      | {
          operator: Extract<
            PmComboboxFilterAllowedOperators,
            "Any of" | "None of" | "Not contains all" | "Contains all" | "All of" | "Not all of"
          >;
          selectedOptions: Array<EuiComboBoxOptionOption<string>>;
          type: Extract<PmComboboxFilterInputTypes, "combobox">;
        }
      | {
          operator: Extract<PmComboboxFilterAllowedOperators, "Missing" | "Present">;
          type: Extract<PmComboboxFilterInputTypes, "boolean">;
        }
    ))
  | null;

export interface PmComboboxFilterProps {
  text: string;
  queryParamKeyPrefix: string;
  filterName: string;
  componentProps: Pick<EuiComboBoxProps<number>, "placeholder">;
  popoverWidth: string;
  allowedOperators: PmComboboxFilterAllowedOperators[];
  options: Array<{
    queryParamValue: string;
    label: string;
    created: string | undefined;
    isGroupLabelOption?: boolean;
  }>;
  alwaysShow?: boolean;
}

export class BaseComboboxFilterClass extends BaseURLFilter<
  PmComboboxFilterAllowedOperators,
  Pick<EuiComboBoxProps<number>, "placeholder">,
  PmComboboxFilterProps
> {
  static type = "combobox_filter" as const;

  constructor(config: PmComboboxFilterProps, overrides?: DeepPartial<PmComboboxFilterProps>) {
    super(config, overrides);
  }

  getCurrentlyAppliedOperatorAndValue({
    savedFilter,
    location,
  }: {
    savedFilter: SavedFilterObject;
    location: ReturnType<typeof useLocation>;
  }): PmComboboxFilterOperatorValue {
    const currentSearchParams = new URLSearchParams(location.search);
    for (const operator of this.getAllowedOperators()) {
      const value = savedFilter
        ? this.getSavedFilterValue({ operator, savedFilter })
        : this.getSearchParamValue({ currentSearchParams, operator });
      if (!value) {
        continue;
      }
      switch (operator) {
        case PM_COMBOBOX_FILTER_OPERATORS.MISSING:
          if (value === "false") {
            return {
              operator: PM_COMBOBOX_FILTER_OPERATORS.MISSING,
              filterType: BaseComboboxFilterClass.type,
              type: PM_COMBOBOX_FILTER_INPUT_TYPES.BOOLEAN,
            };
          }
          break;
        case PM_COMBOBOX_FILTER_OPERATORS.PRESENT:
          if (value === "true") {
            return {
              operator: PM_COMBOBOX_FILTER_OPERATORS.PRESENT,
              filterType: BaseComboboxFilterClass.type,
              type: PM_COMBOBOX_FILTER_INPUT_TYPES.BOOLEAN,
            };
          }
          break;
        case PM_COMBOBOX_FILTER_OPERATORS.ALL_OF:
        case PM_COMBOBOX_FILTER_OPERATORS.ANY_OF:
        case PM_COMBOBOX_FILTER_OPERATORS.NONE_OF:
        case PM_COMBOBOX_FILTER_OPERATORS.NOT_ALL_OF:
        case PM_COMBOBOX_FILTER_OPERATORS.CONTAINS_ALL:
        case PM_COMBOBOX_FILTER_OPERATORS.NOT_CONTAINS_ALL:
          // saved filter data is an array, query param is a comma-delimited string
          // eslint-disable-next-line no-case-declarations
          const parsedValue: string[] = (typeof value === "string" ? value.split(",") : value).map(
            (v: string | number) => v.toString()
          );
          // eslint-disable-next-line no-case-declarations
          const matchingOperator = this.getOptions().some((opt) => parsedValue.includes(opt.queryParamValue));
          if (!matchingOperator) {
            continue;
          }
          // eslint-disable-next-line no-case-declarations
          const selectedOptions = this.getDefaultMappedOptions()
            .filter((opt) => parsedValue.includes(opt.value))
            .filter(Boolean);
          return {
            operator,
            selectedOptions,
            filterType: BaseComboboxFilterClass.type,
            type: PM_COMBOBOX_FILTER_INPUT_TYPES.COMBOBOX,
          };
        default:
          break;
      }
    }
    return null;
  }

  getDefaultMappedOptions() {
    return this.getOptions().map((opt) => ({
      label: opt.label,
      value: opt.queryParamValue,
      color: opt.created ? getPersonaColor({ created: opt.created }) : colors.brand.veryLightBlue,
      isGroupLabelOption: !!opt.isGroupLabelOption,
    }));
  }

  getNewValueFromNewOperator({
    newOperator,
    currentPendingOperatorAndValue,
  }: {
    newOperator: PmComboboxFilterAllowedOperators;
    currentPendingOperatorAndValue: NonNullable<PmComboboxFilterOperatorValue>;
  }): NonNullable<PmComboboxFilterOperatorValue> {
    const oldType = currentPendingOperatorAndValue.type;
    let type: PmComboboxFilterInputTypes = PM_COMBOBOX_FILTER_INPUT_TYPES.BOOLEAN;
    switch (newOperator) {
      case PM_COMBOBOX_FILTER_OPERATORS.MISSING:
      case PM_COMBOBOX_FILTER_OPERATORS.PRESENT:
        return { operator: newOperator, type, filterType: BaseComboboxFilterClass.type };
      default:
        type = PM_COMBOBOX_FILTER_INPUT_TYPES.COMBOBOX;
        if (oldType === PM_COMBOBOX_FILTER_INPUT_TYPES.BOOLEAN) {
          return { operator: newOperator, type, selectedOptions: [], filterType: BaseComboboxFilterClass.type };
        } else {
          return {
            operator: newOperator,
            type,
            selectedOptions: currentPendingOperatorAndValue.selectedOptions,
            filterType: BaseComboboxFilterClass.type,
          };
        }
    }
  }

  getOnApplyClick({
    location,
    closePopover,
    history,
    pendingOperatorAndValue,
    savedFilter,
  }: {
    location: ReturnType<typeof useLocation>;
    history: ReturnType<typeof useHistory>;
    closePopover: () => void;
    pendingOperatorAndValue: NonNullable<PmComboboxFilterOperatorValue>;
    savedFilter: SavedFilterObject;
  }) {
    return () => {
      const savedSearchParams = this.getQueryParamsFromSavedFilter({ savedFilter, location });
      this.deleteAllParamValues({ paramsToMutate: savedSearchParams, location });
      const key = this.getFullQueryParamKey({ operator: pendingOperatorAndValue.operator, target: "url" });
      switch (pendingOperatorAndValue.operator) {
        case PM_COMBOBOX_FILTER_OPERATORS.MISSING:
        case PM_COMBOBOX_FILTER_OPERATORS.PRESENT:
          savedSearchParams.set(
            key,
            pendingOperatorAndValue.operator === PM_COMBOBOX_FILTER_OPERATORS.PRESENT ? "true" : "false"
          );
          break;
        default:
          if (pendingOperatorAndValue.selectedOptions.length === 0) {
            return;
          } else {
            const value = pendingOperatorAndValue.selectedOptions
              .map((mappedOption) => this.getOptions().find((opt) => opt.label === mappedOption.label)?.queryParamValue)
              .filter(Boolean)
              .join(",");
            savedSearchParams.set(key, value);
          }
          break;
      }
      history.replace({
        pathname: location.pathname,
        search: savedSearchParams.toString(),
      });
      closePopover();
    };
  }

  getAppliedFilterCount({
    currentOperatorAndValue,
  }: {
    currentOperatorAndValue: PmComboboxFilterOperatorValue;
  }): number {
    if (!currentOperatorAndValue) {
      return 0;
    }
    switch (currentOperatorAndValue.operator) {
      case PM_COMBOBOX_FILTER_OPERATORS.MISSING:
      case PM_COMBOBOX_FILTER_OPERATORS.PRESENT:
        return 1;
      default:
        return currentOperatorAndValue.selectedOptions.length;
    }
  }

  /*
   * The saved filter objects don't have the '[]' suffix that the query params do
   */
  protected getFullQueryParamKey({
    operator,
    target,
  }: {
    operator: PmComboboxFilterAllowedOperators;
    target: "savedFilter" | "url";
  }): string {
    switch (operator) {
      case "Any of":
        return this.getQueryParamKeyPrefix() + ANY_OF_SUFFIX + (target === "url" ? "[]" : "");
      case "None of":
        return this.getQueryParamKeyPrefix() + NONE_OF_SUFFIX + (target === "url" ? "[]" : "");
      case "Missing":
        return this.getQueryParamKeyPrefix() + MISSING_PRESENT_SUFFIX;
      case "Present":
        return this.getQueryParamKeyPrefix() + MISSING_PRESENT_SUFFIX;
      default:
        return this.getQueryParamKeyPrefix();
    }
  }

  protected getOptions() {
    return this.getConfig().options;
  }
}
