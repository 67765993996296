import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";

import DetailLabel from "../../common/components/detail-label";

let CCCvc = createReactClass({
  propTypes: {
    onDataChanged: PropTypes.func.isRequired,
    cvc: PropTypes.string
  },

  render() {
    return (
      <div className="cvc-info">
        <DetailLabel>CVC</DetailLabel>
        <input
          type="text"
          className="card-cvc"
          placeholder="CVC"
          autoComplete="cc-csv"
          value={this.props.cvc}
          onChange={this.handleCVCChange}
        />
      </div>
    );
  },

  handleCVCChange(e) {
    this.props.onDataChanged({ cvc: e.target.value.trim() });
  }
});

export default CCCvc;
