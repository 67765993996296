import React from "react";
import ShareSVG from "../../svg/share-svg";
import { ImmutableMap } from "../../types/common";

interface Props {
  file: ImmutableMap;
  onShare: (file: ImmutableMap) => void;
}

interface State {
  openShare: boolean;
}

export default class FileViewerShareFile extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openShare: false
    };
  }

  render() {
    return (
      <React.Fragment>
        <a
          className="file-viewer-share-file file-action"
          title="Share"
          onClick={this.props.onShare.bind(this, this.props.file)}
        >
          <ShareSVG />
        </a>
      </React.Fragment>
    );
  }
}
