import * as React from "react";
import shallowCompare from "react-addons-shallow-compare";

import Checkbox from "./checkbox";

export default class PureCheckbox extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  render() {
    return <Checkbox {...this.props} />;
  }
}
