import * as C from "../../constants";
import ContactUtils from "../../utils/contact-utils";
import I from "immutable";
import userStore from "../../authentication/stores/user-store";
import { hasPerm } from "../../common/utils/permission-utils";
import { ImmutableMap, None } from "../../types/common";
import { RoleUtils } from "./role-utils";
import { ManagementAgentNode } from "../../common/graphql/types";
import { asImmer } from "../../../../app/utils/decorators";

type Agent = ManagementAgentNode | MaybeImmutableAgent;

export interface ImmutableAgent extends I.Map<any, any> {
  kind: "ImmutableAgent";
}
export interface ImmutableAgentList extends I.List<any> {
  kind: "ImmutableAgentList";
}

type MaybeImmutableAgent = ImmutableMap | None;
type ImmutableAgentOrList = I.List<I.Map<string, any>> | I.Map<string, any>;

class AgentUtils {
  static NAMELESS_USER = "Nameless user";

  @asImmer()
  static getFullName(agent?: Agent, emptyMsg = AgentUtils.NAMELESS_USER) {
    agent = agent as ManagementAgentNode;

    if (!agent) {
      return emptyMsg;
    }

    const fullName = `${agent.first_name || ""} ${agent.last_name || ""}`;

    if (fullName.replace(" ", "")) {
      return fullName;
    } else {
      return emptyMsg;
    }
  }

  static getPrimaryPhone(agent: I.Map<any, any>) {
    return ContactUtils.getFirstFoundPhone(agent.get("contact"));
  }

  static getPrimaryEmail(agent: I.Map<any, any>) {
    return ContactUtils.getPrimaryEmail(agent.get("contact"));
  }

  static getRoleName(agent: I.Map<any, any>) {
    return agent.getIn(["role", "name"]);
  }

  static getFormattedRole(agent: MaybeImmutableAgent) {
    if (agent) {
      return RoleUtils.formatName(agent.get("role"));
    } else {
      return undefined;
    }
  }

  static canDelete(agent: MaybeImmutableAgent) {
    if (agent) {
      let isCurrentAgent = AgentUtils.isThisTheCurrentAgent(agent);
      return !isCurrentAgent && hasPerm(C.Perms.CAN_DELETE_MANAGEMENT_AGENTS);
    }
  }

  static canEdit(agent: MaybeImmutableAgent) {
    if (agent) {
      let isCurrentAgent = AgentUtils.isThisTheCurrentAgent(agent);
      return !isCurrentAgent && hasPerm(C.Perms.CAN_EDIT_MANAGEMENT_AGENTS);
    }
  }

  static canChangeManagementAgentPropertyGroup() {
    return hasPerm(C.Perms.CAN_CHANGE_MANAGEMENTAGENTPROPERTYGROUPS);
  }

  static isThisTheCurrentAgent(agentOrAgents: ImmutableAgentOrList): boolean {
    const currentUserId = userStore.getCurrentUserId();
    if (agentOrAgents && I.Map.isMap(agentOrAgents)) {
      if (agentOrAgents.getIn(["user", "id"])) {
        return agentOrAgents.getIn(["user", "id"]) === currentUserId;
      }
    } else if (agentOrAgents && I.List.isList(agentOrAgents)) {
      let agents: I.List<I.Map<string, any>> = agentOrAgents as I.List<any>;
      let results = agents.map((agent: I.Map<string, any>) => {
        return agent.getIn(["user", "id"]);
      }) as I.List<number>;
      return results.contains(currentUserId);
    }
    return false;
  }

  static getFirstFoundPhone(agent: I.Map<any, any>, emptyMsg?: string) {
    return ContactUtils.getFirstFoundPhone(agent.get("contact"), emptyMsg);
  }

  static getCellPhone(agent: MaybeImmutableAgent, emptyMsg?: string) {
    return (agent && agent.getIn && agent.getIn(["contact", "cell_phone"])) || emptyMsg;
  }

  static getBusinessPhone(agent: MaybeImmutableAgent, emptyMsg?: string) {
    return (agent && agent.getIn && agent.getIn(["contact", "business_phone"])) || emptyMsg;
  }

  static getHomePhone(agent: MaybeImmutableAgent, emptyMsg?: string) {
    return (agent && agent.getIn && agent.getIn(["contact", "home_phone"])) || emptyMsg;
  }
}

export default AgentUtils;
