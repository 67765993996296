import React, { CSSProperties, useState } from "react";
import {
  EuiButtonEmpty,
  EuiContextMenu,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiContextMenuProps,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiImage,
  EuiLoadingSpinner,
  EuiNotificationBadge,
  EuiPopover,
  EuiText,
} from "@elastic/eui";

import { colors } from "@pm-frontend/styles";
import URL from "@pm-shared/utils/url";
import { PmCheckCircleOutline } from "@pm-frontend/assets/icons/components/PmCheckCircleOutline";
import { CSSObject } from "@emotion/react";

const MESSAGE_TIMEOUT_IN_MS = 3000;

interface BulkAction {
  onClick: () => void;
  icon?: "default" | "download" | "delete" | "share" | "preview";
  text: string;
  dataTestId?: string;
  enabled?: boolean;
}

type PmBulkActionButtonProps = {
  body: React.ReactNode;
  numFilters: number;
  color: "success" | "danger" | "warning";
  alwaysShowBulkActions?: boolean;
  displayState: "button" | "success" | "error" | "loading";
  successText: string;
  "data-testid"?: string;
} & (
  | {
      panels: EuiContextMenuProps["panels"];
      isPopoverOpen: boolean;
      setIsPopoverOpen: React.Dispatch<React.SetStateAction<boolean>>;
      bulkActions?: never;
    }
  | {
      bulkActions: BulkAction[] | undefined;
      panels?: never;
      isPopoverOpen?: never;
      setIsPopoverOpen?: never;
    }
);

const colorMap = {
  success: {
    badge: {
      backgroundColor: colors.interface.green.default,
      textColor: colors.brand.white,
    },
    borderColor: colors.interface.green.default,
    textColor: colors.interface.green.default,
  },
  danger: {
    badge: {
      backgroundColor: colors.interface.red.default,
      textColor: colors.brand.white,
    },
    borderColor: colors.interface.red.default,
    textColor: colors.interface.red.default,
  },
  warning: {
    badge: {
      backgroundColor: colors.brand.meldBlue,
      textColor: colors.brand.white,
    },
    borderColor: colors.brand.meldBlue,
    textColor: colors.brand.meldBlue,
  },
};

const BulkActionButton = ({
  onClick,
  innerBody,
  color,
}: {
  onClick: (() => void) | undefined;
  innerBody: React.ReactNode;
  color: PmBulkActionButtonProps["color"];
}) => {
  return (
    <EuiButtonEmpty
      style={{
        border: `1px solid ${colorMap[color].borderColor}`,
        borderRadius: "6px",
        textDecoration: "none",
        width: "fit-content",
      }}
      contentProps={{ style: { width: "fit-content", padding: "0px" } }}
      onClick={onClick}
    >
      <EuiText color={colorMap[color].textColor} size="s" style={{ lineHeight: "14px" }}>
        {innerBody}
      </EuiText>
    </EuiButtonEmpty>
  );
};

interface PmBulkActionButtonState {
  displayState: "button" | "loading" | "success" | "error";
  color: PmBulkActionButtonProps["color"];
  setColor: React.Dispatch<React.SetStateAction<PmBulkActionButtonProps["color"]>>;
  setBody: React.Dispatch<React.SetStateAction<PmBulkActionButtonProps["body"]>>;
  body: PmBulkActionButtonProps["body"];
  successText: string;
  setToButtonState: () => void;
  setToLoadingState: () => void;
  setToSuccessState: () => void;
  setToErrorState: () => void;
  setSuccessText: React.Dispatch<React.SetStateAction<string>>;
}

interface UsePmBulkActionHookArguments {
  initialColor?: PmBulkActionButtonProps["color"];
  initialBody?: React.ReactNode;
}
/**
 * A custom hook for managing the state of a PmBulkActionButton
 *
 */
const usePmBulkActionButton = (
  props: UsePmBulkActionHookArguments = { initialColor: "success", initialBody: "" }
): PmBulkActionButtonState => {
  const [displayState, setDisplayState] = useState<PmBulkActionButtonProps["displayState"]>("button");
  const [successText, setSuccessText] = useState("");
  const [color, setColor] = useState<PmBulkActionButtonProps["color"]>(props.initialColor || "success");
  const [body, setBody] = useState<PmBulkActionButtonProps["body"]>(props.initialBody || "");
  const setToButtonState = () => setDisplayState("button");
  const setToLoadingState = () => setDisplayState("loading");
  const setToSuccessState = () => {
    setDisplayState("success");
    setTimeout(() => {
      setToButtonState();
    }, MESSAGE_TIMEOUT_IN_MS);
  };
  const setToErrorState = () => {
    setDisplayState("error");
    setTimeout(() => {
      setToButtonState();
    }, MESSAGE_TIMEOUT_IN_MS);
  };

  return {
    displayState,
    successText,
    color,
    body,
    setColor,
    setBody,
    setToButtonState,
    setToLoadingState,
    setToSuccessState,
    setToErrorState,
    setSuccessText,
  };
};

const bulkActionIconMap: Record<Required<BulkAction>["icon"], string> = {
  default: URL.getStatic("icons/gray_exclamation.svg"),
  download: URL.getStatic("icons/save_alt.svg"),
  delete: URL.getStatic("icons/remove_circle_outline_blue.svg"),
  share: URL.getStatic("icons/share.svg"),
  preview: URL.getStatic("icons/preview.svg"),
};

const cssContextMenu: CSSObject = {
  padding: "0px",
  "& > .euiContextMenuPanelTitle": {
    padding: "0px",
  },
};

const cssPopoverPanelStyle: CSSProperties = {
  minWidth: "171px",
};

const cssPopoverStyle: CSSProperties = {
  width: "fit-content",
};

const cssPanelProps: CSSObject = {
  backgroundColor: `${colors.brand.white}`,
  "& > div::before": {
    borderTopColor: `${colors.brand.white}`,
  },
};

const renderMenuItems = ({
  menuItems,
  additionalOnClick,
}: {
  menuItems: BulkAction[];
  additionalOnClick: () => void;
}) =>
  menuItems
    .filter((action) => !action.hasOwnProperty("enabled") || action.enabled)
    .map((menuItem: BulkAction) => (
      <EuiContextMenuItem
        key={menuItem.text}
        onClick={() => {
          menuItem.onClick();
          additionalOnClick();
        }}
        style={{ padding: "0px" }}
        data-testid={menuItem && menuItem.dataTestId ? menuItem.dataTestId : undefined}
      >
        <EuiFlexGroup
          direction="row"
          gutterSize="s"
          alignItems="center"
          style={{ padding: "10px 12px" }}
          wrap={false}
          responsive={false}
        >
          <EuiFlexItem grow={false} style={{ padding: "2px" }}>
            <EuiImage
              src={menuItem.icon ? bulkActionIconMap[menuItem.icon] : bulkActionIconMap.default}
              alt=""
              style={{ width: "20px", height: "20px" }}
            />
          </EuiFlexItem>
          <EuiFlexItem
            grow={true}
            style={{
              fontFamily: "Open Sans",
              fontSize: "16px",
              lineHeight: "16px",
            }}
          >
            <EuiText size="s" color={colors.neutrals.gray800}>
              {menuItem.text}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiContextMenuItem>
    ));

/**
 * Emulates an EuiFilterButton - https://elastic.github.io/eui/?search#/forms/filter-group
 * This is needed to add extra styling and color options
 *
 * If passing `panel` the popover state must be provided by the consumer.
 * Otherwise the popover state is defined in an uncontrolled manner
 */
const PmBulkActionButton = ({
  body,
  numFilters,
  color,
  bulkActions,
  alwaysShowBulkActions,
  displayState,
  successText,
  "data-testid": dataTestId = "bulk-action-button",
  panels,
  setIsPopoverOpen: controlledSetIsPopoverOpen,
  isPopoverOpen: controlledIsPopoverOpen,
}: PmBulkActionButtonProps) => {
  const [uncontrolledIsPopoverOpen, uncontrolledSetIsPopoverOpen] = useState<boolean>(false);

  const innerBody = (
    <EuiFlexGroup direction="row" responsive={false} gutterSize="s" alignItems="center">
      <EuiFlexItem grow={false} data-testid={dataTestId}>
        {body}
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {displayState === "loading" ? (
          <EuiLoadingSpinner size="s" />
        ) : (
          <EuiNotificationBadge
            style={{
              color: colorMap[color].badge.textColor,
              background: colorMap[color].badge.backgroundColor,
              padding: "0px 4px",
              borderRadius: "3px",
              height: "17px",
            }}
          >
            {numFilters}
          </EuiNotificationBadge>
        )}
      </EuiFlexItem>
      {(alwaysShowBulkActions || (bulkActions && bulkActions.length > 1) || panels) && (
        <EuiFlexItem>
          <EuiIcon type="arrowDown" color={color} />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  );
  if (displayState === "error") {
    return (
      <EuiFlexGroup
        alignItems="center"
        direction="row"
        responsive={false}
        gutterSize="s"
        wrap={false}
        style={{ height: "40px" }}
      >
        <EuiFlexItem grow={false}>
          <PmCheckCircleOutline />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText
            color={colors.interface.red.dark}
            style={{ fontSize: "14px", fontWeight: 600, whiteSpace: "nowrap" }}
          >
            Something went wrong.
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  } else if (displayState === "success") {
    return (
      <EuiFlexGroup
        alignItems="center"
        direction="row"
        wrap={false}
        responsive={false}
        gutterSize="s"
        style={{ height: "40px" }}
      >
        <EuiFlexItem grow={false}>
          <PmCheckCircleOutline />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText
            color={colors.interface.green.statusText}
            style={{ fontSize: "14px", fontWeight: 600, whiteSpace: "nowrap" }}
          >
            {successText}
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  } else if (!alwaysShowBulkActions && (!bulkActions || bulkActions.length === 1) && panels === undefined) {
    const onClick = bulkActions ? bulkActions[0].onClick : undefined;
    return <BulkActionButton onClick={onClick} innerBody={innerBody} color={color} />;
  } else if (panels && controlledSetIsPopoverOpen) {
    return (
      <EuiPopover
        button={
          <BulkActionButton
            innerBody={innerBody}
            color={color}
            onClick={() => controlledSetIsPopoverOpen((state) => !state)}
          />
        }
        isOpen={controlledIsPopoverOpen}
        closePopover={() => controlledSetIsPopoverOpen(false)}
        panelPaddingSize="none"
        anchorPosition="downLeft"
        panelStyle={cssPopoverPanelStyle}
        repositionOnScroll={true}
        style={cssPopoverStyle}
      >
        <EuiContextMenu panels={panels} initialPanelId={panels?.[0].id || 0} size="s" />
      </EuiPopover>
    );
  } else {
    return (
      <EuiPopover
        id="memory-table-bulk-actions-popover"
        button={
          <BulkActionButton
            innerBody={innerBody}
            color={color}
            onClick={() => uncontrolledSetIsPopoverOpen((state) => !state)}
          />
        }
        isOpen={uncontrolledIsPopoverOpen}
        onClick={() => uncontrolledSetIsPopoverOpen((state) => !state)}
        closePopover={() => uncontrolledSetIsPopoverOpen(false)}
        panelPaddingSize="none"
        anchorPosition="downLeft"
        panelStyle={cssPopoverPanelStyle}
        panelProps={{ css: cssPanelProps }}
        repositionOnScroll={true}
        style={cssPopoverStyle}
      >
        <EuiContextMenuPanel
          title={
            <EuiText
              color={colors.neutrals.gray800}
              style={{ fontSize: "14px", fontWeight: 700, lineHeight: "20px", padding: "16px" }}
            >
              <b>Bulk Actions</b>
            </EuiText>
          }
          size="s"
          items={renderMenuItems({
            menuItems: bulkActions || [],
            additionalOnClick: () => uncontrolledSetIsPopoverOpen(false),
          })}
          style={{ padding: "0px" }}
          css={cssContextMenu}
        />
      </EuiPopover>
    );
  }
};

export { PmBulkActionButton, PmBulkActionButtonProps, usePmBulkActionButton, PmBulkActionButtonState };
