import styled from "@emotion/styled";
import React from "react";
import URL from "@pm-shared/utils/url";

// this is an example of a legacy component that was converted into a styled component by pulling the CSS from the OLDCSS file

const EmptyDiv = styled.div`
  text-align: center;
  margin-top: 30px;
`;
const EmptySprite = styled.span`
  overflow: hidden;
  display: block;
  background: url(${URL.getStatic("static/img/empty-view.png")}) no-repeat;
  background-size: contain;
  max-width: 367px;
  width: 100%;
  height: 174px;
  margin: auto;
`;

const H4 = styled.h4`
  color: #4e515e;
  font-family: Open Sans, Helvetica, Roboto, Arial, sans-serif;
  font-weight: 700;
  font-size: 1.375rem;
`;

const EmptyView: React.FC<{ message?: string; children?: any }> = (props) => (
  <EmptyDiv data-test="empty-view" {...props}>
    <EmptySprite />
    {props.message && <H4>{props.message}</H4>}
    {props.children || <></>}
  </EmptyDiv>
);

export default EmptyView;
