import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";

import DetailLabel from "../../common/components/detail-label";

let CCnumber = createReactClass({
  propTypes: {
    onDataChanged: PropTypes.func.isRequired,
    cardNumber: PropTypes.string
  },

  render() {
    return (
      <div>
        <DetailLabel>Credit Card</DetailLabel>
        <input
          type="text"
          autoComplete="cc-number"
          className="cc-number-input"
          placeholder="xxxx xxxx xxxx xxxx"
          value={this.props.cardNumber}
          onChange={this.handleCCInputChange}
          ref="input"
        />
      </div>
    );
  },

  handleCCInputChange(e) {
    this.props.onDataChanged({ cardNumber: e.target.value.trim() });
  },

  componentDidMount() {
    if (this.props.focusOnMount) {
      this.refs.input.focus();
    }
  }
});

export default CCnumber;
