import alt from "../../alt";

import BaseStore, { StatusStates } from "../../stores/base-store";
import VendorAppointmentSource from "../sources/vendor-appointment-source";
import vendorAppointmentActions from "../actions/vendor-appointment-actions";

let ExtraActions = {
  schedule: "SCHEDULE",
  requestMoreAvailability: "REQUEST_MORE_AVAILABILITY"
};

class VendorAppointmentStore extends BaseStore {
  constructor(options) {
    super(options);

    this.registerAsync(VendorAppointmentSource);

    this.bindListeners({
      fetching: vendorAppointmentActions.FETCH_ONE,
      fetchDone: vendorAppointmentActions.FETCH_ONE_DONE,
      fetchFail: vendorAppointmentActions.FETCH_ONE_FAIL,
      schedule: vendorAppointmentActions.SCHEDULE,
      scheduleDone: vendorAppointmentActions.SCHEDULE_DONE,
      scheduleFail: vendorAppointmentActions.SCHEDULE_FAIL,
      requestMoreAvailability: vendorAppointmentActions.REQUEST_MORE_AVAILABILITY,
      requestMoreAvailabilityDone: vendorAppointmentActions.REQUEST_MORE_AVAILABILITY_DONE,
      requestMoreAvailabilityFail: vendorAppointmentActions.REQUEST_MORE_AVAILABILITY_FAIL
    });
  }

  schedule(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.schedule, StatusStates.PENDING);
  }

  scheduleDone(dispatchData) {
    this.receiveOne(dispatchData);
    this.augmentAndMarkStatus(dispatchData, ExtraActions.schedule, StatusStates.DONE);
  }

  scheduleFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.schedule, StatusStates.FAIL);
  }

  requestMoreAvailability(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.requestMoreAvailability, StatusStates.PENDING);
  }

  requestMoreAvailabilityDone(dispatchData) {
    this.receiveOne(dispatchData);
    this.augmentAndMarkStatus(dispatchData, ExtraActions.requestMoreAvailability, StatusStates.DONE);
  }

  requestMoreAvailabilityFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.requestMoreAvailability, StatusStates.FAIL);
  }
}

export default alt.createStore(VendorAppointmentStore, "VendorAppointmentStore");
