import React from "react";
import { LinkProps } from "react-router-dom";

import { PageHeaderProps } from "../PageHeader/PageHeader";
import { MobileDetailViewsPageHeader } from "./MobileDetailViewsPageHeader";
import { DesktopDetailViewsPageHeader } from "./DesktopDetailViewsPageHeader";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";

type DetailViewsPageHeaderProps = PageHeaderProps & {
  backButtonAriaLabel: string;
  backButtonText: string;
  linkActions?: PageHeaderProps["linkActions"];
  isInactive?: boolean;
  inactiveAction?: PageHeaderProps["inactiveAction"];
} & (
    | {
        backButtonUrl: string;
        backButtonLinkProps?: never;
        backButtonOnClick?: () => void;
      }
    | {
        backButtonUrl?: never;
        backButtonLinkProps: LinkProps<unknown>;
        backButtonOnClick?: () => void;
      }
    | {
        backButtonUrl?: never;
        backButtonLinkProps?: never;
        backButtonOnClick: () => void;
      }
  );

const DetailViewsPageHeader = (props: DetailViewsPageHeaderProps) => {
  const sharedProps = {
    ...props,
    primaryAction: !props.isInactive ? props.primaryAction : undefined,
    secondaryActions: !props.isInactive ? props.secondaryActions : undefined,
    linkActions: !props.isInactive ? props.linkActions : undefined,
  };

  // for changing the layout based on screensize
  const mobileState = useIsMobile();
  if (mobileState) {
    return <MobileDetailViewsPageHeader {...sharedProps} />;
  } else {
    return <DesktopDetailViewsPageHeader {...sharedProps} />;
  }
};

export { DetailViewsPageHeader, DetailViewsPageHeaderProps };
