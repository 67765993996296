import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";

class TenantSettingsApi extends BaseApi {
  static fetchCurrent() {
    return this.get(ApiUrls.tenantSettings);
  }

  static update(data: any) {
    return this.put(ApiUrls.tenantSettings, data);
  }
}

export default TenantSettingsApi;
