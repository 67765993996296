import React from "react";

export default function Tag() {
  return (
    /* tslint:disable:max-line-length */
    <svg className="tag-svg" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 348.1 348.1">
      <path d="M260.1 48.65c-22 0-40 18-40 40s18 40 40 40 40-18 40-40-18-40-40-40zm0 64c-13.2 0-24-10.8-24-24s10.8-24 24-24 24 10.8 24 24-10.8 24-24 24z" />
      <path d="M308.1 1.05h-108c-10.8 0-20.8 4-28 11.6L11.7 172.25c-15.6 15.6-15.6 40.8 0 56.4l107.2 106.8c7.6 7.6 17.6 11.6 28.4 11.6s20.8-4 28.4-11.6l160.4-160.4c7.6-7.2 12-17.2 12-27.6V41.05c0-22-18-40-40-40zm24 146.4c0 6-2.4 12-7.2 16l-160.8 160.8c-4.4 4.4-10.4 6.8-16.8 6.8s-12.4-2.4-16.8-6.8L22.9 217.45c-9.2-9.2-9.2-24.4 0-34l160.4-159.6c4.4-4.4 10.4-6.8 16.8-6.8h108c13.2 0 24 10.8 24 24v106.4z" />
    </svg>
    /* tslint:enable:max-line-length */
  );
}
