import BackArrowSvg from "../../svg/back-arrow-svg";
import CloseSVG from "../../svg/close-svg";
import DocumentSvg from "../../svg/document-svg";
import FileUtils from "../utils/file-utils";
import ForwardArrow from "../../svg/forward-arrow-svg";
import KeyUtils from "../../utils/key-utils";
import React from "react";
import { ImmutableList, ImmutableMap } from "../../types/common";

interface Props {
  files: ImmutableList;
  file: ImmutableMap;
  onClose: () => void;
  navFile: (file: ImmutableMap) => void;
  renderActions?: (file: ImmutableMap) => React.ReactNode;
}

type WidthSizes = Array<{ width: number; url: string }>;

function ResponsiveImg(props: { file: ImmutableMap }) {
  const srcSet = props.file.get("variable_sizes")
    ? props.file
        .get("variable_sizes")
        .toJS()
        .map((sizes: WidthSizes) => {
          return sizes.map((size: { width: number; url: string }) => {
            return `${size.url} ${size.width}w`;
          });
        })
        .reduce((acc: string[], val: string) => acc.concat(val), [])
        .join(", ")
    : null;

  return <img src={props.file.get("full_compressed")} sizes="100vw" srcSet={srcSet} className="image-preview" />;
}

export default class FilePreview extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.navNextFile = this.navNextFile.bind(this);
    this.navPrevFile = this.navPrevFile.bind(this);
  }

  render() {
    const { file } = this.props;

    return (
      <div className="file-previewer">
        <div className="preview-header">
          <div className="filename">{file.get("filename")}</div>
          <div className="close-previewer" onClick={this.props.onClose}>
            <CloseSVG />
          </div>
        </div>

        <div className="file-preview">{this.renderFilePreview()}</div>
        <div className="file-footer">
          <div className="nav-buttons">
            {this.canNavPrev && (
              <div onClick={this.navPrevFile} role="button" className="nav-button nav-prev">
                <BackArrowSvg />
              </div>
            )}
            {this.canNavNext && (
              <div onClick={this.navNextFile} role="button" className="nav-button nav-next">
                <ForwardArrow />
              </div>
            )}
          </div>
          <div className="file-actions">{this.props.renderActions && this.props.renderActions(file)}</div>
        </div>
      </div>
    );
  }

  renderFilePreview() {
    let { file } = this.props;
    if (file.get("pdf_file_url")) {
      return (
        <object
          height="100%"
          width="100%"
          data={file.get("pdf_file_url")}
          type="application/pdf"
          className="pdf-file-preview"
        >
          <DocumentSvg />
        </object>
      );
    } else if (FileUtils.isPDF(file)) {
      return (
        <object
          height="100%"
          width="100%"
          data={file.get("openable_signed_url")}
          type="application/pdf"
          className="pdf-file-preview"
        >
          <DocumentSvg />
        </object>
      );
    } else if (FileUtils.isPreviewAvailable(file)) {
      return (
        <div className="image-preview-container">
          <ResponsiveImg file={file} />
        </div>
      );
    } else {
      return (
        <div className="image-preview-container">
          <DocumentSvg />
        </div>
      );
    }
  }

  handleKeyDown(e: any) {
    if (KeyUtils.isEscape(e)) {
      this.props.onClose();
    } else if (KeyUtils.isRightArrow(e)) {
      this.navNextFile();
    } else if (KeyUtils.isLeftArrow(e)) {
      this.navPrevFile();
    }
  }

  navNextFile() {
    if (this.canNavNext) {
      this.props.navFile(this.props.files.get(this.curFileIndex + 1));
    }
  }

  navPrevFile() {
    if (this.canNavPrev) {
      this.props.navFile(this.props.files.get(this.curFileIndex - 1));
    }
  }

  componentWillMount() {
    window.document.addEventListener("keydown", this.handleKeyDown);
    this.open();
  }

  componentWillUnmount() {
    window.document.removeEventListener("keydown", this.handleKeyDown);
    this.close();
  }

  open() {
    let body = document.querySelector("body");
    if (body) {
      body.className = body.className + " modal-open";
    }
  }

  close() {
    let body = document.querySelector("body");
    if (body) {
      body.className = body.className.replace(/ modal-open/g, "");
    }
  }

  private get canNavNext() {
    return this.curFileIndex + 1 < this.props.files.size;
  }

  private get canNavPrev() {
    return this.curFileIndex !== 0;
  }

  private get curFileIndex() {
    return this.props.files.findIndex((file: ImmutableMap) => file.get("id") === this.props.file.get("id"));
  }
}
