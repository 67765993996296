import React from "react";

/* tslint:disable:max-line-length */
export class ShareSVG extends React.Component<any> {
  render() {
    return (
      <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 426.667 426.667" className="share-svg">
        <path d="M341.333 256c-25.6 0-48.341 11.541-64 29.461l-108.629-54.293c1.259-5.739 1.963-11.691 1.963-17.835s-.704-12.075-1.963-17.856l108.629-54.293c15.659 17.92 38.4 29.483 64 29.483 47.04 0 85.333-38.293 85.333-85.333C426.667 38.272 388.373 0 341.333 0S256 38.272 256 85.333c0 6.123.704 12.075 1.963 17.835l-108.629 54.293c-15.659-17.899-38.4-29.461-64-29.461C38.293 128 0 166.272 0 213.333c0 47.04 38.293 85.333 85.333 85.333 25.6 0 48.341-11.584 64-29.504l108.629 54.315c-1.259 5.781-1.963 11.733-1.963 17.856 0 47.04 38.293 85.333 85.333 85.333s85.333-38.293 85.333-85.333c.002-47.061-38.292-85.333-85.332-85.333zm0-213.333C364.885 42.667 384 61.76 384 85.333 384 108.885 364.885 128 341.333 128s-42.667-19.115-42.667-42.667c.001-23.573 19.115-42.666 42.667-42.666zM85.333 256c-23.552 0-42.667-19.115-42.667-42.667 0-23.573 19.115-42.667 42.667-42.667S128 189.76 128 213.333C128 236.885 108.885 256 85.333 256zm256 128c-23.552 0-42.667-19.115-42.667-42.667 0-23.573 19.115-42.667 42.667-42.667S384 317.76 384 341.333C384 364.885 364.885 384 341.333 384z" />
      </svg>
    );
  }
}
/* tslint:enable:max-line-length */

export default ShareSVG;
