import _ from "lodash";
import React from "react";

export function pd(func: (e: React.SyntheticEvent<any>) => void) {
  // Returns a function that when called, expects an event, preventDefault()'s then call func
  return _.wrap(func, (f, e: React.SyntheticEvent<any>) => {
    e.preventDefault();

    if (!f) {
      throw Error("Function is not defined. Calling `pd(someFunc)`, but someFunc is not defined.");
    }

    f(e);
  });
}

export function sp(func: (e: React.SyntheticEvent<any>) => void) {
  // Returns a function that when called, expects an event, stopPropagation()'s then call func
  return _.wrap(func, (f, e: React.SyntheticEvent<any>) => {
    e.stopPropagation();

    if (!f) {
      throw Error("Function is not defined. Calling `sp(someFunc)`, but someFunc is not defined.");
    }

    f(e);
  });
}
