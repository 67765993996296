import alt from "../../alt";

import NotificationSettingsApi from "../apis/notification-settings-api";
import messageActions from "../../common/actions/message-actions";

class NotificationSettingsActions {
  constructor() {
    this.generateActions("fetchCurrent", "fetchCurrentDone", "fetchCurrentFail", "updateDone", "updateFail");
  }

  update(data, { cid, showSuccess = true } = {}) {
    NotificationSettingsApi.update(data)
      .then(res => {
        this.updateDone({ data: res.data, cid });

        if (showSuccess) {
          messageActions.storeSuccess({ body: "Settings saved" });
        }
      })
      .catch(res => {
        this.updateFail({ data: res, cid });
      });

    return { cid };
  }
}

export default alt.createActions(NotificationSettingsActions);
