import React, { useMemo, useState, useCallback } from "react";

import { createEditor, Descendant } from "slate";
import { Editable, ReactEditor, RenderElementProps, RenderLeafProps, Slate, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiTextAlign } from "@elastic/eui";
import { Toolbar } from "./toolbar";
import { withInlines } from "./utils";
import { colors } from "@pm-frontend/styles";

interface PmRichTextEditorProps {
  initialValue: Descendant[];
  onChange?: (value: Descendant[]) => void;
  readOnly?: boolean;
  tabIndex?: number;
  dataTestId?: string;
}

const Element: React.FC<RenderElementProps> = ({ attributes, children, element }) => {
  const textAlign = element.align;
  switch (element.type) {
    case "heading-one":
      return (
        <EuiTextAlign textAlign={textAlign}>
          <h1 {...attributes}>{children}</h1>
        </EuiTextAlign>
      );
    case "heading-two":
      return (
        <EuiTextAlign textAlign={textAlign}>
          <h2 {...attributes}>{children}</h2>
        </EuiTextAlign>
      );
    case "ordered-list":
      return (
        <EuiTextAlign textAlign={textAlign}>
          <ol style={{ listStyle: "decimal", listStylePosition: "inside" }} {...attributes}>
            {children}
          </ol>
        </EuiTextAlign>
      );
    case "unordered-list":
      return (
        <EuiTextAlign textAlign={textAlign}>
          <ul style={{ listStyle: "disc", listStylePosition: "inside" }} {...attributes}>
            {children}
          </ul>
        </EuiTextAlign>
      );
    case "quote":
      return (
        <EuiTextAlign textAlign={textAlign}>
          <div
            style={{
              borderLeft: "2px solid #ddd",
              paddingLeft: "10px",
              color: colors.neutrals.gray700,
              fontStyle: "italic",
            }}
            {...attributes}
          >
            {children}
          </div>
        </EuiTextAlign>
      );
    case "paragraph":
      return (
        <EuiTextAlign textAlign={textAlign}>
          <p {...attributes}>{children}</p>
        </EuiTextAlign>
      );
    case "list-item":
      return (
        <EuiTextAlign textAlign={textAlign}>
          <li {...attributes}>{children}</li>
        </EuiTextAlign>
      );
    case "link":
      return (
        <EuiLink href={element.url} {...attributes}>
          {children}
        </EuiLink>
      );
    default:
      return <></>;
  }
};

const Leaf: React.FC<RenderLeafProps> = ({ attributes, children, leaf }) => {
  let formattedChildren = children;
  if (leaf.bold) {
    formattedChildren = <span style={{ fontWeight: "bold" }}>{formattedChildren}</span>;
  }

  if (leaf.code) {
    formattedChildren = (
      <pre>
        <code>{formattedChildren}</code>
      </pre>
    );
  }

  if (leaf.italic) {
    formattedChildren = <span style={{ fontStyle: "italic" }}>{formattedChildren}</span>;
  }

  if (leaf.underline) {
    formattedChildren = <span style={{ textDecoration: "underline" }}>{formattedChildren}</span>;
  }

  return <span {...attributes}>{formattedChildren}</span>;
};

const PmRichTextEditor = ({ initialValue, readOnly, onChange, tabIndex, dataTestId }: PmRichTextEditorProps) => {
  const [value, setValue] = useState<Descendant[]>(initialValue);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const editor = useMemo(() => withInlines(withHistory(withReact(createEditor() as ReactEditor))), []);
  const onChangeWrapper = (content: Descendant[]) => {
    setValue(content);
    if (onChange) {
      onChange(content);
    }
  };

  return (
    <Slate editor={editor} value={value} onChange={onChangeWrapper}>
      <EuiFlexGroup direction="column" alignItems="stretch" gutterSize="s">
        {!readOnly && (
          <EuiFlexItem grow={false}>
            <Toolbar />
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={true}>
          <Editable
            readOnly={readOnly}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            spellCheck={true}
            aria-label="rich-text-box"
            style={
              readOnly
                ? {}
                : {
                    borderRadius: "6px",
                    border: `1px solid ${colors.neutrals.gray200}`,
                    background: colors.brand.white,
                    padding: "8px",
                    minHeight: "20vh",
                  }
            }
            tabIndex={tabIndex}
            {...(dataTestId ? { "data-testid": dataTestId } : {})}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </Slate>
  );
};

export { PmRichTextEditor };
