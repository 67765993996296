import createReactClass from "create-react-class";
import React from "react";

/* tslint:disable:max-line-length */
let Contacts = createReactClass({
  render() {
    return (
      <svg xmlns="https://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" viewBox="0 0 32 34">
        <path d="M27.2 12h-.28C27.604 10.822 28 9.46 28 8c0-4.418-3.582-8-8-8s-8 3.582-8 8c-4.418 0-8 3.582-8 8 0 1.46.397 2.822 1.08 4H4.8C1.95 21.277 0 26.01 0 28.926V32c0 .7 1.108 2 1.938 2H22.25c.83 0 1.75-1.3 1.75-2v-3.074c0-1.017-.25-2.007-.68-2.926h6.93c.83 0 1.75-1.3 1.75-2v-3.074c0-2.916-1.95-7.65-4.8-8.926zM20 4c2.206 0 4 1.794 4 4s-1.794 4-4 4c-.422 0-.822-.084-1.204-.205-.652-1.052-1.54-1.94-2.59-2.59C16.083 8.82 16 8.42 16 8c0-2.206 1.794-4 4-4zm-8 8c2.21 0 4 1.79 4 4s-1.79 4-4 4c-1.977 0-3.61-1.438-3.932-3.323C8.026 16.457 8 16.232 8 16c0-2.21 1.79-4 4-4zm7 18H4v-1.074c0-1.085.713-4.214 1.797-4.926H17.203c1.084.712 1.797 3.84 1.797 4.926V30zm9-8h-9.08c.683-1.178 1.08-4.54 1.08-6h6.203c1.084.712 1.797 3.84 1.797 4.926V22z" />
      </svg>
    );
  },
});
/* tslint:enable:max-line-length */

export default Contacts;
