import * as C from "../../constants";
import PhoneUtils from "../utils/phone-utils";

import React from "react";

interface PhoneAnchorProps {
  emptyText?: string;
  number?: string;
}

class PhoneAnchor extends React.Component<PhoneAnchorProps> {
  public static defaultProps: Partial<PhoneAnchorProps> = {
    emptyText: C.emptyPlaceholder
  };

  render() {
    if (this.props.number && PhoneUtils.isValid(this.props.number)) {
      return <a href={`tel:${PhoneUtils.format(this.props.number)}`}>{this.props.children}</a>;
    }

    return <span>{this.props.emptyText}</span>;
  }
}

export default PhoneAnchor;
