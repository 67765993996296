import qs from "qs";

export const normalizePath = (path: string) => {
  return `/:multitenantId/:orgType/:org/${path}`;
};

const getOrgId = () => {
  return window.location.pathname.split("/")[3];
};

export const getOrgType = () => {
  return window.location.pathname.split("/")[2];
};

const getMultitenant = () => {
  return window.location.pathname.split("/")[1];
};

export const LinkHelper = {
  normalize(url: string) {
    let mTenant = getMultitenant();
    let orgId = getOrgId();
    let orgType = getOrgType();

    let updatedUrl = `/${mTenant}/${orgType}/${orgId}/${url}`;
    return updatedUrl.replace("//", "/");
  },
};

export const parseQuery = (location: any) => {
  let search = location.search;
  if (search && search[0] === "?") {
    search = search.slice(1);
  }
  return qs.parse(search);
};
