import { Textarea } from "@chakra-ui/react";
import React from "react";

export const TextCondition: React.FC<any> = ({ error, onChange, value }) => {
  return (
    <Textarea
      isInvalid={!!error}
      data-test={"workflows.form.select.block_operator.condition_type.text"}
      onChange={onChange}
      value={value}
      resize={"none"}
    />
  );
};
