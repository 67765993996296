var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import qs from "qs";
export const parseQueryParams = (params) => {
  return qs.parse(params[0] === "?" ? params.slice(1) : params);
};
export const getQueryParam = (params, key) => {
  return parseQueryParams(params)[key];
};
export const getQueryParams = (url) => {
  let params = url.substring(url.indexOf("?"));
  let queryParams = params ? parseQueryParams(params) : {};
  queryParams = !queryParams.hasOwnProperty("offset") ? __spreadProps(__spreadValues({}, queryParams), { offset: 0 }) : queryParams;
  const parameters = Object.keys(queryParams);
  let urlParameters = parameters.map((key) => `${key}=${queryParams[key]}`).join("&");
  return urlParameters;
};
