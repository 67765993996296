import I from "immutable";
import React from "react";
import { Response } from "../../utils/base-api";

export interface ContainerState {
  errors: string[];
  loading: boolean;
  result?: Response;
}

export interface PassThroughProps {
  errors: string[];
  loading: boolean;
  result?: Response;
}

export interface ContainerComponent extends React.Component<any, any> {
  state: {
    errors: string[];
    loading: boolean;
    result?: Response;
  };
}

export function defaultContainerState(): ContainerState {
  return {
    errors: [] as string[],
    loading: true
  };
}

export function defaultNotLoadingContainerState() {
  return {
    errors: [] as string[],
    loading: false
  };
}

export function defaultSaveContainerState() {
  return {
    errors: [] as string[],
    loading: false
  };
}

export function startLoading() {
  this.setState({ loading: true, errors: [] });
}

export function loadingFailed(result: Response) {
  this.setState({ errors: result.messages, loading: false });
}

export function loadingSucceeded(result: Response) {
  this.setState({
    data: I.fromJS(result.data),
    loading: false,
    result
  });
}

export function loadingSucceededPaginated(result: Response) {
  this.setState({
    data: I.fromJS(result.data.results),
    loading: false,
    result
  });
}

export function containerStateForChildren(component: ContainerComponent): ContainerState {
  return {
    errors: component.state.errors,
    loading: component.state.loading,
    result: component.state.result
  };
}
