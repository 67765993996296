import React, { FC, useState, useEffect, useRef } from "react";
import { Box, Text, Flex, Spacer, Circle, UnorderedList, ListItem, Button } from "@chakra-ui/react";
import { postSummaryData } from "./comment-services";
import { SmallLoader } from "@pm-assets/js/loaders";
import { isEqual } from "lodash";
import { track, ViewChatSummaryClicked } from "@pm-app/utils/analytics";
import I from "immutable";

type Comment = I.Map<string, any>;

interface Props {
  comments: Comment[];
  meld_id: number;
}

const SummaryComponent: FC<Props> = ({ comments, meld_id }) => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [viewClicked, setViewClicked] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [rateLimitPassed, setRateLimitPassed] = useState<boolean>(false);

  const prevCommentsRef = useRef<Comment[]>([]);

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleViewSummary = () => {
    track(ViewChatSummaryClicked());
    setViewClicked(true);
  };

  useEffect(() => {
    postSummaryData(comments, meld_id, true)
      .then((response) => {
        if (response.message === "No cache available.") {
          setSummaryData([]);
        } else {
          const regex = /(?<=\.)\s/;
          const parsedSummaryData = response.generated_text.split(regex);
          setSummaryData(parsedSummaryData);
        }
      })
      .catch(() => {
        setSummaryData([]);
      });
  }, []);

  useEffect(() => {
    if (!viewClicked || isEqual(prevCommentsRef.current, comments)) {
      return;
    }

    prevCommentsRef.current = comments;
    setIsLoading(true);

    postSummaryData(comments, meld_id, false)
      .then((response) => {
        const regex = /(?<=\.)\s/;
        const parsedSummaryData = response.generated_text.split(regex);
        setSummaryData(parsedSummaryData);
        setIsLoading(false);
      })
      .catch((e) => {
        setError(e.message);
        if (e.message === "Request failed with status code 429") {
          setRateLimitPassed(true);
        }
        setIsLoading(false);
      });
  }, [viewClicked]);

  if (!isVisible) {
    return null;
  }

  return (
    <Box
      minH="139px"
      padding="8px 24px"
      borderRadius="8px"
      background="linear-gradient(0deg, #F7F9FA, #F7F9FA), linear-gradient(0deg, #DEE2E5, #DEE2E5)"
      border="1px solid #DEE2E5"
      boxShadow="0px 1px 10px rgba(17, 117, 204, 0.02)"
    >
      <Flex mt={-2} align="center">
        <Text data-testid="summary-title" fontWeight="700" style={{ color: "black", fontSize: "16px" }}>
          AI Summary of Chat:
        </Text>
        <Spacer />
        <Box
          data-testid="summary-close"
          onClick={handleClose}
          cursor="pointer"
          fontWeight="700"
          fontSize="16px"
          style={{ color: "black" }}
        >
          X
        </Box>
      </Flex>

      {summaryData.length > 0 || viewClicked ? (
        isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="80px">
            <SmallLoader data-testid="loader" />
          </Box>
        ) : error ? (
          <Text data-testid="summary-error" style={{ color: "red" }} mt={4}>
            {rateLimitPassed
              ? "Your organization has used the maximum summary attempts for this Meld."
              : "Error generating summary. Please refresh the page or contact support."}
          </Text>
        ) : (
          <UnorderedList mt={-2} paddingLeft="10px">
            {summaryData.map((data, index) => (
              <ListItem data-testid={`summary-item-${index}`} key={index} style={{ color: "black" }}>
                <Circle size="4px" marginRight="8px" color="black" />
                {data}
              </ListItem>
            ))}
          </UnorderedList>
        )
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80px">
          <Button onClick={handleViewSummary}>Summarize Chat</Button>
        </Box>
      )}
    </Box>
  );
};

export default SummaryComponent;
