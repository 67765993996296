import { AuthUtils } from "../../utils/auth-utils";
import ErrorRenderingUtils from "../../utils/error-rendering-utils";
import I from "immutable";
import ModelUpdateUtils from "../../utils/model-update-utils";
import PropTypes from "prop-types";
import React from "react";
import RegistrationApi from "../../management/apis/registration-api";
import UrlUtils from "../../utils/url-utils";
import Button, { ButtonTypes } from "../../../../app/components/buttons/Button";

let Step1BaseMixin = {
  mixins: [ErrorRenderingUtils, ModelUpdateUtils],

  propTypes: {
    stepForward: PropTypes.func.isRequired
  },

  getDefaultProps() {
    return {
      constraints: {
        first_name: {
          presence: {
            message: "Please enter your first name",
            allowEmpty: false
          }
        },
        last_name: {
          presence: {
            message: "Please enter your last name",
            allowEmpty: false
          }
        },
        name: {
          presence: {
            message: "Please provide a company name",
            allowEmpty: false
          },
          length: {
            minimum: 2,
            tooShort: "Company name must be at least two characters"
          }
        },
        phone: {
          phoneNumber: {
            message: "Phone number entered is not a valid number"
          }
        },
        "address.postcode": {
          presence: {
            message: "Please provide a postal code",
            allowEmpty: false
          }
        }
      }
    };
  },

  getInitialState() {
    return {
      submitting: false,
      data: this.props.data || I.fromJS({}),
      errors: []
    };
  },

  render() {
    let nextStepButtonText = this.state.submitting ? "Loading..." : "Next Step";

    return (
      <div className="form-card-container">
        <p className="accent sub-header">Company information</p>

        {this.props.children}

        <form>
          <input
            type="text"
            id="first-name"
            placeholder="First Name"
            onChange={this.onChangeSet("first_name")}
            value={this.state.data.get("first_name")}
            ref="firstName"
            tabIndex="1"
            maxLength="30"
          />
          <input
            type="text"
            id="last-name"
            placeholder="Last Name"
            onChange={this.onChangeSet("last_name")}
            value={this.state.data.get("last_name")}
            tabIndex="2"
            maxLength="30"
          />
          <input
            type="text"
            id="company-name"
            placeholder="Company Name"
            onChange={this.onChangeSet("name")}
            value={this.state.data.get("name")}
            tabIndex="3"
            maxLength="100"
          />
          <input
            type="text"
            id="phone"
            placeholder="Company Phone"
            onChange={this.onChangeSet("phone")}
            value={this.state.data.get("phone")}
            tabIndex="4"
            maxLength="128"
          />
          <input
            type="text"
            id="company-postcode"
            placeholder="Postal code"
            onChange={this.onChangeSetIn(["address", "postcode"])}
            value={this.state.data.getIn(["address", "postcode"])}
            tabIndex="5"
            maxLength="15"
          />
          {this.renderErrors()}
          <Button
            buttonType={ButtonTypes.Primary}
            className="step-forward-btn step1-btn"
            onClick={this.prepareSubmit(this.stepForward)}
            disabled={this.state.submitting}
          >
            {nextStepButtonText}
          </Button>
        </form>
        {this.renderLoginQuestion()}
      </div>
    );
  },

  renderLoginQuestion() {
    if (!AuthUtils.isAuthenticated()) {
      const next = this.props.next;
      return (
        <p className="accent account-reminder">
          Already have an account?
          <a href={UrlUtils.makeUrl("/login/", { next }, { normalize: false })}> Login</a>
        </p>
      );
    }
  },

  getExtraErrors() {
    return this.state.errors;
  },

  stepForward(e) {
    e.preventDefault();

    if (this.isValid()) {
      this.setState({ submitting: true });

      let data = {
        name: this.state.data.get("name"),
        postcode: this.state.data.getIn(["address", "postcode"]),
        category: this.category
      };

      RegistrationApi.resolveLocations(data)
        .then(result => {
          this.props.stepForward(this.state.data, I.fromJS(result.data));
        })
        .catch(result => {
          this.setState({
            errors: result.messages,
            submitting: false
          });
        });
    }
  },

  componentDidMount() {
    this.refs.firstName.focus();
  }
};

export default Step1BaseMixin;
