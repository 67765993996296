import React from "react";
import requestedSegmentAPI from "../../../availability/apis/requested-segment-api";
import {
  containerStateForChildren,
  defaultSaveContainerState,
  loadingFailed,
  loadingSucceeded,
  startLoading
} from "../../../common/utils/container-utils";

export default function withScheduleAppointmentRequest(Comp) {
  return class withSaveAppointmentRequestContainer extends React.Component {
    constructor(props) {
      super(props);
      this.state = defaultSaveContainerState();

      this.handleSchedule = this.handleSchedule.bind(this);
    }

    render() {
      return <Comp onSchedule={this.handleSchedule} {...containerStateForChildren(this)} {...this.props} />;
    }

    handleSchedule(segmentId) {
      startLoading.call(this);
      requestedSegmentAPI
        .book(segmentId)
        .then(res => {
          loadingSucceeded.call(this, res);
          this.props.onScheduled();
        })
        .catch(loadingFailed.bind(this));
    }
  };
}
