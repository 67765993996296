import _ from "lodash";
import qs from "qs";
import { Location } from "history";

export function parseQuery(location: any) {
  let search = location.search;
  if (search && search[0] === "?") {
    search = search.slice(1);
  }
  return qs.parse(search);
}

export function stringify(params: any) {
  return qs.stringify(params, { arrayFormat: "brackets" });
}

export function arePropsLocationsEqual(props: { location: Location }, otherProps: { location: Location }) {
  let queryParams = parseQuery(otherProps.location);
  return !_.isEqual(queryParams, parseQuery(props.location));
}
