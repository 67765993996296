import React from "react";
import classnames from "classnames";
import SVGInline from "react-svg-inline";

function ButtonIcon(props: { svg: any; className?: string }) {
  const classes = classnames("pm-button-icon", props.className);
  return <SVGInline svg={props.svg} className={classes} />;
}

export default ButtonIcon;
