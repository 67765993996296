import cardApi from "../apis/card-api";
import messageActions from "../../common/actions/message-actions";
import React from "react";
import { withCreditCardMaker } from "../mixins/subscription-credit-card";
import CreditCardModal from "../components/credit-card-modal";

class AddCreditCardModal extends React.Component {
  constructor(options) {
    super(options);

    this.state = {
      saving: false,
      dirty: false,
      errors: [],
      data: {}
    };

    this.handleDataChanged = this.handleDataChanged.bind(this);
    this.trySave = this.trySave.bind(this);
    this.tokenCreateFail = this.tokenCreateFail.bind(this);
    this.updateCard = this.updateCard.bind(this);
  }

  render() {
    return (
      <CreditCardModal
        saving={this.state.saving}
        data={this.state.data}
        dirty={this.state.dirty}
        onClose={this.props.onClose}
        trySave={this.trySave}
        errors={this.state.errors}
        onDataChanged={this.handleDataChanged}
      />
    );
  }

  updateCard(token) {
    cardApi
      .updateCard({ token })
      .then(res => {
        messageActions.storeSuccess({ body: "Card updated" });
        this.props.onCardUpdated();
      })
      .catch(res => {
        this.setState({ saving: false, errors: res.messages });
      });
  }

  trySave() {
    this.setState({ saving: true });

    this.props.createToken(this.state.data, this.updateCard, this.tokenCreateFail);
  }

  tokenCreateFail(error) {
    this.setState({ errors: [error], saving: false });
  }

  handleDataChanged(newData) {
    let data = Object.assign({}, this.state.data, newData);
    this.setState({ data, dirty: true });
  }
}

export default withCreditCardMaker(AddCreditCardModal);
