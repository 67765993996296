import agentActions from "../actions/agent-actions";
import AgentApi from "../apis/agent-api";

let AgentSource = {
  fetchAll() {
    return {
      remote(state, { cid } = {}) {
        return AgentApi.fetchAll();
      },

      shouldFetch(state, force) {
        return true;
      },

      success: agentActions.fetchAllDone,
      error: agentActions.fetchAllFail
    };
  }
};

export default AgentSource;
