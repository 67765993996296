import React from "react";

function DetailFooter(props: { children: any }) {
  return (
    <div className="row">
      <div className="columns small-12">
        <div className="footer">
          <div className="footer-content">{props.children}</div>
        </div>
      </div>
    </div>
  );
}

export default DetailFooter;
