import useSWR from "swr";

import { APIPrefix } from "../api";
import { axios } from "@pm-shared/axios";
import { HistoricalPerformanceMetrics } from "@pm-shared/dashboard-metrics";

// Fetch Historical Performance Metrics

interface FetchHistoricalPerformanceMetricsResponse {
  count: number;
  next?: string;
  previous?: string;
  results: HistoricalPerformanceMetrics;
}

export const fetchHistoricalPerformanceMetrics = () => {
  const url = `${APIPrefix}/metrics/historical-performance/`;

  return {
    response: useSWR<FetchHistoricalPerformanceMetricsResponse>(
      url,
      () => axios.get(url).then((response) => response.data),
      { revalidateOnFocus: false }
    ),
    url,
  };
};
