/** Initializes globally used dependencies such as polyfill and jquery */

import "@babel/polyfill";
import datasetPolyfill from "element-dataset";
import { ErrorHandler } from "../../app/utils/ErrorHandler";

// initialize jquery
import $ from "jquery";
window.jQuery = $;
window.$ = $;

import $cookie from "jquery.cookie"; //eslint-disable-line no-unused-vars
import validate from "validate.js";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import "@webcomponents/url"; //eslint-disable-line no-unused-vars

// Used for LaunchDarkly - IE11 and Edge don't support server side events
import "event-source-polyfill"; //eslint-disable-line no-unused-vars

import { polyfill } from "es6-promise";
polyfill();
import "nodelist-foreach-polyfill";
import "./polyfill/set";

datasetPolyfill();
moment.locale("en");
momentLocalizer();

validate.moment = moment;
import validators from "./common/validators"; //eslint-disable-line no-unused-vars

// TODO remove this
$.removeCookie("csrftoken", { path: "/" });
if (window.PM && window.PM.user) {
  $.removeCookie("csrftoken", {
    path: `/${window.PM.user.multitenantId}/${window.PM.user.orgType}/${window.PM.user.activeOrgId}`,
  });
}

$.ajaxSetup({
  headers: {
    "X-CSRFToken": window.PM.csrf_token,
  },
});

// If the browser doesn't support localStorage, ignore any errors from using it
if (typeof window.localStorage === "object") {
  try {
    window.localStorage.setItem("localStorageTest", 1);
    window.localStorage.removeItem("localStorageTest");
  } catch (e) {
    Storage.prototype._setItem = Storage.prototype.setItem;
    Storage.prototype.setItem = function () {};
  }
}

ErrorHandler.initializeHandler();
