var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const Pay = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement("path", { d: "M20 40a20 20 0 1120-20 20 20 0 01-20 20zm0-38a18 18 0 1018 18A18 18 0 0020 2z" }), /* @__PURE__ */ React.createElement("path", { d: "M27.57 24.49a4.78 4.78 0 01-1.7 3.76 8.81 8.81 0 01-4.77 1.87V34h-2.16v-3.73a21.51 21.51 0 01-3.63-.3 10.88 10.88 0 01-2.88-.81v-2.61a16.28 16.28 0 003.2 1 15.85 15.85 0 003.31.39v-7.35a11.48 11.48 0 01-4.81-2.52 5.12 5.12 0 01-1.39-3.72 4.48 4.48 0 011.7-3.6 8.34 8.34 0 014.5-1.7V6h2.16v3a17.1 17.1 0 015.9 1.25l-.87 2.19a17.27 17.27 0 00-5.07-1.17v7.27A18.6 18.6 0 0125 20.17 5.54 5.54 0 0127 22a4.71 4.71 0 01.57 2.49zm-12-10.17a2.75 2.75 0 00.76 2 7.26 7.26 0 002.61 1.46v-6.44a4.86 4.86 0 00-2.51 1 2.53 2.53 0 00-.86 1.98zm9.16 10.39a2.64 2.64 0 00-.73-1.95 8 8 0 00-2.88-1.48v6.51q3.61-.49 3.61-3.08z" }));
