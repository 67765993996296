var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiPanel } from "@elastic/eui";
import { BorderColor, colors } from "@pm-frontend/styles";
const PmCard = ({
  panelStyles,
  panelClassName,
  padding = "24px",
  backgroundColor = colors.brand.white,
  "data-testid": dataTestId,
  grow = true,
  contents,
  children
}) => {
  let cardContents;
  if (children) {
    cardContents = children;
  } else if (Array.isArray(contents)) {
    cardContents = /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", style: { gap: "16px" } }, contents.filter((item) => item !== null).map((content, index) => /* @__PURE__ */ React.createElement(React.Fragment, { key: index }, index > 0 && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiHorizontalRule,
      {
        margin: "none",
        style: {
          borderColor: BorderColor,
          boxShadow: `0px -1px 0px 0px ${BorderColor} inset`
        }
      }
    )), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, content))));
  } else {
    cardContents = contents;
  }
  return /* @__PURE__ */ React.createElement(
    EuiPanel,
    __spreadProps(__spreadValues({}, dataTestId ? { "data-testid": dataTestId } : {}), {
      style: __spreadValues({
        borderRadius: "8px",
        border: `1px solid ${BorderColor}`,
        background: backgroundColor,
        padding,
        boxShadow: "none"
      }, panelStyles || {}),
      className: panelClassName,
      grow
    }),
    cardContents
  );
};
export { PmCard };
