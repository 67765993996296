import _ from "lodash";
import AltContainer from "alt-container";
import agentActions from "./actions/agent-detail-actions";
import DocumentTitle from "react-document-title";
import ErrorRenderingUtils from "../utils/error-rendering-utils";
import I from "immutable";
import ModelUpdateUtils from "../utils/model-update-utils";
import createReactClass from "create-react-class";
import React from "react";
import { LargeLoader } from "../loaders";
import ResetChangesButtons from "./components/reset-changes-buttons";
import ManagementAgentStore from "../management/stores/management-agent-store";
import { Departments } from "../constants";
import ManagementApi from "../management/apis/management-api";
import { ErrorHandler } from "../../../app/utils/ErrorHandler";

const DepartmentTitleMapping = {
  ACCOUNTING: {
    Accountant: "ACCOUNTANT",
    Supervisor: "SUPERVISOR",
    Other: "OTHER",
  },
  OPERATIONS: {
    "Property Manager": "PROPERTY_MANAGER",
    "Administrative Assistant": "ADMINISTRATIVE_ASSISTANT",
    Supervisor: "SUPERVISOR",
    Other: "OTHER",
  },
  MAINTENANCE: {
    Coordinator: "COORDINATOR",
    Technician: "TECHNICIAN",
    Supervisor: "SUPERVISOR",
    "Project Coordinator": "PROJECT_COORDINATOR",
    Inspector: "INSPECTOR",
    Other: "OTHER",
  },
  LEASING_MARKETING: {
    "Leasing Agent": "LEASING_AGENT",
    Other: "OTHER",
  },
  LEADERSHIP: {
    "Broker/Owner": "BROKER_OWNER",
    Executive: "EXECUTIVE",
    Other: "OTHER",
  },
};

let AccountAgentSettingsTab = createReactClass({
  mixins: [ErrorRenderingUtils, ModelUpdateUtils],

  loadingStore: ManagementAgentStore,

  getInitialState() {
    let data = this.props.agentSettings || I.fromJS({});
    let cid = _.uniqueId("AccountSettingsAgentTab_");

    return {
      data: data,
      update_all: false,
      dirty: false,
      cid,
      constraints: {
        title: {
          presence: {
            message: "Title is required",
            allowEmpty: false,
          },
        },
      },
    };
  },

  onDepartmentChange(event) {
    const department = event.target.value;

    // Update the department and reset the title
    if (department === "OTHER") {
      this.setState((prevState) => ({
        data: prevState.data.set("department", department).set("title", "OTHER"),
        dirty: true,
      }));
    } else {
      this.setState((prevState) => ({
        data: prevState.data.set("department", department).set("title", ""),
        dirty: true,
      }));
    }
  },

  onUpdateToggled(event) {
    const isChecked = event.target.value;
    this.setState(() => ({
      update_all: !!isChecked,
      dirty: true,
    }));
  },

  render() {
    let titleOptions = (department) => {
      if (DepartmentTitleMapping[department]) {
        return Object.entries(DepartmentTitleMapping[department]).map(([title, value]) => {
          return (
            <option key={title} value={value}>
              {title}
            </option>
          );
        });
      } else {
        return null;
      }
    };

    return (
      <div>
        <form onSubmit={this.onSubmit} className="update-account" name="update-account-form">
          <div className="row">
            {this.renderErrors()}

            <div className="columns medium-12 large-4">
              <div className="label-container">
                <label>Department</label>
              </div>
              <select value={this.state.data.get("department")} onChange={this.onDepartmentChange}>
                <option value="" disabled="disabled">
                  Select your department
                </option>
                <option value="MAINTENANCE">{Departments.MAINTENANCE}</option>
                <option value="ACCOUNTING">{Departments.ACCOUNTING}</option>
                <option value="OPERATIONS">{Departments.OPERATIONS}</option>
                <option value="LEADERSHIP">{Departments.LEADERSHIP}</option>
                <option value="LEASING_MARKETING">{Departments.LEASING_MARKETING}</option>
                <option value="OTHER">{Departments.OTHER}</option>
              </select>
            </div>

            <>
              {this.state.data.get("department") && this.state.data.get("department") !== "OTHER" && (
                <div className="columns medium-12 large-4">
                  <div className="label-container">
                    <label>Title</label>
                  </div>
                  <select
                    name="title"
                    value={this.state.data.get("title")}
                    onChange={this.markDirty(this.onChangeSet("title"))}
                  >
                    <option value="" disabled="disabled">
                      Select your title
                    </option>
                    {titleOptions(this.state.data.get("department"))}
                  </select>
                </div>
              )}
            </>
            <>
              {this.state.dirty &&
                this.state.data.get("title") &&
                this.state.data.get("title") !== "OTHER" &&
                this.state.managements &&
                this.state.managements.toJS().length > 1 && (
                  <div className="columns medium-12 large-4">
                    <div className="label-container">
                      <label>Is this your title in all markets?</label>
                    </div>
                    <div className="pm-selection-item-text">
                      <input
                        type="checkbox"
                        id="updateAll"
                        value={this.state.update_all}
                        onChange={this.onUpdateToggled}
                        style={{ transform: "scale(1.5)", marginLeft: "4px" }}
                      />
                      <text className="label-container" style={{ fontWeight: "normal" }}>
                        Set for all markets
                      </text>
                    </div>
                  </div>
                )}
            </>
            <div>
              <div />
            </div>
          </div>

          <div className="footer">
            <div className="footer-content">{this.renderActionButtons()}</div>
          </div>
        </form>
      </div>
    );
  },

  renderActionButtons() {
    if (this.state.dirty) {
      return (
        <ResetChangesButtons
          resetClickFn={this.onResetChanges(this.props.agentSettings)}
          saveClickFn={this.prepareSubmit(this.updateSettings)}
        />
      );
    }
  },

  updateSettings(e) {
    e.preventDefault();

    if (this.isValid()) {
      let data = this.state.data.toJS();
      data.user = data.user.id;
      data.update_all = this.state.update_all;
      agentActions.update(data, { cid: this.state.cid });
      this.setState({ dirty: false, update_all: false });
    }
  },

  componentWillMount() {
    ManagementApi.fetchAll()
      .then((res) => {
        this.setState({ managements: I.fromJS(res.data) });
      })
      .catch((error) => ErrorHandler.handleAxiosError(error));
  },
});

export default createReactClass({
  render() {
    return (
      <DocumentTitle title="Agent Settings">
        <AltContainer
          stores={{
            agentSettings() {
              return {
                value: ManagementAgentStore.getCurrentAgent(),
                store: ManagementAgentStore,
              };
            },
          }}
          render={function (props) {
            if (ManagementAgentStore.didFail()) {
              return <div>Failed to load user settings</div>;
            } else if (ManagementAgentStore.isLoading()) {
              return <LargeLoader />;
            }

            return <AccountAgentSettingsTab {...props} />;
          }}
        />
      </DocumentTitle>
    );
  },

  componentWillMount() {
    ManagementAgentStore.fetchAll();
  },
});
