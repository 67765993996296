import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

class IntegrationApi extends BaseApi {
  static enableIntegration(slug: string) {
    return this.patch(ApiUrls.integrationEnable(slug));
  }

  static create(data: any, slug: string) {
    return this.post(ApiUrls.integrationList(slug), data);
  }

  static update(integrationId: number, data: any, slug: string) {
    return this.patch(ApiUrls.integrationDetail(slug, integrationId), data);
  }

  static sync(slug: string) {
    return this.patch(ApiUrls.integrationSync(slug));
  }

  static toggleEnabled(slug: string, data: any) {
    return this.patch(ApiUrls.toggleIntegrationEnabled(slug), data);
  }

  static fetchEnabled() {
    return this.get(ApiUrls.integrationsEnabled);
  }
}

export default IntegrationApi;
