import _ from "lodash";
import { parseQuery } from "../common/utils/location-utils";
import SavedFilterUtils from "../common/utils/saved-filter-utils";
import moment from "moment";
import { track, VendorFiltersApplied, VendorFiltersCleared } from "../../../app/utils/analytics";
import { AuthUtils } from "../utils/auth-utils";

export let Helpers = {
  getNonDateFields() {
    let { radioFields = [], checkboxFields = [], selectFields = [], multiselectFields = [], booleanFields = [] } = this;

    return [].concat(radioFields, checkboxFields, selectFields, multiselectFields, booleanFields);
  },

  getDateFields() {
    return this.dateFields || [];
  },

  getNumericRangeFields() {
    return this.numericRangeFields || [];
  },
};

const datetimeOperators = ["__gte", "__lte", "__interval", "__offset_lte", "__offset_gte"];
const numericEqualityOperators = ["__gte", "__lte"];

let DataFilter = {
  getDefaultProps() {
    let filterFields = Helpers.getNonDateFields
      .call(this)
      .filter((category) => category)
      .reduce((prev, curr, index, arr) => {
        prev[curr] = curr;
        return prev;
      }, {});
    let filterCategoryMap = Object.assign({}, filterFields);

    Helpers.getDateFields.call(this).forEach((dateField) => {
      datetimeOperators.forEach((operator) => {
        filterCategoryMap[dateField + operator] = dateField;
        filterFields[dateField + operator] = dateField + operator;
      });
    });

    Helpers.getNumericRangeFields.call(this).forEach((numericField) => {
      numericEqualityOperators.forEach((operator) => {
        filterCategoryMap[numericField + operator] = numericField;
        filterFields[numericField + operator] = numericField + operator;
      });
    });

    return {
      filterFields,
      filterCategoryMap,
    };
  },

  getInitialState(newProps) {
    let props = newProps || this.props;
    let query = this._getFilterConfig(props);

    let filters = Object.keys(props.filterFields).reduce((prev, key) => {
      prev[key] = query[key];
      return prev;
    }, {});
    let filtersOpen = Helpers.getNonDateFields
      .call(this.constructor)
      .filter((field) => field)
      .reduce((prev, key) => {
        prev[key + "Open"] = this.isAnyParamPresent(query, props.filterFields[key]);
        return prev;
      }, {});

    Helpers.getDateFields.call(this.constructor).forEach((dateField) => {
      let fieldsToTest = datetimeOperators.map((operator) => {
        return dateField + operator;
      });
      filtersOpen[dateField + "Open"] = this.isAnyParamPresent(query, fieldsToTest);
    });

    Helpers.getNumericRangeFields.call(this.constructor).forEach((numericRange) => {
      let fieldsToTest = numericEqualityOperators.map((operator) => {
        return numericRange + operator;
      });
      filtersOpen[numericRange + "Open"] = this.isAnyParamPresent(query, fieldsToTest);
    });

    return Object.assign({ filters }, filtersOpen);
  },

  applyChanges(e, pageName) {
    e.preventDefault();
    let filters = Object.assign({}, this.state.filters);
    // Delete any filters where the parent category has been turned off
    Object.keys(filters).forEach((key) => {
      if (!filters[key] || !this.isFilterTypeOpen(this.props.filterCategoryMap[key])) {
        delete filters[key];
      }
    });
    if (AuthUtils.getActiveOrgType() === "v" && pageName) {
      track(
        VendorFiltersApplied({
          pageName,
        })
      );
    }
    this.props.applyChanges(filters);
  },

  clearChanges(e, pageName) {
    e.stopPropagation();

    let newState = { filters: {} };
    Object.keys(this.state)
      .filter((key) => key.endsWith("Open"))
      .forEach((key) => {
        newState[key] = false;
      });
    if (AuthUtils.getActiveOrgType() === "v" && pageName) {
      track(
        VendorFiltersCleared({
          pageName,
        })
      );
    }
    this.setState(newState);
  },

  setFilter(field, value) {
    let filters = this.state.filters;
    let newValue = null;
    if (["created__lte", "scheduled__lte", "completed__lte"].includes(field)) {
      newValue = moment(value).startOf("day").toDate().getTime();
    }
    filters[field] = newValue || value;
    this.setState({ filters });
  },

  getFilterToggleName(filterType) {
    return filterType + "Open";
  },

  toggleFilterType(filterType) {
    /*
          toggles the filter name
          filterName + 'Open'
          is_activeOpen
          statusOpen
          this.props.toggleFilterType.bind(this, 'is_active')
          this.props.toggleFilterType.bind(this, 'status')
        */
    let filterToggleName = this.getFilterToggleName(filterType);
    this.setState({ [filterToggleName]: !this.state[filterToggleName] });
  },

  isFilterTypeOpen(filterType) {
    /*
            this.state is part of the data-filter HOC context
            filterNameOpen is set by ( getFilterToggleName within toggleFilterType )
            returns this.state[filterName + 'Open']
         */
    return this.state[this.getFilterToggleName(filterType)];
  },

  toggleCheckboxFilter(filterKey, value) {
    /*
         Query params for multi-value fields can either be null, a string, or an array of values.
         This function adds or removes a value and converts it to the appropriate type based on the number of
         values now chosen.
         */
    let filters = this.state.filters;
    let isFilterEmpty = !filters[filterKey];
    let filter;
    let current = new Set(filters[filterKey] || []);

    if (isFilterEmpty) {
      filter = [value];
    } else if (current.has(value)) {
      current.delete(value);
      filter = Array.from(current);
    } else {
      filter = Array.from(current).concat(value);
    }

    filters[filterKey] = filter;
    this.setState({ filters });
  },

  onDropdownClicked(e) {
    if (AuthUtils.getActiveOrgType() === "v" && window.PM.vendorActionPage) {
      track(
        VendorFiltersCleared({
          pageName: window.PM.vendorActionPage,
        })
      );
    }

    if (!e.target.hasAttribute("data-propogate")) {
      e.stopPropagation();
    }
  },

  onActionDropdownClose() {
    this.setState(this.getInitialState());
  },

  componentWillReceiveProps(nextProps) {
    if (SavedFilterUtils.haveFiltersChanged(this.props, nextProps)) {
      this.setState(this.getInitialState(nextProps));
    }
  },

  isAnyParamPresent(query, queryParam) {
    if (Array.isArray(queryParam)) {
      return !!queryParam.find((param) => query[param]);
    } else {
      if (Array.isArray(query[queryParam])) {
        return query[queryParam].length > 0;
      }
      return !!query[queryParam];
    }
  },

  calcNumberOfFilters() {
    let { filterFields } = this.props;
    let filterValues = _.values(filterFields);
    let filterData = this._getFilterConfig(this.props);

    return filterValues.reduce((total, filterName) => {
      const filter = filterData[filterName];
      if (Array.isArray(filter)) {
        return filter.length > 0 ? total + 1 : total;
      } else if (filter) {
        return total + 1;
      }
      return total;
    }, 0);
  },

  _getFilterConfig(props) {
    return props.filterGroup || parseQuery(props.location);
  },
};

export default DataFilter;
