var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const Help = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement("path", { d: "M20 40a20 20 0 1120-20 20 20 0 01-20 20zm0-38a18 18 0 1018 18A18 18 0 0020 2z" }), /* @__PURE__ */ React.createElement("path", { d: "M20 32.5a1 1 0 01-1-1v-2a1 1 0 012 0v2a1 1 0 01-1 1zM20 24.5a1 1 0 01-1-1v-3a1 1 0 011-1 5 5 0 10-5-5 1 1 0 01-2 0 7 7 0 118 6.93v2.07a1 1 0 01-1 1z" }));
