var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
const DEFAULT_MODE = "_DEFAULT";
export default function withModes(Comp, modes, defaultModeFn) {
  return class WithModes extends React.Component {
    constructor(props) {
      super(props);
      if (!modes) {
        throw Error("`modes` is required");
      }
      let mode = DEFAULT_MODE;
      if (defaultModeFn) {
        mode = defaultModeFn(this.props) || mode;
      }
      this.state = { mode };
      this.changeMode = this.changeMode.bind(this);
      this.resetMode = this.resetMode.bind(this);
    }
    render() {
      return /* @__PURE__ */ React.createElement(Comp, __spreadProps(__spreadValues({ mode: this.state.mode }, this.props), { onModeChange: this.changeMode, onResetMode: this.resetMode }));
    }
    changeMode(newMode) {
      this.setState({ mode: newMode });
    }
    resetMode() {
      this.setState({ mode: DEFAULT_MODE });
    }
  };
}
