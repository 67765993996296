import integrationPartnerActions from "../actions/integration-partner-actions";
import integrationPartnerApi from "../apis/integration-partner-api";

let IntegrationPartnerSource = {
  fetchAll() {
    return {
      remote(state) {
        return integrationPartnerApi.fetchAll();
      },

      shouldFetch(state, force) {
        return true;
      },

      success: integrationPartnerActions.fetchAllDone,
      error: integrationPartnerActions.fetchAllFail
    };
  },

  fetchBySlug() {
    return {
      remote(state, slug) {
        return integrationPartnerApi.fetchBySlug(slug);
      },

      shouldFetch(state, force) {
        return true;
      },

      success: integrationPartnerActions.fetchBySlugDone,
      error: integrationPartnerActions.fetchBySlugFail
    };
  }
};

export default IntegrationPartnerSource;
