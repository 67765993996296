var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import AltContainer from "alt-container";
import React from "react";
import vendorInvoiceFileStore from "../stores/vendor-invoice-file-store";
import { SmallLoader } from "../../loaders";
import File from "../../common/components/file";
import FilePreview from "../../common/components/file-preview";
import FileViewerShareFile from "../../common/components/file-viewer-share-file";
import FileViewerDeleteFile from "../../common/components/file-viewer-delete-file";
import FileUtils from "../../common/utils/file-utils";
import FileViewerNewWindow from "../../common/components/file-viewer-new-window";
import FileViewerDownloadFile from "../../common/components/file-viewer-download-file";
import withPreviewableFiles from "../../common/components/with-previewable-files";
class InvoiceFile extends React.Component {
  constructor(props) {
    super(props);
    this.handleViewerDeleteFile = this.handleViewerDeleteFile.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.renderFilePreviewActions = this.renderFilePreviewActions.bind(this);
  }
  render() {
    if (this.props.files.size === 0 && this.props.isLoading) {
      return /* @__PURE__ */ React.createElement(SmallLoader, null);
    }
    if (this.props.files.size === 0) {
      return /* @__PURE__ */ React.createElement("div", { className: "copy" }, this.props.emptyText);
    }
    const f = this.props.files.map((file) => {
      const props = __spreadProps(__spreadValues({}, this.props), {
        deleteFile: this.props.allowDelete ? this.handleDeleteFile : void 0,
        onThumbnailClicked: this.props.navFile,
        file
      });
      return /* @__PURE__ */ React.createElement(File, __spreadValues({ key: file.get("id") }, props));
    });
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("ul", { className: "files" }, f), this.props.previewingFile && /* @__PURE__ */ React.createElement(
      FilePreview,
      {
        files: this.props.files,
        file: this.props.previewingFile,
        onClose: this.props.closePreview,
        navFile: this.props.navFile,
        renderActions: this.renderFilePreviewActions
      }
    ));
  }
  renderFilePreviewActions(file) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, this.props.shareFile && /* @__PURE__ */ React.createElement(FileViewerShareFile, { file, onShare: this.props.shareFile }), this.props.allowDelete && /* @__PURE__ */ React.createElement(FileViewerDeleteFile, { file, onDelete: this.handleViewerDeleteFile }), FileUtils.isOpenable(file) && /* @__PURE__ */ React.createElement(FileViewerNewWindow, { file }), /* @__PURE__ */ React.createElement(FileViewerDownloadFile, { file }));
  }
  handleDeleteFile(file, cid) {
    if (this.props.deleteFile) {
      this.props.deleteFile(file, cid);
      this.props.closePreview();
    }
  }
  handleViewerDeleteFile(file) {
    this.handleDeleteFile(file, "");
  }
}
const PreviewableVendorInvoiceFile = withPreviewableFiles(InvoiceFile);
export class VendorInvoiceFile extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement(
      PreviewableVendorInvoiceFile,
      __spreadProps(__spreadValues({}, this.props), {
        isLoading: this.props.isLoading,
        deleteFile: this.props.deleteFile
      })
    );
  }
}
export default class extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement(AltContainer, { stores: [vendorInvoiceFileStore], render: this.renderVendorInvoiceFile() });
  }
  renderVendorInvoiceFile() {
    const meldId = this.props.meldId;
    return /* @__PURE__ */ React.createElement(
      VendorInvoiceFile,
      __spreadProps(__spreadValues({}, this.props), {
        isLoading: vendorInvoiceFileStore.isLoading(),
        files: vendorInvoiceFileStore.getInvoiceFileForMeld(meldId)
      })
    );
  }
}
