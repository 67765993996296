var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import * as Sentry from "@sentry/browser";
import CancelToken from "../../assets/js/common/utils/cancel-token";
import { BrowserTracing } from "@sentry/tracing";
export class ErrorHandler {
  /**
   * Initializes ErrorHandler's error tracking tools.
   *
   * In this case it will initialize Sentry and configure the Sentry scope while also catching onunhandledrejection
   * errors and sending that to sentry as well.
   */
  static initializeHandler() {
    if (!this.dsn) {
      this.dsn = window.PM.sentryDSN;
      if (this.dsn) {
        Sentry.init({
          dsn: this.dsn,
          attachStacktrace: true,
          release: window.PM.env.release,
          integrations: [
            new BrowserTracing(),
            new Sentry.Replay({
              // Additional SDK configuration goes in here, for example:
              maskAllText: true,
              blockAllMedia: true
            })
          ],
          tracesSampleRate: 1,
          ignoreErrors: [
            "top.GLOBALS",
            // Seems to be some browser extension that is spamming things with random strings (eg. window._rm8182149357162416)
            /.*window._rm.*/,
            "ResizeObserver loop completed with undelivered notifications.",
            "ResizeObserver loop limit exceeded",
            "Request failed with status code 400"
          ],
          allowUrls: [
            window.PM.static_files_cdn,
            /https?:\/\/((app|staging)\.)?propertymeld\.com/,
            /https?:\/\/((propertymeld-.*)\.)?herokuapp\.com/
          ],
          // This sets the sample rate to be 5%. You may want this to be 100% while
          // in development and sample at a lower rate in production
          replaysSessionSampleRate: 0.05,
          // If the entire session is not sampled, use the below sample rate to sample
          // sessions when an error occurs.
          replaysOnErrorSampleRate: 1
          // beforeSend(event) {
          //   if (event.exception) {
          //     Sentry.showReportDialog({ eventId: event.event_id });
          //   }
          //   return event;
          // }
        });
        Sentry.configureScope((scope) => {
          const _a = window.PM, { user, sentryDSN, csrf_token } = _a, windowPM = __objRest(_a, ["user", "sentryDSN", "csrf_token"]);
          scope.setUser(__spreadValues({}, user));
          scope.setExtras(windowPM);
        });
      }
    }
  }
  /**
   * Used to handle any non-axios errors. Will send the error to Sentry and to the console.
   * If Sentry is not initlized, or there is no Sentry DSN (as in local development) the error will simply be printed to the console.
   *
   * @param error The error you are reporting to sentry
   * @param extras Any extra information you want to send along with this error
   */
  static handleError(error, extras) {
    if (this.dsn) {
      Sentry.withScope((scope) => {
        if (extras) {
          scope.setExtras(extras);
        }
        scope.setExtra("redesign_enabled", !!window.PM.redesign_enabled);
        scope.setLevel("error");
        if (!CancelToken.isCancel(error)) {
          Sentry.captureException(error);
        }
      });
    }
    console.error(error, extras);
  }
  /**
   * Logs Axios errors depending on the type of error they are.
   *
   * @param error The error
   */
  static handleAxiosError(error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log(error.message);
      ErrorHandler.handleError(error);
    }
    console.log(error.config);
  }
}
