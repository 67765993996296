var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import AltContainer from "alt-container";
import MeldFiles from "./meld-files";
import React from "react";
import vendorMeldFileActions from "../actions/vendor-meld-file-actions";
import vendorMeldFileStore from "../stores/vendor-meld-file-store";
export class VendorMeldFiles extends React.Component {
  constructor(props) {
    super(props);
    this.deleteFile = this.deleteFile.bind(this);
  }
  render() {
    return /* @__PURE__ */ React.createElement(MeldFiles, __spreadProps(__spreadValues({}, this.props), { isLoading: this.props.isLoading, deleteFile: this.deleteFile }));
  }
  deleteFile(file) {
    vendorMeldFileActions.deleteFile(file.get("id"));
  }
}
export default class extends React.Component {
  render() {
    const meldId = this.props.meldId;
    return /* @__PURE__ */ React.createElement(
      AltContainer,
      {
        stores: [vendorMeldFileStore],
        render: () => {
          return /* @__PURE__ */ React.createElement(
            VendorMeldFiles,
            __spreadProps(__spreadValues({}, this.props), {
              isLoading: vendorMeldFileStore.isLoading(),
              files: vendorMeldFileStore.getFilesForMeld(meldId)
            })
          );
        }
      }
    );
  }
}
