var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import { EuiButton, EuiLink } from "@elastic/eui";
import { colors } from "@pm-frontend/styles";
import { Link } from "react-router-dom";
const classNameMap = {
  danger: "dangerFillButton",
  primary: "primaryFillButton"
};
const backgroundColorMap = {
  danger: colors.interface.red.default,
  primary: colors.brand.meldBlue,
  accent: colors.neutrals.gray500
};
const cssButton = {
  "&.primaryFillButton:hover": {
    backgroundColor: `${colors.brand.darkHover} !important`
  },
  "&.dangerFillButton:hover": {
    textDecoration: "none",
    backgroundColor: `${colors.interface.red.dark} !important`
  }
};
const PmFilledButton = (_a) => {
  var _b = _a, {
    text,
    color = "primary",
    fontSize = "16px",
    padding = "10px 16px",
    "data-testid": dataTestId,
    isEnabled = true,
    isLoading,
    fullWidth = false,
    minWidth
  } = _b, props = __objRest(_b, [
    "text",
    "color",
    "fontSize",
    "padding",
    "data-testid",
    "isEnabled",
    "isLoading",
    "fullWidth",
    "minWidth"
  ]);
  const className = classNameMap[color];
  const backgroundColor = backgroundColorMap[color];
  const style = {
    background: backgroundColor,
    color: colors.brand.white,
    textDecoration: "none",
    fontWeight: "400",
    minWidth: minWidth || "112px",
    height: "fit-content",
    padding,
    fontSize,
    transform: "none"
  };
  if (props.onClick) {
    return /* @__PURE__ */ React.createElement(
      EuiButton,
      __spreadProps(__spreadValues({
        fill: true,
        className,
        fullWidth,
        style,
        onClick: props.onClick
      }, dataTestId ? { "data-testid": dataTestId } : {}), {
        isLoading,
        disabled: !isEnabled,
        contentProps: { style: { height: "fit-content" } },
        textProps: { style: { textAlign: "center" } },
        css: cssButton
      }),
      text
    );
  } else if (props.href) {
    const button = /* @__PURE__ */ React.createElement(
      EuiButton,
      {
        fill: false,
        className,
        fullWidth,
        style,
        "data-testid": dataTestId,
        contentProps: { style: { height: "fit-content" } },
        textProps: { style: { textAlign: "center" } },
        css: cssButton
      },
      text
    );
    if (props.internalLink) {
      return /* @__PURE__ */ React.createElement(Link, { to: props.href }, button);
    } else {
      return /* @__PURE__ */ React.createElement(EuiLink, { href: props.href }, button);
    }
  } else if (props.linkToProps) {
    return /* @__PURE__ */ React.createElement(Link, { to: props.linkToProps }, /* @__PURE__ */ React.createElement(
      EuiButton,
      {
        fill: false,
        className,
        fullWidth,
        style,
        "data-testid": dataTestId,
        contentProps: { style: { height: "fit-content" } },
        textProps: { style: { textAlign: "center" } },
        css: cssButton
      },
      text
    ));
  }
  if (props.formId) {
    return /* @__PURE__ */ React.createElement(
      EuiButton,
      {
        fill: true,
        className,
        fullWidth,
        style,
        "data-testid": dataTestId,
        isLoading,
        disabled: !isEnabled,
        form: props.formId,
        type: "submit",
        contentProps: { style: { height: "fit-content" } },
        textProps: { style: { textAlign: "center" } },
        css: cssButton
      },
      text
    );
  }
  return /* @__PURE__ */ React.createElement(
    EuiButton,
    __spreadProps(__spreadValues({
      fill: true,
      className,
      fullWidth,
      style
    }, dataTestId ? { "data-testid": dataTestId } : {}), {
      contentProps: { style: { height: "fit-content" } },
      textProps: { style: { textAlign: "center" } },
      css: cssButton
    }),
    text
  );
};
export { PmFilledButton };
