import I from "immutable";

import { ImmutableMap } from "../../types/common";
import { Roles } from "../../constants";

export const RoleUtils = {
  formatName(role: ImmutableMap) {
    const name = role.get("name");

    switch (name) {
      case Roles.MANAGEMENT_ADMIN:
        return "Admin";
      case Roles.MANAGEMENT_AGENT:
        return "Agent";
      case Roles.MAINTENANCE:
        return "Maintenance";
      default:
        return name;
    }
  },

  getAssignableRoles(roles: I.List<ImmutableMap>) {
    return roles.filter((role: ImmutableMap) => {
      return role.get("permissions").every((permission: ImmutableMap) => {
        return window.PM.permissions.includes(permission.get("codename"));
      });
    });
  }
};
