import BaseOwnerForm from "./base-owner-form";
import createReactClass from "create-react-class";
import React from "react";
import UnitOwnerSelect from "./components/unit-owner-select";
import withSaveOwnerProperties from "./components/with-save-owner-properties";
import CancelSaveButtons from "../common/components/cancel-save-buttons";
import { FlexJustifyContent } from "../../../app/components/utils/constants";

let AddPropertyOwnerForm = createReactClass({
  mixins: [BaseOwnerForm],

  renderName() {
    let ownerName = "";
    if (this.state.data.get("first_name")) {
      ownerName += this.state.data.get("first_name");
    }
    if (this.state.data.get("last_name")) {
      ownerName += " " + this.state.data.get("last_name");
    }

    return (
      <div>
        <div className="columns small-8">
          <div className="label-container">
            <label>Owner Name</label>
          </div>
          <UnitOwnerSelect
            value={ownerName}
            onOwnerChosen={this.onOwnerChosen}
            ownerIdExclusions={this.props.ownerIdExclusions}
          />
        </div>
      </div>
    );
  },

  onOwnerChosen(owner) {
    if (owner.id) {
      this.setState({
        data: this.state.data.merge({
          id: owner.id,
          first_name: owner.first_name,
          last_name: owner.last_name,
          email: owner.email,
          hub_access_level: owner.hub_access_level,
          contact: owner.contact
            ? {
                business_phone: owner.contact.business_phone,
                cell_phone: owner.contact.cell_phone,
                home_phone: owner.contact.home_phone
              }
            : {}
        })
      });
    } else {
      let ownerName = owner.split(/ (.+)/);
      this.setState({
        data: this.state.data.merge({
          id: null,
          first_name: ownerName[0],
          last_name: ownerName[1]
        })
      });
    }
  },

  renderButtonControls() {
    return (
      <div className="footer">
        <div className="footer-content">
          <CancelSaveButtons
            onCancelFn={this.props.close}
            onSaveFn={this.onSubmit}
            cancelDisabled={this.isPendingSave()}
            saveDisabled={this.isPendingSave()}
            justifyContainer={FlexJustifyContent.Center}
          />
        </div>
      </div>
    );
  },

  onSubmit(e) {
    e.preventDefault();
    this.setState({ submissionTried: true });
    if (this.isValid()) {
      let data = this.state.data.merge({ properties: [this.props.propertyId] }).toJS();
      if (data.id) {
        this.props.handleAddOwnerProperty(data.id, this.props.propertyId);
      } else {
        this.props.onOwnerSave(data);
      }
    }
  }
});

export default withSaveOwnerProperties(AddPropertyOwnerForm);
