import React from "react";
import { EuiCard, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from "@elastic/eui";

import { colors } from "@pm-frontend/styles";

interface SiteSearchMobileResultCardProps {
  contentArray: React.ReactNodeArray;
  isDisabled?: boolean;
}

const renderContentItem = (content: React.ReactNode, isDisabled: boolean) => {
  if (typeof content === "string") {
    return (
      <EuiText
        color={!isDisabled ? colors.neutrals.gray800 : colors.neutrals.gray600}
        size="m"
        style={{ lineHeight: "24px" }}
      >
        {content}
      </EuiText>
    );
  }
  return content;
};

const SiteSearchMobileResultCard = ({ contentArray, isDisabled }: SiteSearchMobileResultCardProps) => {
  const innerContent = (
    <EuiFlexGroup direction="column" gutterSize="l">
      {contentArray.map((item, index) => {
        if (!item) {
          return null;
        }
        if (index === 0 && isDisabled) {
          return (
            <EuiFlexItem grow={false} key={index}>
              <EuiFlexGroup direction="row" gutterSize="s" responsive={false} alignItems="center">
                <EuiIcon type="eyeClosed" color={colors.neutrals.gray600} />
                <EuiFlexItem grow={false}>{renderContentItem(item, isDisabled)}</EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          );
        }
        return (
          <EuiFlexItem grow={false} key={index}>
            {renderContentItem(item, !!isDisabled)}
          </EuiFlexItem>
        );
      })}
    </EuiFlexGroup>
  );
  return (
    <EuiCard
      title={innerContent}
      titleElement="span"
      textAlign="left"
      paddingSize="m"
      style={{
        borderRadius: "6px",
        border: `1px solid ${colors.neutrals.gray200}`,
        background: colors.brand.white,
        boxShadow: "0px 0px 24px 0px rgba(73, 80, 87, 0.03)",
      }}
    />
  );
};

export { SiteSearchMobileResultCard };
