import React from "react";
import { EuiAvatar, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiText, EuiToolTip } from "@elastic/eui";

import { colors } from "@pm-frontend/styles";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";

interface SettingsMenuProps {
  avatarExtraText?: string;
  avatarColor: string;
  userName: string;
  email: string;
  menuSections: Array<{ item: React.ReactNode; key: string }>;
}
/**
 * A generic component to display settings menus in different apps
 */
const SettingsMenu = ({ avatarExtraText, avatarColor, userName, email, menuSections }: SettingsMenuProps) => {
  const isMobile = useIsMobile();
  return (
    <EuiFlexGroup
      direction="column"
      gutterSize="l"
      className="eui-yScroll"
      style={isMobile ? { padding: "12px" } : { maxHeight: "80vh", maxWidth: "300px" }}
    >
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction="column" alignItems="flexStart" style={{ gap: "12px" }}>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="row" responsive={false} alignItems="center" style={{ gap: "12px" }}>
              <EuiFlexItem grow={false}>
                <EuiAvatar
                  className="pm-header-settings-avatar"
                  name={userName}
                  color={avatarColor}
                  style={{
                    height: "40px",
                    width: "40px",
                    fontFamily: '"Open Sans", Helvetica, Roboto, Arial, sans-serif',
                  }}
                />
              </EuiFlexItem>
              {avatarExtraText && (
                <EuiFlexItem grow={false} style={{ minWidth: 0, maxWidth: "216px", overflow: "hidden" }}>
                  <EuiToolTip delay="long" content={avatarExtraText}>
                    <EuiText
                      color={colors.neutrals.gray800}
                      style={{
                        fontWeight: 600,
                        textOverflow: "ellipsis",
                        maxInlineSize: "unset",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                      grow={false}
                    >
                      {avatarExtraText}
                    </EuiText>
                  </EuiToolTip>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="column" responsive={false} style={{ gap: "2px" }} alignItems="flexStart">
              <EuiFlexItem grow={false}>
                <EuiText color={colors.neutrals.gray900} size="m" style={{ fontWeight: 700 }}>
                  {userName}
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText color={colors.neutrals.gray800} size="xs">
                  {email}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {menuSections && (
          <EuiFlexGroup direction="column" alignItems="flexStart" style={{ flexGrow: 0, gap: "12px" }}>
            {menuSections.map((section, index) => {
              return (
                <React.Fragment key={section.key}>
                  <EuiFlexItem grow={false}>{section.item}</EuiFlexItem>
                  {index !== menuSections.length - 1 && (
                    <EuiFlexItem grow={false}>
                      <EuiHorizontalRule margin="none" />
                    </EuiFlexItem>
                  )}
                </React.Fragment>
              );
            })}
          </EuiFlexGroup>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export { SettingsMenu, SettingsMenuProps };
