import classnames from "classnames";
import { MdTranslate } from "react-icons/md";
import _ from "lodash";
import AltContainer from "alt-container";
import * as C from "../constants";
import CommentView from "../../../app/views/melds/CommentView";
import CommentSummaryView from "../../../app/views/melds/CommentSummaryView";
import CommentTemplate from "./records/comment-template";
import CommentActions from "./actions/comment-actions";
import CommentStore from "./stores/comment-store";
import CommentTemplateDropdown from "./components/comment-template-dropdown";
import CommentVisibilitySelector, { Visibility } from "./components/comment-visibility-selector";
import createReactClass from "create-react-class";
import { ErrorHandler } from "../../../app/utils/ErrorHandler";
import I from "immutable";
import Link, { LinkHelper } from "@pm-shared/utils/link";
import MeldUtils from "./utils/meld-utils";
import { ModelUpdate, ModelUpdateProps } from "../utils/model-update-utils";
import OwnerAddModal from "../property/owner-add-modal";
import OwnerUtils from "../property/utils/owner-utils";
import PropTypes from "prop-types";
import React from "react";
import SetIntervalWhileActive, { InjectedProps } from "../common/components/set-interval-while-active";
import { SmallLoader } from "../loaders";
import Status from "../mixins/status";
import TenantSettingsStore from "../tenant_app/stores/tenant-settings-store";
import TenantUtils from "../tenant/utils/tenant-utils";
import { Actions } from "../stores/base-store";
import { fitToContent } from "../common/utils/textarea-utils";
import { hasPerm } from "../common/utils/permission-utils";
import { Prompt } from "react-router-dom";
import Button, { ButtonTypes } from "../../../app/components/buttons/Button";
import ButtonContainer from "../../../app/components/containers/ButtonContainer";
import DropdownButton from "../../../app/components/buttons/DropdownButton";
import OptionsButton from "../../../app/components/buttons/OptionsButton";
import MenuList from "../../../app/components/texts/MenuList";
import { FlexJustifyContent } from "../../../app/components/utils/constants";
import MeldContext, { MeldContextProvider, MeldContextProviderState } from "../../../app/contexts/MeldContext";
import {
  track,
  VendorChatReceiverSelected,
  VendorChatSent,
  VendorTranslateToEnglishClicked,
  TenantTranslateToEnglishClicked,
} from "@pm-app/utils/analytics";
import { AuthUtils } from "@pm-assets/js/utils/auth-utils";
import Features from "@pm-assets/js/common/feature-flags";
import { postTranslateToEnglishData } from "../../../app/views/melds/comment-services";
import { fetchCurrentVendorAgentNonSWR } from "@pm-property-management-hub/src/utils/api/vendors";
import { fetchCurrentTenantLanguageNonSWR } from "@pm-property-management-hub/src/utils/api/tenants";

import MessageModifyComponent from "@pm-property-management-hub/src/components/melds/message-modify";
import { getTranslatedUIPrompt } from "../../../app/views/melds/utils/language_utils";

enum CommentsModes {
  DEFAULT = "DEFAULT",
  ADD_OWNER = "ADD_OWNER",
}

export interface CommentsProps extends ModelUpdateProps {
  comments: I.List<I.Map<string, any>>;
  loading: boolean;
  meld: I.Map<string, any>;
  refreshMeld: () => void;
  tenantSettings?: I.Map<string, any>;
}

export let Comments = createReactClass<CommentsProps, { data: any }>({
  loadingStore: CommentStore,

  propTypes: {
    meld: PropTypes.object.isRequired,
  },

  mixins: [Status],

  getInitialState() {
    this.textRef = React.createRef();

    this.mergeData = this.props.mergeData.bind(this);
    this.setVal = this.props.setVal.bind(this);

    return {
      show_translation_feature: false,
      default_language: "en",
      rateLimitPassed: false,
      cid: _.uniqueId("Comments_"),
      creatingComment: false,
      data: I.fromJS({
        hidden_from_tenant: false,
        hidden_from_vendor: false,
        hidden_from_owner: true,
        text: "",
        translatedText: null,
        translatedTo: null,
        isTranslating: false,
        is_translated: false,
      }),
      mode: CommentsModes.DEFAULT,
    };
  },

  handleTranslateClick() {
    this.setState({ data: this.state.data.set("isTranslating", true) });
    if (this.state.data.get("translatedTo") === "en") {
      this.setState({
        data: this.state.data.set("translatedText", null).set("translatedTo", null).set("isTranslating", false),
      });
    } else {
      if (LinkHelper.getOrgType() === "v") {
        track(VendorTranslateToEnglishClicked());
      }
      if (LinkHelper.getOrgType() === "t") {
        track(TenantTranslateToEnglishClicked());
      }
      postTranslateToEnglishData(this.state.data.get("text"), this.props.meld.get("id"))
        .then((response) => {
          this.setState({
            data: this.state.data
              .set("translatedText", response.generated_text)
              .set("translatedTo", "en")
              .set("isTranslating", false),
          });
        })
        .catch((e) => {
          this.setState({
            rateLimitPassed: e.message === "Request failed with status code 429" ? true : false,

            data: this.state.data.set("translatedTo", null).set("isTranslating", false),
          });
        });
    }
  },

  handleTextChange(value: string) {
    if (this.state.data.get("translatedText")) {
      this.setState({ data: this.state.data.set("translatedText", value) });
    } else {
      this.setState({ data: this.state.data.set("text", value) });
    }
  },

  render() {
    if (this.props.loading) {
      return <SmallLoader />;
    }

    const isTranslationEnabled = () => {
      const orgType = LinkHelper.getOrgType();
      if (orgType === "v") {
        return Features.isGptTranslateEnabled();
      } else if (orgType === "t") {
        return Features.isResidentGptTranslateEnabled();
      }
      return false;
    };

    const attemptMentions = this.state.data.get("text").includes(" @") || this.state.data.get("text").startsWith("@");

    const mentionDisabledStyles = {
      marginTop: "8px",
      color: "var(--interface-red-default, #B2250F)",
    };

    const belowTextMessage = () => {
      return (
        <>
          {Features.isLegacyCustomer() && attemptMentions && (
            <p style={mentionDisabledStyles}>@Mentions aren't available on your pricing tier</p>
          )}
          {Features.isMeldMentionsEnabled() && attemptMentions && (
            <>
              {this.props.meld.get("meld_type") === "ESTIMATE" && (
                <p style={mentionDisabledStyles}>@Mentions aren't available on estimates yet</p>
              )}
              {this.props.meld.get("meld_type") === "MELD" && LinkHelper.getOrgType() === "m" && (
                <p style={mentionDisabledStyles}>Toggle on Meld 2.0 to @mention someone</p>
              )}
            </>
          )}
        </>
      );
    };

    // TODO special multibutton for send message to
    return (
      <div
        className={classnames("comments", "OLDCSS", { "no-comments": !this.props.comments.size })}
        ref={(node) => (this.comments = node)}
      >
        <Prompt
          when={!!this.state.data.get("text")}
          message={() => "Are you sure you want to leave? Any unsaved messages will be lost."}
        />
        {this.renderSummary()}
        {this.renderComments()}
        <div className="row">
          <div className="columns">
            <textarea
              name="New comment"
              ref={this.textRef}
              className="new-comment"
              data-test="new-comment"
              value={this.state.data.get("translatedText") || this.state.data.get("text")}
              onChange={(event) => this.handleTextChange(event.target.value)}
              placeholder="Enter a new message"
              disabled={
                this.state.creatingComment ||
                this.state.data.get("isTranslating") ||
                this.state.data.get("translatedTo") === "en"
              }
            />
            {this.state.data.get("isTranslating") && <SmallLoader />}
            {belowTextMessage()}
            {(LinkHelper.getOrgType() === "v" || LinkHelper.getOrgType() === "t") &&
              this.state.show_translation_feature &&
              this.state.default_language !== null &&
              this.state.default_language !== "EN" &&
              isTranslationEnabled() && (
                <div
                  style={{ color: "var(--brand-meld-blue, #1175CC)", cursor: "pointer", marginLeft: "-4px" }}
                  onClick={this.handleTranslateClick}
                >
                  <MdTranslate />
                  {this.state.rateLimitPassed
                    ? "You have used the maximum translations on this Meld"
                    : this.state.data.get("translatedTo") === "en"
                    ? getTranslatedUIPrompt("cancel", this.state.default_language)
                    : getTranslatedUIPrompt("translate to english", this.state.default_language)}
                </div>
              )}
            {LinkHelper.getOrgType() === "m" && Features.isGptChatModifyEnabled() && (
              <MessageModifyComponent
                text={this.state.data.get("text")}
                handleTextChange={this.handleTextChange}
                meld_id={this.props.meld.get("id")}
              />
            )}
          </div>
        </div>

        <div className="row">
          <div className="columns small-12 comment-action-buttons">
            {this.renderTemplateConfig()}
            <ButtonContainer
              className="comment-button-container send-message-container"
              justify={FlexJustifyContent.FlexStart}
            >
              <Button
                buttonType={ButtonTypes.Primary}
                className="send-message"
                onClick={this.submitMsg}
                disabled={
                  (!this.state.data.get("hidden_from_owner") && !this.getOwnersCount()) || this.state.creatingComment
                }
              >
                Send Message to
              </Button>
              {this.renderVisibilitySelect()}
            </ButtonContainer>
          </div>
        </div>
        <div className="row">
          <div className="columns dropdown-description">{this.renderVisibilityDescription()}</div>
        </div>
        {this.renderMode()}
      </div>
    );
  },

  getOwnersCount() {
    const owners = this.props.meld.getIn(["owners"]);

    if (owners) {
      return owners.count();
    }
  },

  renderComments() {
    if (this.props.comments.size === 0) {
      if (CommentStore.isLoading()) {
        return <SmallLoader />;
      } else {
        return (
          <div className="row">
            <div className="column">
              <p>No messages.</p>
            </div>
          </div>
        );
      }
    }

    return this.props.comments.map((comment: I.Map<string, any>, i: number) => {
      return (
        <CommentView
          comment={comment}
          key={i}
          show_translation_feature={this.state.show_translation_feature}
          default_language={this.state.default_language}
          meld_id={this.props.meld.get("id")}
        />
      );
    });
  },

  renderSummary() {
    if (Features.isGptSummaryEnabled() && LinkHelper.getOrgType() === "m" && this.props.comments.size > 3) {
      return <CommentSummaryView meld_id={this.props.meld.get("id")} comments={this.props.comments} />;
    }
  },

  renderTemplateConfig() {
    if (LinkHelper.getOrgType() === "m") {
      if (this.state.templateMenuOpen) {
        return (
          <div className="comment-button-container comment-dropdown-container" data-test="comment-dropdown-container">
            <CommentTemplateDropdown
              onSelectTemplate={this.handleTemplateSelected}
              onBlur={this.handleTemplateSelectBlur}
            />
          </div>
        );
      } else {
        return (
          <ButtonContainer
            forceInlineButtons={true}
            className="comment-button-container"
            justify={FlexJustifyContent.FlexStart}
          >
            <Button
              style={{ marginTop: "4px" }}
              className="template-menu-btn"
              data-test="template-menu-btn"
              onClick={this.handleTemplateMenuButtonClicked}
            >
              Use Template
            </Button>
            <DropdownButton
              className="template-dropdown"
              renderButton={() => <OptionsButton className="menu-button" withBorder={true} />}
              renderContents={() => (
                <MenuList>
                  <li className="menu-item">
                    <Link to="/account-settings/templates/" className="action">
                      Edit Templates
                    </Link>
                  </li>
                </MenuList>
              )}
            />
          </ButtonContainer>
        );
      }
    }
  },

  getVisibility() {
    if (this.state.data.get("hidden_from_tenant")) {
      if (this.state.data.get("hidden_from_vendor")) {
        if (!this.state.data.get("hidden_from_owner")) {
          return Visibility.ManagersAndOwnersOnly;
        } else {
          return Visibility.ManagersOnly;
        }
      } else {
        return Visibility.ManagersAndVendorsOnly;
      }
    } else if (this.state.data.get("hidden_from_vendor")) {
      return Visibility.ManagersAndTenantsOnly;
    } else {
      return Visibility.Everyone;
    }
  },

  onVisibilityChange(visibility: Visibility) {
    if (this.props.pageName && AuthUtils.getActiveOrgType() === "v") {
      track(VendorChatReceiverSelected({ meldId: this.props.meld.get("id"), pageName: this.props.pageName }));
    }

    switch (visibility) {
      case Visibility.Everyone:
        this.mergeData({
          hidden_from_vendor: false,
          hidden_from_tenant: false,
          hidden_from_owner: true,
        });
        break;
      case Visibility.ManagersAndVendorsOnly:
        this.mergeData({
          hidden_from_vendor: false,
          hidden_from_tenant: true,
          hidden_from_owner: true,
        });
        break;
      case Visibility.ManagersAndTenantsOnly:
        this.mergeData({
          hidden_from_vendor: true,
          hidden_from_tenant: false,
          hidden_from_owner: true,
        });
        break;
      case Visibility.ManagersOnly:
        this.mergeData({
          hidden_from_vendor: true,
          hidden_from_tenant: true,
          hidden_from_owner: true,
        });
        break;
      case Visibility.ManagersAndOwnersOnly:
        this.mergeData({
          hidden_from_vendor: true,
          hidden_from_tenant: true,
          hidden_from_owner: false,
        });
        break;
      default:
        ErrorHandler.handleError(new Error(`Unknown visibility setting ${visibility}`));
    }
  },

  shouldDisableOwnerMessaging() {
    // If there are no owners, trying to send to an owner prompts the manager to add an owner.
    // Therefore, disable this option if the manager does not have permission to add an owner to a property and
    // the unit/property lacks owners.
    return (
      LinkHelper.getOrgType() === "m" &&
      !hasPerm(C.Perms.CAN_ADD_EDIT_DEACTIVATE_OWNERS) &&
      this.props.meld.getIn(["owners"]).isEmpty()
    );
  },

  renderVisibilitySelect() {
    let orgType = LinkHelper.getOrgType();

    return (
      <CommentVisibilitySelector
        onVisibilityChange={this.onVisibilityChange}
        selectedVisibility={this.getVisibility()}
        disableOwners={this.shouldDisableOwnerMessaging()}
        showManagers={orgType === "m"}
        showOwners={orgType === "m"}
        showTenants={["m", "t"].includes(orgType)}
        showVendors={["m", "v"].includes(orgType)}
      />
    );
  },

  renderVisibilityDescription() {
    let meld = this.props.meld;
    let orgType = LinkHelper.getOrgType();
    let entities: I.List<string> = I.List();
    let registeredTenants = MeldUtils.getTenants(meld).filter((tenant: I.Map<string, any>) => !!tenant.get("user"));

    if (orgType !== "v" && !this.state.data.get("hidden_from_vendor")) {
      let vendor = MeldUtils.getAssignedVendor(meld);
      if (vendor) {
        entities = entities.push(vendor.get("name"));
      } else {
        entities = entities.push("Assigned Vendor");
      }
    }

    if (orgType === "t") {
      let currentId: number | null = null;
      if (this.props.tenantSettings) {
        currentId = this.props.tenantSettings.get("id");
      }

      entities = entities.push(meld.getIn(["management", "name"]));

      if (!this.state.data.get("hidden_from_tenant")) {
        entities = entities
          .concat(
            registeredTenants
              .filter((tenant: I.Map<string, any>) => tenant.get("id") !== currentId)
              .map((tenant: I.Map<string, any>) => "Tenant " + TenantUtils.getFullName(tenant))
          )
          .toList();
      }
    } else {
      if (!this.state.data.get("hidden_from_tenant")) {
        entities = entities
          .concat(registeredTenants.map((tenant: I.Map<string, any>) => "Tenant " + TenantUtils.getFullName(tenant)))
          .toList();
      }

      if (orgType === "m" && !this.state.data.get("hidden_from_owner")) {
        let owners = meld.getIn(["owners"]);
        if (owners.count()) {
          entities = entities
            .concat(owners.map((owner: I.Map<string, any>) => "Owner " + OwnerUtils.getName(owner)))
            .toList();
        } else {
          return (
            <a className="add-owner" onClick={() => this.setMode(CommentsModes.ADD_OWNER)}>
              There is no owner at this meld's property. Click here if you wish to add one.
            </a>
          );
        }
      }

      entities = entities.push(meld.getIn(["management", "name"]));
    }

    if (entities.size > 2) {
      return (
        <div>
          Will be seen by{" "}
          {entities.pop().map((entity, i) => (
            <span key={i}>
              <b>{entity}</b>,{" "}
            </span>
          ))}
          and <b>{entities.last()}</b>
        </div>
      );
    } else {
      return (
        <div>
          Will be seen by <b>{entities.first()}</b>{" "}
          {entities.size === 2 ? (
            <span>
              and <b>{entities.last()}</b>
            </span>
          ) : (
            ""
          )}
        </div>
      );
    }
  },

  renderMode() {
    if (CommentsModes.ADD_OWNER === this.state.mode) {
      return (
        <OwnerAddModal
          propertyId={this.props.meld.getIn(["unit", "prop", "id"])}
          isModalOpen={true}
          close={() => {
            this.setMode(CommentsModes.DEFAULT);
            this.props.refreshMeld();
          }}
        />
      );
    }
  },

  setMode(mode: CommentsModes) {
    this.setState({ mode });
  },

  submitMsg() {
    if (this.state.data.get("translatedTo") === "en") {
      this.setState(
        {
          data: this.state.data
            .set("text", this.state.data.get("translatedText"))
            .set("translatedTo", null)
            .set("translatedText", null)
            .set("is_translated", true),
        },
        () => this.createCommentIfTextExists()
      );
    } else {
      this.createCommentIfTextExists();
    }
  },

  createCommentIfTextExists() {
    if (this.state.data.get("text")) {
      this.setState({ creatingComment: true }, () => {
        let meldId = this.props.meld.get("id");
        CommentActions.createComment(this.state.data.merge({ meld: meldId }).toJS(), this.state.cid);
        if (this.props.pageName && AuthUtils.getActiveOrgType() === "v") {
          track(VendorChatSent({ meldId, pageName: this.props.pageName }));
        }
      });
    }
  },

  handleTemplateSelected(template: CommentTemplate) {
    const text = this.state.data.get("text").concat(template.text, "\n");
    if (template.change_visibility && (template.hidden_from_owner || !this.shouldDisableOwnerMessaging())) {
      this.mergeData({
        hidden_from_vendor: template.hidden_from_vendor,
        hidden_from_tenant: template.hidden_from_tenant,
        hidden_from_owner: template.hidden_from_owner,
        text,
      });
    } else {
      this.setVal("text", text);
    }
    this.setState({ templateMenuOpen: false });
  },

  handleTemplateMenuButtonClicked() {
    this.setState({ templateMenuOpen: true });
  },

  handleTemplateSelectBlur() {
    this.setState({ templateMenuOpen: false });
  },

  async componentWillMount() {
    if (LinkHelper.getOrgType() === "v") {
      const result = await fetchCurrentVendorAgentNonSWR();
      this.setState({ show_translation_feature: result.default_language !== "EN" });
      this.setState({ default_language: result.default_language });
    }

    if (LinkHelper.getOrgType() === "t") {
      const result = await fetchCurrentTenantLanguageNonSWR();
      this.setState({ show_translation_feature: result.default_language !== "EN" });
      this.setState({ default_language: result.default_language });
    }
  },

  componentDidUpdate(prevProps, prevState) {
    // Scroll to the bottom of comment list if there is a new comment
    if (this.comments && prevProps.comments.size !== this.props.comments.size) {
      this.comments.scrollTop = this.comments.scrollHeight - this.comments.getBoundingClientRect().height;
    }

    if (prevProps.comments.size !== this.props.comments.size) {
      if (!this.state.data.get("text")) {
        const lastComment = this.props.comments.last();
        if (lastComment) {
          this.mergeData({
            hidden_from_vendor: lastComment.get("hidden_from_vendor"),
            hidden_from_tenant: lastComment.get("hidden_from_tenant"),
            hidden_from_owner: lastComment.get("hidden_from_owner"),
          });
        }
      }
    }

    if (this.state.creatingComment) {
      if (this.didActionSucceed(this.state, Actions.SAVE)) {
        this.setState({
          creatingComment: false,
          data: this.state.data.set("text", ""),
        });
      } else if (this.didActionFail(this.state, Actions.SAVE)) {
        this.setState({ creatingComment: false });
      }
    }

    if (this.state.data.get("text") !== prevState.data.get("text")) {
      fitToContent(this.textRef, 500, 68);
    }
  },
});

let WrappedComments = ModelUpdate(Comments);

export interface CommentsWrapperProps {
  meld: I.Map<string, any>;
}

class CommentsWrapper extends React.Component<CommentsWrapperProps & InjectedProps> {
  render() {
    let props = this.props;

    return (
      <MeldContextProvider meldId={this.props.meld.get("id")} meld={props.meld}>
        <MeldContext.Consumer>
          {(context: MeldContextProviderState) => {
            if (!context) {
              return;
            }

            const { meld, loading, actions } = context;

            return (
              <AltContainer
                stores={{
                  comments() {
                    return {
                      value: CommentStore.getComments().filter((comment: I.Map<string, any>) => {
                        return comment.get("meld") === props.meld.get("id");
                      }),
                      store: CommentStore,
                    };
                  },
                  tenantSettings() {
                    return {
                      value: TenantSettingsStore.getCurrent(),
                      store: TenantSettingsStore,
                    };
                  },
                }}
              >
                <WrappedComments
                  {...this.props}
                  meld={meld || this.props.meld}
                  loading={loading}
                  refreshMeld={actions.refreshMeld}
                />
              </AltContainer>
            );
          }}
        </MeldContext.Consumer>
      </MeldContextProvider>
    );
  }

  fetchComments() {
    this.props.callAndSetInterval(() => {
      CommentStore.fetchComments({
        params: {
          meld: this.props.meld.get("id"),
        },
      });
    }, 60000);
  }

  componentDidUpdate(prevProps: Readonly<CommentsWrapperProps & InjectedProps>) {
    if (prevProps.meld.get("id") !== this.props.meld.get("id")) {
      this.props.clearIntervals();
      this.fetchComments();
    }
  }

  componentWillMount() {
    if (LinkHelper.getOrgType() === "t") {
      TenantSettingsStore.fetchCurrent();
    }

    this.fetchComments();
  }
}

export default SetIntervalWhileActive<CommentsWrapperProps>(CommentsWrapper);
