import _ from "lodash";
import I from "immutable";
import moment from "moment";
import validate from "validate.js";

import { SyncStatuses } from "../constants";
import PhoneUtils from "./utils/phone-utils";

/* From the validate.js docs - prepending ^ to an error message will prevent the library from prepending the field name
 to the error message.
 */

validate.validators.minAvailabilties = function (
  segments: Array<I.Map<string, any>>,
  options: { getMeld: () => I.Map<string, any> }
) {
  let meld = options.getMeld();

  if (meld.get("tenant_presence_required") && meld.get("has_registered_tenant") && segments.length < 2) {
    return "At least 2 time slots must be chosen.";
  }
};

let US = new RegExp("^\\d{5}(-{0,1}\\d{4})?$");
let CA = new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/i);

validate.validators.zipCode = function (zip: string) {
  let toTest = zip.replace(/\W+/g, "");
  if (!US.test(toTest) && !CA.test(toTest)) {
    return "Postal code is invalid";
  }
};

validate.validators.lineItems = function (
  lineItems: any[],
  options: { getInvoiceType?: () => string; getCurrentTotal?: () => number }
) {
  let errors = new Set();

  let lineItemConstraints = {
    description: {
      length: {
        maximum: 1000,
        tooLong: "^Line item descriptions cannot be more than 1000 characters",
      },
      presence: {
        message: "^Line item descriptions cannot be blank",
        allowEmpty: false,
      },
    },
    unit_price: {
      presence: {
        message: "^Line item value cannot be blank",
        allowEmpty: false,
      },
    },
  };

  let lineItemExpenditureConstraints = {
    description: {
      length: {
        maximum: 1000,
        tooLong: "^Line item descriptions cannot be more than 1000 characters",
      },
      presence: {
        message: "^Line item descriptions cannot be blank",
        allowEmpty: false,
      },
    },
    unit_price: {
      presence: {
        message: "^Line item value cannot be blank",
        allowEmpty: false,
      },
    },
    line_item_type: {
      presence: {
        message: "^Please select a type for all line items.",
        allowEmpty: false,
      },
    },
  };

  let estimateLineItemConstraints = {
    description: {
      length: {
        maximum: 1000,
        tooLong: "^Line item descriptions can't be more than 1000 characters",
      },
      presence: {
        message: "^Line item descriptions can't be blank",
        allowEmpty: false,
      },
    },
    unit_cost: {
      presence: {
        message: "^Line item value can't be blank",
        allowEmpty: false,
      },
    },
  };

  if (options.getInvoiceType && options.getInvoiceType() === "upload") {
    const parsedUnitPrice = Number.parseFloat(lineItems[0].unit_price);
    if (!_.isArray(lineItems) || lineItems.length === 0 || Number.isNaN(parsedUnitPrice) || parsedUnitPrice < 0) {
      errors.add("Enter the total amount for the invoice");
    }
  } else if (!_.isArray(lineItems) || lineItems.length === 0) {
    errors.add("At least one line is required");
  } else {
    // Validate each LineItem in the array
    for (let lineItem of lineItems) {
      let selectedConstraints;

      if (lineItem.hasOwnProperty("line_item_type") && !lineItem.cost_estimate) {
        selectedConstraints = lineItemExpenditureConstraints;
      } else {
        selectedConstraints = lineItem.cost_estimate ? estimateLineItemConstraints : lineItemConstraints;
      }

      let error = validate.validate(lineItem, selectedConstraints);
      if (error) {
        for (let errorKey of Object.keys(error)) {
          for (let err of error[errorKey]) {
            errors.add(err);
          }
        }
      }
    }

    // Check of invoice total does not exceed max precision
    if (options.getCurrentTotal && options.getCurrentTotal() >= 100000) {
      errors.add("Total cannot be greater than 7 digits long in total.");
    }
  }

  return errors.size ? errors : null;
};

validate.validators.vendorInvoiceFile = function (vendorInvoiceFile: any, options: { getInvoiceType: () => string }) {
  let errors: string[] = [];

  let invoiceType = options.getInvoiceType();

  if (invoiceType === "upload" && (!vendorInvoiceFile || !vendorInvoiceFile.file)) {
    errors = errors.concat(["No invoice has been uploaded"]);
  }

  return errors.length ? errors : null;
};

interface SyncItem {
  chosen_link: {
    id: number;
  };
  sync_status: SyncStatuses;
}

validate.validators.syncItem = function (
  _sync_status: SyncStatuses,
  options: { duplicateLink: (items: SyncItem[]) => string },
  _key: string,
  attributes: SyncItem[]
) {
  let chosenLinkDupes = new Set();
  let errors: string[] = [];

  // Duplicate link errors
  return attributes.reduce((prev: string[], curr: SyncItem) => {
    if (curr.sync_status === "LINK" && curr.chosen_link && !chosenLinkDupes.has(curr.chosen_link.id)) {
      let dupes = attributes.filter((item) => item.chosen_link && item.chosen_link.id === curr.chosen_link.id);
      if (dupes.length > 1) {
        chosenLinkDupes.add(curr.chosen_link.id);
        return prev.concat(options.duplicateLink(dupes));
      }
    }
    return prev;
  }, errors);
};

validate.validators.hasPetInput = function (
  pets: any[],
  options: { message: string },
  _key: string,
  attributes: { has_pets: boolean }
) {
  if (attributes.has_pets) {
    if (!pets || pets.length < 1) {
      return options.message;
    }
  }
};

validate.validators.estimateDays = function (days: number | null | undefined, options: { message: string }) {
  if (days && days < 0) {
    return options.message;
  }
};

validate.validators.estimatePMFee = function (fee: number | null | undefined, options: { message: string }) {
  if (fee && fee < 0) {
    return options.message;
  }
};

validate.validators.phoneNumber = function (number: string, options: { message: string }) {
  if (!PhoneUtils.isValid(number)) {
    return options.message;
  }
};

validate.validators.availabilities = function (
  availabilities: any[],
  options: { message: string; minimum: number; checkAvailabilities: boolean }
) {
  if (options.checkAvailabilities) {
    if (!availabilities || availabilities.length < options.minimum) {
      return options.message;
    }
  }
};

validate.validators.presenceToggled = function (presenceToggled: boolean, options: { message: string }) {
  if (!presenceToggled) {
    return options.message;
  }
};

validate.extend(validate.validators.datetime, {
  parse: (value: moment.MomentInput) => {
    return +moment.utc(value);
  },
  format: (value: moment.MomentInput, options: { dateOnly: boolean }) => {
    let format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
    return moment.utc(value).format(format);
  },
});

export function validateOptionalEmail(email: string | null | undefined, options: any, key: string, attributes: any) {
  if (email) {
    let errors = validate.validate(attributes, { [key]: { email: options } }, { fullMessages: false });
    if (errors) {
      return errors[key];
    }
  }
}

validate.validators.optionalEmail = validateOptionalEmail;

export default {};
