var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import ErrorUtils from "./error-utils";
import React from "react";
let ErrorRenderingUtils = {
  getInitialState() {
    return {
      submissionTried: false
    };
  },
  prepareSubmit(func) {
    return (...args) => {
      this.setState({ submissionTried: true });
      func(...args);
    };
  },
  isValid() {
    return this.getValidationErrors().length === 0;
  },
  renderErrors() {
    if (this.state.submissionTried || this.isReadyToShowErrors && this.isReadyToShowErrors()) {
      return ErrorUtils.transformErrorsToHtml(this.getErrors());
    }
  },
  getErrors() {
    let errors = this.getServerErrors() || [];
    errors = errors.concat(this.getValidationErrors());
    if (this.getExtraErrors) {
      errors = errors.concat(this.getExtraErrors());
    }
    return errors;
  },
  getValidationErrors() {
    let errors = [];
    let constraints = this.getConstraints ? this.getConstraints() : this.props.constraints || this.state.constraints;
    if (constraints) {
      errors = errors.concat(ErrorUtils.getValidationErrorList(this.getModelState(), constraints));
    }
    if (this.getExtraValidationErrors) {
      errors = errors.concat(this.getExtraValidationErrors());
    }
    return errors;
  },
  displayErrors(errors) {
    if (errors) {
      return ErrorUtils.transformErrorsToHtml(errors);
    }
  },
  getErrorString(errors, prefix = "") {
    return `${prefix} ${errors.join(", ")}`;
  },
  getServerErrors() {
    let errors = this.state.serverErrors || [];
    if (this.state.status) {
      errors = errors.concat(this.state.status.get("msgs") || []);
    }
    return errors;
  },
  getModelState() {
    return this.state.data.toJS();
  },
  resetValidation() {
    this.setState({ submissionTried: false });
  },
  initValidation() {
    this.setState({ submissionTried: true });
  }
};
export default ErrorRenderingUtils;
export function ErrorUtilsHOC(ComposedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        submissionTried: false
      };
      this.initValidation = this.initValidation.bind(this);
      this.isDataValid = this.isDataValid.bind(this);
    }
    render() {
      return /* @__PURE__ */ React.createElement(
        ComposedComponent,
        __spreadProps(__spreadValues(__spreadValues({}, this.props), this.state), {
          initValidation: this.initValidation,
          resetValidation: this.resetValidation,
          isDataValid: this.isDataValid
        })
      );
    }
    resetValidation() {
      this.setState({ submissionTried: false });
    }
    initValidation() {
      this.setState({ submissionTried: true });
    }
    isDataValid(data, constraints) {
      return ErrorUtils.getValidationErrorList(data, constraints).length === 0;
    }
  };
}
