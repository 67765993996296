import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";

class SettingsApi extends BaseApi {
  static fetchCurrent() {
    return this.get(ApiUrls.mgrAppSettings);
  }

  static update(data: any) {
    return this.patch(ApiUrls.mgrAppSettings, data);
  }
}

export default SettingsApi;
