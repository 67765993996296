import classnames from "classnames";

import blockWithBaseClass from "../utils/withBaseClass";

export enum TextBlockSizes {
  XS = "pm-text-size-xs",
  SM = "pm-text-size-sm",
  MD = "pm-text-size-md",
  LG = "pm-text-size-lg",
  XL = "pm-text-size-xl",
  XXL = "pm-text-size-xxl",
  XXXL = "pm-text-size-xxxl"
}

export interface TextBlockProps {
  className?: string;
  size?: TextBlockSizes;
}

const TextBlock: React.FC<TextBlockProps> = props => {
  return blockWithBaseClass(props, classnames("pm-textblock", props.size));
};

export default TextBlock;
