export default class Storage {
  public static getItem(key: string) {
    return window.localStorage.getItem(key);
  }

  public static removeItem(key: string) {
    return window.localStorage.removeItem(key);
  }

  public static setItem(key: string, item: string) {
    return window.localStorage.setItem(key, item);
  }

  public static userPrefix(strings: TemplateStringsArray): string {
    return `${window.PM.user.id}:${strings.join("")}`;
  }
}
