import useSWR from "swr";
import { APIPrefix } from "../api";
import { axios } from "@pm-shared/axios";
import {
  OwnerServiceDisplay,
  OwnerServiceModify,
  OwnerServiceOptIn,
} from "@pm-property-management-hub/src/types/owner-services.types";
import { OptedInOwners } from "@pm-property-management-hub/src/types/owner-services.types";

export const createOwnerService = (data: OwnerServiceModify) => {
  return axios.post(`${APIPrefix}/owner-services/`, data);
};

export const updateOwnerService = (id: number, data: OwnerServiceModify) => {
  return axios.put(`${APIPrefix}/owner-services/${id}/`, data);
};

export const deleteOwnerService = (id: number) => {
  return axios.delete(`${APIPrefix}/owner-services/${id}/`);
};

export const fetchOwnerService = (id: number) => {
  const url = `${APIPrefix}/owner-services/${id}/`;
  return useSWR<OwnerServiceDisplay>(url, () => axios.get(url).then((response) => response.data), {
    revalidateOnFocus: false,
  });
};

export const publishModifyOwnerService = (id: number, data: any) => {
  return axios.put(`${APIPrefix}/owner-services/${id}/`, data);
};

export const fetchOwnerServices = () => {
  const url = `${APIPrefix}/owner-services/`;
  return useSWR<OwnerServiceDisplay[]>(url, () => axios.get(url).then((response) => response.data));
};

export const fetchUsersOptedInToService = (id: number) => {
  return useSWR<OptedInOwners[]>(`${APIPrefix}/owner-services/?search=${id}`, () =>
    axios.get(`${APIPrefix}/owner-services-opt-in/?search=${id}`).then((response) => response.data)
  );
};

export const deleteOptedInUserService = (id: number) => {
  return axios.delete(`${APIPrefix}/owner-services-opt-in/${id}/`);
};

export const ownerServiceOptIn = (data: OwnerServiceOptIn) => {
  return axios.post(`${APIPrefix}/owner-services-opt-in/`, data);
};
