import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";

class VendorMeldFileApi extends BaseApi {
  static create(data: any, meldId: number) {
    return this.post(ApiUrls.vendorMeldFileList(meldId), data);
  }

  static fetchFilesForMeld(meldId: number) {
    return this.get(ApiUrls.vendorMeldFileList(meldId));
  }

  static deleteFile(fileId: number) {
    return this.delete(ApiUrls.vendorMeldFileDetail(fileId));
  }

  static getPolicyDataForFile(file: File) {
    return this.get(ApiUrls.vendorMeldFileGeneratePolicy, {
      params: { filename: file.name }
    });
  }
}

export default VendorMeldFileApi;
