import alt from "../../alt";

import BaseStore, { StatusStates } from "../../stores/base-store";
import MeldSource from "../sources/meld-source";
import meldActions from "../actions/meld-actions";

import MeldUtils from "../utils/meld-utils";

export const ExtraActions = {
  ASSIGN_MAINTENANCE: "ASSIGN_MAINTENANCE",
  CANCEL: "CANCEL",
  MARK_COMPLETE: "MARK_COMPLETE",
  REVIEW: "REVIEW",
  ACCEPT: "ACCEPT",
  UPDATE_SEGMENTS: "UPDATE_SEGMENTS",
  ADD_NEW_VENDOR_APPOINTMENT: "ADD_NEW_VENDOR_APPOINTMENT",
};

export const VIEWS = {
  MANAGER_IN_PROGRESS: "MANAGER_IN_PROGRESS",
};

class MeldStore extends BaseStore {
  constructor(options) {
    super();

    this.registerAsync(MeldSource);

    this.bindListeners({
      fetchingAll: meldActions.FETCH_MELDS,
      fetchAllDone: meldActions.FETCH_MELDS_DONE,
      fetchAllFail: meldActions.FETCH_MELDS_FAIL,
      fetching: meldActions.FETCH_MELD,
      fetchFail: meldActions.FETCH_MELD_FAIL,
      fetchDone: meldActions.FETCH_MELD_DONE,
      fetchMeldsByUnitDone: meldActions.FETCH_MELDS_BY_UNIT_DONE,
      cancel: meldActions.cancel,
      cancelDone: meldActions.cancelDone,
      cancelFail: meldActions.cancelFail,
      markComplete: meldActions.MARK_COMPLETE,
      markCompleteDone: meldActions.MARK_COMPLETE_DONE,
      markCompleteFail: meldActions.MARK_COMPLETE_FAIL,
      review: meldActions.REVIEW,
      reviewDone: meldActions.REVIEW_DONE,
      reviewFail: meldActions.REVIEW_FAIL,
      updating: meldActions.UPDATE,
      updateDone: meldActions.UPDATE_DONE,
      accepting: meldActions.ACCEPT,
      acceptDone: meldActions.ACCEPT_DONE,
      acceptFail: meldActions.ACCEPT_FAIL,
      assignMaintenance: meldActions.ASSIGN_MAINTENANCE,
      assignMaintenanceDone: meldActions.ASSIGN_MAINTENANCE_DONE,
      assignMaintenanceFail: meldActions.ASSIGN_MAINTENANCE_FAIL,
      updateSegments: meldActions.UPDATE_SEGMENTS,
      updateSegmentsDone: meldActions.UPDATE_SEGMENTS_DONE,
      updateSegmentsFail: meldActions.UPDATE_SEGMENTS_FAIL,
      addNewVendorAppointmentDone: meldActions.ADD_NEW_VENDOR_APPOINTMENT_DONE,
      addNewVendorAppointmentFail: meldActions.ADD_NEW_VENDOR_APPOINTMENT_FAIL,
      rescheduleVendorAppointmentDone: meldActions.RESCHEDULE_VENDOR_APPOINTMENT_DONE,
      rescheduleVendorAppointmentFail: meldActions.RESCHEDULE_VENDOR_APPOINTMENT_FAIL,
    });

    this.exportPublicMethods({
      getMeldsByUnit: this.getMeldsByUnit.bind(this),
      getTenantInProgress: this.getTenantInProgress.bind(this),
      getTenantCompleted: this.getTenantCompleted.bind(this),
      getManagementInProgress: this.getManagementInProgress.bind(this),
      filter: this.filter.bind(this),
      getClosed: this.getClosed.bind(this),
    });
  }

  getMeldsByUnit(unitId) {
    let uId = parseInt(unitId);
    return this.getModelState().filter((meld) => meld.getIn(["unit", "id"]) === uId);
  }

  fetchDone(dispatchData) {
    super.clear();
    super.fetchDone(dispatchData);
  }

  fetchMeldsByUnitDone(melds) {
    this.receive(melds);
  }

  getTenantInProgress() {
    return this.getModelState().filter((meld) => MeldUtils.isInProgress(meld));
  }

  getTenantCompleted() {
    return this.getModelState().filter((meld) => MeldUtils.isCompleteForTenant(meld));
  }

  getManagementInProgress() {
    return this.getModelState().filter((meld) => MeldUtils.isInProgress(meld));
  }

  getClosed() {
    return this.getModelState().filter((meld) => MeldUtils.isClosed(meld));
  }

  filter(func) {
    return this.getModelState().filter(func);
  }

  cancel(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.CANCEL, StatusStates.PENDING);
  }

  cancelDone(dispatchData) {
    this.receiveOne(dispatchData.data);
    this.augmentAndMarkStatus(dispatchData, ExtraActions.CANCEL, StatusStates.DONE);
  }

  cancelFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.CANCEL, StatusStates.FAIL);
  }

  markComplete(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.MARK_COMPLETE, StatusStates.PENDING);
  }

  markCompleteFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.MARK_COMPLETE, StatusStates.FAIL);
  }

  markCompleteDone(dispatchData) {
    this.receiveOne(dispatchData.data);
    this.augmentAndMarkStatus(dispatchData, ExtraActions.MARK_COMPLETE, StatusStates.DONE);
  }

  review(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.REVIEW, StatusStates.PENDING);
  }

  reviewFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.REVIEW, StatusStates.FAIL);
  }

  reviewDone(dispatchData) {
    this.receiveOne(dispatchData.data);
    this.augmentAndMarkStatus(dispatchData, ExtraActions.REVIEW, StatusStates.DONE);
  }

  accepting(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.ACCEPT, StatusStates.PENDING);
  }

  acceptDone(dispatchData) {
    this.receiveOne(dispatchData);
    this.augmentAndMarkStatus(dispatchData, ExtraActions.ACCEPT, StatusStates.DONE);
  }

  acceptFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.ACCEPT, StatusStates.FAIL);
  }

  assignMaintenance(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.ASSIGN_MAINTENANCE, StatusStates.PENDING);
  }

  assignMaintenanceDone(dispatchData) {
    this.receiveOne(dispatchData);
    this.augmentAndMarkStatus(dispatchData, ExtraActions.ASSIGN_MAINTENANCE, StatusStates.DONE);
  }

  assignMaintenanceFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.ASSIGN_MAINTENANCE, StatusStates.FAIL);
  }

  updateSegments(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.UPDATE_SEGMENTS, StatusStates.PENDING);
  }

  updateSegmentsDone(dispatchData) {
    this.receiveOne(dispatchData);
    this.augmentAndMarkStatus(dispatchData, ExtraActions.UPDATE_SEGMENTS, StatusStates.DONE);
  }

  updateSegmentsFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.UPDATE_SEGMENTS, StatusStates.FAIL);
  }

  addNewVendorAppointmentDone(dispatchData) {
    this.receiveOne(dispatchData);
    this.augmentAndMarkStatus(dispatchData, ExtraActions.ADD_NEW_VENDOR_APPOINTMENT, StatusStates.FAIL);
  }
  addNewVendorAppointmentFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.ADD_NEW_VENDOR_APPOINTMENT, StatusStates.FAIL);
  }
  rescheduleVendorAppointmentDone(dispatchData) {
    this.receiveOne(dispatchData);
    this.augmentAndMarkStatus(dispatchData, ExtraActions.RESCHEDULE_VENDOR_APPOINTMENT, StatusStates.FAIL);
  }
  rescheduleVendorAppointmentFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.RESCHEDULE_VENDOR_APPOINTMENT, StatusStates.FAIL);
  }
}

export default alt.createStore(MeldStore, "MeldStore");
