import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";

class ClientApi extends BaseApi {
  static getReload(options: any) {
    return this.get(ApiUrls.clientReload, { params: options });
  }
}

export default ClientApi;
