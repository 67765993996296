import BaseApi from "../../utils/base-api";
import {
  estimateLineItemsURL,
  updateEstimateLineItems,
  estimateURL
} from "@pm-assets/js/meld/apis/estimates-api";
import axios from "axios";

class EstimateLineItemsApi extends BaseApi {
  static createLineItems(estimateID: number, lineItems: Map<string, any>) {
    let url = estimateLineItemsURL(estimateID)
    let xhrOptions = {headers : this._getAdditionalHeaders()}
    return axios.post(url, lineItems, xhrOptions);
  }

  static update(estimateID: number, lineItems: Map<string, any>) {
    let url = updateEstimateLineItems(estimateID)
    let xhrOptions = {headers : this._getAdditionalHeaders()}
    let payload = {line_items: lineItems}
    return axios.patch(url, payload, xhrOptions);
  }

  static updateEstimate(estimateID: number, data: Map<string, any> | any) {
    let url = estimateURL(estimateID)
    let xhrOptions = {headers : this._getAdditionalHeaders()}
    return axios.patch(url, data, xhrOptions);
  }
}

export default EstimateLineItemsApi;
