import React from "react";
import { colors } from "@pm-frontend/styles";

export const SidebarDashboardIcon = ({ isActive = false }: { isActive?: boolean }) => {
  const fill = isActive ? colors.brand.meldBlue : colors.neutrals.gray800;
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <g id="Group_2">
          <path
            id="Vector"
            d="M12.6667 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2ZM12.6667 12.6667H3.33333V3.33333H12.6667V12.6667Z"
            fill={fill}
          />
          <path id="Vector_2" d="M5.99984 8H4.6665V11.3333H5.99984V8Z" fill={fill} />
          <path id="Vector_3" d="M11.3333 4.66675H10V11.3334H11.3333V4.66675Z" fill={fill} />
          <path id="Vector_4" d="M8.66683 9.33325H7.3335V11.3333H8.66683V9.33325Z" fill={fill} />
          <path id="Vector_5" d="M8.66683 6.66675H7.3335V8.00008H8.66683V6.66675Z" fill={fill} />
        </g>
      </g>
    </svg>
  );
};
