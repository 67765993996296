import React from "react";

import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from "@elastic/eui";
import { colors } from "@pm-frontend/styles";
import URL from "@pm-shared/utils/url";

export interface PmCalloutProps {
  message: React.ReactNode;
  body?: React.ReactNode;
  "data-testid"?: string;
  type?: "danger" | "normal" | "subdued" | "black";
  background?: string;
  borderColor?: string;
}

const PmCallout = ({
  message,
  "data-testid": dataTestId,
  body,
  type = "normal",
  background = colors.neutrals.gray50,
  borderColor = colors.neutrals.gray100,
}: PmCalloutProps) => {
  let textColor: string = colors.brand.meldBlue;
  let icon = URL.getStatic("icons/exclamation_in_circle.svg");
  if (type === "danger") {
    textColor = colors.interface.red.default;
    icon = URL.getStatic("icons/delete.svg");
  } else if (type === "subdued") {
    textColor = colors.neutrals.gray700;
    icon = URL.getStatic("icons/exclamation_in_circle_gray.svg");
  } else if (type === "black") {
    textColor = colors.neutrals.gray800;
    icon = URL.getStatic("icons/exclamation_in_circle_gray.svg");
  }

  const style: React.CSSProperties = {
    gap: "16px",
    background,
    border: `1px solid ${borderColor}`,
    borderRadius: "2px",
    padding: "16px",
  };

  let formattedMessage: React.ReactNode;
  if (typeof message === "string") {
    formattedMessage = (
      <EuiText color={textColor} size="s" style={{ fontWeight: 600, whiteSpace: "pre-wrap" }}>
        {message}
      </EuiText>
    );
  } else {
    formattedMessage = message;
  }

  return (
    <EuiFlexGroup direction="column" style={style} alignItems="flexStart">
      <EuiFlexItem grow={false}>
        <EuiFlexGroup direction="row" alignItems="center" gutterSize="s" responsive={false}>
          <EuiFlexItem grow={true} style={{ minWidth: "20px" }}>
            <EuiIcon type={icon} />
          </EuiFlexItem>
          <EuiFlexItem grow={false} {...(dataTestId ? { "data-testid": dataTestId } : {})}>
            {formattedMessage}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      {body ? <EuiFlexItem grow={false}> {body} </EuiFlexItem> : null}
    </EuiFlexGroup>
  );
};

export { PmCallout };
