import { Handler } from "mitt";
import I from "immutable";

function createModelStore(store: new () => any) {
  return class extends store {
    on: (handler: Handler) => void;
    off: (handler: Handler) => void;

    constructor() {
      super();

      this.state.models = I.List();
    }

    getAll() {
      return this.state.models;
    }

    getOne(id: any) {
      id = Number.parseInt(id, 10);
      return this.state.models.find((m: { id: number }) => m.id === id);
    }

    beginTransaction() {
      this._models = this.state.models;
    }

    abortTransaction() {
      if (this._models) {
        this.setState({ models: this._models });
        this._models = null;
      }
    }
  };
}

export default createModelStore;
