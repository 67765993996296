import alt from "../../alt";

import BaseStore, { StatusStates } from "../../stores/base-store";
import SettingsActions from "../actions/settings-actions";
import SettingsSource from "../sources/settings-source";

export let ExtraActions = {
  FETCH_CURRENT: "FETCH_CURRENT"
};

class SettingsStore extends BaseStore {
  constructor(options) {
    super(options);

    this.bindListeners({
      fetchingCurrent: SettingsActions.FETCH_CURRENT,
      fetchingCurrentDone: SettingsActions.FETCH_CURRENT_DONE,
      fetchingCurrentFail: SettingsActions.FETCH_CURRENT_FAIL,
      updating: SettingsActions.UPDATE,
      updateDone: SettingsActions.UPDATE_DONE,
      updateFail: SettingsActions.UPDATE_FAIL
    });

    this.exportPublicMethods({
      getCurrent: this.getCurrent.bind(this)
    });

    this.registerAsync(SettingsSource);
  }

  fetchingCurrent(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.FETCH_CURRENT, StatusStates.PENDING);
  }

  fetchingCurrentDone(dispatchData) {
    this.receiveOne(dispatchData);

    this.augmentAndMarkStatus(dispatchData, ExtraActions.FETCH_CURRENT, StatusStates.DONE);
  }

  fetchingCurrentFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, ExtraActions.FETCH_CURRENT, StatusStates.FAIL);
  }

  getCurrent() {
    return this.getModelState().first();
  }
}

export default alt.createStore(SettingsStore, "SettingsStore");
