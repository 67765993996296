var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { authParams, mApi, tApi, vApi } from "../../common/api";
import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";
import { LinkHelper } from "@pm-shared/utils/link";
import { ErrorHandler } from "@pm-app/utils/ErrorHandler";
class MeldAPI extends BaseApi {
  static cancel(data, id) {
    return this.patch(ApiUrls.meldCancel(id), data);
  }
  static markComplete(id, data) {
    return this.patch(ApiUrls.completeMeld(id), data);
  }
  static review(id, data) {
    return this.patch(ApiUrls.reviewMeld(id), data);
  }
  static getAll(options) {
    return this.get(ApiUrls.meldsList, options);
  }
  static getOne(id) {
    const isManager = LinkHelper.getOrgType() === "m";
    const isTenant = LinkHelper.getOrgType() === "t";
    const isVendor = LinkHelper.getOrgType() === "v";
    if (isManager) {
      return mApi.mOIApiMeldsRead(__spreadValues({
        id: typeof id === "number" ? id : parseInt(id, 10)
      }, authParams));
    } else if (isTenant) {
      return tApi.tOIApiMeldsRead(__spreadValues({
        id: typeof id === "number" ? id.toString() : id
      }, authParams));
    } else if (isVendor) {
      return vApi.vOIApiMeldsRead(__spreadValues({ id: id.toString() }, authParams));
    } else {
      ErrorHandler.handleError(Error("Unknown org type when trying to getOne meld"));
    }
    return mApi.mOIApiMeldsRead(__spreadValues({ id: typeof id === "number" ? id : parseInt(id, 10) }, authParams));
  }
  static getOneRecurringMeld(id) {
    return mApi.mOIApiMeldsRecurringRead(__spreadValues({
      id: typeof id === "number" ? id : parseInt(id, 10)
    }, authParams));
  }
  static updateRecurringMeld(ticket) {
    return mApi.mOIApiMeldsRecurringPartialUpdate(__spreadValues({ id: ticket.id, data: ticket }, authParams));
  }
  static update(ticket) {
    return mApi.mOIApiMeldsPartialUpdate(__spreadValues({ id: ticket.id, data: ticket }, authParams));
  }
  static updateTags(ticket) {
    return this.patch(ApiUrls.meldTags(ticket.id), ticket);
  }
  static fetchMeldsByUnit(unitId) {
    return mApi.mOIApiMeldsUnitRead(__spreadValues({ id: unitId.toString() }, authParams));
  }
  static fetchMeldsByProp(propId) {
    return mApi.mOIApiMeldsPropertyRead(__spreadValues({ id: propId.toString() }, authParams));
  }
  static fetchMeldsByTenant(tenantId) {
    return this.get(ApiUrls.tenantMeldList(tenantId));
  }
  static fetchMeldsByVendor(vendorId) {
    return this.get(ApiUrls.vendorMeldList(vendorId));
  }
  static fetchFinishableTickets(meldId) {
    return this.get(ApiUrls.finishableTicketList(meldId));
  }
  static accept(meldId, data, options) {
    return this.patch(ApiUrls.managerScheduleMeld(meldId), data, options);
  }
  static create(data) {
    return mApi.mOIApiMeldsCreate(__spreadValues({ data }, authParams));
  }
  static createTenantMeld(data) {
    return tApi.tOIApiMeldsCreate(__spreadValues({ data }, authParams));
  }
  static updateTenantMeld(data) {
    if (data.id) {
      return tApi.tOIApiMeldsUpdate(__spreadValues({ id: data.id.toString(), data }, authParams));
    }
  }
  static assignMaintenance(id, maint) {
    return this.patch(ApiUrls.assignMaintenance(id), maint);
  }
  static assignCoordinator(ticket) {
    return this.patch(ApiUrls.assignCoordinator(ticket.id), ticket);
  }
  static updateSegments(id, data, options) {
    return this.patch(ApiUrls.meldRescheduleSegments(id), data, options);
  }
  static updateTenants(meldId, tenants) {
    return this.patch(ApiUrls.meldTenants(meldId), { tenants });
  }
  static updateUserGroups(meldId, userGroups) {
    return this.patch(ApiUrls.updateMeldUserGroups(meldId), {
      user_groups: userGroups
    });
  }
  static fetchForCalendar({ url, xhrOptions } = {}) {
    if (url) {
      return this.get(url, __spreadValues({ normalizeUrl: false }, xhrOptions));
    }
    return this.get(ApiUrls.calendarMelds, xhrOptions);
  }
  static fetchOneForCalendar(id, xhrOptions) {
    return this.get(ApiUrls.calendarMeldDetail(id), xhrOptions);
  }
  static shareMeldFiles(id, data) {
    return this.patch(ApiUrls.shareMeldFiles(id), data);
  }
  static downloadMeldFiles(id, data) {
    return this.post(ApiUrls.downloadMeldFiles(id), data);
  }
  static addNewVendorAppointment(id, data, options) {
    return this.post(ApiUrls.addNewVendorAppointment(id), data, options);
  }
  static rescheduleVendorAppointment(id, data, options) {
    return this.patch(ApiUrls.rescheduleVendorAppointment(id), data, options);
  }
  static cancelVendorAppointment(id, data) {
    return this.patch(ApiUrls.cancelVendorAppointment(id), data);
  }
}
export default MeldAPI;
