import vendorAppointmentActions from "../actions/vendor-appointment-actions";
import vendorAppointmentApi from "../apis/vendor-appointment-api";

let VendorAppointmentSource = {
  fetchOne() {
    return {
      remote(state, id, options) {
        return vendorAppointmentApi.fetchOne(id, options);
      },

      shouldFetch(state, force) {
        return true;
      },

      success: vendorAppointmentActions.fetchOneDone,
      error: vendorAppointmentActions.fetchOneFail
    };
  }
};

export default VendorAppointmentSource;
