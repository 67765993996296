import React from "react";
import { ImmutableMap } from "../../types/common";

export interface Injected {
  previewingFile?: ImmutableMap;
  navFile: (file: ImmutableMap) => void;
  closePreview: () => void;
}

export default function withPreviewableFiles<ChildProps>(Comp: React.ComponentType<ChildProps & Injected>) {
  type ContainerProps = ChildProps;

  interface State {
    previewingFile?: ImmutableMap;
  }

  return class WithPreviewableFiles extends React.Component<ContainerProps, State> {
    constructor(props: any) {
      super(props);

      this.state = {};
      this.handleNavFile = this.handleNavFile.bind(this);
      this.handleClosePreview = this.handleClosePreview.bind(this);
    }

    render(): JSX.Element {
      return (
        <Comp
          {...this.props}
          previewingFile={this.state.previewingFile}
          navFile={this.handleNavFile}
          closePreview={this.handleClosePreview}
        />
      );
    }

    handleNavFile(file: ImmutableMap) {
      this.setState({ previewingFile: file });
    }

    handleClosePreview() {
      this.setState({ previewingFile: undefined });
    }
  };
}
