var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Flex,
  Text,
  useToast,
  ModalFooter,
  Select,
  Box
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { MdTranslate } from "react-icons/md";
const languageMap = {
  EN: "English",
  ES: "Spanish",
  ZH: "Chinese",
  KO: "Korean",
  PT: "Portuguese",
  FR: "French",
  RU: "Russian",
  JA: "Japanese"
};
export const LanguageSelectModal = ({ userDetails, updateLanguageSettings, tenant }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("EN");
  const [languageSet, setLanguageSet] = useState(false);
  const toast = useToast();
  const history = useHistory();
  useEffect(() => {
    if (userDetails && !localStorage.getItem(`languageSet_${userDetails.id}`)) {
      if (!userDetails.default_language) {
        if (!userDetails.prompt_for_mobile) {
          setIsOpen(true);
        }
      }
    }
  }, [userDetails]);
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleNavigateToSettings = () => {
    const multiTenancy = location.pathname.split("/");
    const meldRoutePrefix = `/${multiTenancy[1]}/${multiTenancy[2]}/${multiTenancy[3]}`;
    history.push(`${meldRoutePrefix}/account-settings/language/`);
    handleClose();
  };
  const handleSuccess = () => {
    localStorage.setItem(`languageSet_${userDetails.id}`, "true");
    setLanguageSet(true);
  };
  const handleError = () => {
    toast({
      title: "Error",
      description: "There was an error setting your language preference. Please contact support.",
      status: "error",
      position: "top",
      duration: 5e3,
      isClosable: true
    });
    handleClose();
  };
  const updateSettingsWithLanguage = (language) => __async(void 0, null, function* () {
    setSubmitting(true);
    try {
      if (tenant) {
        yield updateLanguageSettings(language);
      } else {
        const updatedSettings = __spreadProps(__spreadValues({}, userDetails), { default_language: language });
        yield updateLanguageSettings(updatedSettings);
      }
      handleSuccess();
    } catch (error) {
      handleError();
    } finally {
      setSubmitting(false);
    }
  });
  return /* @__PURE__ */ React.createElement(Modal, { isOpen, onClose: handleClose, closeOnOverlayClick: false, "data-test": "language-modal" }, /* @__PURE__ */ React.createElement(ModalOverlay, null), /* @__PURE__ */ React.createElement(ModalContent, null, /* @__PURE__ */ React.createElement(ModalHeader, null, /* @__PURE__ */ React.createElement(Flex, { align: "center" }, /* @__PURE__ */ React.createElement(MdTranslate, { style: { color: "var(--brand-meld-blue, #1175CC)" } }), /* @__PURE__ */ React.createElement(Text, { marginLeft: 4, "data-test": "header-text" }, languageSet ? "Language Set!" : "Preferred Language"))), /* @__PURE__ */ React.createElement(ModalBody, { mt: -6 }, !languageSet && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Text, { "data-test": "modal-body-text", fontSize: 16 }, "Property Meld offers some features for other languages. Please confirm your preferred language."), /* @__PURE__ */ React.createElement(
    Select,
    {
      placeholder: "Select language",
      value: selectedLanguage || "",
      onChange: (e) => setSelectedLanguage(e.target.value),
      isDisabled: submitting,
      marginTop: 4,
      "data-test": "language-select"
    },
    Object.entries(languageMap).map(([key, value]) => /* @__PURE__ */ React.createElement("option", { key, value: key }, value))
  )), languageSet && /* @__PURE__ */ React.createElement(Text, { "data-test": "modal-body-text", fontSize: 16 }, selectedLanguage && `Your preferred language for chat communication has been set to ${languageMap[selectedLanguage]}.`)), /* @__PURE__ */ React.createElement(ModalFooter, null, /* @__PURE__ */ React.createElement(Box, { display: "flex", justifyContent: "flex-end" }, languageSet && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Button,
    {
      colorScheme: "blue",
      cursor: "pointer",
      onClick: handleNavigateToSettings,
      mr: 3,
      "data-test": "go-to-settings-button"
    },
    "Go to Settings"
  ), /* @__PURE__ */ React.createElement(
    Button,
    {
      variant: "ghost",
      onClick: handleClose,
      "data-test": "close-button",
      colorScheme: "blue",
      cursor: "pointer"
    },
    "Close"
  )), !languageSet && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    Button,
    {
      colorScheme: "blue",
      isLoading: submitting,
      onClick: () => selectedLanguage && updateSettingsWithLanguage(selectedLanguage),
      "data-test": "confirm-button",
      mr: 3,
      cursor: "pointer",
      disabled: !selectedLanguage
    },
    "Confirm"
  ))))));
};
