export const AuthUtils = {
  getActiveOrg() {
    return window.PM?.user?.activeOrgId as number;
  },

  getActiveOrgType() {
    return window.PM?.user?.orgType;
  },

  getUserId() {
    return window.PM?.user?.id;
  },

  getUser() {
    return window.PM?.user;
  },

  getUserOwnerHubAccessLevel() {
    return window.PM?.user?.hub_access_level;
  },

  getUserSecret() {
    return window.PM?.user?.secret;
  },

  getUserContact() {
    return window.PM?.user?.contact;
  },

  getUserName() {
    return window.PM?.user?.name;
  },

  getUserNotificationSettings() {
    return window.PM?.user?.notification_settings;
  },

  isStale() {
    return window.PM?.is_stale;
  },

  getSecretMeld() {
    return window.PM?.secret_meld;
  },

  getSecretEstimateList() {
    return window.PM?.secret_meld_estimates;
  },

  getUserMultitenant() {
    return window.PM?.user?.multitenant;
  },

  getUserOwnerId() {
    return window.PM?.user?.owner_id;
  },

  getUserEmail() {
    return window.PM?.user?.email;
  },

  getManagementName() {
    return window.PM?.management_name || "NA";
  },

  getCsrfToken() {
    return window.PM.csrf_token;
  },

  getAnnouncement() {
    return window.PM?.announcement;
  },

  isAuthenticated() {
    return window.PM.user.isAuth;
  },

  hasMultiOrgs() {
    return window.PM.user.hasMultiOrgs;
  },
  getAccountKey() {
    return `${window.location.pathname.split("/").slice(1, 4).join("-")}`;
  },
  getManagementTimezone() {
    return window.PM?.management_timezone;
  },
};
