import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";
import AltContainer from "alt-container";
import classnames from "classnames";

import SetIntervalMixin from "../../mixins/set-interval-mixin";
import WindowClickedMixin from "../../window-clicked-mixin";
import messageActions from "../actions/message-actions";
import messageStore, { MessageTypes } from "../stores/message-store";

let GlobalMessage = createReactClass({
  mixins: [SetIntervalMixin, WindowClickedMixin],

  propTypes: {
    message: PropTypes.object
  },

  render() {
    let { body, type } = this.props.message || {};

    let messageClasses = classnames({
      columns: true,
      "small-12": true,
      "global-message": true,
      display: body,
      error: type === MessageTypes.ERROR,
      success: type === MessageTypes.SUCCESS,
      info: type === MessageTypes.INFO
    });

    return (
      <div className="row" onClick={e => e.stopPropagation()}>
        <div className={messageClasses}>
          <span className="message-body">{body}</span>
          {this.renderReloadMsg()}
        </div>
      </div>
    );
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.message !== nextProps.message) {
      clearTimeout(this.currentTimeout);
      this.currentTimeout = setTimeout(() => messageActions.markOldest(), 15000);
    }
  },

  handleWindowClicked() {
    clearTimeout(this.currentTimeout);
    messageActions.markOldest();
  },

  renderReloadMsg() {
    if (this.props.message && this.props.message.type === MessageTypes.EXCEPTION) {
      return (
        <a href="" className="try-reload">
          {" "}
          Try reloading
        </a>
      );
    }
  }
});

export { GlobalMessage };

export default createReactClass({
  render() {
    return (
      <AltContainer
        stores={{
          message() {
            return {
              store: messageStore,
              value: messageStore.getNext()
            };
          }
        }}
      >
        <GlobalMessage />
      </AltContainer>
    );
  }
});
