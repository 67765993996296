var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { showRedesignNavigation } from "@pm-assets/js/utils/redesign-routing";
export var BackgroundColor = /* @__PURE__ */ ((BackgroundColor2) => {
  BackgroundColor2["White"] = "background-white";
  BackgroundColor2["Grey"] = "background-grey";
  BackgroundColor2["LightGrey"] = "background-light-grey";
  return BackgroundColor2;
})(BackgroundColor || {});
const AppContext = React.createContext({});
export class AppContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: {
        setBackgroundColor: this.setBackgroundColor.bind(this),
        setAlert: this.setAlert.bind(this),
        clearAlert: this.clearAlert.bind(this)
      },
      backgroundColor: "background-white" /* White */,
      alert: void 0
    };
  }
  setBackgroundColor(backgroundColor) {
    if (showRedesignNavigation() && backgroundColor === "background-grey" /* Grey */) {
      return;
    }
    this.setState({ backgroundColor });
  }
  setAlert(alert) {
    this.setState({ alert });
  }
  clearAlert() {
    this.setState({ alert: void 0 });
  }
  render() {
    const context = this.state;
    return /* @__PURE__ */ React.createElement(AppContext.Provider, { value: __spreadValues({}, context) }, this.props.children);
  }
}
export default AppContext;
