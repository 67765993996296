const { format, parseISO } = require("date-fns");
// import moment from "moment";

// in case we want to revert
// const formatDateMMDDYYYY = (date: Date, dayMonthFormat?: string, emptyMsg = "") => {
// if (!date) {
//   return emptyMsg;
// }
// let mom = moment(date);
// let format = dayMonthFormat;
//
// if (!format) {
//   format = "MM/DD/YYYY";
// }
//
// return mom.format(format);
// };

const getDate = (date: Date | string): Date => {
  let dateObject: Date;
  if (date instanceof Date) {
    dateObject = date;
  } else {
    dateObject = new Date(date);
  }
  return dateObject;
};

// Example on how it will look like: 3:08 pm
const formatTimeHHMM_xm = (date: Date | string, emptyMsg = ""): string => {
  const dateObject = getDate(date);
  if (dateObject.toString() === "Invalid Date") {
    return emptyMsg;
  }
  return format(dateObject, "h:mm aaa");
};

// Example on how it will look like: January 31, 2024, 3:08 pm
const formatDateTimeMonthDDYYYYHHMM_xm = (date: Date | string, emptyMessage = ""): string => {
  try {
    const dateObject = parseISO(date);
    return format(dateObject, "MMMM d, yyyy, h:mm aaa");
  } catch {
    return emptyMessage;
  }
};

const formatFutureEventDateStart = (eventData: any) => {
  if (eventData && eventData.dtstart) {
    const eventStart = new Date(eventData.dtstart);
    const now = new Date();
    if (eventStart >= now) {
      return formatDateTimeMonthDDYYYYHHMM_xm(eventData.dtstart);
    }
  }
  return null;
};

const formatFutureEventDateEnd = (eventData: any) => {
  if (eventData && eventData.dtend) {
    const eventEnd = new Date(eventData.dtend);
    const now = new Date();
    if (eventEnd >= now) {
      return formatTimeHHMM_xm(eventData.dtend);
    }
  }
  return null;
};

const formatPastEventDateStart = (eventData: any) => {
  if (eventData && eventData.dtstart) {
    const eventStart = new Date(eventData.dtstart);
    const now = new Date();
    if (eventStart < now) {
      return formatDateTimeMonthDDYYYYHHMM_xm(eventData.dtstart);
    }
  }
  return null;
};

const formatPastEventDateEnd = (eventData: any) => {
  if (eventData && eventData.dtend) {
    const eventEnd = new Date(eventData.dtend);
    const now = new Date();
    if (eventEnd < now) {
      return formatTimeHHMM_xm(eventData.dtend);
    }
  }
  return null;
};

const allEventDateData = (eventData: any): string | null => {
  if (eventData && eventData.dtstart && eventData.dtend) {
    const startTime = formatDateTimeMonthDDYYYYHHMM_xm(eventData.dtstart);
    const endTime = formatTimeHHMM_xm(eventData.dtend);
    const result = `${startTime} - ${endTime}`;

    return result;
  }
  return null;
};

// Example on how it will look like: January 31, 2024
const formatDateMonthDDYYYY = (date: Date | string, emptyMsg = ""): string => {
  const dateObject = getDate(date);
  if (dateObject.toString() === "Invalid Date") {
    return emptyMsg;
  }
  return format(dateObject, "LLLL do, yyyy");
};

// Example on how it will look like: 12/22/1999
const formatDateMMDDYYYY = (date: Date | string, emptyMsg = ""): string => {
  const dateObject = getDate(date);
  if (dateObject.toString() === "Invalid Date") {
    return emptyMsg;
  }
  return dateObject.toLocaleDateString("en-us");
};

// Example on how it will look like: 12/22/1999, 12:45 am
const formatDateTimeMMDDYYYYHHMM_xm = (date: Date | string, emptyMessage = ""): string => {
  const dateObject = getDate(date);
  if (dateObject.toString() === "Invalid Date") {
    return emptyMessage;
  }
  return format(dateObject, "MM/d/yyyy, h:mm aaa");
};

export {
  formatTimeHHMM_xm,
  formatDateTimeMonthDDYYYYHHMM_xm,
  formatDateMonthDDYYYY,
  formatDateMMDDYYYY,
  formatDateTimeMMDDYYYYHHMM_xm,
  formatFutureEventDateStart,
  formatFutureEventDateEnd,
  formatPastEventDateEnd,
  formatPastEventDateStart,
  allEventDateData,
};
