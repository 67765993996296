import { authParams, vApi } from "../../common/api";
import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";

class AssignmentRequestAPI extends BaseApi {
  static vendorReject(id: number, reason: string) {
    return vApi.vOIApiAssignmentsRejectPartialUpdate({
      id,
      data: { reject_reason: reason },
      ...authParams
    });
  }

  static vendorAccept(id: number) {
    return this.patch(ApiUrls.vendorAcceptAssignment(id));
  }

  static fetchPending(options: any) {
    return this.get(ApiUrls.assignmentRequestList, options);
  }

  static fetchOne(id: number, options: any) {
    return this.get(ApiUrls.assignmentRequestDetail(id), options);
  }

  static updateSegments(id: number, data: any, options: any) {
    return this.patch(ApiUrls.vendorUpdateSegments(id), data, options);
  }
}

export default AssignmentRequestAPI;
