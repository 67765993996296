import React from "react";

import { hasPerm } from "../../../assets/js/common/utils/permission-utils";
import { ImmutableMap } from "../../../assets/js/types/common";
import Link, { LinkHelper } from "@pm-shared/utils/link";
import ManagementAgentUtils from "../../../assets/js/management/utils/agent-utils";
import OwnerUtils from "../../../assets/js/property/utils/owner-utils";
import { Perms } from "../../../assets/js/constants";
import TenantUtils from "../../../assets/js/tenant/utils/tenant-utils";
import VendorAgentUtils from "../../../assets/js/vendor/utils/agent-utils";

export interface UserNameProps {
  managementAgent?: ImmutableMap;
  owner?: ImmutableMap;
  tenant?: ImmutableMap;
  vendorAgent?: ImmutableMap;
}

function UserName(props: UserNameProps) {
  let name;
  let link;

  if (props.managementAgent) {
    name = ManagementAgentUtils.getFullName(props.managementAgent, props.managementAgent.getIn(["management", "name"]));

    if (LinkHelper.getOrgType() === "m" && hasPerm(Perms.CAN_VIEW_AGENTS)) {
      link = "/users/";
    }
  } else if (props.owner) {
    name = OwnerUtils.getName(props.owner);

    if (LinkHelper.getOrgType() === "m" && hasPerm(Perms.CAN_VIEW_UNITS)) {
      link = `/properties/owners/${props.owner.get("id")}/summary/`;
    }
  } else if (props.tenant) {
    name = TenantUtils.getFullName(props.tenant);

    if (LinkHelper.getOrgType() === "m" && hasPerm(Perms.CAN_VIEW_TENANTS)) {
      link = `/tenants/${props.tenant.get("id")}/`;
    }
  } else if (props.vendorAgent) {
    name = VendorAgentUtils.getFullName(props.vendorAgent, "");
    const vendorName = props.vendorAgent.getIn(["vendor", "name"]);

    name = name ? `${name} \u25CF ${vendorName}` : vendorName;

    if (LinkHelper.getOrgType() === "m" && hasPerm(Perms.CAN_VIEW_VENDORS)) {
      link = `/vendors/${props.vendorAgent.getIn(["vendor", "id"])}/summary/`;
    }
  }

  if (link) {
    return <Link to={link}>{name}</Link>;
  } else {
    return name;
  }
}

export default UserName;
