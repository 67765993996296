import createReactClass from "create-react-class";
import React from "react";

/* tslint:disable:max-line-length */
let Document = createReactClass<{ classes?: string; width?: string; height?: string }, {}>({
  render() {
    let width = this.props.width || "89";
    let height = this.props.height || "98";
    return (
      <svg
        className={"document-svg " + (this.props.classes || "")}
        width={width}
        height={height}
        viewBox="0 0 89 98"
        xmlns="https://www.w3.org/2000/svg"
      >
        <title>ZIP Copy</title>
        <g fill="none" fillRule="evenodd">
          <path d="M10.75 29.29L2.135 41.058h9.732L10.75 29.29z" fill="#FFF" />
          <path
            d="M5.287 43.197l1.555-.168c2.19-.24 4.02-2.223 4.068-4.43l.105-4.92c.048-2.202-.942-2.533-2.21-.727L3.602 40.36c-1.267 1.803-.514 3.076 1.685 2.837zm.323 2.983c-4.76.516-7.215-3.626-4.463-7.545l5.203-7.407c2.974-4.234 7.776-2.645 7.665 2.517l-.106 4.92c-.08 3.712-3.054 6.946-6.744 7.346l-1.556.17z"
            id="Shape"
            fill="#707F8B"
          />
          <path d="M77.608 29.29l8.618 11.767h-9.733l1.115-11.766z" fill="#FFF" />
          <path
            d="M83.673 44.06c2.132.568 2.91-.49 1.734-2.372l-5.382-8.606c-1.175-1.877-2.088-1.604-2.04.598l.105 4.92c.048 2.207 1.824 4.46 3.946 5.024l1.637.436zm-.772 2.9l-1.636-.437c-3.415-.91-6.097-4.32-6.173-7.86l-.105-4.918c-.113-5.258 4.798-6.707 7.583-2.253l5.382 8.605c2.614 4.18-.278 8.133-5.05 6.862z"
            id="Shape"
            fill="#707F8B"
          />
          <path d="M11 3h45.447l7.955 8.645L76 23.34V95H11V3z" fill="#FFF" />
          <path
            d="M11.6 4.3v89c0 .554.447 1 1.002 1h61.996c.55 0 1.002-.45 1.002-.992v-67.78c0-.306-.316-1.07-.534-1.29l-12.89-12.933-7.402-7.475c-.21-.212-.972-.53-1.273-.53H12.6c-.558 0-1 .443-1 1zm-3 0c0-2.21 1.78-4 4-4h40.9c1.105 0 2.634.64 3.406 1.42l7.4 7.474L77.19 22.12c.78.782 1.41 2.308 1.41 3.407v67.78c0 2.206-1.8 3.993-4.002 3.993H12.602c-2.21 0-4.002-1.787-4.002-4v-89z"
            id="Shape"
            fill="#707F8B"
          />
          <path d="M56.095 4.024l19.437 20.382H56.095V4.024z" fill="#CCCED5" />
          <path
            d="M73.712 23.474h-15.49c-.83 0-1.5-.67-1.5-1.504V5.76l16.99 17.714zm-19.99-20.29V21.97c0 2.49 2.013 4.504 4.5 4.504h17.912c1.86 0 2.69-1.906 1.388-3.26L56.995 1.854c-1.327-1.38-3.273-.567-3.273 1.33z"
            id="Shape"
            fill="#707F8B"
          />
          <path fill="#CCCED5" d="M2 40h84v35H2z" />
          <path
            d="M3 42.99v28.02c0 .546.445.99.997.99h81.006c.547 0 .997-.448.997-.99V42.99c0-.546-.445-.99-.997-.99H3.997c-.547 0-.997.448-.997.99zm-3 0C0 40.786 1.798 39 3.997 39h81.006C87.21 39 89 40.785 89 42.99v28.02c0 2.204-1.798 3.99-3.997 3.99H3.997C1.79 75 0 73.215 0 71.01V42.99z"
            fill="#707F8B"
          />
        </g>
      </svg>
    );
  },
});
/* tslint:enable:max-line-length */

export default Document;
