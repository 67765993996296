import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSuperSelect, EuiSuperSelectProps, EuiText } from "@elastic/eui";
import { css } from "@emotion/react";

import { colors } from "@pm-frontend/styles";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";

interface SiteSearchDesktopFiltersBaseProps {
  searchFilterType: string;
  setSearchFilterType: (newFilter: string) => void;
  filtersToShow: string[];
  appType?: "management" | "vendor";
}

const labelCss = css`
  & {
    div {
      label.euiFormLabel {
        font-size: 14px;
        color: ${colors.neutrals.gray800};
      }
    }
  }
`;

const getDisplay = (text: NonNullable<string>) => {
  return (
    <EuiText size="s" color={colors.neutrals.gray800} style={{ fontFamily: "Open Sans" }}>
      {text.charAt(0).toUpperCase() + text.slice(1)}
    </EuiText>
  );
};

const getMobileFilterOptions = (items: string[]): EuiSuperSelectProps["options"] => {
  return items.map((item) => ({
    inputDisplay: getDisplay(item),
    dropdownDisplay: getDisplay(item),
    value: item,
  }));
};

function SiteSearchDesktopFiltersBase({
  searchFilterType,
  setSearchFilterType,
  filtersToShow,
  appType = "management",
}: SiteSearchDesktopFiltersBaseProps) {
  const isMobile = useIsMobile();

  if (appType === "management") {
    if (isMobile) {
      return (
        <EuiFormRow label="Filter Results" fullWidth={true} style={{ color: colors.neutrals.gray800 }} css={labelCss}>
          <EuiSuperSelect
            options={getMobileFilterOptions(filtersToShow)}
            valueOfSelected={searchFilterType}
            onChange={(value) => setSearchFilterType(value)}
          />
        </EuiFormRow>
      );
    }

    return (
      <EuiFlexGroup direction="column" style={{ padding: "24px", gap: "32px" }}>
        <EuiFlexItem grow={false}>
          <EuiText color={colors.neutrals.gray800} style={{ fontWeight: 700, whiteSpace: "nowrap" }} size="s">
            Narrow your search
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="column" style={{ gap: "12px" }}>
            {filtersToShow.map((filter) => {
              return (
                <EuiFlexItem grow={false} key={filter}>
                  <EuiText
                    size="s"
                    color={searchFilterType === filter ? colors.brand.meldBlue : colors.neutrals.gray700}
                    style={{
                      padding: "8px 0px",
                      cursor: "pointer",
                      fontWeight: searchFilterType === filter ? 700 : 400,
                    }}
                    onClick={() => setSearchFilterType(filter)}
                    data-testid={`search-filter-${filter}`}
                  >
                    {filter.charAt(0).toUpperCase() + filter.slice(1)}
                  </EuiText>
                </EuiFlexItem>
              );
            })}
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  } else {
    return <></>;
  }
}

export { SiteSearchDesktopFiltersBase };
