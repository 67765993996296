import { useHistory, useLocation } from "react-router-dom";

import { BaseComboboxFilterClass } from "./PmComboboxFilter/BaseComboboxFilterClass";
import { BaseDateFilterClass } from "./PmDateFilter/BaseDateFilterClass";
import { BaseDecimalFilterClass } from "./PmDecimalFilter/BaseDecimalFilterClass";
import { BaseSelectableFilterClass } from "./PmSelectableFilter/BaseSelectableFilterClass";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SavedFilterObject = Record<string, any[] | any> | undefined;

// make all subfields of a type partial
export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

// Deep merge function that handles arbitrary object types
function deepMerge<T extends object>(target: T, source: DeepPartial<T>): T {
  const output = { ...target }; // Start with a shallow copy of the target

  for (const key in source) {
    // Ignore undefined source values
    if (source[key] === undefined) {
      continue;
    }

    if (isObject(output[key]) && isObject(source[key])) {
      // If both target and source values are objects, recursively merge them
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      output[key] = deepMerge(output[key] as object, source[key] as object) as any;
    } else {
      // Otherwise, directly assign the source value
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      output[key] = source[key] as any;
    }
  }

  return output;
}

// Utility function to check if a value is an object
function isObject(item: unknown): item is object {
  return item !== null && typeof item === "object" && !Array.isArray(item);
}

/**
 * The base class that all filter classes must extend. Has some methods
 * all filters use, as well as requiring the methods used by PmFilterButtons
 * be implemented for all subclasses
 * */
export class BaseURLFilter<
  AllowedOperators extends string,
  ComponentProps,
  FilterConfig extends {
    queryParamKeyPrefix: string;
    filterName: string;
    text: string;
    popoverWidth: string;
    allowedOperators: AllowedOperators[];
    componentProps: ComponentProps;
    alwaysShow?: boolean;
  }
> {
  private static SAVED_FILTER_IGNORED_FIELDS = [
    "id",
    "name",
    "filterset_fields",
    "position",
    "private",
    "current_agent_default",
    "limit",
    "offset",
  ];
  protected config: FilterConfig;

  constructor(config: FilterConfig, overrides?: DeepPartial<FilterConfig>) {
    const _config = overrides ? deepMerge(config, overrides) : config;
    this.config = _config;
  }

  /***
   * Operators such as 'All of', 'None of', 'Between', 'Missing' etc
   */
  getAllowedOperatorOptions() {
    return this.getAllowedOperators().map((opt) => {
      return {
        label: opt,
        value: opt,
        dropdownDisplay: opt,
        inputDisplay: opt,
      };
    });
  }

  getButtonText() {
    return this.getConfig().text;
  }

  getFilterName() {
    return this.getConfig().filterName;
  }

  getOnClearClick({
    location,
    closePopover,
    history,
    savedFilter,
  }: {
    location: ReturnType<typeof useLocation>;
    history: ReturnType<typeof useHistory>;
    savedFilter: SavedFilterObject;
    closePopover: () => void;
  }) {
    return () => {
      const savedSearchParams = this.getQueryParamsFromSavedFilter({ savedFilter, location });
      this.deleteAllParamValues({ paramsToMutate: savedSearchParams, location });
      history.replace({
        pathname: location.pathname,
        search: savedSearchParams.toString(),
      });
      closePopover();
    };
  }

  getPopoverWidth() {
    return "350px";
  }

  getQueryParamsFromSavedFilter({
    savedFilter,
    location,
    paramsToMutate,
  }: {
    savedFilter: Record<string, string | string[] | null> | undefined;
  } & (
    | {
        location: ReturnType<typeof useLocation>;
        paramsToMutate?: never;
      }
    | {
        // optimization for methods where we call this often and want to avoid
        // initializing `searchParams` each time
        location?: never;
        paramsToMutate: URLSearchParams;
      }
  )): URLSearchParams {
    if (savedFilter === undefined) {
      const newParams = paramsToMutate || new URLSearchParams(location.search);
      // handles cases where the `saved_filter` param value isn't a real filter id
      // or is 'default' and the user doesn't have a default filter
      newParams.delete("saved_filter");
      return newParams;
    }
    const searchParams = paramsToMutate || new URLSearchParams();
    for (const entry of Object.entries(savedFilter)) {
      const [key, value] = entry;
      if (BaseURLFilter.SAVED_FILTER_IGNORED_FIELDS.includes(key)) {
        continue;
      }
      if (value === "" || value === null || (Array.isArray(value) && value.length === 0)) {
        continue;
      }

      if (Array.isArray(value)) {
        searchParams.set(key + "[]", value.join(","));
      } else {
        searchParams.append(key, value);
      }
    }

    return searchParams;
  }

  getQueryParamValuesFromURL(props: {
    location: ReturnType<typeof useLocation>;
    target: "url" | "savedFilter";
    paramsToMutate?: URLSearchParams;
  }): URLSearchParams {
    const currentURLParams = new URLSearchParams(location.search);
    const newParams = props.paramsToMutate || new URLSearchParams();
    for (const operator of this.getAllowedOperators()) {
      const paramKey = this.getFullQueryParamKey({ operator, target: props.target });
      const value = currentURLParams.get(paramKey);
      if (value) {
        newParams.set(paramKey, value);
      }
    }

    return newParams;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAppliedFilterCount(_props: unknown) {
    throw new Error("This method must be implemented by sub-classes");
  }

  getShouldAlwaysShow() {
    return !!this.getConfig().alwaysShow;
  }

  deleteAllParamValues({
    location,
    paramsToMutate,
  }: {
    location: ReturnType<typeof useLocation>;
    paramsToMutate?: URLSearchParams;
  }): URLSearchParams {
    const searchParams = paramsToMutate || new URLSearchParams(location.search);
    for (const operator of this.getAllowedOperators()) {
      searchParams.delete(this.getFullQueryParamKey({ operator, target: "url" }));
    }
    return searchParams;
  }

  protected getAllowedOperators() {
    return this.getConfig().allowedOperators;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected getFullQueryParamKey(_props: { operator: string; target: "savedFilter" | "url" }): string {
    throw new Error("This method must be implemented by sub-classes");
  }

  protected getQueryParamKeyPrefix() {
    return this.getConfig().queryParamKeyPrefix;
  }

  protected getConfig() {
    return this.config;
  }

  protected getSavedFilterValue({
    savedFilter,
    operator,
  }: {
    savedFilter: NonNullable<SavedFilterObject>;
    operator: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): any[] | any {
    return savedFilter[this.getFullQueryParamKey({ operator, target: "savedFilter" })];
  }

  protected getSearchParamValue({
    operator,
    location,
    currentSearchParams,
  }:
    | {
        location: ReturnType<typeof useLocation>;
        operator: string;
        currentSearchParams?: never;
      }
    | {
        // optimization for methods where we call this often and want to avoid
        // initializing `searchParams` each time
        location?: never;
        operator: string;
        currentSearchParams: URLSearchParams;
      }) {
    const searchParams = currentSearchParams || new URLSearchParams(location.search);
    return searchParams.get(this.getFullQueryParamKey({ operator, target: "url" }));
  }
}

export type FilterClassTypes =
  | BaseSelectableFilterClass
  | BaseComboboxFilterClass
  | BaseDecimalFilterClass
  | BaseDateFilterClass;
