import { Property } from "csstype";
import { Editor, Element, Range, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import { ReactEditor } from "slate-react";

import { CustomElement, LinkElement, MarkType } from "./custom-types";

const ALIGN_TYPES = ["center", "justify", "left", "right"];
const LIST_TYPES = ["ordered-list", "unordered-list"];

function getFormatField(formatType: CustomElement["type"] | Property.TextAlign) {
  return ALIGN_TYPES.includes(formatType) ? "align" : "type";
}

export function isBlockActive(
  editor: ReactEditor & HistoryEditor,
  formatType: CustomElement["type"] | Property.TextAlign
) {
  const { selection } = editor;
  if (!selection) {
    return false;
  }
  const field = getFormatField(formatType);
  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node[field] === formatType,
    })
  );

  return !!match;
}

export function isLinkActive(editor: ReactEditor & HistoryEditor) {
  const [link] = Editor.nodes(editor, {
    match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node.type === "link",
  });
  return !!link;
}

export function isMarkActive(editor: ReactEditor & HistoryEditor, markType: MarkType): boolean {
  const marks = Editor.marks(editor);
  if (marks) {
    return marks[markType] === true;
  }
  return false;
}

export function toggleMark(editor: ReactEditor & HistoryEditor, markType: MarkType): void {
  if (isMarkActive(editor, markType)) {
    Editor.removeMark(editor, markType);
  } else {
    Editor.addMark(editor, markType, true);
  }
}

export function toggleBlock(
  editor: ReactEditor & HistoryEditor,
  formatType: CustomElement["type"] | Property.TextAlign
): void {
  const isActive = isBlockActive(editor, formatType);
  const isList = LIST_TYPES.includes(formatType);

  Transforms.unwrapNodes(editor, {
    match: (node) =>
      !Editor.isEditor(node) &&
      Element.isElement(node) &&
      LIST_TYPES.includes(node.type) &&
      !ALIGN_TYPES.includes(formatType),
    split: true,
  });
  let newProperties: Partial<Element>;
  if (ALIGN_TYPES.includes(formatType)) {
    // @ts-ignore
    newProperties = { align: isActive ? undefined : formatType };
  } else {
    newProperties = {
      // @ts-ignore
      type: isActive ? "paragraph" : isList ? "list-item" : formatType,
    };
  }
  Transforms.setNodes<Element>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: formatType, children: [] };
    // @ts-ignore
    Transforms.wrapNodes(editor, block);
  }
}

export function withInlines(editor: ReactEditor & HistoryEditor) {
  const { isInline } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isInline = (element) => element.type === "link" || isInline(element);

  return editor;
}

export function unwrapLink(editor: ReactEditor & HistoryEditor) {
  Transforms.unwrapNodes(editor, {
    match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node.type === "link",
  });
}

export function wrapLink(editor: ReactEditor & HistoryEditor, url: string) {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
}

/**
 * Handle situations where user enters www.google.com instead of
 * https://www.google.com.
 * @param link
 */
export function getAbsoluteLink(link: string) {
  try {
    const url = new URL(link);
    return url.href;
  } catch (_e) {
    return `https://${link}`;
  }
}
