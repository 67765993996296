import { useQuery } from "@tanstack/react-query";
import { AxiosHeaders } from "axios";

import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { ApiUrls } from "@pm-frontend/shared/utils/api-urls";
import { apiFetch } from "@pm-frontend/shared/utils/apiFetch";

const notificationKeys = {
  all: ["notifications"],
  unread: () => [...notificationKeys.all, "unread"],
} as const;

export const useGetUnreadNotificationCount = () => {
  return useQuery<{ unread: number }>({
    queryFn: () =>
      apiFetch(LinkHelper.normalize(ApiUrls.notificationsUnread), {
        headers: new AxiosHeaders({ "Cache-Control": "max-age=60" }),
      }),
    queryKey: notificationKeys.unread(),
    refetchInterval: 60 * 1000,
    refetchIntervalInBackground: false,
  });
};
