import React, { RefObject } from "react";
import validate from "validate.js";
import _ from "lodash";

let ErrorUtils = {
  getErrorHtml(data: any, constraints: any, extraErrors?: string[]) {
    let errors = this.getValidationErrorList(data, constraints);
    errors = errors.concat(extraErrors || []);
    return this.transformErrorsToHtml(errors);
  },

  getValidationErrors(data: any, constraints: any) {
    return validate(data, constraints, { fullMessages: false });
  },

  getErrorClass(attr: string) {
    if (this.view.state.submissionTried) {
      let errors = this.getValidationErrors();
      if (errors) {
        return errors[attr] ? "error" : "";
      }
    }
  },

  getValidationErrorList(data: any, constraints: any) {
    let validationErrors = this.getValidationErrors(data, constraints) || [];
    let errors: string[] = [];
    _.forIn(validationErrors, value => {
      errors = errors.concat(value);
    });

    return errors;
  },

  transformErrorsToHtml(errors: any[], ref?: RefObject<HTMLUListElement>) {
    if (!errors) {
      return null;
    }

    let errorsHtml = errors.map(function(error, i) {
      if (_.isArray(error)) {
        return <ul>{this.transformErrorsToHtml(error)}</ul>;
      }
      return (
        <li className="error" key={i}>
          {error}
        </li>
      );
    });

    return (
      <ul className="errors" ref={ref}>
        {errorsHtml}
      </ul>
    );
  }
};

export default ErrorUtils;
