import CommentActions from "../actions/comment-actions";
import CommentApi from "../apis/comment-api";

let CommentSource = {
  fetchComments() {
    return {
      remote(state, options = {}) {
        return CommentApi.fetchComments(options);
      },

      shouldFetch(state, force) {
        return true;
      },

      success: CommentActions.fetchCommentsDone,
      error: CommentActions.fetchCommentsFail
    };
  },

  fetchComment() {
    return {
      remote(state, id, clazz) {
        return CommentApi.fetchComment(id, clazz);
      },

      shouldFetch(state, id, clazz, force) {
        return true;
      },

      success: CommentActions.fetchCommentDone,
      error: CommentActions.fetchCommentFail
    };
  },

  createComment() {
    return {
      remote(state, comment, cid) {
        return CommentApi.createComment(comment);
      },

      shouldFetch(state, force) {
        return true;
      },

      success: CommentActions.createCommentDone,
      error: CommentActions.createCommentFail
    };
  },

  updateComment() {
    return {
      remote(state, comment, cid) {
        return CommentApi.updateComment(comment);
      },

      shouldFetch(state, force) {
        return true;
      },

      success: CommentActions.updateCommentDone,
      error: CommentActions.updateCommentFail
    };
  }
};

export default CommentSource;
