import CalendarUtils from "../../utils/calendar-utils";
import I from "immutable";

export default class ReminderUtils {
  static getTitle(reminder: I.Map<string, any>, empty: string = "Untitled") {
    return reminder.get("title") || empty;
  }

  static hasOverdue(reminders: I.List<I.Map<string, any>>) {
    return reminders.some(this.isOverdue);
  }

  static isOverdue(reminder: I.Map<string, any>) {
    return !reminder.get("completed") && CalendarUtils.isBeforeNow(reminder.get("due"));
  }

  static isComplete(reminder: I.Map<string, any>) {
    return !!reminder.get("completed");
  }

  static sort(reminders: I.List<I.Map<string, any>>) {
    return reminders.sort((a, b) => {
      const isCompleteA = ReminderUtils.isComplete(a);
      const isCompleteB = ReminderUtils.isComplete(b);

      if (isCompleteA && isCompleteB) {
        return 0;
      }

      if (isCompleteA && !isCompleteB) {
        return 1;
      }

      if (!isCompleteA && isCompleteB) {
        return -1;
      }

      const dueA = new Date(a.get("due"));
      const dueB = new Date(b.get("due"));

      if (dueA < dueB) {
        return -1;
      }
      if (dueA > dueB) {
        return 1;
      }
      if (dueA === dueB) {
        return 0;
      }

      return 0;
    });
  }
}
