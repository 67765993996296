import invoiceActions from "../actions/invoice-actions";
import InvoiceApi from "../apis/invoice-api";

let InvoiceStore = {
  fetchAll() {
    return {
      remote(state, options) {
        return InvoiceApi.fetchAll(options);
      },

      shouldFetch() {
        return true;
      },

      success: invoiceActions.fetchAllDone,
      error: invoiceActions.fetchAllFail
    };
  },

  fetchOne() {
    return {
      remote(state, invoiceId, options) {
        return InvoiceApi.fetchOne(invoiceId, options);
      },

      shouldFetch() {
        return true;
      },

      success: invoiceActions.fetchOneDone,
      error: invoiceActions.fetchOneFail
    };
  },

  fetchInvoicesByUnit() {
    return {
      remote(state, unitId) {
        return InvoiceApi.fetchInvoicesByUnit(unitId);
      },

      shouldFetch(state, id, force) {
        return true;
      },

      success: invoiceActions.fetchInvoicesByUnitDone,
      error: invoiceActions.fetchInvoicesByUnitFail
    };
  }
};

export default InvoiceStore;
