const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

/**
 * Utility function for formatting currency
 *  99999.99 -> $99,999.00
 */
export const formatCurrency = (value: number) => currencyFormatter.format(value);

/**
 * Utility function to convert cents into dollars
 *  1000000 -> $10,000.00
 */
export const centsToDollars = (value: number) => currencyFormatter.format(value / 100);
