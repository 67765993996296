var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import { MobileDetailViewsPageHeader } from "./MobileDetailViewsPageHeader";
import { DesktopDetailViewsPageHeader } from "./DesktopDetailViewsPageHeader";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
const DetailViewsPageHeader = (props) => {
  const sharedProps = __spreadProps(__spreadValues({}, props), {
    primaryAction: !props.isInactive ? props.primaryAction : void 0,
    secondaryActions: !props.isInactive ? props.secondaryActions : void 0,
    linkActions: !props.isInactive ? props.linkActions : void 0
  });
  const mobileState = useIsMobile();
  if (mobileState) {
    return /* @__PURE__ */ React.createElement(MobileDetailViewsPageHeader, __spreadValues({}, sharedProps));
  } else {
    return /* @__PURE__ */ React.createElement(DesktopDetailViewsPageHeader, __spreadValues({}, sharedProps));
  }
};
export { DetailViewsPageHeader };
