import React from "react";

import ErrorUtils from "../../utils/error-utils";

import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box, ListItem, ListIcon, List
} from '@chakra-ui/react';
import { MdRemoveCircle } from "react-icons/md";

export interface ErrorProps {
  data: any;
  constraints?: any;
  extraErrors?: string[];
  skipValidation?: boolean;
}

function Errors(props: ErrorProps) {
  let errors: string[] = [];

  if (!props.skipValidation) {
    errors = ErrorUtils.getValidationErrorList(props.data, props.constraints) || [];
  }

  errors = errors.concat(props.extraErrors || []);

  return <ErrorList errors={errors} />;
}

export default Errors;

function ErrorList(props: { errors?: string[] }) {
  if (props.errors && props.errors.length) {
    let errorsHtml = props.errors.map((error, i) => {
      return (
        <li className="error" key={i}>
          {error}
        </li>
      );
    });

    return <ul className="errors">{errorsHtml}</ul>;
  }

  return null;
}

export { ErrorList };


function ChakraErrorList(props: { errors?: string[] }) {
  if (props.errors && props.errors.length) {

    let errorsHtml = props.errors.map((error, i) => {
      return (
        <ListItem key={i}>
          <ListIcon as={MdRemoveCircle} color="red.500" />
          {error}
        </ListItem>
      );
    });

    return (
    <Alert status="error" margin={"8px 0"}>
      <Box flex="1">
        <AlertTitle>Error!</AlertTitle>
        <AlertDescription display="block">
          <List spacing={3}>
          {errorsHtml}
          </List>
        </AlertDescription>
      </Box>
    </Alert>
    );
  }

  return null;
}

export { ChakraErrorList };
