import OwnerActions from "../actions/owner-actions";
import OwnerApi from "../apis/owner-api";

let OwnerSource = {
  fetchOwners() {
    return {
      remote(state, { cid, xhrOptions = {} } = {}) {
        return OwnerApi.fetchAll(xhrOptions);
      },

      shouldFetch(state, force) {
        return true;
      },

      success: OwnerActions.fetchOwnersDone,
      error: OwnerActions.fetchOwnersFail
    };
  },

  fetchOwner() {
    return {
      remote(state, id) {
        return OwnerApi.fetchOne(id);
      },

      shouldFetch(state, id, force) {
        return true;
      },

      success: OwnerActions.fetchOwnerDone,
      error: OwnerActions.fetchOwnerFail
    };
  }
};

export default OwnerSource;
