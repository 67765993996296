import * as C from "../../constants";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import shallowEqual from "shallow-equal/objects";
import Button from "../../../../app/components/buttons/Button";
import ButtonContainer from "../../../../app/components/containers/ButtonContainer";
import { FlexJustifyContent } from "../../../../app/components/utils/constants";
import ForwardButton from "../../../../app/components/buttons/ForwardButton";
import BackButton from "../../../../app/components/buttons/BackButton";

interface CalendarControlsProps {
  duration?: number;
  navNext: () => void;
  navPrev: () => void;
  numDays: number;
  onDurationChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onTodayClicked: () => void;
  showDurationSelect?: boolean;
  startDay: moment.Moment;
}

class CalendarControls extends React.Component<CalendarControlsProps> {
  static propTypes = {
    navNext: PropTypes.func.isRequired,
    navPrev: PropTypes.func.isRequired,
    startDay: PropTypes.object.isRequired,
    numDays: PropTypes.number,
    showDurationSelect: PropTypes.bool
  };

  static defaultProps = {
    numDays: 1,
    showDurationSelect: false
  };

  shouldComponentUpdate(nextProps: CalendarControlsProps) {
    const { startDay, ...props } = this.props;
    const { startDay: startDayNP, ...propsNP } = nextProps;
    return !shallowEqual(propsNP, props) || !startDay.isSame(startDayNP);
  }

  render() {
    return (
      <div className="date-controls">
        <Button className="today" onClick={this.props.onTodayClicked}>
          Today
        </Button>
        <ButtonContainer justify={FlexJustifyContent.Center}>
          <BackButton className="prev-week week-button" onClick={this.props.navPrev} />
          <div className="current-week">{this.renderCurrentTimeSpan()}</div>
          <ForwardButton className="next-week week-button" onClick={this.props.navNext} />
        </ButtonContainer>
        {this.props.showDurationSelect && (
          <select className="duration-selector" onChange={this.props.onDurationChange} value={this.props.duration}>
            <option value={C.CalendarDuration.DAY}>Day</option>
            <option value={C.CalendarDuration.FOUR_DAY}>4-day</option>
            <option value={C.CalendarDuration.WEEK}>Week</option>
          </select>
        )}
      </div>
    );
  }

  renderCurrentTimeSpan() {
    const { numDays, startDay } = this.props;

    if (numDays === 1) {
      return startDay.format("MMM Do");
    } else {
      return `${startDay.format("MMM Do")} - ${startDay
        .clone()
        .add(numDays - 1, "days")
        .format("MMM Do")}`;
    }
  }
}

export default CalendarControls;
