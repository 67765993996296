import { parseInt } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { BaseURLFilter, DeepPartial, SavedFilterObject } from "../BaseFilterClasses";

export const PM_DECIMAL_FILTER_OPERATORS = {
  GREATER_THAN: "Greater or equal to",
  LESSER_THAN: "Lesser or equal to",
  MISSING: "Missing",
  PRESENT: "Present",
  EQUALS: "Equal to",
  NOT_EQUALS: "Not equal to",
  BETWEEN: "Between",
} as const;

export type PmDecimalFilterAllowedOperators =
  typeof PM_DECIMAL_FILTER_OPERATORS[keyof typeof PM_DECIMAL_FILTER_OPERATORS];

const GREATER_SUFFIX = "_gte" as const;
const LESSER_SUFFIX = "_lte" as const;
const MISSING_SUFFIX = "_exists" as const;
const PRESENT_SUFFIX = "_exists" as const;
const EQUALS_SUFFIX = "_equal" as const;
const NOT_EQUALS_SUFFIX = "_not_equal" as const;

type PmDecimalFilterSuffixes =
  | typeof MISSING_SUFFIX
  | typeof PRESENT_SUFFIX
  | typeof EQUALS_SUFFIX
  | typeof NOT_EQUALS_SUFFIX
  | typeof GREATER_SUFFIX
  | typeof LESSER_SUFFIX;

export interface PmDecimalFilterProps {
  text: string;
  numberUnitLabel: string;
  queryParamKeyPrefix: string;
  popoverWidth: string;
  allowedOperators: PmDecimalFilterAllowedOperators[];
  // some filters have inconsistent key values
  suffixOverrides?: Partial<Record<PmDecimalFilterSuffixes, string>>;
  filterName: string;
  alwaysShow: boolean;
  componentProps: null;
}

export const PM_DECIMAL_FILTER_INPUT_TYPES = {
  ONE_NUMBER: "one-decimal-input",
  TWO_NUMBER: "two-decimal-input",
  BOOLEAN: "boolean",
} as const;

export type PmDecimalFilterInputTypes =
  typeof PM_DECIMAL_FILTER_INPUT_TYPES[keyof typeof PM_DECIMAL_FILTER_INPUT_TYPES];

export type PmDecimalFilterOperatorValue =
  | ({ filterType: typeof BaseDecimalFilterClass.type } & (
      | {
          // one decimal input
          operator: Extract<
            PmDecimalFilterAllowedOperators,
            "Greater or equal to" | "Lesser or equal to" | "Equal to" | "Not equal to"
          >;
          value: string;
          type: Extract<PmDecimalFilterInputTypes, "one-decimal-input">;
        }
      | {
          // two decimal input
          operator: Extract<PmDecimalFilterAllowedOperators, "Between">;
          firstValue: string;
          secondValue: string;
          type: Extract<PmDecimalFilterInputTypes, "two-decimal-input">;
        }
      | {
          // boolean input (zero number)
          operator: Extract<PmDecimalFilterAllowedOperators, "Missing" | "Present">;
          type: Extract<PmDecimalFilterInputTypes, "boolean">;
        }
    ))
  | null;

export class BaseDecimalFilterClass extends BaseURLFilter<PmDecimalFilterAllowedOperators, null, PmDecimalFilterProps> {
  static type = "decimal_filter" as const;

  constructor(config: PmDecimalFilterProps, overrides?: DeepPartial<PmDecimalFilterProps>) {
    super(config, overrides);
  }

  deleteAllParamValues({
    location,
    paramsToMutate,
  }: {
    location: ReturnType<typeof useLocation>;
    paramsToMutate?: URLSearchParams;
  }) {
    const searchParams = paramsToMutate || new URLSearchParams(location.search);
    for (const operator of this.getAllowedOperators()) {
      if (operator === PM_DECIMAL_FILTER_OPERATORS.BETWEEN) {
        continue;
      }
      searchParams.delete(this.getFullQueryParamKey({ operator }));
    }
    return searchParams;
  }

  getAllowedOperatorOptions() {
    return this.getAllowedOperators().map((opt) => ({
      label: opt,
      value: opt,
      dropdownDisplay: opt,
      inputDisplay: opt,
    }));
  }

  getCurrentlyAppliedOperatorAndValue({
    savedFilter,
    location,
  }: {
    savedFilter: SavedFilterObject;
    location: ReturnType<typeof useLocation>;
  }): PmDecimalFilterOperatorValue {
    // between operator requires both '_lte' and '_gte' param keys, so we check for it first
    if (this.getAllowedOperators().includes(PM_DECIMAL_FILTER_OPERATORS.BETWEEN)) {
      if (savedFilter) {
        const greaterValue = this.getSavedFilterValue({
          savedFilter,
          operator: PM_DECIMAL_FILTER_OPERATORS.GREATER_THAN,
        });
        const lesserValue = this.getSavedFilterValue({
          savedFilter,
          operator: PM_DECIMAL_FILTER_OPERATORS.LESSER_THAN,
        });
        if (greaterValue && lesserValue) {
          return {
            operator: PM_DECIMAL_FILTER_OPERATORS.BETWEEN,
            firstValue: greaterValue,
            secondValue: lesserValue,
            type: PM_DECIMAL_FILTER_INPUT_TYPES.TWO_NUMBER,
            filterType: BaseDecimalFilterClass.type,
          };
        }
      } else {
        const greaterValue = this.getSearchParamValue({ location, operator: PM_DECIMAL_FILTER_OPERATORS.GREATER_THAN });
        const lesserValue = this.getSearchParamValue({ location, operator: PM_DECIMAL_FILTER_OPERATORS.LESSER_THAN });

        if (greaterValue && typeof greaterValue === "string" && lesserValue && typeof lesserValue === "string") {
          return {
            operator: PM_DECIMAL_FILTER_OPERATORS.BETWEEN,
            firstValue: greaterValue,
            secondValue: lesserValue,
            type: PM_DECIMAL_FILTER_INPUT_TYPES.TWO_NUMBER,
            filterType: BaseDecimalFilterClass.type,
          };
        }
      }
    }

    for (const operator of this.getAllowedOperators()) {
      // between handled above
      if (operator === PM_DECIMAL_FILTER_OPERATORS.BETWEEN) {
        continue;
      }
      const type = this.getInputType({ operator });
      const currentSearchParams = new URLSearchParams(location.search);
      const searchParamValue = savedFilter
        ? this.getSavedFilterValue({ savedFilter, operator })
        : this.getSearchParamValue({ currentSearchParams, operator });
      if (type === PM_DECIMAL_FILTER_INPUT_TYPES.BOOLEAN) {
        if (operator === PM_DECIMAL_FILTER_OPERATORS.MISSING && searchParamValue === "false") {
          return { operator: PM_DECIMAL_FILTER_OPERATORS.MISSING, type, filterType: BaseDecimalFilterClass.type };
        } else if (operator === PM_DECIMAL_FILTER_OPERATORS.PRESENT && searchParamValue === "true") {
          return { operator: PM_DECIMAL_FILTER_OPERATORS.PRESENT, type, filterType: BaseDecimalFilterClass.type };
        }
      } else if (type === PM_DECIMAL_FILTER_INPUT_TYPES.ONE_NUMBER) {
        const parsedSearchParamValue = searchParamValue && parseInt(searchParamValue, 10);
        if (parsedSearchParamValue && !Number.isNaN(parsedSearchParamValue)) {
          if (
            operator === PM_DECIMAL_FILTER_OPERATORS.EQUALS ||
            operator === PM_DECIMAL_FILTER_OPERATORS.NOT_EQUALS ||
            operator === PM_DECIMAL_FILTER_OPERATORS.GREATER_THAN ||
            operator === PM_DECIMAL_FILTER_OPERATORS.LESSER_THAN
          ) {
            return { operator, value: parsedSearchParamValue, type, filterType: BaseDecimalFilterClass.type };
          }
        }
      } else if (type === PM_DECIMAL_FILTER_INPUT_TYPES.TWO_NUMBER) {
        // BETWEEN was handled above
        continue;
      }
    }
    return null;
  }

  getNewValueFromNewOperator({
    newOperator,
    currentPendingOperatorAndValue,
  }: {
    newOperator: PmDecimalFilterAllowedOperators;
    currentPendingOperatorAndValue: NonNullable<PmDecimalFilterOperatorValue>;
  }): NonNullable<PmDecimalFilterOperatorValue> {
    const oldType = currentPendingOperatorAndValue.type;
    let type: PmDecimalFilterInputTypes = PM_DECIMAL_FILTER_INPUT_TYPES.BOOLEAN;
    switch (newOperator) {
      case PM_DECIMAL_FILTER_OPERATORS.PRESENT:
      case PM_DECIMAL_FILTER_OPERATORS.MISSING:
        return { operator: newOperator, type, filterType: BaseDecimalFilterClass.type };
      case PM_DECIMAL_FILTER_OPERATORS.EQUALS:
      case PM_DECIMAL_FILTER_OPERATORS.NOT_EQUALS:
      case PM_DECIMAL_FILTER_OPERATORS.LESSER_THAN:
      case PM_DECIMAL_FILTER_OPERATORS.GREATER_THAN:
        type = PM_DECIMAL_FILTER_INPUT_TYPES.ONE_NUMBER;
        if (oldType === PM_DECIMAL_FILTER_INPUT_TYPES.ONE_NUMBER) {
          return {
            operator: newOperator,
            value: currentPendingOperatorAndValue.value,
            type,
            filterType: BaseDecimalFilterClass.type,
          };
        } else if (oldType === PM_DECIMAL_FILTER_INPUT_TYPES.TWO_NUMBER) {
          return {
            operator: newOperator,
            value: currentPendingOperatorAndValue.firstValue,
            type,
            filterType: BaseDecimalFilterClass.type,
          };
        } else if (oldType === PM_DECIMAL_FILTER_INPUT_TYPES.BOOLEAN) {
          return { operator: newOperator, value: "", type, filterType: BaseDecimalFilterClass.type };
        } else {
          throw new Error("Need to implement new input type for getNewValeFromNewOperator");
        }
      case PM_DECIMAL_FILTER_OPERATORS.BETWEEN:
        type = PM_DECIMAL_FILTER_INPUT_TYPES.TWO_NUMBER;
        if (oldType === PM_DECIMAL_FILTER_INPUT_TYPES.ONE_NUMBER) {
          return {
            operator: newOperator,
            firstValue: currentPendingOperatorAndValue.value,
            secondValue: "",
            type,
            filterType: BaseDecimalFilterClass.type,
          };
        } else if (oldType === PM_DECIMAL_FILTER_INPUT_TYPES.TWO_NUMBER) {
          return {
            operator: newOperator,
            firstValue: currentPendingOperatorAndValue.firstValue,
            secondValue: currentPendingOperatorAndValue.secondValue,
            type,
            filterType: BaseDecimalFilterClass.type,
          };
        } else if (oldType === PM_DECIMAL_FILTER_INPUT_TYPES.BOOLEAN) {
          return {
            operator: newOperator,
            firstValue: "",
            secondValue: "",
            type,
            filterType: BaseDecimalFilterClass.type,
          };
        }
    }
    throw new Error("Need to implement new input type for getNewValeFromNewOperator");
  }

  getNumberUnitLabel() {
    return this.getConfig().numberUnitLabel;
  }

  getOnApplyClick({
    location,
    closePopover,
    history,
    currentPendingOperatorValue,
    savedFilter,
  }: {
    location: ReturnType<typeof useLocation>;
    history: ReturnType<typeof useHistory>;
    closePopover: () => void;
    currentPendingOperatorValue: NonNullable<PmDecimalFilterOperatorValue>;
    savedFilter: SavedFilterObject;
  }) {
    return () => {
      const savedSearchParams = this.getQueryParamsFromSavedFilter({ savedFilter, location });
      this.deleteAllParamValues({ paramsToMutate: savedSearchParams, location });

      const key = this.getFullQueryParamKey({ operator: currentPendingOperatorValue.operator });
      switch (currentPendingOperatorValue.type) {
        case PM_DECIMAL_FILTER_INPUT_TYPES.TWO_NUMBER:
          if (!currentPendingOperatorValue.firstValue || !currentPendingOperatorValue.secondValue) {
            // don't apply changes
            return;
          }
          if (currentPendingOperatorValue.operator === PM_DECIMAL_FILTER_OPERATORS.BETWEEN) {
            const firstKey = this.getFullQueryParamKey({ operator: PM_DECIMAL_FILTER_OPERATORS.GREATER_THAN });
            const secondKey = this.getFullQueryParamKey({ operator: PM_DECIMAL_FILTER_OPERATORS.LESSER_THAN });
            savedSearchParams.set(firstKey, currentPendingOperatorValue.firstValue);
            savedSearchParams.set(secondKey, currentPendingOperatorValue.secondValue);
          }
          break;
        case PM_DECIMAL_FILTER_INPUT_TYPES.ONE_NUMBER:
          if (!currentPendingOperatorValue.value) {
            // don't apply changes
            return;
          }
          savedSearchParams.set(key, currentPendingOperatorValue.value);
          break;
        case PM_DECIMAL_FILTER_INPUT_TYPES.BOOLEAN:
          savedSearchParams.set(
            key,
            currentPendingOperatorValue.operator === PM_DECIMAL_FILTER_OPERATORS.PRESENT ? "true" : "false"
          );
          break;
        default:
          break;
      }
      history.replace({
        pathname: location.pathname,
        search: savedSearchParams.toString(),
      });
      closePopover();
    };
  }

  getAppliedFilterCount({
    currentOperatorAndValue,
  }: {
    currentOperatorAndValue: PmDecimalFilterOperatorValue;
  }): number {
    if (!currentOperatorAndValue) {
      return 0;
    }
    return 1;
  }

  protected getFullQueryParamKey({ operator }: { operator: string }): string {
    const suffixOverrides = this.getSuffixOverrides();
    const prefix = this.getQueryParamKeyPrefix();
    // note that BETWEEN does not have a single query param key, but uses GREATER and LESSER
    switch (operator) {
      case PM_DECIMAL_FILTER_OPERATORS.MISSING:
        return prefix + (suffixOverrides?.[MISSING_SUFFIX] || MISSING_SUFFIX);
      case PM_DECIMAL_FILTER_OPERATORS.PRESENT:
        return prefix + (suffixOverrides?.[PRESENT_SUFFIX] || PRESENT_SUFFIX);
      case PM_DECIMAL_FILTER_OPERATORS.EQUALS:
        return prefix + (suffixOverrides?.[EQUALS_SUFFIX] || EQUALS_SUFFIX);
      case PM_DECIMAL_FILTER_OPERATORS.NOT_EQUALS:
        return prefix + (suffixOverrides?.[NOT_EQUALS_SUFFIX] || NOT_EQUALS_SUFFIX);
      case PM_DECIMAL_FILTER_OPERATORS.GREATER_THAN:
        return prefix + (suffixOverrides?.[GREATER_SUFFIX] || GREATER_SUFFIX);
      case PM_DECIMAL_FILTER_OPERATORS.LESSER_THAN:
        return prefix + (suffixOverrides?.[LESSER_SUFFIX] || LESSER_SUFFIX);
      default:
        return prefix;
    }
  }

  private getInputType({ operator }: { operator: PmDecimalFilterAllowedOperators }): PmDecimalFilterInputTypes {
    switch (operator) {
      case PM_DECIMAL_FILTER_OPERATORS.MISSING:
      case PM_DECIMAL_FILTER_OPERATORS.PRESENT:
        return PM_DECIMAL_FILTER_INPUT_TYPES.BOOLEAN;
      case PM_DECIMAL_FILTER_OPERATORS.EQUALS:
      case PM_DECIMAL_FILTER_OPERATORS.NOT_EQUALS:
      case PM_DECIMAL_FILTER_OPERATORS.GREATER_THAN:
      case PM_DECIMAL_FILTER_OPERATORS.LESSER_THAN:
        return PM_DECIMAL_FILTER_INPUT_TYPES.ONE_NUMBER;
      case PM_DECIMAL_FILTER_OPERATORS.BETWEEN:
        return PM_DECIMAL_FILTER_INPUT_TYPES.TWO_NUMBER;
      default:
        return PM_DECIMAL_FILTER_INPUT_TYPES.BOOLEAN;
    }
  }

  private getSuffixOverrides() {
    return this.getConfig().suffixOverrides;
  }
}
