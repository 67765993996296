import clientApi from "./api/client-api";
import { ErrorHandler } from "../../../app/utils/ErrorHandler";
export function monitorVersionChanges() {
  window.setInterval(function() {
    clientApi
      .getReload({
        version: window.PM.env.version
      })
      .then(response => {
        if (response.data.immediate) {
          window.location.reload();
        }
        window.PM.env.outOfDate = response.data.reload_needed;
      })
      .catch(error => ErrorHandler.handleAxiosError(error));
  }, 5 * 60 * 1000);
}
