import React from "react";
import classnames from "classnames";

/**
 * Returns a block (<div>) with the assigned baseClass prepending the className
 */
function blockWithBaseClass(props: any, baseClass: string) {
  const classes = classnames(baseClass, props.className);
  const { className, ...newProps } = props;

  return <div className={classes} {...newProps} />;
}

export default blockWithBaseClass;
