import React from "react";
import { colors } from "@pm-frontend/styles";

export const SidebarWorkflowsIcon = ({ isActive = false }: { isActive?: boolean }) => {
  const fill = isActive ? colors.brand.meldBlue : colors.neutrals.gray800;
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1378_22302)">
        <path
          d="M9.44683 9.13992L10.3802 7.52659C10.4402 7.42659 10.4135 7.29992 10.3268 7.22659L9.34017 6.45325C9.36017 6.30659 9.3735 6.15325 9.3735 5.99992C9.3735 5.84659 9.36017 5.69325 9.34017 5.53992L10.3268 4.76659C10.4135 4.69325 10.4402 4.56659 10.3802 4.46659L9.44683 2.85325C9.38683 2.75325 9.26683 2.71325 9.16017 2.75325L8.00017 3.21992C7.76017 3.03325 7.50017 2.87992 7.2135 2.75992L7.04017 1.52659C7.02017 1.41992 6.92017 1.33325 6.80683 1.33325H4.94017C4.82683 1.33325 4.72683 1.41992 4.70683 1.53325L4.5335 2.76659C4.2535 2.88659 3.98683 3.03992 3.74683 3.22659L2.58683 2.75992C2.48017 2.71992 2.36016 2.75992 2.30017 2.85992L1.36683 4.47325C1.30683 4.57325 1.3335 4.69992 1.42017 4.77325L2.40683 5.54659C2.38683 5.69325 2.3735 5.84659 2.3735 5.99992C2.3735 6.15325 2.38683 6.30659 2.40683 6.45992L1.42017 7.23325C1.3335 7.30659 1.30683 7.43325 1.36683 7.53325L2.30017 9.14659C2.36016 9.24659 2.48017 9.28659 2.58683 9.24659L3.74683 8.77992C3.98683 8.96659 4.24683 9.11992 4.5335 9.23992L4.70683 10.4733C4.72683 10.5799 4.82683 10.6666 4.94017 10.6666H6.80683C6.92017 10.6666 7.02017 10.5799 7.04017 10.4666L7.2135 9.23325C7.4935 9.11325 7.76017 8.95992 8.00017 8.77325L9.16017 9.23992C9.26683 9.27992 9.38683 9.23992 9.44683 9.13992ZM5.8735 7.33325C5.14017 7.33325 4.54016 6.73325 4.54016 5.99992C4.54016 5.26659 5.14017 4.66659 5.8735 4.66659C6.60683 4.66659 7.20683 5.26659 7.20683 5.99992C7.20683 6.73325 6.60683 7.33325 5.8735 7.33325Z"
          fill={fill}
        />
        <path
          d="M14.6135 12.4465L13.9735 11.9532C13.9868 11.8598 14.0002 11.7598 14.0002 11.6598C14.0002 11.5598 13.9935 11.4598 13.9735 11.3665L14.6068 10.8732C14.6602 10.8265 14.6802 10.7465 14.6402 10.6798L14.0402 9.6465C14.0068 9.57984 13.9268 9.55984 13.8535 9.57984L13.1135 9.87984C12.9602 9.75984 12.7935 9.65984 12.6068 9.5865L12.4935 8.79984C12.4868 8.71984 12.4202 8.6665 12.3535 8.6665H11.1602C11.0868 8.6665 11.0202 8.71984 11.0135 8.79317L10.9002 9.57984C10.7202 9.65984 10.5468 9.75317 10.3935 9.87317L9.65349 9.57317C9.58682 9.5465 9.50682 9.57317 9.46682 9.63984L8.86682 10.6732C8.83349 10.7398 8.84015 10.8198 8.90015 10.8665L9.53349 11.3598C9.52015 11.4532 9.51349 11.5532 9.51349 11.6532C9.51349 11.7532 9.52015 11.8532 9.53349 11.9465L8.90015 12.4398C8.84682 12.4865 8.82682 12.5665 8.86682 12.6332L9.46682 13.6665C9.50015 13.7332 9.58015 13.7532 9.65349 13.7332L10.3935 13.4332C10.5468 13.5532 10.7135 13.6532 10.9002 13.7265L11.0135 14.5132C11.0268 14.5865 11.0868 14.6398 11.1602 14.6398H12.3535C12.4268 14.6398 12.4935 14.5865 12.5002 14.5132L12.6135 13.7265C12.7935 13.6465 12.9668 13.5532 13.1135 13.4332L13.8602 13.7332C13.9268 13.7598 14.0068 13.7332 14.0468 13.6665L14.6468 12.6332C14.6868 12.5732 14.6668 12.4932 14.6135 12.4465ZM11.7535 12.5532C11.2602 12.5532 10.8535 12.1532 10.8535 11.6532C10.8535 11.1532 11.2535 10.7532 11.7535 10.7532C12.2535 10.7532 12.6535 11.1532 12.6535 11.6532C12.6535 12.1532 12.2468 12.5532 11.7535 12.5532Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1378_22302">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
