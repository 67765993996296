import React from "react";
import classnames from "classnames";

import { FlexJustifyContent, FlexAlignItems } from "../utils/constants";

export interface ButtonContainerProps {
  children: any;
  justify?: FlexJustifyContent;
  fullButtons?: boolean;
  inline?: boolean;
  className?: string;
  forceInlineButtons?: boolean;
  align?: FlexAlignItems;
  forceSize?: boolean;
}

export default function ButtonContainer(props: ButtonContainerProps) {
  const classes = classnames(
    "pm-button-container",
    {
      [props.justify as string]: !!props.justify,
      "pm-inline": props.inline,
      "full-buttons": props.fullButtons,
      "force-inline-buttons": !!props.forceInlineButtons,
      [props.align as string]: !!props.align,
      "force-size": props.forceSize
    },
    props.className
  );

  return <div className={classes}>{props.children}</div>;
}
