import createReactClass from "create-react-class";

import { OPTIONS as RegistrationOptions } from "./apis/registration-api";
import Step1BaseMixin from "../authentication/components/step1-base-mixin";

let Step1 = createReactClass({
  mixins: [Step1BaseMixin],

  category: RegistrationOptions.PROPERTY_MANAGER
});

export default Step1;
