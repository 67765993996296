var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import { Tag } from "@chakra-ui/react";
export const StatusBadge = (_a) => {
  var _b = _a, { colorScheme = "white", size = "sm", children } = _b, props = __objRest(_b, ["colorScheme", "size", "children"]);
  const defaults = __spreadValues({
    borderRadius: 14,
    children,
    fontSize: "sm",
    paddingY: 1,
    paddingX: 2,
    size
  }, props);
  switch (colorScheme) {
    case "white":
      return /* @__PURE__ */ React.createElement(Tag, __spreadValues({ backgroundColor: "#EDF2F7", color: "#3A5265" }, defaults));
    case "yellow":
      return /* @__PURE__ */ React.createElement(Tag, __spreadValues({ backgroundColor: "#FFEA8A", color: "#6C5E08" }, defaults));
    case "red":
      return /* @__PURE__ */ React.createElement(Tag, __spreadValues({ backgroundColor: "#FDC1AF", color: "#7F2318" }, defaults));
    case "green":
      return /* @__PURE__ */ React.createElement(Tag, __spreadValues({ backgroundColor: "#C4E5B5", color: "#275A2C" }, defaults));
    default:
      return null;
  }
};
StatusBadge.displayName = "StatusBadge";
