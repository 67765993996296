import React from "react";

/* tslint:disable:max-line-length */
export default class PopoutSvg extends React.Component {
  render() {
    return (
      <svg xmlns="https://www.w3.org/2000/svg" className="popout-svg" width="459" height="459" viewBox="0 0 459 459">
        <path d="M408 408H51V51h178.5V0H51C22.95 0 0 22.95 0 51v357c0 28.05 22.95 51 51 51h357c28.05 0 51-22.95 51-51V229.5h-51V408zM280.5 0v51h91.8L122.4 300.9l35.7 35.699L408 86.7v91.8h51V0H280.5z" />
      </svg>
    );
  }
}
/* tslint:enable:max-line-length */
