var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const OwnerApproved = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement("path", { d: "M39.73.32a1 1 0 00-1.41 0l-12.92 12-5.67-6.1a1 1 0 00-1.41-.06 1 1 0 00-.05 1.42l6.35 6.83a1 1 0 00.69.32 1 1 0 00.68-.26L39.68 1.73a1 1 0 00.05-1.41zM12 26a9 9 0 10-9-9 9 9 0 009 9zm0-16a7 7 0 11-7 7 7 7 0 017-7zM12 27A24.12 24.12 0 00.52 29.93a1 1 0 00-.52.88V39a1 1 0 001 1h29a1 1 0 001-1c0-5.29-8.95-12-19-12zM2 38v-6.59A22.43 22.43 0 0112 29c8.46 0 15.7 5.16 16.84 9z" }));
