import { LinkHelper } from "@pm-shared/utils/link";
import { parseQuery, stringify } from "../common/utils/location-utils";

const UrlUtils = {
  getSearchParameters(url: Location) {
    let urlToSearch = url;

    if (!urlToSearch) {
      urlToSearch = window.location;
    }

    return parseQuery(urlToSearch);
  },

  makeUrl(url: string, params = {}, { normalize = true } = {}) {
    /* Normalize the url (add the /{multitenantId}/{OrgType}/{org_id}) and append query params */

    let paramStr = stringify(params);

    let resultUrl = `${url}${paramStr ? "?" + paramStr : ""}`;

    if (normalize) {
      resultUrl = LinkHelper.normalize(resultUrl);
    }

    return resultUrl;
  },

  getCurrentPathname() {
    return window.location.pathname;
  },
};

export default UrlUtils;
