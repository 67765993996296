var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import classnames from "classnames";
import Link from "@pm-shared/utils/link";
function LinkButton(props) {
  const classes = classnames(
    {
      "pm-button": !!props.buttonType,
      [props.buttonType]: !!props.buttonType,
      disabled: props.disabled
    },
    props.className
  );
  const { href, to, disabled } = props;
  if (to && href) {
    throw new Error("LinkButton can have 'href' or 'to' but not both");
  }
  if (!to && !href) {
    throw new Error("LinkButton needs to have 'href' or 'to' but not both");
  }
  if (href && !to) {
    return /* @__PURE__ */ React.createElement("a", { className: classes, href }, /* @__PURE__ */ React.createElement("div", { className: "button-children-container" }, props.children));
  }
  const _a = props, { buttonType, className, children } = _a, linkProps = __objRest(_a, ["buttonType", "className", "children"]);
  return /* @__PURE__ */ React.createElement(Link, __spreadValues({ className: classes, disabled }, linkProps), /* @__PURE__ */ React.createElement("div", { className: "button-children-container" }, children));
}
export default LinkButton;
