import useSWR from "swr";
import qs from "qs";

import { APIPrefix } from "../api";
import { axios } from "@pm-shared/axios";
import { Meld } from "@pm-owner-hub/src/utils/api-types";

interface FetchMeldsFilter {
  limit?: number;
  offset?: number;
  order_by?: string;
  queryString?: string;
}

interface FetchMeldsResponse {
  count: number;
  next?: string;
  previous?: string;
  results: Meld[];
}

export const fetchMelds = ({ limit, offset, order_by, queryString }: FetchMeldsFilter = {}) => {
  const url = `${APIPrefix}/melds/?${qs.stringify({ limit, offset, order_by })}${queryString}`;

  return { response: useSWR<FetchMeldsResponse>(url, () => axios.get(url).then((response) => response.data)), url };
};
