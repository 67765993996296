import classnames from "classnames";
import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MdCheck } from "react-icons/md";

import { FlexJustifyContent } from "../../utils/constants";

export enum ButtonTypes {
  Primary = "primary",
  Neutral = "neutral",
  Tertiary = "tertiary",
  Danger = "danger"
}

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  /**
   * Whether or not the button should act as a submit button.
   * @default false
   */
  submit?: boolean;
  /**
   * Color variant to use. Can be Primary, Neutral, Tertiary, or Danger.
   * @default ButtonTypes.Neutral
   */
  buttonType?: ButtonTypes;
  /**
   * Set flex-direction to columns.
   * @default false
   */
  flexColumns?: boolean;
  /**
   * How to align content within the button.
   * Can be FlexStart, FlexEnd, Center, SpaceAround, SpaceBetween, and SpaceEvenly.
   * @todo This doesn't seem to be used?
   */
  justifyContent?: FlexJustifyContent;
  /**
   * Show success icon.
   * @default false
   */
  showLoading?: boolean;
  /**
   * Show loading icon.
   * @default false
   */
  showSuccess?: boolean;
}

/** @todo When replacing with Chakra, use the integration-link Button as a starting point. */
export const Button = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    submit = false,
    buttonType = ButtonTypes.Neutral,
    flexColumns = false,
    justifyContent = FlexJustifyContent,
    showLoading = false,
    showSuccess = false,
    children,
    className,
    ...elementProps
  } = props;

  const classes = classnames(
    "pm-forms-button",
    `pm-forms-button-${buttonType}`,
    {
      "flex-columns": flexColumns,
      "pm-forms-button-loading": showLoading,
      "pm-forms-button-success": showSuccess
    },
    className ? className.split(" ") : ""
  );

  return (
    <button ref={ref} className={classes} type={submit ? "submit" : "button"} {...elementProps}>
      <div className="pm-forms-button-children-container">{children}</div>

      {showLoading && (
        <div className="pm-forms-button-indication-container">
          <AiOutlineLoading3Quarters className="pm-forms-button-indication" />
        </div>
      )}

      {showSuccess && (
        <div className="pm-forms-button-indication-container">
          <MdCheck className="pm-forms-button-indication" />
        </div>
      )}
    </button>
  );
});

Button.displayName = "Button";

export default Button;
