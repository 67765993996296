import I from "immutable";
import _ from "lodash";
import moment from "moment";
import URLUtils from "../utils/url-utils";
import qs from "qs";
import { stringify } from "../common/utils/location-utils";

export const StatusStates = {
  DONE: "DONE",
  FAIL: "FAIL",
  PENDING: "PENDING"
};

export const Actions = {
  DELETE: "DELETE",
  SAVE: "SAVE",
  FETCH_ALL: "FETCH_ALL",
  UPDATE: "UPDATE",
  FETCH: "FETCH"
};

export let Response = I.Record({
  url: null,
  queryParams: null,
  prevParams: null,
  nextParams: null,
  models: null,
  ids: null,
  cid: null
});

const DEFAULT_CID = "DEFAULT_CID";

class BaseStore {
  constructor({ idField = "id", ...options } = {}) {
    this.state = this._getInitialState();
    this.idField = idField;

    this._paginatedResultsCache = {};

    if (options) {
      this.defaultComparator = options.defaultComparator;
    }

    this.exportPublicMethods({
      clear: this.clear.bind(this),
      deleting: this.deleting.bind(this),
      deleteDone: this.deleteDone.bind(this),
      deleteFail: this.deleteFail.bind(this),
      didFail: this.didFail.bind(this),
      fetchingAll: this.fetchingAll.bind(this),
      fetchAllFail: this.fetchAllFail.bind(this),
      fetchAllDone: this.fetchAllDone.bind(this),
      fetchFail: this.fetchFail.bind(this),
      fetchDone: this.fetchDone.bind(this),
      getAll: this.getAll.bind(this),
      getTotalCount: this.getTotalCount.bind(this),
      getModelState: this.getModelState.bind(this),
      getOne: this.getOne.bind(this),
      getNextPage: this.getNextPage.bind(this),
      getPrevPage: this.getPrevPage.bind(this),
      getStatus: this.getStatus.bind(this),
      getByUrl: this.getByUrl.bind(this),
      isDeleteComplete: this.isDeleteComplete.bind(this),
      receive: this.receive.bind(this),
      receiveOne: this.receiveOne.bind(this),
      saving: this.saving.bind(this),
      saveDone: this.saveDone.bind(this),
      saveFail: this.saveFail.bind(this),
      updating: this.updating.bind(this),
      updateDone: this.updateDone.bind(this),
      updateFail: this.updateFail.bind(this)
    });
  }

  _getInitialState() {
    let initialState = {
      statuses: I.fromJS([]),
      paginations: I.List(),
      responses: I.List(),
      totalCount: 0
    };

    initialState.modelState = this.getInitialModelState();

    return initialState;
  }

  getInitialModelState() {
    return I.fromJS([]);
  }

  getState() {
    return this.state;
  }

  getTotalCount() {
    return this.state.totalCount || this.getModelState().count();
  }

  clear() {
    this.setState(this._getInitialState());
  }

  receive(data) {
    let models = I.fromJS(this._getReceiveResults(data)).map(model => {
      return model.setIn(["_meta", "created_moment"], moment(model.get("created")));
    });

    // Filter out models that haven't changed
    models = models.filter(m => {
      return !this.getModelState().includes(m);
    });

    this.associateResponse(data);

    // Only worry about merging state if there are new or updated models
    if (models.size) {
      let existingUpdates = models
        .filter(model => {
          const mId = model.get(this.idField);
          return this.getModelState().find(m => m.get(this.idField) === mId);
        })
        .map(model => {
          return this.getModelState()
            .find(m => {
              return m.get(this.idField) === model.get(this.idField);
            })
            .mergeDeep(model);
        });

      let newModels = models.filter(model => {
        return !this.getModelState().find(m => m.get(this.idField) === model.get(this.idField));
      });

      let newAndUpdated = newModels.concat(existingUpdates);

      let newState = this.getModelState()
        .filterNot(model => newAndUpdated.find(m => m.get(this.idField) === model.get(this.idField)))
        .concat(newAndUpdated);

      // TODO delete, and delete all `defaultComparator`'s in subclasses, since sorting should be done server side
      if (this.defaultComparator) {
        newState = newState.sort(this.defaultComparator);
      }

      this.setModelState(newState);
    } else {
      // Still trigger
      this.emitChange();
    }
  }

  receiveOne(data) {
    if (data.data) {
      this.receive([data.data]);
    } else {
      this.receive([data]);
    }
  }

  getAll() {
    return this.getModelState();
  }

  getOne(id) {
    // Assume id is an integer until we see it isn't
    let parsedId = parseInt(id);

    if (Number.isNaN(parsedId)) {
      parsedId = id;
    }

    return this.getModelState()
      .filter(x => x.get(this.idField) === parsedId)
      .get(0);
  }

  createNewStatuses({ cid, statusState, msgs, action = Actions.FETCH, data = {} }) {
    let newStatus = { cid, statusState, msgs };

    newStatus.msgs = newStatus.msgs || data.messages;
    newStatus.cid = newStatus.cid || DEFAULT_CID;

    newStatus.action = action;

    return this.getStatuses()
      .filterNot(status => status.get("cid") === newStatus.cid)
      .push(I.Map(newStatus));
  }

  markStatus(...args) {
    let statuses = this.createNewStatuses(...args);
    this.setState({ statuses: statuses });
  }

  augmentAndMarkStatus(dispatchData, action, statusState) {
    dispatchData.action = action;
    dispatchData.statusState = statusState;
    this.markStatus(dispatchData);
  }

  saving(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, Actions.SAVE, StatusStates.PENDING);
  }

  deleting(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, Actions.DELETE, StatusStates.PENDING);
  }

  fetchingAll(dispatchData) {
    this.setModelState(this._getInitialState().modelState);
    this.setState({ paginations: this._getInitialState().paginations });

    this.augmentAndMarkStatus(dispatchData, Actions.FETCH_ALL, StatusStates.PENDING);
  }

  fetchAllFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, Actions.FETCH_ALL, StatusStates.FAIL);
  }

  fetchAllDone(dispatchData) {
    this.receive(dispatchData);

    this.augmentAndMarkStatus(dispatchData, Actions.FETCH_ALL, StatusStates.DONE);
  }

  fetching(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, Actions.FETCH, StatusStates.PENDING);
  }

  fetchFail(dispatchData) {
    this.remove(dispatchData.id);

    this.augmentAndMarkStatus(dispatchData, Actions.FETCH, StatusStates.FAIL);
  }

  fetchDone(dispatchData) {
    this.receiveOne(dispatchData.data);

    this.augmentAndMarkStatus(dispatchData, Actions.FETCH, StatusStates.DONE);
  }

  deleteDone(dispatchData) {
    this.remove(dispatchData.id);

    this.augmentAndMarkStatus(dispatchData, Actions.DELETE, StatusStates.DONE);
  }

  deleteFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, Actions.DELETE, StatusStates.FAIL);
  }

  saveDone(dispatchData) {
    this.receiveOne(dispatchData.data);

    this.augmentAndMarkStatus(dispatchData, Actions.SAVE, StatusStates.DONE);
  }

  updating(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, Actions.UPDATE, StatusStates.PENDING);
  }

  updateDone(dispatchData) {
    this.receiveOne(dispatchData.data);

    this.augmentAndMarkStatus(dispatchData, Actions.UPDATE, StatusStates.DONE);
  }

  updateFail(dispatchData) {
    this.augmentAndMarkStatus(dispatchData, Actions.UPDATE, StatusStates.FAIL);
  }

  saveFail(payload) {
    return this._handleFail(Actions.SAVE, payload);
  }

  getStatus(cid = DEFAULT_CID) {
    return (
      this.getStatuses()
        .filter(status => status.get("cid") === cid)
        .get(0) || I.Map()
    );
  }

  remove(id) {
    let idInt = Number.parseInt(id);
    let state = this.getModelState().filter(x => x.get("id") !== idInt);
    this.setState({
      totalCount: Math.max(this.state.totalCount - 1, 0)
    });

    this.bustCache(I.Set([idInt]));

    this.setModelState(state);
  }

  isDeleteComplete(cid) {
    let status = this.getStatus(cid);
    return status.get("statusState") === StatusStates.DONE && status.get("action") === Actions.DELETE;
  }

  didFail() {
    let status = this.getStatus();

    if (status.size && status.get("statusState") === StatusStates.FAIL) {
      return true;
    }

    return false;
  }

  getModelState() {
    return this.state.modelState;
  }

  setModelState(newState) {
    this.setState({ modelState: newState });
  }

  getStatuses() {
    return this.state.statuses;
  }

  getResponses() {
    return this.state.responses;
  }

  setResponses(responses) {
    this.setState({ responses });
  }

  setStatuses(statuses) {
    this.setState({ statuses });
  }

  getByUrl(url) {
    if (this._paginatedResultsCache[url]) {
      return this._paginatedResultsCache[url];
    }

    let response = this._findResponseByUrl(url);

    if (response) {
      let ids = response.ids;
      let models = this.getModelState()
        .filter(m => ids.contains(m.get("id")))
        .sortBy(m => ids.indexOf(m.get("id")));
      this._paginatedResultsCache[url] = models;
      return models;
    }

    return I.List();
  }

  associateResponse(data) {
    /*
         The format of `data` should {...xhrResponseObject} or {data: xhrResponseObject},
         where xhrResponseObject is a response from `axios`

         We then store the data from the response (ie. data.data) by mapping it to the *unique* combination
         of pathname and the query params from the xhr.

         By mapping the data to the query params and pathname, we can then look up the result of that response with
         .getByUrl()

         This process avoids having to duplicate filtering, sorting, and pagination in the JavaScript world.
         */
    let xhr = this._extractXhr(data);

    if (this._isPaginatedXhr(xhr)) {
      this._updateResponses(xhr);

      this.setState({
        totalCount: data.data.count
      });
    } else {
      let modelData = data;
      if (this._isXhr(xhr)) {
        modelData = data.data;
      }

      if (_.isArray(modelData)) {
        let dataIds = I.Set(modelData.map(m => m.id));
        this.bustCache(dataIds);
      }
    }
  }

  bustCache(ids) {
    let idSet = ids;

    if (idSet instanceof I.List) {
      idSet.toSet();
    } else if (idSet instanceof Array) {
      idSet = I.Set(idSet);
    } else if (_.isInteger(ids)) {
      idSet = I.Set([idSet]);
    }

    let responses = this.getResponses();

    this.getResponses().forEach((resp, index) => {
      if (resp.ids.toSet().intersect(idSet).size) {
        let responseToDelete = responses.get(index);
        if (this._paginatedResultsCache[responseToDelete.url]) {
          delete this._paginatedResultsCache[responseToDelete.url];
        }
      }
    });
  }

  _extractXhr(data) {
    if (data && data.config) {
      return data;
    } else if (data && data.data && data.data.config) {
      return data.data;
    }
  }

  _updateResponses(xhr) {
    let responses = this.getResponses();
    let url = URLUtils.makeUrl(xhr.config.url, xhr.config.params, {
      normalize: false
    });
    let prevResponse = this._findResponseByUrl(url);
    let models = I.fromJS(xhr.data.results);

    // If the models are exactly the same as before, there is nothing to update
    if (!prevResponse || !prevResponse.models || !prevResponse.models.equals(models)) {
      let prevFullUrl = xhr.data.previous;
      let nextFullUrl = xhr.data.next;
      let nextParams = null;
      let prevParams = null;

      if (nextFullUrl) {
        nextFullUrl = decodeURI(nextFullUrl);
        nextParams = I.fromJS(URLUtils.getSearchParameters(new URL(nextFullUrl)));
      }

      if (prevFullUrl) {
        prevFullUrl = decodeURI(prevFullUrl);
        prevParams = I.fromJS(URLUtils.getSearchParameters(new URL(prevFullUrl)));
      }

      let response = new Response({
        url,
        models,
        ids: models.map(m => m.get("id")),
        queryParams: I.fromJS(xhr.config.params),
        prevParams,
        nextParams
      });

      this._paginatedResultsCache[url] = models;

      responses = responses.filter(oldResponse => oldResponse.url !== response.url).concat(I.List([response]));
      this.setResponses(responses);
    }

    return responses;
  }

  getNextPage(url) {
    let response = this.getResponses().find(oldResponse => oldResponse.url === url);

    if (response && response.nextParams) {
      return {
        pathname: URLUtils.getCurrentPathname(),
        search: "?" + stringify(response.nextParams.toJS())
      };
    }
  }

  getPrevPage(url) {
    let response = this.getResponses().find(oldResponse => oldResponse.url === url);

    if (response && response.prevParams) {
      return {
        pathname: URLUtils.getCurrentPathname(),
        search: "?" + stringify(response.prevParams.toJS())
      };
    }
  }

  _getReceiveResults(data) {
    if (data && data.data && data.data.results) {
      return data.data.results;
    }

    if (data && data.data && _.isArray(data.data)) {
      return data.data;
    }

    return data;
  }

  _handleFail(action, { cid, msgs }) {
    let statuses = this.getStatuses()
      .filterNot(status => status.get("cid") === cid)
      .push(
        I.Map({
          fail: true,
          cid,
          msgs,
          action,
          statusState: StatusStates.FAIL
        })
      );
    this.setState({ statuses: statuses });
  }

  _getDataState(page, pageSize) {
    if (page) {
      this.state
        .get(this.dataName)
        .sort((item1, item2) => parseInt(item2.id) - parseInt(item1.id))
        .skipWhile((value, key) => key < page * pageSize)
        .takeWhile((value, key) => key < page * pageSize + pageSize);
    }
    return this.state.get(this.dataName);
  }

  _findResponseByUrl(url) {
    const [path, queryString] = url.split("?");

    // Sort all the query string params that are arrays. Since they should be considered equal even if the param value is in
    // a different index than another array with the same values.
    const queryParams = I.fromJS(qs.parse(queryString)).map(param => {
      if (param instanceof I.List) {
        return param.sort();
      } else {
        return param;
      }
    });
    return this.getResponses().find(oldResponse => {
      const oldPath = oldResponse.url.split("?")[0];
      return oldPath === path && I.is(queryParams, oldResponse.queryParams);
    });
  }

  _isXhr(possibleXhr) {
    // Format of axios xhr
    return possibleXhr && possibleXhr.statusText && possibleXhr.config;
  }

  _isPaginatedXhr(xhr) {
    return xhr && xhr.data.results;
  }
}

BaseStore.config = {
  onSerialize: state => {
    return I.Map(state).toJS();
  },

  onDeserialize: data => {
    return I.fromJS(data).toObject();
  }
};

export default BaseStore;
