import { EuiSelectableOption, EuiSelectableProps } from "@elastic/eui";
import { useHistory, useLocation } from "react-router-dom";

import { BaseURLFilter, DeepPartial, SavedFilterObject } from "../BaseFilterClasses";

const ANY_OF_SUFFIX = "" as const;
const NONE_OF_SUFFIX = "_exclude" as const;

export const PM_SELECTABLE_FILTER_OPERATORS = {
  ANY_OF: "Any of",
  NONE_OF: "None of",
  NOT_CONTAINS_ALL: "Not contains all",
  CONTAINS_ALL: "Contains all",
  ALL_OF: "All of",
  NOT_ALL_OF: "Not all of",
  MISSING: "Missing",
  PRESENT: "Present",
} as const;

export interface PmSelectableFilterProps {
  text: string;
  filterName: string;
  options: Array<{ label: string; queryParamValue: string }>;
  queryParamKeyPrefix: string;
  componentProps: Pick<EuiSelectableProps, "searchable" | "singleSelection">;
  popoverWidth: string;
  alwaysShow?: boolean;
  allowedOperators: PmSelectableFilterAllowedOperators[];
}

export type PmSelectableFilterAllowedOperators =
  typeof PM_SELECTABLE_FILTER_OPERATORS[keyof typeof PM_SELECTABLE_FILTER_OPERATORS];

export const PM_SELECTABLE_FILTER_INPUT_TYPES = { SELECT: "select", BOOLEAN: "boolean" } as const;

export type PmSelectableFilterInputTypes =
  typeof PM_SELECTABLE_FILTER_INPUT_TYPES[keyof typeof PM_SELECTABLE_FILTER_INPUT_TYPES];

export type PmSelectableFilterOperatorValue =
  | ({
      filterType: typeof BaseSelectableFilterClass.type;
    } & (
      | {
          operator: Extract<
            PmSelectableFilterAllowedOperators,
            "Any of" | "None of" | "Not contains all" | "Contains all" | "All of" | "Not all of"
          >;
          options: EuiSelectableOption[];
          type: Extract<PmSelectableFilterInputTypes, "select">;
        }
      | {
          operator: Extract<PmSelectableFilterAllowedOperators, "Missing" | "Present">;
          type: Extract<PmSelectableFilterInputTypes, "boolean">;
        }
    ))
  | null;

export class BaseSelectableFilterClass extends BaseURLFilter<
  PmSelectableFilterAllowedOperators,
  Pick<EuiSelectableProps, "searchable" | "singleSelection">,
  PmSelectableFilterProps
> {
  static type = "selectable_filter" as const;

  constructor(config: PmSelectableFilterProps, overrides?: DeepPartial<PmSelectableFilterProps>) {
    super(config, overrides);
  }

  getCurrentlyAppliedOperatorAndValue({
    savedFilter,
    location,
  }: {
    savedFilter: SavedFilterObject;
    location: ReturnType<typeof useLocation>;
  }): PmSelectableFilterOperatorValue {
    const currentSearchParams = new URLSearchParams(location.search);
    for (const operator of this.getAllowedOperators()) {
      const value = savedFilter
        ? this.getSavedFilterValue({ operator, savedFilter })
        : this.getSearchParamValue({ currentSearchParams, operator });
      if (!value) {
        continue;
      }
      switch (operator) {
        case PM_SELECTABLE_FILTER_OPERATORS.MISSING:
          if (value === "false") {
            return {
              operator: PM_SELECTABLE_FILTER_OPERATORS.MISSING,
              filterType: BaseSelectableFilterClass.type,
              type: PM_SELECTABLE_FILTER_INPUT_TYPES.BOOLEAN,
            };
          }
          break;
        case PM_SELECTABLE_FILTER_OPERATORS.PRESENT:
          if (value === "true") {
            return {
              operator: PM_SELECTABLE_FILTER_OPERATORS.MISSING,
              filterType: BaseSelectableFilterClass.type,
              type: PM_SELECTABLE_FILTER_INPUT_TYPES.BOOLEAN,
            };
          }
          break;
        case PM_SELECTABLE_FILTER_OPERATORS.ALL_OF:
        case PM_SELECTABLE_FILTER_OPERATORS.ANY_OF:
        case PM_SELECTABLE_FILTER_OPERATORS.NONE_OF:
        case PM_SELECTABLE_FILTER_OPERATORS.NOT_ALL_OF:
        case PM_SELECTABLE_FILTER_OPERATORS.CONTAINS_ALL:
        case PM_SELECTABLE_FILTER_OPERATORS.NOT_CONTAINS_ALL:
          // saved filter data is an array, query param is a comma-delimited string
          // eslint-disable-next-line no-case-declarations
          const parsedValues: string[] = typeof value === "string" ? value.split(",") : value;
          // eslint-disable-next-line no-case-declarations
          const operatorMatches = this.getOptions().some((opt) => parsedValues.includes(opt.queryParamValue));
          if (!operatorMatches) {
            continue;
          }
          return {
            operator,
            options: this.getOptions().map((opt) => ({
              label: opt.label,
              checked: parsedValues.some((parsedValue: string) => parsedValue === opt.queryParamValue)
                ? "on"
                : undefined,
            })),
            filterType: BaseSelectableFilterClass.type,
            type: PM_SELECTABLE_FILTER_INPUT_TYPES.SELECT,
          };
        default:
          break;
      }
    }
    return null;
  }

  getDefaultMappedOptions(): EuiSelectableOption[] {
    return this.getOptions().map((opt) => ({
      label: opt.label,
      checked: undefined,
    }));
  }

  getIsSingleSelect(): boolean {
    return !!this.getConfig().componentProps.singleSelection;
  }

  getNewValueFromNewOperator({
    newOperator,
    currentPendingOperatorAndValue,
  }: {
    newOperator: PmSelectableFilterAllowedOperators;
    currentPendingOperatorAndValue: NonNullable<PmSelectableFilterOperatorValue>;
  }): NonNullable<PmSelectableFilterOperatorValue> {
    const oldType = currentPendingOperatorAndValue.type;
    let type: PmSelectableFilterInputTypes = PM_SELECTABLE_FILTER_INPUT_TYPES.BOOLEAN;
    switch (newOperator) {
      case PM_SELECTABLE_FILTER_OPERATORS.MISSING:
      case PM_SELECTABLE_FILTER_OPERATORS.PRESENT:
        return { operator: newOperator, type, filterType: BaseSelectableFilterClass.type };
      default:
        type = PM_SELECTABLE_FILTER_INPUT_TYPES.SELECT;
        if (oldType === PM_SELECTABLE_FILTER_INPUT_TYPES.BOOLEAN) {
          return { operator: newOperator, type, options: [], filterType: BaseSelectableFilterClass.type };
        } else {
          return {
            operator: newOperator,
            type,
            options: currentPendingOperatorAndValue.options,
            filterType: BaseSelectableFilterClass.type,
          };
        }
    }
  }

  getOnApplyClick({
    location,
    closePopover,
    history,
    savedFilter,
    pendingOperatorAndValue,
  }: {
    location: ReturnType<typeof useLocation>;
    history: ReturnType<typeof useHistory>;
    closePopover: () => void;
    savedFilter: SavedFilterObject;
    pendingOperatorAndValue: NonNullable<PmSelectableFilterOperatorValue>;
  }) {
    return () => {
      const savedSearchParams = this.getQueryParamsFromSavedFilter({ savedFilter, location });
      this.deleteAllParamValues({ paramsToMutate: savedSearchParams, location });
      const key = this.getFullQueryParamKey({ operator: pendingOperatorAndValue.operator, target: "url" });
      switch (pendingOperatorAndValue.operator) {
        case PM_SELECTABLE_FILTER_OPERATORS.PRESENT:
          savedSearchParams.set(key, "true");
          break;
        case PM_SELECTABLE_FILTER_OPERATORS.MISSING:
          savedSearchParams.set(key, "false");
          break;
        default:
          if (pendingOperatorAndValue.options.length === 0) {
            return;
          }
          // eslint-disable-next-line no-case-declarations
          const value = pendingOperatorAndValue.options
            .filter((mappedOption) => mappedOption.checked === "on")
            .map((mappedOption) => this.getOptions().find((opt) => opt.label === mappedOption.label)?.queryParamValue)
            .filter(Boolean)
            .join(",");
          savedSearchParams.set(key, value);
      }
      history.replace({
        pathname: location.pathname,
        search: savedSearchParams.toString(),
      });
      closePopover();
    };
  }

  getAppliedFilterCount({
    currentOperatorAndValue,
  }: {
    currentOperatorAndValue: PmSelectableFilterOperatorValue;
  }): number {
    if (!currentOperatorAndValue) {
      return 0;
    }
    switch (currentOperatorAndValue.type) {
      case PM_SELECTABLE_FILTER_INPUT_TYPES.SELECT:
        return currentOperatorAndValue.options.filter((opt) => opt.checked === "on").length;
      case PM_SELECTABLE_FILTER_INPUT_TYPES.BOOLEAN:
        return 1;
      default:
        return 0;
    }
  }

  /*
   * The saved filter objects don't have the '[]' suffix that the query params do
   */
  protected getFullQueryParamKey({ operator, target }: { operator: string; target: "savedFilter" | "url" }): string {
    const bracketString = this.getIsSingleSelect() ? "" : "[]";

    switch (operator) {
      case "Any of":
        return this.getQueryParamKeyPrefix() + ANY_OF_SUFFIX + (target === "url" ? bracketString : "");
      case "None of":
        return this.getQueryParamKeyPrefix() + NONE_OF_SUFFIX + (target === "url" ? bracketString : "");
      default:
        return this.getQueryParamKeyPrefix();
    }
  }

  private getOptions() {
    return this.getConfig().options;
  }
}
