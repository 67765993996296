import jstz from "jstz";

import NotificationSettingsActions from "../actions/notification-settings-actions";

let NotificationSettingsMixins = {
  componentWillReceiveProps(nextProps) {
    if (
      !this.state.timezoneHasBeenSet &&
      nextProps.notificationSettings &&
      !nextProps.notificationSettings.get("timezone_has_been_set")
    ) {
      this.setState({ timezoneHasBeenSet: true });
      setTimeout(() => {
        NotificationSettingsActions.update(
          nextProps.notificationSettings
            .merge({
              timezone: jstz.determine().name(),
              timezone_has_been_set: true
            })
            .toJS(),
          { showSuccess: false }
        );
      }, 0);
    }
  }
};

export default NotificationSettingsMixins;
