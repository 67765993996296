import useSWR from "swr";
import qs from "qs";

import { axios } from "@pm-shared/axios";
import { Owner } from "@pm-shared/owner";
import { APIPrefix } from "../api";

// Fetch Owners

interface FetchOwnersFilters {
  associated_to_property?: string;
  prop?: string;
  search_keys?: string;
  // django defaults
  limit?: number;
  offset?: number;
  order_by?: string;
}

interface FetchOwnersResponse {
  count: number;
  next?: string;
  previous?: string;
  results: Owner[];
}

export const fetchOwners = (filter?: FetchOwnersFilters) => {
  const url = filter ? `${`${APIPrefix}/owners/`}?${qs.stringify(filter)}` : `${APIPrefix}/owners/`;

  return { response: useSWR<FetchOwnersResponse>(url, () => axios.get(url).then((response) => response.data)), url };
};
