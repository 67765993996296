import I from "immutable";
import React from "react";

import ButtonGroup from "@pm-app/components/forms/button-group/button-group";
import Button, { ButtonTypes } from "@pm-app/components/forms/button/button";
import FormGroup from "@pm-app/components/forms/form-group/form-group";
import Modal, { ModalFooter } from "@pm-app/components/modal/modal";

import { ErrorHandler } from "../../../../app/utils/ErrorHandler";
import messageActions from "../../common/actions/message-actions";
import CalendarUtils from "../../utils/calendar-utils";
import TenantMeldCancellationRequestAPI from "../apis/manager-tenant-cancellation-request-api";

export enum CancelNoteModes {
  DEFAULT = "DEFAULT",
  DELETE = "DELETE"
}

export interface CancelNoteProps {
  note: I.Map<string, any>;
  canDelete: boolean;
  onDelete?: () => void;
}

interface CancelNoteState {
  mode: CancelNoteModes;
  deleting: boolean;
  errors: string[];
}

export default class CancelNote extends React.Component<CancelNoteProps, CancelNoteState> {
  constructor(props: CancelNoteProps) {
    super(props);

    this.state = {
      mode: CancelNoteModes.DEFAULT,
      deleting: false,
      errors: []
    };
  }

  render() {
    return (
      <div>
        {this.props.note.get("cancel_notes")}
        <br />
        {CalendarUtils.formatDayMonthTime(this.props.note.get("updated"))}
        <br />
        {this.renderDelete()}
        {this.renderMode()}
      </div>
    );
  }

  renderDelete() {
    if (this.props.canDelete) {
      return <a onClick={() => this.setMode(CancelNoteModes.DELETE)}>Delete</a>;
    }
  }

  renderMode() {
    if (CancelNoteModes.DELETE === this.state.mode) {
      return (
        <Modal
          className={"cancel-notes-modal"}
          header={"Delete Cancellation Request"}
          isOpen={true}
          onClose={() => this.setMode(CancelNoteModes.DEFAULT)}
        >
          <FormGroup>
            <div className={"cancel-notes-body"}>
              Are you sure you want to delete this request to cancel from the Tenant?
            </div>
          </FormGroup>
          <ModalFooter>
            <ButtonGroup>
              <Button
                buttonType={ButtonTypes.Primary}
                data-test="delete-button"
                onClick={() => {
                  this.setState({ deleting: true });
                  TenantMeldCancellationRequestAPI.deleteNote(this.props.note.get("id"))
                    .then(() => {
                      if (this.props.onDelete) {
                        this.props.onDelete();
                      }
                    })
                    .catch(result => {
                      messageActions.storeError({
                        body: result.messages[0]
                      });
                    })
                    .then(() => {
                      this.setState({ deleting: false });
                      this.setMode(CancelNoteModes.DEFAULT);
                    })
                    .catch(error => ErrorHandler.handleAxiosError(error));
                }}
                disabled={this.state.deleting}
              >
                Delete
              </Button>
              <Button
                buttonType={ButtonTypes.Neutral}
                onClick={() => this.setMode(CancelNoteModes.DEFAULT)}
                disabled={this.state.deleting}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </Modal>
      );
    }
  }

  setMode(mode: CancelNoteModes) {
    this.setState({ mode });
  }
}
