import { parsePhoneNumber } from "libphonenumber-js";

let PhoneUtils = {
  format(phoneNumber: string) {
    try {
      const parsed = parsePhoneNumber(phoneNumber, "US");
      return parsed.format("NATIONAL");
    } catch (e) {
      return phoneNumber;
    }
  },

  isValid(phoneNumber: string) {
    try {
      const parsed = parsePhoneNumber(phoneNumber, "US");
      return parsed.isValid();
    } catch (e) {
      return false;
    }
  }
};

export default PhoneUtils;
