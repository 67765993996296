const multitenancy = window.location.pathname.split("/");
export const routePrefix = `/${multitenancy[1]}/${multitenancy[2]}/${multitenancy[3]}`;
export const APIPrefix = `/${multitenancy[1]}/${multitenancy[2]}/${multitenancy[3]}/api`;

export const DEFAULT_MELDS_LIST_LIMIT = 10;
export const DEFAULT_OWNERS_LIST_LIMIT = 10;

export * from "./api/account";
export * from "./api/melds";
export * from "./api/metrics";
export * from "./api/owners";
export * from "./api/vendors";
export { fetchWorkflowConfig } from "@pm-property-management-hub/src/utils/api/workflows";
export type { WorkflowConfig } from "@pm-property-management-hub/src/utils/api/workflows";
export type { WorkflowCondition } from "@pm-property-management-hub/src/utils/api/workflows";
export { updateMeldWorkflows } from "@pm-property-management-hub/src/utils/api/workflows";
export { createMeldWorkflow } from "@pm-property-management-hub/src/utils/api/workflows";
export { fetchMeldWorkflow } from "@pm-property-management-hub/src/utils/api/workflows";
export { fetchWorkflowTemplates } from "@pm-property-management-hub/src/utils/api/workflows";
export { fetchMeldWorkflows } from "@pm-property-management-hub/src/utils/api/workflows";
export { conditionSupportsAnd } from "@pm-property-management-hub/src/components/workflows/block-condition";
export { fetchOwnerService } from "@pm-property-management-hub/src/utils/api/owner-services";
export { createOwnerService } from "@pm-property-management-hub/src/utils/api/owner-services";
export { updateOwnerService } from "@pm-property-management-hub/src/utils/api/owner-services";
export { fetchEmbedUrl } from "@pm-property-management-hub/src/utils/api/actionable-insights";
