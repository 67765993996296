var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import I from "immutable";
import IdleTimer from "react-idle-timer";
import React from "react";
export default function SetIntervalWhileActive(ComposedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        intervals: I.Set(),
        isIdle: false
      };
      this.setInterval = this.setInterval.bind(this);
      this.clearIntervals = this.clearIntervals.bind(this);
      this.callAndSetInterval = this.callAndSetInterval.bind(this);
      this.handleIdle = this.handleIdle.bind(this);
      this.handleActive = this.handleActive.bind(this);
    }
    render() {
      return /* @__PURE__ */ React.createElement(IdleTimer, { timeout: 5 * 60 * 1e3, idleAction: this.handleIdle, activeAction: this.handleActive }, /* @__PURE__ */ React.createElement(
        ComposedComponent,
        __spreadValues({
          setInterval: this.setInterval,
          clearIntervals: this.clearIntervals,
          callAndSetInterval: this.callAndSetInterval
        }, this.props)
      ));
    }
    handleIdle() {
      this.setState({ isIdle: true });
    }
    handleActive() {
      this.setState({ isIdle: false });
    }
    setInterval(handler, timeout) {
      const funcToCall = () => {
        if (!this.state.isIdle) {
          handler();
        }
      };
      const interval = window.setInterval.call(null, funcToCall, timeout);
      this.setState({ intervals: this.state.intervals.add(interval) });
    }
    clearIntervals() {
      this.state.intervals.map(clearInterval);
    }
    componentWillUnmount() {
      this.clearIntervals();
    }
    callAndSetInterval(handler, timeout) {
      handler();
      this.setInterval(handler, timeout);
    }
  };
}
