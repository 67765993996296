var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { Button, IconButton } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { Box, Flex, Stack } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { SkeletonGroup } from "@pm-owner-hub/src/components/skeleton-group";
import React, { useEffect, useState } from "react";
import { IoRemoveCircleOutline } from "react-icons/io5";
import { BooleanCondition } from "./conditions/boolean-condition";
import { ChoiceCondition } from "./conditions/choice-condition";
import { DecimalCondition } from "./conditions/decimal-condition";
import { IntegerCondition } from "./conditions/integer-condition";
import { TextCondition } from "./conditions/text-condition";
import { TimestampCondition } from "./conditions/timestamp-condition";
import nextId from "react-id-generator";
import Features from "@pm-assets/js/common/feature-flags";
import { omit } from "lodash";
export const ConditionOpType = (props) => {
  var _a;
  const { change, valueIndex, lookup_name, operator_key, values } = props;
  const targetChange = (e) => {
    const clone = [...values];
    clone[valueIndex] = e.target.value;
    change(lookup_name, operator_key, clone);
  };
  switch ((_a = props.mapped_config) == null ? void 0 : _a.condition_type) {
    case "choice":
      return /* @__PURE__ */ React.createElement(ChoiceCondition, __spreadValues({ onChange: targetChange }, props));
    case "text":
      return /* @__PURE__ */ React.createElement(TextCondition, __spreadValues({ onChange: targetChange }, props));
    case "decimal":
      return /* @__PURE__ */ React.createElement(DecimalCondition, __spreadValues({ onChange: (number) => targetChange({ target: { value: number } }) }, props));
    case "integer":
      return /* @__PURE__ */ React.createElement(IntegerCondition, __spreadValues({ onChange: (number) => targetChange({ target: { value: number } }) }, props));
    case "boolean":
      return /* @__PURE__ */ React.createElement(BooleanCondition, __spreadValues({ onChange: targetChange }, props));
    case "timestamp":
      const intervalOps = ["greater_than_past", "lesser_than_past", "greater_than_future", "lesser_than_future"];
      return /* @__PURE__ */ React.createElement(
        TimestampCondition,
        __spreadValues({
          onChange: (value) => {
            if (value) {
              if (intervalOps.includes(operator_key) && typeof value === "string") {
                targetChange({ target: { value } });
              }
              if (!intervalOps.includes(operator_key) && (value == null ? void 0 : value.toISOString)) {
                const date = value == null ? void 0 : value.toISOString();
                if (values[valueIndex] !== date) {
                  targetChange({ target: { value: date } });
                }
              }
              if (!intervalOps.includes(operator_key) && value.start_day) {
                targetChange({ target: { value } });
              }
            } else {
              targetChange({ target: { value: "" } });
            }
          }
        }, props)
      );
    default:
      return /* @__PURE__ */ React.createElement(React.Fragment, null, "ConditionOpType missing condition_type");
  }
};
export const conditionSupportsAnd = (type, operator, index, values) => {
  if (Number.isInteger(index) && ["in_range", "not_in_range", "between"].includes(operator || "") && index === 0 && (values == null ? void 0 : values.length) && values.length < 2) {
    return true;
  }
  if (!operator || operator === "") {
    return false;
  }
  if (type === "text") {
    return true;
  }
  return type === "choice" && !["missing", "present"].includes(operator || "");
};
export const And = ({ valueIndex, value, condition_type, operator_key, clickAnd }) => {
  if (conditionSupportsAnd(condition_type, operator_key, valueIndex, value)) {
    return /* @__PURE__ */ React.createElement(
      Button,
      {
        "data-test": "workflows.form.select.block_operator.action.and",
        onClick: clickAnd,
        marginLeft: 2,
        size: "xs",
        textTransform: "uppercase",
        variant: "primary"
      },
      "And"
    );
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null);
};
export const RemoveIcon = ({ show, clickRemove }) => {
  if (show) {
    return /* @__PURE__ */ React.createElement(
      IconButton,
      {
        "data-test": "workflows.form.select.block_operator.action.remove",
        "aria-label": "Remove Value",
        fontSize: "lg",
        icon: /* @__PURE__ */ React.createElement(Icon, { as: IoRemoveCircleOutline }),
        onClick: clickRemove,
        padding: 0,
        variant: "ghost",
        _hover: { shadow: "none" }
      }
    );
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null);
};
function GetWorkflowOperators(mapped_config) {
  let filteredMappedConfig = Object.assign({}, mapped_config);
  if (Features.isInRecurringRangeEnabled() && mapped_config) {
    filteredMappedConfig.operators = omit(filteredMappedConfig.operators, "in_recurring_range");
  }
  return filteredMappedConfig;
}
export const BlockCondition = ({
  lookup_name,
  operator_key,
  value,
  mapped_config,
  config_list,
  conditionCount,
  conditionCountId,
  groupIndex,
  remove,
  change,
  error
}) => {
  if (!config_list) {
    return /* @__PURE__ */ React.createElement(SkeletonGroup, null);
  }
  const [mappedConfig, setMappedConfig] = useState(mapped_config);
  useEffect(() => {
    if (mapped_config) {
      const filteredMappedConfig = GetWorkflowOperators(mapped_config);
      setMappedConfig(filteredMappedConfig);
    }
  }, [mapped_config]);
  return /* @__PURE__ */ React.createElement(Stack, { spacing: 0, _notLast: { borderBottomWidth: 1, borderBottomColor: "blue.50", pb: 4 } }, /* @__PURE__ */ React.createElement(Flex, { alignItems: "center", justifyContent: "space-between" }, /* @__PURE__ */ React.createElement(
    Select,
    {
      "data-test": "workflows.form.select.block_condition",
      onChange: (e) => {
        change(e.target.value, "", [""]);
      },
      placeholder: "Select an Optional Condition",
      value: lookup_name || ""
    },
    config_list && config_list.map((c) => {
      return /* @__PURE__ */ React.createElement(
        "option",
        {
          key: c.lookup_name,
          value: c.lookup_name,
          "data-test": "workflows.form.select.block_condition.option"
        },
        c.readable
      );
    })
  ), conditionCount > 1 && /* @__PURE__ */ React.createElement(
    IconButton,
    {
      "data-test": "workflows.form.action.block_condition.remove",
      "aria-label": "Remove Condition",
      fontSize: "lg",
      icon: /* @__PURE__ */ React.createElement(Icon, { as: IoRemoveCircleOutline }),
      onClick: () => remove(groupIndex, conditionCountId),
      padding: 0,
      variant: "ghost",
      _hover: { shadow: "none" }
    }
  )), lookup_name && mapped_config && mappedConfig && /* @__PURE__ */ React.createElement(Box, { key: nextId(`box-${lookup_name}-${operator_key}-${conditionCountId}-`), paddingLeft: 4, paddingTop: 2 }, /* @__PURE__ */ React.createElement(Flex, { alignItems: "center" }, /* @__PURE__ */ React.createElement(
    Select,
    {
      isInvalid: !!error,
      "data-test": "workflows.form.select.block_operator",
      onChange: (e) => {
        if (!["missing", "present"].includes(e.target.value)) {
          change(lookup_name, e.target.value, [""]);
        } else {
          change(lookup_name, e.target.value, []);
        }
      },
      placeholder: "Select an Operator",
      value: operator_key
    },
    Object.keys(mappedConfig.operators).filter((o) => {
      var _a;
      return (_a = mapped_config.operators[o]) == null ? void 0 : _a.human_label;
    }).map((o) => /* @__PURE__ */ React.createElement(
      "option",
      {
        key: nextId(`option-${lookup_name}-${operator_key}-${conditionCountId}-${o}-`),
        value: o,
        "data-test": "workflows.form.select.block_operator.option"
      },
      mapped_config.operators[o].human_label
    ))
  )), /* @__PURE__ */ React.createElement(Stack, { spacing: 2 }, value && (Array.isArray(value) ? value : [value]).map((v, valueIndex, items) => {
    const clickAnd = () => {
      const clone = [...value, ""];
      change(lookup_name, operator_key, clone);
    };
    const clickRemove = () => {
      const clone = [...value];
      clone.splice(valueIndex, 1);
      change(lookup_name, operator_key, clone);
    };
    return /* @__PURE__ */ React.createElement(
      Flex,
      {
        alignItems: "center",
        key: `condition-${lookup_name}-${operator_key}-${conditionCountId}-${valueIndex}-`,
        paddingLeft: 4,
        paddingTop: 2,
        width: "100%"
      },
      /* @__PURE__ */ React.createElement(Flex, { alignItems: "center", justifyContent: "space-between", width: "100%" }, /* @__PURE__ */ React.createElement(
        ConditionOpType,
        {
          lookup_name,
          value: v,
          values: items,
          conditionCount,
          conditionCountId,
          operator_key,
          mapped_config,
          config_list,
          valueIndex,
          change,
          error
        }
      ), /* @__PURE__ */ React.createElement(Stack, { isInline: true }, /* @__PURE__ */ React.createElement(
        And,
        __spreadValues({}, {
          valueIndex,
          value,
          condition_type: mapped_config.condition_type,
          operator_key,
          change,
          clickAnd
        })
      ), /* @__PURE__ */ React.createElement(RemoveIcon, __spreadValues({}, { show: Array.isArray(value) && (value == null ? void 0 : value.length) > 1, clickRemove }))))
    );
  }))));
};
