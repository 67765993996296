import { Unit } from "@pm-owner-hub/src/utils/api-types";

export function getDesignator(unit: Unit) {
  return (
    [
      { prefix: "APT", number: unit.apartment },
      { prefix: "STE", number: unit.suite },
      { prefix: "UNIT", number: unit.unit },
      { prefix: "RM", number: unit.room },
      { prefix: "DEPT", number: unit.department },
    ]
      .filter((designator) => {
        return !!designator.number;
      })
      .map((designator) => {
        return [designator.prefix, designator.number].join(" ");
      })
      .join(", ") || ""
  );
}
