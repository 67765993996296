import React from "react";

import { AuthUtils } from "../../utils/auth-utils";

let AccountChooserMixin = {
  renderChooseAccountItem() {
    if (AuthUtils.hasMultiOrgs()) {
      return (
        <li className="menu-item">
          <a href="/">Switch Accounts</a>
        </li>
      );
    }
  }
};

export default AccountChooserMixin;
