const filerevs = window.PM.env.filerevs;

export default {
  getStatic(fragment: string) {
    let staticUrl = window.PM?.env?.staticUrl;
    staticUrl ||= "/";
    if (!staticUrl.endsWith("static/")) {
      staticUrl = staticUrl + "static/";
    }
    let file = fragment;

    if (file.startsWith("static/")) {
      file = file.substring(7);
    }

    if (filerevs) {
      if (file in filerevs) {
        return staticUrl + filerevs[file];
      }
    }

    return staticUrl + file;
  },
};
