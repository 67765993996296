import { authParams, mApi, tApi, vApi } from "../../common/api";
import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";
import { numOrStr } from "../../types/common";
import { LinkHelper } from "@pm-shared/utils/link";
import { ErrorHandler } from "@pm-app/utils/ErrorHandler";
import {
  MeldDetailView,
  MeldListView,
  RecurringMeldDetail,
  TenantMeldDetail,
  TenantMeldListView,
  UnitMeldListView,
  PropertyMeldListView,
  VendorMeldDetailView,
} from "@pm-openapi";
import { AxiosPromise } from "axios";

class MeldAPI extends BaseApi {
  static cancel(data: any, id: numOrStr) {
    return this.patch(ApiUrls.meldCancel(id), data);
  }

  static markComplete(id: numOrStr, data: any) {
    return this.patch(ApiUrls.completeMeld(id), data);
  }

  static review(id: numOrStr, data: any) {
    return this.patch(ApiUrls.reviewMeld(id), data);
  }

  static getAll(options: any) {
    return this.get(ApiUrls.meldsList, options);
  }

  static getOne(id: numOrStr): AxiosPromise<MeldDetailView | TenantMeldDetail | VendorMeldDetailView> {
    const isManager = LinkHelper.getOrgType() === "m";
    const isTenant = LinkHelper.getOrgType() === "t";
    const isVendor = LinkHelper.getOrgType() === "v";
    if (isManager) {
      return mApi.mOIApiMeldsRead({
        id: typeof id === "number" ? id : parseInt(id, 10),
        ...authParams,
      });
    } else if (isTenant) {
      return tApi.tOIApiMeldsRead({
        id: typeof id === "number" ? id.toString() : id,
        ...authParams,
      });
    } else if (isVendor) {
      return vApi.vOIApiMeldsRead({ id: id.toString(), ...authParams });
    } else {
      ErrorHandler.handleError(Error("Unknown org type when trying to getOne meld"));
    }
    return mApi.mOIApiMeldsRead({ id: typeof id === "number" ? id : parseInt(id, 10), ...authParams });
  }

  static getOneRecurringMeld(id: numOrStr) {
    return mApi.mOIApiMeldsRecurringRead({
      id: typeof id === "number" ? id : parseInt(id, 10),
      ...authParams,
    });
  }

  static updateRecurringMeld(ticket: RecurringMeldDetail) {
    return mApi.mOIApiMeldsRecurringPartialUpdate({ id: ticket.id!, data: ticket, ...authParams });
  }

  static update(ticket: MeldDetailView) {
    return mApi.mOIApiMeldsPartialUpdate({ id: ticket.id!, data: ticket, ...authParams });
  }

  static updateTags(ticket: any) {
    return this.patch(ApiUrls.meldTags(ticket.id), ticket);
  }

  static fetchMeldsByUnit(unitId: numOrStr): AxiosPromise<UnitMeldListView[]> {
    return mApi.mOIApiMeldsUnitRead({ id: unitId.toString(), ...authParams });
  }

  static fetchMeldsByProp(propId: numOrStr): AxiosPromise<PropertyMeldListView[]> {
    return mApi.mOIApiMeldsPropertyRead({ id: propId.toString(), ...authParams });
  }

  static fetchMeldsByTenant(tenantId: numOrStr) {
    return this.get(ApiUrls.tenantMeldList(tenantId));
  }

  static fetchMeldsByVendor(vendorId: numOrStr) {
    return this.get(ApiUrls.vendorMeldList(vendorId));
  }

  static fetchFinishableTickets(meldId: numOrStr) {
    return this.get(ApiUrls.finishableTicketList(meldId));
  }

  static accept(meldId: numOrStr, data: any, options?: any) {
    return this.patch(ApiUrls.managerScheduleMeld(meldId), data, options);
  }

  static create(data: MeldListView) {
    return mApi.mOIApiMeldsCreate({ data, ...authParams });
  }

  static createTenantMeld(data: TenantMeldListView) {
    return tApi.tOIApiMeldsCreate({ data, ...authParams });
  }

  static updateTenantMeld(data: TenantMeldDetail) {
    if (data.id) {
      return tApi.tOIApiMeldsUpdate({ id: data.id.toString(), data, ...authParams });
    }
  }

  static assignMaintenance(id: numOrStr, maint: any) {
    return this.patch(ApiUrls.assignMaintenance(id), maint);
  }

  static assignCoordinator(ticket: any) {
    return this.patch(ApiUrls.assignCoordinator(ticket.id), ticket);
  }

  static updateSegments(id: numOrStr, data: any, options?: any) {
    return this.patch(ApiUrls.meldRescheduleSegments(id), data, options);
  }

  static updateTenants(meldId: numOrStr, tenants: any) {
    return this.patch(ApiUrls.meldTenants(meldId), { tenants });
  }

  static updateUserGroups(meldId: numOrStr, userGroups: any) {
    return this.patch(ApiUrls.updateMeldUserGroups(meldId), {
      user_groups: userGroups,
    });
  }

  static fetchForCalendar({ url, xhrOptions }: { url?: string; xhrOptions?: any } = {}) {
    if (url) {
      return this.get(url, { normalizeUrl: false, ...xhrOptions });
    }
    return this.get(ApiUrls.calendarMelds, xhrOptions);
  }

  static fetchOneForCalendar(id: numOrStr, xhrOptions: any) {
    return this.get(ApiUrls.calendarMeldDetail(id), xhrOptions);
  }

  static shareMeldFiles(id: numOrStr, data: any) {
    return this.patch(ApiUrls.shareMeldFiles(id), data);
  }

  static downloadMeldFiles(id: numOrStr, data: any) {
    return this.post(ApiUrls.downloadMeldFiles(id), data);
  }

  static addNewVendorAppointment(id: number, data: any, options: any) {
    return this.post(ApiUrls.addNewVendorAppointment(id), data, options);
  }
  static rescheduleVendorAppointment(id: number, data: any, options: any) {
    return this.patch(ApiUrls.rescheduleVendorAppointment(id), data, options);
  }
  static cancelVendorAppointment(id: number, data: any) {
    return this.patch(ApiUrls.cancelVendorAppointment(id), data);
  }
}

export default MeldAPI;
