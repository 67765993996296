import alt from "../../alt";
import messageActions from "../../common/actions/message-actions";
import integrationAPI from "../apis/integration-api";
import integrationPartnerStore from "../stores/integration-partner-store";

class IntegrationPartnerActions {
  constructor() {
    this.generateActions(
      "fetchAll",
      "fetchAllDone",
      "fetchAllFail",
      "fetchBySlug",
      "fetchBySlugDone",
      "toggleEnabledFail",
      "toggleEnabledDone",
      "syncFail"
    );
  }

  fetchBySlugFail(dispatchData) {
    messageActions.storeError({ body: dispatchData.messages[0] });

    return dispatchData;
  }

  sync(slug) {
    integrationAPI
      .sync(slug)
      .then(res => {})
      .catch(res => {
        this.syncFail({ slug });
      });

    return { slug };
  }

  toggleEnabled(slug, data) {
    integrationAPI
      .toggleEnabled(slug, data)
      .then(res => {
        integrationPartnerStore.fetchBySlug(slug);

        if (data.enabled) {
          messageActions.storeSuccess({
            body: "Integration enabled"
          });
        } else {
          messageActions.storeInfo({ body: "Integration disabled" });
        }
        this.toggleEnabledDone({ slug });
      })
      .catch(res => {
        this.toggleEnabledFail({ slug });
      });

    return { slug };
  }
}

export default alt.createActions(IntegrationPartnerActions);
