import React from "react";
import { Textarea, Props as TextareaProps } from "./textarea";
import { Field, FieldProps } from "formik";

interface Props extends TextareaProps {
  /**
   * Gets passed to formik's <Field /> component.
   * Used to match the field to form value for validation and state management.
   */
  match: string;
}

export const FormikTextarea: React.FC<Props> = ({ match, ...rest }) => (
  <Field name={match}>{({ field, meta }: FieldProps) => <Textarea errors={meta.error} {...field} {...rest} />}</Field>
);

FormikTextarea.displayName = "FormikTextarea";
