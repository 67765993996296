import moment from "moment";

const currentYear = new Date().getFullYear();

let CardUtils = {
  getFormattedDayMonth(date: any, dayMonthFormat?: string, emptyMsg = "") {
    if (!date) {
      return emptyMsg;
    }

    let mom = moment(date);
    let format = dayMonthFormat;

    // Don't include the year unless it's different than the current year
    if (!format) {
      if (mom.year() < currentYear) {
        format = "D MMMM YYYY";
      } else {
        format = "D MMMM";
      }
    }

    return mom.format(format);
  },

  getFormattedTime(date: any, timeFormat = "h:mma", emptyMsg = "") {
    if (!date) {
      return emptyMsg;
    }

    return moment(date).format(timeFormat);
  }
};

export default CardUtils;
