import React from "react";
import { colors } from "@pm-frontend/styles";

export const SidebarVendorIcon = ({ isActive = false }: { isActive?: boolean }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={isActive ? colors.brand.meldBlue : colors.neutrals.gray800}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="build" clipPath="url(#clip0_1299_22396)">
        <path
          id="Vector"
          d="M15.0733 12.6599L9.01997 6.60657C9.63997 5.04657 9.31997 3.20657 8.05997 1.9399C6.52664 0.406566 4.13997 0.266566 2.43997 1.50657L4.99997 4.07323L4.05331 5.01323L1.49997 2.4599C0.259974 4.15323 0.399974 6.54657 1.93331 8.07323C3.17331 9.31323 4.97997 9.6399 6.52664 9.0599L12.6 15.1332C12.86 15.3932 13.28 15.3932 13.54 15.1332L15.0733 13.5999C15.34 13.3466 15.34 12.9266 15.0733 12.6599ZM13.0733 13.7266L6.76664 7.4199C6.35997 7.7199 5.90664 7.8999 5.43331 7.96657C4.52664 8.0999 3.57331 7.82657 2.87997 7.13323C2.24664 6.50657 1.95331 5.66657 1.99997 4.8399L4.05997 6.8999L6.88664 4.07323L4.82664 2.01323C5.65331 1.96657 6.48664 2.2599 7.11998 2.88657C7.83997 3.60657 8.11331 4.5999 7.94664 5.52657C7.86664 5.9999 7.66664 6.4399 7.35997 6.83323L13.66 13.1332L13.0733 13.7266Z"
          fill={isActive ? colors.brand.meldBlue : colors.neutrals.gray800}
        />
      </g>
      <defs>
        <clipPath id="clip0_1299_22396">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
