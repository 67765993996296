var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { MeldManagerStatusLabels, MeldStatus } from "@pm-frontend/shared/types/meld";
import { getCategoryLabel } from "@pm-frontend/shared/utils/meld-utils";
import { WorkCategory } from "@pm-frontend/shared/utils/statuses";
import { WorkType } from "@pm-frontend/shared/utils/statuses";
import { getWorkTypeLabel } from "@pm-frontend/shared/utils/meld-utils";
import { BaseSortFilterClass } from "../PmSortFilter/BaseSortFilterClass";
import { BaseComboboxFilterClass } from "../PmComboboxFilter/BaseComboboxFilterClass";
import { BaseDateFilterClass } from "../PmDateFilter/BaseDateFilterClass";
import { BaseDecimalFilterClass } from "../PmDecimalFilter/BaseDecimalFilterClass";
import { BaseSelectableFilterClass } from "../PmSelectableFilter/BaseSelectableFilterClass";
import { getFullName } from "@pm-frontend/shared/utils/agent-utils";
const MeldFilterQueryKeysPrefix = {
  assignedMaintenance: "maint",
  categories: "categories",
  coordinator: "coordinator",
  creation_date: "created",
  maint_type: "maint_type",
  priority: "priority",
  sort: "ordering",
  status: "status",
  tpr: "tpr",
  work_hours_logged: "wl",
  work_type: "work_type"
};
export const MeldFilterQueryKeys = __spreadProps(__spreadValues({}, MeldFilterQueryKeysPrefix), {
  priority: MeldFilterQueryKeysPrefix.priority + "[]",
  status: MeldFilterQueryKeysPrefix.status + "[]",
  work_type: MeldFilterQueryKeysPrefix.work_type + "[]",
  coordinator: MeldFilterQueryKeysPrefix.coordinator + "[]",
  maint_type: MeldFilterQueryKeysPrefix.maint_type + "[]"
});
export const getMeldSavedFilterConfig = ({
  savedFilters,
  otherFilters,
  alwaysShow = false
}) => {
  const privateOptions = savedFilters.filter((opt) => opt.private).map((opt) => ({ label: opt.name, queryParamValue: opt.id.toString(), isGroupLabel: false }));
  const sharedOptions = savedFilters.filter((opt) => !opt.private).map((opt) => ({ label: opt.name, queryParamValue: opt.id.toString(), isGroupLabel: false }));
  return {
    options: [
      { label: "Shared", isGroupLabel: true, queryParamValue: "" },
      ...sharedOptions,
      { label: "Private", isGroupLabel: true, queryParamValue: "" },
      ...privateOptions
    ],
    popoverWidth: "300px",
    alwaysShow,
    otherFilters
  };
};
const MeldFilterSortConfigs = {
  sort: {
    filterName: "sort_melds",
    options: [
      { label: "Created: Old", queryParamValue: "created" },
      { label: "Created: Recent", queryParamValue: "-created" },
      { label: "Meld: A-Z", queryParamValue: "brief_description" },
      { label: "Meld: Z-A", queryParamValue: "-brief_description" },
      { label: "Scheduled: Soon", queryParamValue: "scheduled" },
      { label: "Scheduled: Later", queryParamValue: "-scheduled" },
      { label: "Updated: Old", queryParamValue: "updated" },
      { label: "Updated: Recent", queryParamValue: "-updated" }
    ],
    defaultOption: "Created: Old",
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.sort,
    alwaysShow: true,
    popoverWidth: "300px"
  }
};
const MeldSelectableFilterConfigs = {
  priority: {
    text: "Priority",
    filterName: "priority",
    options: [
      { label: "High", queryParamValue: "HIGH" },
      { label: "Medium", queryParamValue: "MEDIUM" },
      { label: "Low", queryParamValue: "LOW" }
    ],
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.priority,
    componentProps: {
      searchable: false
    },
    popoverWidth: "300px",
    allowedOperators: ["Any of", "None of"]
  },
  maint_type: {
    text: "Maintenance Type",
    filterName: "maintenance_type",
    options: [
      { label: "External", queryParamValue: "2" },
      { label: "Internal", queryParamValue: "1" }
    ],
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.maint_type,
    componentProps: {
      searchable: false,
      singleSelection: true
    },
    popoverWidth: "300px",
    allowedOperators: ["Any of"]
  },
  status: {
    text: "Status",
    filterName: "status",
    options: Object.keys(MeldStatus).map((status) => ({
      label: MeldManagerStatusLabels[status],
      queryParamValue: status
      // this is an unused status
    })).filter((opt) => opt.queryParamValue !== MeldStatus.OPEN),
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.status,
    componentProps: {
      searchable: true,
      singleSelection: false
    },
    popoverWidth: "300px",
    allowedOperators: ["Any of"]
  },
  tpr: {
    text: "Resident Required",
    popoverWidth: "300px",
    filterName: "tpr",
    options: [
      { label: "Required", queryParamValue: "true" },
      { label: "Not Required", queryParamValue: "false" }
    ],
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.tpr,
    allowedOperators: ["Any of"],
    componentProps: {
      searchable: false,
      singleSelection: true
    }
  },
  work_type: {
    text: "Work Type",
    filterName: "work_type",
    options: Object.keys(WorkType).map((workType) => ({
      label: getWorkTypeLabel(workType),
      queryParamValue: workType
    })),
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.work_type,
    componentProps: {
      searchable: true,
      singleSelection: false
    },
    popoverWidth: "300px",
    allowedOperators: ["Any of", "None of"]
  }
};
const MeldComboboxFilterConfigs = {
  assignedMaintenance: {
    text: "Assigned Maintenance",
    popoverWidth: "300px",
    filterName: "meld_assigned_maint",
    // this property must be overriden when constructing the BaseComboboxFilterClass
    options: [],
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.assignedMaintenance,
    allowedOperators: ["Any of"],
    componentProps: {
      placeholder: "Filter by assigned maintenance"
    }
  },
  categories: {
    text: "Categories",
    popoverWidth: "300px",
    filterName: "meld_category",
    options: Object.values(WorkCategory).map((wc) => ({
      label: getCategoryLabel(wc),
      queryParamValue: wc,
      created: void 0
    })),
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.categories,
    allowedOperators: ["Any of"],
    componentProps: {
      placeholder: "Filter by work category"
    }
  },
  coordinator: {
    filterName: "coordinator",
    text: "Coordinator",
    // this property must be overriden when constructing the BaseComboboxFilterClass
    options: [],
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.coordinator,
    popoverWidth: "300px",
    componentProps: {
      placeholder: "Filter by coordinator"
    },
    allowedOperators: ["Any of", "None of", "Missing", "Present"]
  }
};
const MeldFilterConfigsDate = {
  creation_date: {
    text: "Creation Date",
    filterName: "creation_date",
    componentProps: null,
    alwaysShow: false,
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.creation_date,
    allowedOperators: ["In range", "Not in range", "In the past", "Older than", "Equal to", "Before", "After"],
    popoverWidth: "300px",
    suffixOverrides: { _gte: "__gte", _lte: "__lte", _offset_lte: "__offset_lte", _interval: "__interval" }
  }
};
const MeldDecimalFilterConfigs = {
  work_hours_logged: {
    text: "Hours logged",
    filterName: "work_hours_logged",
    numberUnitLabel: "Hours",
    popoverWidth: "300px",
    queryParamKeyPrefix: MeldFilterQueryKeysPrefix.work_hours_logged,
    allowedOperators: [
      "Greater or equal to",
      "Lesser or equal to",
      "Between",
      "Missing",
      "Present",
      "Equal to",
      "Not equal to"
    ],
    suffixOverrides: { _gte: "__gte", _lte: "__lte" },
    componentProps: null,
    alwaysShow: false
  }
};
export const MeldFilterConfigs = {
  selectable: MeldSelectableFilterConfigs,
  combobox: MeldComboboxFilterConfigs,
  decimal: MeldDecimalFilterConfigs,
  date: MeldFilterConfigsDate,
  sort: MeldFilterSortConfigs
};
export function getDefaultMeldFilters({
  allMaint,
  alwaysShowSortFilter = true
}) {
  const allAgents = (allMaint || []).filter(
    (maint) => maint.type === "ManagementAgent"
  );
  const allVendors = (allMaint || []).filter((maint) => maint.type === "Vendor");
  const allVendorInvites = (allMaint || []).filter(
    (maint) => maint.type === "VendorInvite"
  );
  const allAgentsMapped = allAgents.map((agent) => ({
    queryParamValue: agent.id.toString(),
    label: getFullName(agent),
    created: agent.created
  }));
  const allMaintMapped = [
    { label: "Agents", isGroupLabelOption: true, queryParamValue: "", created: void 0 },
    ...allAgents.map((agent) => ({
      queryParamValue: agent.composite_id,
      label: `${getFullName(agent)} (Maintenance)`,
      created: agent.created
    })),
    { label: "Vendors", isGroupLabelOption: true, queryParamValue: "", created: void 0 },
    ...allVendors.map((vendor) => ({
      queryParamValue: vendor.composite_id,
      created: vendor.created,
      label: `${vendor.name || vendor.email || ""} (Vendor)`
    })),
    { label: "Vendor Invites", isGroupLabelOption: true, queryParamValue: "", created: void 0 },
    ...allVendorInvites.map((invite) => ({
      queryParamValue: invite.composite_id,
      created: invite.created,
      label: `${invite.name || invite.email || ""} (Invited Vendor)`
    }))
  ];
  return {
    filters: [
      // NOTE: these are alphabetical by the filter button text
      new BaseComboboxFilterClass(MeldFilterConfigs.combobox.assignedMaintenance, { options: allMaintMapped }),
      new BaseComboboxFilterClass(MeldFilterConfigs.combobox.categories),
      new BaseComboboxFilterClass(MeldFilterConfigs.combobox.coordinator, { options: allAgentsMapped }),
      new BaseDateFilterClass(MeldFilterConfigs.date.creation_date),
      new BaseDecimalFilterClass(MeldFilterConfigs.decimal.work_hours_logged),
      new BaseSelectableFilterClass(MeldFilterConfigs.selectable.maint_type),
      new BaseSelectableFilterClass(MeldFilterConfigs.selectable.priority),
      new BaseSelectableFilterClass(MeldFilterConfigs.selectable.status),
      new BaseSelectableFilterClass(MeldFilterConfigs.selectable.tpr),
      new BaseSelectableFilterClass(MeldFilterConfigs.selectable.work_type)
      // TODO: scheduled date
      // TODO: scheduled date
    ],
    sortFilter: new BaseSortFilterClass(MeldFilterConfigs.sort.sort, { alwaysShow: alwaysShowSortFilter })
  };
}
