import SettingsActions from "../actions/settings-actions";
import SettingsApi from "../apis/settings-api";

let SettingsSource = {
  fetchCurrent() {
    return {
      remote(state, { cid } = {}) {
        return SettingsApi.fetchCurrent();
      },

      shouldFetch(state, force) {
        return state.modelState.size === 0;
      },

      success: SettingsActions.fetchCurrentDone,
      error: SettingsActions.fetchCurrentFail
    };
  }
};

export default SettingsSource;
