import { History, Location } from "history";
import React from "react";
import { Link as L, NavLink as NavL } from "react-router-dom";
import { stringify } from "@pm-assets/js/common/utils/location-utils";

export let LinkHelper = {
  normalize(url: string) {
    let mTenant = this.getMultitenant();
    let orgId = this.getOrgId();
    let orgType = this.getOrgType();

    let updatedUrl = `/${mTenant}/${orgType}/${orgId}/${url}`;
    return updatedUrl.replace("//", "/");
  },

  getOrgId() {
    return window.location.pathname.split("/")[3];
  },

  getOrgType() {
    return window.location.pathname.split("/")[2];
  },

  getMultitenant() {
    return window.location.pathname.split("/")[1];
  },
};

function _getLinkProps(props: any) {
  let { to, normalize, query, ...otherProps } = props;
  let newTo;

  if (_.isObject(to)) {
    // @ts-ignore
    newTo = Object.assign({}, to, { pathname: to.pathname });
  } else {
    newTo = { pathname: to };
  }

  if (normalize) {
    newTo.pathname = LinkHelper.normalize(newTo.pathname);
  }

  if (query) {
    // @ts-ignore
    newTo.search = stringify(query);
  }

  return Object.assign(otherProps, { to: newTo });
}

class Link extends React.Component<any> {
  public static defaultProps = {
    normalize: true,
  };

  render() {
    return <L {..._getLinkProps(this.props)}>{this.props.children}</L>;
  }
}

class NavLink extends React.Component<any> {
  public static defaultProps = {
    normalize: true,
  };

  render() {
    return <NavL {..._getLinkProps(this.props)}>{this.props.children}</NavL>;
  }
}

export { NavLink };

export let LinkUtils = {
  getCurrentSubRoute(location: Location) {
    return location.pathname.split("/").slice(5)[0];
  },

  reloadIfVersionOld(location: Location, history: History) {
    if (window.PM.env.outOfDate) {
      let url;

      if (window.location.hash) {
        url = window.location.pathname + "#" + location.pathname + (location.search || "?") + "_k=" + location.key;
      } else {
        url = location.pathname + location.search;
      }

      if (history.action === "PUSH") {
        window.location.assign(url);
      } else {
        window.location.replace(url);
      }

      if (window.location.hash) {
        window.location.reload();
      }
    }
  },
};

export default Link;

export function normalizePath(path: string) {
  return `/:multitenantId/:orgType/:org/${path}`;
}
