import Calendar from "./calendar";
import CalendarControls from "./calendar-controls";
import CalendarUtils from "../../utils/calendar-utils";
import moment from "moment";
import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";
import withOptimalNumDays from "./with-optimal-num-days";

let AvailabilitySelector = createReactClass({
  propTypes: {
    onEventDeleted: PropTypes.func.isRequired,
    onEventClicked: PropTypes.func.isRequired,
    events: PropTypes.array.isRequired,
    increment: PropTypes.number.isRequired,
    eventStyleGetter: PropTypes.func,
  },

  getInitialState() {
    return {
      startDay: moment().startOf("day"),
    };
  },

  render() {
    let events = this.props.events.map((event) => {
      event.dtstart = moment(event.dtstart);
      event.dtend = moment(event.dtend);
      return event;
    });

    return (
      <div>
        <div className="calendar-controls">
          <CalendarControls
            numDays={this.props.numDays}
            startDay={this.state.startDay.clone()}
            onTodayClicked={this.navToToday}
            navPrev={this.navPrevWeek}
            navNext={this.navNextWeek}
          />
        </div>
        <Calendar
          disabledTo={CalendarUtils.roundUpMoment()}
          events={events}
          numDays={this.props.numDays}
          onDeleteClicked={this.onDeleteClicked}
          onEventClicked={this.props.onEventClicked}
          timeBlockSize={this.props.increment}
          startDay={this.state.startDay.clone()}
          eventStyleGetter={this.props.eventStyleGetter} 
        />
      </div>
    );
  },

  onDeleteClicked(event) {
    this.props.onEventDeleted(event);
  },

  navToToday() {
    this.setState({ startDay: moment().startOf("day") });
  },

  navPrevWeek() {
    this.setState({
      startDay: this.state.startDay.clone().subtract(this.props.numDays, "day"),
    });
  },

  navNextWeek() {
    this.setState({
      startDay: this.state.startDay.clone().add(this.props.numDays, "day"),
    });
  },
});

export default withOptimalNumDays(AvailabilitySelector);
