import alt from "../../alt";
import BaseStore from "../../stores/base-store";
import vendorMeldFileSource from "../sources/vendor-meld-file-source";
import vendorMeldFileActions from "../actions/vendor-meld-file-actions";

class VendorMeldFileStore extends BaseStore {
  constructor() {
    super();

    this.registerAsync(vendorMeldFileSource);

    this.bindListeners({
      receive: vendorMeldFileActions.FETCH_FILES_DONE,
      saveDone: vendorMeldFileActions.CREATE_FILE_DONE,
      saving: vendorMeldFileActions.CREATE_FILE,
      deleteDone: vendorMeldFileActions.DELETE_FILE_DONE,
      deleting: vendorMeldFileActions.DELETE_FILE
    });

    this.exportPublicMethods({
      getFilesForMeld: this.getFilesForMeld.bind(this)
    });
  }

  getFilesForMeld(meldId) {
    let id = parseInt(meldId);
    return this.getModelState().filter(file => file.get("meld") === id);
  }
}

export default alt.createStore(VendorMeldFileStore, "VendorMeldFileStore");
