import React from "react";
import classnames from "classnames";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  /**
   * Where the group should be set to display: inline-block.
   * @default false
   */
  inlineBlock?: boolean;
}

export const FormGroup: React.FC<Props> = ({ inlineBlock = false, children, ...elementProps }) => {
  const classes = classnames("pm-forms-form-group", { "inline-block": inlineBlock });

  return (
    <div className={classes} {...elementProps}>
      {children}
    </div>
  );
};

FormGroup.displayName = "FormGroup";

export default FormGroup;
