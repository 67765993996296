import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

class RMInventoryItemAPI extends BaseApi {
  static search(query: string) {
    return this.get(ApiUrls.rmInventoryItemSearch, { params: { query } });
  }
}

export default RMInventoryItemAPI;
