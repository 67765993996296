var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { asImmer } from "@pm-app/utils/decorators";
import { getReadableStatus } from "@pm-property-management-hub/src/utils/owners";
import { OwnerHubAccessLevelEnum } from "@pm-shared/owner";
class OwnerUtils {
  static getName(owner, emptyMsg = "No owner") {
    owner = owner;
    if (!owner) {
      return emptyMsg;
    }
    return `${owner.first_name || ""} ${owner.last_name || ""}`;
  }
  static getBaseOwnerName(owner, emptyMsg = "No owner") {
    if (!owner) {
      return emptyMsg;
    }
    return `${owner.first_name || ""} ${owner.last_name || ""}`;
  }
  static getFirstNumber(owner) {
    const phone = owner.getIn(["contact", "business_phone"]) || owner.getIn(["contact", "cell_phone"]) || owner.getIn(["contact", "home_phone"]);
    return phone ? phone : "No number";
  }
  static getCellPhone(owner) {
    let phone = owner.getIn(["contact", "cell_phone"]);
    if (!phone) {
      phone = "No number";
    }
    return phone;
  }
  static getHomePhone(owner) {
    let phone = owner.getIn(["contact", "home_phone"]);
    if (!phone) {
      phone = "No number";
    }
    return phone;
  }
  static getOwnerEmail(owner) {
    return owner.get("email");
  }
  static getBusinessPhone(owner) {
    let phone = owner.getIn(["contact", "business_phone"]);
    if (!phone) {
      phone = "No number";
    }
    return phone;
  }
  static isEnabled(owner) {
    return owner.get("hub_access_level") !== OwnerHubAccessLevelEnum.DIRECT_ONLY;
  }
  static getHubAccessLevel(owner) {
    return getReadableStatus(owner.get("hub_access_level"));
  }
}
__decorateClass([
  asImmer()
], OwnerUtils, "getName", 1);
export default OwnerUtils;
