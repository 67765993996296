import { ImmutableMap } from "@pm-assets/js/types/common";
import FileUtils from "@pm-assets/js/common/utils/file-utils";
import DocumentSvg from "@pm-assets/js/svg/document-svg";
import { Box, Image } from "@chakra-ui/react";
import React from "react";
import Axios, { AxiosPromise } from "axios";
import { parseString } from "xml2js";

export class RenderFileUtils {
  static renderFilePreview(file: ImmutableMap) {
    if (FileUtils.isPDF(file)) {
      return (
        <object
          height="100%"
          width="100%"
          data={file.get("openable_signed_url")}
          type="application/pdf"
          className="pdf-file-preview"
        >
          <DocumentSvg />
        </object>
      );
    } else if (!FileUtils.isPDF(file) && FileUtils.isPreviewAvailable(file)) {
      return <Image margin="0 auto" src={file.get("full_compressed")} />;
    } else {
      return (
        <Box display={"flex"} flexDir={"column"} height={"100%"} paddingBottom={"50px"} paddingTop={"50px"}>
          <Box width={"auto"} padding={"10px"} margin={"auto"}>
            <DocumentSvg />
          </Box>
        </Box>
      );
    }
  }
}

export const uploadToS3 = async (file: File, policy: any) => {
  // Create a form to POST to S3.
  let formData = new FormData();

  Object.entries(policy.fields).forEach(([key, value]: [string, string]) => {
    formData.append(key, value);
  });
  formData.append("file", file);
  const uploadResponse = await Axios.post(policy.url, formData);

  // Parse the XML response.
  let uploadResponseData: any | undefined;
  parseString(uploadResponse.data, (error: any, result: any) => {
    if (error) {
      throw error;
    }

    uploadResponseData = result;
  });

  return uploadResponseData;
};

export interface FileUpload {
  files: File[];
  generatePolicy: (filename: string) => AxiosPromise<any>;
  upload: (
    file: string,
    filename: string,
    bulkFileCount: number,
    bulkFileNames: string
  ) => AxiosPromise<any> | Promise<any>;
}
