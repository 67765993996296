import React from "react";
import { authParams, mApi } from "@pm-assets/js/common/api";
import Button, { ButtonTypes } from "@pm-app/components/forms/button/button";
import ButtonGroup from "@pm-app/components/forms/button-group/button-group";
import Modal, { ModalBody, ModalFooter } from "@pm-app/components/modal/modal";

export const deleteMeldFiles = async (files: any[], onDelete?: () => void, onError?: (e: any) => void) => {
  // Workaround to wait for all of the delete calls to finish before calling onDelete.
  await Promise.all(
    files.map(async (file: any) => {
      try {
        await mApi.mOIApiMeldsFilesDelete({ id: file.id, ...authParams });
      } catch (error) {
        if (onError) {
          onError(error);
        }
      }
    })
  );

  if (onDelete) {
    onDelete();
  }
};

interface Props {
  files: any[];
  isOpen?: boolean;
  multiple?: boolean;
  onClose?: () => void;
  onDelete?: () => void;
}

export const DeleteFilesModal: React.FC<Props> = ({ files, multiple = false, onDelete, ...props }) => {
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    }
  };

  return (
    <Modal header="Delete Confirmation" {...props}>
      <ModalBody>
        {!multiple && (
          <>
            You are about to delete the file <strong>{files[0]?.filename}</strong>, which cannot be undone. Are you
            sure?
          </>
        )}

        {multiple && (
          <>
            You are about to delete <strong>{files.length}</strong> file(s), which cannot be undone. Are you sure?
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button buttonType={ButtonTypes.Primary} onClick={handleDelete}>
            Delete File{multiple ? "(s)" : ""}
          </Button>
          <Button buttonType={ButtonTypes.Neutral} onClick={handleClose}>
            Cancel
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteFilesModal;
