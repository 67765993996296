import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

class CommentTemplateAPI extends BaseApi {
  static create(template: { name: string; text: string }) {
    return this.post(ApiUrls.commentTemplateList, template);
  }

  static fetchAll({ url, xhrOptions }: { url?: string; xhrOptions?: any } = {}) {
    if (url) {
      return this.get(url, { normalizeUrl: false, ...xhrOptions });
    }
    return this.get(ApiUrls.commentTemplateList, xhrOptions);
  }

  static fetchOne(id: number) {
    return this.get(ApiUrls.commentTemplateDetail(id));
  }

  static updateCommentTemplate(template: { id: number; name: string; text: string }) {
    return this.patch(ApiUrls.commentTemplateDetail(template.id), template);
  }

  static deleteCommentTemplate(id: number) {
    return this.delete(ApiUrls.commentTemplateDetail(id));
  }
}

export default CommentTemplateAPI;
