import I from "immutable";
import alt from "../../alt";
import BaseStore from "../../stores/base-store";
import vendorInvoiceFileSource from "../sources/vendor-invoice-file-source";
import vendorInvoiceFileActions from "../actions/vendor-invoice-file-actions";

class VendorInvoiceFileStore extends BaseStore {
  constructor() {
    super();

    this.registerAsync(vendorInvoiceFileSource);

    this.bindListeners({
      receive: vendorInvoiceFileActions.FETCH_VENDOR_INVOICE_FILE_DONE,
      saveDone: vendorInvoiceFileActions.CREATE_VENDOR_INVOICE_FILE_DONE,
      saving: vendorInvoiceFileActions.CREATE_VENDOR_INVOICE_FILE,
      deleteDone: vendorInvoiceFileActions.DELETE_VENDOR_INVOICE_FILE_DONE,
      deleting: vendorInvoiceFileActions.DELETE_VENDOR_INVOICE_FILE
    });

    this.exportPublicMethods({
      getInvoiceFileForMeld: this.getInvoiceFileForMeld.bind(this)
    });
  }

  getInvoiceFileForMeld(meldId) {
    let id = parseInt(meldId);
    return this.getModelState()
      .filter(file => file.get("meld") === id)
      .reduce((p, c) => {
        if (p.get("id") < c.get("id")) {
          return c;
        } else {
          return p;
        }
      }, I.Map({ id: -1 }));
  }
}

export default alt.createStore(VendorInvoiceFileStore, "VendorInvoiceFileStore");
