import React from "react";
import { EuiHeaderSectionItemButton, EuiIcon } from "@elastic/eui";

import URL from "@pm-shared/utils/url";

const HeaderMobileMenuItem = ({ toggleSidebar }: { toggleSidebar: () => void }) => (
  <EuiHeaderSectionItemButton aria-label="Navigation Menu" onClick={toggleSidebar} style={{ padding: "0px" }}>
    <EuiIcon type={URL.getStatic("icons/menu.svg")} size="original" />
  </EuiHeaderSectionItemButton>
);

export { HeaderMobileMenuItem };
