var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiText
} from "@elastic/eui";
import { colors } from "@pm-frontend/styles";
import { PmEmptyButton } from "../Buttons/PmEmptyButton";
import { PmFilledButton } from "../Buttons/PmFilledButton";
const parseBody = (body) => {
  if (!Array.isArray(body)) {
    if (typeof body === "string") {
      return /* @__PURE__ */ React.createElement(EuiText, { style: { color: colors.neutrals.gray800, fontSize: "16px", padding: "0px" } }, body);
    } else {
      return body;
    }
  } else {
    const result = [];
    body.forEach((line, index) => {
      if (typeof line === "string") {
        result.push(
          /* @__PURE__ */ React.createElement(EuiText, { key: line + index, style: { color: colors.neutrals.gray800, fontSize: "16px", padding: "0px" } }, line)
        );
      } else {
        result.push(line);
      }
      if (index < body.length - 1) {
        result.push(/* @__PURE__ */ React.createElement("br", { key: index }));
      }
    });
    return result;
  }
};
const PmConfirmModal = ({
  closeModal,
  title,
  body,
  primaryButtonText,
  primaryButtonOnClick,
  primaryButtonLoading,
  primaryButtonColor = "primary",
  secondaryButtonText = "Cancel",
  confirmButtonDataTestId,
  hideConfirmButton = false,
  disablePrimaryButton = false
}) => {
  return /* @__PURE__ */ React.createElement(
    EuiModal,
    {
      maxWidth: "448px",
      className: "pmConfirmModal",
      onClose: closeModal,
      style: { padding: "24px", gap: "16px", position: "relative" }
    },
    title ? /* @__PURE__ */ React.createElement(EuiModalHeader, { style: { padding: "0px" } }, /* @__PURE__ */ React.createElement(EuiText, { color: colors.neutrals.gray900, style: { fontSize: "24px", fontWeight: "700" } }, title)) : null,
    body ? /* @__PURE__ */ React.createElement(EuiModalBody, null, parseBody(body)) : null,
    /* @__PURE__ */ React.createElement(EuiModalFooter, { style: { padding: "0px" } }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", alignItems: "flexStart", style: { gap: "16px" } }, !hideConfirmButton && /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      PmFilledButton,
      __spreadProps(__spreadValues({
        color: primaryButtonColor,
        padding: "10px 12px",
        onClick: primaryButtonOnClick,
        isLoading: primaryButtonLoading
      }, confirmButtonDataTestId ? { "data-testid": confirmButtonDataTestId } : {}), {
        text: primaryButtonText,
        isEnabled: !disablePrimaryButton
      })
    )), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(PmEmptyButton, { onClick: closeModal, text: secondaryButtonText, padding: "9.5px 8px" }))))
  );
};
export { PmConfirmModal };
