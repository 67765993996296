import { axios } from "@pm-shared/axios";

const multitenancy = window.location.pathname.split("/");
const APIPrefix = `/${multitenancy[1]}/${multitenancy[2]}/${multitenancy[3]}/api`;

const agentInvoicePolicyGenerateUrl = (filename: string) =>
  `${APIPrefix}/melds/vendor-invoice-file/generate-policy/?filename=${encodeURIComponent(filename)}`;

export const agentInvoicePolicyGenerate = (filename: string) => {
  return axios.get(agentInvoicePolicyGenerateUrl(filename));
};
