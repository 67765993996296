var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import File from "../../common/components/file";
import FilePreview from "../../common/components/file-preview";
import FileUtils from "../../common/utils/file-utils";
import FileViewerDeleteFile from "../../common/components/file-viewer-delete-file";
import FileViewerDownloadFile from "../../common/components/file-viewer-download-file";
import FileViewerNewWindow from "../../common/components/file-viewer-new-window";
import FileViewerShareFile from "../../common/components/file-viewer-share-file";
import React from "react";
import withPreviewableFiles from "../../common/components/with-previewable-files";
import { SmallLoader } from "../../loaders";
import SelectAllSelector from "../../common/components/select-all-selector";
class MeldFiles extends React.Component {
  constructor(props) {
    super(props);
    this.handleViewerDeleteFile = this.handleViewerDeleteFile.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.renderFilePreviewActions = this.renderFilePreviewActions.bind(this);
    this.handleCheckSelectedAll = this.handleCheckSelectedAll.bind(this);
    this.handleSelectedAll = this.handleSelectedAll.bind(this);
  }
  render() {
    if (this.props.files.size === 0 && this.props.isLoading) {
      return /* @__PURE__ */ React.createElement(SmallLoader, null);
    }
    if (this.props.files.size === 0) {
      return /* @__PURE__ */ React.createElement("div", { className: "copy" }, this.props.emptyText);
    }
    let files = this.props.files.map((file) => {
      let props = __spreadProps(__spreadValues({}, this.props), {
        deleteFile: this.props.allowDelete ? this.handleDeleteFile : void 0,
        onThumbnailClicked: this.props.navFile,
        file
      });
      return /* @__PURE__ */ React.createElement(File, __spreadValues({ key: file.get("id") }, props));
    });
    return /* @__PURE__ */ React.createElement(React.Fragment, null, this.renderSelector(), /* @__PURE__ */ React.createElement("ul", { className: "files" }, files), this.props.previewingFile && /* @__PURE__ */ React.createElement(
      FilePreview,
      {
        files: this.props.files,
        file: this.props.previewingFile,
        onClose: this.props.closePreview,
        navFile: this.props.navFile,
        renderActions: this.renderFilePreviewActions
      }
    ));
  }
  renderSelector() {
    if (this.props.selectedFilesCount !== void 0) {
      const totalItemCount = this.props.files.size;
      const selectedAll = totalItemCount === this.props.selectedFilesCount;
      return /* @__PURE__ */ React.createElement(
        SelectAllSelector,
        {
          selectedAll,
          numberOfSelectedItems: this.props.selectedFilesCount,
          onSelectedAll: this.handleCheckSelectedAll,
          onSelectedAllPages: this.handleSelectedAll,
          itemName: "file",
          nonSingularItemName: "files",
          totalItemCount
        }
      );
    }
  }
  renderFilePreviewActions(file) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, this.props.shareFile && /* @__PURE__ */ React.createElement(FileViewerShareFile, { file, onShare: this.props.shareFile }), this.props.allowDelete && /* @__PURE__ */ React.createElement(FileViewerDeleteFile, { file, onDelete: this.handleViewerDeleteFile }), FileUtils.isOpenable(file) && /* @__PURE__ */ React.createElement(FileViewerNewWindow, { file }), this.props.isDownloadEnabled && /* @__PURE__ */ React.createElement(FileViewerDownloadFile, { file }));
  }
  handleDeleteFile(file, cid) {
    if (this.props.deleteFile) {
      this.props.deleteFile(file, cid);
      this.props.closePreview();
    }
  }
  handleViewerDeleteFile(file) {
    this.handleDeleteFile(file, "");
  }
  handleSelectedAll() {
    if (this.props.onSelectedAll) {
      this.props.onSelectedAll(this.props.files);
    }
  }
  handleCheckSelectedAll() {
    if (this.props.selectedFilesCount === 0) {
      if (this.props.onSelectedAll) {
        this.props.onSelectedAll(this.props.files);
      }
    } else if (this.props.onSelectedNone) {
      this.props.onSelectedNone();
    }
  }
}
MeldFiles.defaultProps = {
  isDownloadEnabled: true
};
export default withPreviewableFiles(MeldFiles);
