import React from "react";

/*eslint-disable max-len */
export default class Scheduled extends React.Component {
  render() {
    return (
      <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 134.565 134.565" className="scheduled-svg">
        <path d="M37.883 54.667h-12.6c-2.52 0-4.2 1.682-4.2 4.205s1.68 4.205 4.2 4.205h12.6c2.52 0 4.2-1.682 4.2-4.205s-1.68-4.205-4.2-4.205zM96.683 58.872c0 2.523 1.68 4.205 4.2 4.205h16.8c2.52 0 4.2-1.682 4.2-4.205s-1.68-4.205-4.2-4.205h-16.8c-2.52 0-4.2 1.682-4.2 4.205zM88.283 54.667h-12.6c-2.52 0-4.2 1.682-4.2 4.205s1.68 4.205 4.2 4.205h12.6c2.52 0 4.2-1.682 4.2-4.205s-1.68-4.205-4.2-4.205zM63.083 54.667h-12.6c-2.52 0-4.2 1.682-4.2 4.205s1.68 4.205 4.2 4.205h12.6c2.52 0 4.2-1.682 4.2-4.205s-1.68-4.205-4.2-4.205zM37.883 75.693h-12.6c-2.52 0-4.2 1.682-4.2 4.205s1.68 4.205 4.2 4.205h12.6c2.52 0 4.2-1.682 4.2-4.205s-1.68-4.205-4.2-4.205zM117.683 75.693h-16.8c-2.52 0-4.2 1.682-4.2 4.205s1.68 4.205 4.2 4.205h16.8c2.52 0 4.2-1.682 4.2-4.205s-1.68-4.205-4.2-4.205z" />
        <path d="M121.463 8.41h-7.98V4.205c0-2.523-1.68-4.205-4.2-4.205-2.52 0-4.2 1.682-4.2 4.205V8.41h-16.8V4.205c0-2.523-1.68-4.205-4.2-4.205s-4.2 1.682-4.2 4.205V8.41h-16.8V4.205c0-2.523-1.68-4.205-4.2-4.205s-4.2 1.682-4.2 4.205V8.41h-16.8V4.205c0-2.523-1.68-4.205-4.2-4.205-2.52 0-4.2 1.682-4.2 4.205V8.41h-12.18c-7.98 0-17.22 6.728-17.22 15.14v87.887c0 8.83 8.82 14.718 17.22 14.718h29.82c5.88 5.467 13.86 8.41 22.26 8.41s16.38-3.364 22.26-8.41h29.82c7.98 0 13.02-5.887 13.02-14.718V23.55c0-8.832-5.04-15.14-13.02-15.14zm-52.08 117.745c-13.86 0-25.2-11.354-25.2-25.23s11.34-25.232 25.2-25.232 25.2 11.354 25.2 25.23-11.34 25.232-25.2 25.232zm56.7-92.514h-8.4c-2.52 0-4.2 1.683-4.2 4.206 0 2.523 1.68 4.205 4.2 4.205h8.4v69.386c0 6.308-3.36 6.308-4.62 6.308h-23.1c2.1-3.785 3.78-7.99 4.2-12.615h15.12c2.52 0 4.2-1.683 4.2-4.206 0-2.523-1.68-4.205-4.2-4.205h-15.12c-2.1-16.4-16.38-29.437-33.18-29.437s-31.08 12.615-33.18 29.436h-10.92c-2.52 0-4.2 1.68-4.2 4.204 0 2.523 1.68 4.205 4.2 4.205h10.92c.42 4.625 2.1 8.83 4.2 12.614h-23.1c-3.78 0-8.82-2.944-8.82-6.308V42.052h92.4c2.52 0 4.2-1.682 4.2-4.205s-1.68-4.205-4.2-4.205h-92.4V23.55c0-2.945 4.62-6.73 8.82-6.73h12.18v4.206c0 2.523 1.68 4.205 4.2 4.205 2.52 0 4.2-1.68 4.2-4.204V16.82h16.8v4.206c0 2.523 1.68 4.205 4.2 4.205s4.2-1.68 4.2-4.204V16.82h16.8v4.206c0 2.523 1.68 4.205 4.2 4.205s4.2-1.68 4.2-4.204V16.82h16.8v4.206c0 2.523 1.68 4.205 4.2 4.205 2.52 0 4.2-1.68 4.2-4.204V16.82h7.98c4.2 0 4.62 4.206 4.62 6.73v10.09z" />
        <path d="M79.043 89.57l-13.86 13.877-5.46-5.467c-1.68-1.682-4.2-1.682-5.88 0-1.68 1.682-1.68 4.205 0 5.887l8.4 8.41c.42.42.42.42.84.42l.42.422c.42 0 .84.42 1.68.42.42 0 1.26 0 1.68-.42l.42-.422c.42 0 .42-.42.84-.42l16.8-16.822c1.68-1.682 1.68-4.205 0-5.887-1.68-1.682-4.2-1.682-5.88 0z" />
      </svg>
    );
  }
}
/*eslint-enable max-len */
