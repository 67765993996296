import React, { useEffect, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";

import { Box, Button, CSSReset, Flex, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { colors } from "@pm-app/components/theme";
import { getReadableStatus, getStatusColor } from "@pm-property-management-hub/src/utils/owners";
import { StatusBadge } from "@pm-components/status-badge";
import { OwnerHubAccessLevelEnum, Owner } from "@pm-shared/owner";
import { hasPerm } from "@pm-assets/js/common/utils/permission-utils";
import * as C from "@pm-assets/js/constants";
interface Props {
  accessLevel?: Owner["hub_access_level"];
  onChange: (value: Owner["hub_access_level"]) => void;
  disabled?: boolean;
  parentPage?: string;
}

export const HubAccessDropdown: React.FC<Props> = ({
  accessLevel = OwnerHubAccessLevelEnum.DIRECT_ONLY,
  onChange,
  disabled,
  parentPage = "",
}) => {
  const [initialValue, setInitialValue] = useState<Owner["hub_access_level"]>();

  useEffect(() => {
    setInitialValue(accessLevel);
  }, [accessLevel]);

  return (
    <Flex
      flexWrap="wrap"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <CSSReset />
      <Flex>
        <Flex w="142px" justifyContent="end">
          <StatusBadge
            margin="auto"
            colorScheme={getStatusColor(accessLevel)}
            data-test={`${parentPage}hub_access_level`}
          >
            {getReadableStatus(accessLevel)}
          </StatusBadge>
        </Flex>

        {accessLevel !== OwnerHubAccessLevelEnum.INVITED_TO_HUB &&
          !disabled &&
          hasPerm(C.Perms.CAN_ADD_EDIT_DEACTIVATE_OWNERS) && (
            <Menu>
              <MenuButton as={Button} color={colors.pmBlue} marginLeft={[0, 2, 2, 2, 2]} variant="ghost">
                <Box display="flex" lineHeight="1.7em" fontSize="16px">
                  <MdArrowDropDown fontSize="24px" /> Change access
                </Box>
              </MenuButton>

              <MenuList>
                {Object.keys(OwnerHubAccessLevelEnum)
                  .filter(
                    (key: Owner["hub_access_level"]) =>
                      ![
                        OwnerHubAccessLevelEnum.INVITED_TO_HUB,
                        OwnerHubAccessLevelEnum.NONE,
                        OwnerHubAccessLevelEnum.NOTIFICATIONS_ONLY,
                        initialValue,
                      ].includes(key)
                  )
                  .map((key: Owner["hub_access_level"]) => (
                    <MenuItem key={key} onClick={() => onChange(key)}>
                      {getReadableStatus(key)}
                    </MenuItem>
                  ))}
              </MenuList>
            </Menu>
          )}
      </Flex>
    </Flex>
  );
};

HubAccessDropdown.displayName = "HubAccessDropdown";
