import { LinkVendorPayload } from "@pm-frontend/routes/Vendor/queries";
import { LinkHelper } from "@pm-shared/utils/link";
import { IntegrationResourceDetails } from "../components/IntegrationDetails/IntegrationDetails";
import { INTEGRATIONS, RentManagerModelSyncStatus } from "../types/integrations";
import { ApiUrls } from "./api-urls";
import { RouteUrls } from "./route-urls";

export const getVendorDetailsLink = ({ id }: { id: number }): string =>
  LinkHelper.normalize(RouteUrls.vendorDetail(id));

/**
 * From a list of property groups, remove any that show up under
 * the vendor's "excluded_property_groups" array
 */
export const getAssignedPropertyGroups = <T extends { id: number }>(
  propertyGroups: T[],
  excludedPropertyGroups: T[]
): T[] => {
  const excludedPropertyGroupsMap: Record<number, boolean> = {};
  excludedPropertyGroups.forEach((pg) => {
    excludedPropertyGroupsMap[pg.id] = true;
  });
  return propertyGroups.filter((group) => !excludedPropertyGroupsMap[group.id]);
};

/**
 * From a list of property groups, remove any that show up under
 * the vendor's "excluded_property_groups" array
 * for use with property_group values which are number[]
 */
export const getAssignedPropertyGroupsByNumber = (
  propertyGroups: number[],
  excludedPropertyGroups: number[]
): number[] => {
  const excludedPropertyGroupsMap: Record<number, boolean> = {};
  excludedPropertyGroups.forEach((pg) => {
    excludedPropertyGroupsMap[pg] = true;
  });
  return propertyGroups.filter((group) => !excludedPropertyGroupsMap[group]);
};

interface VendorIntegrationDetailsUtil {
  id: number;
  appfolio_linked: boolean;
  buildium_linked: boolean;
  rent_manager_linked: boolean;
  vendor_management?: {
    appfolio_vendor_link?: {
      id: number;
      af_vendor?: {
        vendor?: {
          CompanyName: string;
          LastName: string;
          FirstName: string;
        };
      };
    };
    buildium_vendor_link?: {
      id: number;
      bldm_vendor?: {
        vendor?: {
          CompanyName: string;
          LastName: string;
          FirstName: string;
        };
      };
    };
    propertyware_vendor?: {
      id: number;
      pw_vendor: {
        vendor: {
          name: string;
        };
      };
    };
    rent_manager_vendor?: {
      id: number;
      rm_vendor: {
        vendor: {
          Name: string;
        };
      };
    };
  };
}
/**
 * Gets an object to be used with IntegrationDetails component
 */
export const getVendorIntegrationDetails = (vendor: VendorIntegrationDetailsUtil): IntegrationResourceDetails => {
  const result = {
    integrationName: undefined,
    integrationResourceName: undefined,
    resourceStatus: undefined,
    deletedStatus: false,
  } as IntegrationResourceDetails;

  if (vendor.appfolio_linked) {
    const afApiVendorInfo = vendor.vendor_management?.appfolio_vendor_link?.af_vendor?.vendor;
    const afApiVendorName =
      afApiVendorInfo?.CompanyName || [afApiVendorInfo?.FirstName, afApiVendorInfo?.LastName].filter(Boolean).join(" ");

    result.integrationName = INTEGRATIONS.APPFOLIO;
    result.integrationResourceName = afApiVendorName;
    result.resourceStatus = vendor.appfolio_linked ? "active" : "deactivated";
  } else if (vendor.buildium_linked) {
    const buildiumVendorInfo = vendor.vendor_management?.buildium_vendor_link?.bldm_vendor?.vendor;
    const name =
      buildiumVendorInfo?.CompanyName ||
      [buildiumVendorInfo?.FirstName, buildiumVendorInfo?.LastName].filter(Boolean).join(" ");

    result.integrationName = INTEGRATIONS.BUILDIUM;
    result.integrationResourceName = name;
    result.resourceStatus = vendor.buildium_linked ? "active" : "inactive";
  } else if (vendor.vendor_management?.propertyware_vendor) {
    const pwVendorInfo = vendor.vendor_management?.propertyware_vendor;

    result.integrationName = INTEGRATIONS.PROPERTYWARE;
    result.integrationResourceName = pwVendorInfo?.pw_vendor?.vendor?.name;
    result.resourceStatus = pwVendorInfo ? "active" : "inactive";
  } else if (vendor.rent_manager_linked) {
    const rmVendorInfo = vendor.vendor_management?.rent_manager_vendor;
    result.integrationName = INTEGRATIONS.RENTMANAGER;
    result.integrationResourceName = rmVendorInfo?.rm_vendor?.vendor?.Name;
    result.resourceStatus = "active";
  }

  return result;
};

/**
 * Gets the unlink url for the vendor for an active integration
 */
export const getUnlinkVendorUrl = (vendor: VendorIntegrationDetailsUtil): string | undefined => {
  const integrationDetails = getVendorIntegrationDetails(vendor);

  switch (integrationDetails.integrationName) {
    case INTEGRATIONS.APPFOLIO: {
      const afapiVendorId = vendor.vendor_management?.appfolio_vendor_link?.id;
      return afapiVendorId ? ApiUrls.afapiVendor(afapiVendorId) : undefined;
    }
    case INTEGRATIONS.BUILDIUM: {
      const bldmVendorId = vendor.vendor_management?.buildium_vendor_link?.id;
      return bldmVendorId ? ApiUrls.bldmVendor(bldmVendorId) : undefined;
    }
    case INTEGRATIONS.PROPERTYWARE: {
      const pwVendorId = vendor.vendor_management?.propertyware_vendor?.id;
      return pwVendorId ? ApiUrls.pwVendorsSyncUpdate(pwVendorId) : undefined;
    }
    case INTEGRATIONS.RENTMANAGER: {
      const rmVendorId = vendor.vendor_management?.rent_manager_vendor?.id;
      return rmVendorId ? ApiUrls.rmVendorsSyncUpdate(rmVendorId) : undefined;
    }
    default:
      return;
  }
};

/**
 * Get the url for linking the vendor given the integration
 */
export const getLinkVendorUrl = (integration: INTEGRATIONS): string => {
  switch (integration) {
    case INTEGRATIONS.APPFOLIO:
      return ApiUrls.afapiVendorSync;
    case INTEGRATIONS.BUILDIUM:
      return ApiUrls.bldmVendorSync;
    case INTEGRATIONS.PROPERTYWARE:
      return ApiUrls.pwVendorsSync;
    case INTEGRATIONS.RENTMANAGER:
      return ApiUrls.rmVendorsSync;
    default:
      return "";
  }
};

/**
 * Get the payload for linking the vendor given the integration
 */
export const getLinkVendorPayload = (
  vendor: VendorIntegrationDetailsUtil,
  activeIntegration: INTEGRATIONS,
  partnerId: number
): LinkVendorPayload => {
  switch (activeIntegration) {
    case INTEGRATIONS.APPFOLIO:
      return {
        vendor: vendor.id,
        af_vendor: partnerId,
      };
    case INTEGRATIONS.BUILDIUM:
      return {
        vendor: vendor.id,
        buildium_vendor: partnerId,
      };
    case INTEGRATIONS.PROPERTYWARE:
      return {
        vendor: vendor.id,
        pw_vendor: partnerId,
      };
    case INTEGRATIONS.RENTMANAGER:
      return {
        vendor: vendor.id,
        rm_vendor: partnerId,
        sync_status: RentManagerModelSyncStatus.LINK,
      };
  }
};

export const getIsVendorLinked = (vendor: VendorIntegrationDetailsUtil): boolean =>
  vendor.appfolio_linked ||
  vendor.buildium_linked ||
  vendor.rent_manager_linked ||
  !!vendor.vendor_management?.propertyware_vendor;

export const getVendorDisplayName = (vendor: { name?: string; email?: string }) => {
  if (vendor.name) {
    return vendor.name;
  } else if (vendor.email) {
    return vendor.email;
  } else {
    return "A vendor";
  }
};
