import I from "immutable";
import IdleTimer from "react-idle-timer";
import React from "react";

export interface InjectedProps {
  setInterval: (handler: (...args: any[]) => void, timeout: number) => void;
  clearIntervals: () => void;
  callAndSetInterval: (handler: (...args: any[]) => void, timeout: number) => void;
}

export default function SetIntervalWhileActive<ChildProps>(
  ComposedComponent: React.ComponentType<ChildProps & InjectedProps>
) {
  interface S {
    intervals: I.Set<number>;
    isIdle: boolean;
  }

  type P = ChildProps;

  return class extends React.Component<P, S> {
    constructor(props: P) {
      super(props);

      this.state = {
        intervals: I.Set(),
        isIdle: false
      };

      this.setInterval = this.setInterval.bind(this);
      this.clearIntervals = this.clearIntervals.bind(this);
      this.callAndSetInterval = this.callAndSetInterval.bind(this);
      this.handleIdle = this.handleIdle.bind(this);
      this.handleActive = this.handleActive.bind(this);
    }

    render() {
      return (
        <IdleTimer timeout={5 * 60 * 1000} idleAction={this.handleIdle} activeAction={this.handleActive}>
          <ComposedComponent
            setInterval={this.setInterval}
            clearIntervals={this.clearIntervals}
            callAndSetInterval={this.callAndSetInterval}
            {...this.props}
          />
        </IdleTimer>
      );
    }

    handleIdle() {
      this.setState({ isIdle: true });
    }

    handleActive() {
      this.setState({ isIdle: false });
    }

    setInterval(handler: (...args: any[]) => void, timeout: number) {
      const funcToCall = () => {
        if (!this.state.isIdle) {
          handler();
        }
      };

      const interval = window.setInterval.call(null, funcToCall, timeout);
      this.setState({ intervals: this.state.intervals.add(interval) });
    }

    clearIntervals() {
      this.state.intervals.map(clearInterval);
    }

    componentWillUnmount() {
      this.clearIntervals();
    }

    callAndSetInterval(handler: (...args: any[]) => void, timeout: number) {
      handler();
      this.setInterval(handler, timeout);
    }
  };
}
