import alt from "../../alt";
import messageActions from "../../common/actions/message-actions";

class InvoiceActions {
  constructor() {
    this.generateActions(
      "fetchAll",
      "fetchAllDone",
      "deleteDone",
      "fetchOne",
      "fetchOneDone",
      "fetchOneFail",
      "fetchInvoicesByUnit",
      "fetchInvoicesByUnitDone",
      "fetchInvoicesByUnitFail"
    );
  }

  fetchAllFail(dispatchData) {
    if (dispatchData.messages.length) {
      messageActions.storeError({ body: dispatchData.messages[0] });
    }

    return dispatchData;
  }
}

export default alt.createActions(InvoiceActions);
