import React from "react";
import URL from "@pm-shared/utils/url";

class SmallLoader extends React.Component<any> {
  render() {
    return <img src={URL.getStatic("static/img/loader@03x.gif")} className="loader" alt={this.props.alt} />;
  }
}

class LargeLoader extends React.Component<any> {
  render() {
    return <img src={URL.getStatic("static/img/loader.gif")} className="loader" alt={this.props.alt} />;
  }
}

class FloatingLargeLoader extends React.Component<any> {
  render() {
    return (
      <div className="floating-loader">
        <LargeLoader {...this.props} />
      </div>
    );
  }
}

export { SmallLoader, LargeLoader, FloatingLargeLoader };
