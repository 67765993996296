import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";
import Multiselect from "react-widgets/lib/Multiselect";

let MultiselectFilter = createReactClass({
  propTypes: {
    filterName: PropTypes.string.isRequired,
    // options should be an array of objects, like [{id: 1, name: 'Red'}, {id: 2, name: 'Orange'}]
    options: PropTypes.array.isRequired,
    onSearch: PropTypes.func,
    filter: PropTypes.string,
    valueField: PropTypes.string.isRequired, // In the above example, valueField is 'id'
    textField: PropTypes.oneOfType([PropTypes.string, PropTypes.func]), // In the above example, textField is 'name'
    optionsSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    onChange: PropTypes.func.isRequired, // called with the array of selected options
    isFilterOpen: PropTypes.bool.isRequired,
    disableCheckBox: PropTypes.bool,
    onToggleFilter: PropTypes.func.isRequired,
    noSelectedOptionText: PropTypes.string,
    emptyList: PropTypes.string
  },

  getInitialState() {
    this.wasClicked = false;

    return {};
  },

  getDefaultProps() {
    return {
      optionValueTransform: Number.parseInt
    };
  },

  render() {
    let { filterName, isFilterOpen } = this.props;

    return (
      <div>
        <div className="filter-type">
          {!this.props.disableCheckBox && (
            <input
              type="checkbox"
              id={`${filterName}-filter`}
              checked={isFilterOpen}
              onChange={() => this.props.onToggleFilter(filterName)}
            />
          )}
          <label htmlFor={`${filterName}-filter`}>{this.props.children}</label>
        </div>
        {this.renderMultiselect()}
      </div>
    );
  },

  renderMultiselect() {
    const messages = {
      emptyList: this.props.emptyList || "There are no items in this list"
    };
    let multiSelectVal = null;

    if (this.props.optionsSelected) {
      if (Array.isArray(this.props.optionsSelected)) {
        multiSelectVal = this.props.optionsSelected.map(option => this.props.optionValueTransform(option));
      } else {
        multiSelectVal = this.props.optionsSelected.split(",").map(option => this.props.optionValueTransform(option));
      }
    }

    if (this.props.isFilterOpen) {
      const filter = this.props.filter ? this.props.filter : "startsWith";

      return (
        <div className="filter-choices">
          <div className="filter-choice">
            <Multiselect
              valueField={this.props.valueField}
              textField={this.props.textField}
              data={this.props.options}
              duration={75}
              defaultValue={multiSelectVal}
              filter={this.props.onSearch ? () => true : filter}
              placeholder={this.props.noSelectedOptionText}
              onChange={this.handleChange}
              emptyList={this.props.emptyList}
              autoFocus={this.wasClicked}
              onSearch={this.props.onSearch}
              messages={messages}
            />
          </div>
        </div>
      );
    }
  },

  handleChange(values) {
    this.props.onChange(this.props.filterName, values.map(value => value[this.props.valueField]));
  },

  componentWillReceiveProps(nextProps) {
    if (nextProps.isFilterOpen && !this.props.isFilterOpen) {
      this.wasClicked = true;
    }
  }
});

export default MultiselectFilter;
