import React from "react";

export default class StateSelect extends React.Component<{
  inputAttrs: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
}> {
  render() {
    return (
      <input
        type="text"
        placeholder="State"
        name="county_province"
        autoComplete="address-level1"
        maxLength={200}
        {...this.props.inputAttrs}
      />
    );
  }
}
