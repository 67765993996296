import I from "immutable";

let ImmutableUtils = {
  mapIDs(data: I.List<any>) {
    const firstItem = data.first();

    if (firstItem) {
      if (Number.isInteger(firstItem)) {
        return data;
      }

      if (firstItem.get("id")) {
        return data.map(pg => pg.get("id"));
      }
    }

    return data;
  }
};

export default ImmutableUtils;
