import AltContainer from "alt-container";
import I from "immutable";
import MeldFiles, { MeldFilesProps } from "./meld-files";
import React from "react";
import vendorMeldFileActions from "../actions/vendor-meld-file-actions";
import vendorMeldFileStore from "../stores/vendor-meld-file-store";
import { Omit } from "../../types/common";

export class VendorMeldFiles extends React.Component<MeldFilesProps> {
  constructor(props: MeldFilesProps) {
    super(props);
    this.deleteFile = this.deleteFile.bind(this);
  }

  render() {
    return <MeldFiles {...this.props} isLoading={this.props.isLoading} deleteFile={this.deleteFile} />;
  }

  deleteFile(file: I.Map<string, any>) {
    vendorMeldFileActions.deleteFile(file.get("id"));
  }
}

interface MeldFilesContainerProps extends Omit<MeldFilesProps, "files" | "isLoading"> {
  meldId: number;
  files?: any;
}

export default class extends React.Component<MeldFilesContainerProps> {
  render() {
    const meldId = this.props.meldId;
    return (
      <AltContainer
        stores={[vendorMeldFileStore]}
        render={() => {
          return (
            <VendorMeldFiles
              {...this.props}
              isLoading={vendorMeldFileStore.isLoading()}
              files={vendorMeldFileStore.getFilesForMeld(meldId)}
            />
          );
        }}
      />
    );
  }
}
