import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiPageHeader, EuiPageHeaderSection } from "@elastic/eui";

import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import { HeaderLogoItem } from "./items/HeaderLogoItem";
import { HeaderMobileMenuItem } from "./items/HeaderMobileMenuItem";
import { BorderColor, colors } from "@pm-frontend/styles";

interface ApplicationHeaderProps {
  toggleSidebar: () => void;
  desktopRightItems: Array<{ item: React.ReactNode; key: string; enabled?: boolean }>;
  mobileRightItems: Array<{ item: React.ReactNode; key: string; enabled?: boolean; dataTestId?: string }>;
  logoLinkUrl: string;
}

const ApplicationHeader = ({
  toggleSidebar,
  desktopRightItems,
  mobileRightItems,
  logoLinkUrl,
}: ApplicationHeaderProps) => {
  const isMobile = useIsMobile();

  const filteredDesktopRightItems = desktopRightItems.filter((item) => !item.hasOwnProperty("enabled") || item.enabled);
  const filteredMobileRightItems = mobileRightItems.filter((item) => !item.hasOwnProperty("enabled") || item.enabled);

  const mobileSections: Array<ApplicationHeaderProps["mobileRightItems"]> = [
    [
      {
        item: <HeaderMobileMenuItem toggleSidebar={toggleSidebar} key="header-mobile-menu-item" />,
        key: "menu",
      },
    ],
    [
      {
        item: <HeaderLogoItem logoLinkUrl={logoLinkUrl} key="header-logo-item" />,
        key: "logo",
      },
    ],
    filteredMobileRightItems,
  ];

  if (isMobile) {
    return (
      <EuiPageHeader
        style={{ background: colors.neutrals.gray50, boxShadow: `0px -1px 0px 0px ${BorderColor} inset` }}
        paddingSize="none"
        responsive={false}
        bottomBorder="extended"
        data-testid="management-hub-application-header"
      >
        <EuiFlexGroup
          style={{ padding: "4px 16px" }}
          responsive={false}
          direction="row"
          justifyContent="spaceBetween"
          alignItems="center"
        >
          {mobileSections.map((section, sectionIndex) => (
            <EuiPageHeaderSection
              key={sectionIndex}
              style={{
                flexGrow: 0,
                display: "flex",
                alignItems: "center",
                position: sectionIndex === 1 ? "absolute" : undefined,
                left: sectionIndex === 1 ? "calc(50vw - 32px)" : undefined,
                gap: "6px",
              }}
            >
              {section.map((item) => (
                <div key={item.key} data-testid={item.dataTestId || ""}>
                  {item.item}
                </div>
              ))}
            </EuiPageHeaderSection>
          ))}
        </EuiFlexGroup>
      </EuiPageHeader>
    );
  }

  return (
    <EuiPageHeader
      style={{
        padding: "0px 24px",
        height: "64px",
        boxShadow: `0px -1px 0px 0px ${BorderColor} inset`,
        background: colors.brand.white,
      }}
      alignItems="center"
    >
      <EuiFlexGroup
        direction="row"
        responsive={false}
        alignItems="center"
        justifyContent="spaceBetween"
        className="eui-fullHeight"
        style={{ minHeight: "64px" }}
      >
        <EuiFlexItem grow={false}>
          <HeaderLogoItem logoLinkUrl={logoLinkUrl} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" responsive={false} alignItems="center" gutterSize="l">
            <EuiFlexGroup direction="row" responsive={false} alignItems="center" style={{ gap: "16px" }}>
              {filteredDesktopRightItems &&
                filteredDesktopRightItems.map((item) => (
                  <EuiFlexItem grow={false} key={item.key}>
                    {item.item}
                  </EuiFlexItem>
                ))}
            </EuiFlexGroup>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPageHeader>
  );
};

export { ApplicationHeader, ApplicationHeaderProps };
