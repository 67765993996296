var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import { EuiText } from "@elastic/eui";
import { colors, fontSizes, fontWeights } from "@pm-frontend/styles";
import Linkify from "react-linkify";
const fontWeightMapping = {
  regular: fontWeights.regular,
  semiBold: fontWeights.semiBold,
  bold: fontWeights.bold
};
const fontSizeMapping = {
  p1: fontSizes.p1,
  p2: fontSizes.p2,
  p3: fontSizes.p3
};
const PmText = (_a) => {
  var _b = _a, {
    fontWeight = "regular",
    fontSize = "p1",
    children,
    color = colors.neutrals.gray800,
    style
  } = _b, rest = __objRest(_b, [
    "fontWeight",
    "fontSize",
    "children",
    "color",
    "style"
  ]);
  const customTextStyles = __spreadProps(__spreadValues({}, style), {
    fontWeight: fontWeightMapping[fontWeight],
    fontSize: fontSizeMapping[fontSize]
  });
  return /* @__PURE__ */ React.createElement(Linkify, { className: "inline-link" }, /* @__PURE__ */ React.createElement(EuiText, __spreadProps(__spreadValues({ style: customTextStyles }, rest), { color }), children));
};
export { PmText };
