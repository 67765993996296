var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState } from "react";
import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule } from "@elastic/eui";
import { BorderColor, PageContentHeight } from "@pm-frontend/styles";
import { ApplicationSidebarBase } from "./ApplicationSidebarBase";
import URL from "@pm-shared/utils/url";
import { useIsMobile } from "@pm-frontend/shared/hooks/useIsMobile";
import { ApplicationSidebarMobile } from "./ApplicationSidebarMobile";
const SIDEBAR_NAV_STATE_LOCAL_STORAGE_KEY = "ui_redesign_is_sidebar_nav_is_open";
const readSidebarStateFromLocalStorage = (user_key) => {
  let lsKey = SIDEBAR_NAV_STATE_LOCAL_STORAGE_KEY + user_key;
  const value = window.localStorage.getItem(lsKey);
  return value === "true" || value === null ? "open" : "closed";
};
const writeSidebarStateToLocalStorage = (user_key, state) => {
  let lsKey = SIDEBAR_NAV_STATE_LOCAL_STORAGE_KEY + user_key;
  window.localStorage.setItem(lsKey, state.toString());
};
const useApplicationSidebarState = (userKey) => {
  const isMobile = useIsMobile();
  const [sidebarNavIsOpen, setSidebarNavIsOpen] = useState(() => {
    if (isMobile) {
      return false;
    }
    return readSidebarStateFromLocalStorage(userKey) === "open";
  });
  const toggleSidebarNav = () => {
    setSidebarNavIsOpen((state) => {
      if (isMobile) {
        return !state;
      }
      writeSidebarStateToLocalStorage(userKey, !state);
      return !state;
    });
  };
  return { sidebarNavIsOpen, toggleSidebarNav };
};
const ApplicationSidebar = (props) => {
  const isMobile = useIsMobile();
  if (isMobile) {
    if (!props.isOpen) {
      return null;
    }
    return /* @__PURE__ */ React.createElement(ApplicationSidebarMobile, __spreadValues({}, props));
  }
  if (!props.isOpen) {
    return /* @__PURE__ */ React.createElement("div", { style: { padding: "64px 16px", boxShadow: `-1px 0px 0px 0px ${BorderColor} inset` }, className: "eui-yScroll" }, /* @__PURE__ */ React.createElement(ApplicationSidebarBase, __spreadValues({}, props)), /* @__PURE__ */ React.createElement("div", { style: { minHeight: "32px", paddingTop: "16px" } }, /* @__PURE__ */ React.createElement(
      EuiButtonIcon,
      {
        iconType: URL.getStatic("icons/menu_close-1.svg"),
        iconSize: "original",
        onClick: props.toggleOpen,
        "aria-label": "Open sidebar menu",
        style: { width: "20px", height: "20px" }
      }
    )));
  }
  return /* @__PURE__ */ React.createElement(
    EuiFlexGroup,
    {
      direction: "column",
      style: {
        padding: "32px 8px 0px 16px",
        gap: "32px",
        maxHeight: PageContentHeight,
        overflowY: "auto",
        scrollbarGutter: "stable",
        boxShadow: `-1px 0px 0px 0px ${BorderColor} inset`
      },
      className: "eui-yScroll"
    },
    /* @__PURE__ */ React.createElement(ApplicationSidebarBase, __spreadValues({}, props)),
    props.desktopItems && /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", style: { marginTop: "auto", marginBottom: "auto" }, gutterSize: "s" }, props.desktopItems.map((item, index, items) => /* @__PURE__ */ React.createElement(React.Fragment, { key: index }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, item), index < items.length - 1 ? /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiHorizontalRule, { margin: "none", style: { backgroundColor: BorderColor } })) : null)))
  );
};
export { ApplicationSidebar, useApplicationSidebarState };
