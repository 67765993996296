import classnames from "classnames";
import React, { CSSProperties } from "react";
import { Keys } from "../../utils/key-utils";

export interface SearchSelectProps {
  allowNew: boolean;
  data: any[];
  displayComponent: React.ComponentType<{ item: any }>;
  activeItem?: any;
  defaultValue?: any;
  displayKey?: string;
  onItemUpdated?: (item: any) => void;
  onSearch?: (query: string) => void;
}

interface SearchSelectState {
  inputFocused: boolean;
  currentValue: any;
  focusedItem: any;
}

export default class SearchSelect extends React.Component<SearchSelectProps, SearchSelectState> {
  static defaultProps = {
    data: [],
    allowNew: true
  };

  focusedItem: HTMLLIElement;
  input: HTMLInputElement;
  resultList: HTMLUListElement;

  constructor(options: SearchSelectProps) {
    super(options);

    this.state = {
      inputFocused: false,
      currentValue: this.props.defaultValue,
      focusedItem: null
    };

    this.handleInputClicked = this.handleInputClicked.bind(this);
    this.handleInputBlurred = this.handleInputBlurred.bind(this);
    this.handleItemClicked = this.handleItemClicked.bind(this);
    this.handleTextEntered = this.handleTextEntered.bind(this);
    this.handleInputFocused = this.handleInputFocused.bind(this);
    this.handleItemMouseOver = this.handleItemMouseOver.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  render() {
    let { inputFocused, currentValue } = this.state;
    let { activeItem, data } = this.props;
    let inputStyles = {} as CSSProperties;
    const showResults = inputFocused;

    if (!inputFocused && activeItem) {
      inputStyles.left = -10000;
      inputStyles.position = "absolute";
    }

    const inputWrapperClasses = classnames({
      single: true,
      "select-input": true,
      "select-input-item-selected": !inputFocused && activeItem
    });

    const searchSelectClasses = classnames({
      "search-select": true,
      "show-results": showResults,
      "has-results": data.length > 0
    });

    return (
      <div className={searchSelectClasses} onBlur={this.handleInputBlurred} onFocus={this.handleInputFocused}>
        <div className={inputWrapperClasses} onClick={this.handleInputClicked}>
          {this.renderSelectedItem()}
          <input
            type="text"
            ref={node => (this.input = node as HTMLInputElement)}
            style={inputStyles}
            value={currentValue}
            onChange={this.handleTextEntered}
            onKeyDown={this.handleKeyDown}
          />
        </div>
        {this.renderResultList()}
      </div>
    );
  }

  renderSelectedItem() {
    let { displayComponent: DisplayComponent, displayKey, activeItem } = this.props;

    if (DisplayComponent) {
      return (
        <div className="selected-item">
          <DisplayComponent item={activeItem} />
        </div>
      );
    } else if (displayKey) {
      return <div className="selected-item">{activeItem ? activeItem[displayKey] : ""}</div>;
    }
  }

  renderResultList() {
    let { displayComponent: DisplayComponent, displayKey, data } = this.props;

    if (data.length === 0) {
      return null;
    }

    let items = data.map((item: any, i: number) => {
      let extraProps = {} as { ref?: (node: HTMLLIElement) => void };
      let renderedItem;
      const isFocused = this.state.focusedItem === item;
      let classes = classnames({
        "result-list-item": true,
        "focused-item": isFocused
      });

      if (isFocused) {
        extraProps.ref = (node: HTMLLIElement) => (this.focusedItem = node);
      }

      if (DisplayComponent) {
        renderedItem = (
          <div>
            <DisplayComponent item={item} />
          </div>
        );
      } else if (displayKey) {
        renderedItem = <div>{item[displayKey]}</div>;
      }

      return (
        <li
          key={i}
          onMouseDown={this.handleItemClicked.bind(this, item)}
          className={classes}
          onMouseOver={this.handleItemMouseOver.bind(this, item)}
          {...extraProps}
        >
          {renderedItem}
        </li>
      );
    });

    return (
      <ul className="result-list" ref={(node: HTMLUListElement) => (this.resultList = node)}>
        {items}
      </ul>
    );
  }

  handleItemMouseOver(item: any) {
    this.setState({ focusedItem: item });
  }

  handleItemClicked(item: any, e: React.MouseEvent<HTMLLIElement>) {
    this.selectItem(item);
    e.preventDefault();
  }

  handleInputClicked() {
    this.handleInputFocused();
  }

  handleInputBlurred() {
    this.setState({ inputFocused: false });
  }

  handleTextEntered(e: React.ChangeEvent<HTMLInputElement>) {
    let { allowNew } = this.props;

    if (this.state.inputFocused) {
      if (allowNew && this.props.onItemUpdated) {
        this.props.onItemUpdated(null);
      }

      this.setState({ currentValue: e.target.value });
      if (this.props.onSearch) {
        this.props.onSearch(e.target.value);
      }
    }
  }

  handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    let { data } = this.props;
    let { inputFocused } = this.state;
    let focusedItemIndex = data.findIndex((item: any) => item === this.state.focusedItem);

    if (inputFocused && focusedItemIndex !== -1) {
      if (e.keyCode === Keys.KEY_DOWN) {
        if (focusedItemIndex !== data.length - 1) {
          this.setState({ focusedItem: data[focusedItemIndex + 1] });
        }
        e.preventDefault();
      } else if (e.keyCode === Keys.KEY_UP) {
        if (focusedItemIndex !== 0) {
          this.setState({ focusedItem: data[focusedItemIndex - 1] });
        }
        e.preventDefault();
      } else if (e.keyCode === Keys.ENTER) {
        this.selectItem(data[focusedItemIndex]);
        e.preventDefault();
      } else if (e.keyCode === Keys.ESCAPE) {
        this.setState({ inputFocused: false });
      }
    }
  }

  selectItem(item: any) {
    if (this.props.onItemUpdated) {
      this.props.onItemUpdated(item);
    }
    this.setState({ inputFocused: false });
  }

  handleInputFocused() {
    let { data } = this.props;
    this.setState({
      inputFocused: true,
      focusedItem: data ? data[0] : null
    });
  }

  componentDidUpdate() {
    if (this.state.inputFocused) {
      this.input.focus();

      if (this.focusedItem) {
        const resultRect = this.resultList.getBoundingClientRect();
        const focusedItemRect = this.focusedItem.getBoundingClientRect();

        if (focusedItemRect.bottom > resultRect.bottom) {
          this.resultList.scrollTop = this.resultList.scrollTop + this.focusedItem.clientHeight;
        } else if (focusedItemRect.top < resultRect.top) {
          this.resultList.scrollTop = this.resultList.scrollTop - this.focusedItem.clientHeight;
        }
      }
    } else {
      this.input.blur();
    }
  }

  componentWillReceiveProps(nextProps: SearchSelectProps) {
    if (nextProps.data !== this.props.data) {
      this.setState({ focusedItem: nextProps.data[0] });
    }
  }
}
