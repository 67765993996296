import alt from "../../alt";

import BaseStore from "../../stores/base-store";
import invoiceActions from "../actions/invoice-actions";
import invoiceSource from "../sources/invoice-source";

class InvoiceStore extends BaseStore {
  constructor(options) {
    super(options);

    this.bindListeners({
      fetchingAll: invoiceActions.FETCH_ALL,
      fetchAllDone: invoiceActions.FETCH_ALL_DONE,
      fetchAllFail: invoiceActions.FETCH_ALL_FAIL,
      fetching: invoiceActions.FETCH_ONE,
      fetchDone: invoiceActions.FETCH_ONE_DONE,
      fetchFail: invoiceActions.FETCH_ONE_FAIL,
      deleteDone: invoiceActions.DELETE_DONE,
      fetchInvoicesByUnitDone: invoiceActions.FETCH_INVOICES_BY_UNIT_DONE
    });

    this.registerAsync(invoiceSource);

    this.exportPublicMethods({
      getInvoicesByUnit: this.getInvoicesByUnit.bind(this)
    });
  }

  getInvoicesByUnit(unitId) {
    let uId = parseInt(unitId);
    return this.getModelState().filter(invoice => invoice.getIn(["meld", "unit", "id"]) === uId);
  }

  fetchInvoicesByUnitDone(invoices) {
    this.receive(invoices);
  }
}

export default alt.createStore(InvoiceStore, "InvoiceStore");
