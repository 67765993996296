import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

interface AgentDetail {
  id: number;
  department: string;
  title: string;
  contact?: number;
  user?: number;
  management: number;
  role: number;
  new_notification_settings: number;
  agent_preferences: number;
  first_name: string;
  last_name: string;
  show_tour: boolean;
  default_meld_filter?: number;
  default_invoice_filter?: number;
  property_groups: number[];
  update_all?: boolean;
}

class AgentDetailAPI extends BaseApi {
  static update(data: AgentDetail) {
    return this.put(ApiUrls.agentMeDetail, data);
  }
}

export default AgentDetailAPI;
