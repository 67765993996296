import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";

class SearchApi extends BaseApi {
  static searchAll(options: any) {
    return this.get(ApiUrls.searchAll, options);
  }
}

export default SearchApi;
