import React from "react";
import { EuiHeaderSectionItem, EuiIcon } from "@elastic/eui";
import { Link } from "react-router-dom";

import URL from "@pm-shared/utils/url";
import { RouteUrls } from "@pm-frontend/shared/utils/route-urls";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";

const HeaderNotificationItem = ({ hasUnreadNotifications }: { hasUnreadNotifications: boolean }) => {
  if (hasUnreadNotifications) {
    return (
      <EuiHeaderSectionItem aria-label="Notifications">
        <Link to={LinkHelper.normalize(RouteUrls.notifications)} style={{ padding: "0px" }}>
          <EuiIcon type={URL.getStatic("icons/notification-dot.svg")} size="l" />
        </Link>
      </EuiHeaderSectionItem>
    );
  }
  return (
    <EuiHeaderSectionItem aria-label="Notifications">
      <Link to={LinkHelper.normalize(RouteUrls.notifications)} style={{ padding: "0px" }}>
        <EuiIcon type={URL.getStatic("icons/notification-none.svg")} size="l" />
      </Link>
    </EuiHeaderSectionItem>
  );
};

export { HeaderNotificationItem };
