import { ImmutableMap } from "../../types/common";
import mime from "mime";

const previewableFileTypes = new Set([
  "application/pdf",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/heic",
  "image/heif",
]);

let FileUtils = {
  isOpenable(file: ImmutableMap) {
    return !!file.get("openable_signed_url");
  },

  isPreviewAvailable(file: ImmutableMap) {
    return previewableFileTypes.has(FileUtils.getMimeType(file));
  },

  isThumbnailAvailable(file: ImmutableMap) {
    return FileUtils.isPreviewAvailable(file) && !!file.get("thumbnail");
  },

  isPDF(file: ImmutableMap) {
    return mime.getType(file.get("filename")) === "application/pdf";
  },

  getMimeType(file: ImmutableMap) {
    const filename: string = file.get("filename") || "";
    return mime.getType(filename) || "";
  },
};
export default FileUtils;
