import I from "immutable";
import React from "react";

import AgentUtils from "@pm-assets/js/management/utils/agent-utils";
import { ImmutableMap } from "@pm-assets/js/types/common";
import OwnerUtils from "@pm-assets/js/property/utils/owner-utils";
import TenantUtils from "@pm-assets/js/tenant/utils/tenant-utils";
import { UserTypes } from "@pm-app/components/labels/UserTypeLabel";
import VendorAgentUtils from "@pm-assets/js/vendor/utils/agent-utils";

enum CommentClasses {
  ManagerComment = "ManagerComment",
  TenantComment = "TenantComment",
  VendorComment = "VendorComment",
  OwnerComment = "OwnerComment"
}

const CommentUtils = {
  getUserType(comment: ImmutableMap) {
    switch (comment.get("clazz")) {
      case CommentClasses.TenantComment:
        return UserTypes.Tenant;
      case CommentClasses.ManagerComment:
        return UserTypes.Manager;
      case CommentClasses.VendorComment:
        return UserTypes.Vendor;
      case CommentClasses.OwnerComment:
        return UserTypes.Owner;
    }
  },

  getReadStatuses(
    readByInApp: I.List<ImmutableMap>,
    emailStatuses: I.List<ImmutableMap>,
    messageStatuses: I.List<ImmutableMap>,
    readerType: string
  ) {
    const read = I.Set(readByInApp)
      .union(
        emailStatuses
          .filter((readerEmailStatus: ImmutableMap) => readerEmailStatus.getIn(["email_status", "opened"]))
          .map((readerEmailStatus: ImmutableMap) => readerEmailStatus.get(readerType))
      )
      .union(
        messageStatuses
          .filter((readerMessageStatus: ImmutableMap) => readerMessageStatus.getIn(["message_status", "delivered"]))
          .map((readerMessageStatus: ImmutableMap) => readerMessageStatus.get(readerType))
      );

    const deliveryFailed = I.Set(
      emailStatuses
        .filter((readerEmailStatus: ImmutableMap) => readerEmailStatus.getIn(["email_status", "delivery_failed"]))
        .map((readerEmailStatus: ImmutableMap) => readerEmailStatus.get(readerType))
    )
      .union(
        messageStatuses
          .filter((readerMessageStatus: ImmutableMap) =>
            readerMessageStatus.getIn(["message_status", "delivery_failed"])
          )
          .map((readerMessageStatus: ImmutableMap) => readerMessageStatus.get(readerType))
      )
      .subtract(read);

    const unread = I.Set(emailStatuses.map((readerEmailStatus: ImmutableMap) => readerEmailStatus.get(readerType)))
      .union(messageStatuses.map((readerMessageStatus: ImmutableMap) => readerMessageStatus.get(readerType)))
      .subtract(read)
      .subtract(deliveryFailed);

    return { read, deliveryFailed, unread };
  },

  getFormattedReadStatuses(
    titlePrefix: string,
    managementAgents: I.Set<ImmutableMap>,
    vendorAgents: I.Set<ImmutableMap>,
    tenants: I.Set<ImmutableMap>,
    owners: I.Set<ImmutableMap>
  ) {
    let elements = I.List();

    function createStatuses(
      userSet: I.Set<ImmutableMap>,
      formatUserFn: (user: ImmutableMap) => string,
      userType: string
    ) {
      if (!userSet.isEmpty()) {
        const usersFormatted = userSet
          .map(formatUserFn)
          .sort()
          .join(", ");
        const plural = vendorAgents.count() > 1;
        elements = elements.push(
          <span key={userType[0]}>
            <div className="status-header">{`${titlePrefix} ${userType}${plural ? "s" : ""}:`}</div>
            <span>{`${usersFormatted}`}</span>
          </span>
        );
      }
    }

    createStatuses(vendorAgents, vendorAgent => VendorAgentUtils.getFullName(vendorAgent), "vendor");
    createStatuses(owners, owner => OwnerUtils.getName(owner), "owner");
    createStatuses(tenants, tenant => TenantUtils.getFullName(tenant), "tenant");
    createStatuses(managementAgents, managementAgent => AgentUtils.getFullName(managementAgent), "manager");

    return (
      <span>{elements.interleave(I.Range(elements.count() - 1).map((i: number) => <br key={i} />)).flatten()}</span>
    );
  }
};

export default CommentUtils;
