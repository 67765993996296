import createReactClass from "create-react-class";
import React from "react";

/* tslint:disable:max-line-length */
let QuestionMark = createReactClass<{ classes?: string }, {}>({
  render() {
    return (
      <svg className={this.props.classes} xmlns="https://www.w3.org/2000/svg" viewBox="0 0 64 64">
        <path d="M31.15 51.53c-1.917 0-3.548.604-4.9 1.815-1.353 1.21-2.03 2.682-2.03 4.406 0 1.953.685 3.482 2.05 4.596 1.372 1.113 2.963 1.67 4.788 1.67 1.856 0 3.473-.55 4.857-1.65 1.384-1.098 2.072-2.635 2.072-4.614 0-1.725-.66-3.195-1.98-4.406-1.325-1.212-2.942-1.816-4.857-1.816M50.69 8.666c-1.757-2.67-4.272-4.81-7.48-6.355C40.022.775 36.31 0 32.17 0c-4.457 0-8.422.93-11.782 2.77-3.367 1.838-5.962 4.198-7.724 7.02-1.774 2.83-2.668 5.677-2.668 8.46 0 1.49.616 2.878 1.83 4.125 1.222 1.254 2.742 1.89 4.516 1.89 3.02 0 5.09-1.78 6.14-5.26.958-2.888 2.137-5.085 3.5-6.542 1.285-1.372 3.367-2.066 6.188-2.066 2.433 0 4.352.682 5.875 2.086 1.5 1.388 2.23 3.044 2.23 5.058 0 1.016-.236 1.926-.714 2.788-.503.906-1.134 1.74-1.87 2.478-.78.78-2.067 1.957-3.825 3.493-2.048 1.794-3.7 3.37-4.908 4.672-1.252 1.356-2.274 2.953-3.03 4.74-.76 1.804-1.146 3.952-1.146 6.387 0 2.08.58 3.69 1.728 4.79 1.135 1.09 2.548 1.642 4.203 1.642 3.165 0 5.152-1.73 5.742-4.963.306-1.346.53-2.286.68-2.823.137-.486.335-.984.59-1.482.247-.482.64-1.03 1.164-1.635.55-.63 1.296-1.376 2.207-2.207 3.41-3.046 5.813-5.253 7.147-6.56 1.376-1.348 2.576-2.963 3.57-4.805 1.017-1.89 1.534-4.11 1.534-6.598-.002-3.154-.896-6.11-2.66-8.79" />
      </svg>
    );
  },
});
/* tslint:enable:max-line-length */

export default QuestionMark;
