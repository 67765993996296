import { ApiApi, Configuration, MApi, OApi, TApi, VApi } from "./openapi";
import { LinkHelper } from "@pm-shared/utils/link";
import { BaseApiCustomCodes } from "../../../assets/js/utils/base-api";
import { decrementXHRCount, incrementXHRCount } from "../../../assets/js/utils/xhr-counter";
import { ERROR_403, ERROR_404, ERROR_SUPPORT_MESSAGE } from "../../../assets/js/constants";
import ApiUtils from "../../../assets/js/utils/api-utils";
import { AuthUtils } from "../../../assets/js/utils/auth-utils";

export const authParams = {
  multitenant: LinkHelper.getMultitenant(),
  org: LinkHelper.getOrgType(),
  org_id: LinkHelper.getOrgId(),
};

export const Api = new ApiApi(
  new Configuration({
    basePath: window.location.origin,
    baseOptions: {
      headers: { "X-CSRFToken": AuthUtils.getCsrfToken() },
    },
  })
);

export let mApi = new MApi(
  new Configuration({
    basePath: window.location.origin,
    baseOptions: {
      headers: { "X-CSRFToken": AuthUtils.getCsrfToken() },
    },
  })
);

export let vApi = new VApi(
  new Configuration({
    basePath: window.location.origin,
    baseOptions: {
      headers: { "X-CSRFToken": AuthUtils.getCsrfToken() },
    },
  })
);

export let tApi = new TApi(
  new Configuration({
    basePath: window.location.origin,
    baseOptions: {
      headers: { "X-CSRFToken": AuthUtils.getCsrfToken() },
    },
  })
);

// @ts-ignore
export let oApi = new OApi(
  new Configuration({
    basePath: window.location.origin,
    baseOptions: {
      headers: { "X-CSRFToken": AuthUtils.getCsrfToken() },
    },
  })
);

export const onFulfilled = (response: any) => {
  decrementXHRCount();
  return response;
};

export const onRejected = (error: any) => {
  decrementXHRCount();

  // tslint:disable-next-line: triple-equals
  if (error == "Cancel") {
    error.code = BaseApiCustomCodes.RequestCancelled;
    error.message = `${BaseApiCustomCodes.RequestCancelled} - Request was canceled`;
    return Promise.reject(error);
  }

  let errorsMessages;
  if (error && error.response) {
    if (error.response.status === 403) {
      errorsMessages = [ERROR_403];
    } else if (error.response.status === 404) {
      errorsMessages = [ERROR_404];
    } else {
      errorsMessages = ApiUtils.transformErrors(error.response);
    }
  }
  if (!errorsMessages || !errorsMessages.length) {
    errorsMessages = [ERROR_SUPPORT_MESSAGE];
  }
  error.messages = errorsMessages;
  // TODO Remove this line after converting base store to only use `messages` instead of `msgs`
  error.msgs = errorsMessages;
  return Promise.reject(error);
};

export const reqOnFulfilled = (config: any) => {
  incrementXHRCount();
  return config;
};
export const reqOnRejected = (error: any) => {
  decrementXHRCount();
  return Promise.reject(error);
};

// Property 'axios' is protected and only accessible within class 'BaseAPI' and its subclasses.
// It's set as protected and part of the openapi gen code, ignore the error, and force the override
// @ts-ignore
mApi.axios.interceptors.request.use(reqOnFulfilled, reqOnRejected);

// @ts-ignore
mApi.axios.interceptors.response.use(onFulfilled, onRejected);

// @ts-ignore
tApi.axios.interceptors.request.use(reqOnFulfilled, reqOnRejected);

// @ts-ignore
tApi.axios.interceptors.response.use(onFulfilled, onRejected);

// @ts-ignore
vApi.axios.interceptors.request.use(reqOnFulfilled, reqOnRejected);

// @ts-ignore
vApi.axios.interceptors.response.use(onFulfilled, onRejected);
