import React, { MouseEvent } from "react";
import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem } from "@elastic/eui";
import { Property } from "csstype";
import { useSlate } from "slate-react";

import { CustomElement, MarkType } from "./custom-types";
import {
  getAbsoluteLink,
  isBlockActive,
  isLinkActive,
  isMarkActive,
  toggleBlock,
  toggleMark,
  unwrapLink,
  wrapLink,
} from "./utils";
import { colors } from "@pm-frontend/styles";
import { css, Global } from "@emotion/react";
import {
  PmEditorAlignCenter,
  PmEditorAlignJustify,
  PmEditorAlignLeft,
  PmEditorAlignRight,
  PmEditorBold,
  PmEditorInsertLink,
  PmEditorItalic,
  PmEditorOrderedList,
  PmEditorQuote,
  PmEditorUnderline,
  PmEditorUnorderedList,
} from "@pm-frontend/assets/icons/components/PmEditorToolbarIcons";

interface BlockButtonProps {
  formatType: CustomElement["type"] | Property.TextAlign;
  buttonContent: (arg0: boolean) => React.ReactNode;
}

interface MarkButtonProps {
  markType: MarkType;
  buttonContent: (arg0: boolean) => React.ReactNode;
}

const toolBarButtonStyle: React.CSSProperties = {
  borderRadius: "2px 0px 0px 2px",
  border: `1px solid ${colors.neutrals.gray200}`,
  padding: "8px",
  width: "40px",
};

const BlockButton: React.FC<BlockButtonProps> = ({ formatType, buttonContent }) => {
  const editor = useSlate();
  const active = isBlockActive(editor, formatType);

  return (
    <EuiButtonEmpty
      className="editor-toolbar-button"
      style={toolBarButtonStyle}
      aria-label={formatType}
      isSelected={active}
      textProps={{ style: { height: "20px", alignSelf: "center" } }}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        toggleBlock(editor, formatType);
      }}
    >
      {buttonContent(active)}
    </EuiButtonEmpty>
  );
};

const MarkButton: React.FC<MarkButtonProps> = ({ markType, buttonContent }) => {
  const editor = useSlate();
  const active = isMarkActive(editor, markType);

  return (
    <EuiButtonEmpty
      className="editor-toolbar-button"
      style={toolBarButtonStyle}
      isSelected={active}
      aria-label={markType}
      textProps={{ style: { height: "20px", alignSelf: "center" } }}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        toggleMark(editor, markType);
      }}
    >
      {buttonContent(active)}
    </EuiButtonEmpty>
  );
};

// The PC+ toolbar doesn't include heading controls - leaving this in but we will need an icon for h1,h2
export const HeadingOneButton: React.FC = () => {
  return <BlockButton formatType="heading-one" buttonContent={(_: boolean) => undefined} />;
};

// The PC+ toolbar doesn't include heading controls - leaving this in but we will need an icon for h1,h2
export const HeadingTwoButton: React.FC = () => {
  return <BlockButton formatType="heading-two" buttonContent={(_: boolean) => undefined} />;
};

export const OrderedListButton: React.FC = () => {
  return (
    <BlockButton
      formatType="ordered-list"
      buttonContent={(active: boolean) => <PmEditorOrderedList {...(active ? { fill: colors.brand.meldBlue } : {})} />}
    />
  );
};

export const UnorderedListButton: React.FC = () => {
  return (
    <BlockButton
      formatType="unordered-list"
      buttonContent={(active: boolean) => (
        <PmEditorUnorderedList {...(active ? { fill: colors.brand.meldBlue } : {})} />
      )}
    />
  );
};

export const QuoteButton: React.FC = () => {
  return (
    <BlockButton
      formatType="quote"
      buttonContent={(active: boolean) => <PmEditorQuote {...(active ? { fill: colors.brand.meldBlue } : {})} />}
    />
  );
};

// The PC+ toolbar doesn't include a divider control - leaving this in but we will need an icon
export const DividerButton: React.FC = () => {
  return <BlockButton formatType="divider" buttonContent={(_: boolean) => undefined} />;
};

export const LinkButton: React.FC = () => {
  const editor = useSlate();
  const active = isLinkActive(editor);
  return (
    <EuiButtonEmpty
      className="editor-toolbar-button"
      style={toolBarButtonStyle}
      aria-label="link"
      textProps={{ style: { height: "20px", alignSelf: "center" } }}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        if (active) {
          unwrapLink(editor);
        } else {
          // eslint-disable-next-line no-alert
          const url = window.prompt("Enter the URL of the link:");
          if (!url || !editor.selection) {
            return;
          }
          wrapLink(editor, getAbsoluteLink(url));
        }
      }}
    >
      <PmEditorInsertLink fill={active ? colors.brand.meldBlue : undefined} />
    </EuiButtonEmpty>
  );
};

export const AlignLeftButton: React.FC = () => {
  return (
    <BlockButton
      formatType="left"
      buttonContent={(active: boolean) => <PmEditorAlignLeft {...(active ? { fill: colors.brand.meldBlue } : {})} />}
    />
  );
};

export const AlignCenterButton: React.FC = () => {
  return (
    <BlockButton
      formatType="center"
      buttonContent={(active: boolean) => <PmEditorAlignCenter {...(active ? { fill: colors.brand.meldBlue } : {})} />}
    />
  );
};

export const AlignRightButton: React.FC = () => {
  return (
    <BlockButton
      formatType="right"
      buttonContent={(active: boolean) => <PmEditorAlignRight {...(active ? { fill: colors.brand.meldBlue } : {})} />}
    />
  );
};

export const AlignJustifyButton: React.FC = () => {
  return (
    <BlockButton
      formatType="justify"
      buttonContent={(active: boolean) => <PmEditorAlignJustify {...(active ? { fill: colors.brand.meldBlue } : {})} />}
    />
  );
};

export const BoldButton: React.FC = () => {
  return (
    <MarkButton
      markType="bold"
      buttonContent={(active: boolean) => <PmEditorBold {...(active ? { fill: colors.brand.meldBlue } : {})} />}
    />
  );
};

export const ItalicButton: React.FC = () => {
  return (
    <MarkButton
      markType="italic"
      buttonContent={(active: boolean) => <PmEditorItalic {...(active ? { fill: colors.brand.meldBlue } : {})} />}
    />
  );
};

export const UnderlineButton: React.FC = () => {
  return (
    <MarkButton
      markType="underline"
      buttonContent={(active: boolean) => <PmEditorUnderline {...(active ? { fill: colors.brand.meldBlue } : {})} />}
    />
  );
};

// The PC+ toolbar doesn't include a code control - leaving this in but we will need an icon
export const CodeButton: React.FC = () => {
  return <MarkButton markType="code" buttonContent={(_: boolean) => undefined} />;
};

const Toolbar = ({}) => {
  return (
    <>
      <Global
        styles={css`
          .editor-toolbar-button {
            inline-size: 20px;
            block-size: 20px;
            padding: 0px !important;
          }
        `}
      />
      <EuiFlexGroup direction="row" responsive={false} alignItems="flexStart" style={{ gap: "12px" }}>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" responsive={false} alignItems="flexStart" gutterSize="none">
            <BoldButton />
            <ItalicButton />
            <UnderlineButton />
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" responsive={false} alignItems="flexStart" gutterSize="none">
            <QuoteButton />
            <OrderedListButton />
            <UnorderedListButton />
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" responsive={false} alignItems="flexStart" gutterSize="none">
            <AlignLeftButton />
            <AlignCenterButton />
            <AlignRightButton />
            <AlignJustifyButton />
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="row" responsive={false} alignItems="flexStart" gutterSize="none">
            <LinkButton />
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  );
};

export { Toolbar };
