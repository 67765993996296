import alt from "../../alt";

import UserAPI from "../apis/user-api";
import messageActions from "../../common/actions/message-actions";

class UserActions {
  constructor() {
    this.generateActions("fetchCurrent", "fetchCurrentDone", "fetchCurrentFail", "updateDone", "updateFail");
  }

  update(data, { cid } = {}) {
    UserAPI.update(data)
      .then(res => {
        messageActions.storeSuccess({ body: "Settings saved." });
        this.updateDone({ data: res.data, cid });
      })
      .catch(res => {
        this.updateFail({ data: res, cid });
      });

    return { cid };
  }
}

export default alt.createActions(UserActions);
