import React from "react";
import * as H from "history";

import Button, { ButtonTypes } from "./Button";
import ButtonIcon from "./ButtonIcon";
import LinkButton from "./LinkButton";

const downSvg = require("../../assets/icons/ArrowDown.svg");

export interface MultiButtonProps {
  className?: string;
  buttonType?: ButtonTypes;
  mainLabel: string;
  onMainClicked: (() => void) | H.LocationDescriptor;
  linkQuery?: any;
}

export interface MultiButtonState {
  dropdownActive: boolean;
}

export default class MultiButton extends React.Component<MultiButtonProps, MultiButtonState> {
  constructor(props: MultiButtonProps) {
    super(props);
    this.state = { dropdownActive: false };

    this.showDropdown = this.showDropdown.bind(this);
    this.onWindowClick = this.onWindowClick.bind(this);
  }

  componentDidUpdate() {
    if (this.state.dropdownActive) {
      window.addEventListener("click", this.onWindowClick, false);
    }
  }

  onWindowClick() {
    if (this.state.dropdownActive) {
      this.setState({ dropdownActive: false });
    }
    window.removeEventListener("click", this.onWindowClick, false);
  }

  showDropdown(e: React.MouseEvent<any>) {
    if (!this.state.dropdownActive) {
      e.stopPropagation();
      this.setState({
        dropdownActive: true
      });
    }
  }

  renderMainButton() {
    const { onMainClicked, buttonType, mainLabel, linkQuery } = this.props;

    if (typeof onMainClicked === "function") {
      return (
        <Button buttonType={buttonType} className="pm-multi-button-main-btn" onClick={onMainClicked as () => void}>
          {mainLabel}
        </Button>
      );
    }

    const type = buttonType ? buttonType : ButtonTypes.Neutral;

    return (
      <LinkButton buttonType={type} className="pm-multi-button-main-btn" to={onMainClicked} query={linkQuery}>
        {mainLabel}
      </LinkButton>
    );
  }

  render() {
    const children = React.Children.map(this.props.children, (child: any) => {
      if (child) {
        return React.cloneElement(child, {
          buttonType: this.props.buttonType
        });
      }
    });

    return (
      <div className="pm-button pm-multi-button">
        <div className="pm-multi-button-main-container">
          {this.renderMainButton()}
          <Button
            buttonType={this.props.buttonType}
            className="pm-mulit-button-dropwdown-toggle"
            onClick={this.showDropdown}
          >
            <ButtonIcon className="pm-multi-button-toggle-icon" svg={downSvg} />
          </Button>
        </div>
        {this.state.dropdownActive && <div className="pm-mutli-button-dropdown">{children}</div>}
      </div>
    );
  }
}
