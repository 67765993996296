var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
function deepMerge(target, source) {
  const output = __spreadValues({}, target);
  for (const key in source) {
    if (source[key] === void 0) {
      continue;
    }
    if (isObject(output[key]) && isObject(source[key])) {
      output[key] = deepMerge(output[key], source[key]);
    } else {
      output[key] = source[key];
    }
  }
  return output;
}
function isObject(item) {
  return item !== null && typeof item === "object" && !Array.isArray(item);
}
const _BaseURLFilter = class {
  constructor(config, overrides) {
    const _config = overrides ? deepMerge(config, overrides) : config;
    this.config = _config;
  }
  /***
   * Operators such as 'All of', 'None of', 'Between', 'Missing' etc
   */
  getAllowedOperatorOptions() {
    return this.getAllowedOperators().map((opt) => {
      return {
        label: opt,
        value: opt,
        dropdownDisplay: opt,
        inputDisplay: opt
      };
    });
  }
  getButtonText() {
    return this.getConfig().text;
  }
  getFilterName() {
    return this.getConfig().filterName;
  }
  getOnClearClick({
    location: location2,
    closePopover,
    history,
    savedFilter
  }) {
    return () => {
      const savedSearchParams = this.getQueryParamsFromSavedFilter({ savedFilter, location: location2 });
      this.deleteAllParamValues({ paramsToMutate: savedSearchParams, location: location2 });
      history.replace({
        pathname: location2.pathname,
        search: savedSearchParams.toString()
      });
      closePopover();
    };
  }
  getPopoverWidth() {
    return "350px";
  }
  getQueryParamsFromSavedFilter({
    savedFilter,
    location: location2,
    paramsToMutate
  }) {
    if (savedFilter === void 0) {
      const newParams = paramsToMutate || new URLSearchParams(location2.search);
      newParams.delete("saved_filter");
      return newParams;
    }
    const searchParams = paramsToMutate || new URLSearchParams();
    for (const entry of Object.entries(savedFilter)) {
      const [key, value] = entry;
      if (_BaseURLFilter.SAVED_FILTER_IGNORED_FIELDS.includes(key)) {
        continue;
      }
      if (value === "" || value === null || Array.isArray(value) && value.length === 0) {
        continue;
      }
      if (Array.isArray(value)) {
        searchParams.set(key + "[]", value.join(","));
      } else {
        searchParams.append(key, value);
      }
    }
    return searchParams;
  }
  getQueryParamValuesFromURL(props) {
    const currentURLParams = new URLSearchParams(location.search);
    const newParams = props.paramsToMutate || new URLSearchParams();
    for (const operator of this.getAllowedOperators()) {
      const paramKey = this.getFullQueryParamKey({ operator, target: props.target });
      const value = currentURLParams.get(paramKey);
      if (value) {
        newParams.set(paramKey, value);
      }
    }
    return newParams;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAppliedFilterCount(_props) {
    throw new Error("This method must be implemented by sub-classes");
  }
  getShouldAlwaysShow() {
    return !!this.getConfig().alwaysShow;
  }
  deleteAllParamValues({
    location: location2,
    paramsToMutate
  }) {
    const searchParams = paramsToMutate || new URLSearchParams(location2.search);
    for (const operator of this.getAllowedOperators()) {
      searchParams.delete(this.getFullQueryParamKey({ operator, target: "url" }));
    }
    return searchParams;
  }
  getAllowedOperators() {
    return this.getConfig().allowedOperators;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getFullQueryParamKey(_props) {
    throw new Error("This method must be implemented by sub-classes");
  }
  getQueryParamKeyPrefix() {
    return this.getConfig().queryParamKeyPrefix;
  }
  getConfig() {
    return this.config;
  }
  getSavedFilterValue({
    savedFilter,
    operator
  }) {
    return savedFilter[this.getFullQueryParamKey({ operator, target: "savedFilter" })];
  }
  getSearchParamValue({
    operator,
    location: location2,
    currentSearchParams
  }) {
    const searchParams = currentSearchParams || new URLSearchParams(location2.search);
    return searchParams.get(this.getFullQueryParamKey({ operator, target: "url" }));
  }
};
export let BaseURLFilter = _BaseURLFilter;
BaseURLFilter.SAVED_FILTER_IGNORED_FIELDS = [
  "id",
  "name",
  "filterset_fields",
  "position",
  "private",
  "current_agent_default",
  "limit",
  "offset"
];
