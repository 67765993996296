import createReactClass from "create-react-class";
import React from "react";

/* tslint:disable:max-line-length */
let Calendar = createReactClass({
  render() {
    return (
      <svg
        className={this.props.classes}
        xmlns="https://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
        width="16.5"
        height="16"
        viewBox="0 0 33 32"
      >
        <path
          d="M30.808 32H2.178c-1.21 0-2.192-.98-2.192-2.19V2.19C-.014.98.966 0 2.178 0h.82v5.967h11.977V0h3.036v5.967h12.01V0h.788C32.018 0 33 .98 33 2.19v27.62c0 1.21-.98 2.19-2.192 2.19zm-.785-23.03H2.998v20h27.025v-20zm-18.348 5.455c.56-.56 1.468-.56 2.027 0l3.04 3.04 3.04-3.04c.56-.56 1.467-.56 2.027 0 .558.56.558 1.468 0 2.027l-3.04 3.04 3.04 3.04c.558.558.558 1.467 0 2.026-.56.56-1.47.56-2.03 0l-3.038-3.04-3.04 3.04c-.56.56-1.467.56-2.027 0s-.56-1.467 0-2.027l3.04-3.038-3.04-3.04c-.56-.56-.56-1.467 0-2.027zM20.075 0h7.947v3.97h-7.947V0zM5 0h8.007v3.97H5V0z"
          id="path-1"
          fillRule="evenodd"
        />
      </svg>
    );
  },
});
/* tslint:enable:max-line-length */

export default Calendar;
