import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { SearchResultManagement } from "@pm-frontend/shared/types/api/propertymeld/api";
import { getResultLink, getRowIsEnabled } from "@pm-frontend/shared/utils/search-utils";
import { BaseSearchResultRowProps } from "./SiteSearchDesktopBaseResults";

export const useGlobalSearchKeyboardActions = <T extends BaseSearchResultRowProps>(
  items: T[],
  highlightedIndex: number | undefined,
  setHighlightedIndex: React.Dispatch<React.SetStateAction<number | undefined>>,
  onResultClick: (resultType: string, subResultType: string | undefined) => void
) => {
  const history = useHistory();
  const [intervalId, setIntervalId] = useState<number | null>(null);
  useEffect(() => {
    // prevents cursor from moving in input box
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        event.stopPropagation();
        event.preventDefault();
        // Start interval only if it's not already running
        if (!intervalId && event.repeat) {
          if (event.key === "ArrowDown") {
            const id = window.setInterval(() => {
              setHighlightedIndex((current) => {
                if (current === items.length - 1) {
                  return current;
                }
                return current === undefined ? 0 : current + 1;
              });
            }, 80);

            setIntervalId(id);
          }

          if (event.key === "ArrowUp") {
            const id = window.setInterval(() => {
              setHighlightedIndex((current) => {
                return current === 0 || current === undefined ? undefined : current - 1;
              });
            }, 80);

            setIntervalId(id);
          }
        } else if (!event.repeat) {
          // handle single key press
          if (event.key === "ArrowUp") {
            setHighlightedIndex((current) => {
              return current === 0 || current === undefined ? undefined : current - 1;
            });
          } else if (event.key === "ArrowDown") {
            setHighlightedIndex((current) => {
              if (current === items.length - 1) {
                return current;
              }
              return current === undefined ? 0 : current + 1;
            });
          }
        }
      }
    };

    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        if (highlightedIndex === undefined) {
          return;
        }
        const highlightedRow = items[highlightedIndex] as unknown as SearchResultManagement | undefined;

        if (!highlightedRow) {
          return;
        }
        if (!getRowIsEnabled(highlightedRow)) {
          return;
        }
        const link = getResultLink(highlightedRow);
        if (!link) {
          return;
        }
        history.push(link);
        onResultClick(highlightedRow._index, undefined);
      }

      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        event.stopPropagation();
        event.preventDefault();
        // handle releasing a held down key
        if (intervalId) {
          window.clearInterval(intervalId);
          setIntervalId(null);
        }
      }
    };
    document.addEventListener("keyup", handleKeyUp);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [highlightedIndex, items, intervalId]);
};
