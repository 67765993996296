import React from "react";

/* tslint:disable:max-line-length */
export default class ForwardArrow extends React.Component<{}> {
  render() {
    return (
      <svg
        xmlns="https://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
        className="forward-arrow-svg"
        viewBox="0 0 12 20"
      >
        <path
          d="M.613 19.455c-.787-.752-.85-1.798 0-2.717L7.225 10 .613 3.26c-.85-.917-.787-1.966 0-2.712.785-.752 2.112-.704 2.85 0 .737.7 7.944 8.095 7.944 8.095.394.374.59.865.59 1.357 0 .49-.196.982-.59 1.36 0 0-7.207 7.39-7.944 8.095-.738.705-2.065.75-2.85 0z"
          fillRule="evenodd"
        />
      </svg>
    );
  }
}
/* tslint:enable:max-line-length */
