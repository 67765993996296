import notificationApi from "../api/notification-api";
import Store from "../../common/stores/store";
import { ErrorHandler } from "@pm-app/utils/ErrorHandler";

class NotificationsUnreadStore extends Store {
  constructor() {
    super();
    this.initRequestFunctions({
      fetchUnread: this.fetchUnread,
    });
  }

  fetchUnread() {
    return notificationApi
      .fetchUnread()
      .then((res) => {
        this.setState(res.data);
      })
      .catch((error) => ErrorHandler.handleAxiosError(error));
  }
}

export default new NotificationsUnreadStore();
