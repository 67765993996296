import tenantMeldFileActions from "../actions/tenant-meld-file-actions";
import tenantMeldFileApi from "../apis/tenant-meld-file-api";

let TenantMeldFileSource = {
  fetchFilesForMeld() {
    return {
      remote(state, meldId) {
        return tenantMeldFileApi.fetchFilesForMeld(meldId);
      },

      shouldFetch(state, meldId, force) {
        return true;
      },

      success: tenantMeldFileActions.fetchFilesDone,
      error: tenantMeldFileActions.fetchFilesFail,
      loading: tenantMeldFileActions.fetchFiles
    };
  }
};

export default TenantMeldFileSource;
