import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";
import { omit } from "@chakra-ui/utils";
import { SERVICE_SEVERITIES } from "../../utils/owner-services/constants";

interface SeverityBadgeProps extends BoxProps {
  severity: string;
}

// @ts-ignore
const severityColor = (severity: string) => {
  // TODO: Change color based on severity
  return "#41474D";
}

// TODO: Refactor to use @pmeld/components/badge
export const SeverityBadge = (props: SeverityBadgeProps) => {
  const boxProps = omit(props, ["severity"]);
  return (
    <Box {...boxProps} backgroundColor={severityColor(props.severity)}>
      {SERVICE_SEVERITIES[props.severity]}
    </Box>
  );

};