import I from "immutable";

import * as C from "../../../constants";

const VendorInvoiceUtils = {
  canEdit(invoice: I.Map<string, any>) {
    const status = this.getStatus(invoice);

    return [C.InvoiceStatuses.SUBMITTED, C.InvoiceStatuses.DRAFT, C.InvoiceStatuses.HOLD].includes(status);
  },

  canSubmit(invoice: I.Map<string, any>) {
    const status = this.getStatus(invoice);
    return [C.InvoiceStatuses.DRAFT, C.InvoiceStatuses.HOLD].includes(status);
  },

  getStatus(invoice: I.Map<string, any>) {
    if (invoice) {
      return invoice.get("status");
    }
  },

  getSubmitInvoiceText(invoice?: I.Map<string, any>) {
    let text = "After submission managers will be able to a make payment on this invoice.";

    if (!invoice || invoice.get("status") === C.InvoiceStatuses.DRAFT) {
      text = `${text} You can still edit the invoice until it has been paid.`;
    }

    return text;
  },

  get unpaidStatuses() {
    delete this.unpaidStatuses;
    return (this.unpaidStatuses = [C.InvoiceStatuses.DRAFT, C.InvoiceStatuses.SUBMITTED]);
  },

  get onHoldStatuses() {
    delete this.onHoldStatuses;
    return (this.onHoldStatuses = [C.InvoiceStatuses.DRAFT, C.InvoiceStatuses.HOLD]);
  },

  get submittedStatuses() {
    delete this.submittedStatuses;
    return (this.submittedStatuses = [
      C.InvoiceStatuses.SUBMITTED,
      C.InvoiceStatuses.IN_REVIEW,
      C.InvoiceStatuses.APPROVED
    ]);
  },

  get closedStatuses() {
    delete this.closedStatuses;
    return (this.closedStatuses = [C.InvoiceStatuses.PAID, C.InvoiceStatuses.DECLINED]);
  },

  get allStatuses() {
    delete this.allStatuses;
    return (this.allStatuses = [
      C.InvoiceStatuses.DRAFT,
      C.InvoiceStatuses.SUBMITTED,
      C.InvoiceStatuses.IN_REVIEW,
      C.InvoiceStatuses.HOLD,
      C.InvoiceStatuses.PAID,
      C.InvoiceStatuses.DECLINED,
      C.InvoiceStatuses.APPROVED
    ]);
  },

  get paidStatuses() {
    delete this.paidStatuses;
    return (this.paidStatuses = C.InvoiceStatuses.PAID);
  },

  get defaultInvoiceQuery() {
    delete this.defaultInvoiceQuery;
    return (this.defaultInvoiceQuery = {
      status: this.onHoldStatuses,
      ordering: C.DEFAULT_INVOICE_SORT
    });
  },

  get submittedInvoiceQuery() {
    delete this.submittedInvoiceQuery;
    return (this.submittedInvoiceQuery = {
      status: this.submittedStatuses,
      ordering: C.DEFAULT_INVOICE_SORT
    });
  }
};

export default VendorInvoiceUtils;
