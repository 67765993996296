var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const Documents = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement("path", { d: "M31 0H9a3 3 0 00-3 3v34a3 3 0 003 3h22a3 3 0 003-3V3a3 3 0 00-3-3zm1 37a1 1 0 01-1 1H9a1 1 0 01-1-1V3a1 1 0 011-1h22a1 1 0 011 1z" }), /* @__PURE__ */ React.createElement("path", { d: "M12 9h8a1 1 0 000-2h-8a1 1 0 000 2zM12 13h8a1 1 0 000-2h-8a1 1 0 000 2zM28 27h-8a1 1 0 000 2h8a1 1 0 000-2zM28 31h-8a1 1 0 000 2h8a1 1 0 000-2zM28 15H12a1 1 0 000 2h16a1 1 0 000-2zM28 19H12a1 1 0 000 2h16a1 1 0 000-2z" }));
