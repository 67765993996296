import _ from "lodash";
import * as C from "../../constants";
import MomentUtils from "../../utils/moment-utils";
import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";
import TimeSegment from "./time-segment";

const NUM_FIFTEEN_MIN_INCREMENTS_IN_DAY = 4 * 24;

const BEFORE_HOURS_RANGE = [0, 4];
const WORK_HOURS_RANGE = [4, 56];
const AFTER_HOURS_RANGE = [56, 60];

let Calendar = createReactClass({
  propTypes: {
    startDay: PropTypes.object.isRequired,
    onEventClicked: PropTypes.func.isRequired,
    onDeleteClicked: PropTypes.func.isRequired,
    timeBlockSize: PropTypes.number.isRequired,
    events: PropTypes.array,
    disabledTo: PropTypes.object,
    numDays: PropTypes.number.isRequired,
    eventStyleGetter: PropTypes.func,
  },

  getDefaultProps() {
    return {
      events: [],
    };
  },

  getInitialState() {
    return {
      active: {},
      compressedMode: true,
    };
  },

  render() {
    let startDay = this.props.startDay.clone();

    let timeSegment = startDay.clone();
    let timeLabelMoment = timeSegment.clone();
    let timeSegmentRows;

    if (this.state.compressedMode) {
      timeSegmentRows = this.getCompressedOverflowRows(
        BEFORE_HOURS_RANGE,
        timeLabelMoment.clone(),
        timeLabelMoment.hours(C.COMPRESSED_CALENDAR_MORNING_START_HOUR)
      );
      timeSegment.add(5, "hours");

      let workingHoursRows = this.getEditableSegments(WORK_HOURS_RANGE, timeLabelMoment.clone(), timeSegment);
      let startMoment = timeLabelMoment.clone().hours(C.COMPRESSED_CALENDAR_EVENING_END_HOUR);
      let endMoment = timeLabelMoment.clone().hours(C.COMPRESSED_CALENDAR_EVENING_END_HOUR).add(4, "hours");
      let afterHoursRow = this.getCompressedOverflowRows(AFTER_HOURS_RANGE, startMoment, endMoment);

      timeSegmentRows = timeSegmentRows.concat(workingHoursRows, afterHoursRow);
    } else {
      timeSegmentRows = this.getEditableSegments(
        [0, NUM_FIFTEEN_MIN_INCREMENTS_IN_DAY],
        timeLabelMoment.clone(),
        timeSegment
      );
    }

    return (
      <div className="calendar">
        <table>
          <thead className="calendar-day-header">
            <tr>
              <th />
              {this.renderDayColumns()}
            </tr>
          </thead>
          <tbody>{timeSegmentRows}</tbody>
        </table>
      </div>
    );
  },

  renderDayColumns() {
    let startDay = this.props.startDay.clone();

    return _.range(this.props.numDays).map((dayIndex) => {
      const dateStr = startDay.clone().add(dayIndex, "days").format("ddd M/D");
      return <th key={dateStr}>{dateStr}</th>;
    });
  },

  onTimeSegmentMouseEnter({ timeSegment }) {
    for (let i = 0; i < this.props.timeBlockSize; i++) {
      let active = {
        dtstart: timeSegment.clone().subtract(i * 15, "minutes"),
        dtend: timeSegment.clone().add(this.props.timeBlockSize * 15 - i * 15, "minutes"),
      };

      if (!this._collides(active)) {
        this.setState({ active });
        break;
      }
    }
  },

  onTimeSegmentMouseLeave() {
    this.setState({ active: {} });
  },

  getCompressedOverflowRows(timeIntervalRanges, startMoment, endMoment) {
    return _.range.apply(this, timeIntervalRanges).map((timeIntervalIndex) => {
      let timeSegmentsRow = _.range(this.props.numDays + 1).map((dayIndex) => {
        if (dayIndex === 0) {
          if (timeIntervalIndex % 4 === 0) {
            return (
              <td
                key={timeIntervalIndex + dayIndex}
                onClick={() =>
                  this.setState({
                    compressedMode: !this.state.compressedMode,
                  })
                }
                className="time-segment-hour"
                rowSpan="4"
              >
                {startMoment.format("hA")}-{endMoment.format("hA")}
              </td>
            );
          } else {
            return null;
          }
        }

        return <td key={timeIntervalIndex + dayIndex} className="time-slot disabled" />;
      });

      return (
        <tr className="time-segment-row" key={`time-segment-row-${timeIntervalIndex}`}>
          {timeSegmentsRow}
        </tr>
      );
    });
  },

  getEditableSegments(timeIntervalRange, timeLabelMoment, timeSegment) {
    return _.range.apply(this, timeIntervalRange).map((timeIntervalIndex) => {
      let timeSegmentsRow = _.range(this.props.numDays + 1).map((dayIndex) => {
        let result;

        if (dayIndex === 0) {
          result = this.getTimeColumn(timeIntervalIndex, dayIndex, timeLabelMoment);
        } else {
          let ts;

          if (this.state.compressedMode) {
            ts = timeSegment
              .clone()
              .add(dayIndex - 1, "days")
              .hours(C.COMPRESSED_CALENDAR_MORNING_START_HOUR)
              .add((timeIntervalIndex - 4) * 15, "minutes");
          } else {
            ts = timeSegment
              .clone()
              .add(dayIndex - 1, "days")
              .hours(0)
              .add(timeIntervalIndex * 15, "minutes");
          }

          result = this.getTimeSegment(timeIntervalIndex, dayIndex, ts);
        }
        return result;
      });

      return (
        <tr className="time-segment-row" key={timeIntervalIndex}>
          {timeSegmentsRow}
        </tr>
      );
    });
  },

  getTimeColumn(timeIntervalIndex, dayIndex, timeLabelMoment) {
    if (timeIntervalIndex % 4 === 0) {
      let result = (
        <td
          key={timeIntervalIndex + dayIndex}
          onClick={() =>
            this.setState({
              compressedMode: !this.state.compressedMode,
            })
          }
          className="time-segment-hour"
          rowSpan="4"
        >
          {timeLabelMoment.format("hA")}
        </td>
      );
      timeLabelMoment.add(1, "hour");

      return result;
    }
  },

  getTimeSegment(timeIntervalIndex, dayIndex, timeSegment) {
    return (
      <TimeSegment
        active={this.state.active}
        compressedMode={this.state.compressedMode}
        disabledTo={this.props.disabledTo}
        events={this.props.events}
        key={timeIntervalIndex + dayIndex}
        onDeleteClicked={this.props.onDeleteClicked}
        onEventClicked={this.props.onEventClicked}
        onTimeSegmentMouseEnter={this.onTimeSegmentMouseEnter}
        onTimeSegmentMouseLeave={this.onTimeSegmentMouseLeave}
        onSegmentClicked={this.markDate}
        timeSegment={timeSegment}
        eventStyleGetter={this.props.eventStyleGetter}
      />
    );
  },

  markDate() {
    if (!_.isEmpty(this.state.active)) {
      this.setState({ active: {} });
      this.props.onEventClicked(this.state.active);
    }
  },

  _collides({ dtstart, dtend }) {
    return _.find(this.props.events, (event) => {
      return MomentUtils.intersects(dtstart, dtend, event.dtstart, event.dtend);
    });
  },
});

export default Calendar;
