import I from "immutable";
import KeyUtils from "./key-utils";
import React from "react";

let ModelUpdateUtils = {
  setVal(label: string, value: any) {
    this.setState({ data: this.state.data.set(label, value) });
  },

  deleteVal(label: string, value: any) {
    this.setState({ data: this.state.data.delete(label, value) });
  },

  mergeData(data: I.Map<string, any>) {
    this.setState({ data: this.state.data.merge(data) });
  },

  setInVal(labelArr: string[], value: any) {
    this.setState({ data: this.state.data.setIn(labelArr, value) });
  },

  setWithLabel(label: string) {
    return (value: any) => {
      ModelUpdateUtils.setVal.call(this, label, value);
    };
  },

  deleteWithLabel(label: string) {
    return (value: any) => {
      ModelUpdateUtils.deleteVal.call(this, label, value);
    };
  },

  setInWithLabel(labelArr: string[]) {
    return (value: any) => {
      ModelUpdateUtils.setInVal.call(this, labelArr, value);
    };
  },

  onChangeSetIn(labelArr: string[]) {
    return (e: React.ChangeEvent<any>) => {
      this.setState({
        data: this.state.data.setIn(labelArr, e.target.value)
      });
    };
  },

  setFromEvent(label: string, e: React.ChangeEvent<any>) {
    this.setState({ data: this.state.data.set(label, e.target.value) });
  },

  onChangeSet(label: string) {
    return (e: React.ChangeEvent<any>) => {
      this.setState({ data: this.state.data.set(label, e.target.value) });
    };
  },

  onChangeToggle(label: string) {
    return () => {
      this.setState({
        data: this.state.data.set(label, !this.state.data.get(label))
      });
    };
  },

  onChangeToggleIn(labelArr: string[]) {
    return () => {
      this.setState({
        data: this.state.data.setIn(labelArr, !this.state.data.getIn(labelArr))
      });
    };
  },

  forwardOnChange(e: React.SyntheticEvent<any>) {
    this.props.onChange(e);
  },

  onEventSet(label: string) {
    return (value: any) => {
      ModelUpdateUtils.setVal.call(this, label, value);
    };
  },

  submitOnEnter(submitFunc: () => void) {
    return (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (KeyUtils.isEnterKey(e)) {
        submitFunc();
      }
    };
  },

  markDirty(func: (...args: any[]) => void) {
    return (...args: any[]) => {
      this.setState({ dirty: true });
      return func(...args);
    };
  },

  onResetChanges(originalData: I.Map<string, any>) {
    return (e: React.SyntheticEvent<any>) => {
      e.preventDefault();
      this.setState({
        data: originalData,
        dirty: false
      });
    };
  }
};

export default ModelUpdateUtils;

export interface ModelUpdateProps {
  setVal: typeof ModelUpdateUtils.setVal;
  deleteVal: typeof ModelUpdateUtils.setVal;
  mergeData: typeof ModelUpdateUtils.mergeData;
  setInVal: typeof ModelUpdateUtils.setInVal;
  setWithLabel: typeof ModelUpdateUtils.setWithLabel;
  deleteWithLabel: typeof ModelUpdateUtils.deleteWithLabel;
  setInWithLabel: typeof ModelUpdateUtils.setInWithLabel;
  onChangeSetIn: typeof ModelUpdateUtils.onChangeSetIn;
  setFromEvent: typeof ModelUpdateUtils.setFromEvent;
  onChangeSet: typeof ModelUpdateUtils.onChangeSet;
  onChangeToggle: typeof ModelUpdateUtils.onChangeToggle;
  onChangeToggleIn: typeof ModelUpdateUtils.onChangeToggleIn;
  forwardOnChange: typeof ModelUpdateUtils.forwardOnChange;
  onEventSet: typeof ModelUpdateUtils.onEventSet;
  submitOnEnter: typeof ModelUpdateUtils.submitOnEnter;
  markDirty: typeof ModelUpdateUtils.markDirty;
  onResetChanges: typeof ModelUpdateUtils.onResetChanges;
}

export function ModelUpdate(WrapperComponent: React.ComponentClass<any>) {
  return class extends React.Component<typeof WrapperComponent.defaultProps> {
    render() {
      return <WrapperComponent {...this.props} {...ModelUpdateUtils} />;
    }
  };
}
