import PopoutSvg from "../../svg/popout-svg";
import React from "react";
import { ImmutableMap } from "../../types/common";

interface Props {
  file: ImmutableMap;
}

export default class FileViewerNewWindow extends React.Component<Props> {
  render() {
    let { file } = this.props;

    return (
      <a
        className="file-viewer-open-file file-action"
        title="Open in new window"
        href={file.get("openable_signed_url")}
        target="_blank"
      >
        <PopoutSvg />
      </a>
    );
  }
}
