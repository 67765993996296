import _ from "lodash";

let StrUtils = {
  // Example: {a: 1, b: 2} => 'a=1,b=2'
  objectToKeyValStr(obj: object) {
    return _.map(_.toPairs(obj), (pair) => {
      return pair.join("=");
    }).join();
  },

  capitalizeEnum(str: string) {
    return _.capitalize(str.toLowerCase().split("_").join(" "));
  },

  uuid() {
    // tslint:disable:no-bitwise
    // https://stackoverflow.com/a/2117523/1451447
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      let r = (Math.random() * 16) | 0;
      let v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    // tslint:enable:no-bitwise
  },

  joinWithSpace(...classNames: string[]) {
    return classNames.filter((cls) => cls).join(" ");
  },

  combineWords(...words: any[]) {
    return words
      .map((w) => _.words(w, /[^ ]+/g))
      .reduce((w1, w2) => w1.concat(w2))
      .join(" ");
  },

  capitalizeWord(s: string) {
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  },
};

export default StrUtils;
