import Axios, { AxiosRequestConfig } from "axios";
// import { onFulfilled, onRejected, reqOnFulfilled, reqOnRejected } from "@pm-assets/js/common/api";
// import { AuthUtils } from "@pm-assets/js/utils/auth-utils";
/**
 * Axios config
 */

const axios = Axios.create({
  headers: {
    "X-CSRFToken": window.PM.csrf_token,
  },
});

// copied from /web/shared/axios
// add interceptors for functional tests
// axios.interceptors.response.use(onFulfilled, onRejected);
// axios.interceptors.request.use(reqOnFulfilled, reqOnRejected);

const apiFetch = async (url: string, config: AxiosRequestConfig = {}) => axios.get(url, config).then((res) => res.data);

const apiDelete = async (url: string, data = undefined) => axios.delete(url, data).then((res) => res.data);

// @ts-expect-error no type
const apiPatch = async (url: string, data) => axios.patch(url, data).then((res) => res.data);

// @ts-expect-error no type
const apiPut = async (url: string, data) => axios.put(url, data).then((res) => res.data);

// @ts-expect-error no type
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const apiPost = async (url: string, data, config = {}) => axios.post(url, data).then((res) => res.data);

export { apiFetch, apiDelete, apiPatch, apiPost, apiPut };
