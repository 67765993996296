import React, { useState } from "react";
import { MdRotateRight, MdInfoOutline, MdClose } from "react-icons/md";
import { SmallLoader } from "@pm-assets/js/loaders";
import { postModifyData } from "@pm-app/views/melds/comment-services";
import { track, ChatModifyClicked } from "@pm-app/utils/analytics";

const buttonStyles = {
  base: {
    padding: "8px 12px",
    margin: "4px 4px 2px 4px",
    cursor: "pointer",
    borderRadius: "20px",
    borderWidth: "1px",
    borderStyle: "solid",
  },
  selected: {
    color: "#1175CC",
    borderColor: "#1175CC",
  },
  default: {
    color: "gray",
    borderColor: "gray",
  },
};

type ButtonValue = "professional" | "friendly" | "urgent" | "short" | "medium" | "long";

interface MessageComponentProps {
  handleTextChange: (event: React.ChangeEvent<HTMLInputElement> | string) => void;
  text: string;
  meld_id: number;
}

const MessageModifyComponent: React.FC<MessageComponentProps> = ({ handleTextChange, text, meld_id }) => {
  const [selectedTone, setSelectedTone] = useState<ButtonValue | "">("");
  const [selectedLength, setSelectedLength] = useState<ButtonValue | "">("");
  const [rewrite, setRewrite] = useState<boolean>(false);
  const [showAssistant, setShowAssistant] = useState<boolean>(false);
  const [previewText, setPreviewText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const resetState = () => {
    setSelectedTone("");
    setSelectedLength("");
    setRewrite(false);
    setShowAssistant(false);
    setPreviewText("");
    setLoading(false);
  };

  const handleClose = () => {
    resetState();
    setShowAssistant(false);
  };

  const generatePreviewText = () => {
    setLoading(true);
    track(ChatModifyClicked());
    postModifyData(text, selectedTone, selectedLength, meld_id)
      .then((response) => {
        setPreviewText(response.generated_text);
        setLoading(false);
      })
      .catch((e) => {
        {
          e.message === "Request failed with status code 429"
            ? setPreviewText("Your organization has used the maximum writing assistant attempts for this Meld.")
            : setPreviewText("Error generating preview. Please reload page and try again.");
        }
        setLoading(false);
      });
  };

  const handleRewriteToggle = () => {
    if (rewrite) {
      handleTextChange(previewText);
      resetState();
      return;
    }

    generatePreviewText();
    setRewrite(true);
  };

  const renderButton = (
    value: ButtonValue,
    selectedValue: ButtonValue | "",
    setDataTest: string,
    onClickHandler: (value: ButtonValue) => void,
    label: string
  ) => (
    <button
      data-test={setDataTest}
      style={{ ...buttonStyles.base, ...(value === selectedValue ? buttonStyles.selected : buttonStyles.default) }}
      onClick={() => onClickHandler(value)}
    >
      {label}
    </button>
  );

  const renderChatRewrite = () => (
    <div style={{ marginTop: "30px" }}>
      <button
        style={{
          ...buttonStyles.base,
          ...buttonStyles.selected,
          padding: "8px 24px",
        }}
        onClick={handleRewriteToggle}
        disabled={loading}
        data-test="rewrite-toggle"
      >
        {loading ? <SmallLoader /> : rewrite ? "Use Text" : "Rewrite"}
      </button>
      {rewrite && (
        <div
          data-test="rewrite-text"
          style={{
            textDecoration: "underline",
            display: "flex",
            marginLeft: "20px",
            marginTop: "5px",
            cursor: "pointer",
          }}
          onClick={generatePreviewText}
        >
          <span style={{ marginTop: "6px" }}>
            <MdRotateRight />
          </span>
          <span>Rewrite</span>
        </div>
      )}
    </div>
  );
  if (!showAssistant) {
    return (
      <div
        data-test="assistant-toggle"
        style={{ cursor: "pointer", display: "flex", alignItems: "center", margin: "10px" }}
        onClick={() => setShowAssistant(true)}
      >
        <MdInfoOutline style={{ marginRight: "10px" }} />
        Need help writing this text?
        <span style={{ textDecoration: "underline", marginLeft: "10px" }}>Open AI Assistant</span>
      </div>
    );
  }

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "20px",
        border: "1px solid lightgray",
        backgroundColor: "transparent",
        borderRadius: "8px",
        margin: "10px 0",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer",
            }}
            onClick={handleClose}
            data-test="close-button"
          >
            <MdClose size={24} />
          </div>
          <div style={{ marginRight: "5px" }}>
            <p data-test="message-tone" style={{ margin: "0 0 8px 0", color: "black" }}>
              Message Tone
            </p>
            <div>
              {renderButton("professional", selectedTone, "tone-professional", setSelectedTone, "Professional")}
              {renderButton("friendly", selectedTone, "tone-friendly", setSelectedTone, "Friendly")}
              {renderButton("urgent", selectedTone, "tone-urgent", setSelectedTone, "Urgent")}
            </div>
          </div>

          <div style={{ marginRight: "5px" }}>
            <p style={{ margin: "0 0 8px 0", color: "black" }}>Message Length</p>
            <div>
              {renderButton("short", selectedLength, "length-short", setSelectedLength, "Short")}
              {renderButton("medium", selectedLength, "length-medium", setSelectedLength, "Medium")}
            </div>
          </div>

          <div>{renderChatRewrite()}</div>

          <div />
        </div>

        {previewText && (
          <div style={{ margin: "20px 0" }}>
            <span>{previewText}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageModifyComponent;
