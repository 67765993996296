let ValidationUtils = {
  validateCC(creditCardData) {
    let errors = [];

    if (!Stripe.card.validateCVC(creditCardData.cvc)) {
      errors.push("CVC is invalid");
    }

    if (!Stripe.card.validateExpiry(creditCardData.expMonth, creditCardData.expYear)) {
      errors.push("Expiration is invalid");
    }

    if (!Stripe.card.validateCardNumber(creditCardData.cardNumber)) {
      errors.push("Card number is invalid");
    }

    return errors;
  }
};

export default ValidationUtils;
