import React from "react";

import { Tag, TagProps } from "@chakra-ui/react";

export type ColorSchemes = "white" | "red" | "yellow" | "green";

interface Props extends TagProps {
  /**
   * Color variant to use.
   * @default general
   */
  colorScheme: ColorSchemes;
}

export const StatusBadge: React.FC<Props> = ({ colorScheme = "white", size = "sm", children, ...props }) => {
  const defaults: Partial<TagProps> = {
    borderRadius: 14,
    children,
    fontSize: "sm",
    paddingY: 1,
    paddingX: 2,
    size,
    ...props
  };

  switch (colorScheme) {
    case "white":
      return <Tag backgroundColor="#EDF2F7" color="#3A5265" {...defaults} />;
    case "yellow":
      return <Tag backgroundColor="#FFEA8A" color="#6C5E08" {...defaults} />;
    case "red":
      return <Tag backgroundColor="#FDC1AF" color="#7F2318" {...defaults} />;
    case "green":
      return <Tag backgroundColor="#C4E5B5" color="#275A2C" {...defaults} />;
    default:
      return null;
  }
};

StatusBadge.displayName = "StatusBadge";
