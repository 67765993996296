import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiPanel, EuiPanelProps } from "@elastic/eui";

import { BorderColor, colors } from "@pm-frontend/styles";

type PmCardProps = {
  panelClassName?: string;
  panelStyles?: EuiPanelProps["style"];
  padding?: string;
  backgroundColor?: string;
  "data-testid"?: string;
  grow?: boolean;
} & (
  | {
      contents: React.ReactNode | React.ReactNodeArray;
      children?: never;
    }
  | {
      contents?: never;
      children: React.ReactNode;
    }
);

/** Similar to an EuiPanel with our styling set
 * https://eui.elastic.co/?search#/display/card
 *
 * if contents is an array then horizontal lines will be inserted between
 * the items
 */
const PmCard = ({
  panelStyles,
  panelClassName,
  padding = "24px",
  backgroundColor = colors.brand.white,
  "data-testid": dataTestId,
  grow = true,
  contents,
  children,
}: PmCardProps) => {
  let cardContents: React.ReactNode;
  if (children) {
    cardContents = children;
  } else if (Array.isArray(contents)) {
    cardContents = (
      <EuiFlexGroup direction="column" style={{ gap: "16px" }}>
        {contents
          .filter((item) => item !== null)
          .map((content, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <EuiFlexItem grow={false}>
                  <EuiHorizontalRule
                    margin="none"
                    style={{
                      borderColor: BorderColor,
                      boxShadow: `0px -1px 0px 0px ${BorderColor} inset`,
                    }}
                  />
                </EuiFlexItem>
              )}
              <EuiFlexItem grow={false}>{content}</EuiFlexItem>
            </React.Fragment>
          ))}
      </EuiFlexGroup>
    );
  } else {
    cardContents = contents;
  }

  return (
    <EuiPanel
      {...(dataTestId ? { "data-testid": dataTestId } : {})}
      style={{
        borderRadius: "8px",
        border: `1px solid ${BorderColor}`,
        background: backgroundColor,
        padding,
        boxShadow: "none",
        ...(panelStyles || {}),
      }}
      className={panelClassName}
      grow={grow}
    >
      {cardContents}
    </EuiPanel>
  );
};

export { PmCard, PmCardProps };
