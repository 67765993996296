import React from "react";

export default class DetailLabel extends React.Component {
  render() {
    return (
      <div className="label-container">
        <label className="detail-label">{this.props.children}</label>
      </div>
    );
  }
}
