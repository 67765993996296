import classnames from "classnames";
import Link from "../../../../web/shared/utils/link";
import React from "react";
import { parseQuery } from "../utils/location-utils";
import { withRouter } from "react-router-dom";
import { AuthUtils } from "../../utils/auth-utils";
import { track, VendorSortByOpened } from "../../../../app/utils/analytics";

class SortField extends React.Component {
  constructor(props) {
    super(props);
    this.handleItemClicked = this.handleItemClicked.bind(this);
  }

  render() {
    let query = parseQuery(this.props.location);
    let { fieldName } = this.props;
    query.ordering = fieldName;

    let linkClasses = classnames({
      action: true,
      active: this.props.ordering === this.props.fieldName,
    });

    return (
      <li className="menu-item" onClick={this.handleItemClicked} data-test="action_dropdown.sorter.item.action.sort">
        <Link className={linkClasses} to={this.props.location.pathname} query={query} normalize={false}>
          {this.props.children}
        </Link>
      </li>
    );
  }

  handleItemClicked(e) {
    if (AuthUtils.getActiveOrgType() === "v" && this.props.pageName) {
      track(
        VendorSortByOpened({
          pageName: this.props.pageName,
        })
      );
    }
    if (this.props.onSortFieldClicked) {
      this.props.onSortFieldClicked(this.props.fieldName, e);
    }
  }
}

export { SortField as UnwrappedSortField };

export default withRouter(SortField);
