var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
export default function withFileRefresher(Comp, fetchFn) {
  return class WithFileRefresher extends React.Component {
    render() {
      return /* @__PURE__ */ React.createElement(Comp, __spreadValues({}, this.props));
    }
    componentWillMount() {
      this.fetchInterval = setInterval(() => fetchFn(this.props), 1e3 * 60 * 60 * 8);
    }
    componentWillUnmount() {
      clearInterval(this.fetchInterval);
    }
  };
}
