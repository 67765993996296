import moment, { Moment, unitOfTime } from "moment";
import * as C from "../constants";

function momentDatePart(datePart: unitOfTime.All) {
  return (mom: Moment) => {
    if (mom && mom.get(datePart)) {
      return mom.get(datePart);
    } else if (mom) {
      return moment(mom).get(datePart);
    }
  };
}

interface Format {
  separator?: string;
  dayMonthFmt?: string;
  timeFmt?: string;
}

/**
 * @deprecated Use app/utils/DateUtils instead
 */
let CalendarUtils = {
  renderFormattedDay(date: string, format: string = "MMMM Do"): string | undefined {
    if (date) {
      return moment(date).format(format);
    }
  },

  renderFormattedDayMonthYear(date: string | Date, format: string = "MMMM Do, YYYY"): string | undefined {
    if (date) {
      return moment(date).format(format);
    }
  },

  renderFormattedTime(date: string): string {
    return moment(date)
      .format(C.TIME_FORMAT)
      .toUpperCase();
  },

  formatDayMonthTime(date: string, { separator = ", at ", dayMonthFmt, timeFmt }: Format = {}): string {
    return `${this.renderFormattedDay(date, dayMonthFmt)}${separator}${this.renderFormattedTime(date, timeFmt)}`;
  },

  dateComparator(d1: Date, d2: Date): number {
    return d2.getTime() - d1.getTime();
  },

  getDaysUntil(date: Date): number {
    return moment(date).diff(moment(), "days");
  },

  parseOrGet(dt: string | Date) {
    if (!dt) {
      return dt;
    }
    if (!(dt instanceof Date)) {
      return moment(dt).toDate();
    }
    return dt;
  },

  isValidDate(d: any) {
    if (Object.prototype.toString.call(d) !== "[object Date]") {
      return false;
    }

    return !isNaN(d.getTime());
  },

  roundUpMoment(mom: Moment, minutes: number = 15): Moment {
    /* Rounds up to the next minute interval. Eg. if it's 10:34, then we round up to 10:45. */
    let toRound = (mom || moment()).startOf("minute");
    return toRound.add(minutes - (toRound.minutes() % minutes), "minutes");
  },

  roundDownMoment(mom: Moment, minutes: number = 15): Moment {
    /* Rounds up to the next minute interval. Eg. if it's 10:34, then we round up to 10:30. */
    let toRound = (mom || moment()).startOf("minute");
    return toRound.subtract(toRound.minutes() % minutes, "minutes");
  },

  roundNearestMoment(mom: Moment, minutes: number = 15): Moment {
    /* Rounds to the next minute interval. Eg. if it's 10:34, then we round up to 10:30.
            if it's 10:40, then we round up to 10:45.
        */
    let m = mom || moment();
    let offset = m.minutes() % minutes;
    if (offset < minutes / 2) {
      return CalendarUtils.roundDownMoment(m, minutes);
    } else {
      return CalendarUtils.roundUpMoment(m, minutes);
    }
  },

  computeMinutesSince(fromDate: Date, toDate: Date): number {
    let from = moment(fromDate);
    let to = toDate ? moment(toDate) : moment();

    return to.diff(from, "minutes");
  },

  computeSecondsSince(fromDate: Date | string, toDate: Date | string): number {
    let from = moment(fromDate);
    let to = toDate ? moment(toDate) : moment();

    return to.diff(from, "seconds");
  },

  dateFromTime(time: string | undefined | null | number, defalt = null) {
    if (time) {
      let date = new Date();
      date.setTime(Number(time));
      return date;
    }

    return defalt;
  },

  oneYearAgo(): Date {
    return moment()
      .subtract(1, "year")
      .toDate();
  },

  isToday(mom: Moment): boolean {
    return mom.isSame(moment(), "day");
  },

  isBeforeToday(mom: Moment | string): boolean {
    let comparisonDate = mom
    if (typeof mom === "string") {
      comparisonDate = moment(mom)
    }
    return moment()
      .startOf("day")
      .isAfter(comparisonDate);
  },

  isBeforeNow(dateString: string): boolean {
    return moment().isAfter(moment(dateString));
  },

  year: momentDatePart("year"),
  month: momentDatePart("month"),
  day: momentDatePart("day")
};

export default CalendarUtils;
