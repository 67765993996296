import moment from "moment";
import React from "react";

interface Props {
  numDays: number;
}

export default function withCalendarControls(
  Comp: React.ComponentClass<any>,
  { getStartDayFn }: { getStartDayFn?: (props: Props) => moment.Moment } = {}
) {
  interface State {
    startDay: moment.Moment;
  }

  return class WithCalendarControlsComponent extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = {
        startDay: getStartDayFn ? getStartDayFn(props) : moment().startOf("day")
      };

      this.handleTodayClicked = this.handleTodayClicked.bind(this);
      this.handleNavPrev = this.handleNavPrev.bind(this);
      this.handleNavNext = this.handleNavNext.bind(this);
    }

    render() {
      return (
        <Comp
          startDay={this.state.startDay}
          onTodayClicked={this.handleTodayClicked}
          onNavPrev={this.handleNavPrev}
          onNavNext={this.handleNavNext}
          {...this.props}
        />
      );
    }

    handleTodayClicked() {
      this.setState({ startDay: moment().startOf("day") });
    }

    handleNavPrev() {
      this.setState({
        startDay: this.state.startDay.clone().subtract(this.props.numDays, "days")
      });
    }

    handleNavNext() {
      this.setState({
        startDay: this.state.startDay.clone().add(this.props.numDays, "days")
      });
    }
  };
}
