import React, { Component } from "react";

let SetIntervalMixin = {
  componentWillMount() {
    this.intervals = [];
  },

  setInterval() {
    this.intervals.push(window.setInterval.apply(null, arguments));
  },

  componentWillUnmount() {
    this.intervals.map(clearInterval);
  },

  callAndSetInterval(func, delay, ...args) {
    func(...args);
    this.setInterval(func, delay, ...args);
  }
};

export default SetIntervalMixin;

export function withSetInterval(Comp) {
  return class WithSetInterval extends Component {
    constructor(options) {
      super(options);
      this.intervals = [];
      this.setInterval = this.setInterval.bind(this);
    }

    render() {
      return <Comp {...this.props} setInterval={this.setInterval} />;
    }

    setInterval(...args) {
      this.intervals.push(window.setInterval.apply(null, ...args));
    }

    componentWillUnmount() {
      this.intervals.map(clearInterval);
    }
  };
}
