import classNames from "classnames";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";
import React from "react";

let StepTracker = createReactClass({
  propTypes: {
    isANextStep: PropTypes.bool.isRequired,
    isFinished: PropTypes.bool.isRequired,
    isCurrentStep: PropTypes.bool.isRequired
  },

  render() {
    let stepClasses = classNames({
      "step-marker": true,
      "finished-step": this.props.isFinished,
      "current-step": this.props.isCurrentStep
    });

    let stepCheckClasses = classNames({
      icon: true,
      "step-check": this.props.isFinished
    });

    let separator = this.props.isANextStep ? <span className="step-sep icon" /> : "";

    return (
      <span>
        <span className={stepClasses}>
          <span className={stepCheckClasses} />
        </span>
        {separator}
      </span>
    );
  }
});

export default StepTracker;
