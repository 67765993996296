export function getTranslatedUIPrompt(input: string, language: string): string {
  const translations = new Map<string, { toEnglish: string; toNative: string; cancel: string }>([
    ["ES", { toEnglish: "traducir al inglés", toNative: "traducir al español", cancel: "cancelar" }],
    ["ZH", { toEnglish: "翻译成英文", toNative: "翻译成中文", cancel: "取消" }],
    ["KO", { toEnglish: "영어로 번역", toNative: "한국어로 번역", cancel: "취소" }],
    ["PT", { toEnglish: "traduzir para o inglês", toNative: "traduzir para o português", cancel: "cancelar" }],
    ["FR", { toEnglish: "traduire en anglais", toNative: "traduire en français", cancel: "annuler" }],
    ["RU", { toEnglish: "перевести на английский", toNative: "перевести на русский", cancel: "отмена" }],
    ["JA", { toEnglish: "英語に翻訳", toNative: "日本語に翻訳", cancel: "キャンセル" }],
  ]);

  const translation = translations.get(language);
  if (!translation) {
    return "Translate";
  }

  switch (input) {
    case "translate to english":
      return translation.toEnglish;
    case "cancel":
      return translation.cancel;
    default:
      return translation.toNative;
  }
}
