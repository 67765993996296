import createReactClass from "create-react-class";
import React from "react";

/* tslint:disable:max-line-length */
export default createReactClass<{ classes?: string }, {}>({
  render() {
    return (
      <svg
        className={this.props.classes}
        xmlns="https://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 25 24"
      >
        <path
          d="M2.47 17.28L15.137 4.637 19.36 8.85 6.693 21.496 2.47 17.28zM19.36.422c.584-.582 1.53-.582 2.112 0l2.112 2.107c.583.58.583 1.525 0 2.107l-3.168 3.16-4.223-4.214L19.36.423zM0 23.952l1.405-5.62 4.223 4.216-5.63 1.403z"
          id="path-1"
          fillRule="evenodd"
        />
      </svg>
    );
  },
});
/* tslint:enable:max-line-length */
