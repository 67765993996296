import React, { forwardRef, ReactNode, useEffect } from "react";
import classnames from "classnames";
import Div100vh from "react-div-100vh";

import { Close } from "../icons";

// <ModalBody />

export const ModalBody: React.FC = ({ children }) => <div className="pm-modal-body">{children}</div>;

ModalBody.displayName = "ModalBody";

// <RefModalBody />
export const RefModalBody = forwardRef<HTMLDivElement, { children: ReactNode }>(({ children }, ref) => (
  <div className="pm-modal-body" ref={ref}>
    {children}
  </div>
));
RefModalBody.displayName = "RefModalBody";

// <ModalFooter />

export const ModalFooter: React.FC = ({ children }) => <div className="pm-modal-footer">{children}</div>;

ModalFooter.displayName = "ModalFooter";

// <Modal />

export enum Size {
  Small = "small",
  Medium = "medium",
}

interface Props {
  /**
   * Optional class(es) to add to outermost modal element.
   */
  className?: string;
  /**
   * Whether or not the modal contains a scrollable element.
   * If true, the modal will enforce a max height less than the browser viewport.
   * @default false
   */
  hasInnerScroll?: boolean;
  /**
   * Header text.
   */
  header?: string;
  /**
   * @default Small
   */
  size?: Size;
  /**
   * Whether or not the modal is open.
   * @default false
   */
  isOpen?: boolean;
  /**
   * Function that gets called when isOpen gets set to false.
   */
  onClose?: () => void;
}

export const Modal: React.FC<Props> = ({
  className,
  hasInnerScroll,
  header,
  size = Size.Small,
  isOpen = false,
  onClose,
  children,
}) => {
  useEffect(() => {
    window.document.addEventListener("keyup", handleKeyUp);

    if (hasInnerScroll) {
      window.document.body.classList.toggle("pm-modal-open", isOpen);
    }

    return () => {
      window.document.removeEventListener("keyup", handleKeyUp);

      if (hasInnerScroll) {
        window.document.body.classList.remove("pm-modal-open");
      }
    };
  }, []);

  const handleKeyUp = (e: KeyboardEvent) => {
    if (isOpen && e.key === "Escape") {
      e.stopPropagation();

      close();
    }
  };

  const close = () => {
    if (onClose) {
      onClose();
    }
  };

  if (isOpen) {
    return (
      <>
        <div className="pm-modal-overlay" />

        <Div100vh
          className={classnames("pm-modal", `pm-modal-${size}`, className, { "pm-has-inner-scroll": hasInnerScroll })}
          style={{ height: hasInnerScroll ? "calc(100rvh - 32px)" : "auto" }}
        >
          <div className="pm-modal-header">
            <h1>{header}</h1>

            <button className="pm-modal-close" onClick={close}>
              <Close />
            </button>
          </div>

          {children}
        </Div100vh>
      </>
    );
  }

  return null;
};

Modal.displayName = "Modal";

export default Modal;
