import alt from "../../alt";
import ManagementAppointmentApi from "../apis/management-appointment-api";
import messageActions from "../../common/actions/message-actions";

class ManagementAppointmentActions {
  constructor() {
    this.generateActions(
      "fetchOne",
      "fetchOneDone",
      "fetchOneFail",
      "scheduleDone",
      "scheduleFail",
      "requestMoreAvailabilityDone",
      "requestMoreAvailabilityFail"
    );
  }

  schedule(id, data, { cid, options } = {}) {
    ManagementAppointmentApi.schedule(id, data, options)
      .then(res => {
        this.scheduleDone({ data: res.data, cid });
      })
      .catch(res => {
        if (res.messages.length) {
          messageActions.storeError({ body: res.messages[0] });
        }
        this.scheduleFail({ cid });
      });
    return { id, data, cid };
  }

  requestMoreAvailability(id, { cid, options } = {}) {
    ManagementAppointmentApi.requestMoreAvailability(id, options)
      .then(res => {
        this.requestMoreAvailabilityDone({ data: res.data, cid });
      })
      .catch(res => {
        this.requestMoreAvailabilityFail({ data: res.data, cid });
      });

    return { cid };
  }
}

export default alt.createActions(ManagementAppointmentActions);
