var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { BaseIcon } from "../base-icon";
export const OutboundLink = (props) => /* @__PURE__ */ React.createElement(BaseIcon, __spreadValues({}, props), /* @__PURE__ */ React.createElement("path", { d: "M39 16.25a1 1 0 01-1-1V3a1 1 0 00-1-1H24.75a1 1 0 010-2H37a3 3 0 013 3v12.25a1 1 0 01-1 1zM33 40H3a3 3 0 01-3-3V7a3 3 0 013-3h14.75a1 1 0 010 2H3a1 1 0 00-1 1v30a1 1 0 001 1h30a1 1 0 001-1V22.25a1 1 0 012 0V37a3 3 0 01-3 3z" }), /* @__PURE__ */ React.createElement("path", { d: "M11.87 29.13a1 1 0 01-.71-.29 1 1 0 010-1.42L37.71.88a1 1 0 011.41 1.41L12.58 28.84a1 1 0 01-.71.29z" }));
