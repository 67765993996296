import React from "react";

export class Pillbox extends React.PureComponent<{ style?: React.CSSProperties }> {
  render() {
    return (
      <ul className="pillbox" style={this.props.style}>
        {this.props.children}
      </ul>
    );
  }
}

export default Pillbox;
