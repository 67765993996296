import ContactSettingsActions from "../actions/contact-settings-actions";
import ContactSettingsApi from "../apis/contact-settings-api";

let ContactSettingsSource = {
  fetchCurrent() {
    return {
      remote(state, { cid } = {}) {
        return ContactSettingsApi.fetchCurrent();
      },

      shouldFetch(state, force) {
        return true;
      },

      success: ContactSettingsActions.fetchCurrentDone,
      error: ContactSettingsActions.fetchCurrentFail
    };
  }
};

export default ContactSettingsSource;
