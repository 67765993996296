import _ from "lodash";
import AvailabilityUtils from "../../../availability/utils/availability-utils";
import CalendarControls from "../../../availability/components/calendar-controls";
import DetailBody from "../../../common/components/detail-body";
import DetailFooter from "../../../common/components/detail-footer";
import DetailHeader from "../../../common/components/detail-header";
import DetailView from "../../../common/components/detail-view";
import DocumentTitle from "react-document-title";
import HistoryUtils from "../../../common/utils/history-utils";
import moment from "moment";
import React from "react";
import Scheduled from "../../../svg/scheduled-svg";
import SchedulerMeldDetails from "./scheduler-meld-details";
import TimeSlotCheckbox from "../../../availability/components/time-slot-checkbox";
import TimeSlotHeader from "../../../availability/components/time-slot-header";
import withCalendarControls from "../../../availability/components/with-calendar-controls";
import withOptimalNumDays from "../../../availability/components/with-optimal-num-days";
import withScheduleAppointmentRequest from "./with-schedule-appointment-request";
import { ErrorList } from "../../../common/components/errors";
import { withRouter } from "react-router-dom";
import CancelSaveButtons from "../../../common/components/cancel-save-buttons";
import Features from "@pm-assets/js/common/feature-flags";

const HOUR_INCREMENTS = 2;

function getStartDay(props) {
  let avail = props.appointmentRequest
    .get("availabilities")
    .sort(AvailabilityUtils.dtstartDifference)
    .find(AvailabilityUtils.isAvailabilityScheduleable);
  return moment.max(moment(), AvailabilityUtils.getDTStartMoment(avail));
}

class ScheduleFromRequestPicker extends React.Component {
  constructor(props) {
    super(props);

    this.handleTimeSlotClicked = this.handleTimeSlotClicked.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.state = {
      data: this.props.appointment.get("availability_segment"),
      selectedAvailabilities: [],
    };
  }

  render() {
    let { meld, appointment, loading, startDay, numDays } = this.props;

    return (
      <DocumentTitle title={`Instant Schedule - ${this.props.meld.get("brief_description")}`}>
        <div className="request-appointment-picker">
          <DetailView mediumClass={12} largeClass={10}>
            <DetailHeader>
              <h2 className="detail-title">Schedule</h2>
            </DetailHeader>
            <DetailBody>
              <SchedulerMeldDetails meld={meld} appointment={appointment} unit={meld.get("unit")} />

              <hr className="default-separator" />

              <div className="detail-section-title">
                <Scheduled />
                Instant Schedule
              </div>
              <p className="copy">
                {Features.isMultipleAppointmentsEnabled()
                  ? "These times were requested by the tenant. Select one or more to schedule appointments."
                  : "These times were requested by the tenant, select one to schedule an appointment."}
                None of these times work for you? <a onClick={this.props.onOptOut}>Select your availability.</a>
              </p>
              <div className="row">
                <div className="columns">
                  <div className="calendar-controls">
                    <CalendarControls
                      numDays={numDays}
                      startDay={startDay}
                      onTodayClicked={this.props.onTodayClicked}
                      navPrev={this.props.onNavPrev}
                      navNext={this.props.onNavNext}
                      showDurationSelect={false}
                    />
                  </div>
                </div>
              </div>

              <div className="row time-slots-row">
                <div className="columns">
                  {_.range(numDays).map((day) => {
                    const dt = startDay.clone().add(day, "days");

                    const offset =
                      this.calcPossibleSlots(day, 0).filter((start) => {
                        const end = start.clone().add(HOUR_INCREMENTS, "hours");
                        let avail = this._findSegment(start, end);
                        return this.isSegmentAvailable(avail);
                      }).length > 0
                        ? 0
                        : 1;

                    return (
                      <div className="time-slot-col" key={dt}>
                        <TimeSlotHeader mom={dt} />
                        {this.calcPossibleSlots(day, offset).map((start) => {
                          const end = start.clone().add(HOUR_INCREMENTS, "hours");
                          let avail = this._findSegment(start, end);
                          const unavailable = !this.isSegmentAvailable(avail);
                          return (
                            <div key={start}>
                              <TimeSlotCheckbox
                                start={start}
                                end={end}
                                unavailable={unavailable}
                                selected={this.isScheduled(avail)}
                                onClick={this.handleTimeSlotClicked.bind(this, avail)}
                              />
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
                  <p>
                    None of these times work for you? <a onClick={this.props.onOptOut}>Select your availability.</a>
                  </p>
                </div>
              </div>

              <ErrorList errors={this.props.errors} />
              <DetailFooter>
                <CancelSaveButtons
                  onCancelFn={this.handleCancel}
                  onSaveFn={this.handleSave}
                  cancelDisabled={loading}
                  saveDisabled={
                    loading ||
                    (Features.isMultipleAppointmentsEnabled()
                      ? this.state.selectedAvailabilities.length === 0
                      : !this.state.data)
                  }
                  cancelText="Not Now"
                  saveText={loading ? "Saving..." : "Schedule"}
                />
              </DetailFooter>
            </DetailBody>
          </DetailView>
        </div>
      </DocumentTitle>
    );
  }

  calcPossibleSlots(day, offset) {
    return _.range(6).map((i) => {
      return this.props.startDay
        .clone()
        .startOf("day")
        .add(day, "days")
        .add(offset + 8 + i * 2, "hours");
    });
  }

  handleCancel() {
    let meld = this.props.meld;
    HistoryUtils.push(this.props.history, `/meld/${meld.get("id")}/summary/`);
  }

  handleTimeSlotClicked(availability) {
    if (Features.isMultipleAppointmentsEnabled()) {
      this.setState((prevState) => {
        const availabilityId = availability.get("id");
        const isSelected = prevState.selectedAvailabilities.includes(availabilityId);

        if (isSelected) {
          return {
            selectedAvailabilities: prevState.selectedAvailabilities.filter((id) => id !== availabilityId),
          };
        } else {
          return {
            selectedAvailabilities: [...prevState.selectedAvailabilities, availabilityId],
          };
        }
      });
    } else {
      this.setState({ data: availability });
    }
  }

  handleSave(e) {
    e.preventDefault();
    if (Features.isMultipleAppointmentsEnabled()) {
      this.props.onSchedule(this.state.selectedAvailabilities);
    } else {
      this.props.onSchedule(this.state.data.get("id"));
    }
  }

  isSegmentAvailable(availability) {
    return availability && AvailabilityUtils.isAvailabilityScheduleable(availability);
  }

  isScheduled(availability) {
    if (!availability) {
      return false;
    }
    if (Features.isMultipleAppointmentsEnabled()) {
      return this.state.selectedAvailabilities.includes(availability.get("id"));
    } else {
      if (this.state.data) {
        const apptDTStart = AvailabilityUtils.getDTStartMoment(this.state.data);
        const apptDTEnd = AvailabilityUtils.getDTEndMoment(this.state.data);
        const requestedApptDTStart = AvailabilityUtils.getDTStartMoment(availability);
        const requestedApptDTEnd = AvailabilityUtils.getDTEndMoment(availability);
        return apptDTStart.isSame(requestedApptDTStart) && apptDTEnd.isSame(requestedApptDTEnd);
      }
      return false;
    }
  }

  _findSegment(startMoment, endMoment) {
    return this.props.appointmentRequest.get("availabilities").find((a) => {
      return (
        AvailabilityUtils.getDTStartMoment(a).isSame(startMoment) &&
        AvailabilityUtils.getDTEndMoment(a).isSame(endMoment)
      );
    });
  }
}

function numDaysToDisplay() {
  if (window.innerWidth < 615) {
    return 1;
  }
  if (window.innerWidth < 700) {
    return 2;
  }
  if (window.innerWidth < 1300) {
    return 3;
  }
  if (window.innerWidth < 1500) {
    return 4;
  }
  return 5;
}

ScheduleFromRequestPicker = withCalendarControls(ScheduleFromRequestPicker, {
  getStartDayFn: getStartDay,
});
ScheduleFromRequestPicker = withOptimalNumDays(ScheduleFromRequestPicker, {
  optimalDaysFn: numDaysToDisplay,
});
ScheduleFromRequestPicker = withScheduleAppointmentRequest(ScheduleFromRequestPicker);
export default withRouter(ScheduleFromRequestPicker);
