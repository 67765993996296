import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiInMemoryTable, EuiInMemoryTableProps, EuiText } from "@elastic/eui";
import { css } from "@emotion/react";

import URL from "@pm-shared/utils/url";
import { colors } from "@pm-frontend/styles";

const tableCss = css`
  & {
    thead {
      display: none;
    }
    tbody {
      tr {
        td {
          .euiTableCellContent {
            padding: 6px 0px;
          }
        }
      }
    }
  }
`;
const removeSpacerCss = css`
  & {
    div {
      div.euiSpacer {
        max-height: 8px;
      }
    }
  }
`;

const renderOption = (option: AccountSelectorProps["options"][number]) => {
  if (option.currentlySelected) {
    return (
      <EuiFlexItem grow={false} key={option.id}>
        <EuiFlexGroup direction="row" responsive={false} alignItems="center" style={{ gap: "8px" }}>
          <EuiFlexItem grow={false} style={{ width: "16px" }}>
            <EuiIcon type={URL.getStatic("icons/heading-menu-check.svg")} size="original" />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText color={colors.neutrals.gray800} size="s" style={{ fontWeight: 600 }}>
              {option.text}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    );
  }

  return (
    <a href={option.href} key={option.id}>
      <EuiFlexItem grow={false} style={{ paddingLeft: "24px" }}>
        <EuiText color={colors.neutrals.gray800} size="s">
          {option.text}
        </EuiText>
      </EuiFlexItem>
    </a>
  );
};

interface AccountOptions {
  href: string;
  id: number;
  text: string;
  currentlySelected: boolean;
}

interface AccountSelectorProps {
  isLoading: boolean;
  options: AccountOptions[];
}

/**
 * This is a generic account selector component used by different apps
 */
const AccountSelector = ({ options, isLoading }: AccountSelectorProps) => {
  let accountSelectorBody: React.ReactNode = null;

  const columns: EuiInMemoryTableProps<AccountSelectorProps["options"][number]>["columns"] = [
    {
      field: "name",
      name: "Account",
      render: (_, account) => renderOption(account),
    },
  ];

  if (options.length <= 1) {
    return null;
  } else if (options.length <= 5) {
    // if managements is five or less just show the five managements
    accountSelectorBody = (
      <EuiFlexGroup direction="column" style={{ gap: "12px" }}>
        {options.map((option) => renderOption(option))}
      </EuiFlexGroup>
    );
  } else {
    // for agents with more than five managements
    accountSelectorBody = (
      <EuiFlexItem grow={false} css={removeSpacerCss}>
        <EuiInMemoryTable
          items={options}
          columns={columns}
          search={{
            box: {
              placeholder: "Search for an account",
              incremental: true,
              compressed: true,
            },
          }}
          compressed={true}
          loading={isLoading}
          rowHeader="none"
          css={tableCss}
          responsive={false}
          rowProps={{ style: { background: colors.brand.white } }}
          cellProps={{ style: { border: "none", flexGrow: 0 } }}
        />
      </EuiFlexItem>
    );
  }

  return (
    <>
      <EuiFlexItem grow={false} className="eui-yScroll">
        <EuiFlexGroup direction="column" alignItems="flexStart" style={{ gap: "12px" }}>
          <EuiFlexItem grow={false}>
            <EuiText size="s" color={colors.neutrals.gray900} style={{ fontWeight: 700 }}>
              Select Segment
            </EuiText>
          </EuiFlexItem>
          {accountSelectorBody && <EuiFlexItem grow={false}>{accountSelectorBody}</EuiFlexItem>}
        </EuiFlexGroup>
      </EuiFlexItem>
    </>
  );
};

export { AccountSelector, AccountSelectorProps };
