import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import StrUtils from "../../utils/str-utils";

export interface CardProps {
  sizeClassName: string;
  isSelected?: boolean;
}

class Card extends React.Component<CardProps> {
  static propTypes = {
    sizeClassName: PropTypes.string.isRequired,
    isSelected: PropTypes.bool
  };

  static defaultProps = {
    sizeClassName: "",
    isSelected: false
  };

  render() {
    const containerClasses = StrUtils.joinWithSpace(this.props.sizeClassName, "card-container");
    const cardClasses = classNames({
      card: true,
      "card-selected": this.props.isSelected
    });

    return (
      <div className={containerClasses}>
        <div className={cardClasses}>{this.props.children}</div>
      </div>
    );
  }
}

export default Card;
