import ApiUrls from "../../utils/api-urls";
import BaseApi from "../../utils/base-api";

class ManagementAppointmentAPI extends BaseApi {
  static fetchOne(id: number, options = {}) {
    return this.get(ApiUrls.managementAppointmentDetail(id), options);
  }

  static schedule(id: number, data: any, options = {}) {
    return this.patch(ApiUrls.managementAppointmentSchedule(id), data, options);
  }

  static requestMoreAvailability(id: number, data: any, options = {}) {
    return this.patch(ApiUrls.managementAppointmentRequestMoreAvailability(id), data, options);
  }
}

export default ManagementAppointmentAPI;
