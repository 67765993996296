import _ from "lodash";
import ActionDropdown from "../../action-dropdown";
import Card from "../../common/components/card";
import classNames from "classnames";
import DocumentSvg from "../../svg/document-svg";
import I from "immutable";
import moment from "moment";
import styled from "@emotion/styled";
import OwnerUtils from "../../property/utils/owner-utils";
import PureCheckbox from "../../common/components/pure-checkbox";
import React, { FC, useState } from "react";
import TenantUtils from "../../tenant/utils/tenant-utils";
import truncate from "truncate";
import { ImmutableMap } from "../../types/common";
import FileUtils from "../utils/file-utils";
import DeleteFilesModal from "@pm-app/components/modal/delete-files-modal";

const FileContents = styled.div`
  overflow: auto;
`;

enum FileModes {
  DEFAULT = "DEFAULT",
  DELETE = "DELETE",
}

export interface FileProps {
  file: ImmutableMap;
  deleteFile?: (file: I.Map<string, any>, cid: string) => void;
  setExpiration?: () => void;
  isDownloadEnabled?: boolean;
  isDropdownEnabled?: boolean;
  shareFile?: (file: I.Map<string, any>) => void;
  selectable?: boolean;
  isSelected?: (file: I.Map<string, any>) => boolean;
  onSelectionToggled?: (file: I.Map<string, any>) => void;
  onThumbnailClicked: (file: ImmutableMap) => void;
}

const File: FC<FileProps> = ({
  file,
  deleteFile,
  shareFile,
  selectable,
  setExpiration,
  isDownloadEnabled = true,
  isDropdownEnabled,
  children,
  onSelectionToggled,
  onThumbnailClicked,
  isSelected: _isSelected,
}) => {
  const [cid] = useState(_.uniqueId("File_"));
  const [mode, setMode] = useState(FileModes.DEFAULT);

  const classes = classNames({
    "header-col": true,
    "card-selector": true,
    hidden: !selectable,
  });

  const isSelected = () => (_isSelected ? _isSelected(file) : false);

  const resetMode = () => setMode(FileModes.DEFAULT);

  const renderMode = () => {
    if (mode === FileModes.DELETE) {
      return (
        <DeleteFilesModal
          files={[file.toJS()]}
          isOpen={true}
          onDelete={() => deleteFile && deleteFile(file, cid)}
          onClose={resetMode}
        />
      );
    }
  };

  const renderActionDropdown = () => {
    let actions = [];

    if (isDownloadEnabled) {
      actions.push(
        <li className="menu-item download-file" key="download-file">
          <a className="action" href={file.get("signed_url")} onClick={() => window.open(file.get("signed_url"))}>
            Download
          </a>
        </li>
      );
    }

    if (shareFile) {
      actions.push(
        <li className="menu-item share-item" key="share-file">
          <span className="action" onClick={() => shareFile && shareFile(file)}>
            Share
          </span>
        </li>
      );
    }

    if (deleteFile) {
      actions.push(
        <li className="menu-item delete-file" key="delete-file">
          <span className="action" onClick={() => setMode(FileModes.DELETE)}>
            Delete
          </span>
        </li>
      );
    }

    if (actions.length > 0) {
      return <ActionDropdown enabled={isDropdownEnabled}>{actions}</ActionDropdown>;
    }
  };

  const renderExpiration = () => {
    if (file.has("expiration_date")) {
      let expirationDate = file.get("expiration_date");
      if (expirationDate) {
        if (moment() < moment(expirationDate)) {
          return (
            <div className="card-attributes last final-section">
              <span className="card-attribute card-accent">
                <a className="expiration-link" onClick={setExpiration}>
                  Expires {moment(expirationDate).format("MMMM D, YYYY")}
                </a>
              </span>
            </div>
          );
        } else {
          return (
            <div className="card-attributes last final-section">
              <span className="card-attribute card-accent">
                <a className="expiration-link danger" onClick={setExpiration}>
                  Expired {moment(expirationDate).format("MMMM D, YYYY")}
                </a>
              </span>
            </div>
          );
        }
      } else {
        return (
          <div className="card-attributes last final-section">
            <span className="card-attribute card-accent">
              <a className="expiration-link" onClick={setExpiration}>
                Set an expiration date
              </a>
            </span>
          </div>
        );
      }
    }
  };

  const renderShared = () => {
    let owners = file.get("owners_shared_with") || I.List();
    let vendors = file.get("vendors_shared_with") || I.List();
    let tenants = file.get("tenants_shared_with") || I.List();

    let ownerClasses = classNames({
      "card-attributes": true,
      last: vendors.isEmpty() && tenants.isEmpty(),
      "final-section": vendors.isEmpty() && tenants.isEmpty(),
    });

    let vendorClasses = classNames({
      "card-attributes": true,
      last: tenants.isEmpty(),
      "final-section": tenants.isEmpty(),
    });

    return (
      <div>
        {!owners.isEmpty() && (
          <div className={ownerClasses}>
            <span className="card-attribute card-accept" title="shared-with-owners">
              Shared with owners: {owners.map(OwnerUtils.getName).join(", ")}
            </span>
          </div>
        )}
        {!vendors.isEmpty() && (
          <div className={vendorClasses}>
            <span className="card-attribute card-accept" title="shared-with-vendors">
              Shared with vendors: {vendors.map((vendor: I.Map<string, any>) => vendor.get("name")).join(", ")}
            </span>
          </div>
        )}
        {!tenants.isEmpty() && (
          <div className="card-attributes last final-section">
            <span className="card-attribute card-accept" title="shared-with-tenants">
              Shared with tenants:{" "}
              {tenants.map((tenant: I.Map<string, any>) => TenantUtils.getFullName(tenant)).join(", ")}
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <Card sizeClassName="small-12 medium-6 large-4 file-container" isSelected={isSelected()}>
      <div className="file-header">
        <PureCheckbox
          type="checkbox"
          htmlFor={`owner-${file.get("filename")}`}
          className={classes}
          checked={isSelected()}
          onClick={(e) => {
            e.preventDefault();
            if (onSelectionToggled) {
              onSelectionToggled(file);
            }
          }}
        />
        <span className="file-name" title={file.get("filename")}>
          {file.get("filename")}
        </span>
        <span className="header-col">{renderActionDropdown()}</span>
      </div>
      <FileContents>
        <div className="file-thumbnail" onClick={() => onThumbnailClicked(file)}>
          {FileUtils.isThumbnailAvailable(file) && <img src={file.get("thumbnail")} />}
          {!FileUtils.isThumbnailAvailable(file) && <DocumentSvg />}
        </div>
        {renderExpiration()}
        {renderShared()}
        {renderMode()}
        {children}
      </FileContents>
    </Card>
  );
};

export default File;

export class DeleteOnlyFile extends React.Component<{
  fileName: string;
  onDelete: () => void;
}> {
  render() {
    return (
      <Card sizeClassName="small-12 medium-6 large-4">
        <div className="card-header small-card-header badged-header">
          <span className="header-col">
            <div className="file" title={this.props.fileName}>
              <DocumentSvg classes="file-ico" />
              <span className="file-name">{truncate(this.props.fileName, 40)}</span>
            </div>
          </span>
          <span className="header-col">
            <ActionDropdown enabled={true}>
              <li className="menu-item delete-file">
                <span className="action" onClick={this.props.onDelete}>
                  Delete
                </span>
              </li>
            </ActionDropdown>
          </span>
        </div>
      </Card>
    );
  }
}

export function Files(props: { children: any }) {
  return <ul className="files">{props.children}</ul>;
}
