var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLink } from "@elastic/eui";
import URL from "@pm-shared/utils/url";
import { Link } from "react-router-dom";
import { LinkHelper } from "@pm-frontend/shared/utils/api-helpers";
import { PageHeader } from "@pm-frontend/shared/components/PageHeader/PageHeader";
const DesktopDetailViewsPageHeader = ({
  subtitle,
  title,
  icon,
  primaryAction,
  secondaryActions,
  prependBadgeRow,
  badges,
  backButtonUrl,
  backButtonLinkProps,
  backButtonOnClick,
  backButtonAriaLabel,
  backButtonText,
  linkActions,
  isInactive = false,
  inactiveAction,
  "data-testid": dataTestId,
  primaryButtonLoading,
  extraContentRow
}) => {
  let backButtonLinkElement;
  if (backButtonUrl) {
    backButtonLinkElement = /* @__PURE__ */ React.createElement(Link, __spreadValues({ to: LinkHelper.normalize(backButtonUrl) }, backButtonOnClick ? { onClick: backButtonOnClick } : {}), /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, gutterSize: "s" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiIcon, { type: URL.getStatic("icons/arrow_back.svg"), "aria-label": backButtonAriaLabel, size: "original" })), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, backButtonText)));
  } else if (backButtonLinkProps) {
    backButtonLinkElement = /* @__PURE__ */ React.createElement(Link, __spreadValues(__spreadValues({}, backButtonLinkProps), backButtonOnClick ? { onClick: backButtonOnClick } : {}), /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", responsive: false, gutterSize: "s" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
      EuiIcon,
      {
        type: URL.getStatic("icons/arrow_back.svg"),
        "aria-label": backButtonAriaLabel,
        size: "original",
        style: { minHeight: "20px", maxHeight: "20px" }
      }
    )), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, backButtonText)));
  } else if (backButtonOnClick) {
    backButtonLinkElement = /* @__PURE__ */ React.createElement(
      EuiFlexGroup,
      {
        direction: "row",
        responsive: false,
        gutterSize: "s",
        onClick: backButtonOnClick,
        style: { cursor: "pointer" }
      },
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
        EuiIcon,
        {
          type: URL.getStatic("icons/arrow_back.svg"),
          "aria-label": backButtonAriaLabel,
          size: "original",
          style: { minHeight: "20px", maxHeight: "20px" }
        }
      )),
      /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiLink, null, backButtonText))
    );
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", alignItems: "stretch", justifyContent: "flexStart", gutterSize: "l" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { alignItems: "flexStart", style: { height: "20px" } }, backButtonLinkElement)), /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(
    PageHeader,
    {
      subtitle,
      title,
      icon,
      linkActions,
      primaryAction,
      secondaryActions,
      inactiveAction,
      prependBadgeRow,
      badges,
      isInactive,
      "data-testid": dataTestId,
      primaryButtonLoading,
      extraContentRow
    }
  )))));
};
export { DesktopDetailViewsPageHeader };
