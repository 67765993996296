import React from "react";

export interface LineItemPriceProps {
  currentUnitPrice: string;
  onUnitPriceChanged: React.ChangeEventHandler<HTMLInputElement>;
  onFinalizePriceChanges: React.FocusEventHandler<HTMLInputElement>;
}

class LineItemPrice extends React.Component<LineItemPriceProps> {
  render() {
    return (
      <div className="line-item-price line-item-field">
        <label className="line-item-label">Price</label>
        <div className="line-item-price-input-container">
          <span className="vertical-center-wrapper">
            <span className="invoice-currency">$</span>
          </span>
          <input
            type="text"
            className="line-item-price-input"
            value={this.props.currentUnitPrice}
            onChange={this.props.onUnitPriceChanged}
            onBlur={this.props.onFinalizePriceChanges}
          />
        </div>
      </div>
    );
  }
}

export default LineItemPrice;
