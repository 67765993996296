import { numOrStr } from "../types/common";

let Currency = {
  dollar(amount: number, decimal: boolean = true): string | null | undefined {
    let fixedAmount = decimal ? this.toFixed(amount, 2) : this.toFixed(amount, 0);

    if (fixedAmount) {
      return `$${fixedAmount}`;
    }
  },

  toFixed(amount: numOrStr, fixedPlaces: number = 2, thousandSeparator: boolean = true): string {
    let floatAmount;

    if (typeof amount === "number") {
      floatAmount = amount;
    } else {
      floatAmount = Number.parseFloat(amount);
    }

    if (!Number.isNaN(floatAmount)) {
      let fixedAmount = floatAmount.toFixed(fixedPlaces);

      if (thousandSeparator) {
        return this.commaSeparate(fixedAmount);
      }

      return fixedAmount;
    }
    return "";
  },

  commaSeparate(str: string): string {
    let [integerPart, fractionalPart] = str.split(".");

    let integers = integerPart
      .split("")
      .reverse()
      .reduce((prev, curr, i) => {
        let isOnePastThirdDigit = i % 3 === 0;

        if (isOnePastThirdDigit) {
          return `${curr},${prev}`;
        }

        return curr + prev;
      });

    return fractionalPart ? `${integers}.${fractionalPart}` : integers;
  }
};

export default Currency;
