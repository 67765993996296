import React from "react";
import CalendarUtils from "../../../utils/calendar-utils";

export default function ScheduledTime(props) {
  if (props.appointment && props.appointment.get("availability_segment")) {
    let scheduledStartDate = props.appointment.getIn(["availability_segment", "event", "dtstart"]);
    scheduledStartDate = CalendarUtils.formatDayMonthTime(scheduledStartDate, { timeFmt: "h:mma" });
    let scheduledEndTime = props.appointment.getIn(["availability_segment", "event", "dtend"]);
    scheduledEndTime = CalendarUtils.renderFormattedTime(scheduledEndTime, "h:mma");

    return (
      <p>
        Scheduled for{" "}
        <span className="scheduled-date">
          {scheduledStartDate}-{scheduledEndTime}
        </span>
      </p>
    );
  }

  return <p className="copy">Not scheduled</p>;
}
