import React from "react";
import ScheduledTime from "./scheduled-time";
import { MeldType } from "../../../constants";
import MeldUtils from "../../../meld/utils/meld-utils";

export default class SchedulerMeldDetails extends React.Component {
  render() {
    const meld = this.props.meld;
    return (
      <div>
        <div className="detail-section-title">
          {meld.get("meld_type") === MeldType.ESTIMATE ? "Estimate" : "Meld"} Details
        </div>
        <div className="row">
          <div className="columns small-12 medium-6 large-4">
            <h3>Description</h3>
            <div className="copy">{meld.get("brief_description")}</div>
          </div>
          <div className="columns small-12 medium-6 large-4">
            <h3>Address</h3>
            <div className="copy" data-testid="address-info">
              {MeldUtils.propertyName(meld) && <div>{MeldUtils.propertyName(meld)}</div>}
              <div>{MeldUtils.getAddressLine1(meld)}</div>
              <div>{MeldUtils.getMeldUnitName(meld)}</div>
              <div>{MeldUtils.getAbbreviatedPropertyListLine2(meld)}</div>
            </div>
          </div>
          <div className="columns small-12 medium-12 large-4">
            <h3>Appointment</h3>
            <ScheduledTime appointment={this.props.appointment} />
          </div>
        </div>
      </div>
    );
  }
}
