import Currency from "../../utils/currency";
import Delete from "../../svg/delete-svg";
import LineItem from "../../payment/components/line-item";
import LineItemCost from "../../payment/components/line-item-cost";
import LineItemDescription from "../../payment/components/line-item-description";
import React from "react";
import { BaseLineItemCostTypeEnum } from "../../common/openapi";

class EstimateLineItem extends LineItem {
  render() {
    let { lineItem } = this.props;

    return (
      <div className="row invoice-line-items-row">
        <div className="line-item-field columns small-12 large-4">
          {!this.props.disableInput && (
            <span className="line-item-delete-icon" onClick={() => this.props.onDeleteRequested(lineItem)}>
              <Delete />
            </span>
          )}
          <LineItemDescription
            lineItem={lineItem}
            onDescriptionChanged={this.onDescriptionChanged}
            disabled={this.props.disableInput}
          />
        </div>
        <div className="line-item-quantity columns small-12 large-2">
          <label className="line-item-label">Quantity</label>
          <input
            type="text"
            value={this.state.currentQuantity}
            onChange={this.onUnitQuantityChanged}
            onBlur={this.finalizeQuantityChanges}
            disabled={this.props.disableInput}
          />
        </div>
        <div className="columns small-12 large-2">
          <LineItemCost
            currentUnitCost={this.state.currentUnitCost}
            onUnitCostChanged={this.onUnitCostChanged}
            onFinalizeCostChanges={this.finalizeCostChanges}
            disableInput={this.props.disableInput}
          />
        </div>
        <div className="columns small-12 large-2">
          <select
            onChange={this.onCostTypeChanged}
            value={lineItem.get("line_item_type")}
            disabled={this.props.disableInput}
          >
            <option value={BaseLineItemCostTypeEnum.FEE}>Fee</option>
            <option value={BaseLineItemCostTypeEnum.LABOR}>Labor</option>
            <option value={BaseLineItemCostTypeEnum.MATERIALS}>Materials</option>
          </select>
        </div>
        <div className="line-item-field columns small-12 large-2">
          <label className="line-item-label">Item Total</label>
          <div className="line-item-total">{Currency.dollar(this.calcTotalEstimate())}</div>
        </div>
      </div>
    );
  }
}

export default EstimateLineItem;
