import React from "react";
import classnames from "classnames";

import Link from "@pm-shared/utils/link";

// export interface LinkButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
//   children: any;
//   buttonType?: ButtonTypes;
//   to?: H.LocationDescriptor | LinkProps | { to: H.LocationDescriptor; query: any };
//   disabled?: boolean;
//   query?: any;
//   normalize?: boolean;
//   replace?: boolean;
// }

function LinkButton(props: any) {
  const classes = classnames(
    {
      "pm-button": !!props.buttonType,
      [props.buttonType as string]: !!props.buttonType,
      disabled: props.disabled,
    },
    props.className
  );

  const { href, to, disabled } = props;

  if (to && href) {
    throw new Error("LinkButton can have 'href' or 'to' but not both");
  }

  if (!to && !href) {
    throw new Error("LinkButton needs to have 'href' or 'to' but not both");
  }

  if (href && !to) {
    return (
      <a className={classes} href={href}>
        <div className="button-children-container">{props.children}</div>
      </a>
    );
  }

  const { buttonType, className, children, ...linkProps } = props;

  return (
    <Link className={classes} disabled={disabled} {...linkProps}>
      <div className="button-children-container">{children}</div>
    </Link>
  );
}

export default LinkButton;
