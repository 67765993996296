import qs from "qs";

export const parseQueryParams = (params: string) => {
  return qs.parse(params[0] === "?" ? params.slice(1) : params);
};

export const getQueryParam = (params: string, key: string) => {
  return parseQueryParams(params)[key];
};

export const getQueryParams = (url: string) => {
  let params = url.substring(url.indexOf("?"));

  let queryParams = params ? parseQueryParams(params) : {};
  // the offset parameter is mandatory in pagination
  queryParams = !queryParams.hasOwnProperty("offset") ? { ...queryParams, offset: 0 } : queryParams;

  const parameters = Object.keys(queryParams);
  let urlParameters = parameters.map((key: any) => `${key}=${queryParams[key]}`).join("&");
  return urlParameters;
};
