var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import { Link as L, NavLink as NavL } from "react-router-dom";
import { stringify } from "@pm-assets/js/common/utils/location-utils";
export let LinkHelper = {
  normalize(url) {
    let mTenant = this.getMultitenant();
    let orgId = this.getOrgId();
    let orgType = this.getOrgType();
    let updatedUrl = `/${mTenant}/${orgType}/${orgId}/${url}`;
    return updatedUrl.replace("//", "/");
  },
  getOrgId() {
    return window.location.pathname.split("/")[3];
  },
  getOrgType() {
    return window.location.pathname.split("/")[2];
  },
  getMultitenant() {
    return window.location.pathname.split("/")[1];
  }
};
function _getLinkProps(props) {
  let _a = props, { to, normalize, query } = _a, otherProps = __objRest(_a, ["to", "normalize", "query"]);
  let newTo;
  if (_.isObject(to)) {
    newTo = Object.assign({}, to, { pathname: to.pathname });
  } else {
    newTo = { pathname: to };
  }
  if (normalize) {
    newTo.pathname = LinkHelper.normalize(newTo.pathname);
  }
  if (query) {
    newTo.search = stringify(query);
  }
  return Object.assign(otherProps, { to: newTo });
}
class Link extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement(L, __spreadValues({}, _getLinkProps(this.props)), this.props.children);
  }
}
Link.defaultProps = {
  normalize: true
};
class NavLink extends React.Component {
  render() {
    return /* @__PURE__ */ React.createElement(NavL, __spreadValues({}, _getLinkProps(this.props)), this.props.children);
  }
}
NavLink.defaultProps = {
  normalize: true
};
export { NavLink };
export let LinkUtils = {
  getCurrentSubRoute(location) {
    return location.pathname.split("/").slice(5)[0];
  },
  reloadIfVersionOld(location, history) {
    if (window.PM.env.outOfDate) {
      let url;
      if (window.location.hash) {
        url = window.location.pathname + "#" + location.pathname + (location.search || "?") + "_k=" + location.key;
      } else {
        url = location.pathname + location.search;
      }
      if (history.action === "PUSH") {
        window.location.assign(url);
      } else {
        window.location.replace(url);
      }
      if (window.location.hash) {
        window.location.reload();
      }
    }
  }
};
export default Link;
export function normalizePath(path) {
  return `/:multitenantId/:orgType/:org/${path}`;
}
