import React from "react";
import classnames from "classnames";

export enum HeaderSizes {
  Medium = "xl",
  Large = "xxl"
}

export interface HeaderProps {
  children: any;
  className?: string;
  size?: HeaderSizes;
}

function Header(props: HeaderProps) {
  const classes = classnames("pm-headerblock", props.className, props.size);

  if (props.size === HeaderSizes.Large) {
    return <h1 className={classes}>{props.children}</h1>;
  } else {
    return <h2 className={classes}>{props.children}</h2>;
  }
}

export default Header;
