import {
  InputGroup,
  InputRightAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
  VStack,
  Tooltip,
} from "@chakra-ui/react";
import { Datepicker } from "@pmeld/components";
import { formatISODuration, isValid, parseISO, eachMinuteOfInterval, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { colors } from "@pm-app/components/theme";
import { AuthUtils } from "@pm-assets/js/utils/auth-utils";

// For whatever reason, date-fns has a `formatISODuration` function
// but not the inverse. So we need to manually get the value from
// the duration string.
//
// https://rgxdb.com/r/MD2234J
// https://github.com/date-fns/date-fns/pull/2302
const DURATION_REGEX =
  /^(-?)P(?=\d|T\d)(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)([DW]))?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+(?:\.\d+)?)S)?)?$/;

let times: any[] = []; // time array
const start = new Date();
start.setHours(6, 30, 0, 0); // Default Start time for time values
const end = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 24);

eachMinuteOfInterval({ start, end }, { step: 15 }).forEach((date: any) => {
  let time_ap = format(date, "h:mm aa");
  let time_twentyfour = format(date, "HH:mm:ss");
  let time = { ap: time_ap, key: time_twentyfour };
  times.push(time);
});

interface DateValues {
  start_day: number;
  start_time: string;
  end_day: number;
  end_time: string;
}

export const TimestampCondition: React.FC<{
  operator_key: string;
  value?: string;
  error?: string[];
  onChange: (value: Date | string | DateValues | undefined) => void;
}> = ({ operator_key, value, error, onChange }) => {
  if (["before", "after", "equal", "in_range", "not_in_range"].includes(operator_key)) {
    let parsedValue;

    if (value && isValid(parseISO(value))) {
      parsedValue = parseISO(value);
    }

    return (
      <Datepicker
        data-test={"workflows.form.select.block_operator.condition_type.timestamp"}
        isInvalid={!!error}
        onChange={(date) => onChange(date)}
        testPrefix="workflows.form.select.block_operator.condition_type.timestamp"
        value={parsedValue}
        width="100%"
      />
    );
  }

  if (["in_recurring_range"].includes(operator_key)) {
    const DaysOfWeek = [
      {
        key: 1,
        day: "Monday",
      },
      {
        key: 2,
        day: "Tuesday",
      },
      {
        key: 3,
        day: "Wednesday",
      },
      {
        key: 4,
        day: "Thursday",
      },
      {
        key: 5,
        day: "Friday",
      },
      {
        key: 6,
        day: "Saturday",
      },
      {
        key: 7,
        day: "Sunday",
      },
    ];

    // @ts-ignore
    let [startDay, setSelectedStartDay] = useState<number>(value?.start_day);
    // @ts-ignore
    let [endDay, setSelectedEndDay] = useState<number>(value?.end_day);
    // @ts-ignore
    let [startTime, setSelectedStartTime] = useState(value?.start_time);
    // @ts-ignore
    let [endTime, setSelectedEndTime] = useState(value?.end_time);

    useEffect(() => {
      // @ts-ignore
      setSelectedStartDay(value?.start_day);
      // @ts-ignore
      setSelectedEndDay(value?.end_day);
      // @ts-ignore
      setSelectedStartTime(value?.start_time);
      // @ts-ignore
      setSelectedEndTime(value?.end_time);
    }, [value]);

    const handleStartDayChange = (event: any) => {
      setSelectedStartDay(Number(event.target.value));
      onChange({
        start_day: event.target.value,
        start_time: startTime,
        end_day: endDay,
        end_time: endTime,
      });
    };
    const handleEndDayChange = (event: any) => {
      setSelectedEndDay(Number(event.target.value));
      onChange({
        start_day: startDay,
        start_time: startTime,
        end_day: event.target.value,
        end_time: endTime,
      });
    };
    const handleStartTimeChange = (event: any) => {
      setSelectedStartTime(event.target.value);
      onChange({ start_day: startDay, start_time: event.target.value, end_day: endDay, end_time: endTime });
    };
    const handleEndTimeChange = (event: any) => {
      setSelectedEndTime(event.target.value);
      onChange({
        start_day: startDay,
        start_time: startTime,
        end_day: endDay,
        end_time: event.target.value,
      });
    };

    return (
      <VStack alignItems={"left"}>
        <Text fontSize={"lg"} as={"b"}>
          Day
        </Text>
        <Select
          data-test={"workflows.form.select.block_operator.condition_type.timestamp.select"}
          value={startDay ? startDay : ""}
          onChange={handleStartDayChange}
        >
          <option
            key={""}
            value={""}
            disabled
            data-test={"workflows.form.select.block_operator.condition_type.timestamp.select.option.0"}
          >
            Start Day
          </option>
          {DaysOfWeek.map((day, idx) => (
            <option
              data-test={`workflows.form.select.block_operator.condition_type.timestamp.select.option.${idx + 1}`}
              key={day.key}
              value={day.key}
            >
              {day.day}
            </option>
          ))}
        </Select>
        <Text fontSize={"lg"} as={"b"}>
          Time
        </Text>
        <Select
          data-test={"workflows.form.select.block_operator.condition_type.timestamp.select"}
          value={startTime ? startTime : ""}
          onChange={handleStartTimeChange}
        >
          <option
            data-test={"workflows.form.select.block_operator.condition_type.timestamp.select.option.0"}
            key={""}
            value={""}
            disabled
          >
            Start Time
          </option>
          {times.map((time, idx) => (
            <option
              data-test={`workflows.form.select.block_operator.condition_type.timestamp.select.option.${idx + 1}`}
              key={time.key}
              value={time.key}
            >
              {time.ap}
            </option>
          ))}
        </Select>
        <Text fontSize={"md"} as={"b"} color={colors.pmGreyMedium}>
          And
        </Text>
        <Text fontSize={"lg"} as={"b"}>
          Day
        </Text>
        <Select
          data-test={"workflows.form.select.block_operator.condition_type.timestamp.select"}
          value={endDay ? endDay : ""}
          onChange={handleEndDayChange}
        >
          <option
            data-test={"workflows.form.select.block_operator.condition_type.timestamp.select.option.0"}
            key={""}
            value={""}
            disabled
          >
            End Day
          </option>
          {DaysOfWeek.map((day, idx) => (
            <option
              data-test={`workflows.form.select.block_operator.condition_type.timestamp.select.option.${idx + 1}`}
              key={day.key}
              value={day.key}
            >
              {day.day}
            </option>
          ))}
        </Select>
        <Text fontSize={"lg"} as={"b"}>
          Time
        </Text>
        <Select
          data-test={"workflows.form.select.block_operator.condition_type.timestamp.select"}
          value={endTime ? endTime : ""}
          onChange={handleEndTimeChange}
        >
          <option
            data-test={"workflows.form.select.block_operator.condition_type.timestamp.select.option.0"}
            key={""}
            value={""}
            disabled
          >
            End Time
          </option>
          {times.map((time, idx) => (
            <option
              data-test={`workflows.form.select.block_operator.condition_type.timestamp.select.option.${idx + 1}`}
              key={time.key}
              value={time.key}
            >
              {time.ap}
            </option>
          ))}
        </Select>
        <Tooltip label={"To change your time zone go to Account Settings > Company"}>
          <Tag variant={"outline"}>
            <TagLabel>
              <>Timezone set to {AuthUtils.getManagementTimezone()} </>
            </TagLabel>
            <TagRightIcon as={AiOutlineQuestionCircle} color={colors.pmBlue} />
          </Tag>
        </Tooltip>
      </VStack>
    );
  }

  if (["greater_than_past", "lesser_than_past", "greater_than_future", "lesser_than_future"].includes(operator_key)) {
    let parsedValue;

    if (value) {
      const matches = value.match(DURATION_REGEX);

      if (matches) {
        // Since we only care about days, we can just grab the
        // day value by index.
        parsedValue = Number(matches[4]);
      }
    }

    return (
      <InputGroup>
        <NumberInput
          data-test={"workflows.form.select.block_operator.condition_type.timestamp"}
          isInvalid={!!error}
          onChange={(newValue) => onChange(formatISODuration({ days: Number(newValue) }))}
          value={parsedValue}
          width="100%"
        >
          <NumberInputField data-test={"workflows.form.select.block_operator.condition_type.timestamp.input"} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>

        <InputRightAddon children={parsedValue === 1 ? "day" : "days"} />
      </InputGroup>
    );
  }

  return <></>;
};
