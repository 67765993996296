import TenantSettingsActions from "../actions/tenant-settings-actions";
import TenantSettingsApi from "../apis/tenant-settings-api";

let TenantSettingsSource = {
  fetchCurrent() {
    return {
      remote(state, { cid } = {}) {
        return TenantSettingsApi.fetchCurrent();
      },

      shouldFetch(state, force) {
        return state.modelState.size === 0;
      },

      success: TenantSettingsActions.fetchCurrentDone,
      error: TenantSettingsActions.fetchCurrentFail
    };
  }
};

export default TenantSettingsSource;
