var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import moment from "moment";
import React from "react";
export default function withCalendarControls(Comp, { getStartDayFn } = {}) {
  return class WithCalendarControlsComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        startDay: getStartDayFn ? getStartDayFn(props) : moment().startOf("day")
      };
      this.handleTodayClicked = this.handleTodayClicked.bind(this);
      this.handleNavPrev = this.handleNavPrev.bind(this);
      this.handleNavNext = this.handleNavNext.bind(this);
    }
    render() {
      return /* @__PURE__ */ React.createElement(
        Comp,
        __spreadValues({
          startDay: this.state.startDay,
          onTodayClicked: this.handleTodayClicked,
          onNavPrev: this.handleNavPrev,
          onNavNext: this.handleNavNext
        }, this.props)
      );
    }
    handleTodayClicked() {
      this.setState({ startDay: moment().startOf("day") });
    }
    handleNavPrev() {
      this.setState({
        startDay: this.state.startDay.clone().subtract(this.props.numDays, "days")
      });
    }
    handleNavNext() {
      this.setState({
        startDay: this.state.startDay.clone().add(this.props.numDays, "days")
      });
    }
  };
}
