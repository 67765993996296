import React from "react";

interface LineItemCostProps {
  onFinalizeCostChanges: any;
  onUnitCostChanged: any;
  currentUnitCost: number | any;
  disableInput?: boolean;
}

class LineItemCost extends React.Component<LineItemCostProps> {
  render() {
    return (
      <div className="line-item-cost line-item-field">
        <label className="line-item-label">Cost</label>
        <div className="line-item-cost-input-container">
          <span className="vertical-center-wrapper">
            <span className="invoice-currency">$</span>
          </span>
          <input
            type="text"
            className="line-item-cost-input"
            data-test="line-item-cost.input"
            value={this.props.currentUnitCost}
            onChange={this.props.onUnitCostChanged}
            onBlur={this.props.onFinalizeCostChanges}
            disabled={this.props.disableInput}
          />
        </div>
      </div>
    );
  }
}

export default LineItemCost;
