var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import * as C from "../../constants";
import ContactUtils from "../../utils/contact-utils";
import I from "immutable";
import userStore from "../../authentication/stores/user-store";
import { hasPerm } from "../../common/utils/permission-utils";
import { RoleUtils } from "./role-utils";
import { asImmer } from "../../../../app/utils/decorators";
const _AgentUtils = class {
  static getFullName(agent, emptyMsg = _AgentUtils.NAMELESS_USER) {
    agent = agent;
    if (!agent) {
      return emptyMsg;
    }
    const fullName = `${agent.first_name || ""} ${agent.last_name || ""}`;
    if (fullName.replace(" ", "")) {
      return fullName;
    } else {
      return emptyMsg;
    }
  }
  static getPrimaryPhone(agent) {
    return ContactUtils.getFirstFoundPhone(agent.get("contact"));
  }
  static getPrimaryEmail(agent) {
    return ContactUtils.getPrimaryEmail(agent.get("contact"));
  }
  static getRoleName(agent) {
    return agent.getIn(["role", "name"]);
  }
  static getFormattedRole(agent) {
    if (agent) {
      return RoleUtils.formatName(agent.get("role"));
    } else {
      return void 0;
    }
  }
  static canDelete(agent) {
    if (agent) {
      let isCurrentAgent = _AgentUtils.isThisTheCurrentAgent(agent);
      return !isCurrentAgent && hasPerm(C.Perms.CAN_DELETE_MANAGEMENT_AGENTS);
    }
  }
  static canEdit(agent) {
    if (agent) {
      let isCurrentAgent = _AgentUtils.isThisTheCurrentAgent(agent);
      return !isCurrentAgent && hasPerm(C.Perms.CAN_EDIT_MANAGEMENT_AGENTS);
    }
  }
  static canChangeManagementAgentPropertyGroup() {
    return hasPerm(C.Perms.CAN_CHANGE_MANAGEMENTAGENTPROPERTYGROUPS);
  }
  static isThisTheCurrentAgent(agentOrAgents) {
    const currentUserId = userStore.getCurrentUserId();
    if (agentOrAgents && I.Map.isMap(agentOrAgents)) {
      if (agentOrAgents.getIn(["user", "id"])) {
        return agentOrAgents.getIn(["user", "id"]) === currentUserId;
      }
    } else if (agentOrAgents && I.List.isList(agentOrAgents)) {
      let agents = agentOrAgents;
      let results = agents.map((agent) => {
        return agent.getIn(["user", "id"]);
      });
      return results.contains(currentUserId);
    }
    return false;
  }
  static getFirstFoundPhone(agent, emptyMsg) {
    return ContactUtils.getFirstFoundPhone(agent.get("contact"), emptyMsg);
  }
  static getCellPhone(agent, emptyMsg) {
    return agent && agent.getIn && agent.getIn(["contact", "cell_phone"]) || emptyMsg;
  }
  static getBusinessPhone(agent, emptyMsg) {
    return agent && agent.getIn && agent.getIn(["contact", "business_phone"]) || emptyMsg;
  }
  static getHomePhone(agent, emptyMsg) {
    return agent && agent.getIn && agent.getIn(["contact", "home_phone"]) || emptyMsg;
  }
};
let AgentUtils = _AgentUtils;
AgentUtils.NAMELESS_USER = "Nameless user";
__decorateClass([
  asImmer()
], AgentUtils, "getFullName", 1);
export default AgentUtils;
