import { APIPrefix } from "@pm-property-management-hub/src/utils/api";
import useSWR from "swr";
import { axios } from "@pm-shared/axios";

export const workflowLookupNames = [
  "meld_assigned",
  "meld_brief_description",
  "meld_completion_date",
  "meld_created",
  "meld_description",
  "meld_due_date",
  "meld_owner_approval_comment",
  "meld_owner_approval_completed_date",
  "meld_owner_approval_dollar_amount",
  "meld_owner_approval_request_date",
  "meld_priority",
  "meld_status",
  "meld_tenant_presence_required",
  "meld_tenant_rating",
  "meld_tenant_review",
  "meld_work_category",
  "meld_work_location",
  "meldexpenditures_amount",
  "meldexpenditures_created",
  "meldexpenditures_notes",
  "meldexpenditures_status",
  "meldinvoice_amount",
  "meldinvoice_notes",
  "meldinvoice_payment_requested",
  "meldinvoice_status",
  "meldinvoice_vendor_id",
  "vendor_assigned_vendor",
  "workentry_checkin",
  "workentry_checkout",
  "workentry_created",
  "workentry_description",
  "workentry_hours",
  "workentry_long_description",
];

export const fetchMeldWorkflows = () => {
  return useSWR<any[]>(`${APIPrefix}/workflows/list/`, () =>
    axios.get(`${APIPrefix}/workflows/list/`).then((response) => response.data)
  );
};

export const fetchWorkflowTemplates = () => {
  const url = `${APIPrefix}/workflows/templates/list/`;
  return useSWR<any[]>(url, () => axios.get(url).then((response) => response.data));
};

export const fetchMeldWorkflow = (id: number) => {
  const url = `${APIPrefix}/workflows/retrieve/${id}/`;
  return useSWR<any>(url, () => axios.get(`${APIPrefix}/workflows/retrieve/${id}/`).then((response) => response.data), {
    revalidateOnFocus: false,
  });
};

export const createMeldWorkflow = (data: any) => {
  return axios.post(`${APIPrefix}/workflows/create/`, data);
};

export const updateMeldWorkflows = (data: {
  workflow_trees: {
    id?: number;
    enabled: string | null;
    name: string;
    trigger_event: string;
    nodes?: any; // TODO?: https://github.com/apryor6/py2ng
  }[];
}) => {
  return axios.post(`${APIPrefix}/workflows/update/`, data).then((response) => response.data);
};

export interface WorkflowCondition {
  // see: workflow/engine/nodes/conditions/config.py
  lookup_name: string;
  condition_type: "choice" | "text" | "timestamp" | "decimal" | "boolean";
  operators: { [key: string]: { [key: string]: [key: string] } }; // any_of, all_of, etc, etc
  options: string[][] | null; // the options for the condition e.g. meld_priority has low/med/high
  readable: string;
  source: "db";
}

export interface OperatorI {
  class_name: string;
  load_default: string;
  allow_none: boolean;
  attribute: null | string;
  data_key: null | string;
  dump_default: string;
  dump_only: boolean;
  error_messages: { [x: string]: string };
  metadata: { [x: string]: any };
  required: boolean;
  validate: any[];
  validators: any[];
}

export interface OperatorFullI extends OperatorI {
  inner: OperatorI[];
}

export interface WorkflowConfig {
  triggers: { [key: string]: string };
  actions: { [key: string]: any };
  conditions_list: WorkflowCondition[];
  conditions_map: { [x: string]: WorkflowCondition };
  operators: { [key: string]: OperatorFullI };
}

export const fetchWorkflowConfig = () => {
  return useSWR<WorkflowConfig>(`${APIPrefix}/workflow-config/`, () =>
    axios.get(`${APIPrefix}/workflow-config/`).then((response) => response.data)
  );
};

export const fetchVendors = () => {
  return useSWR<any>(`${APIPrefix}/vendors/`, () =>
    axios.get(`${APIPrefix}/vendors/`).then((response) => response.data)
  );
};

export const fetchPropertyGroups = () => {
  return useSWR<any>(`${APIPrefix}/properties/groups/`, () =>
    axios.get(`${APIPrefix}/properties/groups/`).then((response) => response.data)
  );
};

export const fetchMeldTags = () => {
  return useSWR<any>(`${APIPrefix}/melds/tags/abbrev/`, () =>
    axios.get(`${APIPrefix}/melds/tags/abbrev/`).then((response) => response.data)
  );
};

export const getEmptyWorkflowBlockCondition = (why: string) => {
  return { id: null, lookup_name: null, operator: null, options: null, type: null, value: [""], why };
};
