var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from "@elastic/eui";
import { colors } from "@pm-frontend/styles";
import URL from "@pm-shared/utils/url";
const PmCallout = ({
  message,
  "data-testid": dataTestId,
  body,
  type = "normal",
  background = colors.neutrals.gray50,
  borderColor = colors.neutrals.gray100
}) => {
  let textColor = colors.brand.meldBlue;
  let icon = URL.getStatic("icons/exclamation_in_circle.svg");
  if (type === "danger") {
    textColor = colors.interface.red.default;
    icon = URL.getStatic("icons/delete.svg");
  } else if (type === "subdued") {
    textColor = colors.neutrals.gray700;
    icon = URL.getStatic("icons/exclamation_in_circle_gray.svg");
  } else if (type === "black") {
    textColor = colors.neutrals.gray800;
    icon = URL.getStatic("icons/exclamation_in_circle_gray.svg");
  }
  const style = {
    gap: "16px",
    background,
    border: `1px solid ${borderColor}`,
    borderRadius: "2px",
    padding: "16px"
  };
  let formattedMessage;
  if (typeof message === "string") {
    formattedMessage = /* @__PURE__ */ React.createElement(EuiText, { color: textColor, size: "s", style: { fontWeight: 600, whiteSpace: "pre-wrap" } }, message);
  } else {
    formattedMessage = message;
  }
  return /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "column", style, alignItems: "flexStart" }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, /* @__PURE__ */ React.createElement(EuiFlexGroup, { direction: "row", alignItems: "center", gutterSize: "s", responsive: false }, /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: true, style: { minWidth: "20px" } }, /* @__PURE__ */ React.createElement(EuiIcon, { type: icon })), /* @__PURE__ */ React.createElement(EuiFlexItem, __spreadValues({ grow: false }, dataTestId ? { "data-testid": dataTestId } : {}), formattedMessage))), body ? /* @__PURE__ */ React.createElement(EuiFlexItem, { grow: false }, " ", body, " ") : null);
};
export { PmCallout };
