import React from "react";

/* tslint:disable:max-line-length */
export default class TruckSvg extends React.Component<any> {
  render() {
    let classes = `${this.props.classes} truck-icon`;
    return (
      <svg className={classes} viewBox="0 0 82 59" version="1.1" xmlns="https://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Truck" transform="translate(3.000000, 3.000000)" strokeWidth="5" stroke="#4E515E">
            <path d="M28.826813,41.137207 L48.173187,41.137207 L48.173187,41.137207 C48.0594805,41.7407425 48,42.3634251 48,43 C48,48.5228475 52.4771525,53 58,53 C63.5228475,53 68,48.5228475 68,43 C68,42.3634251 67.9405195,41.7407425 67.826813,41.137207 L73.0455659,41.137207 C74.7066071,41.137207 76.0531469,39.7865409 76.0531467,38.1438319 L76.0531439,10.7310936 C76.0531437,9.07789813 74.7049414,7.73771843 73.0547133,7.73771843 L43.7187517,7.73771843 L43.7187511,3.28360059 C43.7187509,1.62706618 42.3753832,0.284179706 40.7182173,0.284179705 L17.6587366,0.284179689 C16.0015877,0.284179688 14.1871078,1.54035873 13.6051424,3.0921737 L0.390463033,38.329213 C-0.191126323,39.8800253 0.675913213,41.137207 2.33366267,41.137207 L9.17318702,41.137207 C9.05948053,41.7407425 9,42.3634251 9,43 C9,48.5228475 13.4771525,53 19,53 C24.5228475,53 29,48.5228475 29,43 C29,42.3634251 28.9405195,41.7407425 28.826813,41.137207 Z M19,53 C24.5228475,53 29,48.5228475 29,43 C29,37.4771525 24.5228475,33 19,33 C13.4771525,33 9,37.4771525 9,43 C9,48.5228475 13.4771525,53 19,53 Z M43.7000122,7.8359375 L43.7000122,39.6650391 L43.7000122,7.8359375 Z M58,53 C63.5228475,53 68,48.5228475 68,43 C68,37.4771525 63.5228475,33 58,33 C52.4771525,33 48,37.4771525 48,43 C48,48.5228475 52.4771525,53 58,53 Z M59.137207,7.94580078 L59.137207,20.0077756 C59.137207,21.6603355 60.4821922,23 62.1275643,23 L76.6482405,23" />
          </g>
        </g>
      </svg>
    );
  }
}
/* tslint:enable:max-line-length */
