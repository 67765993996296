import React from "react";

/* tslint:disable:max-line-length */
export default class TenantSvg extends React.Component<any> {
  render() {
    return (
      <svg
        className={`${this.props.classes} tenant-svg`}
        xmlns="https://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 28 28"
      >
        <path
          d="M26.25 28h-3.927H5.677 1.75C.783 28 0 27.217 0 26.25V21c0-3.26 2.883-6.076 7.118-7.58-.065-.38-.118-.77-.118-1.17V7c0-3.866 3.134-7 7-7s7 3.134 7 7v5.25c0 .4-.053.79-.118 1.17C25.117 14.925 28 17.74 28 21v5.25c0 .967-.784 1.75-1.75 1.75zM18 6.47c0-1.934-2.067-3.5-4-3.5-1.933 0-4 1.566-4 3.5v6.093c0 1.932 2.067 3.5 4 3.5 1.933 0 4-1.568 4-3.5V6.47zM25 21c0-1.89-2.014-3.532-5.01-4.456-1.28 1.635-3.75 2.706-5.99 2.706s-4.71-1.07-5.99-2.706C5.013 17.468 3 19.11 3 21v3.97h22V21z"
          id="path-1"
          fillRule="evenodd"
        />
      </svg>
    );
  }
}
/* tslint:enable:max-line-length */
