import alt from "../../alt";

class MessageActions {
  constructor() {}

  storeError(data) {
    return data;
  }

  storeSuccess(data) {
    return data;
  }

  storeInfo(data) {
    return data;
  }

  markOldest() {
    return {};
  }
}

export default alt.createActions(MessageActions);
