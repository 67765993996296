import BaseApi from "../../utils/base-api";
import ApiUrls from "../../utils/api-urls";

class ManagementApi extends BaseApi {
  static fetchAll() {
    return this.get(ApiUrls.managementList);
  }
}

export default ManagementApi;
