import CalendarUtils from "../../utils/calendar-utils";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import moment from "moment";
import PropTypes from "prop-types";
import createReactClass from "create-react-class";
import React from "react";

let DateUnits = {
  MONTHS: "months",
  DAYS: "days",
  WEEKS: "weeks",
};

let SubChoices = {
  BETWEEN: "BETWEEN",
  IN_THE_PAST: "IN_THE_PAST",
  OFFSET_LTE: "OFFSET_LTE",
};

const SUB_CHOICE = "SubChoice";
const GTE_SUFFIX = "__gte";
const LTE_SUFFIX = "__lte";
const INTERVAL_SUFFIX = "__interval";
const OFFSET_LTE_SUFFIX = "__offset_lte";

let DateFilter = createReactClass({
  propTypes: {
    filterName: PropTypes.string.isRequired,
    filterUpdated: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    isFilterOpen: PropTypes.bool.isRequired,
    maxFromDate: PropTypes.instanceOf(Date),
    maxToDate: PropTypes.instanceOf(Date),
    minFromDate: PropTypes.instanceOf(Date),
    minToDate: PropTypes.instanceOf(Date),
  },

  getInitialState() {
    return {
      [this.props.filterName + SUB_CHOICE]: null,
      intervalUnit: this.dateUnit(),
      offsetUnit: this.offsetUnit(),
    };
  },

  render() {
    let { filterName, isFilterOpen } = this.props;

    return (
      <div>
        <div className="filter-type">
          <input
            type="checkbox"
            id={`date-filter-${filterName}`}
            checked={isFilterOpen}
            onChange={() => this.props.onToggleFilter(filterName)}
          />
          <label htmlFor={`date-filter-${filterName}`}>{this.props.children}</label>
        </div>
        {isFilterOpen ? this.renderChoices() : null}
      </div>
    );
  },

  renderChoices() {
    let { filterName } = this.props;
    let subChoice = this.state[filterName + SUB_CHOICE] || this.calcSubChoice();

    return (
      <div className="filter-choices">
        <div className="filter-choice">
          <select className="filter-select" onChange={this.onSubChoiceSelected} value={subChoice}>
            <option value={SubChoices.BETWEEN}>Date Range</option>
            <option value={SubChoices.IN_THE_PAST}>In the past</option>
            {this.renderBeforeOption()}
          </select>
          {this.renderSubChoices()}
        </div>
      </div>
    );
  },

  renderBeforeOption() {
    if (this.props.offsetLTELabel) {
      return <option value={SubChoices.OFFSET_LTE}>{this.props.offsetLTELabel}</option>;
    }
  },

  renderSubChoices() {
    let { filterName } = this.props;
    let subChoice = this.state[filterName + SUB_CHOICE] || this.calcSubChoice();

    if (subChoice === SubChoices.BETWEEN) {
      return (
        <span className="date-filter-sub-choices">
          <DateTimePicker
            time={false}
            defaultValue={this.getDateFilterFieldValue(filterName + GTE_SUFFIX)}
            placeholder="After"
            parse={this.parseDate}
            max={this.props.maxFromDate}
            min={this.props.minFromDate}
            onChange={this.handleGTEChange}
          />
          <DateTimePicker
            time={false}
            placeholder="Before"
            defaultValue={this.getDateFilterFieldValue(filterName + LTE_SUFFIX)}
            max={this.props.maxToDate}
            min={this.props.minToDate}
            parse={this.parseDate}
            onChange={this.handleLTEChange}
          />
        </span>
      );
    } else if (subChoice === SubChoices.IN_THE_PAST) {
      return (
        <span>
          <input
            type="text"
            placeholder="# of"
            className="time-in-past"
            value={this.getDateUnitValueForCategory()}
            onChange={this.handleIntervalChanged}
          />
          <select
            className="time-in-past-unit"
            value={this.state.intervalUnit}
            onChange={this.handleIntervalValueChanged}
          >
            <option value={DateUnits.DAYS}>Days</option>
            <option value={DateUnits.MONTHS}>Months</option>
          </select>
        </span>
      );
    } else if (subChoice === SubChoices.OFFSET_LTE) {
      return (
        <span>
          <input
            type="text"
            placeholder="0"
            className="time-in-past"
            value={this.getBeforeValueForInput()}
            onChange={this.handleOffsetChanged}
          />
          <select
            className="time-in-past-unit"
            value={this.state.offsetUnit}
            onChange={this.handleOffsetThanUnitChanged}
          >
            <option value={DateUnits.DAYS}>Days</option>
            <option value={DateUnits.WEEKS}>Weeks</option>
            <option value={DateUnits.MONTHS}>Months</option>
          </select>
        </span>
      );
    }
  },

  calcSubChoice() {
    let subChoice = SubChoices.BETWEEN;
    let { filterName, filters } = this.props;

    if (filters[filterName + INTERVAL_SUFFIX]) {
      subChoice = SubChoices.IN_THE_PAST;
    } else if (filters[filterName + OFFSET_LTE_SUFFIX]) {
      subChoice = SubChoices.OFFSET_LTE;
    }

    return subChoice;
  },

  parseDate(dateStr: string) {
    let date = new Date(dateStr);
    if (CalendarUtils.isValidDate(date)) {
      return date;
    }
  },

  onSubChoiceSelected(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ [this.props.filterName + SUB_CHOICE]: e.target.value });
  },

  handleLTEChange(date: Date) {
    let newDate = date ? moment(date).add(1, "days").startOf("day").toDate().getTime() : null;
    this.updateFilters({ lte: newDate });
  },

  handleGTEChange(date: Date) {
    let newDate = date ? moment(date).startOf("day").toDate().getTime() : null;
    this.updateFilters({ gte: newDate });
  },

  handleIntervalValueChanged(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({ intervalUnit: e.target.value });
    this.updateFilters({ interval: "" });
  },

  handleIntervalChanged(e: React.ChangeEvent<HTMLInputElement>) {
    let dateUnit = this.state.intervalUnit;
    let val;

    let unitQuantity = parseInt(e.target.value, 10);

    if (unitQuantity > 9999) {
      unitQuantity = 9999;
    }

    if (Number.isInteger(unitQuantity)) {
      val = moment.duration({ [dateUnit]: unitQuantity }).toISOString();
    } else {
      val = "";
    }

    this.updateFilters({ interval: val });
  },

  handleOffsetThanUnitChanged(e: React.ChangeEvent<HTMLSelectElement>) {
    const newUnit = e.target.value;
    this.setState({ offsetUnit: newUnit });
    let offsetVal = this.props.filters[this.props.filterName + OFFSET_LTE_SUFFIX];
    offsetVal = this.stripAllButIntegers(offsetVal);

    const val = `-${offsetVal}${newUnit.slice(0, 1)}`;
    this.props.filterUpdated(this.props.filterName + OFFSET_LTE_SUFFIX, val);
  },

  handleOffsetChanged(e: React.ChangeEvent<HTMLInputElement>) {
    let val = this.stripAllButIntegers(e.target.value);
    if (val) {
      val = `-${val}${this.state.offsetUnit.slice(0, 1)}`;
    }
    this.updateFilters({ offset: val });
  },

  getDateFilterFieldValue(filterName: string) {
    let filterValue = this.props.filters[filterName];

    if (filterValue) {
      let date = new Date();
      date.setTime(filterValue);
      if (["created__lte", "scheduled__lte", "completed__lte"].includes(filterName)) {
        date.setDate(date.getDate() - 1);
      }
      return date;
    } else {
      return null;
    }
  },

  getBeforeValueForInput() {
    let offsetVal = this.props.filters[this.props.filterName + OFFSET_LTE_SUFFIX] || "";
    return this.stripAllButIntegers(offsetVal);
  },

  stripAllButIntegers(val: string) {
    return val ? val.replace(/[^0-9]+/g, "") : "";
  },

  getDateUnitValueForCategory() {
    let unitValue = this.state.intervalUnit;

    let interval = this.props.filters[this.props.filterName + INTERVAL_SUFFIX];

    if (interval) {
      interval = moment.duration(interval);
      if (unitValue === DateUnits.DAYS) {
        return interval.asDays();
      } else {
        return interval.asMonths();
      }
    }
  },

  offsetUnit() {
    let offsetVal = this.props.filters[this.props.filterName + OFFSET_LTE_SUFFIX];

    if (offsetVal) {
      if (offsetVal.includes("d")) {
        return DateUnits.DAYS;
      } else if (offsetVal.includes("w")) {
        return DateUnits.WEEKS;
      } else if (offsetVal.includes("m")) {
        return DateUnits.MONTHS;
      }
    }

    return DateUnits.DAYS;
  },

  dateUnit() {
    let interval = this.props.filters[this.props.filterName + INTERVAL_SUFFIX];

    if (interval) {
      interval = moment.duration(interval);
      if (interval.days()) {
        return DateUnits.DAYS;
      }
      return DateUnits.MONTHS;
    }
    return DateUnits.DAYS;
  },

  updateFilters({ offset, interval, gte, lte }: { offset?: string; interval?: string; gte?: number; lte?: number }) {
    if (offset !== undefined) {
      this.props.filterUpdated(this.props.filterName + GTE_SUFFIX, "");
      this.props.filterUpdated(this.props.filterName + LTE_SUFFIX, "");
      this.props.filterUpdated(this.props.filterName + OFFSET_LTE_SUFFIX, offset);
      this.props.filterUpdated(this.props.filterName + INTERVAL_SUFFIX, "");
    } else if (interval !== undefined) {
      this.props.filterUpdated(this.props.filterName + GTE_SUFFIX, "");
      this.props.filterUpdated(this.props.filterName + LTE_SUFFIX, "");
      this.props.filterUpdated(this.props.filterName + OFFSET_LTE_SUFFIX, "");
      this.props.filterUpdated(this.props.filterName + INTERVAL_SUFFIX, interval);
    } else if (gte !== undefined) {
      this.props.filterUpdated(this.props.filterName + GTE_SUFFIX, gte);
      this.props.filterUpdated(this.props.filterName + OFFSET_LTE_SUFFIX, "");
      this.props.filterUpdated(this.props.filterName + INTERVAL_SUFFIX, "");
    } else if (lte !== undefined) {
      this.props.filterUpdated(this.props.filterName + LTE_SUFFIX, lte);
      this.props.filterUpdated(this.props.filterName + OFFSET_LTE_SUFFIX, "");
      this.props.filterUpdated(this.props.filterName + INTERVAL_SUFFIX, "");
    }

    if (interval !== undefined) {
      this.setState({
        [this.props.filterName + SUB_CHOICE]: SubChoices.IN_THE_PAST,
      });
    } else if (offset !== undefined) {
      this.setState({
        [this.props.filterName + SUB_CHOICE]: SubChoices.OFFSET_LTE,
      });
    }
  },
});

export default DateFilter;
