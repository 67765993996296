import I from "immutable";

import { asImmer } from "../../../../app/utils/decorators";
import * as C from "../../constants";
import { ErrorHandler } from "../../../../app/utils/ErrorHandler";
import { ImmutableMap } from "../../types/common";
import { LinkHelper } from "@pm-shared/utils/link";
import { MeldInvoiceNode } from "../../common/graphql/types";

type Invoice = MeldInvoiceNode | ImmutableMap;

export default class InvoiceUtils {
  static defaultQuery = { saved_filter: "default" };

  @asImmer()
  static hasBeenSubmitted(invoice: Invoice) {
    invoice = invoice as MeldInvoiceNode;
    let status = invoice.status;
    return status ? status.toString() !== C.InvoiceStatuses.DRAFT : false;
  }

  static getFormattedStatus(invoice: I.Map<string, any>) {
    const status = invoice.get("status");

    switch (status) {
      case C.InvoiceStatuses.DRAFT:
        return "Draft";
      case C.InvoiceStatuses.SUBMITTED:
        return "Submitted";
      case C.InvoiceStatuses.PAID:
        return "Paid";
      case C.InvoiceStatuses.HOLD:
        return "Hold";
      case C.InvoiceStatuses.IN_REVIEW:
        return "In Review";
      case C.InvoiceStatuses.DECLINED:
        return "Declined";
      case C.InvoiceStatuses.APPROVED:
        return "Approved";
      default:
        ErrorHandler.handleError(new Error(`Unknown invoice status ${status}`));
        return "";
    }
  }

  static getDownloadUrl(invoice: I.Map<string, any>) {
    return LinkHelper.normalize(`/invoices/${invoice.get("id")}/download/`);
  }

  static getViewUrl(invoice: I.Map<string, any>) {
    return LinkHelper.normalize(`/invoices/${invoice.get("id")}/view/`);
  }

  @asImmer()
  static getID(invoice: I.Map<string, any> | Invoice) {
    invoice = invoice as MeldInvoiceNode;
    return invoice.id;
  }

  static getTitle(invoice: I.Map<string, any>) {
    return invoice.getIn(["meld", "brief_description"]);
  }

  static sumInvoices(invoices: I.Map<string, any>) {
    return invoices.reduce((total: number, invoice) => total + Number.parseFloat(invoice.get("amount")), 0);
  }

  static getPaymentNotes(invoice: I.Map<string, any>, emptyMsg = "No notes recorded") {
    return invoice.getIn(["payment", "notes"]) || emptyMsg;
  }

  static calcLineItemPrice(
    cost: number,
    {
      rentMgrInvItem,
    }: {
      rentMgrInvItem?: {
        Markup?: number;
        IsMarkupPercentage?: boolean;
        Cost?: number;
      };
    } = {}
  ) {
    if (rentMgrInvItem) {
      if (rentMgrInvItem.Markup && rentMgrInvItem.IsMarkupPercentage) {
        return cost + (rentMgrInvItem.Markup * cost) / 100;
      }
      if (rentMgrInvItem.Markup) {
        return cost + rentMgrInvItem.Markup;
      }
    }
    return cost;
  }

  static getHoldReason(invoice: I.Map<string, any>) {
    const holdReasons = invoice.get("hold_reasons");

    if (!holdReasons.isEmpty()) {
      return holdReasons.last().get("reason");
    }
  }

  static getDeclineReason(invoice: I.Map<string, any>) {
    const declineReasons = invoice.get("decline_reasons");

    if (!declineReasons.isEmpty()) {
      return declineReasons.last().get("reason");
    }
  }

  static canManagerReview(invoice: I.Map<string, any>) {
    return ![C.InvoiceStatuses.DRAFT, C.InvoiceStatuses.IN_REVIEW, C.InvoiceStatuses.PAID].includes(
      invoice.get("status")
    );
  }

  static canManagerHold(invoice: I.Map<string, any>) {
    return ![C.InvoiceStatuses.DRAFT, C.InvoiceStatuses.HOLD, C.InvoiceStatuses.PAID].includes(invoice.get("status"));
  }

  static canManagerDecline(invoice: I.Map<string, any>) {
    return ![C.InvoiceStatuses.DRAFT, C.InvoiceStatuses.DECLINED, C.InvoiceStatuses.PAID].includes(
      invoice.get("status")
    );
  }

  static canManagerApprove(invoice: I.Map<string, any>) {
    return ![C.InvoiceStatuses.DRAFT, C.InvoiceStatuses.APPROVED, C.InvoiceStatuses.PAID].includes(
      invoice.get("status")
    );
  }

  static canManagerPay(invoice: I.Map<string, any>) {
    return ![C.InvoiceStatuses.DRAFT, C.InvoiceStatuses.PAID].includes(invoice.get("status"));
  }

  static getInvoiceFile(invoice: I.Map<string, any>) {
    return invoice.get("vendorinvoicefile") || I.fromJS({ pdf_file_url: InvoiceUtils.getViewUrl(invoice) });
  }
}
