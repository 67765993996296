import classnames from "classnames";
import Link, { LinkUtils } from "../../../../web/shared/utils/link";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";

class SubNavLink extends React.Component {
  render() {
    let currentSubRoute = LinkUtils.getCurrentSubRoute(this.props.location);

    let liClassNames = classnames({
      active: currentSubRoute === this.props.activePath,
      "subnav-item": true,
    });

    return (
      <li className={liClassNames}>
        <Link to={this.props.to}>{this.props.children}</Link>
      </li>
    );
  }
}

SubNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  activePath: PropTypes.string.isRequired,
};

export default withRouter(SubNavLink);
