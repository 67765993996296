import { ErrorHandler } from "../../../../app/utils/ErrorHandler";

let HitUtils = {
  getLinkUrl(hit) {
    switch (hit._index) {
      case "tenant":
        return `/tenants/${hit._source.id}/`;
      case "unit":
        return `/properties/${hit._source.id}/`;
      case "meld":
        return `/meld/${hit._source.id}/summary/`;
      case "owner":
        return `/properties/owners/${hit._source.id}/summary/`;
      case "vendor":
        return `/vendors/${hit._source.id}/summary/`;
      case "recurring_meld":
        return `/melds/recurring/${hit._source.id}/`;
      default:
        ErrorHandler.handleError(new Error(`ElasticSearch hit did not match an expected index: ${hit._index}`));
    }
  }
};

export default HitUtils;
