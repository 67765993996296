import Currency from "../../utils/currency";
import LineItem from "./line-item";
import LineItemPrice from "./line-item-price";
import React from "react";

class InvoiceLineItem extends LineItem {
  render() {
    return (
      <div className="row invoice-line-items-row">
        {this.renderDescription()}
        <div className="line-item-quantity columns small-12 large-2">
          <label className="line-item-label">Quantity</label>
          <input
            type="text"
            className="line-item-quantity-input"
            value={this.state.currentQuantity}
            onChange={this.onUnitQuantityChanged}
            onBlur={this.finalizeQuantityChanges}
          />
        </div>
        <div className="columns small-12 large-2">
          <LineItemPrice
            currentUnitPrice={this.state.currentUnitPrice}
            onUnitPriceChanged={this.onUnitPriceChanged}
            onFinalizePriceChanges={this.finalizePriceChanges}
          />
        </div>
        <div className="line-item-field columns small-12 large-2">
          <label className="line-item-label">Item Total</label>
          <div className="line-item-total">{Currency.dollar(this.calcTotal())}</div>
        </div>
      </div>
    );
  }
}

export default InvoiceLineItem;
