import { History } from "history";
import { stringify } from "./location-utils";
import { ErrorHandler } from "../../../../app/utils/ErrorHandler";
import { LinkHelper } from "@pm-shared/utils/link";
import RouteUtils from "./route-utils";

interface Options {
  query?: any;
  normalize?: boolean;
  state?: any;
}

const HistoryUtils = {
  push(history: History, path: string, { query, normalize = true, state }: Options = {}) {
    if (!history) {
      ErrorHandler.handleError(new Error(`History not provided for path: ${path}`));
    }

    let pathname = path;
    if (query) {
      pathname = `${pathname}?${stringify(query)}`;
    }

    if (normalize || normalize === undefined) {
      pathname = LinkHelper.normalize(pathname);
    }

    history.push(pathname, state);
  },

  replace(history: History, path: string, { query, normalize = true, state }: Options = {}) {
    if (!history) {
      ErrorHandler.handleError(new Error(`History not provided for path: ${path}`));
    }

    let pathname = path;
    if (query) {
      pathname = `${pathname}?${stringify(query)}`;
    }

    if (normalize || normalize === undefined) {
      pathname = LinkHelper.normalize(pathname);
    }

    history.replace(pathname, state);
  },

  replaceOrGoBack(history: History, path: string, { normalize = true } = {}) {
    if (RouteUtils.isPrevRoutePathEqualTo(normalize ? LinkHelper.normalize(path) : path)) {
      HistoryUtils.goBack(history);
    } else {
      HistoryUtils.replace(history, path, { normalize });
    }
  },

  goBack(history: History) {
    history.goBack();
  },
};

export default HistoryUtils;
