import classnames from "classnames";
import React from "react";

import Label from "./Label";

export enum UserTypes {
  Manager = "manager",
  Owner = "owner",
  Tenant = "tenant",
  Vendor = "vendor"
}

export interface UserTypeLabelProps {
  userType: UserTypes;
  className?: string;
}

const UserTypeLabel: React.FC<UserTypeLabelProps> = props => {
  const classes = classnames("pm-user-type-label", props.userType, props.className);
  return <Label className={classes}>{props.children}</Label>;
};

export default UserTypeLabel;
