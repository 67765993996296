import I from "immutable";

let ActivityRecord = I.Record({
  extra_context: I.Map({
    actor_name: null,
    object_name: null,
    future: null
  }),
  id: null,
  actor_id: null,
  object_id: null,
  time: null,
  verb: I.Map({
    id: null,
    past_tense: null,
    infinitive: null
  }),
  read: true
});

export default class Activity extends ActivityRecord {
  isRead() {
    return this.get("read");
  }

  getActor() {
    return this.getIn(["extra_context", "actor_name"]);
  }

  getObject() {
    return this.getIn(["extra_context", "object_name"]);
  }

  getFuture() {
    return this.getIn(["extra_context", "future"]);
  }

  getVerbId() {
    return this.getIn(["verb", "id"]);
  }

  getVerb() {
    if (this.getIn(["extra_context", "future"])) {
      return "will " + this.getIn(["verb", "infinitive"]);
    } else {
      return this.getIn(["verb", "past_tense"]);
    }
  }

  getSimpleText() {
    let text = `${this.getActor()} ${this.getVerb()} ${this.getObject()}`;

    let future = this.getFuture();
    if (future) {
      text += ` in ${future}`;
    }

    return text;
  }
}
