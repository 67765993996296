import moment from "moment";

/**
 * Some utility functions for rendering dates/days.
 */
const DateUtils = {
  /**
   * Returns a formatted date string "January 1st 1970" or "January 1st 1970 at 12:00am" if full is set to true
   * @param date
   * @param full Set to true to also display the time
   */
  getDateString(date: Date, full = false) {
    if (full) {
      // return moment(date).format('YYYY/MM/DD hh:mma');
      return moment(date).format("MMMM D, YYYY [at] h:mma");
    }

    return moment(date).format("MMMM D, YYYY");
  },

  /**
   * Returns a formatted date string "Jan 1, 1970"
   */
  getMMMDYYYString(date: Date) {
    return moment(date).format("MMM D, YYYY");
  },

  /**
   * Returns a formatted date string "Mon, Jan 1"
   * @param date
   */
  getShortDateString(date: Date) {
    return moment(date).format("ddd, MMM D");
  },

  /**
   * Returns a formatted time string from a given date
   * @param date
   */
  getTimeString(date: Date) {
    return moment(date).format("h:mma");
  },

  /**
   * Returns a formatted time string with capital AM/PM from a given date
   * @param date
   */
  getCapitalTimeString(date: Date) {
    return moment(date).format("h:mm A");
  },

  /**
   * Returns the days from now to the provided date
   * @param date
   */
  getDaysUntil(date: Date) {
    return moment(date).diff(moment(), "days");
  },

  /**
   * Returns a human readable string relating the relative time from now
   * to the provided date: "Yesterday, Today, 3 days, 1 day, 12/12/1990, Wednesday, Last Wednesday"
   * @param date
   */
  getDueDayString(date: Date) {
    const dateMoment = moment(date);

    if (dateMoment.diff(moment(), "days") <= 7) {
      return this.getDaysToString(date);
    }

    return dateMoment.calendar(undefined, {
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      lastWeek: "[last] dddd",
      nextWeek: "dddd",
      sameElse: "L"
    });
  },

  /**
   * Returns the number of days to/since now
   *
   * 3 days, 3 days ago
   *
   * @param date
   * @param withoutSuffix - set to true if you want to exclude "ago"
   */
  getDaysToString(date: Date, withoutSuffix: boolean = false) {
    const dateMoment = moment(date);

    return dateMoment.fromNow(withoutSuffix);
  },

  dateComparator(d1: Date, d2: Date): number {
    return d2.getTime() - d1.getTime();
  }
};

export default DateUtils;
