import { Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { fetchMeldTags, fetchPropertyGroups, fetchVendors } from "@pm-property-management-hub/src/utils/api/workflows";
import { fetchAgents } from "@pm-property-management-hub/src/utils/api/agents";
export const ChoiceCondition: React.FC<any> = (props) => {
  const { value, mapped_config, error, onChange, lookup_name, operator_key } = props;
  const [options, setOptions] = useState(mapped_config?.options || null);
  const shouldFetchVendors = ["meldinvoice_vendor_id", "vendor_assigned_vendor"].includes(lookup_name);

  const shouldFetchAgents = ["management_assigned_internal", "meld_coordinator_assigned"].includes(lookup_name);
  if (shouldFetchAgents) {
    const { data } = fetchAgents();

    useEffect(() => {
      if (data) {
        setOptions(data.map((agent: any) => [agent.id, `${agent.first_name} ${agent.last_name}`]));
      }
    }, [data]);
  }

  if (shouldFetchVendors) {
    const { data } = fetchVendors();

    useEffect(() => {
      if (data) {
        setOptions(data.map((vendor: any) => [vendor.id, vendor.name]));
      }
    }, [data]);
  }

  const shouldFetchPropertyGroups = ["meld_property_groups"].includes(lookup_name);

  if (shouldFetchPropertyGroups) {
    const { data } = fetchPropertyGroups();

    useEffect(() => {
      if (data) {
        setOptions(data.map((pg: any) => [pg.id, pg.name]));
      }
    }, [data]);
  }

  const shouldFetchTags = ["meld_tags"].includes(lookup_name);

  if (shouldFetchTags) {
    const { data } = fetchMeldTags();

    useEffect(() => {
      if (data) {
        setOptions(data.map((meldTag: any) => [meldTag.id, meldTag.name]));
      }
    }, [data]);
  }

  if (!["missing", "present"].includes(value) && !["missing", "present"].includes(operator_key)) {
    return (
      <Select
        isInvalid={!!error}
        data-test={"workflows.form.select.block_operator.condition_type.choice"}
        onChange={onChange}
        placeholder="Select"
        value={value}
      >
        {options?.map(([x, y]: any) => {
          // the backend MUST return a dictionary, or fail
          return (
            <option key={x} value={x} data-test={`workflows.form.select.select.choice.${x}.option`}>
              {y}
            </option>
          );
        })}
      </Select>
    );
  }

  return <></>;
};
