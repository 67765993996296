import _ from "lodash";
import * as C from "../constants";
import classNames from "classnames";

import React from "react";

interface PriorityLightProps {
  priority: string;
}

class PriorityLight extends React.Component<PriorityLightProps> {
  render() {
    let classes = classNames({
      "priority-low": this.props.priority === C.Priority.LOW,
      "priority-medium": this.props.priority === C.Priority.MEDIUM,
      "priority-high": this.props.priority === C.Priority.HIGH,
      "priority-level": true
    });

    let priorityTitle = `${_.capitalize(this.props.priority.toLowerCase())} priority`;

    return <div className={classes} title={priorityTitle} />;
  }
}

export default PriorityLight;
