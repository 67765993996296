import _ from "lodash";
import I from "immutable";

const RecordUtils = {
  initFromJS(record: any, data: any, recordFields: I.Set<string>) {
    return recordFields.reduce((reduction, fieldName: string) => {
      const datum = data[fieldName];
      if (!_.isUndefined(datum) && datum !== null) {
        return reduction.set(fieldName, I.fromJS(datum));
      }
      return reduction;
    }, record);
  }
};

export default RecordUtils;
